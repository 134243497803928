import { FC } from 'react';
import { useToggle } from 'react-use';
import { Icon } from '@yandex-int/hr-components/Icon/desktop/bundle';

import { ImageViewer } from 'components/ImageViewer';
import { Text } from 'shared/ui/Text';

import { cn } from './ImageWithZoom.cn';
import { ImageWithZoomProps } from './ImageWithZoom.types';

import './ImageWithZoom.css';

export const ImageWithZoom: FC<ImageWithZoomProps> = props => {
    const {
        images,
        withZoom,
    } = props;

    const [showGalleryModal, toggleShowGalleryModal] = useToggle(false);

    if (!images || images?.length === 0) {
        return null;
    }

    return (
        <>
            <div
                className={cn('ImageWrapper', { withZoom })}
                onClick={withZoom ? toggleShowGalleryModal : undefined}
            >
                <img className={cn('Image')} src={images[0]} />
                {withZoom && (
                    <Text className={cn('SearchIcon')} color="inverse">
                        <Icon glyph="search" />
                    </Text>
                )}
            </div>
            <ImageViewer
                closeModal={toggleShowGalleryModal}
                images={images}
                visible={showGalleryModal}
            />
        </>
    );
};

ImageWithZoom.displayName = cn();
