import { VISA_FREE_LIST } from '../FormTripCreate-PersonFields/PersonFields.constants';
import { RoutePointForm } from '../FormTripCreate-RouteFields/RouteFields.types';

export const getVisaRequired = (route: Omit<RoutePointForm, 'id'>[], isB2B: boolean | undefined) => {
    const getCountryName = (routePoint: Omit<RoutePointForm, 'id'>) => {
        return routePoint.cityFrom?.country;
    };

    const isVisaFreeRoute = route.map(getCountryName).every(country => VISA_FREE_LIST.has(country || ''));
    const isSameCountryTrip = new Set(route.map(getCountryName).filter(Boolean)).size <= 1;

    return !isVisaFreeRoute && !isSameCountryTrip && !isB2B;
};
