import { FC } from 'react';

import { Bubble } from 'components/Bubble';
import { OptionalLink } from 'components/OptionalLink';
import { config } from 'config';
import { Text } from 'shared/ui/Text';
import { Participant } from 'types/Participant';
import { getStaffUrl } from 'utils/urls';

import { cn } from '../Participants.cn';

type ParticipantsSingleProps = {
    participant: Participant;
    size?: 's' | 'm' | 'l';
};

const { isB2B } = config;

export const ParticipantsSingle: FC<ParticipantsSingleProps> = props => {
    const { participant, size } = props;

    const participantContent = (
        <>
            <Bubble bubble={{ ...participant, personId: participant.id }} size={size} type="person" />
            <Text as="span" className={cn('Name')} typography="body-m">
                <Text as="div" className={cn('Single', { name: true })}>
                    {participant.name}
                </Text>
                <Text as="div" className={cn('Login')} color="secondary" typography="caption-m">
                    {participant.login}
                </Text>
            </Text>
        </>
    );

    if (isB2B) {
        return (
            <div className={cn('Single')}>
                {participantContent}
            </div>
        );
    }

    return (
        <OptionalLink className={cn('Single')} href={getStaffUrl(participant.login)}>
            {participantContent}
        </OptionalLink>
    );
};
