import { withBemMod } from '@bem-react/core';

import { cn } from '../Bubble.cn';

import './Bubble_mark_check.css';

export interface WithMarkCheckProps {
    mark?: 'check';
}

export const withMarkCheck = withBemMod<WithMarkCheckProps>(cn(), {
    mark: 'check',
});
