import * as t from 'io-ts';

import { FlightService } from './FlightService';
import { HotelService } from './HotelService';
import { TrainService } from './TrainService';

export const Service = t.union([
    FlightService,
    HotelService,
    TrainService,
], 'Service');

export type Service = t.TypeOf<typeof Service>;
