import { FC, useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Button } from 'shared/ui/Button';
import { buildUrl } from 'utils/buildUrl';

import type { QuickFilterProps, Search, TTQuickFilter } from './interface';
import { cn } from './QuickFilter.cn';
import { i18n } from './QuickFilter.i18n';

export const QuickFilter: FC<QuickFilterProps> = props => {
    const { quickFilter, disabled, search } = props;
    const history = useHistory();
    const { pathname } = useLocation();

    type ServiceType = typeof search.type;

    const query = search as Search<ServiceType>;
    const filter = quickFilter as TTQuickFilter<ServiceType>;

    const active = useMemo(() => filter.isQueryActiveFn(query), [filter, query]);

    const handleClick = useCallback(() => {
        const search = active ? filter.disableQueryFn(query) : filter.enableQueryFn(query);

        history.push(buildUrl({ pathname, search }));
    }, [active, filter, query, history, pathname]);

    return (
        <Button
            checked={active}
            className={cn('Button', [props.className])}
            disabled={disabled}
            size="l"
            view="secondary"
            onClick={handleClick}
        >
            {i18n(filter.slug)}
        </Button>
    );
};
