import { FC } from 'react';

import { TravellerDocuments } from '../TravellerDocuments';

import { EmployeeDocumentsProps } from './EmployeeDocuments.types';

import './EmployeeDocuments.css';

export const EmployeeDocuments: FC<EmployeeDocumentsProps> = props => {
    const {
        service,
        allowedDocuments,
        className,
    } = props;

    if (!service.person) {
        return null;
    }

    return (
        <TravellerDocuments
            actions={service.actions}
            allowedDocuments={allowedDocuments}
            className={className}
            person={service.person}
            personInfo={service.person}
            service={service}
        />
    );
};
