import dayjs from 'dayjs';

import { PersonTripTaxiState, TaxiNotActivatedReasons } from 'services/SwaggerApi';

import { i18n } from '../TaxiDetails.i18n';

type GetTagHints = (
        reasons: TaxiNotActivatedReasons[],
        data: PersonTripTaxiState
    ) => {
        firstReason: string;
        restReasons: string[];
    }

export const getTagHints: GetTagHints = (reasons, data) => {
    const {
        taxi_date_from,
        taxi_date_to,
        tracker_date_end,
        tracker_date_start,
    } = data;

    if (reasons.length === 0) {
        return {
            firstReason: '',
            restReasons: [],
        };
    }

    const [firstReason, ...restReasons] = reasons.map(reason => {
        if (reason === 'current_date_not_in_taxi_dates_range') {
            const currentDate = dayjs();
            const dateTo = dayjs(taxi_date_to || tracker_date_end);
            const dateFrom = dayjs(taxi_date_from || tracker_date_start);

            const isFutureDate = dateTo.isBefore(currentDate);

            return i18n(reason, {
                translate: i18n(isFutureDate ? 'date_to' : 'date_from',
                    {
                        taxi_date_to: dateTo.format('DD MMMM'),
                        taxi_date_from: dateFrom.format('DD MMMM'),
                    }),
            });
        }

        return i18n(reason);
    });

    return {
        firstReason,
        restReasons,
    };
};
