import { FC, useCallback } from 'react';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { Card } from 'components/Card';
import { ScrollButton } from 'components/ScrollButton';
import { TravelPolicyCard } from 'components/TravelPolicyCard';
import { withRemote } from 'hocs/withRemote';
import { useTravelPoliciesInfinite } from 'hooks/useTravelPoliciesInfinite';
import { RouteTravelPolicyAdd } from 'routes/RouteTravelPolicyAdd';
import { Button } from 'shared/ui/Button';
import { ErrorState } from 'shared/ui/ErrorState';

import { EmptyState } from '../EmptyState';
import { cn } from '../PageCompanies.cn';
import { i18n } from '../PageCompanies.i18n';

import { TabTravelPoliciesProps, TravelPoliciesListProps } from './TabTravelPolicies.types';

import './TabTravelPolicies.css';

const TravelPoliciesListView = withRemote(({ result }: TravelPoliciesListProps) => {
    const isEmpty = result.items.length === 0;

    const newTravelPolicyUrl = RouteTravelPolicyAdd.getPath({}, {});

    if (isEmpty) {
        return (
            <Card className={cn('TabTravelPoliciesEmptyState')}>
                <ErrorState
                    actions={[
                        <Button
                            key="create"
                            className={cn('AddButton')}
                            size="l"
                            type="link"
                            url={newTravelPolicyUrl}
                            view="primary"
                        >
                            {i18n('add_travel_policy')}
                        </Button>,
                    ]}
                    title={i18n('travel_policies_not_added')}
                />
            </Card>
        );
    }

    return (
        <div className={cn('TabTravelPoliciesList')}>
            {result.items.map(policy => (
                <TravelPolicyCard key={policy.travel_policy_id} data={policy} />
            ))}
        </div>
    );
});

export const TabTravelPolicies: FC<TabTravelPoliciesProps> = () => {
    const [travelPolicies, { isFetchingNextPage, fetchNextPage, hasNextPage }] = useTravelPoliciesInfinite({
        limit: 20,
    });

    const handleFetchMore = useCallback(() => fetchNextPage(), [fetchNextPage]);

    return (
        <div className={cn('TabTravelPolicies')}>
            <TravelPoliciesListView spinner fallbackElement={<EmptyState />} result={travelPolicies} />
            {hasNextPage && (
                <ButtonPlatform progress={Boolean(isFetchingNextPage)} onClick={handleFetchMore}>
                    {i18n('show_more')}
                </ButtonPlatform>
            )}
            <ScrollButton />
        </div>
    );
};
