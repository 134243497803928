import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider } from '@yandex-lego/components/Divider';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { HotelsMapSearchResult } from 'components/HotelsMapSearchResult';
import { getIconProvider, IconClose } from 'components/Icon';
import { withRemote } from 'hocs/withRemote';
import { useSearchResults } from 'hooks/useSearchResults';
import { RouteSearch } from 'routes/RouteSearch';
import { RouteSearchMap } from 'routes/RouteSearchMap';
import { Hotel, ServiceType } from 'services/SwaggerApi';
import { Button } from 'shared/ui/Button';
import { Container } from 'shared/ui/Container';
import { Page } from 'types/Page';
import { mapLoadable } from 'utils/Loadable';

import { cn } from './PageSearchMap.cn';
import { i18n } from './PageSearchMap.i18n';

import './PageSearchMap.css';

const Renderer = withRemote(HotelsMapSearchResult);

function isHotelResults(_results: unknown, service_type: ServiceType): _results is Hotel[] {
    return service_type === 'hotel';
}

/**
 * Страница отображения отелей из поисковой выдачи на карте
 * Переход на страницу только с мобилки
 */
export const PageSearchMap: Page<RouteSearchMap> = props => {
    const { params, search } = props;
    const { searchId } = params;
    const { person_id, trip_id, type } = search;
    const searchOptions = { searchId, trip_id, person_id, type };

    const history = useHistory();

    const [data, infiniteQuery] = useSearchResults(searchId, searchOptions);
    const { fetchNextPage, hasNextPage, isFetchingNextPage } = infiniteQuery;
    const fetchMore = useCallback(() => fetchNextPage(), [fetchNextPage]);

    const hotels = mapLoadable(data, data => {
        if (isHotelResults(data.items, data.service_type)) {
            return data.items;
        }

        return [];
    });

    const goToSearch = useCallback(() =>
        history.push(RouteSearch.getPath(params, search))
    , [history, params, search]);

    return (
        <div className={cn()}>
            <Container>
                <div className={cn('Actions')}>
                    <ButtonPlatform
                        className={cn('ShowMore')}
                        disabled={!hasNextPage}
                        progress={isFetchingNextPage}
                        view="primary"
                        onClick={fetchMore}
                    >
                        {i18n('show_more')}
                    </ButtonPlatform>
                </div>
                <Divider />
                <div className={cn('MapLayout')} />
                <Button
                    className={cn('CloseMap')}
                    icon={getIconProvider(IconClose)}
                    pin="circle-circle"
                    size="l"
                    view="primary"
                    onClick={goToSearch}
                />
                <Renderer
                    hotels={hotels}
                    personId={person_id}
                    searchId={searchId}
                    tripId={trip_id}
                />
            </Container>
        </div>
    );
};

PageSearchMap.displayName = cn();
