import dayjs from 'dayjs';
import { cityIn } from 'lvovich';

import { i18nTransfers } from 'i18n/i18nTransfers';
import { ProviderSegment } from 'services/SwaggerApi';
import { formatDuration } from 'utils/formatDuration';

type TransferData = {
    airportArrivalAt: ProviderSegment['arrival_at'];
    airportDepartureAt: ProviderSegment['departure_at'];
    city: ProviderSegment['arrival']['city'];
};

type GetTransferDurationProps = (
    transfer: TransferData
) => {
    lateArrival: boolean;
    transferDuration: string;
    textContent: string;
    totalMinutes: number;
};

export const getTransferDuration: GetTransferDurationProps = ({ airportArrivalAt, airportDepartureAt, city }) => {
    const arrivalDate = dayjs(airportArrivalAt || 0);
    const departureDate = dayjs(airportDepartureAt || 0);

    const totalMinutes = departureDate.diff(arrivalDate, 'minutes');
    const minutes = totalMinutes % 60;
    const hours = (totalMinutes - minutes) / 60;

    const arrivalHours = arrivalDate.hour();
    const departureHours = departureDate.hour();

    const lateArrival = arrivalHours < 6 || departureHours < 6;

    const transferDuration = formatDuration(`${hours}:${minutes}:00`);

    const transferKey = lateArrival ? 'night_transfer' : 'transfer_in';
    const formattedCity = city ? cityIn(city.name) : '';
    const textContent = i18nTransfers(transferKey, { city: formattedCity });

    return { lateArrival, transferDuration, textContent, totalMinutes };
};
