import { AppError } from 'errors/AppError';

/*
componentStack example:
in PersonalDocument (at PageDocuments.tsx:34)
in div (at PageDocuments.tsx:32)
in _c (at withRemote.tsx:59)
 */
function extractBlockName(componentStack?: string): string | undefined {
    if (!componentStack) return;

    const firstLine = componentStack.split('\n')[0];

    if (!firstLine) return;

    const match = firstLine.match(/in ([a-zA-Z]+)/);

    if (!match) return;

    return match[1];
}

export function logError(error: AppError, options: { componentStack?: string } & Record<string, unknown> = {}) {
    const { componentStack } = options;

    if (window && window.Ya && window.Ya.Rum) {
        const { message, name } = error;
        const block = extractBlockName(componentStack);
        const type = name;
        const level = name === 'DecodeError' ?
            window.Ya.Rum.ERROR_LEVEL.WARN :
            window.Ya.Rum.ERROR_LEVEL.ERROR;

        window.Ya.Rum.logError({ message, block, type, level });
    }
}
