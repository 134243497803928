import { FC } from 'react';

import { Popup as BasePopup } from 'shared/ui/Popup';
import { baseModifiers } from 'shared/ui/Popup/Popup.constants';

import { cn } from '../Dropdown.cn';
import { PopupProps } from '../Dropdown.types';

export const Popup: FC<PopupProps> = props => {
    const {
        anchorRef,
        children,
        className,
        onClose,
        visible,
        popupProps,
        dataTestId,
    } = props;

    return (
        <BasePopup
            anchor={anchorRef}
            className={cn('Popup', [className])}
            data-testid={dataTestId}
            direction="bottom-start"
            modifiers={baseModifiers}
            target="anchor"
            view="default"
            visible={visible}
            onClose={onClose}
            {...popupProps}
        >
            {children}
        </BasePopup>
    );
};
