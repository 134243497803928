import { FC, useCallback } from 'react';

import { SelectApproverForm } from 'components/SelectApproverForm';
import { withModalView } from 'hocs/withModalView';
import { withRemote } from 'hocs/withRemote';
import { useAvailableCompanies } from 'hooks/useAvailableCompanies';
import { i18nAction } from 'i18n/i18nAction';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';
import { mapApproverToFieldPersonValue } from 'utils/serializePerson';

import { useEmployeeActivate } from './EmployeeActivateModal.hooks/useEmployeeActivate';
import { cn } from './EmployeeActivateModal.cn';
import { i18n } from './EmployeeActivateModal.i18n';
import { EmployeeActivateModalProps, HandleSubmit } from './EmployeeActivateModal.types';

import './EmployeeActivateModal.css';

const SelectApproverFormRemote = withRemote(SelectApproverForm);

const EmployeeActivateModalPresenter: FC<EmployeeActivateModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        className,
        employee,
    } = props;

    const { employee_id, company_id, approver } = employee;
    const defaultValues = {
        approver: mapApproverToFieldPersonValue(approver),
        new_company_id: company_id,
    };

    const availableCompanies = useAvailableCompanies();

    const [activateEmployee, activationState, { error }] = useEmployeeActivate({
        onSuccess: onSubmit,
        options: {
            invalidateOnSuccess: true,
            hideValidationErrors: true,
        },
    });

    const isActivating = isLoading(activationState);

    const handleSubmit = useCallback<HandleSubmit>(formFields => {
        const { new_company_id, approver } = formFields;

        activateEmployee(company_id, employee_id, {
            approver_id: approver.id,
            new_company_id: new_company_id!,
        });
    }, [activateEmployee, company_id, employee_id]);

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <SelectApproverFormRemote
                availableCompanies={availableCompanies}
                buttonSubmitText={i18nAction('save')}
                defaultValues={defaultValues}
                employee={employee}
                error={error}
                progress={isActivating}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

EmployeeActivateModalPresenter.displayName = cn();

export const EmployeeActivateModal = withModalView(EmployeeActivateModalPresenter);
