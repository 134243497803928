import { FC } from 'react';
import { Link } from '@yandex-int/hr-components/Link/desktop/bundle';

import { Cell } from 'components/Cell';
import { withTdAttrs } from 'hocs/withTdAttrs';
import { RoutePersonTrip } from 'routes/RoutePersonTrip';

import { cn } from './CellTripLink.cn';
import { CellTripLinkProps } from './CellTripLink.types';

const CellTripLinkBase: FC<CellTripLinkProps> = props => {
    const {
        className,
        tripId,
        personId,
        getAttrs,
    } = props;

    const personTripPath = RoutePersonTrip.getPath({ tripId, personId }, {});

    return (
        <Cell
            className={cn(null, [className])}
            getAttrs={getAttrs}
            size="m"
        >
            <Link
                href={personTripPath}
                theme="link"
            >
                {tripId}
            </Link>
        </Cell>
    );
};

export const CellTripLink = withTdAttrs(CellTripLinkBase);
CellTripLink.displayName = cn();
