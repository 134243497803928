export const en = {
    role: 'Role',
    access: 'Access right',
    assistant: 'Assistant',
    user: 'Employee',
    coordinator: 'Coordinator',
    activated: 'Opened',
    blocked: 'Closed',
    add_employee: 'Add employee',
};
