export const en = {
    add_document: 'Adding a document',
    cancel: 'Cancel',
    citizenship: 'Citizenship',
    document_type: 'Document type',
    expires_on: 'Valid until',
    fill_if_available: 'if applicable',
    first_name: 'First name',
    issued_on: 'Issue date',
    last_name: 'Last name',
    latin_name_warning:
        'The first and last names are transliterated automatically. You will be able to correct them in your profile to match the writing in the bonus cards.',
    middle_name: 'Patronymic',
    no_middle_name: 'No patronymic',
    number: 'Number',
    save: 'Save',
    series: 'Series',
    something_went_wrong: 'Something went wrong, contact support chat',
};
