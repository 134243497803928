import { compareTwoStrings } from 'string-similarity';

import { transliterate } from 'shared/lib/text/transliterate';
import { ServicesDetailsWithId } from 'types/TripRoute';
import { TripSegment } from 'types/TripSegment';
import { getDatesDiff } from 'utils/date/getDatesDiff';
import { getOrderCity } from 'utils/getOrderCity';
import { getOrderDate } from 'utils/getOrderDate';
import { getSegmentCity } from 'utils/getSegmentCity';
import { normalizeNumbers } from 'utils/math/normalizeNumbers';

import { hasSameTypes } from './hasSameTypes';

type SimilarityParams = {
    segment: TripSegment;
    dateDiff: number;
    citySimilarityRatio: number;
};

type MinMax = [number, number];
type CalculateSimilarityParamsReturnValue = {
    similarityParams: SimilarityParams[];
    dateDiffMinMax: MinMax;
    citySimilarityRatioMinMax: MinMax;
};

const calculateSimilarityParams =
    (segments: TripSegment[], serviceDetails: ServicesDetailsWithId): CalculateSimilarityParamsReturnValue => {
        const serviceStartAt = getOrderDate(serviceDetails);
        const serviceDeparture = getOrderCity(serviceDetails) || '';
        const serviceArrival = getOrderCity(serviceDetails, true) || '';

        const similarityParams: SimilarityParams[] = segments.map(segment => {
            if (!hasSameTypes(segment, serviceDetails)) {
                return;
            }

            const dateDiff = Math.abs(getDatesDiff(serviceStartAt, segment.date_from));

            const segmentDeparture = getSegmentCity(segment).name.ru || '';
            const segmentArrival = getSegmentCity(segment, true).name.ru || '';
            const departureCitySimilarityRatio = compareTwoStrings(
                transliterate(serviceDeparture),
                transliterate(segmentDeparture),
            );
            const arrivalCityDiffSimilarityRatio = compareTwoStrings(
                transliterate(serviceArrival),
                transliterate(segmentArrival),
            );
            const citySimilarityRatio = departureCitySimilarityRatio + arrivalCityDiffSimilarityRatio;

            return { segment, dateDiff, citySimilarityRatio };
        }).filter(Boolean);

        const dateDiff = similarityParams.map(param => param.dateDiff);
        const dateDiffMax = Math.max(...dateDiff);
        const dateDiffMin = Math.min(...dateDiff);

        const citySimilarityRatios = similarityParams.map(param => param.citySimilarityRatio);
        const citySimilarityRatioMin = Math.min(...citySimilarityRatios);
        const citySimilarityRatioMax = Math.max(...citySimilarityRatios);

        return {
            similarityParams,
            dateDiffMinMax: [dateDiffMin, dateDiffMax],
            citySimilarityRatioMinMax: [citySimilarityRatioMin, citySimilarityRatioMax],
        };
    };

type NormalizedSimilarityParams = {
    segment: TripSegment;
    norm: number;
};

const normalizeSimilarityParams = (
    similarityParams: SimilarityParams[],
    dateDiffMinMax: MinMax,
    citySimilarityRatioMinMax: MinMax,
): NormalizedSimilarityParams[] => {
    const normalizedSimilarityParams: NormalizedSimilarityParams[] =
        similarityParams.map(similarityParam => {
            const dateDiffNormalized = 1 - normalizeNumbers(
                similarityParam.dateDiff,
                dateDiffMinMax,
            );
            const citySimilarityRatioNormalized = normalizeNumbers(
                similarityParam.citySimilarityRatio,
                citySimilarityRatioMinMax,
            );

            return {
                segment: similarityParam.segment,
                norm: dateDiffNormalized + 2 * citySimilarityRatioNormalized,
            };
        });

    return normalizedSimilarityParams;
};

const canDefineMostRelevantSegment = (normalizedSimilarityParams: NormalizedSimilarityParams[]) => {
    const [mostRelevant, lessRelevant] = normalizedSimilarityParams;

    if (!mostRelevant) {
        return false;
    }

    if (lessRelevant) {
        return mostRelevant.norm > lessRelevant.norm;
    }

    return true;
};

export const findMostRelevantSegment = (
    segments: TripSegment[],
    serviceDetails: ServicesDetailsWithId,
): TripSegment | null => {
    const {
        similarityParams,
        dateDiffMinMax,
        citySimilarityRatioMinMax,
    } = calculateSimilarityParams(segments, serviceDetails);

    const normalizedSimilaritiesParams = normalizeSimilarityParams(
        similarityParams,
        dateDiffMinMax,
        citySimilarityRatioMinMax,
    );

    const sortedNormalizedSimilaritiesParams = normalizedSimilaritiesParams.sort((a, b) => b.norm - a.norm);

    if (!canDefineMostRelevantSegment(sortedNormalizedSimilaritiesParams)) {
        return null;
    }

    return sortedNormalizedSimilaritiesParams[0].segment;
};
