export const getVersion = () => {
    let version = import.meta.env.VITE_YENV as string;

    if (import.meta.env.VITE_YENV === 'storybook') {
        return 'v0.760.0';
    }

    if (import.meta.env.VITE_PR_NUMBER) {
        version = `pr-${import.meta.env.VITE_PR_NUMBER}`;
    }

    if (import.meta.env.VITE_VERSION) {
        version = `v${import.meta.env.VITE_VERSION}`;
    }

    if (import.meta.env.VITE_B2B) {
        version += '-b2b';
    }

    if (import.meta.env.VITE_YENV === 'admin') {
        version += '-admin';
    }

    return version;
};
