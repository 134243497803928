import { RoomTariff } from 'services/SwaggerApi';

type FindBestPriceTariffsReturn = {
    bestPriceTarrifId: string | null;
    bestPriceWithMealTarrifId: string | null;
};

export const findBestPriceTariffs = (tariffs: RoomTariff[]): FindBestPriceTariffsReturn => {
    const bestPriceId = tariffs.find(tariff => tariff.is_travel_policy_compliant)?.id ?? null;
    const bestPriceWithMealId =
        tariffs.find(tariff => tariff.is_meal_included && tariff.is_travel_policy_compliant)?.id ?? null;

    if (bestPriceId === bestPriceWithMealId) {
        return {
            bestPriceTarrifId: bestPriceId,
            bestPriceWithMealTarrifId: null,
        };
    }

    return {
        bestPriceTarrifId: bestPriceId,
        bestPriceWithMealTarrifId: bestPriceWithMealId,
    };
};
