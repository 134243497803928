export const en = {
    profile: 'Profile',
    documents: 'Documents',
    access: 'Access',
    travel_policy: 'Travel policy',
    group: 'Group',
    settings: 'Settings',
    security: 'Security',
    security_confirmation_subtitle: 'Are you sure you want to revoke the permission to process personal data? Without this permission, you will not be able to use the service',
    permision_to_process_pd: 'Permission to process personal data',
    permision_to_process_pd_description: 'You cannot use the service without this permission',
    documents_description: 'For booking tickets and hotels',
    travel_policy_description: 'Rules for searching and adding services for service users',
    loyalty_cards: 'Loyalty cards',
    loyalty_cards_description: 'You can get bonuses even on business trips',
    add_loyalty_card: 'Add loyalty card',
    companions: 'Companions',
    hide_trip_on_staff: 'Hide business trips on Staff',
    hide_trip_on_staff_description: 'Do not show in geps and on the Staff business trips page',
    security_description: 'Privacy of your data in the service and beyond',
    not_found_error_title: 'The profile does not exist or you do not have access',
    not_found_error_description: 'To get access, contact the coordinator',
    to_the_main_action: 'To the main page',
    banner_text: 'Please check the profile details and confirm that they are correct. If there is an error in the data, you can edit it.',
    banner_accept_text: 'I confirm that the data is correct',
    assistant_title: 'Assistant for',
    assistant_description: 'The assistant has access to the data of these profiles for the organization of business trips',
    director_title: 'Your assistant',
    analytics_data_title: 'Data for trip analytics',
    analytics_data_description: 'To manage employee transactions',
    director_description: 'The assistant has access to the data of this profile for the organization of business trips',
    personnel_number_label: 'Employee\'s personnel number',
    personnel_number_placeholder: 'Enter the number',
    default_cost_center_label: 'Default cost center',
    default_cost_center_addon_text: 'Add cost center',
    submit_button_hint: 'Save data for the employee',
    submit_data_success_notifier: 'Data updated successfully',
    type: 'Type',
    create_group: 'Create a group',
    create_travel_policy: 'Create a travel policy',
    personal_tp: 'Personal',
    group_tp: 'Group',
    not_selected: 'Not selected',
};
