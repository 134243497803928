import { FC } from 'react';

import { IconErrorTriangle } from 'components/Icon';
import { withProps } from 'hocs/withProps';
import { Text } from 'shared/ui/Text';

import { cn } from './AlertAction.cn';
import { AlertActionProps } from './AlertAction.types';

export const AlertAction: FC<AlertActionProps> = props => {
    const TextAlertTag = props.alertAs || 'div';
    const TextAlert = withProps({ as: TextAlertTag, typography: 'body-m', color: 'alert' })(Text);

    return (
        <div className={cn(null, [props.className])}>
            <TextAlert>
                <IconErrorTriangle size="m" /> {props.label}
            </TextAlert>
            {props.children}
        </div>
    );
};

AlertAction.displayName = cn();
