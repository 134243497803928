import { Option } from 'components/SelectExt/SelectExt.types';
import { RoomTariff } from 'services/SwaggerApi';
import { formatCurrency } from 'utils/formatCurrency';
import { formatTime } from 'utils/formatDate';

type GetEiloOptions = (choices: RoomTariff['early_checkin_choices'] | RoomTariff['late_checkout_choices']) => Option[]

export const getEiloOptions: GetEiloOptions = choices => {
    if (!choices || choices.length === 0) return [];

    return choices.map((item, index) => {
        const content = `${formatTime(item.hour)}  ·  ${formatCurrency(item.price)}`;

        return {
            value: index,
            content,
        };
    });
};
