import { NearbyStop } from 'services/SwaggerApi';

export const getNearbyStations = (nearStops?: NearbyStop[]): NearbyStop[] => {
    if (!nearStops) return [];

    return nearStops
        .filter(nearStop => nearStop.type === 'station')
        .sort((prevStop, nextStop) => prevStop.distance.value - nextStop.distance.value)
        .slice(0, 2);
};
