import { FC, memo, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { config } from 'config';
import { getCookie } from 'shared/lib/cookie';

import { uxfbB2BScript } from './uxfbScripts';
import {
    createLocationObject,
    useHistorySubscribe,
    useResizeSubscribe,
    useScrollSubscribe,
} from '.';

import './UXFeedback.css';

const style = { display: 'none' };

export type SendMessageType = (type: string, payload?: string | number | object | undefined) => void;

const { isB2B } = config;
const hrtechAccount = 'https://s3.mds.yandex.net/hr-tech-frontend/uxfeedback/uxfeedback.html';

export const UXFeedback: FC = memo(() => {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const location = useLocation();
    const sendMessage: SendMessageType = useCallback((type, payload) => {
        iframeRef.current?.contentWindow?.postMessage(
            {
                type: `uxfb/${type}`,
                payload,
            },
            '*',
        );
    }, []);

    const sendEvent = useCallback(eventName => sendMessage('event', { event: eventName }), [sendMessage]);
    const sendProperties = useCallback(payload => sendMessage('properties', payload), [sendMessage]);

    const { subscribeScroll, unsubscribeScroll } = useScrollSubscribe(sendMessage);
    const { subscribeHistory } = useHistorySubscribe(sendMessage);
    const { sendResize, subscribeResize } = useResizeSubscribe(sendMessage);

    const ymId = getCookie('_ym_uid');

    const sendCurrentWinInfo = useCallback(() => {
        sendMessage('info', {
            url: document.location.href,
            cookies: '',
            ymId,
            devicePixelRatio: window.devicePixelRatio,
            scrollTop: document.documentElement.scrollTop,
            scrollHeight: document.documentElement.scrollHeight,
        });

        sendResize();
    }, [sendMessage, sendResize, ymId]);

    const setIframePosition = useCallback(height => {
        const clientHeight = window.innerHeight || document.documentElement.clientHeight;
        const parsedHeight = parseInt(height, 10);
        const middle = (clientHeight - parsedHeight) / 2;
        const top = parsedHeight >= clientHeight ? 0 : middle;

        if (iframeRef.current) {
            iframeRef.current.style.transform = '';
            iframeRef.current.style.left = '';
            iframeRef.current.style.right = '';
            iframeRef.current.style.top = top + 'px';
        }
    }, []);

    const setIframeSize = useCallback((width, height) => {
        if (iframeRef.current) {
            if (width) {
                iframeRef.current.style.width = width;

                if (width === '100%') {
                    iframeRef.current.style.left = '0';
                    iframeRef.current.style.right = '0';
                    iframeRef.current.style.top = '0';
                    iframeRef.current.style.transform = '';
                }
            }

            if (height) {
                iframeRef.current.style.height = height;
            }
        }
    }, []);

    const setIframeStyle = useCallback(style => {
        if (iframeRef.current) {
            iframeRef.current.setAttribute('style', style);
        }
    }, []);

    const showWidgetIcon = useCallback(
        (width, height) => {
            if (iframeRef.current) {
                iframeRef.current.setAttribute('width', width);
                iframeRef.current.setAttribute('height', height);
                iframeRef.current.className = '';
                iframeRef.current.style.opacity = '1';
            }

            sendMessage('showWidgetIcon', {});
        },
        [sendMessage],
    );

    const setIframeOpacity = useCallback(opacity => {
        if (iframeRef.current) {
            iframeRef.current.style.opacity = opacity;
        }
    }, []);

    const addClassToIframe = useCallback(className => {
        if (iframeRef.current) {
            iframeRef.current.classList.add(className);
        }
    }, []);

    const handleWindowMessage = useCallback(
        (e: MessageEvent) => {
            const { type = '', payload } = e.data;

            const [prefix, name] = type.split('/');

            if (prefix !== 'uxfb') {
                return;
            }

            switch (name) {
                case 'style':
                    return setIframeStyle(payload);

                case 'image':
                    return showWidgetIcon(payload.width, payload.height);

                case 'opacity':
                    setIframeOpacity(payload);

                    return;

                case 'class':
                    addClassToIframe('uxs-slide-back');
                    addClassToIframe(payload);

                    return;

                case 'size':
                    return setIframeSize(payload.width, payload.height);

                case 'position':
                    return setIframePosition(payload.height);

                case 'listener':
                    sendCurrentWinInfo();
                    subscribeResize();

                    return;

                case 'event':
                    return sendEvent(payload.eventName);

                case 'properties':
                    return sendProperties(payload);

                case 'locationListener':
                    return subscribeHistory();

                case 'addScroll':
                    return subscribeScroll();

                case 'removeScroll':
                    return unsubscribeScroll();
            }
        },
        [
            addClassToIframe,
            subscribeScroll,
            unsubscribeScroll,
            subscribeHistory,
            subscribeResize,
            sendCurrentWinInfo,
            setIframeSize,
            setIframePosition,
            setIframeOpacity,
            showWidgetIcon,
            setIframeStyle,
            sendProperties,
            sendEvent,
        ],
    );

    useEffect(() => {
        window.addEventListener('message', handleWindowMessage);

        return () => window.removeEventListener('message', handleWindowMessage);
    }, [handleWindowMessage]);

    useEffect(() => {
        sendMessage('history', {
            oldLocation: JSON.stringify(window.location),
            location: JSON.stringify(createLocationObject(location.pathname)),
        });
    }, [sendMessage, location]);

    if (typeof window === 'undefined') {
        return null;
    }

    return (
        <iframe
            ref={iframeRef}
            frameBorder="0"
            id="uxs_iframe"
            sandbox="allow-scripts allow-same-origin allow-forms allow-popups"
            src={isB2B ? undefined : hrtechAccount}
            srcDoc={isB2B ? uxfbB2BScript : undefined}
            style={style}
        />
    );
});
