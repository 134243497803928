import { FC } from 'react';

import { TagHint } from 'components/TagHint';
import { i18nTravelPolicy } from 'i18n/i18nTravelPolicy';
import { Tag } from 'shared/ui/Tag';
import { Text } from 'shared/ui/Text';

import { cn } from '../HotelRoom.cn';
import { i18n } from '../HotelRoom.i18n';

import { TariffTagsProps } from './TariffTags.types';

import './TariffTags.css';

export const TariffTags: FC<TariffTagsProps> = props => {
    const {
        className,
        isTravelPolicyCompliant,
        travelPolicyViolations,
        isBestPrice,
        isBestPriceWithMeal,
        isBookingByRequest,
    } = props;

    const travelPolicyViolationsContent = travelPolicyViolations.length > 0 ? (
        <Text typography="body-s">{travelPolicyViolations.join('\n')}</Text>
    ) : null;

    return (
        <div className={cn('TariffTags', [className])}>
            {
                !isTravelPolicyCompliant && (
                    <TagHint
                        inverse
                        color="red"
                        desktopTrigger="hover"
                        hintContent={travelPolicyViolationsContent}
                        tagText={i18nTravelPolicy('not_compliant_short')}
                    />
                )
            }
            {
                isBookingByRequest && (
                    <TagHint
                        inverse
                        color="red"
                        desktopTrigger="hover"
                        hintContent={<Text typography="body-s">{i18n('room_on_request_hint')}</Text>}
                        tagText={i18n('room_on_request')}
                    />
                )
            }
            {
                isBestPrice && (
                    <Tag
                        rounded
                        color="purple"
                        maxWidth="full"
                        size="m"
                        text={i18n('best_price')}
                    />
                )
            }
            {
                isBestPriceWithMeal && (
                    <Tag
                        rounded
                        color="purple"
                        maxWidth="full"
                        size="m"
                        text={i18n('best_price_with_meal')}
                    />
                )
            }
        </div>
    );
};
