import { Person, ServiceCreate } from 'services/SwaggerApi';

import { TrainCarriageProps } from '../TrainDetails.types';

type AddServicePayload = {
    tripPersonId: string;
    props: TrainCarriageProps;
    personId: Person['person_id'];
    seatNumber: number;
    tripId: number;
    isExternal: boolean | undefined;
};

export const serializeAddServicePayload = (params: AddServicePayload): ServiceCreate => {
    const {
        props,
        personId,
        seatNumber,
        tripId,
        tripPersonId,
        isExternal,
    } = params;

    const trainId = props.trainDetails.train.id;
    const carriageId = props.carriage.id;

    return {
        search_id: String(props.params.searchId),
        trip_id: tripId,
        type: 'rail',
        provider_item_id: trainId,
        provider_detail_id: carriageId,
        person_id: isExternal ? tripPersonId : personId,
        ...(isExternal && { ext_person_uuids: [personId] }),
        ...(seatNumber && { seat_number: seatNumber }),
        ...(!props.carriage.is_travel_policy_compliant && { overprice_workflow: 'extra_payment' }),
    };
};
