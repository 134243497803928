import { FC } from 'react';
import { getI18nLang } from '@yandex-int/i18n';
import { User } from '@yandex-lego/user';
import { useUnit } from 'effector-react';

import { config } from 'config';
import { isIOS } from 'platform/utils';
import { $theme } from 'shared/models/theme';
import { ThemeName } from 'types/Theme';

import { cn } from '../Header.cn';

import { UserIdProps } from './UserId.types';

import './UserId.css';

export const UserId: FC<UserIdProps> = () => {
    const theme = useUnit<ThemeName>($theme);

    if (isIOS()) return null;

    const lang = getI18nLang();

    const env = config.env === 'production' ? 'prod' : 'test';

    return (
        <User
            fetchMail
            fetchUserData
            className={cn('UserId')}
            env={env}
            lang={lang}
            theme={theme}
        />
    );
};
