export const ru = {
    action_activate: 'Выдать доступ',
    action_block: 'Закрыть доступ',
    action_change_approver: 'Изменить согласующего',
    action_change_company: 'Изменить компанию',
    action_grant_coordinator: 'Назначить координатором',
    action_assign_assistant: 'Назначить ассистента',
    action_remove_assistant: 'Удалить ассистента',
    action_reject: 'Отклонить',
    action_restore: 'Восстановить доступ',
    action_revoke_coordinator: 'Отозвать роль координатора',
    action_add_employee: 'Добавить сотрудника',
    approver_header: 'Согласующий',
    email_header: 'Почта',
    name_header: 'ФИО',
    role_header: 'Роль',
    role_value_user: 'Сотрудник',
    role_value_coordinator: 'Координатор',
    role_value_assistant: 'Ассистент',
    status_header: 'Права доступа',
    status_value_wait_activation: 'Ожидает модерации',
    status_value_activated: 'Открыт',
    status_value_blocked: 'Доступ закрыт',
    status_value_rejected: 'Модерация не пройдена',
    hint_goto_profile: 'Перейти в профиль пользователя',
};
