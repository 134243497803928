import dayjs from 'dayjs';

import { ImpossibleError, UnknownError } from 'errors';
import { PersonTrip, Service } from 'services/SwaggerApi';
import { ServicesDetailsWithId } from 'types/TripRoute';
import {
    SegmentEvent,
    SegmentEventGroup,
    TripSegment,
} from 'types/TripSegment';
import { datesEqual } from 'utils/date/datesEqual';
import { getOrderDate } from 'utils/getOrderDate';
import { logError } from 'utils/logError';

import { findMostRelevantSegment } from './findMostRelevantSegment';
import { generateEvents } from './generateEvents';
import { getSegmentsList } from './getSegmentsList';

export type GenerateSegmentsOptions = {
    personTrip: PersonTrip;
    services: ServicesDetailsWithId[];
};

const findService = (personTrip: PersonTrip, serviceId: number) =>
    personTrip.services.find(service =>
        service.service_id === serviceId,
    );

type GenerateSegmentsResult = {
    segments: TripSegment[];
    restServices: Service[];
    grouppedEvents: SegmentEventGroup[];
};

export const generateSegments = (options: GenerateSegmentsOptions): GenerateSegmentsResult => {
    const { personTrip, services } = options;
    const { route } = personTrip;

    let segments: TripSegment[] = [];
    const restServices: Service[] = [];
    const grouppedEvents: SegmentEventGroup[] = [];

    // если роута нет, то и услуг быть не должно
    if (!route || !route.length) {
        return {
            segments,
            restServices,
            grouppedEvents,
        };
    }

    if (route.length === 1) {
        throw new ImpossibleError('Route must have at least 2 points');
    }

    segments = getSegmentsList(route);

    services
        .sort((serviceA, serviceB) => {
            const serviceAStartAt = getOrderDate(serviceA);
            const serviceBStartAt = getOrderDate(serviceB);

            return new Date(serviceAStartAt).valueOf() - new Date(serviceBStartAt).valueOf();
        })
        .forEach(serviceDetails => {
            const service = findService(personTrip, serviceDetails.service_id);

            if (!service) {
                return;
            }

            const mostRelevantSegment = findMostRelevantSegment(segments, serviceDetails);

            if (!mostRelevantSegment) {
                restServices.push(service);

                return;
            }

            mostRelevantSegment.services.push(service);
            mostRelevantSegment.events = generateEvents(serviceDetails);
        });

    // группируем события по дате и городу
    let nextEvent: SegmentEvent;

    segments
        .flatMap(segment => segment.events)
        .forEach(event => {
            const lastGroup: SegmentEventGroup = grouppedEvents[grouppedEvents.length - 1];

            // на самом деле активное подсвечивать не понятно как, для этого должны быть не точки, а отрезки
            // пока подсвечиваем следующее
            if (!nextEvent && dayjs() < event.date) {
                nextEvent = event;
                nextEvent.isActive = true;
            }

            if (lastGroup && datesEqual(event.date, lastGroup.date) && event.city === lastGroup.city) {
                lastGroup.events.push(event);
            } else {
                grouppedEvents.push({
                    city: event.city,
                    date: event.date,
                    events: [event],
                });
            }
        });

    if (restServices.length) {
        // если услуги попадают в restServices, надо отправить
        // какой-то сигнал в error booster
        logError(new UnknownError('Часть услуг оказалась в restServices'), {
            restServices: restServices
                .map(({ trip_id, person_id, service_id }) => ({ trip_id, person_id, service_id })),
        });
    }

    return {
        segments,
        restServices,
        grouppedEvents,
    };
};
