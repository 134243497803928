import { ComponentProps, FC, ReactElement } from 'react';
import { withBemMod } from '@bem-react/core';

import { Checkbox } from 'shared/ui/Checkbox';

import { cn } from '../FormField.cn';
import { FormFieldProps as BaseProps } from '../FormField.types';

import './FormField_type_checkbox.css';

export interface WithTypeCheckboxProps {
    type?: 'checkbox';
    onChange?: ComponentProps<typeof Checkbox>['onChange'];
    onBlur?: ComponentProps<typeof Checkbox>['onBlur'];
    value?: ComponentProps<typeof Checkbox>['checked'];
    state?: 'error';
    name?: string;
    hint?: ReactElement;
}

export const withTypeCheckbox = withBemMod<WithTypeCheckboxProps, BaseProps>(
    cn(),
    { type: 'checkbox' },
    (Base): FC<BaseProps & WithTypeCheckboxProps> => (props): ReactElement => {
        const {
            onChange,
            onBlur,
            value,
            title,
            required,
            state,
            name,
            hint,
        } = props;
        const checkboxElement = (
            <Checkbox
                checked={value}
                className={cn('Control', { state, name })}
                label={<>{title} {required && <span className={cn('Asteriks')} />} </>}
                lines="multi"
                required={required}
                size="m"
                view="outline"
                onBlur={onBlur}
                onChange={onChange}
            />);
        const replacer = hint ? (
            <div className={cn('CheckboxWrapper')}>
                {checkboxElement}
                <div className={cn('CheckboxHint')}>
                    {hint}
                </div>
            </div>
        ) : checkboxElement;

        return (
            <Base
                { ...props }
                replacer={replacer}
            />
        );
    },
);
