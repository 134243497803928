export const en = {
    compartment: '2d class',
    compartment_female: 'female',
    compartment_male: 'male',
    compartment_mixed: 'mixed',
    compartment_single: '',
    schema_loading: 'Loading car layout',
    schema_not_available: 'Car layout not available',
    train_direction: 'Train moves this way',
};
