import { FieldInputProps, FieldRenderProps } from 'react-final-form';
import { compose, composeU } from '@bem-react/core';

import { SettlementSuggestItem } from 'services/SwaggerApi';

import { withTypeCheckbox } from './_type/FormField_type_checkbox';
import { withTypeDatePicker } from './_type/FormField_type_datepicker';
import { withTypeDestination } from './_type/FormField_type_destination';
import { withTypePerson } from './_type/FormField_type_person';
import { withTypeRadioToggle, WithTypeRadioToggleProps } from './_type/FormField_type_radioToggle';
import { withTypeRangePicker } from './_type/FormField_type_rangepicker';
import { withTypeSelect } from './_type/FormField_type_select';
import { withTypeTextarea } from './_type/FormField_type_textarea';
import { withTypeTextinput } from './_type/FormField_type_textinput';
import { WithTypeSelectProps } from './_type/WithTypeSelectProps';
import { FormField as FormFieldBase } from './FormField';

const FormField = compose(
    composeU(
        withTypeCheckbox,
        withTypeSelect,
        withTypeDatePicker,
        withTypeRangePicker,
        withTypeDestination,
        withTypeTextarea,
        withTypeTextinput,
        withTypePerson,
        withTypeRadioToggle,
    ),
)(FormFieldBase);

type FieldType = Exclude<Parameters<typeof FormField>[0]['type'], undefined>;
type GetValue<T> = (input: FieldInputProps<T>, props: Record<string, unknown>) => T;

function createFieldProvider<T>(type: FieldType, getValue: GetValue<T> = input => input.value) {
    return ({ meta, input, ...props }: FieldRenderProps<T>) => {
        let state: Maybe<'error'>;
        let error: Maybe<string>;

        if (meta.touched && meta.error || (!meta.modifiedSinceLastSubmit && meta.submitError)) {
            state = 'error';
            error = meta.error || meta.submitError;
        }

        return (
            <FormField
                { ...props }
                error={error}
                name={input.name}
                state={state}
                type={type}
                value={getValue(input, props)}
                onBlur={input.onBlur}
                onChange={input.onChange}
            />
        );
    };
}

export const FieldCheckbox = createFieldProvider<boolean>('checkbox', input => Boolean(input.checked));
export const FieldRadioToggle = createFieldProvider<WithTypeRadioToggleProps['value']>('radioToggle');

type FieldSelectValue = WithTypeSelectProps['value'];

const fieldSelectGetValue: GetValue<FieldSelectValue> = (input, { valueOC = [] }) => {
    // valueOC — "value outside controlled", когда состояние селекта хранится во вне
    if ((valueOC as FieldSelectValue).length > 0) return valueOC;

    return input.value;
};

export const FieldSelect = createFieldProvider<FieldSelectValue>('select', fieldSelectGetValue);

export const FieldTextarea = createFieldProvider<string>('textarea');

export const FieldTextinput = createFieldProvider<string>('textinput');

export const FieldDestination = createFieldProvider<SettlementSuggestItem>('destination');

export const FieldRangeDatePicker = createFieldProvider<string>('rangepicker');

export const FieldDatePicker = createFieldProvider<string>('datepicker');

export const FieldPerson = createFieldProvider<Array<string>>('person');
