import { FC, useCallback, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { HostsContextProvider } from '@yandex-int/hr-components/Context/HostsContext';
import { Hosts } from '@yandex-int/hr-components/utils/types';
import { useUnit } from 'effector-react';

import { CurrentCompanyContext } from 'components/CurrentCompanyProvider/CurrentCompanyProvider.context';
import { config } from 'config';
import { useMeta } from 'hooks/useMeta';
import { usePlatform } from 'hooks/usePlatform';
import { $theme, changeThemeEvent } from 'shared/models/theme';
import { MenuType, PageHeader } from 'shared/ui/PageHeader';

import { getActions } from './utils/getActions';
import { getMenuItems } from './utils/getMenuItems';
import { getPopupActions } from './utils/getPopupActions';
import { ActionButton } from './ActionButton';
import { cn } from './Header.cn';
import { i18n } from './Header.i18n';
import { HeaderProps } from './Header.types';
import { HeaderLogo } from './HeaderLogo';
import { HeaderServices } from './Services';

import './Header.css';

const { hosts: tripHosts, isB2B } = config;

const hosts: Hosts = {
    staff: tripHosts.staff,
    center: tripHosts.center,
};

export const Header: FC<HeaderProps> = props => {
    const {
        activePage,
        isAdminPanel,
        className,
    } = props;

    const meta = useMeta();
    const {
        user: {
            is_allowed_select_company,
            login,
            avatar_id,
        },
    } = meta;
    const isCompanySuggestVisible = Boolean(isAdminPanel && is_allowed_select_company);

    const { platform, isMobile } = usePlatform();

    const { currentCompanyId } = useContext(CurrentCompanyContext);

    const menuItems = useMemo(() =>
        getMenuItems({
            currentCompanyId,
            user: meta.user,
            currentPage: activePage,
        })
    , [activePage, currentCompanyId, meta.user]);
    const services = (!isMobile && !isB2B) ? <HeaderServices /> : null;
    const popupActions = getPopupActions(login, platform);
    const actions = getActions({ isMobile, isB2B, avatarId: avatar_id, isCompanySuggestVisible });

    const history = useHistory();
    const handleMenuItemClick = useCallback((e, item) => {
        const comboKey = e.metaKey || e.altKey || e.ctrlKey || e.shiftKey;

        if (!comboKey) {
            e.preventDefault();
            history.push(item.url);
        }
    }, [history]);

    const themeName = useUnit($theme);

    const changeTheme = useUnit(changeThemeEvent);

    const handleToggleTheme = useCallback(() => {
        changeTheme(themeName === 'dark' ? 'light' : 'dark');
    }, [changeTheme, themeName]);

    return (
        <HostsContextProvider hosts={hosts}>
            <PageHeader
                notificationBellIncluded
                actionButton={<ActionButton />}
                actions={actions}
                className={cn(null, [className])}
                desktopLogo={<HeaderLogo />}
                items={menuItems}
                labelMore={i18n('label_more')}
                labelServices={i18n('label_services')}
                login={isB2B ? undefined : meta.user.login}
                menuType={isMobile ? MenuType.BURGER : MenuType.TABS}
                mobileLogo={<HeaderLogo />}
                platform={platform}
                popupActions={popupActions}
                services={services}
                theme={themeName}
                onMenuItemClick={handleMenuItemClick}
                onToggleTheme={handleToggleTheme}
            />
        </HostsContextProvider>
    );
};

Header.displayName = cn();
