import { ServiceDetails } from 'types/TripRoute';

export const getOrderDate = (order: ServiceDetails): string => {
    switch (order.service_type) {
        case 'avia':
            const legs = order.flight.legs;

            if (!legs?.length) {
                return '';
            }

            return legs[0].segments[0].departure_at;

        case 'rail':
            return order.departure_at;

        case 'hotel':
            return order.start_at;
    }
};
