import { FC, useCallback } from 'react';
import { useToggle } from 'react-use';
import dayjs from 'dayjs';

import { Confirm } from 'components/Confirm';
import { getIconProvider, IconPlus, IconSearch } from 'components/Icon';
import { ServiceOrder } from 'components/ServiceOrder';
import { withPropsV2 } from 'hocs/withPropsV2';
import { SearchDetails, useCreateSearch } from 'hooks/useCreateSearchId';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { compactDates, DateFormat, formatDate } from 'utils/formatDate';
import { getTranslation } from 'utils/getTranslation';

import { SegmentNoServicesHotel } from '../SegmentNoServices/SegmentNoServicesHotel';
import { SegmentPlaceholderHotel } from '../SegmentPlaceholder/SegmentPlaceholderHotel';
import { cn } from '../TripRouteSegment.cn';
import { SegmentHotelProps } from '../TripRouteSegment.types';
import { i18n } from '../TripRouteSegment.yt.i18n';

const Dates = withPropsV2(Text, {
    weight: 'regular',
});

const iconProviderPlus = getIconProvider(IconPlus);
const iconProviderSearch = getIconProvider(IconSearch);

export const SegmentHotel: FC<SegmentHotelProps> = props => {
    const {
        segment,
        trip_id,
        person_id,
        showSearchButtons,
        showCitiesAsTitle,
        canAddServices,
    } = props;
    const {
        type,
        city,
        date_from,
        date_to,
        need_hotel,
    } = segment;

    const { isSearchLoading, createSearch } = useCreateSearch();

    const [confirmationVisible, setConfirmationVisible] = useToggle(false);
    const handleConfirmAccept = useCallback(
        () => setConfirmationVisible(false),
        [setConfirmationVisible],
    );
    const routeDates = compactDates(date_from, date_to);

    const isCheckoutDateInPast = dayjs().isAfter(date_to, 'day');

    const searchHotel = useCallback(() => {
        if (isCheckoutDateInPast) {
            return setConfirmationVisible(true);
        }

        const { to_id, date_from, date_to, country } = segment;

        const isCheckInDateInPast = dayjs().isAfter(date_from, 'day');
        const checkInDate = isCheckInDateInPast ?
            formatDate(dayjs(), DateFormat.YYYY_MM_DD) :
            date_from;

        const isOneDayStand = dayjs(date_to).diff(checkInDate, 'day') < 1;
        const checkoutDate = isOneDayStand ?
            formatDate(dayjs(date_to).add(1, 'day'), DateFormat.YYYY_MM_DD) :
            date_to;

        const searchDetailsSerialized: SearchDetails = {
            to_geoid: to_id ?? undefined,
            type: 'Hotel',
            departure_on: checkInDate,
            departure_back_on: checkoutDate,
            person_id,
            trip_id,
            toCountryName: country.name.ru || country.name.en || '',
        };

        createSearch(searchDetailsSerialized);
    }, [createSearch, isCheckoutDateInPast, person_id, segment, setConfirmationVisible, trip_id]);

    const { isMobile } = usePlatform();
    const size = isMobile ? 'm' : 's';

    const hasServices = Boolean(segment.services.length);
    const showPlaceholder = !hasServices && need_hotel;
    const showNoServices = !hasServices && !need_hotel;

    const headlineText = showCitiesAsTitle ?
        <>{getTranslation(city.name, { fallbackToTranslit: true })}, <Dates>{routeDates}</Dates></> :
        i18n('accommodation');

    const actionButtons = (
        <Button
            className={cn('ActionButton', [cn('SearchHotel')])}
            iconLeft={canAddServices ? iconProviderPlus : iconProviderSearch}
            progress={isSearchLoading}
            size={size}
            view="contrast"
            onClick={searchHotel}
        >
            {i18n('add_hotel')}
        </Button>
    );

    return (
        <div className={cn({ type }, [props.className])}>
            <div className={cn('Headline')}>
                <Text typography="title-m">
                    {headlineText}
                </Text>
            </div>

            {showNoServices &&
                <SegmentNoServicesHotel {...props} />
            }
            {showPlaceholder &&
                <SegmentPlaceholderHotel {...props} actionButtons={actionButtons} />
            }
            {hasServices &&
                <>
                    {segment.services.map(service => (<ServiceOrder key={service.service_id} service={service} />))}
                    {showSearchButtons && <div className={cn('BeneathActions')}>{actionButtons}</div>}
                </>
            }

            <Confirm
                className={cn('ConfirmAccept')}
                confirmText={i18n('good')}
                title={i18n('fix_application_date')}
                visible={confirmationVisible}
                onConfirm={handleConfirmAccept}
            />
        </div>
    );
};

SegmentHotel.displayName = cn('Hotel');
