import { useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';

import { usePlatform } from 'hooks/usePlatform';

type UseWatchMonthScrollProps = {
   setActiveMonth: (month: number) => void;
   isPopupVisible: boolean;
}

const visibleMonthCount = 13;
const weekdaysHeight = 52;

const months = new Array(visibleMonthCount).fill(null).map((_, index) => ({ id: `active-month-${index}` }));

/**
 * Отслеживает скролл дейтпикера для отображения видимого месяца как активного
 */
export const useWatchMonthScroll = (props: UseWatchMonthScrollProps) => {
    const { setActiveMonth, isPopupVisible } = props;
    const [isScrolling, setIsScrolling] = useState(false);
    const { isMobile } = usePlatform();

    const datePickerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isMobile || !isPopupVisible) {
            return;
        }

        const container = datePickerRef.current;

        const handleScroll = throttle(() => {
            if (!container || isScrolling) {
                return;
            }

            const monthIndex = months.findLastIndex(item => {
                const itemElement = container.querySelector<HTMLElement>(`#${item.id}`);

                const isMonthOnTheUpperVisibleHalf = itemElement && (container.scrollTop + weekdaysHeight) +
                    container.clientHeight / 2 > itemElement.offsetTop;

                if (isMonthOnTheUpperVisibleHalf) {
                    return true;
                }
            });

            const safeMonth = monthIndex === -1 ? 0 : monthIndex;

            setActiveMonth(safeMonth);
        }, 100, { trailing: true });

        container?.addEventListener('scroll', handleScroll);

        return () => container?.removeEventListener('scroll', handleScroll);
    }, [isMobile, isPopupVisible, isScrolling, setActiveMonth]);

    return { setIsScrolling, datePickerRef };
};
