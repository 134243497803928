import { FC, ReactElement } from 'react';

import { IconProps } from './Icon.types';

export type IconProvider = (className: string) => ReactElement<IconProps>;
type GetIconProvider = (Icon: FC<IconProps>, props?: IconProps) => IconProvider;

/**
 * Создаёт функцию-провайдер иконок (IconProvider)
 * @param Icon компонент иконки
 * @param props дополнительные props для иконки
 */
export const getIconProvider: GetIconProvider = (Icon, props = {}) => {
    // Иконка без заданного size увеличивается в размерах, поэтому задаём размер по умолчанию
    const iconProps = {
        size: 'm',
        ...props,
    };

    return (className: string) => <Icon className={className} {...iconProps} sizeManagement="parent" />;
};
