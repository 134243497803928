import { ComponentType, ReactNode, useMemo } from 'react';

type UseArrayWithReactNode = <T = object>(
    length: number,
    Component: ComponentType<T>,
    props: T,
) => ReactNode[];

export const useArrayWithReactNode: UseArrayWithReactNode = (length, Component, props) => {
    return useMemo(() => {
        const result: React.ReactElement[] = [];

        for (let i = 0; i < length; i++) {
            result.push(
                <Component
                    key={i}
                    {...props}
                />,
            );
        }

        return result;
    }, [Component, length, props]);
};
