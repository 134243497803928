import { PaginatedResponse_CostCenter_ } from 'services/SwaggerApi';

import { i18n } from '../components/TransactionForm/TransactionForm.i18n';

export const getCostCenterOptions = (costCenterList: PaginatedResponse_CostCenter_) => {
    const options = costCenterList.data.map(costCenter => ({
        value: costCenter.cost_center_id,
        content: costCenter.name,
    }));

    const notSelectedOption = {
        value: 0,
        content: i18n('cost_center_not_specified'),
    };

    return [notSelectedOption, ...options];
};
