import { FC } from 'react';
import dayjs from 'dayjs';

import { ListItem } from 'shared/ui/ListItem';
import { formatCurrency } from 'utils/formatCurrency';

import { cn } from '../HotelRoom.cn';
import { i18n } from '../HotelRoom.i18n';

import { CancellationRulesListProps } from './CancellationRulesList.types';

export const CancellationRulesList: FC<CancellationRulesListProps> = props => {
    const {
        penaltyRules,
        noPenaltyRule,
    } = props;

    const withoutPenaltyList = Boolean(noPenaltyRule) && (
        <div className={cn('CancellationRules', { penalty: 'no' })}>
            <ListItem
                key={noPenaltyRule.penalty}
                expanded
                noVerticalPadding
                transparent
                className={cn('CancellationRuleItem')}
                leadingTextParams={{
                    label: i18n('cancellation_without_penalty'),
                    caption: noPenaltyRule.date_to ?
                        i18n('to', { date: dayjs(noPenaltyRule.date_to).format('D MMMM') }) : '',
                    inverse: true,
                }}
            />
        </div>
    );

    const withPenltyList = penaltyRules.length > 0 && (
        <div className={cn('CancellationRules', { penalty: 'yes' })}>
            {
                penaltyRules.map(rule => {
                    const {
                        date_from,
                        date_to,
                        penalty,
                    } = rule;
                    const hasDateInfo = date_from && date_to;

                    return (
                        <ListItem
                            key={penalty}
                            expanded
                            noVerticalPadding
                            transparent
                            className={cn('CancellationRuleItem')}
                            leadingTextParams={{
                                label: i18n('penalty', { penalty: formatCurrency(penalty) }),
                                caption: hasDateInfo && i18n('from_to', {
                                    dateFrom: dayjs(date_from).format('D MMMM'),
                                    dateTo: dayjs(date_to).format('D MMMM'),
                                }),
                                inverse: true,
                            }}
                        />
                    );
                })
            }
        </div>
    );

    return (
        <div className={cn('CancellationRulesList')}>
            {withoutPenaltyList}
            {withPenltyList}
        </div>
    );
};
