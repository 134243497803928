import { i18n } from './i18n';

export function validateRequiredField<T>(value: T) {
    const isEmptyArray = Array.isArray(value) && (value as unknown as Array<unknown>).length === 0;
    const isEmptyString = typeof value === 'string' && value.trim().length === 0;
    const isUndefined = value === undefined;
    const isNull = value === null;
    const isFalse = typeof value === 'boolean' && value === false;

    if (isEmptyArray || isEmptyString || isUndefined || isNull || isFalse) {
        return i18n('error_required');
    }
}
