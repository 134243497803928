import { FC } from 'react';
import { isFirefox } from 'react-device-detect';

import { MessengerWidget } from 'components/MessengerWidget';
import { SupportChatLink } from 'components/SupportChatLink';
import { config } from 'config';
import { isIOS } from 'platform/utils';

import { cn } from './ChatLink.cn';
import { ChatLinkProps } from './ChatLink.types';

import './ChatLink.css';

export const ChatLink: FC<ChatLinkProps> = props => {
    const { chatId } = props;

    const { isB2B } = config;

    const shouldOpenInNewTab = (isIOS() || (isFirefox && !isB2B));

    return (
        shouldOpenInNewTab ? (
            <SupportChatLink
                firstLetterUppercase
                hasIcon
                className={cn('Link')}
                size={isIOS() ? 'compact' : 'full'}
                theme="text"
            />
        ) : (
            <MessengerWidget
                chatId={chatId}
            />
        )
    );
};

ChatLink.displayName = cn();
