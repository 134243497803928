import { useQueryClient } from 'react-query';

import { MetaResponse } from 'services/SwaggerApi';

export const useMeta = (): MetaResponse => {
    const queryClient = useQueryClient();
    // meta получаем один раз при старте приложения в Root.tsx
    const meta = queryClient.getQueryData(['auth_meta']) as MetaResponse;

    return meta;
};
