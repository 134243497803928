export const ru = {
    add_document: 'Добавление документа',
    cancel: 'Отменить',
    citizenship: 'Гражданство',
    document_type: 'Тип документа',
    expires_on: 'Годен до',
    fill_if_available: 'заполните, если есть',
    first_name: 'Имя',
    issued_on: 'Дата выдачи',
    last_name: 'Фамилия',
    latin_name_warning:
        'Имя и фамилия транслитерируются автоматически. Вы сможете поправить их в профиле, чтобы совпало с написанием в бонусных картах.',
    middle_name: 'Отчество',
    no_middle_name: 'Нет отчества',
    number: 'Номер',
    save: 'Сохранить',
    series: 'Серия',
    something_went_wrong: 'Что-то пошло не так, обратитесь в чат поддержки',
};
