import { FC, useCallback } from 'react';
import { compose, composeU } from '@bem-react/core';
import { Registry, withRegistry } from '@bem-react/di';
import {
    cnToolsSuggest,
    Item,
    ToolsSuggest as ToolsSuggestBase,
    withSizeM as toolsSuggestWithSizeM,
    withSizeS as toolsSuggestWithSizeS,
    withViewDefault as toolsSuggestWithViewDefault,
} from '@yandex-int/tools-components/ToolsSuggest/desktop';
import { Textinput } from '@yandex-lego/components/Textinput/desktop/bundle';

import { Popup } from 'shared/ui/Popup';
import { Text } from 'shared/ui/Text';

import { cn } from './Destination.cn';
import { i18n } from './Destination.i18n';
import { DestinationProps, DestinationSuggestItem } from './Destination.types';

const toolsSuggestRegistry = new Registry({ id: cnToolsSuggest() });

toolsSuggestRegistry.set('Textinput', Textinput);
toolsSuggestRegistry.set('Popup', Popup);

const ToolsSuggest = compose(
    toolsSuggestWithViewDefault,
    composeU(
        toolsSuggestWithSizeM,
        toolsSuggestWithSizeS,
    ),
)(withRegistry(toolsSuggestRegistry)(ToolsSuggestBase));

const renderChoice = (choice: DestinationSuggestItem, uniqPopupId?: string) => (
    <div data-id={uniqPopupId} role="menuitem">
        <Text typography="body-s">
            {choice.value.name}
            {', '}
            <Text color="secondary">
                {choice.value.country}
            </Text>
        </Text>
    </div>
);

export const Destination: FC<DestinationProps> = ({
    opened,
    input,
    onInputChange,
    loading,
    searchResults,
    className,
    onChange,
    picked,
    onPick,
    onBlur,
    placeholder,
    pin = 'round-round',
    state,
    uniqPopupId,
    disabled,
    hasClear = true,
}) => {
    const onChosenChange = useCallback((chosenFromSuggestUntyped: Array<Item>) => {
        const chosenFromSuggest = chosenFromSuggestUntyped as DestinationSuggestItem[];

        onChange(chosenFromSuggest);
    }, [onChange]);

    const handleRenderChoice = useCallback(choice => {
        return renderChoice(choice, uniqPopupId);
    }, [uniqPopupId]);

    return (
        <ToolsSuggest
            noArrow
            autoComplete="new-password"
            choices={searchResults}
            className={cn({ type: 'city', hideChosen: true }, [className])}
            disabled={disabled}
            empty={!loading && i18n('nothing_found')}
            hasClear={hasClear}
            loading={loading}
            opened={opened}
            picked={picked}
            pin={pin}
            placeholder={placeholder}
            popupProps={{ keepMounted: false }}
            renderChoice={handleRenderChoice}
            size="m"
            state={state}
            theme="normal"
            value={input}
            view="default"
            onBlur={onBlur}
            onChosenChange={onChosenChange}
            onPickedChange={onPick as any}
            onValueChange={onInputChange}
        />
    );
};
