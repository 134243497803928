import { FC, useReducer } from 'react';
import { useToggle } from 'react-use';

import { Card } from 'components/Card';
import { FloatingIsland } from 'components/FloatingIsland';
import { HeaderPage } from 'components/HeaderPage';
import { Passengers } from 'components/HotelDetails/Passengers';
import { servicesAddReducer } from 'components/HotelDetails/utils/serviceAddReducer';
import { HotelRoom } from 'components/HotelRoom';
import { IconArrowShortRight, IconGroup } from 'components/Icon';
import { Page } from 'components/Page';
import { withRemote } from 'hocs/withRemote';
import { useHotelSearchDetailsWithGrouping } from 'hooks/useHotelSearchDetailsWithGrouping';
import { usePersonTrip } from 'hooks/usePersonTrip';
import { useTrip } from 'hooks/useTrip';
import { HotelDetailWithTariffsResponse, PersonTrip, Trip } from 'services/SwaggerApi';
import { Container } from 'shared/ui/Container';
import { Drawer } from 'shared/ui/Drawer';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';

import { cn } from './PageHotelRooms.cn';
import { i18n } from './PageHotelRooms.i18n';
import { PageHotelRoomsProps } from './PageHotelRooms.types';

import './PageHotelRooms.css';

type PageHotelRoomsRemote = {
    hotelDetails: HotelDetailWithTariffsResponse;
    trip: Trip;
    personId: string;
    tripId?: number;
    searchId: string;
    adult?: number;
    personTrip?: PersonTrip
};

export const PageHotelRoomsRenderer: FC<PageHotelRoomsRemote> = props => {
    const {
        personId,
        searchId,
        trip,
        hotelDetails,
        adult,
        personTrip,
    } = props;

    const canAddServices = personTrip ? personTrip.is_approved : false;
    const { data, hotel } = hotelDetails;
    const { num_of_nights, hotel_name } = hotel;

    const [addedServices, dispatchService] = useReducer(servicesAddReducer, []);

    const [isServicesDrawerVisible, toggleServicesDrawerVisible] = useToggle(false);

    const totalPrice = addedServices.reduce((acc, service) => acc + (service.price || 0), 0);

    return (
        <>
            <div className={cn('RoomsList')}>
                {data.map(room => (
                    <Card key={room.name}>
                        <HotelRoom
                            addedServices={addedServices}
                            adultsCount={adult || 1}
                            canAddServices={canAddServices}
                            dispatchService={dispatchService}
                            hotelName={hotel_name}
                            nights={num_of_nights}
                            optionId={hotel.id}
                            personId={personId}
                            roomData={room}
                            searchId={searchId}
                            trip={trip}
                        />
                    </Card>
                ))}
            </div>
            <FloatingIsland
                leading={<IconGroup className={cn('FloatingIslandIcon')} size="m" />}
                leadingTextParams={{
                    label: <Text weight="medium">{i18n('services_added')}</Text>,
                    caption: i18n('services', { count: addedServices.length, totalPrice: formatCurrency(totalPrice) }),
                }}
                trailing={<IconArrowShortRight size="m" />}
                onClick={toggleServicesDrawerVisible}
            />
            <Drawer
                view="default"
                visible={isServicesDrawerVisible}
                onClose={toggleServicesDrawerVisible}
            >
                <Passengers
                    addedServices={addedServices}
                    dispatchService={dispatchService}
                    personId={personId}
                    tripId={trip.trip_id}
                />
            </Drawer>
        </>
    );
};

const PageHotelRoomsRemote = withRemote(PageHotelRoomsRenderer);

export const PageHotelRooms: FC<PageHotelRoomsProps> = props => {
    const {
        params: { searchId, key },
        search: { trip_id, person_id, adult },
    } = props;

    const hotelDetails = useHotelSearchDetailsWithGrouping(key, searchId, { trip_id, person_id });
    const trip = useTrip(trip_id ?? 0);
    const personTrip = usePersonTrip(trip_id ?? 0, person_id, { isEnabled: trip_id !== undefined });

    return (
        <Page className={cn()}>
            <HeaderPage
                retButton
                title={i18n('page_title')}
            />
            <Container>
                <PageHotelRoomsRemote
                    {...props}
                    adult={adult}
                    hotelDetails={hotelDetails}
                    personId={person_id}
                    personTrip={trip_id === undefined ? undefined : personTrip}
                    searchId={searchId}
                    trip={trip}
                    tripId={trip_id}
                />
            </Container>
        </Page>
    );
};

PageHotelRooms.displayName = cn();
