import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AccountCreate } from 'services/SwaggerApi';

import { schema } from '../FormEmployeeAdd.schema';
import {
    FormEmployeeAddFields,
} from '../FormEmployeeAdd.types';

export const useFormAddEmployee = (
    createAccount: (payload: AccountCreate) => void,
    companyId: number,
) => {
    const onSubmit: SubmitHandler<FormEmployeeAddFields> = values => {
        createAccount({
            yandex_login: values.yandex_login,
            first_name: values.first_name,
            last_name: values.last_name,
            company_id: companyId,
        });
    };

    const methods = useForm<FormEmployeeAddFields>({
        defaultValues: { yandex_login: '', first_name: '', last_name: '' },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });

    return { methods, schema, onSubmit };
};
