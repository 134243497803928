import { FC, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSwiper } from 'swiper/react';

import { CurrentCompanyContext } from 'components/CurrentCompanyProvider/CurrentCompanyProvider.context';
import { FieldTextinput, FormProvider } from 'components/FormField2';
import { useCompanyInvoice } from 'hooks/useCompanyInvoice';
import { usePlatform } from 'hooks/usePlatform';
import { useSwiperAutoHeight } from 'hooks/useSwiperAutoHeight';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { ModalLayout } from 'shared/ui/ModalLayout';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { ModalSlides } from '../CompanyInvoice.types';

import { cn } from './InvoiceForm.cn';
import { i18n } from './InvoiceForm.i18n';
import { schema } from './InvoiceForm.schema';
import { InvoiceFormProps, InvoiceFormType } from './InvoiceForm.types';

import './InvoiceForm.css';

export const InvoiceForm: FC<InvoiceFormProps> = props => {
    const {
        setUrlFile,
        onClose,
        setAmount,
    } = props;

    const { isDesktop, platform } = usePlatform();

    const { currentCompanyId } = useContext(CurrentCompanyContext);

    const swiper = useSwiper();

    const { ref } = useSwiperAutoHeight();
    const [invoiceBalance, state, { error }] = useCompanyInvoice({
        onSuccess: (data, _, payload) => {
            const blob = data as Blob;
            const url = URL.createObjectURL(blob.slice(0, blob.size, 'application/pdf'));

            setUrlFile(url);
            setAmount(payload.amount);
            swiper.slideTo(ModalSlides.DOWNLOAD_SLIDE);
        },
    });

    const methods = useForm<InvoiceFormType>({
        defaultValues: {
            amount: '',
        },
        resolver: yupResolver(schema),
    });

    const handleSubmit: SubmitHandler<InvoiceFormType> = value => {
        invoiceBalance(currentCompanyId, value);
    };

    return (
        <ModalLayout
            actions={[
                <Button
                    key="back"
                    size={isDesktop ? 'm' : 'l'}
                    view="ghost"
                    onClick={onClose}
                >
                    {i18nAction('back')}
                </Button>,
                <Button
                    key="next"
                    progress={isLoading(state)}
                    size={isDesktop ? 'm' : 'l'}
                    view="primary"
                    onClick={methods.handleSubmit(handleSubmit)}
                >
                    {i18nAction('continue')}
                </Button>,
            ]}
            className={cn('FormSlide')}
            platform={platform}
            title={i18n('balance_invoice')}
        >
            {
                <FormProvider
                    error={error}
                    methods={methods}
                    schema={schema}
                >
                    <form
                        ref={ref}
                        className={cn()}
                    >
                        <Text typography="body-s">
                            {i18n('balance_invoice_description')}
                        </Text>
                        <FieldTextinput
                            disabled={isLoading(state)}
                            name="amount"
                            placeholder={i18n('amount_placeholder')}
                        />
                    </form>
                </FormProvider>
            }
        </ModalLayout>

    );
};
