import { useCallback, useEffect, useRef, useState } from 'react';

import { SendMessageType } from '.';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useWindowListener = (type: string, listener: any, useCapture = false, autoSubscribe = false) => {
    const [subscribed, setSubscribed] = useState(autoSubscribe);

    useEffect(() => {
        if (subscribed) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const handler = (...args: any[]) => {
                listener(...args);
            };

            window.addEventListener(type, handler, useCapture);

            return () => window.removeEventListener(type, handler, useCapture);
        }
    }, [subscribed, type, listener, useCapture]);

    return {
        subscribe: () => setSubscribed(true),
        unsubscribe: () => setSubscribed(false),
    };
};

export const useScrollSubscribe = (sendMessage: SendMessageType) => {
    const sendScroll = useCallback(() => {
        sendMessage('scroll', { scrollTop: document.documentElement.scrollTop });
    }, [sendMessage]);

    const { subscribe, unsubscribe } = useWindowListener('scroll', sendScroll);

    return {
        subscribeScroll: subscribe,
        unsubscribeScroll: unsubscribe,
    };
};

export const useHistorySubscribe = (sendMessage: SendMessageType) => {
    const locationRef = useRef(typeof window === 'undefined' ? null : window.location);

    const sendHistory = useCallback(() => {
        sendMessage('history', {
            oldLocation: JSON.stringify(locationRef.current),
            location: JSON.stringify(window.location),
        });

        locationRef.current = window.location;
    }, [sendMessage]);

    const { subscribe, unsubscribe } = useWindowListener('hashchange', sendHistory);

    return {
        subscribeHistory: subscribe,
        unsubscribeHistory: unsubscribe,
    };
};

export const useResizeSubscribe = (sendMessage: SendMessageType) => {
    const sendResize = useCallback(() => {
        const clientHeight = window.innerHeight || document.documentElement.clientHeight;

        sendMessage('clientHeight', clientHeight);
    }, [sendMessage]);

    const { subscribe, unsubscribe } = useWindowListener('resize', sendResize);

    return {
        sendResize,
        subscribeResize: subscribe,
        unsubscribeResize: unsubscribe,
    };
};
