import { Option as SelectOption } from '@yandex-lego/components/Select/Select';

import { TransactionType } from 'services/SwaggerApi';

import { i18n } from '../TransactionForm.i18n';

type GetTransactionTypeOption = (value: TransactionType) => SelectOption;

export const getTransactionTypeOption: GetTransactionTypeOption = value => {
    return {
        value,
        content: i18n(`type_value_${value}`),
    };
};
