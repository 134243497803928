export const ru = {
    add_route_point: 'Направление',
    remove_route_point: 'Удалить',
    make_complex_route: 'Составить сложный маршрут',
    complex_route: 'Сложный маршрут',
    route: 'Маршрут',
    trip_date: 'Дата поездки',
    trip_dates: 'Даты поездки',
    find: 'Найти',
    who: 'Кто',
    goal: 'Цель',
    'choose-goal': 'Выберите цель',
    trip: 'Командировка',
    conf: 'Конференция',
    'error-departure-lt': 'Дата Туда должна быть меньше, чем дата Обратно',
    'error-back-gt': 'Дата Обратно должна быть больше, чем дата Туда',
    save: 'Создать заявку',
    inside_conf: 'Внутренняя конференция',
    outside_conf: 'Внешняя конференция',
    meeting_trip: 'Рабочая встреча с коллегами',
    business_talk_trip: 'Деловые переговоры',
    recruiting_trip: 'Рекрутинг',
    engineer_work_trip: 'Инженерно-монтажные работы',
    committee_directors_trip: 'Совет директоров',
    production_trip: 'Производство',
    cancel: 'Отмена',
    add_participant: 'Добавить участника',
    comment_placeholder: 'Любая дополнительная информация, которая будет полезна для оформления заявки',
    comment: 'Комментарий',
    conf_another_city: 'Вам нужна командировка на данную конференцию?',
    conf_dates: 'Даты проведения конференции',
    conf_date_from_placeholder: 'Начало',
    conf_date_to_placeholder: 'Окончание',
    conf_details: 'Подробности конференции',
    conf_name: 'Название конференции',
    conf_name_placeholder: 'Укажите название',
    conf_url: 'Сайт конференции',
    conf_url_placeholder: 'Укажите ссылку на сайт',
    conf_city: 'Город проведения конференции',
    conf_city_placeholder: 'Например, Москва',
    conf_city_hint: 'Оставьте поле пустым, если конференция будет проходить онлайн',
    program_url: 'Программа мероприятия',
    program_url_placeholder: 'Ссылка на сайт с программой',
    participation_terms: 'Обоснование для участия в конференции',
    participation_terms_placeholder: 'Почему вам необходимо посетить данную конференцию, чем это полезно',
    conf_payment_type_title: 'Условия вашего участия в конференции',
    conf_payment_type_paid: 'Платно',
    conf_payment_type_paid_hint: 'Если вам нужно купить билет или получить компенсацию',
    conf_payment_type_free: 'Бесплатно',
    conf_payment_type_free_hint: 'Если вы участвуете бесплатно или компенсация за билет не нужна',
    conf_payment_need_help: 'Мне нужна помощь с регистрацией на конференцию',
    conf_price: 'Стоимость участия за одного человека',
    conf_price_placeholder: 'Укажите стоимость',
    conf_ticket_type: 'Тип билета',
    conf_ticket_type_placeholder: 'Например, Стандартный, VIP и так далее',
    conf_promocode: 'Промокод на скидку',
    conf_promocode_placeholder: 'Укажите промокод',
    conf_payment_need_compensation: 'Я уже купил билет, мне нужна компенсация',
    departure_city: 'Город',
    departure_date: 'Дата',
    description: 'Описание поездки',
    participants: 'Участники',
    risk_acceptance: 'Я понимаю, что снова растет заболеваемость коронавирусной инфекцией и компания не рекомендует ездить в командировки без крайней необходимости',
    trip_details: 'Данные о поездке',
    why_you_go: 'Зачем вы едете в командировку',
    city_from: 'Откуда',
    city_to: 'Куда',
    date_there: 'Туда',
    date_back: 'Обратно',
    date_when: 'Когда',
    listener: 'Слушатель',
    speaker: 'Докладчик',
    other_role: 'Другая роль',
    other_role_placeholder: 'Укажите роль на конференции',
    presentation_topic: 'Тема доклада',
    presentation_topic_placeholder: 'Укажите тему доклада',
    is_pr_approved: 'Доклад согласован с PR-службой',
    is_pr_approved_description: '{link} все доклады необходимо согласовывать',
    is_pr_approved_description_link: 'По правилам PR-службы',
    attendance_options: 'Способ участия в конференции',
    online: 'Онлайн',
    offline: 'Офлайн',
    pr_group_rules: 'Правила группы PR',
    for_conference: 'Для конференции',
    role: 'Роль участника на конференции',
    name_for_badge: 'Имя на бейдже',
    name_for_badge_placeholder: 'Укажите имя',
    position_for_badge: 'Должность на бейдже',
    position_for_badge_placeholder: 'Укажите должность',
    is_paid_by_host: 'Участие в конференции оплачивает принимающая сторона',
    is_paid_by_host_description: 'Согласно Правилам этики некоторые подарки и знаки гостеприимства, включая оплату проживания/переезда, которые вы получаете от внешних лиц, требуют согласования Отделом комплаенс. Если вы считаете, что эти знаки гостеприимства в сумме стоят более 11 000 рублей, заполните',
    form_for_approval: 'форму согласования',
    yes: 'Да',
    no: 'Нет',
    need_visa: 'Нужна виза',
    wishes_and_passport_details: 'Пожелания по билетам и гостинице, паспортные данные',
};
