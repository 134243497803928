import { useCallback, useContext, useState } from 'react';
import { MutationFunction, useMutation, UseMutationResult, useQueryClient } from 'react-query';

import { FetchError } from 'errors';
import { SwaggerApi } from 'services/SwaggerApi';
import { sendMetrikaGoal } from 'shared/lib/metrika/sendMetrikaGoal';
import { addToast } from 'utils/addToast';
import { failure, initial, loading, RemoteData, success } from 'utils/Loadable';
import { logError } from 'utils/logError';
import { isFormError } from 'utils/responseToHTTPError';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../SwaggerContext';

import { useMeta } from './useMeta';

type Hooks<TArgs extends unknown[], TSuccess, TError> = {
    onSuccess?: (data: TSuccess, ...args: TArgs) => void;
    onError?: (err: TError, ...args: TArgs) => void;
};

type HookOptions = {
    options?: {
        hideErrorNotifier?: boolean;
        hideValidationErrors?: boolean;
        invalidateOnSuccess?: boolean;
    };
};

type QueryKeyTyped = keyof SwaggerApi | [keyof SwaggerApi, ...unknown[]];

/**
 * Создает обертку для мутационных ручек из SwaggerApi
 *
 * @param key Ключ из SwaggerApi
 * @param invalidateQueriesFn функция возвращающая массив ключей которые будут инвалидированы после мутационного запроса
 *
 *
 * @example
 * ```tsx
 * export const useCostCenterCreate = createUseMutationHook(
 *     'cost_center_create',
 *     () => ['cost_center_list'],
 * );
 * ```
 *
 * В случае необходимости инвалидации только ручки c определенным набором id/ключей
 * из аргумента функции можно получить id / список id,
 * который передавался в вызове функции и инвалидировать ключи с определенными id
 *
 * @example
 * ```tsx
 * export const useDeleteDocument = createUseMutationHook(
 *     'person_document_delete',
 *     (_document_id, person_id) => [
 *         ['person_document_list', person_id],
 *     ],
 * );
 * ```
 */
export function createUseMutationHook<
    K extends keyof SwaggerApi,
    TFetchResponse extends ReturnType<SwaggerApi[K]>,
    TSuccess extends ExtractResponseSuccess<TFetchResponse>,
    TArgs extends Parameters<SwaggerApi[K]>,
>(
    key: K,
    invalidateQueriesFn: (...args: TArgs) => QueryKeyTyped[],
) {
    return function useMutationHook(
        { onSuccess, onError, options }: Hooks<TArgs, TSuccess, FetchError> & HookOptions = {},
    ): [
        (...args: TArgs) => void,
        RemoteData<TSuccess, FetchError>,
        Omit<UseMutationResult<TSuccess, FetchError, TArgs>, 'mutateAsync'>,
        (...args: TArgs) => Promise<unknown>
    ] {
        const meta = useMeta();
        const { api } = useContext(SwaggerContext);
        const fetchFunc = api[key];
        const {
            hideErrorNotifier = false,
            hideValidationErrors = false,
            invalidateOnSuccess = false,
        } = options || {};

        //@ts-ignore
        const queryFn = (args: TArgs) => fetchFunc(...args).then(throwHTTPErrors);
        const queryClient = useQueryClient();
        const [state, setState] = useState<RemoteData<TSuccess, FetchError>>(initial());
        const { mutateAsync, ...mutationRest } = useMutation<TSuccess, FetchError, TArgs>(queryFn as
            MutationFunction<TSuccess, TArgs>);

        const mutateAsyncCall = useCallback((...args: TArgs) => (
            mutateAsync(args, {
                onSuccess: (data, variables) => {
                    try {
                        sendMetrikaGoal(meta.user, key, ...args);
                    } catch (e) {
                        logError(new Error(`Ошибка при отправке метрики в ручке ${key}`));
                    }

                    setState(() => success(data));

                    if (invalidateOnSuccess) {
                        invalidateQueriesFn(...variables).forEach(query => queryClient.invalidateQueries(query));
                    }

                    if (onSuccess) {
                        onSuccess(data, ...args);
                    }
                },
                onError: (err, _variables, _onMutateValue) => {
                    setState(failure(err));

                    const hideNotifier = hideErrorNotifier || (hideValidationErrors && isFormError(err));

                    if (!hideNotifier) {
                        addToast({ title: err.name, message: err.message });
                    }

                    if (onError) {
                        onError(err, ...args);
                    }
                },
                onSettled: (_data, _error, variables, _onMutateValue) => {
                    if (!invalidateOnSuccess) {
                        invalidateQueriesFn(...variables).forEach(query => queryClient.invalidateQueries(query));
                    }
                },
            })
        ), [
            mutateAsync,
            invalidateOnSuccess,
            onSuccess,
            queryClient,
            hideErrorNotifier,
            hideValidationErrors,
            onError,
            meta,
        ]);

        const mutateFn = useCallback((...args: TArgs) => {
            setState(loading());
            mutateAsyncCall(...args).catch(err => {
                setState(failure(err));
            });
        }, [mutateAsyncCall]);

        const asyncMutateFn = useCallback((...args: TArgs) => new Promise((resolve, reject) => {
            setState(loading());
            mutateAsyncCall(...args)
                .then(resolve)
                .catch(err => {
                    setState(failure(err));
                    reject(err);
                });
        }), [mutateAsyncCall]);

        return [mutateFn, state, mutationRest, asyncMutateFn];
    };
}
