import { getBaobabInitState } from '@yandex-int/react-baobab-logger';

import { uuidv4 } from 'shared/lib/uuidv4';

export function getBaobabState() {
    const baobabState = getBaobabInitState({
        reqId: uuidv4(),
        service: 'trip',
        table: 'trip_test',
        pageUrl: 'trip.yandex-team.ru',
        slots: [],
    });

    return baobabState;
}
