/* eslint-disable */
// DO NOT EDIT: Generated by pnpm run swagger

import { apiFetchTypedErrors } from '../SwaggerApi.utils';
import { SwaggerApiTemp } from './SwaggerApiTemp.ts';
import * as Types from '.';

export class SwaggerApi extends SwaggerApiTemp {
    /**
     * Tg Link Person
     * @param payload Types.TgLinkRequest
     * @param options RequestInit
     * @returns Promise
     */
    tg_link_person(
        payload: Types.TgLinkRequest,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.TgLinkRequest,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/tg_bot/persons/link', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Tg Check Access
     * @param tg_id number
     * @param options RequestInit
     * @returns Promise
     */
    tg_check_access(
        tg_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/tg_bot/persons/check_access/${tg_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * New Tg Trip List
     * @param tg_id number
     * @param options RequestInit
     * @returns Promise
     */
    new_tg_trip_list(
        tg_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TgPersonTrip[]> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/tg_bot/v2/trips/${tg_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Tg Trip List
     * @param tg_id number
     * @param queryParams Types.TTg_trip_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    tg_trip_list(
        tg_id: number,
        queryParams: Types.TTg_trip_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_Trip_> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TTg_trip_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/tg_bot/trips/${tg_id}`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Tg Person Trip Detail
     * @param trip_id number
     * @param tg_id number
     * @param options RequestInit
     * @returns Promise
     */
    tg_person_trip_detail(
        trip_id: number,
        tg_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PersonTrip> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/tg_bot/trips/${trip_id}/${tg_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * New Tg Person Trip Detail
     * @param trip_id number
     * @param tg_id number
     * @param options RequestInit
     * @returns Promise
     */
    new_tg_person_trip_detail(
        trip_id: number,
        tg_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TgPersonTripDetail> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/tg_bot/v2/trips/${trip_id}/${tg_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Детальная информация об отельной услуге
     * @param service_id number
     * @param tg_id number
     * @param options RequestInit
     * @returns Promise
     */
    get_tg_hotel_service_details(
        service_id: number,
        tg_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.HotelServiceDetails> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/tg_bot/services/hotel/${service_id}/${tg_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Детальная информация о жд услуге
     * @param service_id number
     * @param tg_id number
     * @param options RequestInit
     * @returns Promise
     */
    get_tg_rail_service_details(
        service_id: number,
        tg_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.RailServiceDetails> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/tg_bot/services/rail/${service_id}/${tg_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Детальная информация об авиа услуге
     * @param service_id number
     * @param tg_id number
     * @param options RequestInit
     * @returns Promise
     */
    get_tg_avia_service_details(
        service_id: number,
        tg_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.AviaServiceDetails> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/tg_bot/services/avia/${service_id}/${tg_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Детальная информация об отельной услуге
     * @param tg_id number
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    new_get_tg_hotel_service_details(
        tg_id: number,
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TgHotelServiceDetails> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/tg_bot/v2/services/hotel/${service_id}/${tg_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Детальная информация об авиа услуге
     * @param tg_id number
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    new_get_tg_avia_service_details(
        tg_id: number,
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TgAviaServiceDetails> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/tg_bot/v2/services/avia/${service_id}/${tg_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Детальная информация об авиа услуге
     * @param tg_id number
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    new_get_tg_rail_service_details(
        tg_id: number,
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TgRailServiceDetails> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/tg_bot/v2/services/rail/${service_id}/${tg_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Get Companies
     * @param queryParams Types.TGet_companiesQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_companies(
        queryParams: Types.TGet_companiesQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_companiesQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/companies', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Get Airports
     * @param queryParams Types.TGet_airportsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_airports(
        queryParams: Types.TGet_airportsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_airportsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/airports', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Create Aviacenter Avia Search
     * @param payload Types.AviaSearchRequest
     * @param options RequestInit
     * @returns Promise
     */
    create_aviacenter_avia_search(
        payload: Types.AviaSearchRequest,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.AviaSearchRequest,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/avia/search', null, [201, 422], 'POST', payload, options);
    }
    /**
     * Get Aviacenter Avia Search Results
     * @param queryParams Types.TGet_aviacenter_avia_search_resultsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_aviacenter_avia_search_results(
        queryParams: Types.TGet_aviacenter_avia_search_resultsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_aviacenter_avia_search_resultsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/avia/search/results', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Get Fare Families
     * @param queryParams Types.TGet_fare_familiesQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_fare_families(
        queryParams: Types.TGet_fare_familiesQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_fare_familiesQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/avia/fare_families', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Get Flight Info
     * @param queryParams Types.TGet_flight_infoQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_flight_info(
        queryParams: Types.TGet_flight_infoQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_flight_infoQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/avia/flight_info', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Get Rules
     * @param queryParams Types.TGet_rulesQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_rules(
        queryParams: Types.TGet_rulesQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_rulesQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/avia/rules', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Avia Book
     * @param payload Types.AviaBookIn
     * @param options RequestInit
     * @returns Promise
     */
    avia_book(
        payload: Types.AviaBookIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.AviaBookIn,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/avia/book', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Create Aviacenter Hotel Search
     * @param payload Types.HotelSearchRequest
     * @param options RequestInit
     * @returns Promise
     */
    create_aviacenter_hotel_search(
        payload: Types.HotelSearchRequest,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.HotelSearchRequest,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/hotel/search', null, [201, 422], 'POST', payload, options);
    }
    /**
     * Get Aviacenter Hotel Search Results
     * @param queryParams Types.TGet_aviacenter_hotel_search_resultsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_aviacenter_hotel_search_results(
        queryParams: Types.TGet_aviacenter_hotel_search_resultsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_aviacenter_hotel_search_resultsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/hotel/search/results', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Get Hotel Search View
     * @param queryParams Types.TGet_hotel_search_viewQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_hotel_search_view(
        queryParams: Types.TGet_hotel_search_viewQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_hotel_search_viewQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/hotel/search/view', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Hotel Book
     * @param payload Types.HotelBookIn
     * @param options RequestInit
     * @returns Promise
     */
    hotel_book(
        payload: Types.HotelBookIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.HotelBookIn,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/hotel/book', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Order Detail
     * @param billing_number number
     * @param options RequestInit
     * @returns Promise
     */
    order_detail(
        billing_number: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aviacenter/order/${billing_number}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Get Train Search
     * @param queryParams Types.TGet_train_searchQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_train_search(
        queryParams: Types.TGet_train_searchQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_train_searchQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/train/search', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Create Train Search
     * @param queryParams Types.TCreate_train_searchQuery
     * @param payload Types.TrainSearchRequest
     * @param options RequestInit
     * @returns Promise
     */
    create_train_search(
        queryParams: Types.TCreate_train_searchQuery,
        payload: Types.TrainSearchRequest,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.TrainSearchRequest,
            Types.TCreate_train_searchQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/train/search', queryParams, [201, 422], 'POST', payload, options);
    }
    /**
     * Get Train Info
     * @param queryParams Types.TGet_train_infoQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_train_info(
        queryParams: Types.TGet_train_infoQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_train_infoQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/train/info', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Train Book
     * @param payload Types.TrainBookIn
     * @param options RequestInit
     * @returns Promise
     */
    train_book(
        payload: Types.TrainBookIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.TrainBookIn,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aviacenter/train/book', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Webhook
     * @param options RequestInit
     * @returns Promise
     */
    webhook(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/bot/webhook', null, [200, 422], 'POST', null, options);
    }
    /**
     * Approve Person Trip
     * @param payload Types.PersonTripTrackerNotification
     * @param options RequestInit
     * @returns Promise
     */
    approve_person_trip(
        payload: Types.PersonTripTrackerNotification,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.PersonTripTrackerNotification,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/callbacks/tracker/approve/', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Set Person Trip Manager
     * @param payload Types.PersonTripTrackerNotification
     * @param options RequestInit
     * @returns Promise
     */
    set_person_trip_manager(
        payload: Types.PersonTripTrackerNotification,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.PersonTripTrackerNotification,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/callbacks/tracker/set_manager/', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Set Person Trip Watchers
     * @param payload Types.PersonTripTrackerWatchers
     * @param options RequestInit
     * @returns Promise
     */
    set_person_trip_watchers(
        payload: Types.PersonTripTrackerWatchers,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.PersonTripTrackerWatchers,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/callbacks/tracker/set_watchers/', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Update Taxi Date By Issue
     * Set taxi_dates affects to update_taxi_state_task
     * @param payload Types.PersonTripTrackerTaxiDateUpdate
     * @param options RequestInit
     * @returns Promise
     */
    update_taxi_date_by_issue(
        payload: Types.PersonTripTrackerTaxiDateUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.PersonTripTrackerTaxiDateUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/callbacks/tracker/update_taxi_date/', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Update Conf Gap Dates By Issue
     * Set gap dates affects to create_or_update_staff_trip_task
     * @param payload Types.PersonTripTrackerGapDatesUpdate
     * @param options RequestInit
     * @returns Promise
     */
    update_conf_gap_dates_by_issue(
        payload: Types.PersonTripTrackerGapDatesUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.PersonTripTrackerGapDatesUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/callbacks/tracker/update_conf_gap_dates/', null, [204, 422], 'POST', payload, options);
    }
    /**
     * Update Support Person
     * @param payload Types.SamsaraSupportUpdate
     * @param options RequestInit
     * @returns Promise
     */
    update_support_person(
        payload: Types.SamsaraSupportUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.SamsaraSupportUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/callbacks/samsara/support/', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Info
     * @param options RequestInit
     * @returns Promise
     */
    info(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/idm/info/', null, [200], 'GET', null, options);
    }
    /**
     * Add Role
     * @param options RequestInit
     * @returns Promise
     */
    add_role(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/idm/add-role/', null, [200, 422], 'POST', null, options);
    }
    /**
     * Remove Role
     * @param options RequestInit
     * @returns Promise
     */
    remove_role(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/idm/remove-role/', null, [200, 422], 'POST', null, options);
    }
    /**
     * Get All Roles
     * @param options RequestInit
     * @returns Promise
     */
    get_all_roles(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/idm/get-all-roles/', null, [200], 'GET', null, options);
    }
    /**
     * Arq Stats
     * @param queryParams Types.TArq_statsQuery
     * @param options RequestInit
     * @returns Promise
     */
    arq_stats(
        queryParams: Types.TArq_statsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TArq_statsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/monitoring/arq', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Notification About Message
     * Уведомление о получении нового сообщения в чате с аэроклубом
     * @param payload Types.NotificationSyncIn
     * @param options RequestInit
     * @returns Promise
     */
    notification_about_message(
        payload: Types.NotificationSyncIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.NotificationSyncIn,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/notifications/sync', null, [200, 422], 'PUT', payload, options);
    }
    /**
     * Trip Event
     * @param payload Types.EventIn
     * @param options RequestInit
     * @returns Promise
     */
    trip_event(
        payload: Types.EventIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.EventIn,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/notifications/event', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Create Approvement
     * @param payload Types.ApprovementData
     * @param options RequestInit
     * @returns Promise
     */
    create_approvement(
        payload: Types.ApprovementData,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.ApprovementData,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/ok/create', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Upload Aeroclub Registry
     * @param payload Types.Body_upload_aeroclub_registry_api_registries__post
     * @param options RequestInit
     * @returns Promise
     */
    upload_aeroclub_registry(
        payload: Types.Body_upload_aeroclub_registry_api_registries__post,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.Body_upload_aeroclub_registry_api_registries__post,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/registries/', null, [201, 422], 'POST', payload, options);
    }
    /**
     * Transaction List
     * @param company_id number
     * @param queryParams Types.TTransaction_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    transaction_list(
        company_id: number,
        queryParams: Types.TTransaction_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_Transaction_> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TTransaction_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/transactions`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Transaction Create
     * @param company_id number
     * @param payload Types.TransactionCreate
     * @param options RequestInit
     * @returns Promise
     */
    transaction_create(
        company_id: number,
        payload: Types.TransactionCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.TransactionId> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.TransactionCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/transactions`, null, [201, 422], 'POST', payload, options);
    }
    /**
     * Transaction List For Client
     * @param company_id number
     * @param queryParams Types.TTransaction_list_for_clientQuery
     * @param options RequestInit
     * @returns Promise
     */
    transaction_list_for_client(
        company_id: number,
        queryParams: Types.TTransaction_list_for_clientQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_TransactionLite_> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TTransaction_list_for_clientQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/transactions/for_client`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Transaction Detail
     * @param company_id number
     * @param transaction_id string
     * @param options RequestInit
     * @returns Promise
     */
    transaction_detail(
        company_id: number,
        transaction_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.Transaction> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/transactions/${transaction_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Transaction Delete
     * @param company_id number
     * @param transaction_id string
     * @param options RequestInit
     * @returns Promise
     */
    transaction_delete(
        company_id: number,
        transaction_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/transactions/${transaction_id}`, null, [204, 422], 'DELETE', null, options);
    }
    /**
     * Transaction Update
     * @param company_id number
     * @param transaction_id string
     * @param payload Types.TransactionPartialUpdate
     * @param options RequestInit
     * @returns Promise
     */
    transaction_update(
        company_id: number,
        transaction_id: string,
        payload: Types.TransactionPartialUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TransactionId> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.TransactionPartialUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/transactions/${transaction_id}`, null, [200, 422], 'PATCH', payload, options);
    }
    /**
     * Transaction Detail For Client
     * @param company_id number
     * @param transaction_id string
     * @param options RequestInit
     * @returns Promise
     */
    transaction_detail_for_client(
        company_id: number,
        transaction_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TransactionLite> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/transactions/for_client/${transaction_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Set Transaction Paid
     * @param company_id number
     * @param transaction_id string
     * @param options RequestInit
     * @returns Promise
     */
    set_transaction_paid(
        company_id: number,
        transaction_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/transactions/${transaction_id}/set_paid`, null, [200, 422], 'POST', null, options);
    }
    /**
     * Company Balance
     * @param company_id number
     * @param options RequestInit
     * @returns Promise
     */
    company_balance(
        company_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.Balance> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/balance`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Company Expenses
     * @param company_id number
     * @param queryParams Types.TCompany_expensesQuery
     * @param options RequestInit
     * @returns Promise
     */
    company_expenses(
        company_id: number,
        queryParams: Types.TCompany_expensesQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.Expenses> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TCompany_expensesQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/expenses`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Company Invoice
     * @param company_id number
     * @param payload Types.InvoiceCreate
     * @param options RequestInit
     * @returns Promise
     */
    company_invoice(
        company_id: number,
        payload: Types.InvoiceCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.InvoiceCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/invoice`, null, [200, 422], 'POST', payload, options);
    }
    /**
     * Check Registry
     * @param payload Types.Body_check_registry_api_billing_check_registry_post
     * @param options RequestInit
     * @returns Promise
     */
    check_registry(
        payload: Types.Body_check_registry_api_billing_check_registry_post,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.Body_check_registry_api_billing_check_registry_post,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/billing/check_registry', null, [201, 422], 'POST', payload, options);
    }
    /**
     * Balance Update
     * Обрабатывает сообщение об изменении баланса из Logbroker (ручка для billing-deposits-taker)
     * Сообщения сохраняются в БД, размер пополнения вычисляется как разность между балансом текущей
     * версии договора (version_id) и предыдущей
     * 
     * Подробнее: https://wiki.yandex-team.ru/trip/dev/billing/integration/
     * @param payload Types.BalanceUpdate
     * @param options RequestInit
     * @returns Promise
     */
    balance_update(
        payload: Types.BalanceUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.BalanceUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/billing/balance_updates', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Transaction List Xlsx
     * @param company_id number
     * @param queryParams Types.TTransaction_list_xlsxQuery
     * @param options RequestInit
     * @returns Promise
     */
    transaction_list_xlsx(
        company_id: number,
        queryParams: Types.TTransaction_list_xlsxQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TTransaction_list_xlsxQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/billing/companies/${company_id}/export_transactions`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Available Companies
     * @param options RequestInit
     * @returns Promise
     */
    available_companies(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.CompanyName[]>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/companies/available', null, [200], 'GET', null, options);
    }
    /**
     * Employee List
     * @param company_id number
     * @param queryParams Types.TEmployee_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    employee_list(
        company_id: number,
        queryParams: Types.TEmployee_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_Employee_> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TEmployee_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Employee Detail
     * @param company_id number
     * @param employee_id string
     * @param options RequestInit
     * @returns Promise
     */
    employee_detail(
        company_id: number,
        employee_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.Employee> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Employee Change Company
     * @param company_id number
     * @param employee_id string
     * @param queryParams Types.TEmployee_change_companyQuery
     * @param options RequestInit
     * @returns Promise
     */
    employee_change_company(
        company_id: number,
        employee_id: string,
        queryParams: Types.TEmployee_change_companyQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TEmployee_change_companyQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/change_company`, queryParams, [204, 422], 'POST', null, options);
    }
    /**
     * Employee Activate
     * @param company_id number
     * @param employee_id string
     * @param queryParams Types.TEmployee_activateQuery
     * @param options RequestInit
     * @returns Promise
     */
    employee_activate(
        company_id: number,
        employee_id: string,
        queryParams: Types.TEmployee_activateQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TEmployee_activateQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/activate`, queryParams, [204, 422], 'POST', null, options);
    }
    /**
     * Employee Reject
     * @param company_id number
     * @param employee_id string
     * @param options RequestInit
     * @returns Promise
     */
    employee_reject(
        company_id: number,
        employee_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/reject`, null, [204, 422], 'POST', null, options);
    }
    /**
     * Employee Block
     * @param company_id number
     * @param employee_id string
     * @param options RequestInit
     * @returns Promise
     */
    employee_block(
        company_id: number,
        employee_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/block`, null, [204, 422], 'POST', null, options);
    }
    /**
     * Employee Restore
     * @param company_id number
     * @param employee_id string
     * @param queryParams Types.TEmployee_restoreQuery
     * @param options RequestInit
     * @returns Promise
     */
    employee_restore(
        company_id: number,
        employee_id: string,
        queryParams: Types.TEmployee_restoreQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TEmployee_restoreQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/restore`, queryParams, [204, 422], 'POST', null, options);
    }
    /**
     * Employee Grant Coordinator
     * @param company_id number
     * @param employee_id string
     * @param options RequestInit
     * @returns Promise
     */
    employee_grant_coordinator(
        company_id: number,
        employee_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/grant_coordinator`, null, [204, 422], 'POST', null, options);
    }
    /**
     * Employee Revoke Coordinator
     * @param company_id number
     * @param employee_id string
     * @param options RequestInit
     * @returns Promise
     */
    employee_revoke_coordinator(
        company_id: number,
        employee_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/revoke_coordinator`, null, [204, 422], 'POST', null, options);
    }
    /**
     * Employee Assign Assistant
     * @param company_id number
     * @param employee_id string
     * @param queryParams Types.TEmployee_assign_assistantQuery
     * @param options RequestInit
     * @returns Promise
     */
    employee_assign_assistant(
        company_id: number,
        employee_id: string,
        queryParams: Types.TEmployee_assign_assistantQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TEmployee_assign_assistantQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/assign_assistant`, queryParams, [204, 422], 'POST', null, options);
    }
    /**
     * Employee Remove Assistant
     * @param company_id number
     * @param employee_id string
     * @param options RequestInit
     * @returns Promise
     */
    employee_remove_assistant(
        company_id: number,
        employee_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/remove_assistant`, null, [204, 422], 'POST', null, options);
    }
    /**
     * Get Employee Assistants
     * @param company_id number
     * @param employee_id string
     * @param queryParams Types.TGet_employee_assistantsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_employee_assistants(
        company_id: number,
        employee_id: string,
        queryParams: Types.TGet_employee_assistantsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_Employee_> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_employee_assistantsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/assistants`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Get Employee Chiefs
     * @param company_id number
     * @param employee_id string
     * @param queryParams Types.TGet_employee_chiefsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_employee_chiefs(
        company_id: number,
        employee_id: string,
        queryParams: Types.TGet_employee_chiefsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_Employee_> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_employee_chiefsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/chiefs`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Employee Change Approver
     * @param company_id number
     * @param employee_id string
     * @param queryParams Types.TEmployee_change_approverQuery
     * @param options RequestInit
     * @returns Promise
     */
    employee_change_approver(
        company_id: number,
        employee_id: string,
        queryParams: Types.TEmployee_change_approverQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TEmployee_change_approverQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/employees/${employee_id}/change_approver`, queryParams, [204, 422], 'POST', null, options);
    }
    /**
     * Employee Company Details
     * @param queryParams Types.TEmployee_company_detailsQuery
     * @param options RequestInit
     * @returns Promise
     */
    employee_company_details(
        queryParams: Types.TEmployee_company_detailsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.CompanyDetails> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TEmployee_company_detailsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/companies/details', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Create Company
     * @param payload Types.CompanyCreateIn
     * @param options RequestInit
     * @returns Promise
     */
    create_company(
        payload: Types.CompanyCreateIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.CompanyCreateIn,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/companies/create', null, [201, 422], 'POST', payload, options);
    }
    /**
     * Cost Center List
     * @param company_id number
     * @param queryParams Types.TCost_center_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    cost_center_list(
        company_id: number,
        queryParams: Types.TCost_center_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_CostCenter_> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TCost_center_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/cost_centers`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Cost Center Create
     * @param company_id number
     * @param payload Types.CostCenterCreate
     * @param options RequestInit
     * @returns Promise
     */
    cost_center_create(
        company_id: number,
        payload: Types.CostCenterCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.CostCenterId> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.CostCenterCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/cost_centers`, null, [201, 422], 'POST', payload, options);
    }
    /**
     * Cost Center Delete
     * @param company_id number
     * @param cost_center_id number
     * @param options RequestInit
     * @returns Promise
     */
    cost_center_delete(
        company_id: number,
        cost_center_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}/cost_centers/${cost_center_id}`, null, [204, 422], 'DELETE', null, options);
    }
    /**
     * Get Companies List
     * @param queryParams Types.TGet_companies_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_companies_list(
        queryParams: Types.TGet_companies_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_CompanyLite_> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TGet_companies_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/companies/', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Update Company
     * @param company_id number
     * @param payload Types.CompanyUpdate
     * @param options RequestInit
     * @returns Promise
     */
    update_company(
        company_id: number,
        payload: Types.CompanyUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.CompanyUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/companies/${company_id}`, null, [200, 422], 'PATCH', payload, options);
    }
    /**
     * Person Send Feedback
     * @param payload Types.Feedback
     * @param options RequestInit
     * @returns Promise
     */
    person_send_feedback(
        payload: Types.Feedback,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.Feedback,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/feedback/', null, [204, 422], 'POST', payload, options);
    }
    /**
     * Саджест по сущностям Аэроклуба (города, страны, отели и т.д.)
     * @param queryParams Types.TReference_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    reference_list(
        queryParams: Types.TReference_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.Reference[]> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TReference_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/aeroclub/references', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Получение доступных параметров фильтрации созданного поискового запроса
     * @param search_request_id number
     * @param options RequestInit
     * @returns Promise
     */
    search_filter_list(
        search_request_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.FilterParameter[]> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/search/${search_request_id}/filterparameters`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Получение статуса поискового запроса
     * @param search_request_id number
     * @param options RequestInit
     * @returns Promise
     */
    search_request_status(
        search_request_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ACSearchRequestStatus> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/search/${search_request_id}/status`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Детализация объекта поискового запроса
     * @param search_request_id number
     * @param options RequestInit
     * @returns Promise
     */
    search_request_detail(
        search_request_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.SearchRequest> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/search/${search_request_id}/details`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Результаты поискового запроса (все опции)
     * @param search_request_id number
     * @param queryParams Types.TSearch_result_list_deprecatedQuery
     * @param options RequestInit
     * @returns Promise
     */
    search_result_list_deprecated(
        search_request_id: number,
        queryParams: Types.TSearch_result_list_deprecatedQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_SearchResultInList_> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TSearch_result_list_deprecatedQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/search/${search_request_id}`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Количество результатов поискового запроса (все опции)
     * @param search_request_id number
     * @param queryParams Types.TSearch_result_countQuery
     * @param options RequestInit
     * @returns Promise
     */
    search_result_count(
        search_request_id: number,
        queryParams: Types.TSearch_result_countQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.SearchResultCount> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TSearch_result_countQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/search/${search_request_id}/results/count`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Результаты одной опции поискового запроса
     * @param search_request_id number
     * @param option_id number
     * @param queryParams Types.TSearch_option_result_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    search_option_result_list(
        search_request_id: number,
        option_id: number,
        queryParams: Types.TSearch_option_result_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_SearchResultInList_> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TSearch_option_result_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/search/${search_request_id}/options/${option_id}/results`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Количество результатов одной опции поискового запроса
     * @param search_request_id number
     * @param option_id number
     * @param queryParams Types.TSearch_result_option_countQuery
     * @param options RequestInit
     * @returns Promise
     */
    search_result_option_count(
        search_request_id: number,
        option_id: number,
        queryParams: Types.TSearch_result_option_countQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.SearchResultCount> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TSearch_result_option_countQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/search/${search_request_id}/options/${option_id}/results/count`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Детальная информация одного варианта поискового запроса
     * @param search_request_id number
     * @param option_id number
     * @param queryParams Types.TSearch_option_detailQuery
     * @param options RequestInit
     * @returns Promise
     */
    search_option_detail(
        search_request_id: number,
        option_id: number,
        queryParams: Types.TSearch_option_detailQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.SearchResult> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TSearch_option_detailQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/search/${search_request_id}/options/${option_id}`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Детальная информация о услуге
     * @param order_id number
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    aeroclub_service_detail(
        order_id: number,
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ACService> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/orders/${order_id}/services/${service_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Доступные места ЖД-услуги
     * @param order_id number
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    aeroclub_service_seats(
        order_id: number,
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ACRailSearchResult> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/orders/${order_id}/services/${service_id}/seats`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Доступные документы для добавления в услугу
     * @param order_id number
     * @param service_id number
     * @param queryParams Types.TAllowed_document_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    allowed_document_list(
        order_id: number,
        service_id: number,
        queryParams: Types.TAllowed_document_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ACTravellerDocument[]> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TAllowed_document_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/orders/${order_id}/services/${service_id}/allowed_documents`, queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Данные профиля из Аэроклуба
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    get_aeroclub_profile_by_person_id(
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.Profile> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/person_profiles/${person_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Список бонусных карт профиля из Аэроклуба по нашему person_id
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    get_aeroclub_profile_bonus_cards_by_person_id(
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ACBonusCard[]> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/person_profiles/${person_id}/bonus_cards`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Детальная информация о персональной командировке (включая услуги)
     * @param journey_id number
     * @param trip_id number
     * @param options RequestInit
     * @returns Promise
     */
    aeroclub_trip_detail(
        journey_id: number,
        trip_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.BusinessTrip> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/aeroclub/journeys/${journey_id}/trips/${trip_id}`, null, [200, 422], 'GET', null, options);
    }
    /**
     * Get Csrf Token
     * @param options RequestInit
     * @returns Promise
     */
    get_csrf_token(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TokenResponse>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/csrf_token/', null, [200], 'GET', null, options);
    }
    /**
     * Ext Person List
     * @param options RequestInit
     * @returns Promise
     */
    ext_person_list(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ExtPerson[]> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/ext_persons/', null, [200, 400, 404, 500], 'GET', null, options);
    }
    /**
     * Ext Person Create
     * @param payload Types.ExtPersonBase
     * @param options RequestInit
     * @returns Promise
     */
    ext_person_create(
        payload: Types.ExtPersonBase,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.ExtPersonId> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.ExtPersonBase,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/ext_persons/', null, [201, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Ext Person Get
     * @param ext_person_uuid string
     * @param options RequestInit
     * @returns Promise
     */
    ext_person_get(
        ext_person_uuid: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ExtPerson> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/ext_persons/${ext_person_uuid}`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Ext Person Update
     * @param ext_person_uuid string
     * @param payload Types.ExtPersonBase
     * @param options RequestInit
     * @returns Promise
     */
    ext_person_update(
        ext_person_uuid: string,
        payload: Types.ExtPersonBase,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.ExtPersonBase,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/ext_persons/${ext_person_uuid}`, null, [204, 400, 404, 422, 500], 'PUT', payload, options);
    }
    /**
     * Ext Person Delete
     * @param ext_person_uuid string
     * @param options RequestInit
     * @returns Promise
     */
    ext_person_delete(
        ext_person_uuid: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/ext_persons/${ext_person_uuid}`, null, [204, 400, 404, 422, 500], 'DELETE', null, options);
    }
    /**
     * Ext Person Document Create
     * @param ext_person_uuid string
     * @param payload Types.PersonDocumentCreate
     * @param options RequestInit
     * @returns Promise
     */
    ext_person_document_create(
        ext_person_uuid: string,
        payload: Types.PersonDocumentCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.DocumentId> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.PersonDocumentCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/ext_persons/${ext_person_uuid}/documents`, null, [201, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Ext Person Document Delete
     * @param document_id number
     * @param ext_person_uuid string
     * @param options RequestInit
     * @returns Promise
     */
    ext_person_document_delete(
        document_id: number,
        ext_person_uuid: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/ext_persons/${ext_person_uuid}/documents/${document_id}`, null, [204, 400, 404, 422, 500], 'DELETE', null, options);
    }
    /**
     * Get Available Groups
     * @param queryParams Types.TGet_available_groupsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_available_groups(
        queryParams: Types.TGet_available_groupsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.AvailableGroupsResponse> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_available_groupsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/groups/available', queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Get Group Members
     * @param group_id number
     * @param queryParams Types.TGet_group_membersQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_group_members(
        group_id: number,
        queryParams: Types.TGet_group_membersQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_GroupMembersResponse_> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_group_membersQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/groups/${group_id}/members`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Get Available Persons
     * @param queryParams Types.TGet_available_personsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_available_persons(
        queryParams: Types.TGet_available_personsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_GroupAvailablePersonsResponse_> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_available_personsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/groups/available_persons', queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Get Group Details
     * @param group_id number
     * @param options RequestInit
     * @returns Promise
     */
    get_group_details(
        group_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.GroupDetailResponse> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/groups/${group_id}`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Edit Group
     * @param group_id number
     * @param payload Types.GroupUpdate
     * @param options RequestInit
     * @returns Promise
     */
    edit_group(
        group_id: number,
        payload: Types.GroupUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.GroupUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/groups/${group_id}`, null, [204, 400, 404, 422, 500], 'PUT', payload, options);
    }
    /**
     * Delete Group
     * @param group_id number
     * @param options RequestInit
     * @returns Promise
     */
    delete_group(
        group_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/groups/${group_id}`, null, [204, 400, 404, 422, 500], 'DELETE', null, options);
    }
    /**
     * Get Group List
     * @param queryParams Types.TGet_group_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_group_list(
        queryParams: Types.TGet_group_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_GroupResponse_> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_group_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/groups/', queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Create Group
     * @param payload Types.GroupCreate
     * @param options RequestInit
     * @returns Promise
     */
    create_group(
        payload: Types.GroupCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.GroupId> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.GroupCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/groups/', null, [201, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Delete Person From Group
     * @param group_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    delete_person_from_group(
        group_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/groups/${group_id}/members/${person_id}`, null, [204, 400, 404, 422, 500], 'DELETE', null, options);
    }
    /**
     * Add One Person
     * @param group_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    add_one_person(
        group_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/groups/${group_id}/members/${person_id}`, null, [204, 400, 404, 422, 500], 'PATCH', null, options);
    }
    /**
     * Persons Suggest
     * @param queryParams Types.TPersons_suggestQuery
     * @param options RequestInit
     * @returns Promise
     */
    persons_suggest(
        queryParams: Types.TPersons_suggestQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TPersons_suggestQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/isearch/persons', queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Auth Meta
     * @param options RequestInit
     * @returns Promise
     */
    auth_meta(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.MetaResponse> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/meta/', null, [200, 400, 404, 500], 'GET', null, options);
    }
    /**
     * User Email
     * @param options RequestInit
     * @returns Promise
     */
    user_email(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.UserEmailResponse> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/meta/email', null, [200, 400, 404, 500], 'GET', null, options);
    }
    /**
     * Get Person Travel Policy
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    get_person_travel_policy(
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PersonTravelPolicyResponse> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.TravelPolicyInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/travel_policy`, null, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Edit Person Group Or Travel Policy
     * @param person_id string
     * @param queryParams Types.TEdit_person_group_or_travel_policyQuery
     * @param options RequestInit
     * @returns Promise
     */
    edit_person_group_or_travel_policy(
        person_id: string,
        queryParams: Types.TEdit_person_group_or_travel_policyQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TEdit_person_group_or_travel_policyQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/travel_policy`, queryParams, [204, 404, 422, 500], 'PUT', null, options);
    }
    /**
     * Person Chat Id
     * Returns samsara chat_id for current user
     * @param options RequestInit
     * @returns Promise
     */
    person_chat_id(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.SamsaraChatId> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/persons/chat_id', null, [200, 404, 500], 'GET', null, options);
    }
    /**
     * Get Person
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    get_person(
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PersonDetailsPublic> |
            ResponseOnStatus<400, Types.CommonB2BProblemErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Get Person Details
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    get_person_details(
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PersonDetails> |
            ResponseOnStatus<400, Types.PersonDetailsErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/details`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Person Details Update
     * @param person_id string
     * @param payload Types.PersonDetailsUpdate
     * @param options RequestInit
     * @returns Promise
     */
    person_details_update(
        person_id: string,
        payload: Types.PersonDetailsUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.CommonB2BProblemErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.PersonDetailsUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/details`, null, [204, 400, 404, 422, 500], 'PUT', payload, options);
    }
    /**
     * Person Analytics Data Update
     * @param person_id string
     * @param payload Types.AnalyticsDataUpdate
     * @param options RequestInit
     * @returns Promise
     */
    person_analytics_data_update(
        person_id: string,
        payload: Types.AnalyticsDataUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.AnalyticsDataUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/analytics_data`, null, [204, 404, 422, 500], 'PATCH', payload, options);
    }
    /**
     * Person Document List
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    person_document_list(
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PersonDocument[]> |
            ResponseOnStatus<400, Types.PersonDocumentOperationErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/documents`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Person Document Create
     * @param person_id string
     * @param payload Types.PersonDocumentCreate
     * @param options RequestInit
     * @returns Promise
     */
    person_document_create(
        person_id: string,
        payload: Types.PersonDocumentCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.DocumentId> |
            ResponseOnStatus<400, Types.PersonDocumentOperationErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.PersonDocumentCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/documents`, null, [201, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Get Person Avatar Id
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    get_person_avatar_id(
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/avatar_id`, null, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Person Document Delete
     * @param document_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    person_document_delete(
        document_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.CommonB2BProblemErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/documents/${document_id}`, null, [204, 400, 404, 422, 500], 'DELETE', null, options);
    }
    /**
     * Person Bonus Card List
     * @param person_id string
     * @param queryParams Types.TPerson_bonus_card_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    person_bonus_card_list(
        person_id: string,
        queryParams: Types.TPerson_bonus_card_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.BonusCard[]> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TPerson_bonus_card_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/bonus_cards`, queryParams, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Bonus Card Create
     * @param person_id string
     * @param payload Types.BonusCardCreate
     * @param options RequestInit
     * @returns Promise
     */
    bonus_card_create(
        person_id: string,
        payload: Types.BonusCardCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.BonusCardId> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.BonusCardCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/bonus_cards`, null, [201, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Get Bonus Card Detail
     * @param bonus_card_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    get_bonus_card_detail(
        bonus_card_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.BonusCard> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/bonus_cards/${bonus_card_id}`, null, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Bonus Card Delete
     * @param bonus_card_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    bonus_card_delete(
        bonus_card_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/bonus_cards/${bonus_card_id}`, null, [204, 404, 422, 500], 'DELETE', null, options);
    }
    /**
     * Get Assistants
     * @param person_id string
     * @param queryParams Types.TGet_assistantsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_assistants(
        person_id: string,
        queryParams: Types.TGet_assistantsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_PersonDetailsPublic_> |
            ResponseOnStatus<400, Types.CommonB2BProblemErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_assistantsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/assistants`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Get Chiefs
     * @param person_id string
     * @param queryParams Types.TGet_chiefsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_chiefs(
        person_id: string,
        queryParams: Types.TGet_chiefsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_PersonDetailsPublic_> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_chiefsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/chiefs`, queryParams, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Person Create In Trip
     * @param payload Types.PersonCreate
     * @param options RequestInit
     * @returns Promise
     */
    person_create_in_trip(
        payload: Types.PersonCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, unknown> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.PersonCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/persons/create', null, [201, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Create Account In Trip
     * @param options RequestInit
     * @returns Promise
     */
    create_account_in_trip(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, unknown> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/persons/create_account', null, [201, 404, 500], 'POST', null, options);
    }
    /**
     * Create Account In Trip For Yandex Login
     * @param payload Types.AccountCreate
     * @param options RequestInit
     * @returns Promise
     */
    create_account_in_trip_for_yandex_login(
        payload: Types.AccountCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, unknown> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.AccountCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/persons/create_account_for_login', null, [201, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Person Subscribe
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    person_subscribe(
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/subscribe`, null, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * User Email Confirm
     * @param options RequestInit
     * @returns Promise
     */
    user_email_confirm(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/persons/confirm_email', null, [204, 404, 500], 'PATCH', null, options);
    }
    /**
     * Change Company
     * @param person_id string
     * @param queryParams Types.TChange_companyQuery
     * @param options RequestInit
     * @returns Promise
     */
    change_company(
        person_id: string,
        queryParams: Types.TChange_companyQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TChange_companyQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/${person_id}/change_company`, queryParams, [204, 404, 422, 500], 'PATCH', null, options);
    }
    /**
     * Sign Consent
     * @param options RequestInit
     * @returns Promise
     */
    sign_consent(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, unknown> |
            ResponseOnStatus<400, Types.SignConsentErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/persons/consent/sign', null, [201, 400, 404, 500], 'POST', null, options);
    }
    /**
     * Unsign Consent
     * @param options RequestInit
     * @returns Promise
     */
    unsign_consent(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.UnsignConsentErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/persons/consent/unsign', null, [204, 400, 404, 500], 'POST', null, options);
    }
    /**
     * Get Consent Document
     * @param consent_document_id number
     * @param options RequestInit
     * @returns Promise
     */
    get_consent_document(
        consent_document_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<400, Types.ConsentDocumentErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/persons/consent_document/${consent_document_id}`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Readiness
     * @param options RequestInit
     * @returns Promise
     */
    readiness(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/probes/readiness', null, [200], 'GET', null, options);
    }
    /**
     * Саджест по городам
     * @param queryParams Types.TCity_suggestQuery
     * @param options RequestInit
     * @returns Promise
     */
    city_suggest(
        queryParams: Types.TCity_suggestQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.SuggestResponse[]> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TCity_suggestQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/provider/suggest/cities', queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Создание поискового запроса для авиа
     * @param queryParams Types.TCreate_avia_searchQuery
     * @param payload Types.SearchAviaRequestIn
     * @param options RequestInit
     * @returns Promise
     */
    create_avia_search(
        queryParams: Types.TCreate_avia_searchQuery,
        payload: Types.SearchAviaRequestIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.SearchId> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.SearchAviaRequestIn,
            Types.TCreate_avia_searchQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/provider/search/avia', queryParams, [201, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Общая информация о поисковом запросе для авиа
     * @param search_id string
     * @param queryParams Types.TGet_avia_search_infoQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_avia_search_info(
        search_id: string,
        queryParams: Types.TGet_avia_search_infoQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.AviaSearchInfo> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_avia_search_infoQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/avia/${search_id}`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Статус поискового запроса для авиа
     * @param search_id string
     * @param queryParams Types.TGet_avia_search_statusQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_avia_search_status(
        search_id: string,
        queryParams: Types.TGet_avia_search_statusQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.AviaSearchStatus> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_avia_search_statusQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/avia/${search_id}/status`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Количество результатов поискового запроса для авиа
     * @param search_id string
     * @param queryParams Types.TGet_avia_search_result_countQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_avia_search_result_count(
        search_id: string,
        queryParams: Types.TGet_avia_search_result_countQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ProviderSearchResultCount> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_avia_search_result_countQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/avia/${search_id}/count`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Результаты поискового запроса для авиа
     * @param search_id string
     * @param queryParams Types.TGet_avia_search_resultsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_avia_search_results(
        search_id: string,
        queryParams: Types.TGet_avia_search_resultsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.AviaSearchResult> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_avia_search_resultsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/avia/${search_id}/results`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Результаты семейства тарифов для перелета
     * @param key string
     * @param search_id string
     * @param queryParams Types.TGet_avia_fare_familiesQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_avia_fare_families(
        key: string,
        search_id: string,
        queryParams: Types.TGet_avia_fare_familiesQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.AviaSearchResult> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_avia_fare_familiesQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/avia/${search_id}/fare-families/${key}`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Детализация авиа
     * @param key string
     * @param search_id string
     * @param queryParams Types.TGet_avia_search_detailsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_avia_search_details(
        key: string,
        search_id: string,
        queryParams: Types.TGet_avia_search_detailsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.AviaDetailResponse> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_avia_search_detailsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/avia/${search_id}/results/${key}`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Получение списка возможных фильтров поискового запроса для авиа
     * @param search_id string
     * @param queryParams Types.TGet_avia_search_filtersQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_avia_search_filters(
        search_id: string,
        queryParams: Types.TGet_avia_search_filtersQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.FilterItem[]> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_avia_search_filtersQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/avia/${search_id}/filters`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Создание поискового запроса для отелей
     * @param queryParams Types.TCreate_hotel_searchQuery
     * @param payload Types.SearchHotelRequestIn
     * @param options RequestInit
     * @returns Promise
     */
    create_hotel_search(
        queryParams: Types.TCreate_hotel_searchQuery,
        payload: Types.SearchHotelRequestIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.SearchId> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.SearchHotelRequestIn,
            Types.TCreate_hotel_searchQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/provider/search/hotel', queryParams, [201, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Доступные фильтров для поискового запроса
     * @param search_id string
     * @param queryParams Types.TGet_hotel_search_filtersQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_hotel_search_filters(
        search_id: string,
        queryParams: Types.TGet_hotel_search_filtersQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.FilterItem[]> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_hotel_search_filtersQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/hotel/${search_id}/filters`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Количество результатов поискового запроса для отелей
     * @param search_id string
     * @param queryParams Types.TGet_hotel_search_result_countQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_hotel_search_result_count(
        search_id: string,
        queryParams: Types.TGet_hotel_search_result_countQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ProviderSearchResultCount> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_hotel_search_result_countQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/hotel/${search_id}/count`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Общая информация о поисковом запросе для отелей
     * @param search_id string
     * @param queryParams Types.TGet_hotel_search_infoQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_hotel_search_info(
        search_id: string,
        queryParams: Types.TGet_hotel_search_infoQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.HotelSearchInfo> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_hotel_search_infoQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/hotel/${search_id}`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Статус поискового запроса для отелей
     * @param search_id string
     * @param queryParams Types.TGet_hotel_search_statusQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_hotel_search_status(
        search_id: string,
        queryParams: Types.TGet_hotel_search_statusQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.HotelSearchStatus> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_hotel_search_statusQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/hotel/${search_id}/status`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Результаты поискового запроса для отелей
     * @param search_id string
     * @param queryParams Types.TGet_hotel_search_resultsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_hotel_search_results(
        search_id: string,
        queryParams: Types.TGet_hotel_search_resultsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.HotelSearchResult> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_hotel_search_resultsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/hotel/${search_id}/results`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Детализация отеля
     * @param key string
     * @param search_id string
     * @param queryParams Types.TGet_hotel_search_detailsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_hotel_search_details(
        key: string,
        search_id: string,
        queryParams: Types.TGet_hotel_search_detailsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.HotelDetailResponse> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_hotel_search_detailsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/hotel/${search_id}/results/${key}`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Детализация отеля с группировкой тарифов по номерам
     * @param key string
     * @param search_id string
     * @param queryParams Types.TGet_hotel_search_details_with_groupingQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_hotel_search_details_with_grouping(
        key: string,
        search_id: string,
        queryParams: Types.TGet_hotel_search_details_with_groupingQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.HotelDetailWithTariffsResponse> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_hotel_search_details_with_groupingQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/hotel/${search_id}/results_with_grouping/${key}`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Создание поискового запроса для поездов
     * @param queryParams Types.TCreate_rail_searchQuery
     * @param payload Types.SearchRailRequestIn
     * @param options RequestInit
     * @returns Promise
     */
    create_rail_search(
        queryParams: Types.TCreate_rail_searchQuery,
        payload: Types.SearchRailRequestIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.SearchId> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.SearchRailRequestIn,
            Types.TCreate_rail_searchQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/provider/search/rail', queryParams, [201, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Результаты поискового запроса для поездов
     * @param search_id string
     * @param queryParams Types.TGet_rail_search_resultsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_rail_search_results(
        search_id: string,
        queryParams: Types.TGet_rail_search_resultsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.RailSearchResult> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_rail_search_resultsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/rail/${search_id}/results`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Количество результатов поискового запроса для поездов
     * @param search_id string
     * @param queryParams Types.TGet_rail_search_result_countQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_rail_search_result_count(
        search_id: string,
        queryParams: Types.TGet_rail_search_result_countQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ProviderSearchResultCount> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_rail_search_result_countQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/rail/${search_id}/count`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Общая информация о поисковом запросе для поездов
     * @param search_id string
     * @param queryParams Types.TGet_rail_search_infoQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_rail_search_info(
        search_id: string,
        queryParams: Types.TGet_rail_search_infoQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.RailSearchInfo> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_rail_search_infoQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/rail/${search_id}`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Статус поискового запроса для Ж/Д
     * @param search_id string
     * @param queryParams Types.TGet_rail_search_statusQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_rail_search_status(
        search_id: string,
        queryParams: Types.TGet_rail_search_statusQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.RailSearchStatus> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_rail_search_statusQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/rail/${search_id}/status`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Получение списка возможных фильтров поискового запроса для поездов
     * @param search_id string
     * @param queryParams Types.TGet_rail_search_filtersQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_rail_search_filters(
        search_id: string,
        queryParams: Types.TGet_rail_search_filtersQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.FilterItem[]> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_rail_search_filtersQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/rail/${search_id}/filters`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Детализация поезда
     * @param key string
     * @param search_id string
     * @param queryParams Types.TGet_rail_search_train_detailsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_rail_search_train_details(
        key: string,
        search_id: string,
        queryParams: Types.TGet_rail_search_train_detailsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.RailDetailResponse> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_rail_search_train_detailsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/search/rail/${search_id}/results/${key}`, queryParams, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Детальная информация об авиа услуге
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    get_avia_service_details(
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.AviaServiceDetails> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/services/avia/${service_id}`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Детальная информация о жд услуге
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    get_rail_service_details(
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.RailServiceDetails> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/services/rail/${service_id}`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Получение доступных мест в поезде в уже добавленной услуге
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    get_rail_places_availability(
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TrainCarriageDetailResponse> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/services/rail/${service_id}/places-availability`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Детальная информация об отельной услуге
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    get_hotel_service_details(
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.HotelServiceDetails> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/services/hotel/${service_id}`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Get Order Document
     * @param billing_number number
     * @param document_id number
     * @param file_name string
     * @param options RequestInit
     * @returns Promise
     */
    get_order_document(
        billing_number: number,
        document_id: number,
        file_name: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<400, Types.BaseTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/provider/services/get_order_document/${billing_number}/${document_id}/${file_name}`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Purpose List
     * @param options RequestInit
     * @returns Promise
     */
    purpose_list(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.Purpose[]> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/purposes/', null, [200, 500], 'GET', null, options);
    }
    /**
     * Получение отзывов по идентификатору отеля
     * @param queryParams Types.TGet_reviews_for_hotelQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_reviews_for_hotel(
        queryParams: Types.TGet_reviews_for_hotelQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.UGCReviewContent> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_reviews_for_hotelQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/reviews/', queryParams, [200, 422, 500], 'GET', null, options);
    }
    /**
     * Service Create
     * @param payload Types.ServiceCreate
     * @param options RequestInit
     * @returns Promise
     */
    service_create(
        payload: Types.ServiceCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.ServiceId> |
            ResponseOnStatus<400, Types.ServiceCreateErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.ServiceCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/services/', null, [201, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Саджест поставщиков услуг
     * @param queryParams Types.TService_provider_suggestQuery
     * @param options RequestInit
     * @returns Promise
     */
    service_provider_suggest(
        queryParams: Types.TService_provider_suggestQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ServiceProvider[]> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TService_provider_suggestQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/services/providers', queryParams, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Get Info Messages
     * @param queryParams Types.TGet_info_messagesQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_info_messages(
        queryParams: Types.TGet_info_messagesQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_SearchInfoMessage_> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_info_messagesQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/services/info_messages', queryParams, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Service Detail
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    service_detail(
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.Service> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/services/${service_id}`, null, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Service Update
     * @param service_id number
     * @param payload Types.ServiceUpdateIn
     * @param options RequestInit
     * @returns Promise
     */
    service_update(
        service_id: number,
        payload: Types.ServiceUpdateIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ServiceId> |
            ResponseOnStatus<400, Types.ServiceUpdateErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.ServiceUpdateIn,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/services/${service_id}`, null, [200, 400, 404, 422, 500], 'PUT', payload, options);
    }
    /**
     * Service Delete
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    service_delete(
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.ServiceDeleteErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/services/${service_id}`, null, [204, 400, 404, 422, 500], 'DELETE', null, options);
    }
    /**
     * Service Document Add
     * @param service_id number
     * @param queryParams Types.TService_document_addQuery
     * @param payload Types.ServiceDocumentIn
     * @param options RequestInit
     * @returns Promise
     */
    service_document_add(
        service_id: number,
        queryParams: Types.TService_document_addQuery,
        payload: Types.ServiceDocumentIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.ServiceDocumentOperationErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.ServiceDocumentIn,
            Types.TService_document_addQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/services/${service_id}/add_document`, queryParams, [204, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Service Document Change
     * @param service_id number
     * @param queryParams Types.TService_document_changeQuery
     * @param payload Types.ServiceDocumentIn
     * @param options RequestInit
     * @returns Promise
     */
    service_document_change(
        service_id: number,
        queryParams: Types.TService_document_changeQuery,
        payload: Types.ServiceDocumentIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.ServiceDocumentOperationErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.ServiceDocumentIn,
            Types.TService_document_changeQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/services/${service_id}/change_document`, queryParams, [204, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Service Document Remove
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    service_document_remove(
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.ServiceDocumentOperationErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/services/${service_id}/remove_document`, null, [204, 400, 404, 422, 500], 'POST', null, options);
    }
    /**
     * Service Reserve
     * @param service_id number
     * @param payload Types.ServiceReserveIn
     * @param options RequestInit
     * @returns Promise
     */
    service_reserve(
        service_id: number,
        payload: Types.ServiceReserveIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.ServiceReserveErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.ServiceReserveIn,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/services/${service_id}/reserve`, null, [204, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Доступные документы для добавления в услугу
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    allowed_documents(
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TravellerDocument[]> |
            ResponseOnStatus<400, Types.CommonB2BProblemErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/services/${service_id}/allowed_documents`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Доступные документы для добавления в услугу
     * @param service_id number
     * @param options RequestInit
     * @returns Promise
     */
    get_allowed_documents_with_ext_persons(
        service_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.AllowedDocuments> |
            ResponseOnStatus<400, Types.CommonB2BProblemErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/services/${service_id}/allowed_documents_with_ext_persons`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Get Settlement
     * @param geoid number
     * @param options RequestInit
     * @returns Promise
     */
    get_settlement(
        geoid: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.Settlement> |
            ResponseOnStatus<400, Types.SettlementErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/settlements/${geoid}`, null, [200, 400, 422, 500], 'GET', null, options);
    }
    /**
     * Автодополнение по людям
     * @param queryParams Types.TSuggest_personsQuery
     * @param options RequestInit
     * @returns Promise
     */
    suggest_persons(
        queryParams: Types.TSuggest_personsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PersonSuggestResponse> |
            ResponseOnStatus<400, Types.PersonSuggestErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TSuggest_personsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/suggest/persons', queryParams, [200, 400, 422, 500], 'GET', null, options);
    }
    /**
     * Автодополнение по странам / гражданствам
     * @param queryParams Types.TCountry_suggestQuery
     * @param options RequestInit
     * @returns Promise
     */
    country_suggest(
        queryParams: Types.TCountry_suggestQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.CountrySuggestResponse> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TCountry_suggestQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/suggest/countries', queryParams, [200, 422, 500], 'GET', null, options);
    }
    /**
     * Автодополнение по населенным пунктам
     * @param queryParams Types.TSettlement_suggestQuery
     * @param options RequestInit
     * @returns Promise
     */
    settlement_suggest(
        queryParams: Types.TSettlement_suggestQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.SettlementSuggestResponse> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TSettlement_suggestQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/suggest/settlements', queryParams, [200, 422, 500], 'GET', null, options);
    }
    /**
     * Автодополнение по компаниям
     * @param queryParams Types.TCompany_suggestQuery
     * @param options RequestInit
     * @returns Promise
     */
    company_suggest(
        queryParams: Types.TCompany_suggestQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.CompanySuggestResponse> |
            ResponseOnStatus<403, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TCompany_suggestQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/suggest/companies', queryParams, [200, 403, 422, 500], 'GET', null, options);
    }
    /**
     * Автодополнение по компаниям из API Контура по реквизитам компании
     * @param queryParams Types.TKontur_company_suggestQuery
     * @param options RequestInit
     * @returns Promise
     */
    kontur_company_suggest(
        queryParams: Types.TKontur_company_suggestQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.CompanySuggestInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TKontur_company_suggestQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/suggest/kontur_companies', queryParams, [200, 422, 500], 'GET', null, options);
    }
    /**
     * Get Switches
     * @param options RequestInit
     * @returns Promise
     */
    get_switches(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/switches/', null, [200], 'GET', null, options);
    }
    /**
     * Get Airline Info
     * @param options RequestInit
     * @returns Promise
     */
    get_airline_info(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<429, Types.TravelErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/travel/airline_info', null, [200, 429, 500], 'GET', null, options);
    }
    /**
     * Get Train Details
     * @param queryParams Types.TGet_train_detailsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_train_details(
        queryParams: Types.TGet_train_detailsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<429, Types.TravelErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_train_detailsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/travel/get_train_details', queryParams, [200, 422, 429, 500], 'GET', null, options);
    }
    /**
     * Get Restrictions
     * Новая ручка covid restriction
     * @param queryParams Types.TGet_restrictionsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_restrictions(
        queryParams: Types.TGet_restrictionsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<429, Types.TravelErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_restrictionsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/travel/restrictions', queryParams, [200, 422, 429, 500], 'GET', null, options);
    }
    /**
     * Get Covid Restrictions
     * @param queryParams Types.TGet_covid_restrictionsQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_covid_restrictions(
        queryParams: Types.TGet_covid_restrictionsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.RestrictionsInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<429, Types.TravelErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_covid_restrictionsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/travel/covid_restrictions', queryParams, [200, 422, 429, 500], 'GET', null, options);
    }
    /**
     * Get Travel Policy List
     * @param queryParams Types.TGet_travel_policy_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_travel_policy_list(
        queryParams: Types.TGet_travel_policy_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_TravelPolicy_> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_travel_policy_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/travel_policies/', queryParams, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Create Travel Policy
     * @param payload Types.TravelPolicyCreate
     * @param options RequestInit
     * @returns Promise
     */
    create_travel_policy(
        payload: Types.TravelPolicyCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.TravelPolicyId> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.TravelPolicyCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/travel_policies/', null, [201, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Get Travel Policy Detailed
     * @param travel_policy_id number
     * @param queryParams Types.TGet_travel_policy_detailedQuery
     * @param options RequestInit
     * @returns Promise
     */
    get_travel_policy_detailed(
        travel_policy_id: number,
        queryParams: Types.TGet_travel_policy_detailedQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TravelPolicyDetailed> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TGet_travel_policy_detailedQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/travel_policies/${travel_policy_id}`, queryParams, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Edit Travel Policy
     * @param travel_policy_id number
     * @param payload Types.TravelPolicyUpdate
     * @param options RequestInit
     * @returns Promise
     */
    edit_travel_policy(
        travel_policy_id: number,
        payload: Types.TravelPolicyUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.TravelPolicyUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/travel_policies/${travel_policy_id}`, null, [204, 404, 422, 500], 'PUT', payload, options);
    }
    /**
     * Delete Travel Policy
     * @param travel_policy_id number
     * @param options RequestInit
     * @returns Promise
     */
    delete_travel_policy(
        travel_policy_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/travel_policies/${travel_policy_id}`, null, [204, 404, 422, 500], 'DELETE', null, options);
    }
    /**
     * Get Available Travel Policies
     * @param options RequestInit
     * @returns Promise
     */
    get_available_travel_policies(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TravelPolicyName[]> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/travel_policies/available', null, [200, 404, 500], 'GET', null, options);
    }
    /**
     * Suggest Travel Policies
     * @param queryParams Types.TSuggest_travel_policiesQuery
     * @param options RequestInit
     * @returns Promise
     */
    suggest_travel_policies(
        queryParams: Types.TSuggest_travel_policiesQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TravelPolicySuggestResult> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TSuggest_travel_policiesQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/travel_policies/suggest', queryParams, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Trip List
     * @param queryParams Types.TTrip_listQuery
     * @param options RequestInit
     * @returns Promise
     */
    trip_list(
        queryParams: Types.TTrip_listQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_Trip_> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TTrip_listQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/trips/', queryParams, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Trip Create
     * @param payload Types.TripCreate
     * @param options RequestInit
     * @returns Promise
     */
    trip_create(
        payload: Types.TripCreate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<201, Types.TripId> |
            ResponseOnStatus<400, Types.CreateOrUpdateTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.TripCreate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/trips/', null, [201, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Trip List From Staff
     * @param queryParams Types.TTrip_list_from_staffQuery
     * @param options RequestInit
     * @returns Promise
     */
    trip_list_from_staff(
        queryParams: Types.TTrip_list_from_staffQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PaginatedResponse_Trip_> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            Types.TTrip_list_from_staffQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/trips/from_staff', queryParams, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Trip Detail
     * @param trip_id number
     * @param options RequestInit
     * @returns Promise
     */
    trip_detail(
        trip_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.Trip> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}`, null, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Trip Update
     * @param trip_id number
     * @param payload Types.TripUpdate
     * @param options RequestInit
     * @returns Promise
     */
    trip_update(
        trip_id: number,
        payload: Types.TripUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TripId> |
            ResponseOnStatus<400, Types.CreateOrUpdateTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.TripUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}`, null, [200, 400, 404, 422, 500], 'PUT', payload, options);
    }
    /**
     * Person Trip Taxi Detail
     * @param trip_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_taxi_detail(
        trip_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PersonTripTaxiState> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/taxi_details`, null, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Person Trip Detail
     * @param trip_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_detail(
        trip_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PersonTrip> |
            ResponseOnStatus<400, Types.PersonTripErrorInfo> |
            ResponseOnStatus<404, Types.TripDetailNotFoundErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Person Trip Update
     * @param trip_id number
     * @param person_id string
     * @param payload Types.PersonTripUpdate
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_update(
        trip_id: number,
        person_id: string,
        payload: Types.PersonTripUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TripId> |
            ResponseOnStatus<400, Types.PersonTripUpdateErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.PersonTripUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}`, null, [200, 400, 404, 422, 500], 'PUT', payload, options);
    }
    /**
     * Person Trip Partial Update
     * @param trip_id number
     * @param person_id string
     * @param payload Types.PersonTripPartialUpdate
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_partial_update(
        trip_id: number,
        person_id: string,
        payload: Types.PersonTripPartialUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.TripId> |
            ResponseOnStatus<400, Types.PersonTripUpdateErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.PersonTripPartialUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}`, null, [200, 400, 404, 422, 500], 'PATCH', payload, options);
    }
    /**
     * Person Trip Services
     * @param trip_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_services(
        trip_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.PersonTripServices> |
            ResponseOnStatus<400, Types.PersonTripErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/services`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Person Trip Send To Verification
     * @param trip_id number
     * @param person_id string
     * @param payload Types.WorkingHolidaysInfo
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_send_to_verification(
        trip_id: number,
        person_id: string,
        payload: Types.WorkingHolidaysInfo,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.PersonTripVerificationErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.WorkingHolidaysInfo,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/send_to_verification`, null, [204, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Person Trip Execute Services
     * @param trip_id number
     * @param person_id string
     * @param payload Types.WorkingHolidaysInfo
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_execute_services(
        trip_id: number,
        person_id: string,
        payload: Types.WorkingHolidaysInfo,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.PersonTripExecuteServiceErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.WorkingHolidaysInfo,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/execute_services`, null, [204, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Person Trip Execute Extra Services
     * @param trip_id number
     * @param person_id string
     * @param payload Types.WorkingHolidaysInfo
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_execute_extra_services(
        trip_id: number,
        person_id: string,
        payload: Types.WorkingHolidaysInfo,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.PersonTripExecuteServiceErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.WorkingHolidaysInfo,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/execute_extra_services`, null, [204, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Person Trip Cancel
     * @param trip_id number
     * @param person_id string
     * @param payload Types.PersonTripCancel
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_cancel(
        trip_id: number,
        person_id: string,
        payload: Types.PersonTripCancel,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.PersonTripCancelErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.PersonTripCancel,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/cancel`, null, [204, 400, 404, 422, 500], 'POST', payload, options);
    }
    /**
     * Person Trip Approve
     * @param trip_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_approve(
        trip_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<400, Types.CommonB2BProblemErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/approve`, null, [204, 400, 404, 422, 500], 'POST', null, options);
    }
    /**
     * Person Trip Document Add
     * @param trip_id number
     * @param document_id number
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_document_add(
        trip_id: number,
        document_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/documents/${document_id}`, null, [204, 404, 422, 500], 'PUT', null, options);
    }
    /**
     * Person Trip Document Remove
     * @param trip_id number
     * @param document_id number
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_document_remove(
        trip_id: number,
        document_id: number,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/documents/${document_id}`, null, [204, 404, 422, 500], 'DELETE', null, options);
    }
    /**
     * Create Manager Chat
     * @param trip_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    create_manager_chat(
        trip_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ManagerChatId> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/create_manager_chat`, null, [200, 404, 422, 500], 'POST', null, options);
    }
    /**
     * Person Trip Join Chat
     * @param trip_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_join_chat(
        trip_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.ChatId> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/join_chat`, null, [200, 404, 422, 500], 'POST', null, options);
    }
    /**
     * Person Trip Get Holidays
     * @param trip_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_get_holidays(
        trip_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.Holidays> |
            ResponseOnStatus<400, Types.PersonTripHolidaysErrorInfo> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/get-holidays`, null, [200, 400, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Person Trip Update Taxi Dates
     * @param trip_id number
     * @param person_id string
     * @param payload Types.TaxiDates
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_update_taxi_dates(
        trip_id: number,
        person_id: string,
        payload: Types.TaxiDates,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            Types.TaxiDates,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/update_taxi_dates`, null, [204, 404, 422, 500], 'PATCH', payload, options);
    }
    /**
     * Person Trip Get Allowed Tp
     * @param trip_id number
     * @param person_id string
     * @param options RequestInit
     * @returns Promise
     */
    person_trip_get_allowed_tp(
        trip_id: number,
        person_id: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.AllowedTravelPolicies> |
            ResponseOnStatus<404, Types.BaseTripForbiddenErrorInfo> |
            ResponseOnStatus<422, Types.HTTPValidationError> |
            ResponseOnStatus<500, Types.BaseTripInternalErrorInfo>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/trips/${trip_id}/persons/${person_id}/get_allowed_tp`, null, [200, 404, 422, 500], 'GET', null, options);
    }
    /**
     * Subscribe App
     * @param payload Types.XivaAppSubscriptionIn
     * @param options RequestInit
     * @returns Promise
     */
    subscribe_app(
        payload: Types.XivaAppSubscriptionIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, Types.XivaSubscriptionOut> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.XivaAppSubscriptionIn,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/xiva/subscribe/app', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Unsubscribe App
     * @param uuid string
     * @param options RequestInit
     * @returns Promise
     */
    unsubscribe_app(
        uuid: string,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<204, null> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >(`/api/xiva/unsubscribe/app/${uuid}`, null, [204, 422], 'POST', null, options);
    }
    /**
     * Unistat
     * @param options RequestInit
     * @returns Promise
     */
    external_unistat(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/external/monitoring/unistat', null, [200], 'GET', null, options);
    }
    /**
     * Arq Stats
     * @param queryParams Types.TExternal_arq_statsQuery
     * @param options RequestInit
     * @returns Promise
     */
    external_arq_stats(
        queryParams: Types.TExternal_arq_statsQuery,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            Types.TExternal_arq_statsQuery
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/external/monitoring/arq', queryParams, [200, 422], 'GET', null, options);
    }
    /**
     * Notification About Message
     * Уведомление о получении нового сообщения в чате с аэроклубом
     * @param payload Types.NotificationSyncIn
     * @param options RequestInit
     * @returns Promise
     */
    external_notification_about_message(
        payload: Types.NotificationSyncIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.NotificationSyncIn,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/external/notifications/sync', null, [200, 422], 'PUT', payload, options);
    }
    /**
     * Trip Event
     * @param payload Types.EventIn
     * @param options RequestInit
     * @returns Promise
     */
    external_trip_event(
        payload: Types.EventIn,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.EventIn,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/external/notifications/event', null, [200, 422], 'POST', payload, options);
    }
    /**
     * Webhook
     * @param options RequestInit
     * @returns Promise
     */
    external_webhook(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/external/bot/webhook', null, [200, 422], 'POST', null, options);
    }
    /**
     * Update Support Person
     * @param payload Types.SamsaraSupportUpdate
     * @param options RequestInit
     * @returns Promise
     */
    external_update_support_person(
        payload: Types.SamsaraSupportUpdate,
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, unknown> |
            ResponseOnStatus<422, Types.HTTPValidationError>,
            Types.SamsaraSupportUpdate,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/external/callbacks/samsara/support/', null, [200, 422], 'POST', payload, options);
    }
}
