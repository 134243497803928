export const en = {
    page_title: 'Room selection',
    services_added: 'Choosen rooms',
    services: {
        one: '{count} room, total price is {totalPrice}',
        some: '{count} rooms, total price is {totalPrice}',
        many: '{count} rooms, total price is {totalPrice}',
        none: 'No room chosen yet',
    },
};
