import { FC } from 'react';

import { usePlatform } from 'hooks/usePlatform';
import { Drawer } from 'shared/ui/Drawer';
import { Popup } from 'shared/ui/Popup';

import { cn } from './PopupPlatform.cn';
import { PopupPlatformProps } from './PopupPlatform.types';

export const PopupPlatform: FC<PopupPlatformProps> = props => {
    const {
        className,
        children,
        popupDirection,
        drawerDirection,
    } = props;

    const { isDesktop } = usePlatform();

    return (
        <>
            {isDesktop ? (
                <Popup
                    {...props}
                    className={cn(null, [className])}
                    direction={popupDirection}
                >
                    {children}
                </Popup>
            ) : (
                <Drawer
                    {...props}
                    className={cn(null, [className])}
                    direction={drawerDirection}
                >
                    {children}
                </Drawer>
            )}
        </>
    );
};

PopupPlatform.displayName = cn();
