import { useContext, useEffect } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';

import { SuggestProps } from 'components/Suggest';
import { Item } from 'shared/ui/Suggest';

import { SwaggerContext } from '../../../../SwaggerContext';

import { fetchCity } from './fetchDefaultValue/fetchCity';
import { fetchPerson } from './fetchDefaultValue/fetchPerson';
import { fetchTravelPolicy } from './fetchDefaultValue/fetchTravelPolicy';

const getValue = (value: unknown) => {
    if (typeof value === 'number' || typeof value === 'string') {
        return {
            fetching: true,
            formattedValue: [],
        };
    }

    return {
        fetching: false,
        formattedValue: value as Nullable<Item | Item[]>,
    };
};

type UseSetDefaultValueOptions = {
   value: unknown;
   name: string;
   setValue: UseFormSetValue<FieldValues>;
   type: SuggestProps['type']
};

export const useSetDefaultValue = ({ value, name, setValue, type }: UseSetDefaultValueOptions) => {
    const { api } = useContext(SwaggerContext);

    useEffect(() => {
        async function setFetchedValue() {
            if (typeof value === 'number' || typeof value === 'string') {
                switch (type) {
                    case 'cities': {
                        const defaultValue = await fetchCity({ value, api });

                        setValue(name, defaultValue);

                        break;
                    }

                    case 'persons': {
                        let defaultValue = await fetchPerson({ value, api });

                        setValue(name, defaultValue);

                        break;
                    }

                    case 'travelPolicies': {
                        let defaultValue = await fetchTravelPolicy({ value, api });

                        setValue(name, defaultValue);
                    }
                }
            }
        }

        setFetchedValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return getValue(value);
};
