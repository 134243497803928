import { config } from 'config';
import { Holidays, PersonTrip } from 'services/SwaggerApi';

import { TripHolidaysScenario } from '../ExecuteTripButton.types';

type GetInitialHolidaysScenario = (workingHolidays: PersonTrip['working_holidays'], holidays: Nullable<Holidays>) => TripHolidaysScenario;

const { isB2B } = config;

export const getInitialHolidaysScenario: GetInitialHolidaysScenario = (workingHolidays, holidays) => {
    if (isB2B) {
        return TripHolidaysScenario.NO_HOLIDAYS;
    }

    // бек присылает null если значение не заполнено - надо его заполнить
    // пустая строка - если пользователь уже указывал значение
    if (typeof workingHolidays === 'string') {
        return TripHolidaysScenario.REASON_ALREADY_SPECIFIED;
    }

    if (holidays?.holidays.length === 0) {
        return TripHolidaysScenario.NO_HOLIDAYS;
    }

    return TripHolidaysScenario.WORKING_ON_HOLIDAYS;
};
