export const ru = {
    general_rules: 'Общие правила',
    avia_rules: 'Правила для авиабилетов',
    rail_rules: 'Правила для ж/д билетов',
    hotel_rules: 'Правила для отелей',
    travel_policy_name: 'Название тревел-политики',
    violation_label: 'При нарушении тревел‑политики',
    approval_needed_label: 'При покупке в рамках тревел‑политики',
    violation_allowed: 'Показывать и отправлять на согласование',
    violation_forbidden: 'Не показывать варианты с нарушением тревел-политики',
    approval_needed: 'Отправлять на согласование',
    approval_not_needed: 'Покупать без согласования',
    additional_conditions: 'Дополнительные условия',
    max_price: 'Не дороже {price} ₽ в один конец',
    max_price_per_night: 'Не дороже {price} ₽ за ночь',
    cheapest_more_percent: 'Дороже самых дешёвых не более, чем на {percent}%',
    max_transfer_duration_hours: 'Максимальная длительность пересадки {hours} часа',
    avia_book_before_days: 'Не позднее, чем за {days} дней до вылета',
    rail_book_before_days: 'Не позднее, чем за {days} дней до отправления',
    hotel_book_before_days: 'Не позднее, чем за {days} дней до заселения',
    refundable_only: 'Только с возможностью возврата',
    hotel_refundable_only: 'Только с возможностью отмены бронирования',
    exchangeable_only: 'Только с возможностью обмена',
    with_baggage_only: 'Только с багажом',
    avia_business_days_only: 'Только с вылетом и прилетом в будние дни',
    rail_business_days_only: 'Только с отправлением и прибытием в будние дни',
    hotel_business_days_only: 'Только с бронированием в будние дни',
    round_trips_only: 'Только маршруты туда-обратно',
    available_classes: 'Доступные классы: {classes}',
    available_categories: 'Доступные типы вагона: {categories}',
    available_stars: 'Доступное количество звезд: {stars}',
    max_price_direction: 'Не дороже {price} ₽ для направления {direction}',
    no_resorts: 'Кроме курортных отелей',
    no_restrictions: 'Без ограничений',
};
