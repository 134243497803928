export const ru = {
    add_participant: 'Добавить участника',
    edit_trip_date: 'Изменить даты поездки',
    trip_number: 'Заявка № {trip_id}',
    copied: 'Номер заявки скопирован',
    cancel: 'Нет',
    cancel_confirmation: 'Вы уверены, что хотите отменить заявку?',
    cancel_confirmation_with_services: 'Мы запустим отмену оформленных услуг, если такая возможность будет доступна. Как правило, отмена услуг невозможна или происходит со штрафами. Для уточнения возможных штрафов перед отменой, обратитесь в поддержку.',
    cancel_confirmation_reason: 'Расскажите, пожалуйста, почему отменяете заявку на командировку',
    cancel_trip: 'Отменить командировку',
    confirm: 'Да, отменить',
    close: 'Закрыть',
    route: 'Маршрут поездки',
    trip_details: 'Подробности командировки',
    active: 'Активные',
    cancelled: 'Отмененные',
};
