import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';

import { AddCompanionDocument } from 'components/AddCompanionDocument';
import { Card } from 'components/Card';
import { Dialog } from 'components/Dialog';
import { DocumentsList } from 'components/DocumentsList';
import { FormProvider } from 'components/FormField2';
import { HeaderPage } from 'components/HeaderPage';
import { IconTrash } from 'components/Icon';
import { Page } from 'components/Page';
import { ContactPersonalInfo } from 'components/PageCompanionAdd/ContactPersonalInfo/ContactPersonalInfo';
import { MainPersonalInfo } from 'components/PageCompanionAdd/MainPersonalInfo/MainPersonalInfo';
import { schema, SchemaType } from 'components/PageCompanionAdd/PageCompanionAdd.schema';
import { i18n as i18nProfilePage } from 'components/PageProfile/PageProfile.i18n';
import { i18n as i18nProfileTab } from 'components/PageProfile/TabProfile/TabProfile.i18n';
import { withRemote } from 'hocs/withRemote';
import { useExtPersonDelete } from 'hooks/useExtPersonDelete';
import { useExtPersonGet } from 'hooks/useExtPersonGet';
import { useExtPersonUpdate } from 'hooks/useExtPersonUpdate';
import { usePlatform } from 'hooks/usePlatform';
import { i18nAction } from 'i18n/i18nAction';
import { RouteCompanion } from 'routes/RouteCompanion';
import { RouteMyActiveTrips } from 'routes/RouteMyActiveTrips';
import { RouteProfile } from 'routes/RouteProfile';
import { Container } from 'shared/ui/Container';
import { ErrorState } from 'shared/ui/ErrorState';
import { Text } from 'shared/ui/Text';
import { getTypedI18nLang } from 'utils/i18n';
import { isLoading } from 'utils/Loadable';
import { serializeDate } from 'utils/serializeDate';

import { cn } from './PageCompanion.cn';
import { i18n } from './PageCompanion.i18n';
import { PageCompanionRendererProps } from './PageCompanion.types';

import './PageCompanion.css';

export const PageCompanionRenderer: FC<PageCompanionRendererProps> = props => {
    const { extPerson, extPersonId, showModal } = props;
    const { isMobile } = usePlatform();

    const {
        first_name_en,
        last_name_en,
        first_name,
        last_name,
        documents,
        ext_person_uuid,
    } = extPerson;

    const methods = useForm<SchemaType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues: {
            first_name,
            last_name,
            first_name_ac_en: first_name_en,
            last_name_ac_en: last_name_en,
            gender: extPerson.gender,
            date_of_birth: new Date(extPerson.date_of_birth),
            email: extPerson.email || undefined,
            phone_number: extPerson.phone_number || undefined,
        },
    });

    const { push } = useHistory();

    const [isDialogVisible, setDialogVisible] = useToggle(false);
    const [modalAddDocumentVisible, toggleModalAddDocumentVisible] = useToggle(true);

    const [deleteExtPerson] = useExtPersonDelete({
        onSuccess: () => {
            push(RouteProfile.getPath({}, { tab: 'companions' }));
        },
        options: { hideValidationErrors: true },
    });
    const [updateExtPerson, updateState, { error }] = useExtPersonUpdate({
        onSuccess: () => {
            push(RouteProfile.getPath({}, { tab: 'companions' }));
        },
        options: { hideValidationErrors: true },
    });

    const handleSubmit: SubmitHandler<SchemaType> = values => {
        const { last_name_ac_en, first_name_ac_en, date_of_birth, ...restValues } = values;

        updateExtPerson(ext_person_uuid, {
            ...restValues,
            date_of_birth: serializeDate(date_of_birth)!,
            first_name_en: first_name_ac_en,
            last_name_en: last_name_ac_en,
        });
    };

    const handleDelete = useCallback(() => {
        deleteExtPerson(ext_person_uuid);
        setDialogVisible(false);
    }, [deleteExtPerson, ext_person_uuid, setDialogVisible]);

    const displayedName = getTypedI18nLang() === 'en' ?
        [first_name_en, last_name_en].join(' ') :
        [first_name, last_name].join(' ');

    const onModalCloseHandle = useCallback(() => {
        push(RouteCompanion.getPath({ extPersonId: ext_person_uuid }, {}));
        toggleModalAddDocumentVisible();
    }, [toggleModalAddDocumentVisible, push, ext_person_uuid]);

    const isModalAddDocumentVisible = extPerson.documents.length === 0 && showModal === 'add_companion_document';

    const headerActions = (
        <>
            <Button
                size="m"
                view="outline"
                width={isMobile ? 'max' : undefined}
                onClick={setDialogVisible}
            >
                <IconTrash size="m" /> {i18n('delete_companion')}
            </Button>
            <Dialog
                close={i18nAction('cancel')}
                closeButtonView="ghost"
                confirm={i18nAction('remove')}
                title={`${i18n('delete_companion')}?`}
                visible={isDialogVisible}
                onClose={setDialogVisible}
                onConfirm={handleDelete}
            />
        </>
    );

    return (
        <Page className={cn()} data-testid="Companion-Page">
            <HeaderPage actions={headerActions} title={displayedName} />
            <Container className={cn('Container')}>
                <div className={cn('Main')}>
                    <FormProvider error={error} methods={methods} schema={schema}>
                        <form>
                            <div className={cn('Fields')}>
                                <MainPersonalInfo />
                                <ContactPersonalInfo />
                            </div>
                        </form>
                    </FormProvider>
                    <Card className={cn('Documents')}>
                        {documents.length > 0 && (
                            <div className={cn('DocumentsTitle')}>
                                <Text typography="title-s">
                                    {i18nProfilePage('documents')}
                                </Text>
                                <Text color="secondary" typography="body-s">
                                    {i18nProfilePage('documents_description')}
                                </Text>
                            </div>
                        )}
                        <DocumentsList
                            items={extPerson.documents}
                            personId={extPersonId}
                            type="extPerson"
                        />
                    </Card>
                </div>
                <Card className={cn('Aside')}>
                    <Button
                        progress={isLoading(updateState)}
                        size="l"
                        view="brand"
                        onClick={methods.handleSubmit(handleSubmit)}
                    >
                        {i18nAction('save')}
                    </Button>
                    <Text align="center" as="div" color="secondary" typography="caption-m">
                        {i18nProfileTab('save_changes_desc')}
                    </Text>
                </Card>
            </Container>
            { isModalAddDocumentVisible && (
                <AddCompanionDocument
                    data-testid="ModalAddExtPersonDocument"
                    extPersonId={extPersonId}
                    visible={modalAddDocumentVisible}
                    onModalClose={onModalCloseHandle}
                />
            )}
        </Page>
    );
};

const PageCompanionRendererRemote = withRemote(PageCompanionRenderer);

export const PageCompanion: Page<RouteCompanion> = props => {
    const {
        params: { extPersonId },
        search: { modal },
    } = props;

    const extPerson = useExtPersonGet(extPersonId);

    const { push } = useHistory();

    const onActionClick = useCallback(() => {
        push(RouteMyActiveTrips.getPath({}, {}));
    }, [push]);

    return (
        <PageCompanionRendererRemote
            spinner
            extPerson={extPerson}
            extPersonId={extPersonId}
            fallbackElement={
                <div className={cn()}>
                    <div className={cn('Error')}>
                        <ErrorState
                            withChatSupport
                            actionTitle={i18n('to_the_main_action')}
                            description={i18n('not_found_error_description')}
                            imageType="not_found"
                            title={i18n('not_found_error_title')}
                            onActionClick={onActionClick}
                        />
                    </div>
                </div>
            }
            showModal={modal}
        />
    );
};

PageCompanion.displayName = cn();
