export const ru = {
    baggage: 'Багаж',
    quantity: ' ×{count}',
    hand_baggage: 'Ручная кладь',
    no_baggage: 'Без багажа',
    no_baggage_info: 'Нет информации о багаже',
    no_hand_baggage: 'Без ручной клади',
    no_hand_baggage_info: 'Нет информации о ручной клади',
    weight: '{weight}кг',
};
