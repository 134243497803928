export const ru = {
    fio: 'ФИО',
    citizenship: 'Гражданство',
    expires_on: 'Годен до',
    first_name: 'Имя',
    issued_on: 'Дата выдачи',
    last_name: 'Фамилия',
    middle_name: 'Отчество',
    number: 'Номер',
    series: 'Серия',
    series_and_number: 'Серия и номер',
};
