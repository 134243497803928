import { FC } from 'react';
import { Textinput } from '@yandex-lego/components/Textinput/desktop/bundle';
import { useDebouncedCallback } from 'use-debounce';

import { cn } from './FilterFieldTextinput.cn';
import { FilterFieldTextinputProps } from './FilterFieldTextinput.types';

export const FilterFieldTextinput: FC<FilterFieldTextinputProps> = props => {
    const { label, throttle = 0 } = props;
    const { value, onChange } = props.input;

    const { callback: handleChange } = useDebouncedCallback(
        event => onChange(event.target.value),
        throttle,
    );

    return (
        <Textinput
            className={cn(null, [props.className])}
            label={label}
            size="m"
            value={value}
            view="default"
            onChange={handleChange}
        />
    );
};

FilterFieldTextinput.displayName = cn();
