import { FC } from 'react';

import { Spinner } from 'components/Spinner';

import { cn } from '../Root.cn';

import { LoadingProps } from './Loading.types';

import './Loading.css';

export const Loading: FC<LoadingProps> = props => {
    const {
        className,
    } = props;

    return (
        <div className={cn('Loading', [className])}>
            <Spinner />
        </div>
    );
};
