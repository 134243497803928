import { useGroupAvailablePersonsInfinite } from 'hooks/useGroupAvailablePersonsInfinite';
import { CompanyName } from 'services/SwaggerApi';
import { foldSuccess, isLoading } from 'utils/Loadable';

type UseAvailablePersonsProps = {
    companyId: CompanyName['company_id'];
};

export function useAvailablePersons(props: UseAvailablePersonsProps) {
    const { companyId } = props;

    const [availablePersonsList, { isFetchingNextPage, fetchNextPage, hasNextPage }] = useGroupAvailablePersonsInfinite(
        {
            company_id: companyId,
        },
    );

    const isAvailablePersonsLoading = isLoading(availablePersonsList);
    const availablePersonsData = foldSuccess(
        availablePersonsList,
        data => data,
        () => ({ items: [], count: 0 }),
    );
    const availablePersons = availablePersonsData.items;
    const availablePersonsCount = availablePersonsData.count;

    return {
        availablePersons,
        availablePersonsCount,
        isAvailablePersonsLoading,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    };
}
