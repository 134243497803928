import { FC } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';

import { TrainFilterKey } from 'api/models/filters/TrainFilterKey';
import { FieldSort, getSortOptionValue } from 'components/FieldSort';
import { FilterFieldCheckbox } from 'components/FilterFieldCheckbox';
import { FilterFieldOption } from 'components/FilterFieldOption';
import { FilterFieldTime } from 'components/FilterFieldTime';
import { FilterGroup } from 'components/FilterGroup';
import { withProps } from 'hocs/withProps';
import { useAvailableFilters } from 'hooks/useAvailableFilters';
import { useCallbackWithScrollToTop } from 'hooks/useCallbackWithScrollToTop';
import { i18nAction } from 'i18n/i18nAction';
import { Divider } from 'shared/ui//Divider';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import { usePrepareFilters } from './TrainFilters.hooks/usePrepareFilters';
import { cn } from './TrainFilters.cn';
import { i18n } from './TrainFilters.i18n';
import { TrainFiltersFormValues, TrainFiltersProps } from './TrainFilters.types';

import './TrainFilters.css';

const Header: FC<{ slug: Parameters<typeof i18n>[0] }> = ({ slug }) =>
    <Text className={cn('Header')}>{i18n(slug)}</Text>;
const FieldOptions = withProps({ component: FilterFieldOption }, cn('FilterOptions'))(Field);
const FieldTime = withProps({ component: FilterFieldTime }, cn('FilterOptions'))(Field);

const defaultValues: TrainFiltersFormValues = {
    train_names: [],
    train_categories: [],
    carriage_types: [],
    carriage_owners: [],
    departure_from_there: [],
    arrival_to_there: [],
    departure_there_timespan: [],
    arrival_there_timespan: [],
};

const FAST_TRAINS = ['САПСАН', 'ЛАСТОЧКА', 'СТРИЖ'];

export const TrainFilters: FC<TrainFiltersProps> = props => {
    const {
        filters: filtersRaw,
        search,
        instant,
        onSubmit,
        onChange,
    } = props;

    const isAvailable = useAvailableFilters<TrainFilterKey>(filtersRaw);
    const filters = usePrepareFilters(filtersRaw);

    const initialValues = {
        ...defaultValues,
        ...search,
        sortOptionValue: getSortOptionValue(search, 'Rail'),
    };

    const fastTrainsFilter = filters.train_names?.filter(({ caption }) => FAST_TRAINS.includes(caption)) ?? [];
    const hasTrainCategories = filters.train_categories && filters.train_categories.length > 0;
    const hasTrainCarriageTypes = filters.carriage_types && filters.carriage_types.length > 0;
    const hasTimeSpanFilters = (
        isAvailable('departure_there_timespan') &&
        isAvailable('arrival_there_timespan')
    );
    const hasRailStationsFilters = (
        isAvailable('departure_from_there') &&
        isAvailable('arrival_to_there')
    );
    const hasCarriesFilters = isAvailable('carriers');
    const hasTrainNamesFilters = isAvailable('train_names');

    const onSubmitWithScrollToTop = useCallbackWithScrollToTop(onSubmit);

    return (
        <Form<TrainFiltersFormValues> {...props} initialValues={initialValues} onSubmit={onSubmitWithScrollToTop}>
            {render => {
                const isCollapsedCarriageOwnerFilters = render.values.carriage_owners?.length;
                const isCollapsedTrainCategoryFilters = render.values.train_categories?.length;
                const isCollapsedTrainStationFilters =
                    render.values.departure_from_there?.length ||
                    render.values.arrival_to_there?.length;

                return (
                    <div className={cn(null, [props.className])}>
                        <form onSubmit={render.handleSubmit}>
                            {instant && <FormSpy onChange={onChange} />}

                            <Field
                                className={cn('FieldSort')}
                                component={FieldSort}
                                name="sortOptionValue"
                                type="Rail"
                            />

                            {fastTrainsFilter.length > 0 && (
                                <>
                                    <div className={cn('QuickFilters')}>
                                        {fastTrainsFilter.map(fastTrain => (
                                            <Field
                                                key={fastTrain.target_id}
                                                activeValue={fastTrain.target_id}
                                                component={FilterFieldCheckbox}
                                                label={fastTrain.caption}
                                                name="train_names"
                                            />
                                        ))}
                                    </div>
                                    <Divider />
                                </>
                            )}

                            {hasTimeSpanFilters && (
                                <>
                                    <FilterGroup label={i18n('time')}>
                                        <Header slug="departure" />
                                        <FieldTime name="departure_there_timespan" time={filters.departure_there_timespan} />
                                        <Header slug="arrival" />
                                        <FieldTime name="arrival_there_timespan" time={filters.arrival_there_timespan} />
                                    </FilterGroup>
                                    <Divider />
                                </>
                            )}

                            {hasCarriesFilters && (
                                <>
                                    <FilterGroup collapsed={!isCollapsedCarriageOwnerFilters} label={i18n('carriage_owner')}>
                                        <FieldOptions name="carriage_owners" options={filters.carriers} />
                                    </FilterGroup>
                                    <Divider />
                                </>
                            )}

                            {hasRailStationsFilters && (
                                <>
                                    <FilterGroup collapsed={!isCollapsedTrainStationFilters} label={i18n('train_station')}>
                                        <Header slug="departure" />
                                        <FieldOptions name="departure_from_there" options={filters.departure_from_there} />
                                        <Header slug="arrival" />
                                        <FieldOptions name="arrival_to_there" options={filters.arrival_to_there} />
                                    </FilterGroup>
                                    <Divider />
                                </>
                            )}
                            {hasTrainCategories && (
                                <>
                                    <FilterGroup collapsed={!isCollapsedTrainCategoryFilters} label={i18n('train_category')}>
                                        <FieldOptions name="train_categories" options={filters.train_categories} />
                                    </FilterGroup>
                                    <Divider />
                                </>
                            )}
                            {hasTrainCarriageTypes && (
                                <FilterGroup label={i18n('carriage_type')}>
                                    <FieldOptions name="carriage_types" options={filters.carriage_types} />
                                </FilterGroup>
                            )}

                            {hasTrainNamesFilters && (
                                <>
                                    <Divider />
                                    <FilterGroup label={i18n('train_name')}>
                                        <FieldOptions name="train_names" options={filters.train_names} />
                                    </FilterGroup>
                                </>
                            )}

                            {!instant && (
                                <div className={cn('ActionButtons')}>
                                    {/* eslint-disable-next-line react/jsx-no-bind */}
                                    <Button
                                        className={cn('ActionButton')}
                                        size="m"
                                        view="outline"
                                        width="max"
                                        onClick={() => render.form.reset(defaultValues)}
                                    >
                                        {i18nAction('reset')}
                                    </Button>
                                    <Button
                                        className={cn('ActionButton', { type: 'submit' })}
                                        size="m"
                                        type="submit"
                                        view="brand"
                                        width="max"
                                    >
                                        {i18nAction('apply')}
                                    </Button>
                                </div>
                            )}
                        </form>
                    </div>
                );
            }}
        </Form>
    );
};

TrainFilters.displayName = cn();
