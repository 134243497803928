import { PurposeOption } from '../Purposes.types';

import { confKinds } from './confKinds';

export function isOptionDisabled(option: PurposeOption, selectedOptions: number[], confOptions: PurposeOption[]) {
    const isOptionOfKindConf = confKinds.includes(option.kind);
    const isConfOptionsSelected = selectedOptions.some(selectedOption => {
        return confOptions.some(({ value: confOption }) => confOption === selectedOption);
    });

    // НЕ дизейблим опцию, если выбрано менее двух опций ИЛИ среди выбранных есть текущая опция
    if (selectedOptions.length < 2 || selectedOptions.includes(option.value)) {
        return false;
    }

    // дизейблим опцию, если среди выбранных нет конференционных ИЛИ текущая опция любого типа, кроме конференционного
    return !isConfOptionsSelected || !isOptionOfKindConf;
}
