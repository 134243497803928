import { FC, ReactElement } from 'react';
import { withBemMod } from '@bem-react/core';

import { Destination, DestinationProps } from 'components/Destination';

import { cn } from '../FormField.cn';
import { FormFieldProps as BaseProps } from '../FormField.types';

export type WithTypeDestinationProps = {
    type?: 'destination';
    placeholder?: DestinationProps['placeholder'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange?: any; // DestinationProps['onChange'];
    onBlur?: DestinationProps['onBlur'];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value?: any; // DestinationProps['value'];
    pin?: DestinationProps['pin'];
    state?: 'error';
    name?: string;
    uniqPopupId?: string;
};

export const withTypeDestination = withBemMod<WithTypeDestinationProps, BaseProps>(
    cn(),
    { type: 'destination' },
    (Base): FC<BaseProps & WithTypeDestinationProps> => (props): ReactElement => {
        const {
            placeholder,
            onChange,
            onBlur,
            value,
            state,
            pin,
            name,
            uniqPopupId,
            disabled,
            hasClear,
        } = props;

        const appendAfter = (
            <Destination
                className={cn('Control', { name })}
                disabled={disabled}
                hasClear={hasClear}
                pin={pin}
                placeholder={placeholder}
                state={state}
                uniqPopupId={uniqPopupId}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
            />
        );

        return (
            <Base
                {...props}
                appendAfter={appendAfter}
            />
        );
    },
);
