import { PersonTrip } from 'services/SwaggerApi';

export const getServicesDocumentInfo = (personTrip: PersonTrip) => {
    const draftServices = personTrip.services.filter(service => service.status === 'draft');

    const servicesWithoutDocumentCount = draftServices
        .filter(service => service.document_id === null).length;

    const shouldAddDocument = servicesWithoutDocumentCount !== 0;

    return {
        shouldAddDocument,
        servicesWithoutDocumentCount,
    };
};
