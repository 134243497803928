export const ru = {
    add_passport: 'Добавить паспорт',
    couldnt_delete_service: 'Не удалось удалить услугу',
    couldnt_add_passport: 'Не удалось добавить паспорт к услуге',
    couldnt_change_passport: 'Не удалось изменить паспорт у услуги',
    executes_service_hint: 'После оформления услуги паспорт изменить нельзя',
    select_passport: 'Выбрать паспорт',
    remove_hint: 'Данное действие нельзя будет отменить. Вы сможете добавить новую услугу после удаления',
    choose_documents: 'Выбрать документы',
    change_documents: 'Поменять документы',
    can_not_change_documents: 'Поменять документы нельзя',
    view_documents: 'Посмотреть документы',
    documents_title: 'Документы',
    passengers_without_docs: {
        one: 'У {count}-го пассажира не выбран документ',
        some: 'У {count}-х пассажиров не выбраны документы',
        many: 'У {count} пассажиров не выбраны документы',
        none: 'Все документы добавлены',
    },
};
