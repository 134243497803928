import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { FieldSuggest, FieldTextinput, FormProvider } from 'components/FormField2';
import { useCreateBonusCard } from 'hooks/useCreateBonusCard';
import { i18nAction } from 'i18n/i18nAction';
import { Text } from 'shared/ui/Text';
// import { EmptySuggestState } from 'shared/ui/EmptySuggestState';
import { isLoading } from 'utils/Loadable';

import { BonusCardSchema, schema } from './BonusCard.schema';
import { cn } from './BonusCardForm.cn';
import { i18n } from './BonusCardForm.i18n';
import { BonusCardFormProps } from './BonusCardForm.types';

import './BonusCardForm.css';

export const BonusCardForm: FC<BonusCardFormProps> = props => {
    const {
        className,
        onSubmit,
        onCancel,
        personId,
    } = props;

    const [createBonusCard, state, { error }] = useCreateBonusCard({ onSuccess: onSubmit });

    const handleSubmit: SubmitHandler<BonusCardSchema> = values => {
        const { bonus_card: { service_type, code } } = values;

        createBonusCard(personId, {
            number: values.card_number,
            service_provider_type: service_type,
            service_provider_code: code,
        });
    };

    const methods = useForm<BonusCardSchema>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });

    return (
        <FormProvider
            error={error}
            methods={methods}
            schema={schema}
        >
            <form
                className={cn(null, [className])}
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <Text typography="title-m">
                    {i18n('add_bonus_card')}
                </Text>
                <FieldSuggest
                    className={cn('Search')}
                    label={i18n('card')}
                    // emptyNode={<EmptySuggestState />}
                    name="bonus_card"
                    type="bonus"
                />
                <FieldTextinput
                    label={i18n('number')}
                    name="card_number"
                    placeholder={i18n('number_placeholder')}
                />
                <div className={cn('Controls')}>
                    <ButtonPlatform
                        className={cn('Cancel')}
                        view="default"
                        onClick={onCancel}
                    >
                        {i18nAction('cancel')}
                    </ButtonPlatform>
                    <ButtonPlatform
                        className={cn('Submit')}
                        progress={isLoading(state)}
                        type="submit"
                        view="brand"
                    >
                        {i18nAction('save')}
                    </ButtonPlatform>
                </div>
            </form>
        </FormProvider>
    );
};

BonusCardForm.displayName = cn();
