import { FC } from 'react';

import { Button } from 'shared/ui/Button';
import { PopupPlatform } from 'shared/ui/PopupPlatform';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';

import { i18n } from '../TrainDetails.i18n';

import { cn } from './TrainSeatInfoPopup.cn';
import { TrainSeatInfoPopupProps } from './TrainSeatInfoPopup.types';

import './TrainSeatInfoPopup.css';

export const TrainSeatInfoPopup: FC<TrainSeatInfoPopupProps> = props => {
    const {
        anchorRef,
        onClose,
        selectedSeatDetails,
        visible,
        carriageClass,
        onChooseClick,
    } = props;

    const maleCompartment = selectedSeatDetails?.compartment_gender === 'male' ? i18n('male_compartment') : '';
    const femaleCompartment = selectedSeatDetails?.compartment_gender === 'female' ? i18n('female_compartment') : '';

    const secondaryTitle = [maleCompartment || femaleCompartment, carriageClass].filter(Boolean).join(' · ');

    return (
        <>
            <PopupPlatform
                hasTail
                motionless
                anchor={anchorRef}
                className={cn(null)}
                data-testid="train-seat-info-popup"
                mainOffset={12}
                scope={'inplace'}
                secondaryOffset={-32}
                tailOffset={-16}
                target="anchor"
                view="default"
                visible={visible}
                onClose={onClose}
            >
                <div className={cn('PopupContent')}>
                    <div className={cn('Title')}>
                        <Text weight="medium">{i18n('seat', { seatNumber: selectedSeatDetails?.place_number })}</Text>
                        <Text color="secondary" typography="caption-m">{secondaryTitle}</Text>
                    </div>
                    {
                        (selectedSeatDetails?.min_price || selectedSeatDetails?.place_type_description) && (
                            <div className={cn('Description')}>
                                {
                                    selectedSeatDetails?.min_price && (
                                        <div className={cn('Price')}>
                                            <Text color="secondary" typography="caption-m">{i18n('price')}</Text>
                                            <Text typography="title-m">{formatCurrency(selectedSeatDetails.min_price)}</Text>
                                        </div>
                                    )
                                }
                                {
                                    selectedSeatDetails?.place_type_description && (
                                        <div className={cn('Features')}>
                                            <Text color="secondary" typography="caption-m">{i18n('features')}</Text>
                                            <Text typography="body-s">{selectedSeatDetails?.place_type_description}</Text>
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                    <Button
                        size="m"
                        view="primary"
                        width="max"
                        onClick={onChooseClick}
                    >
                        {i18n('choose_this_seat')}
                    </Button>
                </div>
            </PopupPlatform>
        </>
    );
};

TrainSeatInfoPopup.displayName = cn();
