import * as z from 'zod';

import { ActivePage } from 'shared/constants/ActivePage';

import { route } from './route';

export const RouteHotelRoomsDecoder = z.object({
    params: z.object({
        searchId: z.string(),
        key: z.string(),
    }),
    search: z.object({
        trip_id: z.optional(z.string().transform(val => Number(val))),
        person_id: z.string(),
        adult: z.string().transform(val => Number(val)),
    }),
});

export type RouteHotelRooms = z.output<typeof RouteHotelRoomsDecoder>;

export const RouteHotelRooms = route(
    '/search/:searchId/results/:key/rooms/',
    RouteHotelRoomsDecoder,
    {
        activePage: ActivePage.ALL_TRIPS_LIST,
    },
);
