import { i18nAviaClass } from 'i18n/i18nAviaClass';
import { i18nCarriageType } from 'i18n/i18nCarriageType';
import { i18nPrepositions } from 'i18n/i18nPrepositions';
import { i18nStars } from 'i18n/i18nStars';
import { ServiceClassesRule, StarsAllowedRule, TrainCarCategoriesRule } from 'services/SwaggerApi';

const aviaClasses = {
    E: 'avia_class_econom',
    C: 'avia_class_comfort',
    F: 'avia_class_first',
    B: 'avia_class_business',
} as const;

const railCategories = {
    Shared: 'common',
    Soft: 'vip',
    Luxury: 'lux',
    Compartment: 'coupe',
    ReservedSeat: 'reserved',
    Sedentary: 'seat',
} as const;

const formatRestrictions = (restrictions: ReadonlyArray<string | number>): string => {
    if (restrictions.length === 0) return '';
    if (restrictions.length === 1) return String(restrictions[0]);

    const lastItem = restrictions[restrictions.length - 1];
    const formattedArray = restrictions.slice(0, -1).join(', ');

    return formattedArray + ` ${i18nPrepositions('and')} ` + lastItem;
};

export const getCabinClasses = (classes: ServiceClassesRule[]) => {
    if (!classes || !classes[0] || !classes[0].service_classes || classes[0].service_classes.length === 0) return;

    return formatRestrictions(classes[0].service_classes.map(value => i18nAviaClass(aviaClasses[value]).toLowerCase()));
};

export const getRailCategories = (categories: TrainCarCategoriesRule[]) => {
    if (
        !categories ||
        !categories[0] ||
        !categories[0].train_car_categories ||
        categories[0].train_car_categories.length === 0
    ) {
        return;
    }

    return formatRestrictions(
        categories[0].train_car_categories.map(value => i18nCarriageType(railCategories[value]).toLowerCase()),
    );
};

export const getHotelStars = (stars: StarsAllowedRule[]) => {
    if (!stars || !stars[0] || !stars[0].stars_allowed || stars[0].stars_allowed.length === 0) return;

    const sortedStars = stars[0].stars_allowed.sort();
    const formattedStars = formatRestrictions(sortedStars.map(value => value));

    if (sortedStars.includes('Unknown')) {
        return `${formattedStars.replace('Unknown', i18nStars('without_stars'))}`;
    }

    const starsCount = sortedStars.length;

    return `${formattedStars} ${i18nStars('stars', {
        count: starsCount,
    })}`;
};
