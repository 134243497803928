import { FC, useCallback } from 'react';

import { i18nErrors } from 'i18n/i18nErrors';
import { Checkbox } from 'shared/ui/Checkbox';

import { cn } from './FilterFieldOption.cn';
import { FilterFieldOptionProps } from './FilterFieldOption.types';

import './FilterFieldOption.css';

export const FilterFieldOption: FC<FilterFieldOptionProps> = props => {
    const { value: selected, onChange } = props.input;

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();

        const { code } = event.target.dataset;

        if (code === undefined) throw new Error(i18nErrors('option_code_undefined'));

        const codeIndex = selected.indexOf(code);

        if (codeIndex === -1) return onChange([...selected, code]);

        return onChange([
            ...selected.slice(0, codeIndex),
            ...selected.slice(codeIndex + 1),
        ]);
    }, [selected, onChange]);

    return (
        <div className={cn(null, [props.className])}>
            {props.options && props.options.map(option => (
                <Checkbox
                    key={option.target_id}
                    checked={selected.includes(option.target_id)}
                    className={cn('Checkbox')}
                    data-code={option.target_id}
                    label={option.element || option.caption}
                    lines="one"
                    size="m"
                    title={option.caption}
                    view="outline"
                    onChange={handleChange}
                />
            ))}
        </div>
    );
};

FilterFieldOption.displayName = cn();
