import { getPlatformHandler } from './utils';

export function sync(nativeMethodName: string): Function {
    const handler = getPlatformHandler(nativeMethodName);

    if (!handler) {
        throw new Error(`Method ${nativeMethodName} is not supported by the current platform`);
    }

    return handler;
}
