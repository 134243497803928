import {
    AviaDetailResponse,
    Flight,
    HotelDetailResponse,
    HotelDetailWithTariffsResponse,
} from 'services/SwaggerApi';

export const getAviaDetailToFlight = (aviaDetail: AviaDetailResponse): Flight => {
    const {
        is_changeable,
        is_refundable,
        price,
        id,
        is_travel_policy_compliant,
        travel_policy_violations,
    } = aviaDetail.flight;

    const flight = {
        id,
        is_changeable,
        is_refundable,
        is_travel_policy_compliant,
        legs: aviaDetail.data,
        baggage_summary: aviaDetail.data[0].segments[0].baggage,
        price,
        // функция getAviaDetailToFlight используется только для не-fare_family тарифов
        fare_family: false,
        travel_policy_violations,
    };

    return flight;
};

const isWithTariffsRespose =
(hotelDetail: HotelDetailWithTariffsResponse | HotelDetailResponse): hotelDetail is HotelDetailWithTariffsResponse => {
    return Array.isArray((hotelDetail as HotelDetailWithTariffsResponse).data[0].tariffs);
};

type GeneralInfoAboutRooms = {
    minPricePerNight: number;
    roomsCount: number;
};

export const getGeneralInfoAboutRooms =
    (hotelDetail: HotelDetailWithTariffsResponse | HotelDetailResponse): GeneralInfoAboutRooms => {
        let roomsCount = 0;

        const minPricePerNight =
            isWithTariffsRespose(hotelDetail) ?
                Math.min(...hotelDetail.data.map(room => room.tariffs.map(tariff => {
                    roomsCount++;

                    return tariff.price_per_night;
                })).flat()) :
                Math.min(...hotelDetail.data.map(room => {
                    roomsCount++;

                    return room.price_per_night;
                }));

        return {
            minPricePerNight,
            roomsCount,
        };
    };
