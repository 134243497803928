export const ru = {
    fill: 'Заполнить',
    filled: 'Заполнена',
    personal_data: 'Персональная информация',
    missing_conf_fields: 'Требуется заполнить данные для конференции',
    missing_date_of_birth: 'Требуется заполнить дату рождения',
    missing_first_name_en: 'Требуется заполнить имя на латинице',
    missing_last_name_en: 'Требуется заполнить фамилию на латинице',
    missing_phone_number: 'Требуется заполнить номер телефона',
    missing_gender: 'Требуется указать пол',
};
