export const en = {
    headerDisclaimer: ' The information is relevant for citizens of the Russian Federation',
    footerDisclaimer: 'Requirements change frequently, check the validity of the data on the official websites before the trip',
    findError: 'Found an error in the information?',
    inform: 'Inform',

    entranceForRussians: 'Entrance',
    visaRequired: 'Visa',
    quarantineRequired: 'Quarantine',
    pcrExpirationPeriodInHoursOrRequired: 'PCR test',
    vaccineRequired: 'Vaccination',
    flightsAvailability: 'Direct flight',
    tourismAvailability: 'Tourism',
};
