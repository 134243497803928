import { FC } from 'react';

import { Card } from 'components/Card';
import { Button } from 'shared/ui/Button';
import { ErrorState } from 'shared/ui/ErrorState';

import { i18n } from '../EmployeesGroupForm.i18n';

import { EmptyStateProps } from './EmptyState.types';

export const EmptyState: FC<EmptyStateProps> = props => {
    const { onAddClick } = props;

    return (
        <Card>
            <ErrorState
                actions={[
                    <Button
                        key="add-employees"
                        size="m"
                        view="primary"
                        onClick={onAddClick}
                    >
                        {i18n('add_users')}
                    </Button>,
                ]}
                description={i18n('error_description')}
                size="s"
                title={i18n('error_title')}
            />
        </Card>
    );
};
