import { FC, useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import dayjs from 'dayjs';

import { Confirm } from 'components/Confirm';
import { getIconProvider, IconPlus, IconSearch } from 'components/Icon';
import { ServiceOrder } from 'components/ServiceOrder';
import { withPropsV2 } from 'hocs/withPropsV2';
import { SearchDetails, useCreateSearch } from 'hooks/useCreateSearchId';
import { usePlatform } from 'hooks/usePlatform';
import { i18nFlightDirection } from 'i18n/i18nFlightDirection';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { getTranslatedRouteCities } from 'utils/aeroclub/getRouteCities';
import { compactDate } from 'utils/formatDate';

import { SegmentNoServicesTransport } from '../SegmentNoServices/SegmentNoServicesTransport';
import { SegmentPlaceholderTransport } from '../SegmentPlaceholder/SegmentPlaceholderTransport';
import { cn } from '../TripRouteSegment.cn';
import { SegmentTransportProps } from '../TripRouteSegment.types';
import { i18n } from '../TripRouteSegment.yt.i18n';

const Dates = withPropsV2(Text, {
    weight: 'regular',
});

const iconProviderPlus = getIconProvider(IconPlus);
const iconProviderSearch = getIconProvider(IconSearch);

export const SegmentTransport: FC<SegmentTransportProps> = props => {
    const {
        segment,
        trip_id,
        person_id,
        showSearchButtons,
        showCitiesAsTitle,
        canAddServices,
    } = props;
    const {
        type,
        date_from,
        city_from,
        city_to,
        need_transfer,
    } = segment;

    const { isSearchLoading, createSearch } = useCreateSearch();

    const [confirmationVisible, setConfirmationVisible] = useToggle(false);
    const [searchType, setSearchType] = useState<'avia' | 'aviaRound' | 'train' | null>(null);

    const searchDetailsSerialized: Omit<SearchDetails, 'type'> = useMemo(() => {
        const { date_from, date_to, city_from, city_to, from_id, to_id } = segment;
        const defaultClassAvia = 'Econom';

        return {
            from_geoid: from_id ?? undefined,
            to_geoid: to_id ?? undefined,
            departure_on: date_from,
            departure_back_on: date_to,
            fromCityName: city_from.name.ru || '',
            toCityName: city_to.name.ru || '',
            person_id,
            trip_id,
            class_avia: defaultClassAvia,
        };
    }, [person_id, segment, trip_id]);

    const isTransferDateInPast = dayjs().isAfter(date_from, 'day');

    const searchAvia = useCallback(() => {
        if (isTransferDateInPast) {
            return setConfirmationVisible(true);
        }

        setSearchType('avia');
        createSearch({ ...searchDetailsSerialized, departure_back_on: undefined, type: 'Avia' });
    }, [createSearch, isTransferDateInPast, searchDetailsSerialized, setConfirmationVisible]);

    const searchAviaRoundTrip = useCallback(() => {
        if (isTransferDateInPast) {
            return setConfirmationVisible(true);
        }

        setSearchType('aviaRound');
        createSearch({ ...searchDetailsSerialized, type: 'Avia' });
    }, [createSearch, isTransferDateInPast, searchDetailsSerialized, setConfirmationVisible]);

    const searchRail = useCallback(() => {
        if (isTransferDateInPast) {
            return setConfirmationVisible(true);
        }

        setSearchType('train');
        createSearch({ ...searchDetailsSerialized, type: 'Rail' });
    }, [createSearch, isTransferDateInPast, searchDetailsSerialized, setConfirmationVisible]);

    const { isMobile } = usePlatform();
    const size = isMobile ? 'm' : 's';

    const hasServices = Boolean(segment.services.length);
    const showPlaceholder = !hasServices && need_transfer;
    const showNoServices = !hasServices && !need_transfer;

    const headlineText = showCitiesAsTitle ?
        <>{getTranslatedRouteCities(city_from.name, city_to.name)}, <Dates>{compactDate(date_from)}</Dates></> :
        i18nFlightDirection('round_trip');

    const actionButtonsIconProvider = canAddServices ? iconProviderPlus : iconProviderSearch;

    const actionButtons = (
        <>
            {segment.round_trip_available && (
                <Button
                    className={cn('ActionButton', [cn('SearchAviaRoundTrip')])}
                    disabled={isSearchLoading}
                    iconLeft={actionButtonsIconProvider}
                    progress={isSearchLoading && searchType === 'aviaRound'}
                    size={size}
                    view="contrast"
                    onClick={searchAviaRoundTrip}
                >
                    {i18n('avia_round_trip')}
                </Button>
            )}
            <Button
                className={cn('ActionButton', [cn('SearchAvia')])}
                disabled={isSearchLoading}
                iconLeft={actionButtonsIconProvider}
                progress={isSearchLoading && searchType === 'avia'}
                size={size}
                view="contrast"
                onClick={searchAvia}
            >
                {i18n('avia')}
            </Button>
            <Button
                className={cn('ActionButton', [cn('SearchRail')])}
                disabled={isSearchLoading}
                iconLeft={actionButtonsIconProvider}
                progress={isSearchLoading && searchType === 'train'}
                size={size}
                view="contrast"
                onClick={searchRail}
            >
                {i18n('rail')}
            </Button>
        </>
    );

    return (
        <div className={cn({ type }, [props.className])}>
            <div className={cn('Headline')}>
                <Text typography="title-m">
                    {headlineText}
                </Text>
            </div>

            {showNoServices &&
                <SegmentNoServicesTransport {...props} />
            }
            {showPlaceholder &&
                <SegmentPlaceholderTransport {...props} actionButtons={actionButtons} />
            }
            {hasServices &&
                <>
                    {segment.services.map(service => <ServiceOrder key={service.service_id} service={service} />)}
                    {showSearchButtons && <div className={cn('BeneathActions')}>{actionButtons}</div>}
                </>
            }

            <Confirm
                className={cn('ConfirmAccept')}
                confirmText={i18n('good')}
                title={i18n('fix_application_date')}
                visible={confirmationVisible}
                onConfirm={setConfirmationVisible}
            />
        </div>
    );
};

SegmentTransport.displayName = cn('Transport');
