import { FC, useCallback } from 'react';
import { useToggle } from 'react-use';

import { ListItem } from 'shared/ui/ListItem';

import { Details } from '../Details';
import { cn } from '../DocumentsList.cn';
import { getDocumentItemTextParams } from '../DocumentsList.utils/getDocumentItemTextParams';
import { ItemMenu } from '../ItemMenu';

import { DocumentItemProps } from './DocumentItem.types';

export const DocumentItem: FC<DocumentItemProps> = props => {
    const { document, personId, type, testId } = props;
    const { document_id } = document;

    const [visibleDetailsModal, toggleVisibleDetailsModal] = useToggle(false);
    const onDetailsClose = useCallback(() => {
        toggleVisibleDetailsModal(false);
    }, [toggleVisibleDetailsModal]);

    const { caption, label } = getDocumentItemTextParams(document);

    return (
        <>
            <ListItem
                key={document_id}
                expanded
                className={cn('Item')}
                leadingTextParams={{
                    label,
                    caption,
                }}
                testId={testId}
                trailing={
                    <ItemMenu
                        document={document}
                        personId={personId}
                        type={type}
                        onOpenDetailsClick={toggleVisibleDetailsModal}
                    />
                }
            />
            <Details
                document={document}
                visible={visibleDetailsModal}
                onClose={onDetailsClose}
            />
        </>
    );
};
