import { FC } from 'react';

import { Card } from 'components/Card';
import { SupportChatButton } from 'components/SupportChatButton';
import { Text } from 'shared/ui/Text';

import { cn } from './SupportChat.cn';
import { i18n } from './SupportChat.i18n';
import { SupportChatProps } from './SupportChat.types';

import './SupportChat.css';

export const SupportChat: FC<SupportChatProps> = props => {
    const { className } = props;

    return (
        <Card className={cn(null, [className])}>
            <Text as="div" className={cn('Title')} typography="title-s">
                {i18n('need_help')}
            </Text>
            <Text
                as="div"
                className={cn('Description')}
                color="secondary"
                typography="body-s"
            >
                {i18n('contact_support_with_any_questions')}
            </Text>
            <SupportChatButton size="l">
                {i18n('ask_question')}
            </SupportChatButton>
        </Card>
    );
};

SupportChat.displayName = cn();
