import { FC, useMemo } from 'react';

import { Address } from 'components/Address';
import { HotelStars } from 'components/HotelStars';
import { Tag } from 'shared/ui/Tag';
import { Text, TextTitleSMedium } from 'shared/ui/Text';

import { cn } from './HotelInfo.cn';
import { i18n } from './HotelInfo.i18n';
import { HotelInfoProps } from './HotelInfo.types';
import { getFacilitiesText, getTags } from './HotelInfo.utils';

import './HotelInfo.css';

export const HotelInfo: FC<HotelInfoProps> = props => {
    const { hotel } = props;

    const {
        address,
        stars,
        hotel_name,
        short_facilities,
        ymap_info,
        location,
    } = hotel;

    const facilitiesText = getFacilitiesText(short_facilities);

    const hasStars = Boolean(stars);
    const hasRate = Boolean(ymap_info?.rate?.average_score);
    const drawDetails = hasStars || hasRate;
    const tags = useMemo(() => getTags(hotel), [hotel]);

    const orgId = ymap_info?.org_id ?? undefined;

    const drawRating = useMemo(() => {
        const rating = ymap_info?.rate?.average_score || 0;

        return (
            <>
                <Text className={cn('Rating')} typography="body-s" weight="medium">{rating.toFixed(1)}</Text>
                <Text color="secondary" typography="body-s">{i18n('rating')}</Text>
            </>
        );
    }, [ymap_info?.rate?.average_score]);

    const hotelCity = location.city?.name || '';

    return (
        <div className={cn(null, [props.className])}>
            <TextTitleSMedium className={cn('Title')}>
                {hotel_name}
            </TextTitleSMedium>
            <div>
                {drawDetails &&
                    <div className={cn('Details')}>
                        {hasStars && <Tag size="m" text={<HotelStars className={cn('Stars')} count={stars} />} />}
                        {hasRate && <Tag size="m" text={drawRating} />}
                    </div>
                }
                {address &&
                    (
                        <Address
                            address={address}
                            as="span"
                            city={hotelCity}
                            hotelName={hotel_name}
                            orgId={orgId}
                        >
                            <Text className={cn('Address')} typography="body-s">
                                {address}
                            </Text>
                        </Address>
                    )
                }
            </div>
            {facilitiesText && (
                <div className={cn('Facilities')} title={facilitiesText}>
                    <Text className={cn('FacilitiesText')} typography="body-s">
                        {facilitiesText}
                    </Text>
                </div>
            )}
            {tags.length > 0 && (
                <div className={cn('Tags')}>
                    {tags.map(tag => (
                        <Tag
                            key={tag}
                            rounded
                            maxWidth="full"
                            size="s"
                            text={tag}
                        />

                    ))}
                </div>
            )}
        </div>
    );
};

HotelInfo.displayName = cn();
