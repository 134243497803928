import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { useAvailableGroups } from 'hooks/useAvailableGroups';
import { useAvailableTravelPolicies } from 'hooks/useAvailableTravelPolicies';
import { usePersonTravelPolicyPut } from 'hooks/usePersonTravelPolicyPut';
import { getAvailableGroupOptions } from 'utils/getAvailableGroupOptions';
import { getAvailableTravelPolicyOptions } from 'utils/getAvailableTravelPolicyOptions';
import { foldSuccess } from 'utils/Loadable';

import { EditorTravelPolicyFields, UseTravelPolicyEditorData } from './useTravelPolicyEditor.types';

export const useTravelPolicyEditor = ({ personTravelPolicy, companyId }: UseTravelPolicyEditorData) => {
    const { group_id, travel_policy_id, person_id } = personTravelPolicy;

    const methods = useForm<EditorTravelPolicyFields>({
        defaultValues: {
            type: 'travel_policy',
            group: 0,
            travel_policy: 0,
            ...(group_id && { group: group_id }),
            ...(travel_policy_id && { travel_policy: travel_policy_id }),
        },
    });

    const travelPolicyList = useAvailableTravelPolicies();
    const travelPolicyOptions = foldSuccess(travelPolicyList, getAvailableTravelPolicyOptions, () => []);

    const groupsList = useAvailableGroups({ company_id: companyId });
    const groupsOptions = foldSuccess(groupsList, getAvailableGroupOptions, () => []);

    const [personTravelPolicyPut, state] = usePersonTravelPolicyPut();

    const type = methods.watch('type');

    useEffect(() => {
        const subscription = methods.watch((data, { name, type }) => {
            if (!type) {
                return;
            }

            if (name === 'group') {
                if (data.group === 0) {
                    personTravelPolicyPut(person_id, {});
                } else {
                    personTravelPolicyPut(person_id, { group_id: data.group });
                    methods.setValue('travel_policy', 0);
                }
            }

            if (name === 'travel_policy') {
                if (data.travel_policy === 0) {
                    personTravelPolicyPut(person_id, {});
                } else {
                    personTravelPolicyPut(person_id, { travel_policy_id: data.travel_policy });
                    methods.setValue('group', 0);
                }
            }
        });

        return () => subscription.unsubscribe();
    }, [methods, personTravelPolicyPut, person_id]);

    return {
        methods,
        type,
        state,
        travelPolicyOptions,
        groupsOptions,
    };
};
