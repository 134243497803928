export const notFoundErrorImage = (
    <svg fill="none" height="246" viewBox="0 0 200 246" width="200" xmlns="http://www.w3.org/2000/svg">
        <path clipRule="evenodd" d="M90.0825 225C90.1931 223.679 90.2883 222.344 90.37 221H85V177H90.4985C90.5004 175.629 90.5125 174.293 90.5384 173H85H83H81H75V169C75 166.791 73.2091 165 71 165H59C56.7909 165 55 166.791 55 169V173H49H47H45H37C32.5817 173 29 176.582 29 181V217C29 221.418 32.5817 225 37 225H45H47H49H65H81H83H85H90.0825ZM59 169H71V173H65H59V169ZM75 177H71H65H59H55H49V221H65H81V177H75ZM37 221H45V177H37C34.7909 177 33 178.791 33 181V217C33 219.209 34.7909 221 37 221Z" fill="black" fillRule="evenodd" />
        <mask fill="white" id="path-3-inside-1_1420_40059">
            <path clipRule="evenodd" d="M95.9289 84.3238C93.8582 84.2415 91.8894 83.3829 90.4184 81.9119C88.8699 80.3634 88 78.2633 88 76.0734C88 73.8835 88.8699 71.7834 90.4184 70.2349C91.9405 68.7128 93.9956 67.8463 96.1451 67.8173C97.6949 56.621 107.304 48 118.927 48C131.629 48 141.927 58.2975 141.927 71V84C141.927 96.7025 131.629 107 118.927 107C106.332 107 96.1021 96.8771 95.9289 84.3238Z" fillRule="evenodd" />
        </mask>
        <path d="M90.4184 81.9119L93.2468 79.0835H93.2468L90.4184 81.9119ZM95.9289 84.3238L99.9285 84.2686C99.8992 82.1427 98.212 80.4113 96.0876 80.3269L95.9289 84.3238ZM90.4184 70.2349L93.2468 73.0633L93.2468 73.0633L90.4184 70.2349ZM96.1451 67.8173L96.1992 71.8169C98.1757 71.7902 99.8362 70.3237 100.107 68.3657L96.1451 67.8173ZM87.59 84.7403C89.7736 86.9239 92.6962 88.1985 95.7701 88.3206L96.0876 80.3269C95.0202 80.2845 94.0052 79.8418 93.2468 79.0835L87.59 84.7403ZM84 76.0734C84 79.3241 85.2914 82.4417 87.59 84.7403L93.2468 79.0835C92.4485 78.2851 92 77.2024 92 76.0734H84ZM87.59 67.4065C85.2914 69.7051 84 72.8227 84 76.0734H92C92 74.9444 92.4485 73.8616 93.2468 73.0633L87.59 67.4065ZM96.091 63.8176C92.9002 63.8608 89.8494 65.147 87.59 67.4065L93.2468 73.0633C94.0315 72.2786 95.0911 71.8319 96.1992 71.8169L96.091 63.8176ZM100.107 68.3657C101.387 59.1205 109.329 52 118.927 52V44C105.279 44 94.0027 54.1215 92.1828 67.2688L100.107 68.3657ZM118.927 52C129.42 52 137.927 60.5066 137.927 71H145.927C145.927 56.0883 133.838 44 118.927 44V52ZM137.927 71V84H145.927V71H137.927ZM137.927 84C137.927 94.4934 129.42 103 118.927 103V111C133.838 111 145.927 98.9117 145.927 84H137.927ZM118.927 103C108.523 103 100.072 94.6375 99.9285 84.2686L91.9292 84.3789C92.1326 99.1166 104.142 111 118.927 111V103Z" fill="black" mask="url(#path-3-inside-1_1420_40059)" />
        <path d="M96.2569 82.6789C94.505 82.6789 92.8248 81.983 91.5861 80.7442C90.3473 79.5054 89.6514 77.8253 89.6514 76.0734C89.6514 74.3215 90.3473 72.6414 91.5861 71.4026C92.8248 70.1638 94.505 69.4679 96.2569 69.4679C100.385 69.4679 106.991 74.422 112.771 62.8624C121.408 70.2936 131.316 69.4679 139.193 69.4679" stroke="black" strokeLinejoin="round" strokeWidth="4" />
        <path d="M128.986 78.1346V82.0512M117.236 78.1346V82.0512" stroke="black" strokeLinecap="round" strokeWidth="4" />
        <path d="M136.615 228C142.881 201.367 141.223 173.695 139.917 161.945V161.945C139.917 159.214 137.704 157 134.973 157H101.453C98.9835 157 96.9817 159.002 96.9817 161.471V161.471C96.9817 161.786 96.9493 162.089 96.8894 162.398C94.5916 174.247 97.7661 203.459 95.3412 227.891C95.3353 227.949 95.3816 228 95.4408 228H110.193M141.569 228H126.806C126.751 228 126.706 227.954 126.706 227.899C126.694 198.671 121.239 179.018 118.579 172.181C118.542 172.086 118.408 172.114 118.392 172.214C115.855 188.328 116.902 198.843 105.239 228" stroke="black" strokeLinecap="round" strokeWidth="4" />
        <path d="M100.642 122.994C101.856 114.089 109.462 107.45 118.45 107.45C127.437 107.45 135.043 114.089 136.257 122.994L141.569 161.945C128.466 159.378 108.541 159.378 95.3303 161.945L100.642 122.994Z" stroke="black" strokeLinejoin="round" strokeWidth="4" />
        <path d="M138 123.858L148.791 123.858C153.97 123.858 158.564 120.536 160.187 115.618L166 98L166.898 96.1568C168.645 92.5714 173.339 91.6569 176.304 94.3241L176.5 94.5" stroke="black" strokeLinecap="round" strokeWidth="4" />
        <path d="M98.5 123.858L87.7089 123.858C82.5303 123.858 77.9359 120.536 76.3133 115.618L70.5 98L69.6019 96.1568C67.8549 92.5714 63.1609 91.6569 60.1956 94.3241L60 94.5" stroke="black" strokeLinecap="round" strokeWidth="4" />
    </svg>
);

export const accessDeniedImage = (
    <svg fill="none" height="232" viewBox="0 0 200 232" width="200" xmlns="http://www.w3.org/2000/svg">
        <path clipRule="evenodd" d="M90.1556 224.096C90.2852 222.437 90.3915 220.759 90.4779 219.068C84.7621 224.889 76.8027 228.5 68 228.5C50.603 228.5 36.5 214.397 36.5 197C36.5 179.603 50.603 165.5 68 165.5C76.819 165.5 84.7915 169.124 90.5095 174.964C90.5254 173.342 90.5589 171.778 90.6169 170.288C84.5169 165.118 76.6226 162 68 162C48.67 162 33 177.67 33 197C33 216.33 48.67 232 68 232C76.4068 232 84.1213 229.036 90.1556 224.096ZM58.9681 187.466C58.9681 182.583 62.9264 178.625 67.8092 178.625C72.6921 178.625 76.6505 182.583 76.6505 187.466V190.568H79.74C82.1562 190.568 84.115 192.527 84.115 194.943V209.101C84.115 211.517 82.1562 213.476 79.74 213.476H56.625C54.2088 213.476 52.25 211.517 52.25 209.101V194.943C52.25 192.527 54.2088 190.568 56.625 190.568H58.9681V187.466ZM73.1505 187.466V190.568H62.4681V187.466C62.4681 184.516 64.8594 182.125 67.8092 182.125C70.7591 182.125 73.1505 184.516 73.1505 187.466ZM74.9005 194.068H60.7181H56.625C56.1417 194.068 55.75 194.46 55.75 194.943V209.101C55.75 209.584 56.1417 209.976 56.625 209.976H79.74C80.2231 209.976 80.615 209.584 80.615 209.101V194.943C80.615 194.46 80.2231 194.068 79.74 194.068H74.9005Z" fill="black" fillRule="evenodd" />
        <mask fill="white" id="path-3-inside-1_4323_1066">
            <path clipRule="evenodd" d="M95.9289 84.3238C93.8582 84.2415 91.8894 83.3829 90.4184 81.9119C88.8699 80.3634 88 78.2633 88 76.0734C88 73.8835 88.8699 71.7834 90.4184 70.2349C91.9405 68.7128 93.9956 67.8463 96.1451 67.8173C97.6949 56.621 107.304 48 118.927 48C131.629 48 141.927 58.2975 141.927 71V84C141.927 96.7025 131.629 107 118.927 107C106.332 107 96.1021 96.8771 95.9289 84.3238Z" fillRule="evenodd" />
        </mask>
        <path d="M90.4184 81.9119L93.2468 79.0835H93.2468L90.4184 81.9119ZM95.9289 84.3238L99.9285 84.2686C99.8991 82.1427 98.212 80.4113 96.0876 80.3269L95.9289 84.3238ZM90.4184 70.2349L93.2468 73.0633L93.2468 73.0633L90.4184 70.2349ZM96.1451 67.8173L96.1992 71.8169C98.1756 71.7902 99.8362 70.3237 100.107 68.3657L96.1451 67.8173ZM87.59 84.7403C89.7736 86.9239 92.6962 88.1985 95.7701 88.3206L96.0876 80.3269C95.0202 80.2845 94.0051 79.8418 93.2468 79.0835L87.59 84.7403ZM84 76.0734C84 79.3241 85.2913 82.4417 87.59 84.7403L93.2468 79.0835C92.4485 78.2851 92 77.2024 92 76.0734H84ZM87.59 67.4065C85.2913 69.7051 84 72.8227 84 76.0734H92C92 74.9444 92.4485 73.8616 93.2468 73.0633L87.59 67.4065ZM96.0909 63.8176C92.9002 63.8608 89.8494 65.147 87.59 67.4065L93.2468 73.0633C94.0315 72.2786 95.0911 71.8319 96.1992 71.8169L96.0909 63.8176ZM100.107 68.3657C101.387 59.1205 109.329 52 118.927 52V44C105.279 44 94.0027 54.1215 92.1828 67.2688L100.107 68.3657ZM118.927 52C129.42 52 137.927 60.5066 137.927 71H145.927C145.927 56.0883 133.838 44 118.927 44V52ZM137.927 71V84H145.927V71H137.927ZM137.927 84C137.927 94.4934 129.42 103 118.927 103V111C133.838 111 145.927 98.9117 145.927 84H137.927ZM118.927 103C108.523 103 100.072 94.6375 99.9285 84.2686L91.9292 84.3789C92.1326 99.1166 104.142 111 118.927 111V103Z" fill="black" mask="url(#path-3-inside-1_4323_1066)" />
        <path d="M96.2569 82.6789C94.505 82.6789 92.8248 81.983 91.5861 80.7442C90.3473 79.5054 89.6514 77.8253 89.6514 76.0734C89.6514 74.3215 90.3473 72.6414 91.5861 71.4026C92.8248 70.1638 94.505 69.4679 96.2569 69.4679C100.385 69.4679 106.991 74.422 112.771 62.8624C121.408 70.2936 131.316 69.4679 139.193 69.4679" stroke="black" strokeLinejoin="round" strokeWidth="4" />
        <path d="M128.986 78.1346V82.0512M117.236 78.1346V82.0512" stroke="black" strokeLinecap="round" strokeWidth="4" />
        <path d="M136.615 228C142.881 201.367 141.223 173.695 139.917 161.945V161.945C139.917 159.214 137.704 157 134.973 157H101.453C98.9835 157 96.9817 159.002 96.9817 161.471V161.471C96.9817 161.786 96.9493 162.089 96.8894 162.398C94.5916 174.247 97.7661 203.459 95.3412 227.891C95.3353 227.949 95.3816 228 95.4408 228H110.193M141.569 228H126.806C126.751 228 126.706 227.954 126.706 227.899C126.694 198.671 121.239 179.018 118.579 172.181C118.542 172.086 118.408 172.114 118.392 172.214C115.855 188.328 116.902 198.843 105.239 228" stroke="black" strokeLinecap="round" strokeWidth="4" />
        <path d="M100.642 122.994C101.856 114.089 109.462 107.45 118.45 107.45C127.437 107.45 135.043 114.089 136.257 122.994L141.569 161.945C128.466 159.378 108.541 159.378 95.3303 161.945L100.642 122.994Z" stroke="black" strokeLinejoin="round" strokeWidth="4" />
        <path d="M134 115.858L144.791 115.858C149.97 115.858 154.564 112.536 156.187 107.618L162 90L162.898 88.1568C164.645 84.5714 169.339 83.6569 172.304 86.3241L172.5 86.5" stroke="black" strokeLinecap="round" strokeWidth="4" />
        <path d="M102.5 115.858L91.7089 115.858C86.5303 115.858 81.9359 112.536 80.3133 107.618L74.5 90L73.6019 88.1568C71.8549 84.5714 67.1609 83.6569 64.1956 86.3241L64 86.5" stroke="black" strokeLinecap="round" strokeWidth="4" />
    </svg>
);
