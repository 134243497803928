import { ComponentType, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from 'components/ErrorFallback';
import { Spinner } from 'components/Spinner';
import {
    hasData,
    isFailure,
    isPending,
    isRefetching,
    isRemote,
    loading,
    refetching,
    RemoteData,
    success,
} from 'utils/Loadable';
import { logError } from 'utils/logError';

import { cn } from './withRemote.cn';
import { RemoteProps } from './withRemote.types';

import './withRemote.css';

const noop = () => {};

export function withRemote<T extends object>(Component: ComponentType<T>): ComponentType<RemoteProps<T>> {
    return (propsRemote: RemoteProps<T>) => {
        const {
            spinner,
            spinnerMessage,
            spinnerDetails,
            showTips,
            fallbackElement,
            fallbackData,
            skeleton: Skeleton,
            errorFallbackClassName,
        } = propsRemote;

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const propsRD = useMemo<RemoteData<any>>(() => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const props: any = {};
            let hasPending = false;
            let hasRefetching = false;

            for (const [key, valueRemote] of Object.entries(propsRemote)) {
                if (isRemote(valueRemote)) {
                    if (isFailure(valueRemote)) return valueRemote;
                    if (isPending(valueRemote)) hasPending = true;

                    if (isRefetching(valueRemote, { withNewKeys: true })) {
                        hasRefetching = true;
                    }

                    if (hasData(valueRemote)) props[key] = valueRemote.result;
                } else {
                    props[key] = valueRemote;
                }
            }

            if (hasPending) {
                return loading();
            }

            if (hasRefetching) {
                return refetching(props);
            }

            return success(props);
        }, [propsRemote]);

        return useMemo(() => {
            if (isFailure(propsRD)) {
                if (fallbackData) {
                    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
                        <Component {...fallbackData} />
                    </ErrorBoundary>;
                }

                if (fallbackElement) {
                    return fallbackElement;
                }

                if (propsRD.error instanceof Error) {
                    return (
                        <ErrorFallback
                            className={errorFallbackClassName}
                            error={propsRD.error}
                                // требуется прокидывать resetErrorBoundary по типам
                            resetErrorBoundary={noop}
                        />
                    );
                }

                return <>{JSON.stringify(propsRD.error)}</>;
            }

            if (isPending(propsRD)) {
                if (Skeleton) {
                    return Skeleton;
                }

                if (!spinner) {
                    return <div />;
                }

                return <Spinner details={spinnerDetails} message={spinnerMessage} showTips={showTips} />;
            }

            if (isRefetching(propsRD)) {
                return (
                    <div className={cn('Wrapper')}>
                        <Spinner isOverComponent />
                        <Component {...propsRD.result} />
                    </div>
                );
            }

            return (
                <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
                    <Component {...propsRD.result} />
                </ErrorBoundary>
            );
        }, [
            propsRD,
            fallbackData,
            fallbackElement,
            errorFallbackClassName,
            Skeleton,
            spinner,
            spinnerDetails,
            spinnerMessage,
            showTips,
        ]);
    };
}
