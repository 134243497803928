import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { DateFormat, formatDate } from 'utils/formatDate';

const array13 = new Array(13).fill(null);

export const getMonths = () => array13.map((_, index) => {
    const todayDate = new Date();
    const month = dayjs(todayDate).add(index, 'month');

    const isSameYear = dayjs(todayDate).isSame(month, 'year');

    const isJanuary = month.month() === 0;

    const capitalizedMonth = capitalize(
        formatDate(month.toString(), DateFormat.MMMM),
    );

    const showMonthWithYear = isJanuary && !isSameYear;

    if (showMonthWithYear) {
        return `${capitalizedMonth} ${month.year()}`;
    }

    return capitalizedMonth;
});
