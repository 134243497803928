import { FC, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { AppLink } from 'components/AppLink';
import { useScrollObserver } from 'hooks/useScrollObserver';
import { TabsMenu } from 'shared/ui/TabsMenu';

import { cn } from './TabsPage.cn';
import { TabsPageProps } from './TabsPage.types';

import './TabsPage.css';

export const TabsPage: FC<TabsPageProps> = props => {
    const {
        tabs,
        activeTab,
        className,
    } = props;

    const tabsRef = useRef<HTMLUListElement>(null);

    useLayoutEffect(() => {
        tabsRef.current?.querySelector('.TabsMenu-Tab_active')?.scrollIntoView({ block: 'nearest', inline: 'center' });
    }, [activeTab]);

    const [visibleTab, setVisibleTab] = useState(tabs?.[0].id);

    useScrollObserver(tabs, setVisibleTab);

    const tabsOptions = useMemo(() => (tabs || []).map(
        ({ id, text, href, disabled }) => ({
            id,
            content: (
                <AppLink
                    className={cn('TabItem')}
                    disabled={disabled}
                    href={href}
                    theme="text"
                >
                    {text}
                </AppLink>
            ),
        }))
    , [tabs]);

    return (
        <div className={cn(null, [className])}>
            <TabsMenu
                marker
                activeTab={activeTab || visibleTab}
                className={cn('TabsMenu')}
                innerRef={tabsRef}
                layout="horiz"
                size="m"
                tabs={tabsOptions}
                view="default"
            />
        </div>
    );
};

TabsPage.displayName = cn();
