import { ErrorBoundary } from 'react-error-boundary';

import { ErrorFallback } from 'components/ErrorFallback';
import { Spinner } from 'components/Spinner';
import { i18nErrors } from 'i18n/i18nErrors';
import { isFailure, isPending } from 'utils/Loadable';
import { logError } from 'utils/logError';

import { RemoteProps, RenderFunction } from './Remote.types';

const noop = () => {};

/**@deprecated надо использовать HOC withRemote */
export function Remote<T>(props: RemoteProps<T>): JSX.Element | null {
    const {
        data,
        render,
        children,
        className,
        showTips,
        spinner,
        skeleton: Skeleton,
        fallbackData,
        fallbackElement,
        errorFallbackClassName,
        ...rest
    } = props;

    const renderFunction = render || children;

    if (!renderFunction) throw new Error(i18nErrors('no_render_function'));

    if (isFailure(data)) {
        if (fallbackData) {
            return (
                <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
                    {renderFunction({ data: fallbackData, className, ...rest })}
                </ErrorBoundary>
            );
        }

        if (fallbackElement) {
            return fallbackElement;
        }

        if (data.error instanceof Error) {
            return (
                <ErrorFallback
                    className={errorFallbackClassName}
                    error={data.error}
                        // требуется прокидывать resetErrorBoundary по типам
                    resetErrorBoundary={noop}
                />
            );
        }

        return <>{JSON.stringify(data.error)}</>;
    }

    if (isPending(data)) {
        if (Skeleton) return <>{Skeleton}</>;

        if (!spinner) return <div />;

        return <Spinner details={props.spinnerDetails} message={props.spinnerMessage} showTips={showTips} />;
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
            {renderFunction({ data: data.result, className, ...rest })}
        </ErrorBoundary>
    );
}

export type Remote<T> = RenderFunction<T>;
