import { FC } from 'react';
import { Divider } from '@yandex-lego/components/Divider';
import { pick } from 'lodash';

import { SupportChatLink } from 'components/SupportChatLink';
import { Text } from 'shared/ui/Text';
import { getTypedI18nLang } from 'utils/i18n';

import { EntryRestrictions } from '../RestrictionsDecoder';
import { getAdditionalText } from '../utils/getAdditionalText';
import { getRestrictionText } from '../utils/getRestrictionText';

import { cn } from './RestrictionsDetail.cn';
import { RESTRICTIONS_FOR_DISPLAY } from './RestrictionsDetail.constants';
import { i18n } from './RestrictionsDetail.i18n';
import { RestrictionsDetailProps } from './RestrictionsDetail.types';

import './RestrictionsDetail.css';

export const RestrictionsDetail: FC<RestrictionsDetailProps> = props => {
    const {
        className,
        restrictions,
        countryName,
    } = props;

    const availableRestrictions = Object.keys(
        pick(restrictions, RESTRICTIONS_FOR_DISPLAY),
    ) as [keyof EntryRestrictions];

    const showAdditionalInfo = getTypedI18nLang() === 'ru';

    const mainRestrictionsBlock = availableRestrictions.map((restriction, index) => {
        const additionalInformation = getAdditionalText(restriction, restrictions);
        const restrictionText = getRestrictionText(restriction, restrictions).toLowerCase();

        return (
            <div key={index} className={cn('Restriction')}>
                <Text as="div" typography="body-m" weight="medium">
                    {i18n(restriction)}: <Text weight="regular">{restrictionText}</Text>
                </Text>
                {showAdditionalInfo && (
                    <Text as="div" className={cn('RestrictionDescription')} color="secondary" typography="caption-m">
                        {additionalInformation}
                    </Text>
                )}
            </div>
        );
    });

    return (
        <div className={cn(null, [className])}>
            <div className={cn('Header')}>
                <Text as="div" typography="title-m" weight="medium">
                    {countryName}
                </Text>
                <Text as="div" color="secondary" typography="paragraph-s">
                    {i18n('headerDisclaimer')}
                </Text>
            </div>
            <div className={cn('MainInfo')}>
                {mainRestrictionsBlock}
            </div>
            <Divider />
            <div className={cn('Footer')}>
                <Text as="div" typography="body-s">
                    {i18n('findError')}
                    &nbsp;<SupportChatLink text={i18n('inform')} />
                </Text>
                <Text as="div" color="secondary" typography="caption-m">
                    {i18n('footerDisclaimer')}
                </Text>
            </div>
        </div>
    );
};

RestrictionsDetail.displayName = cn();
