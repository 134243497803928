/* eslint-disable @typescript-eslint/no-unsafe-return */
import { uuidv4 } from 'shared/lib/uuidv4';
import { getTypedI18nLang } from 'utils/i18n';

import { SupportedBiometryType } from './constants';
import type { DomainVerificationData, ReportError } from './types';

type AsyncOptions<Payload = unknown> = {
    payload: Payload;
    callbackId: string;
};

const MOCK_PIN_AUTH_ENABLED_KEY = 'MOCK_PIN_AUTH_ENABLED';
const MOCK_BIOMETRY_AUTH_ENABLED_KEY = 'MOCK_BIOMETRY_AUTH_ENABLED';

// eslint-disable-next-line @typescript-eslint/ban-types
export const BROWSER_MOCKED_BRIDGE: Record<string, Function> = {
    openURLInBrowser({ url }: { url: string; external: boolean }) {
        return window.open(url, '_blank');
    },
    shareFileByURL() {
        const id = Object.keys(window.BRIDGE)[0]!;
        const promiseMethods = window.BRIDGE[id]!;

        delete window.BRIDGE[id];

        setTimeout(() => promiseMethods.resolve(), 1000);
    },
    openFile({
        payload: { url, filename },
        callbackId,
    }: AsyncOptions<{ url: string; filename?: string }>) {
        const lastUrlSegment = url.split('/').pop();
        const name = filename ?? (lastUrlSegment || '').replace(new RegExp(/\?.*/, 'ig'), '');
        const a = document.createElement('a');

        a.style.display = 'none';
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();

        window.BRIDGE[callbackId]!.resolve();
    },
    showAlert({ payload: { title, message }, callbackId }:
        { payload: { title: string; message: string }; callbackId: string }) {
        const result = window.confirm(`${title}\n${message}`);
        const promiseMethods = window.BRIDGE[callbackId];

        delete window.BRIDGE[callbackId];
        setTimeout(() => (result ? promiseMethods.resolve() : promiseMethods.reject()), 1000);
    },
    reportError({ name, error }: { name: string; error: ReportError }) {
        console.error(name, error);
    },
    switchAccount() {
        window.location.href = `/auth/logout?redirectUrl=${encodeURIComponent(
            window.location.origin,
        )}`;
    },
    async shareText(text: { text: string }) {
        await navigator.clipboard.writeText(text.text);
    },
    setStartPage(text: string) {
        // eslint-disable-next-line no-console
        console.log('It is browser mock for setStartPage method.', text);
    },
    getPlatformConfig({ callbackId }: AsyncOptions) {
        const config: PlatformConfig = {
            id: 'browser',
            uuid: uuidv4(),
            lang: getTypedI18nLang(),
            darkTheme: Boolean(window.matchMedia?.('(prefers-color-scheme: dark)').matches),
            osVersion: '1.0.0',
            appVersion: '0.0.0',
            deviceName: 'browser',
            features: {
                basic: true,
                payment: false,
                security: false,
                notifications: false,
            },
        };
        const promiseMethods = window.BRIDGE[callbackId];

        if (!promiseMethods) {
            return;
        }

        promiseMethods.resolve(JSON.stringify(config));
        delete window.BRIDGE[callbackId];
    },
    getSupportedBiometryType({ callbackId }: AsyncOptions) {
        const promiseMethods = window.BRIDGE[callbackId]!;
        const type = SupportedBiometryType.Fingerprint;

        delete window.BRIDGE[callbackId];

        setTimeout(() => promiseMethods.resolve(String(type)), 100);
    },
    setupPINAuth({ callbackId }: AsyncOptions) {
        const promiseMethods = window.BRIDGE[callbackId]!;
        const value = 'true';

        localStorage.setItem(MOCK_PIN_AUTH_ENABLED_KEY, value);

        delete window.BRIDGE[callbackId];

        setTimeout(() => promiseMethods.resolve(value), 100);
    },
    removePINAuth({ callbackId }: AsyncOptions) {
        const promiseMethods = window.BRIDGE[callbackId]!;

        localStorage.setItem(MOCK_PIN_AUTH_ENABLED_KEY, 'false');
        localStorage.setItem(MOCK_BIOMETRY_AUTH_ENABLED_KEY, 'false');

        delete window.BRIDGE[callbackId];

        setTimeout(() => promiseMethods.resolve('true'), 100);
    },
    checkPINAuthEnabled({ callbackId }: AsyncOptions) {
        const promiseMethods = window.BRIDGE[callbackId]!;
        const isEnabled = localStorage.getItem(MOCK_PIN_AUTH_ENABLED_KEY) === 'true';

        delete window.BRIDGE[callbackId];

        setTimeout(() => promiseMethods.resolve(String(isEnabled)), 100);
    },
    setupBiometryAuth({ callbackId }: AsyncOptions) {
        const promiseMethods = window.BRIDGE[callbackId]!;
        const value = 'true';

        localStorage.setItem(MOCK_BIOMETRY_AUTH_ENABLED_KEY, value);
        delete window.BRIDGE[callbackId];

        setTimeout(() => promiseMethods.resolve(value), 100);
    },
    removeBiometryAuth({ callbackId }: AsyncOptions) {
        const promiseMethods = window.BRIDGE[callbackId]!;

        localStorage.setItem(MOCK_BIOMETRY_AUTH_ENABLED_KEY, 'false');
        delete window.BRIDGE[callbackId];

        setTimeout(() => promiseMethods.resolve('true'), 100);
    },
    checkBiometryAuthEnabled({ callbackId }: AsyncOptions) {
        const promiseMethods = window.BRIDGE[callbackId]!;
        const isEnabled = localStorage.getItem(MOCK_BIOMETRY_AUTH_ENABLED_KEY) === 'true';

        delete window.BRIDGE[callbackId];

        setTimeout(() => promiseMethods.resolve(String(isEnabled)), 100);
    },
    reportEvent() {
        // eslint-disable-next-line no-console
        console.log('It is browser mock for reportEvent method');
    },
    updateUserAttributes() {
        // eslint-disable-next-line no-console
        console.log('It is browser mock for updateUserAttributes method');
    },
    getDomainVerificationData({ callbackId }: AsyncOptions) {
        const promiseMethods = window.BRIDGE[callbackId];

        if (!promiseMethods) {
            return;
        }

        const data: DomainVerificationData = {
            verified: [],
            selected: [],
            unapproved: [],
        };

        promiseMethods.resolve(JSON.stringify(data));
        delete window.BRIDGE[callbackId];
    },
    showOpenByDefaultSettings() {
        // eslint-disable-next-line no-console
        console.log('It is browser mock for showOpenByDefaultSettings method');
    },
};
