import { PageError } from 'components/PageError';
import { useMeta } from 'hooks/useMeta';
import { Page as TPage } from 'types/Page';
import { CheckAccess, RouteParams } from 'types/Route';
import { RoutePathParams } from 'types/RoutePathParams';

export function withAccessControl<P extends RoutePathParams, S extends {}>(
    Page: TPage<RouteParams<P, S>>,
    checkAccess: CheckAccess,
): TPage<RouteParams<P, S>> {
    const WithAccessControlPage = (props: RouteParams<P, S>) => {
        const meta = useMeta();

        const access = checkAccess(meta?.user);

        if (!access.hasAccess) {
            return <PageError {...props} type={access.errorType} />;
        }

        return <Page {...props} />;
    };

    WithAccessControlPage.displayName = Page.displayName;

    return WithAccessControlPage;
}
