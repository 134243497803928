export const ru = {
    profile: 'Профиль',
    documents: 'Документы',
    access: 'Доступы',
    travel_policy: 'Тревел-политика',
    group: 'Группа',
    settings: 'Настройки',
    security: 'Безопасность',
    security_confirmation_subtitle: 'Вы точно хотите отозвать разрешение на обработку персональных данных? Без данного разрешения пользоваться сервисом будет нельзя',
    permision_to_process_pd: 'Разрешение на обработку персональных данных',
    permision_to_process_pd_description: 'Без данного разрешения пользоваться сервисом нельзя',
    documents_description: 'Для оформления билетов и отелей',
    travel_policy_description: 'Правила поиска и добавления услуг для пользователей сервиса',
    loyalty_cards: 'Карты лояльности',
    loyalty_cards_description: 'Получать бонусы можно даже в командировках',
    add_loyalty_card: 'Добавить карту лояльности',
    companions: 'Попутчики',
    hide_trip_on_staff: 'Скрыть командировки на Стафф',
    hide_trip_on_staff_description: 'Не показывать в гепах и на странице Стафф командировки',
    security_description: 'Приватность ваших данных в сервисе и за его пределами ',
    not_found_error_title: 'Профиль не существует или у вас нет доступа',
    not_found_error_description: 'Для получения доступа обратитесь к координатору',
    to_the_main_action: 'На главную',
    banner_text: 'Пожалуйста, проверьте данные профиля и подтвердите их правильность. Если в данных есть ошибка, их можно отредактировать.',
    banner_accept_text: 'Подтверждаю, что данные верны',
    assistant_title: 'Ассистент для',
    assistant_description: 'У ассистента есть доступ к данным данных профилей для организации командировок',
    director_title: 'Ваш ассистент',
    analytics_data_title: 'Данные для аналитики поездок',
    analytics_data_description: 'Для управления транзакциями сотрудника',
    director_description: 'У ассистента есть доступ к данным данного профиля для организации командировок',
    personnel_number_label: 'Табельный номер сотрудника',
    personnel_number_placeholder: 'Укажите номер',
    default_cost_center_label: 'Центр затрат по умолчанию',
    default_cost_center_addon_text: 'Добавить центр затрат',
    submit_button_hint: 'Сохранить данные для сотрудника',
    submit_data_success_notifier: 'Данные успешно обновлены',
    type: 'Тип',
    create_group: 'Создать группу',
    create_travel_policy: 'Создать тревел-политику',
    personal_tp: 'Персональные',
    group_tp: 'Групповые',
    not_selected: 'Не выбрано',
};
