import { FC } from 'react';

import { Cell } from 'components/Cell';
import { PersonTag } from 'components/PersonTag';
import { withTdAttrs } from 'hocs/withTdAttrs';

import { cn } from './CellPerson.cn';
import { CellPersonProps } from './CellPerson.types';

const CellPersonBase: FC<CellPersonProps> = props => {
    const {
        className,
        value,
        getAttrs,
    } = props;

    return (
        <Cell
            className={cn(null, [className])}
            getAttrs={getAttrs}
            size="m"
        >
            {
                value && (
                    <PersonTag
                        className={cn('User')}
                        login={value.login}
                        name={`${value.firstName} ${value.lastName}`}
                        personId={String(value.personId)}
                        size="s"
                    />
                )
            }
        </Cell>
    );
};

export const CellPerson = withTdAttrs(CellPersonBase);
CellPerson.displayName = cn();
