export const en = {
    are_you_ready_to_pay: 'In this case, you will have to pay the extra cost, the agency\'s service fee will be charged for the operation. Write to your travel chat to learn more about the extra payment.',
    b2b_not_complaint_warning: "This service exceeds your company's travel policy",
    b2b_not_complaint_tooltip: "This service exceeds your company's travel policy (service booking rules, cost limits), you can add it to your cart and send it for processing. However, this service will be issued only after the coordinator's approval",
    compliant: 'Travel policy compliant',
    no_info: 'No information about travel policy compliance',
    not_compliant: 'Not travel policy compliant',
    not_compliant_short: 'Not travel policy compliant',
    add_service: 'Add',
    add_not_compliant_service: 'Add the service that does not comply with the travel policy?',
    additional_confirm_info: 'For registration of services in violation of the travel policy, additional approval will be required',
};
