import { FC } from 'react';

import { AppLink } from 'components/AppLink';
import { Bubble } from 'components/Bubble';
import { Dropdown } from 'components/Dropdown';
import { IconArrowShortRight } from 'components/Icon';
import { ItemsGroup } from 'components/ItemsGroup';
import { PersonListItem } from 'components/PersonListItem';
import { RoutePersonTrip } from 'routes/RoutePersonTrip';
import { getFullName } from 'shared/lib/person/getFullName';
import { Text } from 'shared/ui/Text';

import { cn } from './UsersGroupTrip.cn';
import { i18n } from './UsersGroupTrip.i18n';
import { UsersGroupTripProps } from './UsersGroupTrip.types';

import './UsersGroupTrip.css';

/**
 * Участники групповой командировки с попапом перехода на перс заявку
 */
export const UsersGroupTrip: FC<UsersGroupTripProps> = props => {
    const {
        trip,
        size = 'm',
        maxItems = 5,
        className,
    } = props;

    const { trip_id, person_trips } = trip;

    if (!person_trips) {
        return null;
    }

    const users = person_trips.map(personTrip => (
        <Bubble
            key={personTrip.person?.person_id}
            bubble={{
                login: personTrip.person?.login || '',
                personId: String(personTrip.person?.person_id),
                name: `${personTrip.person?.first_name} ${personTrip.person?.last_name}`,
                type: 'person',
            }}
            size={size}
            type="person"
        />
    ));

    return (
        <Dropdown
            stopPropagation
            drawerProps={{
                scope: 'inplace',
            }}
            popupProps={{
                direction: ['bottom-start', 'top-start'],
                scope: 'inplace',
            }}
            triggerElement={
                <ItemsGroup
                    className={cn(null, [className])}
                    items={users}
                    maxItems={maxItems}
                    size={size}
                />
            }
        >
            <div className={cn('Popup')}>
                <Text
                    as="div"
                    className={cn('PopupHint')}
                    color="secondary"
                    typography="caption-m"
                >
                    {i18n('popup_hint')}
                </Text>

                {person_trips.map(personTrip => {
                    const { person } = personTrip;

                    if (!person) {
                        return null;
                    }

                    const { person_id, login } = person;

                    const url = RoutePersonTrip.getPath({ tripId: trip_id, personId: person_id }, {});

                    return (
                        <AppLink
                            key={login}
                            href={url}
                        >
                            <PersonListItem
                                clickable
                                transparent
                                login={person.login}
                                name={getFullName(person)}
                                personId={person.person_id}
                                showMark={personTrip.status === 'cancelled'}
                                size="s"
                                trailing={<IconArrowShortRight size="m" />}
                            />
                        </AppLink>
                    );
                },
                )}
            </div>
        </Dropdown>
    );
};

UsersGroupTrip.displayName = cn();
