import { useCallback } from 'react';

import { FormFieldProps } from '../FormField2.types';

import { validateDependentFields } from '.';

export type UseHandleChangeProps = {
    controller: FormFieldProps['controller'];
    deps: FormFieldProps['deps'];
    context: FormFieldProps['context'];
};

export const useHandleChange = (props: UseHandleChangeProps) => {
    const { controller, deps, context } = props;

    const { trigger } = context;

    const {
        field: { onChange },
        formState: { touchedFields, isSubmitted },
    } = controller;

    return useCallback(value => {
        onChange(value);

        if (deps) {
            validateDependentFields(deps, touchedFields, trigger, isSubmitted);
        }
    }, [onChange, trigger, deps, touchedFields, isSubmitted]);
};
