import { FC } from 'react';
import Animals from '@yandex-travel/icons/components/16/Animals';
import BioToilet from '@yandex-travel/icons/components/16/BioToilet';
import Child from '@yandex-travel/icons/components/16/Child';
import HandLuggage from '@yandex-travel/icons/components/16/HandLuggage';
import HygieneKit from '@yandex-travel/icons/components/16/HygieneKit';
import Include from '@yandex-travel/icons/components/16/Include';
import Multimedia from '@yandex-travel/icons/components/16/Multimedia';
import Newspaper from '@yandex-travel/icons/components/16/Newspaper';
import Panorama from '@yandex-travel/icons/components/16/Panorama';
import Plaid from '@yandex-travel/icons/components/16/Plaid';
import Restaurant from '@yandex-travel/icons/components/16/Restaurant';
import Shower from '@yandex-travel/icons/components/16/Shower';
import Socket from '@yandex-travel/icons/components/16/Socket';
import Tv from '@yandex-travel/icons/components/16/Tv';
import Wash from '@yandex-travel/icons/components/16/Wash';

import {
    IconProps,
    IconTravelAirConditioner,
    IconTravelCar,
    IconTravelHotel,
    IconTravelRestaurant,
    IconTravelWiFi,
} from 'components/Icon';
import { i18nCarriageServiceType } from 'i18n/i18nCarriageServiceType';
import { TrainCarServiceType } from 'services/SwaggerApi';

import { cn } from './CarriageServiceIcon.cn';
import { CarriageServiceIconProps } from './CarriageServiceIcon.types';

const icons: Record<TrainCarServiceType, FC<IconProps>> = {
    transfer: IconTravelCar,
    food: IconTravelRestaurant,
    air_conditioner: IconTravelAirConditioner,
    wifi: IconTravelWiFi,
    tv: Tv,
    newspaper: Newspaper,
    bed: IconTravelHotel,
    bio_toilet: BioToilet,
    baby: Child,
    blanket: Plaid,
    hygienic_shower: Shower,
    socket_usb: Socket,
    socket_220: Socket,
    multimedia_portal: Multimedia,
    pets: Animals,
    restaurant_car: Restaurant,
    panoramic_view: Panorama,
    luggage_compartment: HandLuggage,
    safe: Include,
    comfortable_waiting_room: Include,
    hot_drinks: Include,
    improved_service: Include,
    sanitary_set: HygieneKit,
    shower_in_compartment: Shower,
    shower_in_train: Shower,
    slippers: Include,
    sink_in_compartment: Wash,
    sightseeing_service: Include,
};

export const CarriageServiceIcon: FC<CarriageServiceIconProps> = props => {
    const { service } = props;

    const Icon = icons[service];
    const title = i18nCarriageServiceType(service);

    return (
        <Icon className={cn(null, [props.className])} size={props.size} title={title} />
    );
};

CarriageServiceIcon.displayName = cn();
