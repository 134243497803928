import { FC, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { DatesDropdown } from 'components/DatesDropdown';
import { FieldSuggest, FormProvider } from 'components/FormField2';
import { EmployeeField, useWatchEmployeeSuggest } from 'hooks/useWatchEmployeeSuggest';

import { cn } from './TableTransactionsHeaderLite.cn';
import { i18n } from './TableTransactionsHeaderLite.i18n';
import { HandleDatesSubmit, TableTransactionsHeaderLiteProps } from './TableTransactionsHeaderLite.types';

import './TableTransactionsHeaderLite.css';

export const TableTransactionsHeaderLite: FC<TableTransactionsHeaderLiteProps> = props => {
    const {
        companyId,
        defaultFilters,
        disabled,
        onFiltersChange,
        className,
    } = props;

    const filters = useRef(defaultFilters);

    const defaultExecutionValues = {
        dateFrom: defaultFilters?.execution_date__gte,
        dateTo: defaultFilters?.execution_date__lte,
    };

    const handleDatesSubmit = useCallback<HandleDatesSubmit>(dateFieldsValues => {
        const newValues = {
            ...filters.current,
            execution_date__gte: dateFieldsValues.dateFrom,
            execution_date__lte: dateFieldsValues.dateTo,
        };

        onFiltersChange(newValues);
        filters.current = newValues;
    }, [filters, onFiltersChange]);

    const methods = useForm<EmployeeField>();

    useWatchEmployeeSuggest({ methods, onFiltersChange, filters });

    return (

        <div className={cn(null, [className])}>
            <FormProvider methods={methods}>
                <FieldSuggest
                    className={cn('Search')}
                    companyId={companyId}
                    disabled={disabled}
                    name="employeeSuggest"
                    type="persons"
                />
                <DatesDropdown
                    buttonText={i18n('execution_date')}
                    defaultValues={defaultExecutionValues}
                    disabled={disabled}
                    onSubmit={handleDatesSubmit}
                />
            </FormProvider>
        </div>

    );
};

TableTransactionsHeaderLite.displayName = cn();
