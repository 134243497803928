import { FC, useCallback } from 'react';
import { IClassNameProps } from '@bem-react/core';

import { IconCalendar, IconClose } from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { Text } from 'shared/ui/Text';
import { DateFormat, formatDate } from 'utils/formatDate';

import { cn } from '../DatePicker.cn';
import { i18n } from '../DatePicker.i18n';

type DatePresenterProps = IClassNameProps & {
   placeholder: string;
   onClear: () => void;
   onClick: () => void;
   value: Date | undefined;
   active: boolean;
   state: 'error' | undefined;
   testId: string;
   showSubText?: boolean;
}

export const DatePresenter: FC<DatePresenterProps> = props => {
    const {
        active,
        placeholder,
        className,
        value,
        state,
        testId,
        onClear,
        onClick,
        showSubText,
    } = props;
    const { isDesktop, isMobile } = usePlatform();

    const fieldContent = useCallback((value?: Date) => {
        if (isMobile || !showSubText) {
            return (value ?
                <Text color="brand" typography="body-s">{formatDate(value, DateFormat.D_MMMM_dd)}</Text> :
                placeholder
            );
        }

        const displaySubText = isDesktop && showSubText;

        return (
            <div className={cn('EmptyDateField')}>
                <Text color="secondary" typography={displaySubText ? 'caption-m' : 'body-s'}>
                    {placeholder}
                </Text>
                {displaySubText && (
                    <Text typography="body-s">
                        {value ? formatDate(value, DateFormat.D_MMMM_dd) : i18n('select_date')}
                    </Text>
                )}
            </div>
        );
    }, [isDesktop, isMobile, showSubText, placeholder]);

    return (
        <div
            className={cn('DatePresenter', { active, state }, [className])}
            data-testid={testId}
            onClick={onClick}
        >
            <Text color="secondary" typography="body-s">
                {fieldContent(value)}
            </Text>
            {onClear && (
                value ?
                    <IconClose
                        className={cn('PresenterIcon')}
                        size="m"
                        sizeManagement="parent"
                        onClick={onClear}
                    /> :
                    <IconCalendar
                        className={cn('PresenterIcon')}
                        size="m"
                        sizeManagement="parent"
                    />
            )}
        </div>
    );
};
