import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { schema } from '../FormFindCompany.schema';
import { FormFindCompanyFields, FormFindCompanyProps } from '../FormFindCompany.types';

export const useFormFindCompany = (submitHandler: FormFindCompanyProps['onSubmit']) => {
    const onSubmit: SubmitHandler<FormFindCompanyFields> = values => {
        submitHandler({ ...values });
    };

    const methods = useForm<FormFindCompanyFields>({
        defaultValues: { company: null },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });

    return { methods, schema, onSubmit };
};
