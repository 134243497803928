import { FC } from 'react';

import { TableCompanies } from 'components/TableCompanies';

import { cn } from '../PageCompanies.cn';

import { TabCompaniesProps } from './TabCompanies.types';

export const TabCompanies: FC<TabCompaniesProps> = props => {
    const {
        className,
    } = props;

    return (
        <div className={cn('TabCompanies', [className])}>
            <TableCompanies />
        </div>
    );
};
