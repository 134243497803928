import { TTransaction_list_for_clientQuery } from 'services/SwaggerApi';
import { serializeDate } from 'utils/serializeDate';
import { serializePerson } from 'utils/serializePerson';

import { TableTransactionsLiteFilters } from '../TableTransactionsLite.types';

type SerializeFilters = (filters: TableTransactionsLiteFilters) => TTransaction_list_for_clientQuery;

export const serializeFilters: SerializeFilters = filters => ({
    execution_date__gte: serializeDate(filters.execution_date__gte),
    execution_date__lte: serializeDate(filters.execution_date__lte),
    person_id: serializePerson(filters.employeeSuggest),
});
