import {
    FC,
    useCallback,
    useRef,
    useState,
} from 'react';
import { FreeMode, Mousewheel, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

import { FlightInfoFareFamilyOption } from 'components/FlightInfoFareFamilyOption';
import { usePlatform } from 'hooks/usePlatform';

import 'swiper/css/pagination';

import { ButtonSlide } from './ButtonSlide/ButtonSlide';
import { cn } from './FlightInfoFareFamily.cn';
import { FlightInfoFareFamilyProps, HandleFromEdge } from './FlightInfoFareFamily.types';

import './FlightInfoFareFamily.css';

export const FlightInfoFareFamily: FC<FlightInfoFareFamilyProps> = props => {
    const {
        className,
        activeTariff,
        onTariffChange,
        flightOptions,
    } = props;

    const { isDesktop } = usePlatform();
    const swiperRef = useRef<SwiperType>();
    const [isLeftButtonActive, setIsLeftButtonActive] = useState(false);
    const [isRightButtonActive, setIsRightButtonActive] = useState(true);

    const handleBeforeInit = useCallback((swiper: SwiperType) => {
        swiperRef.current = swiper;
    }, [swiperRef]);

    const handleFromEdge = useCallback<HandleFromEdge>(swiper => {
        setIsLeftButtonActive(!swiper.isBeginning);
        setIsRightButtonActive(!swiper.isEnd);
    }, []);

    if (flightOptions.length === 0) {
        return null;
    }

    return (
        <div className={cn({ isRightButtonActive, isLeftButtonActive }, [className])}>
            <Swiper
                mousewheel
                className={cn('Swiper')}
                freeMode={isDesktop}
                modules={[FreeMode, Mousewheel, Pagination]}
                pagination={!isDesktop}
                slidesPerView="auto"
                spaceBetween={16}
                threshold={3}
                onBeforeInit={handleBeforeInit}
                onFromEdge={handleFromEdge}
                onToEdge={handleFromEdge}
            >
                {isDesktop && (
                    <>
                        <ButtonSlide
                            className={cn('ButtonLeft', ['SwiperButton'])}
                            direction="left"
                            isActive={isLeftButtonActive}
                            swiperRef={swiperRef}
                        />
                        <ButtonSlide
                            className={cn('ButtonRight', ['SwiperButton'])}
                            direction="right"
                            isActive={isRightButtonActive}
                            swiperRef={swiperRef}
                        />
                    </>
                )}

                {flightOptions.map((flight, index) => (
                    <SwiperSlide key={flight.id}>

                        <FlightInfoFareFamilyOption
                            checked={flight.id === activeTariff.id}
                            flight={flight}
                            optionNumber={index}
                            swiperRef={swiperRef}
                            onTariffChange={onTariffChange}
                        />

                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};

FlightInfoFareFamily.displayName = cn();
