import { Skeleton } from '@yandex-int/hr-components/Skeleton';

import { cn } from '../FlightFareFamily.cn';

import './Summary.css';

export const SummarySkeleton = () => {
    return (
        <div className={cn('SummarySkeleton')}>
            <div>
                <Skeleton className={cn('SummarySkeletonTitle')} size="m" />
                <Skeleton className={cn('SummarySkeletonSubtitle')} size="s" />
            </div>
            <Skeleton className={cn('SummarySkeletonControl')} size="l" type="control" />
        </div>
    );
};
