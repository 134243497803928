import { HotelMark } from '../GeoMap.types';

export function getAveragePoint(marks: HotelMark[]) {
    if (marks.length === 0) return undefined;

    return [
        marks.reduce((acc, mark) => acc + mark.latitude, 0) / marks.length,
        marks.reduce((acc, mark) => acc + mark.longitude, 0) / marks.length,
    ];
}
