import { FC, useCallback } from 'react';

import { Card } from 'components/Card';
import { usePersonTripPatch } from 'hooks/usePersonTripPatch';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { cn } from '../TripRouteSegment.cn';
import { EmptyHotelSegmentProps } from '../TripRouteSegment.types';
import { i18n } from '../TripRouteSegment.yt.i18n';

export const SegmentPlaceholderHotel: FC<EmptyHotelSegmentProps> = props => {
    const {
        trip_id,
        person_id,
        segment,
        actionButtons,
        showSearchButtons,
        canAddServices,
    } = props;
    const [patch, patchState] = usePersonTripPatch();

    const onClickHandle = useCallback(() => {
        patch(trip_id, person_id, { route: segment.getRouteWithToggledHotel() });
    }, [patch, person_id, segment, trip_id]);

    const { isMobile } = usePlatform();
    const size = isMobile ? 'm' : 's';

    return (
        <Card className={cn('Placeholder')}>
            <div className={cn('EmptySegmentHeader')}>
                <Text typography="title-s">
                    {!canAddServices ? i18n('not_approved') : i18n('hotel_not_added')}
                </Text>
                <Text color="secondary" typography="body-s">
                    {!canAddServices &&
                        <>
                            {i18n('wait_for_approval')}
                            <br />
                        </>
                    } {i18n('placeholder_hotel_tip')}
                </Text>
            </div>
            {showSearchButtons &&
                <div className={cn('EmptySegmentActions')}>
                    {actionButtons}
                    <Button
                        className={cn('ActionButton')}
                        progress={isLoading(patchState)}
                        size={size}
                        view="contrast"
                        onClick={onClickHandle}
                    >
                        {i18n('no_hotel_needed')}
                    </Button>
                </div>
            }
        </Card>);
};
