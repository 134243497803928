import { compose, composeU } from '@bem-react/core';

import { withMarkCheck } from './_mark/Bubble_mark_check';
import { withMarkDash } from './_mark/Bubble_mark_dash';
import { withMarkQuestion } from './_mark/Bubble_mark_question';
import { withTypeExternal } from './_type/Bubble_type_external';
import { withTypePerson } from './_type/Bubble_type_person.yt';
import { Bubble as BubbleBase } from './Bubble';

export const Bubble = compose(
    composeU(
        withTypePerson,
        withTypeExternal,
    ),
    composeU(
        withMarkCheck,
        withMarkDash,
        withMarkQuestion,
    ),
)(BubbleBase);
