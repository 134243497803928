import { FC } from 'react';

import { Table } from 'components/Table';

import { useColumns } from './TableTransactionsLite.hooks/useColumns';
import { cn } from './TableTransactionsLite.cn';
import { TableTransactionsLiteProps } from './TableTransactionsLite.types';

import './TableTransactionsLite.css';

const defaultColumn = {
    width: 160,
};

export const TableTransactionsLite: FC<TableTransactionsLiteProps> = props => {
    const {
        data: { data: transactions },
        pageSize,
        pageIndex,
        pageCount,
        className,
    } = props;

    const columns = useColumns();

    return (
        <Table
            className={cn('Table', [className])}
            columns={columns}
            data={transactions}
            defaultColumn={defaultColumn}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
        />
    );
};
