import { FC } from 'react';
import { useToggle } from 'react-use';

import { Card } from 'components/Card';
import { HotelStars } from 'components/HotelStars';
import { usePlatform } from 'hooks/usePlatform';
import { UGCReviewContent } from 'services/SwaggerApi';
import { Button } from 'shared/ui/Button';
import { ModalPlatform } from 'shared/ui/ModalPlatform';
import { Text } from 'shared/ui/Text';
import { compactDate } from 'utils/formatDate';

import { cn } from '../HotelDetails.cn';
import { i18n } from '../HotelDetails.i18n';

import { EmptyState } from './EmptyState';
import { ReviewsProps } from './Reviews.types';

import './Reviews.css';

type ReviewsListProps = {
    reviews: UGCReviewContent['views'];
    shortView?: boolean;
};

const ReviewsList: FC<ReviewsListProps> = props => {
    const { reviews, shortView } = props;

    return (
        <div className={cn('Reviews')}>
            {reviews.map(review => (
                <div key={review.id} className={cn('Review')}>
                    <div className={cn('ReviewHeader')}>
                        <Text weight="medium">{review.author?.name}</Text>
                        <div className={cn('ReviewInfo')}>
                            <HotelStars
                                colorful
                                showAll
                                count={review.rating?.val || 0}
                            />
                            <Text color="secondary" typography="body-s">{compactDate(review.time)}</Text>
                        </div>
                    </div>
                    <span className={cn('Review', { shortView })}>
                        {review.text}
                    </span>
                </div>
            ))}
        </div>
    );
};

export const Reviews: FC<ReviewsProps> = props => {
    const {
        reviews: { views },
    } = props;

    const [isReviewModalVisible, toggleReviewModalVisible] = useToggle(false);
    const { isMobile } = usePlatform();

    const shownReviews = views.slice(0, 3);

    return (
        <div className={cn('Section')}>
            <Text className={cn('Title')} color="secondary" typography={isMobile ? 'title-s' : 'title-m'}>
                {i18n('reviews')}
            </Text>

            <Card className={cn('ReviewsCard')}>
                {shownReviews.length > 0 ?
                    <>
                        <ReviewsList shortView reviews={shownReviews} />
                        <Button size="m" view="outline" onClick={toggleReviewModalVisible}>
                            {i18n('show_more')}
                        </Button>
                    </> :
                    <EmptyState />
                }
            </Card>

            <ModalPlatform
                toggleVisible={toggleReviewModalVisible}
                visible={isReviewModalVisible}
            >
                <ReviewsList reviews={views} />
            </ModalPlatform>
        </div>
    );
};
