import { FC } from 'react';

import { Cell } from 'components/Cell';
import { withTdAttrs } from 'hocs/withTdAttrs';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';

import { cn } from './CellCurrency.cn';
import { CellCurrencyProps } from './CellCurrency.types';

import './CellCurrency.css';

const CellCurrencyBase: FC<CellCurrencyProps> = props => {
    const {
        className,
        value,
        getAttrs,
    } = props;

    const valueFormatted = formatCurrency(value, { fractionDigits: 2 });

    return (
        <Cell
            className={cn(null, [className])}
            getAttrs={getAttrs}
            justifyContent="end"
            size="m"
            title={valueFormatted}
        >
            <Text
                className={cn('Text')}
                overflow="ellipsis"
            >
                {valueFormatted}
            </Text>
        </Cell>
    );
};

export const CellCurrency = withTdAttrs(CellCurrencyBase);
CellCurrency.displayName = cn();
