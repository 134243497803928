import { FC } from 'react';

import { ButtonPlatform } from 'components/ButtonPlatform';
import {
    IconBaggageOff,
    IconBaggageOn,
    IconHandBaggageOff,
    IconHandBaggageOn,
} from 'components/Icon';
import { OptionInfoItem } from 'components/OptionInfoItem';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { getBaggageInfo } from 'utils/getBaggageInfo';

import { cn } from './FlightCardActions.cn';
import { i18n } from './FlightCardActions.i18n';
import { FlightCardActionsProps } from './FlightCardActions.types';

import './FlightCardActions.css';

export const FlightCardActions: FC<FlightCardActionsProps> = props => {
    const {
        className,
        baggage,
        price,
        ticketsRemaining,
        isFareFamily,
        onClick,
        canAddServices,
    } = props;

    const {
        hasBaggage,
        baggageText: baggageFormatted,
    } = getBaggageInfo(baggage?.baggage);

    const {
        hasBaggage: hasHandBaggage,
        baggageText: handBaggageFormatted,
    } = getBaggageInfo(baggage?.hand_baggage, { handBaggage: true });

    return (
        <div className={cn(null, [className])}>
            <div className={cn('Services')}>
                <OptionInfoItem
                    hasSuccessColor
                    condition={Boolean(hasHandBaggage)}
                    failureIcon={IconHandBaggageOff}
                    failureText={handBaggageFormatted}
                    successIcon={IconHandBaggageOn}
                    successText={handBaggageFormatted}
                />

                <OptionInfoItem
                    hasFailureColor
                    hasSuccessColor
                    condition={Boolean(hasBaggage)}
                    failureIcon={IconBaggageOff}
                    failureText={baggageFormatted}
                    successIcon={IconBaggageOn}
                    successText={baggageFormatted}
                />
            </div>
            <div className={cn('Details')}>
                <ButtonPlatform
                    disabled={!canAddServices}
                    view="brand"
                    width="max"
                    onClick={onClick}
                >
                    <Text
                        align="center"
                        className={cn('DetailButtonText')}
                        color="inherit"
                        typography="body-s"
                        weight="medium"
                    >
                        {isFareFamily && i18n('from')} {formatCurrency(price)}
                    </Text>
                </ButtonPlatform>
                <Text
                    align="center"
                    as="div"
                    className={cn('SeatsLeft')}
                    color="secondary"
                    typography="caption-m"
                >
                    {i18n('ticketRemaining', { count: ticketsRemaining })}
                </Text>
            </div>
        </div>
    );
};

FlightCardActions.displayName = cn();
