import { ComponentProps, FC } from 'react';
import { TooltipStateful as TooltipStatefulBase } from '@yandex-int/hr-components/Tooltip/desktop/bundle';

import { usePlatform } from 'hooks/usePlatform';

type TooltipStatefulProps = ComponentProps<typeof TooltipStatefulBase> & {
    enabled?: boolean;
};

export const TooltipStateful: FC<TooltipStatefulProps> = props => {
    const {
        children,
        enabled = true,
        hasTail = true,
        size = 's',
        view = 'default',
        trigger,
        ...tooltipProps
    } = props;

    const { isMobile } = usePlatform();

    if (!enabled) {
        return children;
    }

    const defaultTrigger = isMobile ? 'click' : 'hover';

    return (
        <TooltipStatefulBase
            hasTail={hasTail}
            size={size}
            trigger={trigger || defaultTrigger}
            view={view}
            {...tooltipProps}
        >
            {children}
        </TooltipStatefulBase>
    );
};
