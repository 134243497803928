/* eslint-disable react-hooks/rules-of-hooks */
// Сейчас разбираться не буду, очевидно оно работает без багов
// но проблема в том, что когда хуки неправильно называются - без префикса use,
// то и ошибки не подсвечиваются ((
import { SearchResultOptions } from 'types/SearchResultOptions';

import { useHotelSearchDetailsWithGrouping } from './useHotelSearchDetailsWithGrouping';
import { useRailSearchDetails } from './useRailSearchDetails';

export function useSearchOptionDetail(options: SearchResultOptions) {
    const { searchId, key, type, person_id, trip_id } = options;

    switch (type) {
        case 'Hotel':
            return useHotelSearchDetailsWithGrouping(key, searchId, { trip_id, person_id });

        case 'Rail':
            return useRailSearchDetails(key, searchId, { trip_id, person_id });
    }
}
