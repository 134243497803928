import { FC, useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { Card } from 'components/Card';
import { FinanceWidgetSkeleton } from 'components/FinanceWidgetSkeleton';
import { withRemote } from 'hocs/withRemote';
import { DateFormat } from 'utils/formatDate';

import { useExpenses } from './AccountExpenses.hooks/useExpenses';
import { AccountExpenses } from './AccountExpenses';
import { cn } from './AccountExpenses.cn';
import { AccountExpensesContainerProps, HandlePeriodChange } from './AccountExpenses.types';

const AccountExpensesRemote = withRemote(AccountExpenses);

const skeleton = <FinanceWidgetSkeleton className={cn('Skeleton')} />;

export const AccountExpensesContainer: FC<AccountExpensesContainerProps> = props => {
    const {
        className,
        companyId,
    } = props;

    const dateTo = useMemo(() => dayjs(), []);
    const [dateFrom, setDateFrom] = useState(dateTo.subtract(6, 'month'));

    const expenses = useExpenses(companyId, {
        date_from: dateFrom.format(DateFormat.YYYY_MM_DD),
        date_to: dateTo.format(DateFormat.YYYY_MM_DD),
    });

    const handlePeriodChange = useCallback<HandlePeriodChange>(event => {
        const newDateFrom = dateTo.subtract(event.target.value, 'millisecond');

        setDateFrom(newDateFrom);
    }, [dateTo]);

    return (
        <div className={cn(null, [className])}>
            <AccountExpensesRemote
                data={expenses}
                dateFrom={dateFrom}
                dateTo={dateTo}
                fallbackElement={<></>}
                skeleton={<Card className={cn('SkeletonContainer')}>{skeleton}</Card>}
                onPeriodChange={handlePeriodChange}
            />
        </div>
    );
};

AccountExpensesContainer.displayName = cn();
