export const en = {
    monday: 'MO',
    tuesday: 'TU',
    wednesday: 'WE',
    thursday: 'TH',
    friday: 'FR',
    saturday: 'SA',
    sunday: 'SU',
    done: 'Done',
    reset: 'Reset',
    select_one: 'Select {date}',
    select_two: 'Select {dateFrom} - {dateTo}',
    select_date: 'Select date',
};
