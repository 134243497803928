import { FC } from 'react';

import { AccountBalance } from 'components/AccountBalance';
import { AccountExpenses } from 'components/AccountExpenses';

import { cn } from './CompanyFinanceWidgets.cn';
import { CompanyFinanceWidgetsProps } from './CompanyFinanceWidgets.types';

import './CompanyFinanceWidgets.css';

export const CompanyFinanceWidgets: FC<CompanyFinanceWidgetsProps> = props => {
    const {
        className,
        companyId,
    } = props;

    return (
        <div className={cn(null, [className])} data-testid="balance-widgets">
            <AccountBalance
                className={cn('Balance')}
                companyId={companyId}
            />
            <AccountExpenses
                className={cn('Expenses')}
                companyId={companyId}
            />
        </div>
    );
};

CompanyFinanceWidgets.displayName = cn();
