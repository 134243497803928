import * as yup from 'yup';

import { TransactionServiceType, TransactionStatus, TransactionType } from 'services/SwaggerApi';
import { yupFieldDate } from 'shared/lib/yup/yupFieldDate';
import { FieldPersonValue } from 'utils/serializePerson';

import { TransactionFormFields } from './TransactionForm.types';

export const serviceTypes: TransactionServiceType[] = [
    'avia',
    'hotel',
    'rail',
    'transfer',
    'vip_lounge',
    'fast_track',
    'aeroexpress',
    'insurance',
    'additional',
];
export const transactionTypes: TransactionType[] = ['refund', 'purchase', 'exchange'];
export const transactionStatus: TransactionStatus[] = ['hold', 'paid'];

const MIN_FEE = 0;
const MIN_PRICE = -100_000_000;
const MAX_MONEY = 100_000_000;

export const schema: yup.SchemaOf<TransactionFormFields> = yup.object({
    trip_id: yup.number().finite().integer().positive().required(),
    general_service_id: yup.string().trim().nullable(),
    is_penalty: yup.boolean().required(),
    person: yup.mixed<FieldPersonValue>().required(),
    execution_date: yupFieldDate().required(),
    service_type: yup.mixed().oneOf(serviceTypes).required(),
    invoice_date: yupFieldDate(),
    type: yup.mixed().oneOf(transactionTypes).required(),
    status: yup.mixed().oneOf(transactionStatus),
    price: yup.number().finite().min(MIN_PRICE).max(MAX_MONEY).required(),
    cost_center: yup.mixed().nullable(),
    fee: yup.number().finite().max(MAX_MONEY).when('is_penalty', {
        is: true,
        then: schema => schema.min(MIN_FEE),
        otherwise: schema => schema.moreThan(MIN_FEE),
    }).required(),
});
