export const ru = {
    available_rooms: 'Доступные номера',
    features: 'Услуги и удобства',
    review: 'Отзывы',
    choose: 'Выбрать',
    check_in_from: 'Заезд c {time}',
    check_out_before: 'Выезд до {time}',
    colleagues_recommend: 'Коллеги рекомендуют',
    early_check_in: 'Ранний заезд',
    late_check_out: 'Поздний выезд',
    early_check_in_from: 'Время заезда — {time}',
    late_check_out_before: 'Время выезда — {time}',
    check_in_hotel: 'Уточните в отеле заранее возможен ли {type} заезд',
    early_check_in_possible: 'Уточните в отеле заранее возможен ли ранний заезд',
    late_check_out_possible: 'Уточните в отеле заранее возможен ли поздний выезд',
    description: 'Описание',
    meal_included: 'Питание включено',
    no_meal: 'Без питания',
    free_cancelation: 'Бесплатная отмена',
    cancelation_with_fee: 'Отмена со штрафом',
    per_night: 'за ночь',
    rating: 'Рейтинг',
    placement_terms: 'Условия размещения',
    important_information: 'Важная информация',
    based_on_reviews: {
        one: 'На основе {count} отзыва',
        some: 'На основе {count} отзывов',
        many: 'На основе {count} отзывов',
    },
    room_on_request: 'Номер под запрос',
    room_on_request_hint: 'Подтверждение проживания не гарантировано, получение ваучера может занять до 24ч',
    show_all: 'Показать полностью',
    show_more: 'Показать еще',
    hide: 'Скрыть',
    tariffs: 'Тарифы',
    reviews: 'Отзывы',
    empty_reviews_title: 'Отзывов пока нет',
    empty_reviews_description: 'Но скоро они появятся',
    choose_room: 'Выбрать номер',
    guests_enjoyed: 'Гостям понравилось',
    go_to_reviews: 'Перейти к отзывам',
    go_to_trip: 'Перейти в заявку',
    general_rooms_info: '{count} предложений от {minPricePerNight} за ночь',
    m: 'м. {station}',
    no_rooms_selected: 'Выберите номера для участников командировки',
    rooms_selected: 'Номера добавленные в заявку',
    passengers_list: 'Выбранные номера',
};
