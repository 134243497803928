import { FC, memo, useMemo, useReducer, useRef } from 'react';

import { Card } from 'components/Card';
import { HeaderPage } from 'components/HeaderPage';
import { HotelRoom } from 'components/HotelRoom';
import { TabsPage } from 'components/TabsPage';
import { TitlePage } from 'components/TitlePage';
import { ImpossibleError } from 'errors';
import { withRemote } from 'hocs/withRemote';
import { getGeneralInfoAboutRooms } from 'hooks/serviceDetailToService';
import { useGetReviewsForHotel } from 'hooks/useGetReviewsForHotel';
import { usePlatform } from 'hooks/usePlatform';
import { isIOS } from 'platform/utils';
import { HotelServiceDetailsTab } from 'routes/RouteServiceDetails';
import { Container } from 'shared/ui/Container';
import { isInitial } from 'utils/Loadable';

import { getPopularFeatures } from './Features/Features.utils/getPopularFeatures';
import { isGoodPlaceGroup } from './Features/Features.utils/isGoodPlaceGroup';
import { groupFacilities } from './HotelDetails.utils/groupFacilities';
import { Reviews } from './Reviews/Reviews';
import { servicesAddReducer } from './utils/serviceAddReducer';
import { ActionBar } from './ActionBar';
import { Description } from './Description';
import { Features } from './Features';
import { cn } from './HotelDetails.cn';
import { i18n } from './HotelDetails.i18n';
import { HotelDetailsProps } from './HotelDetails.types';
import { ImagesGalery } from './ImagesGalery';
import { ImportantInformation } from './ImportantInformation';
import { LocationButton } from './LocationButton';
import { LocationOnMap } from './LocationOnMap';
import { Passengers } from './Passengers';
import { Rating } from './Rating';
import { ReviewsSkeleton } from './Reviews';
import { SubHeader } from './SubHeader';

import './HotelDetails.css';

const ReviewsRemote = withRemote(Reviews);

export const HotelDetails: FC<HotelDetailsProps> = memo(props => {
    const {
        className,
        params: { searchId, key },
        search: { trip_id, person_id, name, adult = 1 },
        hotelDetails,
        trip,
        canAddServices,
    } = props;

    if (props.search.type !== 'Hotel') {
        throw new ImpossibleError();
    }

    const { minPricePerNight, roomsCount } = getGeneralInfoAboutRooms(hotelDetails);
    const { ymap_info, hotel_name } = hotelDetails.hotel;

    const reviews = useGetReviewsForHotel({ org_id: String(ymap_info?.org_id) }, {
        isEnabled: Boolean(ymap_info?.org_id),
    });

    const count_reviews = ymap_info?.rate?.count_reviews || 0;
    const average_score = ymap_info?.rate?.average_score || 0;
    const importantInformation = hotelDetails.hotel.important_information;
    const hotelDescription = hotelDetails.hotel.description || ymap_info?.description;

    const showReviews = !isInitial(reviews);
    const showRatingCard = count_reviews > 0 && average_score > 0;
    const showImportantInformation = importantInformation ||
        hotelDetails.hotel.check_in ||
        hotelDetails.hotel.check_out;

    const { isMobile, isDesktop } = usePlatform();

    const [addedServices, dispatchService] = useReducer(servicesAddReducer, []);

    const features = (ymap_info?.feature_groups || groupFacilities(hotelDetails.hotel.facilities));
    const popularFeatures = useMemo(() => {
        const popularFeatures = getPopularFeatures(features);
        const isGoodPlace = features.length > 0 && isGoodPlaceGroup(features[0]);

        isGoodPlace && popularFeatures.unshift('good_place');

        return popularFeatures;
    }, [features]);
    const showFeatures = popularFeatures.length >= 2;

    const tabsList: HotelServiceDetailsTab[] = useMemo(() => (
        [
            !isMobile && 'available_rooms',
            showFeatures && 'features',
            showReviews && 'review',
            showImportantInformation && 'placement_terms',
            hotelDescription && 'description',
        ].filter(Boolean)
    ), [isMobile, showFeatures, showReviews, showImportantInformation, hotelDescription]);

    const headerTabs = useMemo(() => {
        return tabsList.map(tab => ({
            id: tab,
            text: i18n(tab),
            href: `#${tab}`,
        }));
    }, [tabsList]);

    const { geo_position } = hotelDetails.hotel;

    const ref = useRef<HTMLDivElement>(null);

    const hotelImages = hotelDetails.hotel.images;
    const hasGeoPostion = geo_position && geo_position?.latitude && geo_position?.longitude;

    return (
        <div className={cn({ isMobileApp: isIOS() }, [className])}>
            <Container wrapperClassName={cn('TitlePage')}>
                <TitlePage
                    retButton
                    title={name}
                />
            </Container>
            <HeaderPage
                retButton
                className={cn('HeaderPage')}
            >
                <SubHeader className={cn('HotelInfo')} hotel={hotelDetails} />
                <div ref={ref} className={cn('LocationAndGallery', { noPhotos: hotelImages.length === 0 })}>
                    {isDesktop && hasGeoPostion && <LocationOnMap geo_position={geo_position} />}
                    {hotelImages.length > 0 && <ImagesGalery images={hotelImages} refContainer={ref} />}
                    {isMobile && hasGeoPostion && <LocationButton geo_position={geo_position} />}
                </div>
            </HeaderPage>
            <Container wrapperClassName={cn('Tabs')}>
                <TabsPage tabs={headerTabs} />
            </Container>
            <Container
                className={cn('Container')}
                wrapperClassName={cn('Wrapper')}
            >
                <div className={cn('Main')}>
                    {isMobile ?
                        <ActionBar
                            adult={adult}
                            count={roomsCount}
                            minPricePerNight={minPricePerNight}
                            optionId={key}
                            personId={person_id}
                            searchId={searchId}
                            tripId={trip_id}
                        /> :
                        <div className={cn('Tab')}>
                            <span
                                className={cn('AnchorOffset')}
                                id="available_rooms"
                            />
                            <div className={cn('RoomsList')}>
                                {hotelDetails.data.map(room => (
                                    <Card key={room.name}>
                                        <HotelRoom
                                            addedServices={addedServices}
                                            adultsCount={adult}
                                            canAddServices={canAddServices}
                                            dispatchService={dispatchService}
                                            hotelName={hotel_name}
                                            nights={hotelDetails.hotel.num_of_nights}
                                            optionId={hotelDetails.hotel.id}
                                            personId={person_id}
                                            roomData={room}
                                            searchId={searchId}
                                            trip={trip}
                                        />
                                    </Card>
                                ))}
                            </div>
                        </div>
                    }
                    {showFeatures && (
                        <div className={cn('Tab')}>
                            <span
                                className={cn('AnchorOffset')}
                                id="features"
                            />
                            <Features features={features} popularFeatures={popularFeatures} />
                        </div>

                    )}
                    {showReviews && (
                        <div className={cn('Tab')}>
                            <span
                                className={cn('AnchorOffset')}
                                id="review"
                            />
                            <ReviewsRemote
                                reviews={reviews}
                                skeleton={<ReviewsSkeleton />}
                            />
                        </div>
                    )}
                    {showImportantInformation && (
                        <div className={cn('Tab')}>
                            <span
                                className={cn('AnchorOffset')}
                                id="placement_terms"
                            />
                            <ImportantInformation
                                checkIn={hotelDetails.hotel.check_in}
                                checkOut={hotelDetails.hotel.check_out}
                                importantInformation={importantInformation}
                            />
                        </div>
                    )}
                    {hotelDescription && (
                        <div className={cn('Tab')}>
                            <span
                                className={cn('AnchorOffset')}
                                id="description"
                            />
                            <Description
                                description={hotelDescription}
                                hotelName={hotel_name}
                            />
                        </div>
                    )}
                </div>
                <div className={cn('Aside')}>
                    <div className={cn('Island')}>
                        {isDesktop && trip_id && (
                            <Passengers
                                addedServices={addedServices}
                                dispatchService={dispatchService}
                                personId={person_id}
                                tripId={trip_id}
                            />
                        )}
                        {showRatingCard && (
                        <div className={cn('Tab', { type: 'rating' })}>
                            <span
                                className={cn('AnchorOffset')}
                                id="rating"
                            />
                            <Rating
                                average_rating={average_score}
                                count_reviews={count_reviews}
                                reviews={isInitial(reviews) ? null : reviews}
                            />
                        </div>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
});
