import { QueryClient } from 'react-query';

import { SwaggerApi } from 'services/SwaggerApi';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

export const prefetchQueries = (queryClient: QueryClient, api: SwaggerApi, personId: string) => {
    // prefetch data for tabs
    // tab active trips
    queryClient.prefetchInfiniteQuery(
        ['trip_list', { limit: 5, person: personId }],
        () => api.trip_list({ limit: 5, person: personId }).then(throwHTTPErrors),
        { cacheTime: Infinity },
    );
    // tab trips
    queryClient.prefetchInfiniteQuery(
        ['trip_list', { limit: 5 }],
        () => api.trip_list({ limit: 5 }).then(throwHTTPErrors),
        { cacheTime: Infinity },
    );
    // tab documents
    queryClient.prefetchQuery(
        ['get_person_details', personId],
        () => api.get_person_details(personId).then(throwHTTPErrors),
        { cacheTime: Infinity },
    );
    queryClient.prefetchQuery(
        ['person_document_list', personId],
        () => api.person_document_list(personId).then(throwHTTPErrors),
        { cacheTime: Infinity },
    );
    queryClient.prefetchQuery(
        ['person_bonus_card_list', personId, {}],
        () => api.person_bonus_card_list(personId, {}).then(throwHTTPErrors),
        { cacheTime: Infinity },
    );
};
