import { useMemo } from 'react';

import { mapLoadable, RemoteData } from 'utils/Loadable';

export function useMapLoadable<V1, V2, TError>(
    loadable: RemoteData<V1, TError>,
    mapFn: (value: V1) => V2,
) {
    return useMemo(
        () => mapLoadable(loadable, mapFn),
        [loadable, mapFn],
    );
}
