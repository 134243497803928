import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { compose, composeU } from '@bem-react/core';
import { Registry, withRegistry } from '@bem-react/di';
import {
    cnToolsSuggest,
    Item,
    ToolsSuggest as ToolsSuggestBase,
    withSizeM as toolsSuggestWithSizeM,
    withSizeS as toolsSuggestWithSizeS,
    withThemeNormal as toolsSuggestWithThemeNormal,
    withViewDefault as toolsSuggestWithViewDefault,
} from '@yandex-int/tools-components/ToolsSuggest/desktop';
import { Textinput } from '@yandex-lego/components/Textinput/desktop/bundle';

import { ConfUserInfoFields } from 'components/FormTripCreate/FormTripCreate-ConfFields/ConfUserInfoFields';
import { PersonFields } from 'components/FormTripCreate/FormTripCreate-PersonFields';
import { UserPane } from 'components/UserPane';
import { UserSelected } from 'components/UserSelected';
import { Popup } from 'shared/ui/Popup';
import { getFieldsType } from 'utils/getFieldsType';

import { cn } from './PersonSuggest.cn';
import { i18n } from './PersonSuggest.i18n';
import { PersonSuggestItem, PersonSuggestProps } from './PersonSuggest.types';

import './PersonSuggest.css';

const toolsSuggestRegistry = new Registry({ id: cnToolsSuggest() });

toolsSuggestRegistry.set('Textinput', Textinput);
toolsSuggestRegistry.set('Popup', Popup);

const ToolsSuggest = compose(
    toolsSuggestWithViewDefault,
    composeU(
        toolsSuggestWithSizeM,
        toolsSuggestWithSizeS,
    ),
    toolsSuggestWithThemeNormal,
)(withRegistry(toolsSuggestRegistry)(ToolsSuggestBase));

interface UserSelectedContainerProps {
    chosen: PersonSuggestItem[];
    value: PersonSuggestItem;
    onCloseClick: (chosen: PersonSuggestItem[]) => void;
    valIndex: number;
}

const UserSelectedContainer: FC<UserSelectedContainerProps> = ({ value, chosen, onCloseClick, valIndex }) => {
    const form = useForm();

    const handleClose = useCallback(() => {
        form.mutators.remove('persons', valIndex);
        onCloseClick(chosen.filter(item => item.uid !== value.uid));
    }, [form.mutators, valIndex, onCloseClick, chosen, value.uid]);

    return <UserSelected
        className={cn('Item')}
        fullName={value.title}
        login={value.login}
        personId={value.id}
        onCloseClick={handleClose}
    />;
};

function renderChoice(choice: PersonSuggestItem): ReactNode {
    return (
        <div>
            <UserPane
                fullName={choice.title}
                login={choice.login}
                personId={choice.id}
            />
        </div>
    );
}

export const PersonSuggest: FC<PersonSuggestProps> = ({
    opened,
    input,
    onInputChange,
    loading,
    choices,
    className,
    value,
    onChange,
    onBlur,
    placeholder,
    singleChoice = false,
    state,
}) => {
    const [picked, onPickedChange] = useState<PersonSuggestItem[]>([]);
    const formState = useFormState();
    const form = useForm();
    const isConference = getFieldsType(formState.values.purposes) === 'conf';

    const onChosenChange = useCallback((chosenFromSuggestUntyped: Array<Item>) => {
        const chosenFromSuggest = chosenFromSuggestUntyped as PersonSuggestItem[];
        const result = [...chosenFromSuggest];

        if (!singleChoice) {
            result.push(...value);
        }

        if (form.getRegisteredFields().includes('persons')) {
            form.mutators.push('persons', ...chosenFromSuggest);
        }

        onChange(result);
    }, [singleChoice, form, onChange, value]);

    const chosen = useMemo(() => {
        if (!singleChoice) return [];

        return (value || []).map(person => {
            return ({ id: `${person.title} @${person.login}` });
        });
    }, [singleChoice, value]);

    const wrapperRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={wrapperRef}>
            <ToolsSuggest
                noArrow
                choices={choices}
                chosen={chosen}
                className={cn({ type: 'person' }, [className])}
                empty={!loading && i18n('nothing_found')}
                loading={loading}
                maxChosen={singleChoice ? 1 : undefined}
                opened={opened}
                picked={picked}
                placeholder={placeholder}
                renderChoice={renderChoice as any}
                scopeRef={wrapperRef}
                size={'m'}
                state={state}
                value={input}
                view="default"
                onBlur={onBlur}
                onChosenChange={onChosenChange}
                onPickedChange={onPickedChange as any}
                onValueChange={onInputChange}
            />
            {
                !singleChoice && (
                    <div className={cn('Chosen')}>
                        <FieldArray name="persons">
                            {({ fields }) => fields.map((name, index) =>
                                <div key={index} className={cn('ChosenItem')}>
                                    <UserSelectedContainer
                                        chosen={value}
                                        valIndex={index}
                                        value={fields.value[index]}
                                        onCloseClick={onChange}
                                    />
                                    <PersonFields
                                        name={name}
                                        route={formState.values.route}
                                        value={fields.value[index]}
                                    />
                                    {isConference &&
                                        <ConfUserInfoFields
                                            name={name}
                                            value={fields.value[index]}
                                        />
                                    }
                                </div>,
                            )}
                        </FieldArray>
                    </div>
                )
            }
        </div>
    );
};
