import * as D from 'io-ts/Decoder';

import { ActivePage } from 'shared/constants/ActivePage';

import { RouteUntyped } from './decoders/RouteUntyped';
import { route } from './route';

export type RouteNotFound = D.TypeOf<typeof RouteUntyped>;

export const RouteNotFound = route(
    '*',
    RouteUntyped,
    {
        activePage: ActivePage.NOT_FOUND,
    },
);
