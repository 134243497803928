import { ServicesDetailsWithId } from 'types/TripRoute';
import { TripSegment } from 'types/TripSegment';

type TypeMap = {[key in ServicesDetailsWithId['service_type']]: TripSegment['type']};

const serviceSegmentTypeMap: TypeMap = {
    rail: 'transport',
    avia: 'transport',
    hotel: 'hotel',
};

export const hasSameTypes = (segment: TripSegment, service: ServicesDetailsWithId) =>
    serviceSegmentTypeMap[service.service_type] === segment.type;
