import { FC } from 'react';

import { SyncYandexIdDocumentsButton } from 'components/SyncYandexIdDocumentsButton';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { ErrorState } from 'shared/ui/ErrorState';

import { cn } from '../DocumentsList.cn';
import { i18n } from '../DocumentsList.i18n';

import { EmptyStateProps } from './EmptyState.types';

import './EmptyState.css';

export const EmptyState: FC<EmptyStateProps> = props => {
    const {
        handleAddClick,
        className,
    } = props;

    return (
        <div className={cn('EmptyState', [className])}>
            <ErrorState
                actions={[
                    (
                        <Button
                            key="create"
                            className={cn('AddButtonEmpty')}
                            size="m"
                            view="primary"
                            onClick={handleAddClick}
                        >
                            {i18nAction('add')}
                        </Button>
                    ),
                    SyncYandexIdDocumentsButton({ }, { key: 'sync' }),
                ].filter(Boolean)}
                description={i18n('empty_description')}
                size="s"
                title={i18n('empty_title')}
            />
        </div>
    );
};
