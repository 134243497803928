import { FC } from 'react';
import { cn as classname } from '@bem-react/classname';
import { compose, composeU } from '@bem-react/core';
import {
    Spin as SpinBase,
    withPositionCenter,
    withSizeL,
    withSizeS,
    withViewDefault,
} from '@yandex-lego/components/Spin/desktop';

import { TripTips } from 'components/TripTips';
import { Text } from 'shared/ui/Text';

import { SpinnerProps } from './Spinner.interface';

import './Spinner.css';

const Spin = compose(
    composeU(
        withSizeL,
        withSizeS,
    ),
    withViewDefault,
    withPositionCenter,
)(SpinBase);

const cn = classname('Spinner');

export const Spinner: FC<SpinnerProps> = props => {
    const {
        message,
        details,
        size,
        className,
        showTips,
        isOverComponent,
    } = props;

    return (
        <div className={cn({ overComponent: isOverComponent }, [className])}>
            <div className={cn('Wrapper')}>
                <Spin
                    progress
                    className={cn('Spin')}
                    size={size || 'l'}
                    view="default"
                />
            </div>
            {message && <Text className={cn('Message')}>{message}</Text>}
            {details && <Text>{details}</Text>}
            {showTips && <TripTips className={cn('TripTips')} />}
        </div>
    );
};
