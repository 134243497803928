import { FieldRenderProps } from 'react-final-form';
import { IClassNameProps } from '@bem-react/core';

import { IFilterOption } from 'api/models/search/filter';

export interface FilterFieldTransfersProps extends FieldRenderProps<string>, IClassNameProps {
    transfers: IFilterOption<string>[];
}

export enum TransfersCount {
    ANY_TRANSFERS = 'any',
    WITHOUT_TRANSFERS = '0',
    MAX_ONE_TRANSFER = '1',
    MAX_TWO_TRANSFERS = '2',
}
