import { TravelPolicyDetailed } from 'services/SwaggerApi';

export const getDefaultFormValues = (details: TravelPolicyDetailed | null) => {
    if (!details) {
        return;
    }

    const aviaTotalMaxPrice = details.avia.max_price && details.avia.max_price.filter(item => !item.location_id)[0];
    const aviaDirectionsMaxPrice = details.avia.max_price ?
        details.avia.max_price.filter(item => Boolean(item.location_id)) :
        [];

    const railTotalMaxPrice = details.rail.max_price && details.rail.max_price.filter(item => !item.location_id)[0];
    const railDirectionsMaxPrice = details.rail.max_price ?
        details.rail.max_price.filter(item => Boolean(item.location_id)) :
        [];

    const hotelTotalMaxPrice =
        details.hotel.max_price_per_night && details.hotel.max_price_per_night.filter(item => !item.location_id)[0];
    const hotelDirectionsMaxPrice = details.hotel.max_price_per_night ?
        details.hotel.max_price_per_night
            .filter(item => Boolean(item.location_id))
            .map(item => ({
                location_id: item.location_id,
                location_name: item.location_name,
                location_type: item.location_type,
                max_price: item.max_price_per_night,
            })) :
        [];

    return {
        name: details.name,

        is_avia_max_price: Boolean(aviaTotalMaxPrice),
        ...(aviaTotalMaxPrice && { avia_max_price: String(aviaTotalMaxPrice.max_price) }),
        is_avia_cheapest_more_percent: Boolean(details.avia.cheapest_more_percent?.length),
        ...(details.avia.cheapest_more_percent?.length > 0 && {
            avia_cheapest_more_percent: String(details.avia.cheapest_more_percent[0].cheapest_more_percent),
        }),
        is_avia_book_before_days: Boolean(details.avia.book_before_days?.length),
        ...(details.avia.book_before_days?.length > 0 && {
            avia_book_before_days: String(details.avia.book_before_days[0].book_before_days),
        }),
        is_avia_max_transfer_duration_hours: Boolean(details.avia.max_transfer_duration_hours?.length),
        ...(details.avia.max_transfer_duration_hours?.length > 0 && {
            avia_max_transfer_duration_hours: String(
                details.avia.max_transfer_duration_hours[0].max_transfer_duration_hours,
            ),
        }),
        avia_refundable_only: Boolean(details.avia.refundable_only[0].refundable_only),
        avia_exchangeable_only: Boolean(details.avia.exchangeable_only[0].exchangeable_only),
        avia_with_baggage_only: Boolean(details.avia.with_baggage_only[0].with_baggage_only),
        avia_business_days_only: Boolean(details.avia.business_days_only[0].business_days_only),
        avia_service_classes:
            details.avia.service_classes && details.avia.service_classes[0] &&
                details.avia.service_classes[0].service_classes || [],
        avia_max_price_directions: aviaDirectionsMaxPrice,

        is_rail_max_price: Boolean(railTotalMaxPrice),
        ...(railTotalMaxPrice && { rail_max_price: String(railTotalMaxPrice.max_price) }),
        is_rail_cheapest_more_percent: Boolean(details.rail.cheapest_more_percent?.length),
        ...(details.rail.cheapest_more_percent?.length > 0 && {
            rail_cheapest_more_percent: String(details.rail.cheapest_more_percent[0].cheapest_more_percent),
        }),
        is_rail_book_before_days: Boolean(details.rail.book_before_days?.length),
        ...(details.rail.book_before_days?.length > 0 && {
            rail_book_before_days: String(details.rail.book_before_days[0].book_before_days),
        }),
        rail_business_days_only: Boolean(details.rail.business_days_only[0].business_days_only),
        rail_categories:
            details.rail.train_car_categories && details.rail.train_car_categories[0] &&
                details.rail.train_car_categories[0].train_car_categories || [],
        rail_max_price_directions: railDirectionsMaxPrice,

        is_hotel_max_price_per_night: Boolean(hotelTotalMaxPrice),
        ...(hotelTotalMaxPrice && { hotel_max_price_per_night: String(hotelTotalMaxPrice.max_price_per_night) }),
        is_hotel_cheapest_more_percent: Boolean(details.hotel.cheapest_more_percent?.length),
        ...(details.hotel.cheapest_more_percent?.length > 0 && {
            hotel_cheapest_more_percent: String(details.hotel.cheapest_more_percent[0].cheapest_more_percent),
        }),
        is_hotel_book_before_days: Boolean(details.hotel.book_before_days?.length),
        ...(details.hotel.book_before_days?.length > 0 && {
            hotel_book_before_days: String(details.hotel.book_before_days[0].book_before_days),
        }),
        hotel_business_days_only: Boolean(details.hotel.business_days_only[0].business_days_only),
        hotel_stars:
            details.hotel.stars_allowed && details.hotel.stars_allowed[0] &&
                details.hotel.stars_allowed[0].stars_allowed || [],
        hotel_max_price_directions: hotelDirectionsMaxPrice,
    };
};
