import { FC } from 'react';

import { HelpTooltip } from 'components/HelpTooltip';
import { Text } from 'shared/ui/Text';

import yandexLogo from './CorporateTariff.assets/IconYa.svg';
import { cn } from './CorporateTariff.cn';
import { i18n } from './CorporateTariff.i18n';
import { CorporateTariffProps } from './CorporateTariff.types';

import './CorporateTariff.css';

export const CorporateTariff: FC<CorporateTariffProps> = props => {
    const {
        typography = 'body-s',
        inverse,
        className,
    } = props;

    return (
        <div className={cn(null, [className])}>
            <img src={yandexLogo} />
            <Text className={cn('Text')} typography={typography}>
                {i18n('corporate_tariff')}
            </Text>

            <HelpTooltip
                content={i18n('tooltip_content')}
                iconSize="m"
                inverse={inverse}
            />
        </div>
    );
};

CorporateTariff.displayName = cn();
