import { FC } from 'react';

import { FieldSuggest, FormProvider } from 'components/FormField2';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';

import { useFormFindCompany } from './hooks/useFormFindCompany';
import { cn } from './FormFindCompany.cn';
import { i18n } from './FormFindCompany.i18n';
import { FormFindCompanyProps } from './FormFindCompany.types';

import './FormFindCompany.css';

export const FormFindCompany: FC<FormFindCompanyProps> = props => {
    const {
        className,
        onSubmit: submitHandler,
    } = props;

    const { methods, schema, onSubmit } = useFormFindCompany(submitHandler);

    return (
        <FormProvider methods={methods} schema={schema}>
            <form
                className={cn(null, [className])}
                data-testid="form-find-company"
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <FieldSuggest
                    name="company"
                    placeholder={i18n('placeholder.suggest_kontur')}
                    type="konturCompanies"
                />
                <Button
                    size="m"
                    type="submit"
                    view="brand"
                >
                    {i18nAction('continue')}
                </Button>
            </form>
        </FormProvider>
    );
};

FormFindCompany.displayName = cn();
