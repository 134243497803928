import { ComponentType } from 'react';

export function withProps<U>(initialProps: U, className: string | null = null) {
    return function wrapper<T>(WrappedComponent: ComponentType<T>): ComponentType<Omit<T, keyof U>> {
        return remainingProps => {
            const props = { ...remainingProps, ...initialProps } as unknown as T;

            return <WrappedComponent className={className} {...props} />;
        };
    };
}
