import { useLocation } from 'react-router-dom';

import { config } from 'config';
import { RouteMyActiveTrips } from 'routes/RouteMyActiveTrips';
import { RouteRegister } from 'routes/RouteRegister';
import { RouteRegisterCompany } from 'routes/RouteRegisterCompany';
import { RouteRegisterEmail } from 'routes/RouteRegisterEmail';
import { RouteRegisterForbidden } from 'routes/RouteRegisterForbidden';
import { RouteRegisterRejected } from 'routes/RouteRegisterRejected';
import { AuthDetails } from 'types/AuthDetails';

import { isAuthDetailsResponse, isUsersResponse, MetaResponse } from './useAuthMeta';

const { isB2B } = config;

const NO_REDIRECT_KEY = 'changeEmail';

const registerPaths = [
    RouteRegister.path,
    RouteRegisterEmail.path,
    RouteRegisterCompany.path,
    RouteRegisterForbidden.path,
    RouteRegisterRejected.path,
];

const noRedirectPaths = [
    RouteRegisterCompany.path,
];

type CheckShouldRedirect = (meta: MetaResponse, path: string, search: string) => boolean;

const checkShouldRedirect: CheckShouldRedirect = (meta, path, search) => {
    const queryParams = new URLSearchParams(search);
    const hasNoRedirectKey = path === RouteRegister.path && queryParams.has(NO_REDIRECT_KEY);

    if (
        !isB2B ||
        isAuthDetailsResponse(meta) && (noRedirectPaths.includes(path) || hasNoRedirectKey)
    ) {
        return false;
    }

    return true;
};

type UserRegisterRedirect = (currentPath: string, meta: MetaResponse) => string | null;

export const useRegisterRedirect: UserRegisterRedirect = (currentPath, meta) => {
    const { search } = useLocation();

    const shouldRedirect = checkShouldRedirect(meta, currentPath, search);

    if (!shouldRedirect) {
        return null;
    }

    let redirectPath: string | null = null;

    if (isAuthDetailsResponse(meta)) {
        switch (meta.detail) {
            case AuthDetails.NOT_CONFIRMED_EMAIL:
                redirectPath = RouteRegisterEmail.path;
                break;

            case AuthDetails.EMPTY_COMPANY:
                redirectPath = RouteRegisterCompany.path;
                break;

            case AuthDetails.USER_DEACTIVATED:
                redirectPath = RouteRegisterForbidden.path;
                break;

            case AuthDetails.USER_REJECTED:
                redirectPath = RouteRegisterRejected.path;
                break;

            default:
                redirectPath = RouteRegister.path;
                break;
        }
    }

    if (isUsersResponse(meta) && registerPaths.includes(currentPath)) {
        redirectPath = RouteMyActiveTrips.path;
    }

    if (redirectPath !== currentPath) {
        return redirectPath;
    }

    return null;
};
