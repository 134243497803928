import { FC } from 'react';

import { Text } from 'shared/ui/Text';

import { cn } from '../ServiceState.cn';
import { i18n } from '../ServiceState.i18n';

import { ServiceStateHintProps } from './ServiceStateHint.types';

import './ServiceStateHint.css';

export const ServiceStateHint: FC<ServiceStateHintProps> = props => {
    const { state, serviceType } = props;

    const title = i18n(`${state}_title`);
    const description = i18n(`${state}_description`, { timespan: i18n(`timespan_${serviceType}`) });

    return (
        <div className={cn('Hint')}>
            <Text typography="body-l" weight="medium">{title}</Text>
            {description && <Text color="secondary" typography="paragraph-s">{description}</Text>}
        </div>
    );
};

ServiceStateHint.displayName = cn();
