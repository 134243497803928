import { useState } from 'react';

import { useSuggestPersons } from 'hooks/useSuggestPersons';
import { CompanyName } from 'services/SwaggerApi';
import { foldSuccess, isLoading } from 'utils/Loadable';

type UsePersonSuggestProps = {
    companyId: CompanyName['company_id'];
};

export function usePersonSuggest(props: UsePersonSuggestProps) {
    const { companyId } = props;

    const [searchValue, setSearchValue] = useState<string>('');

    const persons = useSuggestPersons({
        text: searchValue,
        company_id: companyId,
        isEnabled: Boolean(searchValue),
    });

    const suggestPersons = foldSuccess(persons, ({ result }) => result, () => []).map(person => ({
        first_name: person.title.split(' ')[0],
        group_id: person.group?.group_id,
        group_name: person.group?.group_name,
        last_name: person.title.split(' ')[1],
        login: person.login,
        person_id: person.person_id,
        travel_policy_id: person.travel_policy?.travel_policy_id,
        travel_policy_name: person.travel_policy?.travel_policy_name,
    }));

    return {
        searchValue,
        setSearchValue,
        suggestPersons,
        isSuggestPersonsLoading: isLoading(persons),
    };
}
