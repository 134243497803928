import { FC, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { FieldCheckbox, FieldSelect, FormProvider } from 'components/FormField2';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';

import { cn } from '../ModalEarlyInLateOut.cn';
import { i18n } from '../ModalEarlyInLateOut.i18n';
import { ModalEarlyInLateOutProps } from '../ModalEarlyInLateOut.types';
import { getEiloOptions } from '../ModalEarlyInLateOut.utils';

import { EarlyInLateOutFields, HandleFormSubmit } from './FormEarlyInLateOut.types';

export const FormEarlyInLateOut: FC<ModalEarlyInLateOutProps> = props => {
    const {
        toggleModal,
        earlyCheckinChoices,
        lateCheckoutChoices,
        onFormSubmit,
    } = props;

    const methods = useForm<EarlyInLateOutFields>({
        defaultValues: {
            has_early_check_in: false,
            has_late_check_out: false,
            early_check_in: undefined,
            late_check_out: undefined,
        },
    });

    const checkInTimes = useMemo(() => getEiloOptions(earlyCheckinChoices), [earlyCheckinChoices]);
    const checkOutTimes = useMemo(() => getEiloOptions(lateCheckoutChoices), [lateCheckoutChoices]);

    const onModalClose = useCallback(() => {
        methods.reset();
        toggleModal();
    }, [methods, toggleModal]);

    const formSubmitHandler: HandleFormSubmit = values => {
        onFormSubmit({
            early_check_in: earlyCheckinChoices?.[Number(values.early_check_in)],
            late_check_out: lateCheckoutChoices?.[Number(values.late_check_out)],
        });
        toggleModal();
    };

    return (
        <FormProvider methods={methods}>
            <form
                className={cn('FormEarlyInLateOut')}
                onSubmit={methods.handleSubmit(formSubmitHandler)}
            >
                <div className={cn('Fields')}>
                    <FieldCheckbox
                        checkboxLabel={i18n('early_check_in')}
                        disabled={!earlyCheckinChoices || earlyCheckinChoices?.length === 0}
                        lines="one"
                        name="has_early_check_in"
                    />
                    <FieldSelect
                        className={cn('Select')}
                        disabled={!methods.watch('has_early_check_in')}
                        name="early_check_in"
                        options={checkInTimes}
                        placeholder={i18n('choose_check_in')}
                    />
                    <FieldCheckbox
                        checkboxLabel={i18n('late_check_out')}
                        disabled={!lateCheckoutChoices || lateCheckoutChoices?.length === 0}
                        lines="one"
                        name="has_late_check_out"
                    />
                    <FieldSelect
                        className={cn('Select')}
                        data-testId="forClickTest"
                        disabled={!methods.watch('has_late_check_out')}
                        name="late_check_out"
                        options={checkOutTimes}
                        placeholder={i18n('choose_check_out')}
                    />
                </div>
                <div className={cn('Actions')}>
                    <Button size="m" view="ghost" onClick={onModalClose}>
                        {i18nAction('cancel')}
                    </Button>
                    <Button size="m" type="submit" view="primary">
                        {i18nAction('add')}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};
