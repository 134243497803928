import * as z from 'zod';

import { ActivePage } from 'shared/constants/ActivePage';
import { numberInString } from 'shared/lib/zod/numberInString';
import { checkAccessFactory } from 'utils/checkAccessFactory';

import { route } from './route';

export const RouteTripsDecoder = z.object({
    params: z.object({}),
    search: z.object({
        date_from__lte: z.string(),
        date_from__gte: z.string(),
        city_to: numberInString,
        tracker_issue: z.string(),
        person: z.string(),
        manager: z.string(),
    }).partial(),
});

export type RouteTrips = z.output<typeof RouteTripsDecoder>;

const checkAccess = checkAccessFactory(
    meta => !meta.is_limited_access,
    'forbidden',
);

export const RouteTrips = route(
    '/trips/',
    RouteTripsDecoder,
    {
        activePage: ActivePage.ALL_TRIPS_LIST,
        checkAccess,
    },
);
