import { FC } from 'react';

import { Text } from 'shared/ui/Text';

import { cn } from './Bullet.cn';
import { BulletProps } from './Bullet.types';

import './Bullet.css';

/**
 * • Большая • жирная • точка •
 */
export const Bullet: FC<BulletProps> = props => {
    const {
        className,
        space = 'xs',
    } = props;

    return (
        <Text className={cn({ space }, [className])}>•</Text>
    );
};

Bullet.displayName = cn();
