import { createUseInfiniteQueryHook } from 'hooks/createUseInfiniteQueryHook';
import { TGet_group_membersQuery } from 'services/SwaggerApi';

type UseGroupMembersOptions = {
    isEnabled?: boolean;
};

export const useGroupMembersInfinite = (
    group_id: number,
    queryOptions: TGet_group_membersQuery,
    options: UseGroupMembersOptions,
) => {
    const { isEnabled } = options;

    return createUseInfiniteQueryHook('get_group_members', {
        cacheTime: Infinity,
        enabled: isEnabled,
    })(group_id, queryOptions);
};
