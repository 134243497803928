import { FC, ReactElement } from 'react';
import { withBemMod } from '@bem-react/core';
import { Select } from '@yandex-int/hr-components/Select/desktop/bundle';

import { cn } from '../FormField.cn';
import { FormFieldProps as BaseProps } from '../FormField.types';

import { WithTypeSelectProps } from './WithTypeSelectProps';

export const withTypeSelect = withBemMod<WithTypeSelectProps, BaseProps>(
    cn(),
    { type: 'select' },
    (Base): FC<BaseProps & WithTypeSelectProps> => (props): ReactElement => {
        const {
            placeholder,
            onChange,
            onBlur,
            value,
            options,
            state,
            disabled,
            name,
        } = props;
        const appendAfter = (
            <Select
                checkable
                className={cn('Control', { state, name })}
                disabled={disabled}
                iconProps={{ glyph: 'arrowShortDown' }}
                options={options || []}
                placeholder={placeholder}
                size="m"
                value={value}
                view="secondary"
                width="max"
                onBlur={onBlur}
                onChange={onChange}
            />
        );

        return (
            <Base
                { ...props }
                appendAfter={appendAfter}
            />
        );
    },
);
