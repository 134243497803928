import { FC } from 'react';

import { AppLink } from 'components/AppLink';
import { IconPlus } from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { RouteTripCreate } from 'routes/RouteTripCreate';
import { Button } from 'shared/ui/Button';

import { cn } from '../PageMyActiveTrips.cn';
import { i18n } from '../PageMyActiveTrips.i18n';

import { CreateTripProps } from './CreateTrip.types';

import './CreateTrip.css';

export const CreateTrip: FC<CreateTripProps> = () => {
    const { isMobile } = usePlatform();

    if (isMobile) {
        return (
            <AppLink href={RouteTripCreate.getPath({}, {})}>
                <Button
                    className={cn('CreateTrip')}
                    size="l"
                    type="submit"
                    view="brand"
                >
                    {i18n('create_trip')}
                </Button>
            </AppLink>
        );
    }

    return (
        <AppLink className={cn('CreateTripLink')} href={RouteTripCreate.getPath({}, {})}>
            <div className={cn('CreateTrip')}>
                <div className={cn('CreateTripPlus')}>
                    <IconPlus size="l" />
                </div>
                <h3 className={cn('CreateTripHeader')}>
                    {i18n('create_trip')}
                </h3>
            </div>
        </AppLink>
    );
};
