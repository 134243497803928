import { FC, useCallback } from 'react';
import { Link } from '@yandex-int/hr-components/Link/desktop/bundle';

import { IconChat } from 'components/Icon';
import { useMeta } from 'hooks/useMeta';
import { openURLInBrowser } from 'platform/bridge-methods';
import { isIOS } from 'platform/utils';
import { Text } from 'shared/ui/Text';
import { getMessengerUrl } from 'utils/urls';

import { cn } from './SupportChatLink.cn';
import { i18n } from './SupportChatLink.i18n';
import { SupportChatLinkProps } from './SupportChatLink.types';

import './SupportChatLink.css';

export const SupportChatLink: FC<SupportChatLinkProps> = props => {
    const {
        className,
        theme = 'link',
        size = 'full',
        firstLetterUppercase = false,
        hasIcon = false,
        text,
    } = props;

    const { user: { chat_id } } = useMeta();

    const chatUrl = getMessengerUrl(chat_id);

    const withText = size === 'full';

    const defaultText = firstLetterUppercase ? i18n('support_chat_first_letter_uppercase') : i18n('support_chat');

    const onClick = useCallback(() => {
        openURLInBrowser(chatUrl, !isIOS());
    }, [chatUrl]);

    return (
        <Link
            className={cn(null, [className])}
            theme={theme}
            onClick={onClick}
        >
            <Text color="primary">
                {hasIcon && <IconChat className={cn('ChatIcon', { withText })} size="l" />}
            </Text>
            {withText && (text ? text : defaultText)}
        </Link>
    );
};

SupportChatLink.displayName = cn();
