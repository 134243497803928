import { IClassNameProps } from '@bem-react/core';

import { Person, PersonTrip } from 'services/SwaggerApi';

export type ExecuteTripButtonProps = IClassNameProps & {
    personTrip: PersonTrip;
    personId: Person['person_id'];
    tripId: number;
    canExecute: boolean;
};

export type WorkingHolidaysFormValues = {
    reason: string;
    dates?: string[];
};

export enum TripHolidaysScenario {
    NO_HOLIDAYS = 'noHolidays',
    NOT_WORKING_ON_HOLIDAYS = 'notWorkingOnHolidays',
    WORKING_ON_HOLIDAYS = 'workingOnHolidays',
    REASON_ALREADY_SPECIFIED = 'reasonAlreadySpecified'
}
