import { IFilterOption } from 'api/models/search/filter';

import { IFilterOptionAirport } from './interface';

export function parseAirportOption(option: IFilterOption): IFilterOptionAirport {
    // в названиях аэропортов нет запятых по словам Вадима Кошелева (Аэроклуб)
    // после BTRIP-213 эта функция будет не нужна
    const airportCodeAviaCenter = option.target_id;
    const [airportCity, airportName, airportCode = airportCodeAviaCenter] = option.caption.split(',').map(item => item.trim());

    return {
        ...option,
        airportCity,
        airportName,
        airportCode,
    };
}
