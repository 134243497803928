import { FC } from 'react';

import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { ErrorState } from 'shared/ui/ErrorState';

import { cn } from '../LoyaltyCards.cn';
import { i18n } from '../LoyaltyCards.i18n';

import { EmptyStateProps } from './EmptyState.types';

import './EmptyState.css';

export const EmptyState: FC<EmptyStateProps> = props => {
    const {
        handleAddClick,
        className,
    } = props;

    return (
        <div className={cn('EmptyState', [className])}>
            <ErrorState
                actions={[(
                    <Button
                        key="create"
                        className={cn('AddButton')}
                        size="m"
                        view="primary"
                        onClick={handleAddClick}
                    >
                        {i18nAction('add')}
                    </Button>
                )]}
                description={i18n('empty_description')}
                size="s"
                title={i18n('empty_title')}
            />
        </div>
    );
};
