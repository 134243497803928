import { FC } from 'react';

import { StartrekIssue } from 'components/StartrekIssue';

import { cn } from './TripTickets.cn';
import { i18n } from './TripTickets.i18n';
import { TripTicketsProps } from './TripTickets.types';

import './TripTickets.css';

export const TripTickets: FC<TripTicketsProps> = props => {
    const { trip, personTrip, size = 's' } = props;

    let issueTravel: string | null | undefined;
    let issueConf: string | null | undefined;

    if (personTrip) {
        issueTravel = personTrip.travel_details?.tracker_issue;
        issueConf = personTrip.conf_details?.tracker_issue;
    } else if (trip) {
        issueTravel = trip.issue_travel;
        issueConf = trip.conf_details?.tracker_issue;
    }

    return (
        <div className={cn(null, [props.className])}>
            {!issueTravel && !issueConf && i18n('no_issues')}

            {issueTravel && <StartrekIssue className={cn('Ticket')} issue={issueTravel} size={size} />}
            {issueConf && <StartrekIssue className={cn('Ticket')} issue={issueConf} size={size} />}
        </div>
    );
};

TripTickets.displayName = cn();
