export const ru = {
    settings: 'Общие настройки',
    users_in_group: 'Участники группы',
    users_conditions: 'Условия этой группы применяются на всех добавленных пользователей. Каждый пользователь может состоять только в одной группе',
    group_name: 'Название группы',
    group_name_placeholder: 'Укажите название',
    travel_policy: 'Тревел-политика',
    travel_policy_placeholder: 'Выбрать...',
    travel_policy_not_selected: 'Не выбрана',
    save_caption: 'Новая группа пользователей будет добавлена в компанию',
    save_changes_caption: 'Изменения будут действовать только в новых заявках',
    add_user: 'Добавить участника',
    add_users: 'Добавить пользователей',
    go_to_profile: 'Перейти в профиль',
    remove_from_group: 'Удалить из группы',
    load_more: 'Загрузить ещё',
    error_title: 'В данной группе нет пользователей',
    error_description: 'Тревел-политика данной группы будет действовать на всех добавленных в группу пользователей',
    confirmation_title: 'Подтверждение',
    confirmation_message: 'Вы точно хотите удалить пользователя из данной группы?',
};
