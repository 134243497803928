import { FC, useMemo } from 'react';

import { Bullet } from 'components/Bullet';
import { IconArrowShortRight } from 'components/Icon';
import { ServiceCostDetails } from 'components/ServiceCostDetails';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { DateFormat, formatDate } from 'utils/formatDate';

import { cn } from '../HotelServiceCard.cn';
import { i18n } from '../HotelServiceCard.i18n';
import { HotelServiceCardProps } from '../HotelServiceCard.types';

export const ReservationDetails: FC<HotelServiceCardProps> = props => {
    const { serviceDetails } = props;

    const {
        room,
        start_at,
        end_at,
        price,
    } = serviceDetails;

    const tariffTotal =
        Number(price.service_part) +
        Number(price.additional_products_part ?? 0) +
        Number(price.ext_persons_part ?? 0);

    const triggerElement = useMemo(() =>
        <>
            {i18n('guests_count', { count: (serviceDetails.ext_persons_count || 0) + 1 })}
            <IconArrowShortRight className={cn('Icon')} data-testid="cost_details" />
        </>
    , [serviceDetails.ext_persons_count]);

    return (
        <div className={cn('ReservationDetails')}>
            <div className={cn('RoomInfo')}>
                <Text
                    as="span"
                    color="primary"
                    typography="body-s"
                >
                    <ServiceCostDetails
                        desktopTrigger="hover"
                        price={price}
                        triggerElement={triggerElement}
                    />
                </Text>
                <Text typography="body-s">
                    {room.room_name}
                    {room.room_category && (
                        <>
                            <Bullet />
                            {room.room_category}
                        </>
                    )}
                </Text>
                <Text typography="body-l">
                    {formatCurrency(tariffTotal)}
                </Text>
            </div>
            <Text className={cn('ReservationTimes')} typography="body-s">
                <div className={cn('ReservationTime')}>
                    <span>{i18n('checkin_from')}</span>
                    <Text typography="body-l">
                        {formatDate(start_at, DateFormat.HH_mm)}
                    </Text>
                </div>
                <div className={cn('ReservationTime')}>
                    <span>{i18n('checkout_before')}</span>
                    <Text typography="body-l">
                        {formatDate(end_at, DateFormat.HH_mm)}
                    </Text>
                </div>

            </Text>
        </div>
    );
};
