import { FC, useContext } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { cn } from './FormField2.cn';
import {
    FormFieldComponentProps,
    FormFieldProps,
} from './FormField2.types';
import {
    checkIsFieldRequired,
    ErrorMessage,
    FieldLabel,
    SchemaContext,
} from './utils';

import './FormField2.css';

export function withFormField<TField extends FormFieldProps>(
    WrappedComponent: FC<TField>,
) {
    const FormFieldComponent: FC<FormFieldComponentProps<TField>> = props => {
        const {
            label,
            rules,
            className,
            errorClassName,
            errorTestId,
            hideErrors = false,
            ...restProps
        } = props;

        const { name } = restProps;
        const fieldProps = restProps as TField;

        const context = useFormContext();
        const schema = useContext(SchemaContext);

        const { control, getValues } = context;

        const controller = useController({
            name,
            control,
            rules,
        });

        const isFieldRequired = checkIsFieldRequired(schema, name, getValues());

        return (
            <div
                className={cn(null, [className])}
                data-testid={`field-${name}`}
            >
                {label && (
                    <FieldLabel
                        htmlFor={name}
                        isRequired={isFieldRequired}
                        label={label}
                    />
                )}
                <WrappedComponent {...fieldProps} context={context} controller={controller} />
                {!hideErrors && (
                    <ErrorMessage
                        className={errorClassName}
                        errorTestId={errorTestId || `field-${name}-error`}
                        fieldName={name}
                    />
                )}
            </div>
        );
    };

    return FormFieldComponent;
}

withFormField.displayName = cn();
