import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FieldAttach, FormProvider } from 'components/FormField2';
import { withModalView } from 'hocs/withModalView';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { addToast } from 'utils/addToast';
import { isLoading } from 'utils/Loadable';

import { useRegistryCheck } from './CollateRegistryModal.hooks/useRegistryCheck';
import { cn } from './CollateRegistryModal.cn';
import { i18n } from './CollateRegistryModal.i18n';
import { EXTENSIONS, schema } from './CollateRegistryModal.schema';
import { CollateRegistryFields, CollateRegistryModalProps } from './CollateRegistryModal.types';

import './CollateRegistryModal.css';

const ACCEPTED_EXTENSIONS = EXTENSIONS.join(',');

const CollateRegistryModalPresenter: FC<CollateRegistryModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        className,
    } = props;

    const [checkRegistry, checkState, { error }] = useRegistryCheck({
        onSuccess: () => {
            onSubmit();
            addToast({ title: i18n('sent_to_check'), message: i18n('check_info') });
        },
        options: {
            hideValidationErrors: true,
        },
    });
    const isFileSending = isLoading(checkState);

    const methods = useForm<CollateRegistryFields>({
        resolver: yupResolver(schema),
    });

    const handleSubmit = (data: CollateRegistryFields) => {
        checkRegistry(data);
    };

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <FormProvider error={error} methods={methods} schema={schema}>
                <form
                    className={cn('Form')}
                    onSubmit={methods.handleSubmit(handleSubmit)}
                >
                    <div className={cn('Fieldset')}>
                        <Text
                            as="p"
                            className={cn('Description')}
                            typography="body-s"
                        >
                            {i18n('file_upload_info')}
                        </Text>
                        <FieldAttach
                            hasHolder
                            accept={ACCEPTED_EXTENSIONS}
                            className={cn('Attach')}
                            holderText={i18n('no_file_selected')}
                            name="registry_file"
                        >
                            {i18n('select_file')}
                        </FieldAttach>
                    </div>
                    <div className={cn('Controls')}>
                        <Button
                            className={cn('Cancel')}
                            size="m"
                            view="ghost"
                            onClick={onCancel}
                        >
                            {i18nAction('cancel')}
                        </Button>
                        <Button
                            className={cn('Submit')}
                            progress={isFileSending}
                            size="m"
                            type="submit"
                            view="primary"
                        >
                            {i18nAction('save')}
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

CollateRegistryModalPresenter.displayName = cn();

export const CollateRegistryModal = withModalView(CollateRegistryModalPresenter);
