export const ru = {
    add: 'Добавить',
    apply: 'Применить',
    back: 'Назад',
    cancel: 'Отмена',
    change_seat: 'Изменить место',
    confirm: 'Подтвердить',
    continue: 'Продолжить',
    download_attachments: 'Скачать документы',
    execute: 'Оформить все услуги',
    export: 'Экспортировать',
    reset: 'Сбросить',
    remove: 'Удалить',
    remove_service: 'Удалить услугу',
    retry: 'Повторить',
    save: 'Сохранить',
    save_changes: 'Сохранить изменения',
    select: 'Выбрать',
    send: 'Отправить',
    copied: 'Скопировано',
    open_details: 'Просмотр данных',
    close: 'Закрыть',
    yes: 'Да',
    no: 'Нет',
    yes_delete: 'Да, удалить',
    yes_replace: 'Да, заменить',
    no_leave: 'Нет, оставить',
    refresh_page: 'Обновить страницу',
    chat_with_support: 'Написать в поддержку',
    to_the_main_page: 'Вернуться на главную',
    yes_recall: 'Да, отозвать',
    recall: 'Отозвать',
};
