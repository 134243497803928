import { FC } from 'react';

import { Card } from 'components/Card';
import { ExecuteTripButton } from 'components/ExecuteTripButton';
import { Text } from 'shared/ui/Text';

import { cn } from './ExecuteTrip.cn';
import { i18n } from './ExecuteTrip.i18n';
import { ExecuteTripProps } from './ExecuteTrip.types';

import './ExecuteTrip.css';

export const ExecuteTrip: FC<ExecuteTripProps> = props => {
    const { className, personId, personTrip, tripId, canExecute } = props;

    const title = canExecute ? i18n('can_execute_title') : i18n('add_services_title');
    const description = canExecute ? i18n('can_execute_description') : i18n('add_services_description');

    return (
        <Card className={cn(null, [className])}>
            <Text typography="title-s">
                {title}
            </Text>

            <Text
                className={cn('Description')}
                color="secondary"
                typography="body-s"
            >
                {description}
            </Text>

            <ExecuteTripButton
                canExecute={canExecute}
                personId={personId}
                personTrip={personTrip}
                tripId={tripId}
            />
        </Card>
    );
};

ExecuteTrip.displayName = cn();
