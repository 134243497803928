import { FC } from 'react';

import { HotelStars } from 'components/HotelStars';
import { formatCurrency } from 'utils/formatCurrency';

import { cn } from '../GeoMap.cn';
import { i18n } from '../GeoMap.i18n';
import { HotelMark } from '../GeoMap.types';

export const GeoMarkHotel: FC<{ mark: HotelMark }> = ({ mark }) => (
    <div className={cn('Hotel')}>
        <div className={cn('HotelTitle')} data-hotel-id={mark.id} data-hotel-name={mark.title}>
            {mark.title}
        </div>
        <HotelStars className={cn('HotelStars')} count={mark.stars} />
        <div className={cn('HotelSecondLine')}>
            <span className={cn('HotelPrice')}>
                {i18n('from')} {formatCurrency(mark.price)} {i18n('per_night')}
            </span>
        </div>
    </div>
);
