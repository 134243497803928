import { FC } from 'react';

import { Text } from 'shared/ui/Text';

import { getItemColor } from './OptionInfoItem.utils/getItemColor';
import { getItemIcon } from './OptionInfoItem.utils/getItemIcon';
import { cn } from './OptionInfoItem.cn';
import { OptionInfoItemProps } from './OptionInfoItem.types';

import './OptionInfoItem.css';

export const OptionInfoItem: FC<OptionInfoItemProps> = props => {
    const {
        defaultColor = 'primary',
        successText,
        failureText,
        successIcon,
        failureIcon,
        hasSuccessColor,
        hasFailureColor,
        condition,
        hasColorfulText = false,
    } = props;

    const color = getItemColor(condition, hasSuccessColor, hasFailureColor);
    const Icon = getItemIcon(condition, successIcon, failureIcon);
    const text = condition ? successText : failureText;
    const isIconColorGray = !color;
    const textColor = hasColorfulText ? color : defaultColor;

    return (
        <Text
            as="div"
            className={cn({ color })}
            color={defaultColor}
            typography="body-s"
        >
            {Icon && (
                <Icon
                    className={cn('Icon', { color_secondary: isIconColorGray })}
                    size="m"
                />
            )}
            <Text
                as="div"
                className={cn({ color: textColor })}
                color={defaultColor}

            >
                {text}
            </Text>
        </Text>
    );
};
