import { useMemo } from 'react';
import { isLeft } from 'fp-ts/lib/Either';
import * as z from 'zod';

import { config } from 'config';
import { useParamsPathname } from 'hooks/useParamsPathname';
import { useQueryParams } from 'hooks/useQueryParams';
import { i18nErrors } from 'i18n/i18nErrors';
import { Route, RouteSearchParams } from 'types/Route';
import { RoutePathParams } from 'types/RoutePathParams';

export function usePage<P extends RoutePathParams, S extends RouteSearchParams>(Route: Route<P, S>) {
    const { path, exact, decoder } = Route;

    const untypedParams = useParamsPathname(path, exact);
    const untypedSearch = useQueryParams();

    return useMemo(() => {
        if (decoder instanceof z.ZodType) {
            try {
                return decoder.parse({
                    params: untypedParams,
                    search: untypedSearch,
                });
            } catch (e) {
                if (config.env !== 'production') {
                    console.warn(e);
                    throw new Error(i18nErrors('error_in_page_params'));
                }

                return {
                    params: untypedParams as unknown as P,
                    search: untypedSearch as unknown as S,
                };
            }
        }

        const decoded = decoder.decode({
            params: untypedParams,
            search: untypedSearch,
        });

        if (isLeft(decoded)) {
            // TODO правильно обработать ошибку валидации https://st.yandex-team.ru/BTRIP-687
            if (config.env !== 'production') {
                console.warn(decoded.left);
                throw new Error(i18nErrors('error_in_page_params'));
            }

            return {
                params: untypedParams as unknown as P,
                search: untypedSearch as unknown as S,
            };
        }

        return decoded.right;
    }, [decoder, untypedParams, untypedSearch]);
}
