import { FC } from 'react';
import { IClassNameProps } from '@bem-react/core';

import {
    getIconProvider,
    IconArrowShortDown,
    IconArrowShortUp,
} from 'components/Icon';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import { i18n } from '../FlightServiceCard.i18n';

type CollapseButtonProps = IClassNameProps & {
    isCollapsed: boolean;
    onButtonClick: () => void;
};

const iconProviderArrowDown = getIconProvider(IconArrowShortDown);
const iconProviderArrowUp = getIconProvider(IconArrowShortUp);

export const CollapseButton: FC<CollapseButtonProps> = props => {
    const {
        onButtonClick,
        isCollapsed,
        className,
    } = props;

    const buttonIcon = isCollapsed ? iconProviderArrowDown : iconProviderArrowUp;

    return (
        <Button
            className={className}
            iconRight={buttonIcon}
            size="s"
            view="clear"
            onClick={onButtonClick}
        >
            <Text color="secondary" typography="body-s" weight="medium">
                {i18n('details')}
            </Text>
        </Button>
    );
};
