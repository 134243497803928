import { FC, useRef } from 'react';
import { useToggle } from 'react-use';

import { Card } from 'components/Card';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { ModalPlatform } from 'shared/ui/ModalPlatform';
import { Text } from 'shared/ui/Text';

import { cn } from '../HotelDetails.cn';
import { i18n } from '../HotelDetails.i18n';
import { useShowMoreButtton } from '../useShowMoreButton';

import { DescriptionProps } from './Description.types';

import './Description.css';

export const Description: FC<DescriptionProps> = props => {
    const {
        className,
        description,
        hotelName,
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    const [showModal, toggleShowModal] = useToggle(false);

    const { showButton } = useShowMoreButtton(ref);

    const { isMobile } = usePlatform();

    return (
        <div className={cn('Section', [className])}>
            <Text className={cn('Title')} color="secondary" typography={isMobile ? 'title-s' : 'title-m'}>
                {i18n('description')}
            </Text>
            <Card
                className={cn('TextWrapperCard')}
                title={hotelName}
            >
                <div ref={ref} className={cn('TextWrapper')}>
                    <Text
                        as="div"
                        className={cn('DescriptionText')}
                        typography="body-m"
                    >
                        {description}
                    </Text>
                </div>
                {showButton && (
                    <Button
                        className={cn('ShowMoreButton')}
                        size="m"
                        view="outline"
                        width="auto"
                        onClick={toggleShowModal}
                    >
                        {i18n('show_all')}
                    </Button>
                )}
            </Card>
            <ModalPlatform
                title={hotelName}
                toggleVisible={toggleShowModal}
                visible={showModal}
            >
                <Text
                    as="div"
                    className={cn('DescriptionText')}
                    typography="body-m"
                >
                    {description}
                </Text>
            </ModalPlatform>
        </div>
    );
};
