import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { getIconProvider, IconArrowShortLeft } from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import { cn } from './TitlePage.cn';
import { TitlePageProps } from './TitlePage.types';

import './TitlePage.css';

const iconLeft = getIconProvider(IconArrowShortLeft);

export const TitlePage: FC<TitlePageProps> = props => {
    const {
        retButton,
        actions,
        title,
        titleSecondary,
        className,
    } = props;

    const history = useHistory();
    const hasHistory = history.length > 1;
    const showBackButton = hasHistory && retButton;

    const titleElement = typeof title === 'string' ? (
        <Text as="h1" typography="header">
            {title}
            {titleSecondary && <>{' '}<Text color="disable">{titleSecondary}</Text></>}
        </Text>
    ) : title;
    const actionsElement = actions ? <div className={cn('Actions')}>{actions}</div> : actions;

    const { isMobile } = usePlatform();

    if (!title && !titleSecondary && !actions) {
        return null;
    }

    return (
        <div className={cn({ actions: Boolean(actions) }, [className])}>
            <div className={cn('ButtonWithTitle', { showBackButton })}>
                {showBackButton && (
                    <Button
                        icon={iconLeft}
                        size={isMobile ? 's' : 'm'}
                        view="outline"
                        onClick={history.goBack}
                    />
                )}
                {titleElement}
            </div>
            {actionsElement}
        </div>
    );
};

TitlePage.displayName = cn();
