import { useMemo } from 'react';
import { Column } from 'react-table';

import { AppLink } from 'components/AppLink';
import { CellActions } from 'components/TableCells/CellActions';
import { CellActionsHeader } from 'components/TableCells/CellActionsHeader';
import { CellEmployeeEmail } from 'components/TableCells/CellEmployeeEmail';
import { CellHeader } from 'components/TableCells/CellHeader';
import { CellPerson } from 'components/TableCells/CellPerson';
import { CellStatus } from 'components/TableCells/CellStatus';
import { CellText } from 'components/TableCells/CellText';
import { AssistantTag } from 'components/TableEmployees/AssistantTag';
import { RouteProfile } from 'routes/RouteProfile';
import { Employee } from 'services/SwaggerApi';
import { TooltipStateful } from 'shared/ui/TooltipStateful';

import { i18n } from '../../TableEmployees.i18n';
import { ActionsCallbacks } from '../../TableEmployees.types';
import { getStatusByRole } from '../../TableEmployees.utils/getStatusByRole';
import { useMenuOptions } from '../useMenuOptions';

import { StatusToColor } from './useColumns.types';

const statusToColor: StatusToColor = {
    activated: 'default',
    blocked: 'red',
    wait_activation: 'blue',
    rejected: 'red',
};

export const useColumns = (actionsCallbacks: ActionsCallbacks): Column<Employee>[] => {
    return useMemo(() => [
        {
            id: 'name',
            accessor: ({ first_name, last_name }) => `${first_name} ${last_name}`,
            Header: props => <CellHeader {...props} value={i18n('name_header')} />,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Cell: (props: any) => {
                const {
                    value,
                    row: { original: employee },
                } = props;

                return <CellText
                    {...props}
                    value={(
                        <TooltipStateful content={i18n('hint_goto_profile')} direction="bottom">
                            <AppLink href={RouteProfile.getPath({ personId: employee.employee_id }, {})}>
                                {value}
                            </AppLink>
                        </TooltipStateful>
                    )}
                />;
            },
            width: 200,
            sticky: 'left',
        },
        {
            id: 'email',
            accessor: ({ employee_id, company_id, email }) => ({
                employeeId: employee_id,
                companyId: company_id,
                emailMasked: email,
            }),
            Header: props => <CellHeader {...props} value={i18n('email_header')} />,
            Cell: CellEmployeeEmail,
            width: 240,
        },
        {
            id: 'approver',
            accessor: ({ approver }) => {
                if (!approver) return null;

                return {
                    firstName: approver.first_name,
                    lastName: approver.last_name,
                    login: '-',
                    personId: approver.approver_id,
                };
            },
            Header: props => <CellHeader {...props} value={i18n('approver_header')} />,
            Cell: CellPerson,
            width: 260,
        },
        {
            id: 'role',
            accessor: ({ is_coordinator, is_assistant }) => {
                if (is_coordinator) {
                    return getStatusByRole('coordinator');
                }

                if (is_assistant) {
                    return getStatusByRole('assistant');
                }

                return getStatusByRole('user');
            },
            Header: props => <CellHeader {...props} value={i18n('role_header')} />,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Cell: (props: any) => {
                const {
                    row: { original: employee },
                } = props;

                let dropDownContent;

                if (employee.is_assistant) {
                    dropDownContent = (
                        <AssistantTag employee={employee} />
                    );
                }

                return (
                    <CellStatus
                        {...props}
                        dropDownContent={dropDownContent}
                    />
                );
            },
        },
        {
            id: 'status',
            accessor: ({ status }) => {
                return {
                    text: i18n(`status_value_${status}`),
                    color: statusToColor[status],
                };
            },
            Header: props => <CellHeader {...props} value={i18n('status_header')} />,
            Cell: CellStatus,
        },
        {
            accessor: 'actions',
            Header: CellActionsHeader,
            Cell: props => {
                const {
                    value: actions,
                    row: { original: employee },
                } = props;

                const menuOptions = useMenuOptions(actions, actionsCallbacks, employee);

                return <CellActions
                    {...props}
                    menuOptions={menuOptions}
                />;
            },
            width: 48,
            sticky: 'right',
        },
    ], [actionsCallbacks]);
};
