import { withBemMod } from '@bem-react/core';

import { withRemote } from 'hocs/withRemote';
import { useGetPersonAvatarId } from 'hooks/useGetPersonAvatarId';
import { Text } from 'shared/ui/Text';
import { AvatarLink } from 'types/AvatarLink';

import { cn } from '../Bubble.cn';
import { BubbleProps } from '../Bubble.interface';
import {
    getBubbleColor,
    getBubbleThumbnail,
    getTypo,
} from '../Bubble.utils';

import { WithTypePerson } from './Bubble_type_person.yt';

import './Bubble_type_person.b2b.css';

const getAvatarUrl = (id: string): string => `https://avatars.mdst.yandex.net/get-yapic/${id}/islands-200`;

type BaseBubbleProps = {
    data:
    AvatarLink;
};

const stubAvatarId = '0/0-0';

export const withTypePerson = withBemMod<WithTypePerson, BubbleProps>(
    cn(),
    { type: 'person' },
    Bubble => (props: WithTypePerson & BubbleProps) => {
        const { name } = props.bubble;
        const {
            size,
        } = props;
        const thumbnail = getBubbleThumbnail(name);
        const color = getBubbleColor(name);

        const BaseBubble = (values: BaseBubbleProps) => {
            const { avatar_id } = values.data;
            const hasAvatar = avatar_id !== stubAvatarId;
            const imageSrc = getAvatarUrl(avatar_id ?? '');

            return (
                <Bubble bubbleColor={hasAvatar ? '#ffffff' : color} {...props}>
                    {hasAvatar ?
                        <img
                            alt="avatar"
                            className={cn('Avatar')}
                            src={imageSrc}
                        /> :
                        <Text typography={getTypo(size)} weight="medium">
                            {thumbnail}
                        </Text>
                    }
                </Bubble>);
        };

        const BubbleWithRemote = withRemote(BaseBubble);

        const avatarData = useGetPersonAvatarId(props.bubble?.personId || '') as AvatarLink;

        return <BubbleWithRemote
            data={avatarData}
            fallbackElement={
                <Bubble bubbleColor={color} {...props}>
                    <Text typography={getTypo(size)} weight="medium">
                        {thumbnail}
                    </Text>
                </Bubble>}
        />;
    },
);
