import { ACTranslatedEntry } from 'services/AeroClubApi/ACTranslatedEntry';
import { transliterate } from 'shared/lib/text/transliterate';
import { getTypedI18nLang } from 'utils/i18n';

type TranslationOptions = {
    defaultTranslation?: string;
    fallbackToTranslit?: boolean;
};

export function getTranslation(entry: Partial<ACTranslatedEntry> | undefined | null, options: TranslationOptions = {}) {
    const { defaultTranslation = '', fallbackToTranslit = false } = options;

    if (!entry) return defaultTranslation;

    const lang = getTypedI18nLang();
    let translation = entry[lang] || '';

    if (fallbackToTranslit && !translation) {
        translation = transliterate(entry.ru || '');
    }

    return translation || defaultTranslation;
}
