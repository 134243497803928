import { FC, Fragment } from 'react';

import { i18nCarriageType } from 'i18n/i18nCarriageType';
import { TrainCarriage } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';

import { cn } from '../TrainSearchCard.cn';

type CarriagePricesProps = {
    carriages: TrainCarriage[];
};

export const CarriagePrices: FC<CarriagePricesProps> = props => {
    const { carriages } = props;

    return (
        <Text as="div" className={cn('CarriagePrices')} typography="body-s">
            {carriages.map(({ carriage_type, min_price, place_count }) => (
                <Fragment key={carriage_type}>
                    <Text>
                        {i18nCarriageType(carriage_type)}
                    </Text>
                    <Text align="end">
                        {place_count}
                    </Text>
                    <Text align="end">
                        {formatCurrency(min_price)}
                    </Text>
                </Fragment>
            ))}
        </Text>
    );
};
