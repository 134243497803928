import { FC, Suspense, useContext } from 'react';
import { useQueryClient } from 'react-query';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Language, setI18nLang } from '@yandex-int/i18n';
import dayjs from 'dayjs';
import { useGate } from 'effector-react';

import { Butterfly } from 'components/Butterfly';
import { Register } from 'components/Register';
import { withAccessControl } from 'hocs/withAccessControl';
import { withProps } from 'hocs/withProps';
import { isUsersResponse, useAuthMeta } from 'hooks/useAuthMeta';
import { useCSRFRequest } from 'hooks/useCSRFToken';
import { useNativeUrlHandler } from 'platform/urlHandlers';
import { isIOS } from 'platform/utils';
import { routes } from 'routes';
import { RootGate } from 'shared/models/gates';
import { ChatLink } from 'shared/ui/ChatLink';
import { isFailure, isPending } from 'utils/Loadable';
import { getPassportPorgUrl } from 'utils/urls';

import { SwaggerContext } from '../../SwaggerContext';

import { prefetchQueries } from './Root.utils/prefetchQueries';
import { Error } from './Error';
import { Loading } from './Loading';
import { NoAccess } from './NoAccess';
import { NoPersonalDataConsent } from './NoPersonalDataConsent';
import { Page as RootPage } from './Page';

import 'react-toastify/dist/ReactToastify.css';
import './Root.css';

export const Root: FC = () => {
    useNativeUrlHandler();

    const meta = useAuthMeta();
    const csrfToken = useCSRFRequest();
    const queryClient = useQueryClient();
    const { api } = useContext(SwaggerContext);

    useGate(RootGate);
    // пока нет данных о пользователе, будет прыгать шапка и даже строка поиска
    // данные кешируются, не обновляются, загружаются один раз при старте приложения
    if (isPending(meta) || isPending(csrfToken)) return null;

    if (isFailure(csrfToken)) {
        switch (csrfToken.error.status) {
            case 401:
                return null;

            default:
                return (<Error status={csrfToken.error.status} />);
        }
    } else {
        window.CSRF_TOKEN = csrfToken.result.csrf_token;
    }

    if (isFailure(meta)) {
        switch (meta.error.status) {
            case 401:
                return null;

            case 403:
                return (<Register />);

            case 404:
                window.location.assign(getPassportPorgUrl(window.location.href));

                return null;

            default:
                return (<Error status={meta.error.status} />);
        }
    }

    if (isUsersResponse(meta.result) && !meta.result.user.has_access) {
        return (<NoAccess />);
    }

    if (isUsersResponse(meta.result) && meta.result.user.consent.status === 'need_to_sign') {
        return (<NoPersonalDataConsent />);
    }

    let showFeedbackForms = false;

    // meta всегда будет UserResponse
    if (isUsersResponse(meta.result)) {
        setI18nLang(meta.result.user.language as Language);
        dayjs.locale(meta.result.user.language);

        showFeedbackForms = Boolean(meta.result.user.is_yandex_employee) && !isIOS();

        const personId = meta.result.user.person_id;

        if (isIOS()) {
            prefetchQueries(queryClient, api, personId);
        }
    }

    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                {/* редиректы для обратной совместимости с мобильным приложением */}
                <Redirect exact from="/documents" to="/profile" />
                <Redirect from="/documents/*" to="/profile/*" />
                {routes.map(({ path, exact, decoder, component: PageComponent, routeOptions }) => (
                    <Route
                        key={path}
                        component={withProps({
                            decoder,
                            component: withAccessControl(PageComponent, routeOptions.checkAccess),
                            path,
                            exact,
                            routeOptions,
                            meta: meta.result,
                        })((RootPage))}
                        exact={exact}
                        path={path}
                    />
                ))}
            </Switch>
            {showFeedbackForms && (
                <Butterfly />
            )}
            {(isUsersResponse(meta.result)) && (
                <ChatLink chatId={meta.result.user.chat_id} />
            )}
        </Suspense>
    );
};
