import { WorkingHolidaysFormValues } from '../ExecuteTripButton.types';

export const getWorkingHolidays = (values: WorkingHolidaysFormValues) => {
    const dates = (values.dates || []).filter(Boolean);
    const reason = values.reason;

    const datesText = dates.filter(Boolean).join(',');

    return [datesText, reason].filter(Boolean).join(': ');
};
