export const ru = {
    title: 'Согласование командировки',
    description: 'Если отклонить заявку, то командировку нужно будет создавать заново',
    comment: 'Комментарий к заявке:',
    no: 'Нет',
    yes: 'Да, отклонить',
    approve: 'Согласовать',
    approve_success: 'Заявка согласована',
    approve_error: 'Не удалось согласовать заявку. Попробуйте ещё раз позже, пожалуйста',
    decline: 'Отклонить заявку',
    decline_confirmation: 'Вы уверены, что хотите отклонить заявку?',
    decline_reason: 'Расскажите, пожалуйста, почему отклоняете заявку',
    decline_success: 'Заявка отменена',
    decline_error: 'Не удалось отклонить заявку. Попробуйте ещё раз позже, пожалуйста',
};
