import { IconTravelPlane, IconTravelTrain } from 'components/Icon';
import { fromLocalDate } from 'utils/date/fromLocalDate';
import { getOrderCity } from 'utils/getOrderCity';

import { i18n } from '../TripRoute.yt.i18n';

import { getAirportWithTerminal } from './getAirportNameTerminal';
import { segmentEvent } from './segmentEvent';
import { switchTransportFn } from './switchTransportFn';
import { TransportEventCreator } from './types';

export const departure: TransportEventCreator = service => {
    const date = fromLocalDate(service.departure_at);

    const city = getOrderCity(service) || '';

    const icon = switchTransportFn({
        rail: () => IconTravelTrain,
        avia: () => IconTravelPlane,
    })(service);

    const text = switchTransportFn({
        rail: order => {
            const station = order.train.departure?.train_station?.name;

            return i18n('train_departure', { city, station });
        },
        avia: order => {
            const aviaLocation = order.flight.legs?.[0].segments[0]?.departure;
            const airport_w_terminal = getAirportWithTerminal(aviaLocation);

            return i18n('flight_departure', { city, airport_w_terminal });
        },
    })(service);

    return segmentEvent({
        date,
        text,
        city,
        icon,
    });
};
