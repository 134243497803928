export const ru = {
    are_you_ready_to_pay: 'В этом случае вам придется оплатить превышение стоимости, за операцию будет взиматься сервисный сбор агентства. Подробнее про доплату можно узнать в чате заявки.',
    b2b_not_complaint_warning: 'Эта услуга превышает тревел политику вашей компании',
    b2b_not_complaint_tooltip: 'Эта услуга превышает тревел политику вашей компании (правила бронирования услуг, лимиты их стоимости), вы можете добавить ее в корзину и отправить на оформление. Однако, выписка данной услуги произойдет только после согласования координатора',
    compliant: 'Соответствует тревел-политике',
    no_info: 'Нет информации о соответствии тревел-политике компании',
    not_compliant: 'Не соответствует тревел-политике компании',
    not_compliant_short: 'Не соответствует тревел-политике',
    add_service: 'Добавить',
    add_not_compliant_service: 'Добавить услугу вне тревел-политики?',
    additional_confirm_info: 'Для оформления услуг с нарушением тревел-политики потребуется дополнительное согласование',
};
