import { FC, useCallback } from 'react';
import { ThemeToggler as ThemeTogglerBase } from '@yandex-int/hr-components/ThemeToggler/desktop/bundle';
import { Platform } from '@yandex-int/hr-components/utils';
import { useUnit } from 'effector-react';

import { $theme, changeThemeEvent } from 'shared/models/theme';

import { cn } from './ThemeToggler.cn';
import { ThemeTogglerProps } from './ThemeToggler.types';

import './ThemeToggler.css';

/**
 * Переключалка темы интрефейса
 */
export const ThemeToggler: FC<ThemeTogglerProps> = props => {
    const {
        className,
        platform = Platform.DESKTOP,
    } = props;

    const themeName = useUnit($theme);

    const changeTheme = useUnit(changeThemeEvent);

    const handleClick = useCallback(() => {
        changeTheme(themeName === 'dark' ? 'light' : 'dark');
    }, [changeTheme, themeName]);

    return (
        <ThemeTogglerBase
            className={className}
            currentTheme={themeName}
            platform={platform}
            onToggle={handleClick}
        />
    );
};

ThemeToggler.displayName = cn();
