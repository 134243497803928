import { ComponentProps, FC } from 'react';
import { TableCellProps } from 'react-table';

import { Cell } from 'components/Cell';

type CellProps = ComponentProps<typeof Cell>;
type PropsGetAttrs = Pick<CellProps, 'getAttrs'>;

type CellWithTdAttrsProps<T extends PropsGetAttrs> = T & {
    cell: {
        getCellProps: () => TableCellProps;
    };
};

export const withTdAttrs = <T extends PropsGetAttrs>(Cell: FC<T>) => {
    const CellWithTdAttrs: FC<CellWithTdAttrsProps<T>> = props => {
        const {
            cell,
            ...rest
        } = props;

        // @ts-ignore
        const cellProps: T = rest;

        return (
            <Cell
                getAttrs={cell.getCellProps}
                {...cellProps}
            />
        );
    };

    return CellWithTdAttrs;
};
