export const ru = {
    all_seats_sold_out: 'В выбранном вагоне выкупили все места, необходимо удалить эту услугу и выбрать новую',
    car: 'вагон',
    choose_new_seat: 'Выбранное место уже выкупили, необходимо выбрать новое место',
    class: 'Класс',
    seat: 'место',
    train: 'Поезд',
    paid_by_the_company: 'Оплачивает компания',
    paid_by_you: 'Оплачиваете вы',
};
