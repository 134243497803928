export const ru = {
    add_eilo: 'Добавить ранний заезд или поздний выезд',
    toggle_hide: 'Скрыть',
    toggle_show: 'Показать еще {count}',
    button_get_number: 'Выбрать номер',
    room_booked: 'Номер выбран',
    meal_included: 'Питание включено',
    meal_not_included: 'Без питания',
    nights: {
        one: 'за {count} ночь',
        some: 'за {count} ночи',
        many: 'за {count} ночей',
        none: '',
    },
    best_price: 'Лучшая цена',
    best_price_with_meal: 'Лучшая цена с питанием',
    cancellation_no_info: 'Нет информации о возврате',
    cancellation_without_penalty: 'Бесплатная отмена',
    cancellation_with_penalty: 'Отмена со штрафом',
    cancellation_no: 'Невозвратный тариф',
    to: 'до {date}',
    from_to: 'с {dateFrom} по {dateTo}',
    penalty: 'Штраф за отмену — {penalty}',
    room_on_request: 'Номер под запрос',
    room_on_request_hint: 'Подтверждение проживания не гарантировано, получение ваучера может занять до 24ч',
};
