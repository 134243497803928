import { isValidationError } from 'utils/responseToHTTPError';

import { ServerError, ServerErrorsContextProps } from '../FormProvider.types';

import { getExtraErrors } from './getExtraErrors';
import { getServerErrorMessage } from './getServerErrorMessage';

export const parseServerErrors = (error: ServerError['error']): ServerErrorsContextProps => {
    const errorDetail = error?.detail;
    const validationErrors = getExtraErrors(error);

    if (!isValidationError(errorDetail)) {
        return validationErrors;
    }

    errorDetail.forEach(errorDetail => {
        const errorLocation = String(errorDetail.loc?.[1]);

        validationErrors[errorLocation] = getServerErrorMessage(errorLocation, errorDetail.msg);
    });

    return validationErrors;
};
