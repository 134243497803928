import { withBemMod } from '@bem-react/core';

import { cn } from '../Bubble.cn';

import './Bubble_mark_question.css';

export interface WithMarkQuestionProps {
    mark?: 'question';
}

export const withMarkQuestion = withBemMod<WithMarkQuestionProps>(cn(), {
    mark: 'question',
});
