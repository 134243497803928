export const ru = {
    form_title: 'Данные компании',
    form_subtitle: 'Укажите телефон компании для связи и электронную почту бухгалтерии для отправки финансовых документов',
    phone_number: 'Номер телефона компании',
    email: 'Электронная почта бухгалтерии',
    company_name: 'Название компании',
    inn: 'ИНН',
    kpp: 'КПП',
    legal_address: 'Юридический адрес',
    postal_address: 'Почтовый адрес',
    submit_subtitle: 'Нажимая кнопку "Продолжить", вы принимаете {offerLink} и подтверждаете, что обладаете необходимыми полномочиями на принятие оферты от имени лица, данные которого указаны',
    submit_privacy_subtitle: 'Обращаем внимание, что обработка персональных данных осуществляется Яндексом на условиях, определенных в {privacyPolicyLink}',
    offer: 'оферту сервиса "Яндекс Командировки"',
    privacy_policy: 'Политике конфиденциальности',
};
