export const ru = {
    users: {
        one: '{count} пользователь',
        some: '{count} пользователя',
        many: '{count} пользователей',
        none: '{count} пользователей',
    },
    avia: 'Авиа',
    rail: 'Ж/д',
    hotel: 'Отели',
    price_to: 'до',
    and: 'и',
    stars: {
        one: 'звезда',
        some: 'звезды',
        many: 'звезды',
    },
    without_stars: 'без звезд',
    no_restrictions: 'без ограничений',
};
