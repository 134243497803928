import { FC, useCallback } from 'react';

import { PersonTag } from 'components/PersonTag';
import { withModalView } from 'hocs/withModalView';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { useEmployeeRevokeCoordinator } from './EmployeeRevokeCoordinatorModal.hooks/useEmployeeRevokeCoordinator';
import { cn } from './EmployeeRevokeCoordinatorModal.cn';
import { i18n } from './EmployeeRevokeCoordinatorModal.i18n';
import { EmployeeRevokeCoordinatorModalProps, HandleSubmit } from './EmployeeRevokeCoordinatorModal.types';

import './EmployeeRevokeCoordinatorModal.css';

const EmployeeRevokeCoordinatorModalPresenter: FC<EmployeeRevokeCoordinatorModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        employee,
        className,
    } = props;

    const { company_id, employee_id, first_name, last_name } = employee;

    const [revokeCoordinator, revokeState] = useEmployeeRevokeCoordinator({
        onSuccess: onSubmit,
        options: { invalidateOnSuccess: true },
    });

    const isRevoking = isLoading(revokeState);

    const handleClick = useCallback<HandleSubmit>(() => {
        revokeCoordinator(company_id, employee_id);
    }, [revokeCoordinator, company_id, employee_id]);

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <div className={cn('Info')}>
                <Text
                    className={cn('ActionFor')}
                    typography="body-s"
                >
                    {i18n('revoke_from')}
                </Text>
                <PersonTag
                    login="-"
                    name={`${first_name} ${last_name}`}
                    personId={employee_id}
                    size="m"
                />
            </div>

            <div className={cn('Controls')}>
                <Button
                    className={cn('Cancel')}
                    size="m"
                    view="ghost"
                    onClick={onCancel}
                >
                    {i18nAction('cancel')}
                </Button>
                <Button
                    className={cn('Submit')}
                    data-testid="action-revoke-coordinator"
                    progress={isRevoking}
                    size="m"
                    view="primary"
                    onClick={handleClick}
                >
                    {i18n('action_submit')}
                </Button>
            </div>

        </div>
    );
};

EmployeeRevokeCoordinatorModalPresenter.displayName = cn();

export const EmployeeRevokeCoordinatorModal = withModalView(EmployeeRevokeCoordinatorModalPresenter);
