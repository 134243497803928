import { DateFormat, formatDate } from 'utils/formatDate';

import { i18n } from '../DatePicker.i18n';

export const getConfirmButtonText = (dateFrom: Date | undefined, dateTo?: Date) => {
    if (dateFrom && dateTo) {
        return i18n('select_two', {
            dateFrom: formatDate(dateFrom, DateFormat.D_MMMM),
            dateTo: formatDate(dateTo, DateFormat.D_MMMM),
        });
    }

    if (dateFrom) {
        return i18n('select_one', { date: formatDate(dateFrom, DateFormat.D_MMMM) });
    }

    return i18n('done');
};
