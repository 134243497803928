import { FC } from 'react';
import { TextColorValue } from '@yandex-lego/components/Text';

import { Text } from 'shared/ui/Text';
import { TooltipStateful } from 'shared/ui/TooltipStateful';
import { DateFormat, formatDate } from 'utils/formatDate';

import { cn } from '../FlightRouteTimeline.cn';
import { i18n } from '../FlightRouteTimeline.i18n';

type ArrivalDateProps = {
    isArrivalDifferentDay: boolean;
    arrivalAt: string;
    baseTextColor: TextColorValue;
};

export const ArrivalDate: FC<ArrivalDateProps> = props => {
    const {
        isArrivalDifferentDay,
        arrivalAt,
        baseTextColor,
    } = props;

    const arrivalFormattedDate = formatDate(arrivalAt, DateFormat.D_MMMM_dd);

    if (isArrivalDifferentDay) {
        return (
            <TooltipStateful
                className={cn('Tooltip')}
                content={i18n('arrivalWarning')}
                direction="bottom-end"
                size="m"
                trigger="click"
            >
                <span>
                    <Text
                        align="end"
                        as="span"
                        className={cn('ArrivalText')}
                        color="alert"
                        typography="caption-m"
                    >
                        {arrivalFormattedDate}
                    </Text>
                </span>
            </TooltipStateful>
        );
    }

    return (
        <Text
            align="end"
            as="span"
            color={baseTextColor}
            typography="caption-m"
        >
            {arrivalFormattedDate}
        </Text>
    );
};
