const ruYandexLogo = (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.12744 12.808H10.7988V3.20801H8.36772C5.92283 3.20801 4.63823 4.46499 4.63823 6.31592C4.63823 7.79391 5.34269 8.66412 6.59967 9.56196L4.41722 12.808H6.22672L8.6578 9.1752L7.81521 8.60887C6.79305 7.91822 6.29578 7.37952 6.29578 6.21923C6.29578 5.19707 7.01406 4.50643 8.38154 4.50643H9.12744V12.808Z" fill="white" />
    </svg>
);

const enYandexLogo = (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.21705 8.82647C8.68453 9.85047 8.84035 10.2066 8.84035 11.4366V13.0672H7.17079V10.3179L4.02087 3.46716H5.76279L8.21705 8.82647ZM10.2762 3.46716L8.23374 8.10856H9.93113L11.9791 3.46716H10.2762Z" fill="white" />
    </svg>
);

export const yandexLogo = {
    ru: ruYandexLogo,
    en: enYandexLogo,
};
