import { FC, useMemo } from 'react';

import { HeaderPage } from 'components/HeaderPage';
import { getIconProvider, IconPlus } from 'components/Icon';
import { Page } from 'components/Page';
import { RouteCompanies, Tab } from 'routes/RouteCompanies';
import { RouteTravelPolicyAdd } from 'routes/RouteTravelPolicyAdd';
import { Button } from 'shared/ui/Button';
import { Container } from 'shared/ui/Container';

import { cn } from './PageCompanies.cn';
import { i18n } from './PageCompanies.i18n';
import { TabCompanies } from './TabCompanies';
import { TabGroups } from './TabGroups';
import { TabTravelPolicies } from './TabTravelPolicies';

const tabComponents: { [key in Tab]: FC } = {
    companies: TabCompanies,
    travel_policies: TabTravelPolicies,
    groups: TabGroups,
};

const iconProviderPlus = getIconProvider(IconPlus);

export const PageCompanies: FC<RouteCompanies> = props => {
    const {
        search: { tab = 'companies' },
    } = props;

    const tabsList: Tab[] = useMemo(() => ([
        'companies',
        'travel_policies',
    ]), []);

    const tabs = useMemo(() => {
        return tabsList.map(tab => ({
            id: tab,
            text: i18n(tab),
            href: RouteCompanies.getPath({}, { tab }),
        }));
    }, [tabsList]);

    const ActiveTab = tabComponents[tab];

    const newTravelPolicyUrl = RouteTravelPolicyAdd.getPath({}, {});

    const headerActions = (tab: string) => {
        switch (tab) {
            case 'travel_policies':
                return (
                    <Button
                        iconLeft={iconProviderPlus}
                        size="m"
                        type="link"
                        url={newTravelPolicyUrl}
                        view="outline"
                    >
                        {i18n('add_travel_policy')}
                    </Button>
                );

            default:
                return;
        }
    };

    return (
        <Page className={cn()} data-testid="page-companies">
            <HeaderPage
                actions={headerActions(tab)}
                activeTab={tab}
                tabs={tabs}
                title={i18n('page_title')}
            />
            <Container>
                <ActiveTab />
            </Container>
        </Page>
    );
};

PageCompanies.displayName = cn();
