import { ComponentProps, FC, ReactElement, useCallback } from 'react';
import { withBemMod } from '@bem-react/core';

import { PersonSuggest } from 'components/PersonSuggest';
import { PersonSuggestItem } from 'components/PersonSuggest/PersonSuggest.types';

import { cn } from '../FormField.cn';
import { FormFieldProps as BaseProps } from '../FormField.types';

type PersonUids = ComponentProps<typeof PersonSuggest>['value'];

export interface WithTypePersonProps {
    type?: 'person';
    placeholder?: ComponentProps<typeof PersonSuggest>['placeholder'];
    onChange?: (value: Array<PersonUids>) => void;
    onBlur?: ComponentProps<typeof PersonSuggest>['onBlur'];
    value?: PersonUids;
    pin?: ComponentProps<typeof PersonSuggest>['pin'];
    singleChoice?: ComponentProps<typeof PersonSuggest>['singleChoice'];
    idType?: 'uid' | 'staff_id';
    state?: 'error';
    name?: string;
}

export const withTypePerson = withBemMod<WithTypePersonProps, BaseProps>(
    cn(),
    { type: 'person' },
    (Base): FC<BaseProps & WithTypePersonProps> => (props): ReactElement => {
        const {
            placeholder,
            onChange = () => {},
            onBlur,
            value = [],
            state,
            pin,
            singleChoice,
            idType = 'uid',
            name,
        } = props;

        const handleChange = useCallback(data => {
            onChange(data.map(({ uid, staff_id }: PersonSuggestItem) => idType === 'uid' ? uid : staff_id));
        }, [idType, onChange]);

        const appendAfter = (
            <PersonSuggest
                className={cn('Control', { name })}
                pin={pin}
                placeholder={placeholder}
                singleChoice={singleChoice}
                state={state}
                value={value}
                onBlur={onBlur}
                onChange={handleChange}
            />
        );

        return (
            <Base
                { ...props }
                appendAfter={appendAfter}
            />
        );
    },
);
