import { CompanyEmailForm } from 'components/CompanyEmailForm';
import { DomainlessCompanyButton } from 'components/DomainlessCompanyButton';
import { HeaderLite } from 'components/HeaderLite';
import { RegisterLayout } from 'components/RegisterLayout';
import { Text } from 'shared/ui/Text';
import { Page } from 'types/Page';

import { cn } from './PageRegister.cn';
import { i18n } from './PageRegister.i18n';

import './PageRegister.css';

export const PageRegister: Page = () => {
    return (
        <div className={cn()}>
            <HeaderLite />
            <RegisterLayout
                title={i18n('title')}
            >
                <Text
                    className={cn('Caption')}
                    color="secondary"
                    typography="paragraph-m"
                >
                    {i18n('add_email_caption')}
                </Text>
                <div className={cn('Controls')}>
                    <CompanyEmailForm />
                    <DomainlessCompanyButton />
                </div>
            </RegisterLayout>
        </div>
    );
};

PageRegister.displayName = cn();
