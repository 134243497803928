import { FC } from 'react';

import { cn } from './Container.cn';
import { ContainerProps } from './Container.types';

import './Container.css';

export const Container: FC<ContainerProps> = props => {
    const {
        children,
        className,
        grid,
        ref,
        wrapperClassName,
        ...rest
    } = props;

    return (
        <div ref={ref} {...rest} className={cn({ grid }, [wrapperClassName])}>
            <div className={cn('Children', [className])}>
                {children}
            </div>
        </div>
    );
};

Container.displayName = cn();
