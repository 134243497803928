import { FC } from 'react';

import { RegisterModalLayout } from 'components/RegisterModalLayout';
import { Text } from 'shared/ui/Text';

import { cn } from '../Root.cn';
import { i18n } from '../Root.i18n';

import { NoAccessProps } from './NoAccess.types';

export const NoAccess: FC<NoAccessProps> = props => {
    const {
        className,
    } = props;

    return (
        <div
            className={cn('NoAccess', [className])}
            data-testid="root-no-access"
        >
            <RegisterModalLayout
                className={cn()}
                title={i18n('no_access_title')}
            >
                <Text
                    className={cn('Description')}
                    color="secondary"
                    typography="body-s"
                >
                    {i18n('no_access_description')}
                </Text>
            </RegisterModalLayout>
        </div>
    );
};
