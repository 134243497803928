import { FC, useCallback } from 'react';

import { getIconProvider, IconClose } from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { Drawer } from 'shared/ui/Drawer';
import { Modal } from 'shared/ui/Modal';
import { Text } from 'shared/ui/Text';

import { cn } from './ModalPlatform.cn';
import { ModalPlatformProps } from './ModalPlatform.types';

import './ModalPlatform.css';

const iconProviderClose = getIconProvider(IconClose, { size: 'l' });

export const ModalPlatform: FC<ModalPlatformProps> = props => {
    const {
        visible,
        toggleVisible,
        title,
        description,
        children,
        className,
        showCloseButton = true,
        noPadding,
        keepMounted,
    } = props;

    const { isDesktop } = usePlatform();

    const onClose = useCallback(() => {
        toggleVisible(false);
    }, [toggleVisible]);

    if (isDesktop) {
        return (
            <Modal
                {...props}
                autoFocus={false}
                className={cn({ noPadding }, [className])}
                keepMounted={keepMounted}
                theme="normal"
                visible={visible}
                onClose={onClose}
            >
                {showCloseButton && (
                    <Button
                        className={cn('CloseButton')}
                        icon={iconProviderClose}
                        size="l"
                        view="clear"
                        onClick={onClose}
                    />
                )}
                <div className={cn('Layout')}>
                    {(title || description) && (
                        <div className={cn('TitleAndSubtitle')}>
                            { title && <Text typography="title-s">{title}</Text> }
                            { description && <Text color="secondary" typography="body-s">{description}</Text> }
                        </div>
                    )}
                    <div>
                        {children}
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <Drawer
            className={cn({ noPadding }, [className])}
            keepMounted={keepMounted}
            scope="inplace"
            view="default"
            visible={visible}
            onClose={toggleVisible}
        >
            <div className={cn('Layout')}>
                {title && (
                <Text typography="title-s">{title}</Text>
                )}
                <div>
                    {children}
                </div>
            </div>
        </Drawer>
    );
};
