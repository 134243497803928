import { arrival } from './arrival';
import { arrivalToLocation } from './arrivalToLocation';
import { departure } from './departure';
import { getEvents } from './getEvents';
import { taxiFrom } from './taxiFrom';
import { taxiTo } from './taxiTo';

export const getFlightEvents = getEvents(
    taxiTo,
    arrivalToLocation,
    departure,
    arrival,
    taxiFrom,
);
