import { FC, useCallback, useContext } from 'react';
import { withBemMod } from '@bem-react/core';

import { SettlementSuggestItem } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../../../SwaggerContext';
import { cn } from '../Suggest.cn';
import { i18n } from '../Suggest.i18n';
import {
    RenderOptionContent,
    RenderSelectedValue,
    SuggestProps,
} from '../Suggest.types';

export interface WithTypeCitiesProps {
    type?: 'cities';
}

export type CitySuggestItem = Omit<SettlementSuggestItem, 'geoid' | 'source'> & {
    id: number;
};

type FetchCities = (queryText: string) => Promise<CitySuggestItem[]>;

export const withTypeCities = withBemMod<WithTypeCitiesProps, SuggestProps>(
    cn(),
    { type: 'cities' },
    (Suggest): FC<WithTypeCitiesProps & SuggestProps> => props => {
        const { api } = useContext(SwaggerContext);
        const { suggestView } = props;

        const dataprovider = useCallback<FetchCities>(async text => {
            if (!text) {
                return [];
            }

            const result = await api.settlement_suggest({ query: text })
                .then(throwHTTPErrors).then(response => response.result);

            return result.map(item => {
                const { geoid, source, ...restValues } = item;

                return {
                    id: geoid,
                    ...restValues,
                };
            });
        }, [api]);

        const renderOptionContent = useCallback<RenderOptionContent>(item => {
            return (
                <>
                    <Text weight="medium">{item.name},</Text> <Text color="secondary">{item.country}</Text>
                </>
            );
        }, []);

        const renderSelectedValue = useCallback<RenderSelectedValue>(item => `${item.name}`, []);

        const tagRender = useCallback(item => {
            return (
                <div className={cn('SelectedCity')} data-testId="city-tag">
                    <Text typography="body-s">
                        {item.name}
                    </Text>
                    <Text color="secondary" typography="caption-m">
                        {item.localized_country || item.country}
                    </Text>
                </div>
            );
        }, []);

        return (
            <Suggest
                {...props}
                dataprovider={dataprovider}
                placeholder={props.placeholder || i18n('cities_placeholder')}
                renderOptionContent={renderOptionContent}
                renderSelectedValue={suggestView === 'text' ? renderSelectedValue : undefined}
                renderTagContent={suggestView === 'tags' ? tagRender : undefined}
            />
        );
    },
);
