import { FC, useRef, useState } from 'react';

import { ModalSlider } from 'components/ModalSlider';

import { cn } from './CompanyInvoice.cn';
import { CompanyInvoiceProps } from './CompanyInvoice.types';
import { DownloadInvoice } from './DownloadInvoice';
import { InvoiceForm } from './InvoiceForm';

import './CompanyInvoice.css';

export const CompanyInvoice: FC<CompanyInvoiceProps> = props => {
    const {
        visible,
        onModalClose,
    } = props;

    const [urlFile, setUrlFile] = useState('');
    const [amount, setAmount] = useState('');
    const innerRef = useRef<HTMLDivElement | null>(null);

    return (
        <ModalSlider
            className={cn()}
            innerRef={innerRef}
            visible={visible}
            onModalClose={onModalClose}
        >
            <InvoiceForm
                setAmount={setAmount}
                setUrlFile={setUrlFile}
                onClose={onModalClose}
            />
            <DownloadInvoice
                amount={amount}
                url={urlFile}
                onClose={onModalClose}
            />
        </ModalSlider>
    );
};

CompanyInvoice.displayName = cn();
