import { FC } from 'react';

import { FlightRouteTransferTag } from 'components/FlightRouteTransferTag';
import { usePlatform } from 'hooks/usePlatform';
import { i18nFlightDirection } from 'i18n/i18nFlightDirection';
import { Text } from 'shared/ui/Text';
import { compactDate } from 'utils/formatDate';
import { formatDuration } from 'utils/formatDuration';
import { getRouteDuration } from 'utils/getRouteDuration';

import { FlightInfoRouteContent } from './FlightInfoRouteContent/FlightInfoRouteContent';
import { cn } from './FlightInfoRoute.cn';
import { FlightInfoRouteProps } from './FlightInfoRoute.types';

import './FlightInfoRoute.css';

export const FlightInfoRoute: FC<FlightInfoRouteProps> = props => {
    const {
        leg,
        className,
        extendable = true,
        isCollapsed = true,
        direction,
        toggleCollapse,
    } = props;

    const { segments, segments_count } = leg;

    const { isMobile } = usePlatform();

    const departureCity = segments[0].departure.city?.name;
    const arrivalCity = segments[segments.length - 1].arrival.city?.name;

    const directionText = direction ? `${i18nFlightDirection(direction)} ` : '';

    const duration = formatDuration(getRouteDuration(segments));
    const shoudShowHeader = extendable && !isCollapsed;
    const shoudShowMobileTransferTag = isMobile && isCollapsed;

    return (
        <div className={cn(null, [className])}>
            {shoudShowHeader && (
                <div className={cn('Header')}>
                    <Text typography="body-m" weight="medium">
                        {`${departureCity} – ${arrivalCity}`}
                    </Text>
                    <Text color="secondary" typography="body-s">
                        {`${directionText}${compactDate(segments[0].departure_at)} ${duration ? `• ${duration}` : ''}`}
                    </Text>
                </div>
            )}
            <FlightInfoRouteContent isCollapsed={isCollapsed} leg={leg} toggleCollapse={toggleCollapse} />
            {shoudShowMobileTransferTag && (
                <FlightRouteTransferTag
                    className={cn('TransferTag')}
                    segments={segments}
                    segmentsCount={segments_count}
                />
            )}
        </div>
    );
};

FlightInfoRoute.displayName = cn();
