import { FC, useMemo } from 'react';
import { useToggle } from 'react-use';

import { ButtonMenu } from 'components/ButtonMenu';
import { ButtonPlatform } from 'components/ButtonPlatform';
import { getIconProvider, IconMoreVertical } from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { PersonTrip, UserResponse } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';

import { useMenuOptions } from '../hooks/useMenuOptions';
import { Modals } from '../Modals/Modals';
import { cn } from '../TripInfoPersonHeader.cn';
import { i18n } from '../TripInfoPersonHeader.i18n';
import { Actions, ActionsCallbacks } from '../TripInfoPersonHeader.types';

type ControlsProps = {
    user: UserResponse;
    personTrip: PersonTrip;
    personId: string;
    tripId: number;
};

const iconProviderMoreVertical = getIconProvider(IconMoreVertical);

export const Controls: FC<ControlsProps> = props => {
    const {
        user,
        personTrip,
        personId,
        tripId,
    } = props;

    const canEditPersonTripRoute = personTrip.actions?.edit && Boolean(personTrip.route?.length);

    const { isDesktop, isMobile } = usePlatform();
    const [visibleAddParticipantModal, setVisibleAddParticipantModal] = useToggle(false);
    const [visibleModalDateChange, toggleModalDateChange] = useToggle(false);
    const [visibleTimelineModal, toggleVisibleTimelineModal] = useToggle(false);
    const [visibleConfirmation, toggleConfirmationVisible] = useToggle(false);

    const showAddParticipant = !user.is_limited_access && isDesktop;

    const actions: Actions = {
        cancelTrip: personTrip.actions?.cancel && isMobile,
        addParticipants: !user.is_limited_access && isMobile,
    };

    const actionsCallbacks = useMemo<ActionsCallbacks>(() => ({
        onCancelTrip: toggleConfirmationVisible,
        onAddParticipants: () => setVisibleAddParticipantModal(),
        onEditTrip: toggleModalDateChange,
    }), [toggleConfirmationVisible, setVisibleAddParticipantModal, toggleModalDateChange]);

    const menuOptions = useMenuOptions(actionsCallbacks, personTrip, actions);

    return (
        <div className={cn('Controls')}>
            {showAddParticipant && (
                <ButtonPlatform
                    className={cn('AddParticipants')}
                    size="m"
                    view="outline"
                    onClick={() => setVisibleAddParticipantModal(true)}
                >
                    {i18n('add_participant')}
                </ButtonPlatform>
            )}

            <ButtonPlatform
                className={cn('EditPersonTripButton')}
                disabled={!canEditPersonTripRoute}
                size="m"
                view="outline"
                onClick={toggleModalDateChange}
            >
                {i18n('edit_trip_date')}
            </ButtonPlatform>

            {isDesktop && personTrip.actions?.cancel && (
                <ButtonPlatform
                    className={cn('CancelOption')}
                    size="m"
                    view="outline"
                    onClick={toggleConfirmationVisible}
                >
                    <Text color="control-error">
                        {i18n('cancel_trip')}
                    </Text>
                </ButtonPlatform>
            )}

            {menuOptions.length > 0 && isMobile &&
                <ButtonMenu
                    className={cn('ButtonMenu')}
                    icon={iconProviderMoreVertical}
                    menu={menuOptions}
                    size="m"
                    view="pseudo"
                    width="auto"
                />
            }
            <Modals
                personId={personId}
                personTrip={personTrip}
                setVisibleAddParticipantModal={setVisibleAddParticipantModal}
                toggleConfirmationVisible={toggleConfirmationVisible}
                toggleModalDateChange={toggleModalDateChange}
                toggleVisibleTimelineModal={toggleVisibleTimelineModal}
                tripId={tripId}
                visibleAddParticipantModal={visibleAddParticipantModal}
                visibleConfirmation={visibleConfirmation}
                visibleModalDateChange={visibleModalDateChange}
                visibleTimelineModal={visibleTimelineModal}
            />
        </div>
    );
};
