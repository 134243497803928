import { FC } from 'react';

import { Card } from 'components/Card';
import { RouteTravelPolicy } from 'routes/RouteTravelPolicy';
import { Text } from 'shared/ui/Text';
import { getCabinClasses, getHotelStars, getRailCategories } from 'utils/getFormatedPolicyRules';

import { cn } from './TravelPolicyCard.cn';
import { i18n } from './TravelPolicyCard.i18n';
import { TravelPolicyCardProps } from './TravelPolicyCard.types';
import { formatMaxPrice, joinRestrictionsWithBullet } from './TravelPolicyCard.utils';

import './TravelPolicyCard.css';

export const TravelPolicyCard: FC<TravelPolicyCardProps> = props => {
    const { className, data } = props;

    const { name, persons_count, avia, hotel, rail, travel_policy_id } = data;

    const viewTravelPolicyUrl = RouteTravelPolicy.getPath({ travelPolicyId: travel_policy_id }, {});

    const aviaMaxPrice = formatMaxPrice(avia.max_price);
    const aviaClasses = getCabinClasses(avia.service_classes);
    const aviaRestrictions = joinRestrictionsWithBullet([aviaMaxPrice, aviaClasses]);
    const isAviaNoRestrictions = !aviaMaxPrice && !aviaClasses;

    const railMaxPrice = formatMaxPrice(rail.max_price);
    const railCategories = getRailCategories(rail.train_car_categories);
    const railRestrictions = joinRestrictionsWithBullet([railMaxPrice, railCategories]);
    const isRailNoRestrictions = !railMaxPrice && !railCategories;

    const hotelMaxPrice = formatMaxPrice(hotel.max_price_per_night);
    const hotelStars = getHotelStars(hotel.stars_allowed);
    const hotelRestrictions = joinRestrictionsWithBullet([hotelMaxPrice, hotelStars]);
    const isHotelNoRestrictions = !hotelMaxPrice && !hotelStars;

    return (
        <Card className={cn(null, [className])} href={viewTravelPolicyUrl}>
            <div className={cn('Header')}>
                <Text color="primary" overflow="ellipsis" typography="title-s">
                    {name}
                </Text>
                <Text color="secondary" typography="body-s">
                    {i18n('users', { count: Number(persons_count) })}
                </Text>
            </div>
            <div className={cn('Content')}>
                <div className={cn('Row')}>
                    <Text color="primary" typography="body-s">
                        {i18n('avia')}
                    </Text>
                    <Text className={cn('RestrictionsText')} color="secondary" overflow="ellipsis" typography="body-s">
                        {aviaRestrictions}
                        {isAviaNoRestrictions && i18n('no_restrictions')}
                    </Text>
                </div>
                <div className={cn('Row')}>
                    <Text color="primary" typography="body-s">
                        {i18n('rail')}
                    </Text>
                    <Text className={cn('RestrictionsText')} color="secondary" overflow="ellipsis" typography="body-s">
                        {railRestrictions}
                        {isRailNoRestrictions && i18n('no_restrictions')}
                    </Text>
                </div>
                <div className={cn('Row')}>
                    <Text color="primary" typography="body-s">
                        {i18n('hotel')}
                    </Text>
                    <Text className={cn('RestrictionsText')} color="secondary" overflow="ellipsis" typography="body-s">
                        {hotelRestrictions}
                        {isHotelNoRestrictions && i18n('no_restrictions')}
                    </Text>
                </div>
            </div>
        </Card>
    );
};

TravelPolicyCard.displayName = cn();
