import { apiFetchTypedErrors } from '../SwaggerApi.utils';

import {
    HTTPValidationError,
} from './SwaggerApiTypes';

export class SwaggerApiTemp {
    /*
     * DO NOT DELETE
     * Метод нужный для теста mockSwagger
     */
    for_test(
        options?: RequestInit) {
        return apiFetchTypedErrors<
            ResponseOnStatus<200, { testProp: number }> |
            ResponseOnStatus<422, HTTPValidationError>,
            null,
            null
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        >('/api/companies/details', null, [200, 422], 'GET', null, options);
    }
}
