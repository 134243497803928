import { FC } from 'react';

import { useHandleChange } from 'components/FormField2/utils/useHandleChange';
import { Radiobox, RadioboxProps } from 'shared/ui/Radiobox';

import { withFormField } from '../../FormField2';
import { cn as cnFormField } from '../../FormField2.cn';
import { FormFieldProps } from '../../FormField2.types';

const Control: FC<RadioboxProps & FormFieldProps> = props => {
    const {
        name,
        size = 'm',
        deps,
        controller,
        context,
        options = [],
        ...radioButtonProps
    } = props;

    const {
        field: { value },
    } = controller;

    const handleChange = useHandleChange({ controller, context, deps });

    return (
        <Radiobox
            {...radioButtonProps}
            className={cnFormField('Control', { name })}
            name={name}
            options={options}
            size={size}
            value={value}
            onChange={handleChange}
        />
    );
};

export const FieldRadiobox = withFormField(Control);
