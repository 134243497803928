import { ComponentProps, FC, useCallback } from 'react';
import { Attach } from '@yandex-lego/components/Attach/desktop/bundle';

import { withFormField } from '../FormField2';
import { cn } from '../FormField2.cn';
import { FormFieldProps } from '../FormField2.types';

export type WithTypeAttachProps = ComponentProps<typeof Attach>;

type HandleChange = Required<WithTypeAttachProps>['onChange'];

const Control: FC<WithTypeAttachProps & FormFieldProps> = props => {
    const {
        size = 'm',
        name,
        controller,
        context,
        ...attachProps
    } = props;

    const {
        field: { onChange },
    } = controller;

    const handleFileChange = useCallback<HandleChange>(e => {
        const file = e.target.files?.[0];

        onChange(file);
    }, [onChange]);

    return (
        <Attach
            {...attachProps}
            className={cn('Control', { name })}
            id={name}
            name={name}
            size={size}
            view="default"
            onChange={handleFileChange}
        />
    );
};

export const FieldAttach = withFormField(Control);
