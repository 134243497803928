import { FC } from 'react';
import { DialogLayout } from '@yandex-int/hr-components/DialogLayout/DialogLayout';

import { Modal } from 'shared/ui/Modal';

import { DialogProps } from './Dialog.types';

import './Dialog.css';

/**
 * Модальное окно подтверждения
 */
export const Dialog: FC<DialogProps> = props => {
    const {
        visible,
        autoFocus = false,
        scope = 'inplace',
        dataTestId,
        keepMounted,
        className,
        ...dialogProps
    } = props;

    return (
        <Modal
            autoFocus={autoFocus}
            className={className}
            keepMounted={keepMounted}
            scope={scope == null ? undefined : 'inplace'}
            theme="normal"
            visible={visible}
        >
            <div data-testid={dataTestId}>
                <DialogLayout {...dialogProps} />
            </div>
        </Modal>
    );
};
