import { useMemo } from 'react';

import { MenuOption } from 'components/ActionsMenu';
import {
    getIconProvider,
    IconEdit,
    IconPlus,
    IconTrash,
} from 'components/Icon';
import { PersonTrip } from 'services/SwaggerApi';

import { cn } from '../TripInfoPersonHeader.cn';
import { i18n } from '../TripInfoPersonHeader.i18n';
import { Actions, ActionsCallbacks } from '../TripInfoPersonHeader.types';

type UseMenuOptions = (
    actionsCallbacks: ActionsCallbacks,
    personTrip: PersonTrip,
    actions: Actions
) => MenuOption[];

const iconCancelProvider = getIconProvider(IconTrash);
const iconAddProvider = getIconProvider(IconPlus);
const iconEditProvider = getIconProvider(IconEdit);

export const useMenuOptions: UseMenuOptions = (actionsCallbacks, personTrip, actions) => {
    const {
        onCancelTrip,
        onAddParticipants,
        onEditTrip,
    } = actionsCallbacks;

    return useMemo(() => {
        const options: MenuOption[] = [];

        if (actions.editTrip) {
            options.push({
                label: i18n('edit_trip_date'),
                icon: iconEditProvider,
                onClick: onEditTrip,
            });
        }

        if (actions.addParticipants) {
            options.push({
                label: i18n('add_participant'),
                icon: iconAddProvider,
                onClick: onAddParticipants,
            });
        }

        if (actions.cancelTrip) {
            options.push({
                label: i18n('cancel_trip'),
                icon: iconCancelProvider,
                onClick: onCancelTrip,
                view: 'danger',
                className: cn('CancelOption'),
            });
        }

        return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actionsCallbacks, personTrip]);
};
