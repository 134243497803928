export const ru = {
    page_title: 'Выбор номера',
    services_added: 'Выбранные номера',
    services: {
        one: '{count} номер на сумму {totalPrice}',
        some: '{count} номера на сумму {totalPrice}',
        many: '{count} номеров на сумму {totalPrice}',
        none: 'Номер не выбран',
    },
};
