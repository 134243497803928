import { useEffect } from 'react';

import { FlightInfoFareFamilyOptionProps } from '../FlightInfoFareFamilyOption.types';

type UseSlideToOption = {
    swiperRef: FlightInfoFareFamilyOptionProps['swiperRef'],
    optionNumber: FlightInfoFareFamilyOptionProps['optionNumber'],
    checked: FlightInfoFareFamilyOptionProps['checked']
}

export const useSlideToOption = (options: UseSlideToOption) => {
    const {
        swiperRef,
        checked,
        optionNumber,
    } = options;

    useEffect(() => {
        if (checked) {
            swiperRef?.current?.slideTo(optionNumber);
        } else {
            swiperRef?.current?.slideTo(0);
        }
    }, [swiperRef, optionNumber, checked]);
};
