export const en = {
    title: 'Feedback',
    message_placeholder: 'Enter your question',
    email: 'Email',
    email_placeholder: 'Enter your email',
    email_invalid: 'Invalid email format',
    phone: 'Phone',
    phone_placeholder: 'Enter your phone',
    contact_method_label: 'How can we contact you?',
    send: 'Send',
    close: 'Close',
    we_received_request: 'We have received your request',
    manager_contact_you: 'Our manager will contact you soon',
    eula: 'I consent to the processing of the specified data by YANDEX LLC for the purposes of feedback to me and informing me about the Yandex Trips service under the terms of the',
    eula_policy: 'Privacy Policy',
};
