import { ComponentType, EffectCallback, RefObject } from 'react';

import { ModalView } from 'components/ModalView';

type WithModalView = {
    modalViewVisible: boolean;
    modalViewOnClose: EffectCallback;
    modalViewScope?: RefObject<HTMLElement>;
    modalViewSize?: 's' | 'l';
    modalViewOnCloseOff?: true;
    modalViewHasCloseIcon?: boolean;
    modalViewAutoFocus?: boolean;
};

export function withModalView<T>(Component: ComponentType<T>) {
    return function componentWithModalView<U extends WithModalView & T>(props: U) {
        const {
            modalViewVisible: visible,
            modalViewOnClose: onClose,
            modalViewScope: scope,
            modalViewSize: size,
            modalViewOnCloseOff: modalOnCloseOff,
            modalViewHasCloseIcon: hasCloseIcon = true,
            modalViewAutoFocus: autoFocus = true,
        } = props;
        const modalViewProps = { visible, onClose, scope, size, modalOnCloseOff, hasCloseIcon, autoFocus };

        return (
            <ModalView {...modalViewProps}>
                {visible && <Component {...props} />}
            </ModalView>
        );
    };
}
