import { FC, useCallback, useRef, useState } from 'react';

import { MenuOption } from 'components/ActionsMenu';
import { ButtonMenu } from 'components/ButtonMenu';
import { getIconProvider, IconMoreVertical, IconTrash } from 'components/Icon';
import { useDeleteBonusCard } from 'hooks/useDeleteBonusCard';
import { i18nAction } from 'i18n/i18nAction';
import { isLoading } from 'utils/Loadable';

import { cn } from '../LoyaltyCards.cn';

import { ItemMenuProps } from './ItemMenu.types';

import './ItemMenu.css';

const kebabIcon = getIconProvider(IconMoreVertical);

export const ItemMenu: FC<ItemMenuProps> = props => {
    const { documentId, personId } = props;

    const anchor = useRef(null);

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const onClick = useCallback(() => {
        setIsMenuVisible(!isMenuVisible);
    }, [isMenuVisible]);

    const [deleteCard, deleteResponse] = useDeleteBonusCard();
    const isDeleting = isLoading(deleteResponse);

    const menu: MenuOption[] = [
        {
            icon: getIconProvider(IconTrash),
            label: i18nAction('remove'),
            onClick: () => { deleteCard(documentId, personId) },
            disabled: isDeleting,
        },
    ];

    return (
        <>
            <ButtonMenu
                className={cn('ButtonMenu')}
                direction="bottom-end"
                icon={kebabIcon}
                innerRef={anchor}
                menu={menu}
                size="m"
                view="pseudo"
                onClick={onClick}
            />
        </>
    );
};
