import { FC, useCallback } from 'react';
import { useToggle } from 'react-use';

import { CompanionsAdd } from 'components/CompanionsAdd';
import { ModalEarlyInLateOut } from 'components/ModalEarlyInLateOut';
import { ServiceGroupAdd } from 'components/ServiceGroupAdd';
import { useAddService } from 'hooks/useAddService';
import { useMeta } from 'hooks/useMeta';
import { ServiceCreate } from 'services/SwaggerApi';
import { getFullName } from 'shared/lib/person/getFullName';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { ModalPlatform } from 'shared/ui/ModalPlatform';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { isLoading } from 'utils/Loadable';

import { cn } from '../HotelRoom.cn';
import { i18n } from '../HotelRoom.i18n';
import { TariffTags } from '../TariffTags/TariffTags';
import { TariffTerms } from '../TariffTerms';

import { TariffProps } from './Tariff.types';

import './Tariff.css';

export const Tariff: FC<TariffProps> = props => {
    const {
        nights,
        isBestPrice,
        isBestPriceWithMeal,
        tariffData,
        hotelId,
        personId,
        searchId,
        trip,
        className,
        tariffName,
        addedServices,
        dispatchService,
        adultsCount,
        canAddServices,
    } = props;

    const {
        price_total,
        is_meal_included,
        meal_names,
        is_travel_policy_compliant,
        travel_policy_violations,
        cancellation_rules,
        is_corporate_tariff,
        is_booking_by_request,
        id,
        early_checkin_choices,
        late_checkout_choices,
    } = tariffData;
    const showTarriffTags = (
        isBestPrice ||
        isBestPriceWithMeal ||
        is_booking_by_request ||
        !is_travel_policy_compliant
    );

    const isGroupTrip = trip?.person_trips?.length !== 1;
    const [isGroupModalVisible, setGroupModalVisible] = useToggle(false);
    const [isCompanionModalVisible, toggleCompanionModalVisible] = useToggle(false);
    const [isEiloModalVisible, toggleEiloModalVisible] = useToggle(false);
    const [eiloChecked, toggleEiloChecked] = useToggle(false);
    const { user: { ext_persons_feature_enabled } } = useMeta();
    const companionsInSearch = adultsCount - 1;

    const showAvailableEilo = !isGroupTrip && (companionsInSearch === 0);
    const hasEilo = (Boolean(early_checkin_choices) || Boolean(late_checkout_choices)) && showAvailableEilo;

    const handleAddServiceError = useCallback((options: Partial<ServiceCreate>) => {
        dispatchService({
            type: 'removeService',
            payload: {
                personId: options.person_id,
                roomId: id,
            },
        });
    }, [dispatchService, id]);

    const handleAddServiceSuccess = useCallback(({ person_id, service_id }:
         { person_id: string; service_id: number }) => {
        dispatchService({
            type: 'unsetLoading',
            payload: {
                serviceId: service_id,
                roomId: id,
                personId: person_id,
            },
        });
    }, [dispatchService, id]);

    const handleAddServiceStart = useCallback((
        personId: string,
        additional?: ServiceCreate,
    ) => {
        dispatchService({
            type: 'addService',
            payload: {
                early_check_in: additional?.early_check_in,
                late_check_out: additional?.late_check_out,
                roomId: id,
                isExternal: false,
                roomName: tariffName,
                personId,
                isTPViolation: !is_travel_policy_compliant,
                price: price_total,
                login: trip?.person_trips?.find(trip => trip.person?.person_id === personId)?.person?.login,
                mealIncluded: is_meal_included,
                noFee: cancellation_rules?.[0]?.penalty === 0,
                personName: getFullName(
                        trip?.person_trips?.find(trip => trip.person?.person_id === personId)?.person!,
                ),
                adultsCount,
            },
        });
    }, [
        cancellation_rules, dispatchService, id, is_meal_included,
        is_travel_policy_compliant, price_total, tariffName, trip?.person_trips, adultsCount,
    ]);

    const [addService, addServiceState] = useAddService({
        onSuccess: ({ service_id }) => {
            handleAddServiceSuccess({ service_id, person_id: personId });
        },
        onError: (_, { person_id }) => {
            handleAddServiceError(({ person_id }));
        },
    });

    const addServiceHandler = useCallback((additional?: ServiceCreate) => {
        if (trip) {
            addService({
                ...additional,
                person_id: personId,
                search_id: searchId,
                trip_id: trip.trip_id,
                type: 'hotel',
                provider_item_id: hotelId,
                provider_detail_id: id,
                ...(!is_travel_policy_compliant && { overprice_workflow: 'extra_payment' }),
            });
            handleAddServiceStart(personId, additional);
        }
    }, [
        addService,
        handleAddServiceStart,
        trip,
        id,
        hotelId,
        personId,
        searchId,
        is_travel_policy_compliant,
    ]);

    const handleHotelRoomSelect = useCallback(() => {
        if (eiloChecked) {
            toggleEiloModalVisible(true);

            return;
        }

        if (isGroupTrip) {
            setGroupModalVisible(true);

            return;
        }

        if (companionsInSearch > 0 && ext_persons_feature_enabled) {
            toggleCompanionModalVisible(true);

            return;
        }

        addServiceHandler();
    }, [
        isGroupTrip,
        eiloChecked,
        companionsInSearch,
        ext_persons_feature_enabled,
        setGroupModalVisible,
        toggleCompanionModalVisible,
        toggleEiloModalVisible,
        addServiceHandler,
    ]);

    const isRoomBooked = Boolean(!isGroupTrip && addedServices?.find(service => service.roomId === id));

    const getServiceCreatePayload = useCallback<(personId: string) => ServiceCreate>(personId => ({
        person_id: personId,
        search_id: searchId,
        trip_id: trip?.trip_id || 0,
        type: 'hotel',
        provider_item_id: hotelId,
        provider_detail_id: id,
    }), [hotelId, id, searchId, trip?.trip_id]);

    const createService = useCallback((companionsList: string[]) => {
        addService({ ...getServiceCreatePayload(personId), ext_person_uuids: companionsList });
        handleAddServiceStart(personId);
        toggleCompanionModalVisible(false);
    }, [
        addService,
        getServiceCreatePayload,
        handleAddServiceStart,
        personId,
        toggleCompanionModalVisible,
    ]);

    const cancelCreateService = useCallback(() => {
        toggleCompanionModalVisible(false);
    }, [toggleCompanionModalVisible]);

    return (
        <>
            <div className={cn('Tariff', [className])}>
                <div className={cn('Main')}>
                    {showTarriffTags && (
                        <TariffTags
                            isBestPrice={isBestPrice}
                            isBestPriceWithMeal={isBestPriceWithMeal}
                            isBookingByRequest={is_booking_by_request}
                            isTravelPolicyCompliant={is_travel_policy_compliant}
                            travelPolicyViolations={travel_policy_violations}
                        />
                    )}
                    <TariffTerms
                        cancellationRules={cancellation_rules}
                        isCorporateTariff={is_corporate_tariff}
                        isMealIncluded={is_meal_included}
                        mealNames={meal_names}
                        priceTotal={price_total}
                    />
                    { hasEilo && (
                        <Checkbox
                            checked={eiloChecked}
                            label={i18n('add_eilo')}
                            size="m"
                            view="outline"
                            onClick={toggleEiloChecked}
                        />
                    )}
                </div>
                <div className={cn('Aside')}>
                    <div className={cn('Price')}>
                        <Text typography="title-m">
                            {formatCurrency(price_total)}
                        </Text>
                        <Text color="secondary" typography="body-s">
                            {i18n('nights', { count: nights })}
                        </Text>
                    </div>
                    <Button
                        disabled={!trip?.trip_id || isLoading(addServiceState) || isRoomBooked || !canAddServices}
                        size="m"
                        view="primary"
                        onClick={handleHotelRoomSelect}
                    >
                        {(isRoomBooked && !isLoading(addServiceState)) ? i18n('room_booked') : i18n('button_get_number')}
                    </Button>
                </div>
            </div>
            {trip && (
                <>
                    <ModalPlatform
                        keepMounted={false}
                        toggleVisible={setGroupModalVisible}
                        visible={isGroupModalVisible}
                    >
                        <ServiceGroupAdd
                            getServiceCreatePayload={getServiceCreatePayload}
                            isViolatesTravelPolicy={false}
                            trip={trip}
                            onAddServiceError={handleAddServiceError}
                            onAddServiceStart={handleAddServiceStart}
                            onAddServiceSuccess={handleAddServiceSuccess}
                        />
                    </ModalPlatform>
                    <ModalPlatform
                        className={cn('CompanionsModal')}
                        toggleVisible={toggleCompanionModalVisible}
                        visible={isCompanionModalVisible}
                    >

                        <CompanionsAdd
                            cancel={cancelCreateService}
                            companionsInSearch={companionsInSearch}
                            createService={createService}
                            personId={personId}
                            progress={isLoading(addServiceState)}
                        />
                    </ModalPlatform>
                    {isEiloModalVisible && (
                        <ModalEarlyInLateOut
                            visible
                            earlyCheckinChoices={early_checkin_choices}
                            lateCheckoutChoices={late_checkout_choices}
                            toggleModal={toggleEiloModalVisible}
                            onFormSubmit={addServiceHandler}
                        />
                    )}
                </>
            )}
        </>
    );
};
