import { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { IconBug } from 'components/Icon';
import { useMeta } from 'hooks/useMeta';
import { usePlatform } from 'hooks/usePlatform';
import { getTypedI18nLang } from 'utils/i18n';

import { cn } from './Butterfly.cn';

import './Butterfly.css';

const loadButterfly = (login: string, locale: string) => {
    if (document.getElementById('yndxbug')) {
        return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
        const debugData = JSON.stringify({
            login,
            locale,
            url: window.location.href,
        });

        const script = document.createElement('script');

        script.src = 'https://yastatic.net/s3/frontend/butterfly/latest/butterfly.js';
        script.id = 'yndxbug';
        script.async = true;
        script.setAttribute('position', 'right');
        script.setAttribute('container-id', 'body');
        script.setAttribute('modules', 'forms,info');
        script.setAttribute('data-domain', 'yandex-team');
        script.setAttribute('form', '61112');
        script.setAttribute('data-info', debugData);
        script.setAttribute('data-data', debugData);
        script.onload = resolve;
        script.onerror = reject;

        document.body.appendChild(script);
    });
};

const ButterflyTrip = () => {
    const { isMobile } = usePlatform();

    return (
        <div className={cn('Trip')}>
            <IconBug size={isMobile ? 'm' : 'l'} />
        </div>
    );
};

export const Butterfly: FC = () => {
    const { user } = useMeta();
    const locale = getTypedI18nLang();

    useEffect(() => {
        const patchButterfly = () => {
            const butterflyContainer = document.querySelector<HTMLDivElement>('.YndxBug');
            const butterflyDiv = document.querySelector<HTMLDivElement>('.YndxBug div');
            const butterflyChildren = document.querySelectorAll<HTMLDivElement>('.YndxBug div');
            const showButterfly = butterflyChildren.length === 1;

            if (butterflyContainer) {
                butterflyContainer.style.bottom = '140px';
                butterflyContainer.style.zIndex = '2';
            }

            if (butterflyDiv) {
                if (showButterfly) {
                    ReactDOM.render(<ButterflyTrip />, butterflyDiv);
                }

                butterflyDiv.style.border = 'none';
                butterflyDiv.style.background = 'none';
                butterflyDiv.style.width = '42px';
                butterflyDiv.style.height = '42px';
            }
        };

        // если изменится состав children, снова патчим
        const observer = new MutationObserver(patchButterfly);

        loadButterfly(user.login, locale).then(() => {
            const butterfly = document.querySelector<HTMLDivElement>('.YndxBug');

            if (!butterfly) return;

            patchButterfly();
            observer.observe(butterfly, { childList: true });
        });

        return () => observer.disconnect();
    }, [locale, user.login]);

    return null;
};

Butterfly.displayName = cn();
