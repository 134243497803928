import { FC, useCallback, useEffect, useState } from 'react';

import { FormCompanyRegister } from 'components/FormCompanyRegister';
import { FormFindCompanyFields } from 'components/FormFindCompany/FormFindCompany.types';
import { withRemote } from 'hocs/withRemote';
import { useCompanyDetails } from 'hooks/useCompanyDetails';
import { useCreatePerson } from 'hooks/useCreatePerson';
import { CompanyQuerySuggestItem } from 'services/SwaggerApi';
import { isFailure } from 'utils/Loadable';

import { AlreadyRegisteredError } from './AlreadyRegisteredError';
import { Error } from './Error';
import { FindCompany } from './FindCompany';
import { Layout } from './Layout';
import { cn } from './Register.cn';
import { RegisterBaseProps, RegisterProps } from './Register.types';

const RegisterBase: FC<RegisterBaseProps> = props => {
    const { companyDetails } = props;

    const [createPersonPost, createPersonState] = useCreatePerson({
        options: {
            invalidateOnSuccess: true,
        },
    });

    useEffect(() => {
        if (companyDetails.company_id) {
            createPersonPost();
        }
    }, [companyDetails.company_id, createPersonPost]);

    if (isFailure(createPersonState)) {
        return (<Error />);
    }

    if (!companyDetails.company_id) {
        return (
            <Layout>
                <FormCompanyRegister
                    companyDetails={companyDetails}
                />
            </Layout>
        );
    }

    return null;
};

const RegisterRemote = withRemote(RegisterBase);

export const Register: FC<RegisterProps> = props => {
    const {
        className,
    } = props;

    const [company, setCompany] = useState<CompanyQuerySuggestItem | null>();

    const onSubmitCompany = useCallback((payload: FormFindCompanyFields) => {
        setCompany(payload?.company);
    }, []);

    const companyDetails = useCompanyDetails({
        inn: company?.inn,
        kpp: company?.kpp ?? undefined,
    });

    if (isFailure(companyDetails)) {
        const isCompanyAlreadyRegistred = companyDetails.error.status === 400;
        const is360CompanyNotFound = companyDetails.error.status === 404;

        if (isCompanyAlreadyRegistred) {
            return (
                <Layout>
                    <AlreadyRegisteredError />
                </Layout>
            );
        }

        if (is360CompanyNotFound) {
            return (
                <Layout>
                    <FindCompany onSubmit={onSubmitCompany} />
                </Layout>
            );
        }
    }

    return (
        <div
            className={cn(null, [className])}
            data-testid="register"
        >
            <RegisterRemote
                companyDetails={companyDetails}
                fallbackElement={<Error />}
            />
        </div>
    );
};

Register.displayName = cn();
