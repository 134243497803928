export const en = {
    no_consent_title: 'Access is limited, but it is fixable',
    no_consent_description: 'To access the service, you need to sign a personal data processing authorization',
    view_consent: 'View consent',
    consent_agree: 'Yes, I agree',
    consent_disagree: 'No, I don\'t agree',
    consent_subtitle: 'I consent to the',
    consent_title: 'Personal data',
    consent_pd_processing: 'processing of my personal data',
};
