import { FC, useEffect } from 'react';

import { FieldTextinput, FormProvider } from 'components/FormField2';
import { useCreateAccountInTripForYandexLogin } from 'hooks/useCreateAccountInTripForYandexLogin';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { isLoading } from 'utils/Loadable';

import { useFormAddEmployee } from './hooks/useFormAddEmployee';
import { cn } from './FormEmployeeAdd.cn';
import { i18n } from './FormEmployeeAdd.i18n';
import { FormEmployeeAddProps } from './FormEmployeeAdd.types';

import './FormEmployeeAdd.css';

export const FormEmployeeAdd: FC<FormEmployeeAddProps> = props => {
    const {
        className,
        companyId,
        onSuccess,
        onCancel,
        formVisible,
    } = props;

    const [createAccount, createAccountState, { error }] = useCreateAccountInTripForYandexLogin({ onSuccess });

    const { methods, schema, onSubmit } = useFormAddEmployee(createAccount, companyId);

    useEffect(() => {
        if (!formVisible) {
            methods.reset();
        }
    }, [formVisible, methods]);

    const isSubmitting = isLoading(createAccountState);

    return (
        <FormProvider
            error={error}
            methods={methods}
            schema={schema}
        >
            <form
                className={cn(null, [className])}
                data-testid="form-add-employee"
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <FieldTextinput
                    label={i18n('login')}
                    name="yandex_login"
                />
                <FieldTextinput
                    label={i18n('firstName')}
                    name="first_name"
                />
                <FieldTextinput
                    label={i18n('lastName')}
                    name="last_name"
                />
                <div className={cn('Controls')}>
                    <Button
                        className={cn('Cancel')}
                        size="m"
                        view="clear"
                        onClick={onCancel}
                    >
                        {i18nAction('cancel')}
                    </Button>
                    <Button
                        className={cn('Submit')}
                        progress={isSubmitting}
                        size="m"
                        type="submit"
                        view="brand"
                    >
                        {i18nAction('add')}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

FormEmployeeAdd.displayName = cn();
