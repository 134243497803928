import { FC } from 'react';

import { Card } from 'components/Card';
import { ListItem } from 'shared/ui/ListItem';

import { cn } from '../../PageProfile.cn';
import { i18n } from '../TabTravelPolicy.i18n';
import { GeneralRulesProps } from '../TabTravelPolicy.types';

import './GeneralRules.css';

export const GeneralRules: FC<GeneralRulesProps> = props => {
    const { details } = props;

    return (
        <Card title={i18n('general_rules')}>
            <div className={cn('GeneralRulesList')}>
                <ListItem
                    expanded
                    noVerticalPadding
                    transparent
                    leadingTextParams={{
                        label: details.name,
                        caption: i18n('travel_policy_name'),
                        inverse: true,
                    }}
                />
                <ListItem
                    expanded
                    noVerticalPadding
                    transparent
                    leadingTextParams={{
                        label: details.is_violation_allowed ? i18n('violation_allowed') : i18n('violation_forbidden'),
                        caption: i18n('violation_label'),
                        inverse: true,
                    }}
                />
                <ListItem
                    expanded
                    noVerticalPadding
                    transparent
                    leadingTextParams={{
                        label: details.is_default_approval_needed ?
                            i18n('approval_needed') :
                            i18n('approval_not_needed'),
                        caption: i18n('approval_needed_label'),
                        inverse: true,
                    }}
                />
            </div>
        </Card>
    );
};
