import dayjs from 'dayjs';

import { DateFormat, formatDate } from './formatDate';

export function compactDates(from: string, to: string): string {
    const fromDate = dayjs(from);
    const toDate = dayjs(to);

    const isFromCurrentYear = fromDate.year() === dayjs().year();
    let fromFormat: DateFormat | null = isFromCurrentYear ? DateFormat.D_MMMM : DateFormat.D_MMMM_YYYY;

    const isSameYear = fromDate.year() === toDate.year();
    const isSameDate = fromDate.date() === toDate.date();
    const isSameMonth = fromDate.month() === toDate.month();

    if (isSameDate && isSameMonth && isSameYear) {
        fromFormat = null;
    } else if (isSameMonth && isSameYear) {
        fromFormat = DateFormat.D;
    } else if (isSameYear) {
        fromFormat = DateFormat.D_MMMM;
    }

    const isToCurrentYear = fromDate.year() === dayjs().year();
    const toFormat = isToCurrentYear ? DateFormat.D_MMMM : DateFormat.D_MMMM_YYYY;

    if (fromFormat === DateFormat.D) {
        // если тире между цифр, то пробелы ставить не надо
        return `${formatDate(from, fromFormat)}–${formatDate(to, toFormat)}`;
    } else if (fromFormat) {
        return `${formatDate(from, fromFormat)} – ${formatDate(to, toFormat)}`;
    }

    return formatDate(to, toFormat);
}
