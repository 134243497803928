import { useCallback, useMemo } from 'react';

import { FilterItem } from 'services/SwaggerApi';

type IsAvailable<T> = (name: T) => boolean;

export const useAvailableFilters = <T extends string>(filters: FilterItem[]) => {
    const availableFilters = useMemo(() => {
        const availableFilters = new Set<string>();

        for (const filter of filters) {
            switch (filter.type) {
                case 'multiselect':
                    if (filter.values) availableFilters.add(filter.name);
                    break;

                default:
                    availableFilters.add(filter.name);
                    break;
            }
        }

        return availableFilters;
    }, [filters]);

    return useCallback<IsAvailable<T>>(name => {
        return availableFilters.has(name);
    }, [availableFilters]);
};
