import { FlightInfoFareFamilyOptionSkeleton } from 'components/FlightInfoFareFamilyOption';

import { cn } from '../FlightInfoFareFamily.cn';

import './FlightInfoFareFamilySkeleton.css';

export const FlightInfoFareFamilySkeleton = () => {
    return (
        <div className={cn('Skeleton')}>
            <FlightInfoFareFamilyOptionSkeleton />
            <FlightInfoFareFamilyOptionSkeleton />
        </div>
    );
};
