import { FC, memo } from 'react';

import { Text } from 'shared/ui/Text';

import { cn } from './TaxiDetails.cn';
import { ReasonsDropdownProps } from './TaxiDetails.types';

import './TaxiDetails.css';

export const ReasonsDropdown: FC<ReasonsDropdownProps> = memo(({ reasons }) => {
    return (
        <div className={cn('ReasonDropdown')}>
            {reasons.map((reason, index) => {
                return (
                    <Text
                        key={index}
                        as="div"
                        className={cn('Reason')}
                        typography="body-m"
                    >
                        {reason}
                    </Text>
                );
            })}
        </div>
    );
});
