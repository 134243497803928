import { FC, useCallback, useState } from 'react';

import { config } from 'config';
import { Button } from 'shared/ui/Button';
import { addToast } from 'utils/addToast';
import { getValueOrNull, isLoading, isRefetching } from 'utils/Loadable';

import { usePersonTripHolidays } from './ExecuteTripButton.hooks/usePersonTripHolidays';
import { getServicesDocumentInfo } from './ExecuteTripButton.utils/getServicesDocumentInfo';
import { Content } from './Content';
import { cn } from './ExecuteTripButton.cn';
import { i18n } from './ExecuteTripButton.i18n';
import { ExecuteTripButtonProps } from './ExecuteTripButton.types';

export const ExecuteTripButton: FC<ExecuteTripButtonProps> = props => {
    const { personTrip, personId, tripId, canExecute } = props;
    const { actions, conf_details, working_holidays } = personTrip;

    const canNotCreateService = conf_details && !conf_details.is_another_city;
    const text = canExecute ? i18n('execute_trip') : i18n('need_to_add_services');

    const { shouldAddDocument, servicesWithoutDocumentCount } = getServicesDocumentInfo(personTrip);

    const [visible, setIsVisible] = useState(false);

    const onModalClose = useCallback(() => {
        setIsVisible(false);
    }, []);

    const onExecuteButtonClick = useCallback(() => {
        if (shouldAddDocument) {
            addToast({
                title: i18n('missing_document_title', { count: servicesWithoutDocumentCount }),
                message: i18n('missing_document_message'),
            });
        } else {
            setIsVisible(true);
        }
    }, [servicesWithoutDocumentCount, shouldAddDocument]);

    const { isB2B } = config;
    const holidays = usePersonTripHolidays({ isEnabled: visible && !isB2B })(tripId, personId);

    const isLoadingHolidays = isLoading(holidays);
    const isRefetchingHolidays = isRefetching(holidays);
    const shouldDisableButton = !canExecute && !shouldAddDocument;
    const isModalVisible = visible && !isLoadingHolidays && !isRefetchingHolidays;

    if (canNotCreateService) {
        return null;
    }

    return (
        <>
            <Button
                className={cn()}
                disabled={shouldDisableButton}
                progress={isLoadingHolidays && isRefetchingHolidays}
                size="l"
                view="brand"
                width="max"
                onClick={onExecuteButtonClick}
            >
                {text}
            </Button>
            {isModalVisible && (
                <Content
                    actions={actions}
                    holidays={getValueOrNull(holidays)}
                    personId={personId}
                    tripId={tripId}
                    visible={isModalVisible}
                    workingHolidays={working_holidays}
                    onClose={onModalClose}
                />
            )}
        </>
    );
};

ExecuteTripButton.displayName = cn();
