export const ru = {
    page_title: 'Компании',
    companies: 'Компании',
    travel_policies: 'Тревел-политики',
    groups: 'Группы',
    add_travel_policy: 'Добавить тревел-политику',
    travel_policies_not_added: 'Тревел-политики не добавлены',
    show_more: 'Показать ещё',
    error_title: 'Что-то пошло не так',
    error_description: 'Попробуйте перезагрузить страницу или обратиться в поддержку, если ошибка повторяется',
    add_group: 'Добавить группу',
    groups_not_added: 'Группы не добавлены',
};
