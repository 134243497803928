import { ServiceDocumentDetails } from 'services/SwaggerApi';
import { formatBytes } from 'utils/formatBytes';

export type AttachmentInfo = {
   fileName: string;
   additionalInfo: string;
};

export const getAttachmentsInfo = (attachment: Pick<ServiceDocumentDetails, 'name' | 'length'>): AttachmentInfo => {
    const { name, length } = attachment;

    const lastDotIndex = name.lastIndexOf('.');
    const fileName = name.slice(0, lastDotIndex);
    const fileExt = name.slice(lastDotIndex + 1).toUpperCase();
    const formattedFileSize = length ? formatBytes(length) : null;
    const additionalInfo = [fileExt, formattedFileSize].filter(Boolean).join(' • ');

    return { fileName, additionalInfo };
};
