import { FC, KeyboardEvent, KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { Textinput } from 'shared/ui/Textinput';
import { DateFormat, formatDate } from 'utils/formatDate';

import { cn } from './DateField.cn';
import { i18n } from './DateField.i18n';
import { DateFieldProps } from './DateField.types';

import './DateField.css';

dayjs.extend(customParseFormat);

const getDateString: (inputValue: string) => string = inputValue => {
    const inputWithoutDots = inputValue.split('').filter(char => char !== '.');

    return inputWithoutDots.reduce<string[]>((acc, digit, index) => {
        acc.push(digit);

        if (index === 1 || index === 3) {
            acc.push('.');
        }

        return acc;
    }, []).join('');
};

type RemoveDots = {
    event: KeyboardEvent<HTMLInputElement>;
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const removeDot = (props: RemoveDots) => {
    const { event, setInputValue } = props;

    const inputTarget = event.target as HTMLInputElement;
    const inputValue = inputTarget.value;
    const caretPosition = inputTarget.selectionStart;

    if (event.key === 'Backspace' && caretPosition) {
        const deletedChar = inputTarget.value[caretPosition - 1];

        if (deletedChar === '.' && caretPosition === 3) {
            setInputValue(getDateString(inputValue.slice(0, 1) + inputValue.slice(3)));
            event.preventDefault();
        }

        if (deletedChar === '.' && caretPosition === 6) {
            setInputValue(getDateString(inputValue.slice(0, 4) + inputValue.slice(6)));
            event.preventDefault();
        }
    }
};

type UseWatchExternalValueChangeProps = {
    value: DateFieldProps['value'];
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const useWatchExternalValueChange = (props: UseWatchExternalValueChangeProps) => {
    const { value, setInputValue } = props;
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (document.activeElement === ref.current) {
            return;
        }

        if (!value || dayjs(value).isValid()) {
            setInputValue(formatDate(value, DateFormat.DD_MM_YYYY, ''));
        }
    }, [setInputValue, value]);

    return { ref };
};

/**
 * Поле ввода даты
 */
export const DateField: FC<DateFieldProps> = props => {
    const {
        className,
        value,
        onChange,
        size = 'm',
        view = 'outline',
        ...textInputProps
    } = props;

    const [inputValue, setInputValue] = useState(formatDate(value, DateFormat.DD_MM_YYYY, ''));

    const { ref } = useWatchExternalValueChange({ value, setInputValue });

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const dateString = getDateString(event.target.value);

        setInputValue(dateString);

        if (dateString.length === 10) {
            onChange?.(dayjs(dateString, DateFormat.DD_MM_YYYY).toDate());
        }

        if (dateString.length !== 10 && value !== null) {
            onChange?.(null);
        }
    }, [onChange, value]);

    const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(event => {
        const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Tab', 'Delete'];
        const isMetaKeyPressed = event.metaKey || event.altKey || event.ctrlKey;

        const isAllowedCombination = event.metaKey && (event.key === 'z');

        if (isNaN(Number(event.key)) && !(allowedKeys.includes(event.key)) && !isAllowedCombination) {
            event.preventDefault();

            return;
        }

        if (event.key === 'ArrowUp' && value && dayjs(value).isValid() && !isMetaKeyPressed) {
            onChange?.(dayjs(value).add(1, 'day').toDate());
            setInputValue(formatDate(value, DateFormat.DD_MM_YYYY, ''));
        }

        if (event.key === 'ArrowDown' && value && dayjs(value).isValid() && !isMetaKeyPressed) {
            onChange?.(dayjs(value).subtract(1, 'day').toDate());
            setInputValue(formatDate(value, DateFormat.DD_MM_YYYY, ''));
        }

        removeDot({ setInputValue, event });
    }, [onChange, value]);

    const mask = i18n('mask').split('').map((char, index) => {
        if (index < inputValue.length) {
            return <span key={index} className={cn('HiddenHintChar')}>{inputValue[index]}</span>;
        }

        return <span key={index} className={cn('HintChar')}>{char}</span>;
    });

    return (
        <div className={cn()}>
            <Textinput
                {...textInputProps}
                hasClear
                className={cn(null, [className])}
                controlRef={ref}
                inputMode="numeric"
                maxLength={10}
                size={size}
                value={inputValue}
                view={view}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
            <span className={cn('Placeholder', { size })}>
                {mask}
            </span>
        </div>
    );
};

DateField.displayName = cn();
