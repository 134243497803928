import { HasRequiredData } from 'components/FlightServiceCard/FlightServiceCard.types';
import { FetchError } from 'errors';
import { i18nFlight } from 'i18n/i18nFlight';
import {
    Flight,
    PersonDetailsPublic,
    ProviderLeg,
    ProviderSegment,
} from 'services/SwaggerApi';
import { AIRLINES_WITH_CHOOSE_SEATS_AVAILABLE } from 'shared/constants/CarriagesCodes';
import { BannerInline } from 'types/Notifications';

import { DateFormat, formatDate } from './formatDate';
import { getTpBanner } from './getTpBanner';
import { isSuccess, RemoteData } from './Loadable';

const hasRequiredData: HasRequiredData = person =>
    Boolean(person.has_first_name_en && person.has_last_name_en);

type GetFlightNotes = (
    service: Flight,
    oldIsTpCompliant?: boolean,
    reservationExpiration?: string,
    person?: RemoteData<PersonDetailsPublic, FetchError>,
) => (BannerInline | null);

export const getFlightBannerInfo: GetFlightNotes = (
    service,
    oldIsTpCompliant = true,
    reservationExpiration,
    person,
) => {
    const legs = service.legs || [];
    const carriers = legs.map(({ segments }) => segments.map(segment => segment.carrier)).flat();

    const isAirlineWithChooseSeats = carriers.find(carrier => {
        if (carrier.id) {
            return AIRLINES_WITH_CHOOSE_SEATS_AVAILABLE.includes(carrier?.id);
        }
    });

    // TODO: Выпилить oldIsTpCompliant после включения новых ТП - BTRIP-9669
    const isTravelPolicyViolation = !oldIsTpCompliant || !service.is_travel_policy_compliant;

    if (person) {
        const hasToFillInData = isSuccess(person) && !hasRequiredData(person.result);

        if (hasToFillInData) {
            return {
                title: i18nFlight('fill_your_en_data'),
                type: 'error',
            };
        }
    }

    if (reservationExpiration) {
        const formattedDate = formatDate(reservationExpiration, DateFormat.D_MMMM_HH_mm);
        const reservationText = i18nFlight('reservation', { date: formattedDate });

        return {
            title: reservationText,
            type: 'info',
        };
    }

    if (isTravelPolicyViolation) {
        return getTpBanner(service, oldIsTpCompliant);
    }

    if (isAirlineWithChooseSeats) {
        return {
            title: i18nFlight('choose_seats_available'),
            type: 'info',
        };
    }

    return null;
};

type GetFlightCardValues = (
    service: Flight,
    // TODO: Выпилить oldIsTpCompliant после включения новых ТП - BTRIP-9669
    oldIsTpCompliant?: boolean,
    reservationExpiration?: string,
    person?: RemoteData<PersonDetailsPublic, FetchError>,
) => {
    legs: ProviderLeg[];
    baggage: ProviderSegment['baggage'] ;
    banner: BannerInline | null;
    ticketsRemaining: number;
};

export const getFlightCardValues: GetFlightCardValues =
    (service, oldIsTpCompliant, reservationExpiration, person) => {
        const legs = service.legs || [];
        const baggage = service.baggage_summary;
        const ticketsRemaining = legs[0]?.segments[0].seats;

        const banner = getFlightBannerInfo(service, oldIsTpCompliant, reservationExpiration, person);

        return {
            legs,
            baggage,
            ticketsRemaining,
            banner,
        };
    };
