import { useQueryClient } from 'react-query';

import { TokenResponse } from 'services/SwaggerApi';

import { createUseQueryHook } from './createUseQueryHook';

/** по дэфолту токен на бэке протухает через 24 часа */
const twentyFourHoursInMs = 24 * 59 * 60 * 1000;

export const useCSRFRequest = createUseQueryHook(
    'get_csrf_token',
    {
        staleTime: twentyFourHoursInMs,
        refetchInterval: twentyFourHoursInMs,
        refetchIntervalInBackground: true,
    },
);

export const useCSRFToken = (): TokenResponse => {
    const queryClient = useQueryClient();
    // csrf токен получаем один раз при старте приложения в Root.tsx
    const CSRFToken = queryClient.getQueryData(['get_csrf_token']) as TokenResponse;

    return CSRFToken;
};
