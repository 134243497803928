import { FC } from 'react';

import { withModalView } from 'hocs/withModalView';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import { cn } from './ConfirmationModal.cn';
import { ConfirmationModalProps } from './ConfirmationModal.types';

import './ConfirmationModal.css';

export const ConfirmationSlide: FC<ConfirmationModalProps> = props => {
    const {
        className,
        title,
        message,
        cancelText = i18nAction('no_leave'),
        confirmText = i18nAction('yes_delete'),
        onCancel,
        onSubmit,
    } = props;

    return (
        <div className={cn(null, [className])}>
            <div className={cn('Header')}>
                <Text
                    as="div"
                    className={cn('Title')}
                    typography="title-s"
                    weight="medium"
                >
                    {title}
                </Text>
                <Text as="p" className={cn('Info')} typography="body-s">
                    {message}
                </Text>
            </div>
            <div className={cn('Controls')}>
                <Button
                    className={cn('Cancel')}
                    size="m"
                    view="pseudo"
                    onClick={onCancel}
                >
                    {cancelText}
                </Button>
                <Button
                    className={cn('Submit')}
                    size="m"
                    view="primary"
                    onClick={onSubmit}
                >
                    {confirmText}
                </Button>
            </div>
        </div>
    );
};

export const ConfirmationModal = withModalView(ConfirmationSlide);

ConfirmationSlide.displayName = cn();
