import * as yup from 'yup';

import { WorkingHolidaysFormValues } from '../ExecuteTripButton.types';

export type GetSchemaParams = { isDatesRequired: boolean };

export const getSchema = ({ isDatesRequired }: GetSchemaParams): yup.SchemaOf<WorkingHolidaysFormValues> =>
    yup.object({
        reason: yup.string().required(),
        dates: yup.array().required().min(isDatesRequired ? 1 : 0),
    });
