import { OrderParams } from '../FieldSort.types';

import { getOptions } from './getOptions';

export const getOptionValue = (index?: number): OrderParams => {
    if (index === undefined) {
        return {};
    }

    const options = getOptions();

    return options[index] || {};
};
