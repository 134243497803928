import { useMemo } from 'react';

import { MenuOption } from 'components/ActionsMenu';
import {
    getIconProvider,
    IconCheck,
    IconEdit,
    IconTrash,
} from 'components/Icon';
import { Transaction, TransactionActions } from 'services/SwaggerApi';

import { i18n } from '../TableTransactions.i18n';
import { ActionsCallbacks } from '../TableTransactions.types';

type UseMenuOptions = (
    actions: TransactionActions,
    actionsCallbacks: ActionsCallbacks,
    transaction: Transaction,
) => MenuOption[];

const iconProviderEdit = getIconProvider(IconEdit);
const iconProviderTrash = getIconProvider(IconTrash);
const iconProviderCheck = getIconProvider(IconCheck);

export const useMenuOptions: UseMenuOptions = (actions, actionsCallbacks, transaction) => {
    const {
        onUpdate,
        onDelete,
        onHold,
    } = actionsCallbacks;

    return useMemo(() => {
        const options: MenuOption[] = [];

        if (actions.set_transaction_paid) {
            options.push({
                label: i18n('action_set_transaction_paid'),
                icon: iconProviderCheck,
                onClick: () => onHold(transaction),
            });
        }

        if (actions.update) {
            options.push({
                label: i18n('action_update'),
                icon: iconProviderEdit,
                onClick: () => onUpdate(transaction),
            });
        }

        if (actions.delete) {
            options.push({
                label: i18n('action_delete'),
                view: 'danger',
                icon: iconProviderTrash,
                onClick: () => onDelete(transaction),
            });
        }

        return options;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actions, actionsCallbacks, transaction]);
};
