import { PageError } from 'components/PageError';
import { Page } from 'types/Page';

import { cn } from './PageNotFound.cn';

export const PageNotFound: Page = props => {
    return <PageError
        {...props}
        type="not_found"
    />;
};

PageNotFound.displayName = cn();
