import { FC } from 'react';
import { cn as classname } from '@bem-react/classname';
import { Checkbox, CheckboxProps } from '@yandex-int/hr-components/Checkbox/desktop/bundle';

const cn = classname('RichCheckbox');

export const RichCheckbox: FC<CheckboxProps> = ({ className, children, label, ...checkboxProps }) => {
    return (
        <Checkbox
            size="m"
            view="outline"
            {...checkboxProps}
            className={cn(null, [className])}
            label={children || label}
        />
    );
};
