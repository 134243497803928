export const en = {
    addDocumentLink: 'Add Document',
    addHotelLink: 'Add Hotel',
    add_airline_ticket: 'Add airline ticket',
    add_airline_ticket_back: 'Add return airline ticket',
    add_participants: 'Add travelers',
    add_train_ticket: 'Add train ticket',
    add_train_ticket_back: 'Add train ticket back',
    add_train_ticket_to_destination: 'Add train ticket to destination',
    business_trip: 'Business trip',
    cancel: 'No',
    cancel_confirmation: 'Are you sure you want to cancel your trip?',
    cancel_confirmation_with_services:
        "We'll cancel your bookings, but keep in mind that most bookings are non-refundable or are subject to cancelation fees. Please contact support to learn more about possible fees before canceling the trip.",
    cancel_confirmation_reason: "Please tell us why you're canceling the trip request",
    cancel_trip: 'Cancel my trip',
    confirm: 'Yes, cancel',
    documentsHeader: 'Documents',
    fix_application_date: 'You entered past dates. Please correct them and try again.',
    group_trip: 'Group travel request',
    hotelHeader: 'Hotel',
    inner_conference_reminder:
        'A request for a conference in your city was created. For details, see the ticket in the INTERCONF queue',
    offline_trip:
        "You can't book tickets or accommodation in the service. The agent will do it for you based on your comments in the travel request.",
    see_in_tracker: 'Ticket: ',
    ticketsHeader: 'Tickets',
    timeline: 'Timeline',
    tripLoading: 'Loading business trip',
    trip_number: 'Travel request No. {trip_id}',
    yes_understand: 'Yes, I understand',
    conference_without_trip_title: 'Conference without a business trip',
    conference_without_trip_description: 'If plans change and a business trip is needed, you can cancel the application and make a new one',
    cancelled_title: 'Business trip canceled',
    cancelled_description: 'You can create a new one or contact support if this is an error',
    chat_with_support: 'Contact support',
    we_recommend_to_submit: 'We recommend sending all added services for processing immediately. Otherwise, something may run out or prices may change, requiring you to add the services again.',
    we_recomment_to_submit_continuation: 'For details on processing, please refer to the',
    wiki_page: 'wiki page',
};
