export const en = {
    arrival: 'Arrival',
    branded: 'Branded',
    carriage_owner: 'Carrier',
    carriage_type: 'Car type',
    departure: 'Departure',
    electronic_registration: 'Electronic registration',
    time: 'Time',
    train_category: 'Train category',
    train_name: 'Train',
    train_station: 'Train station',
};
