export const en = {
    available_places: 'Available places',
    bottom: 'Bottom',
    bottom_side: 'Bottom side',
    carriage: 'Carriage',
    choose_carriage_class: 'Choose a car class',
    choose_place_for_employee: 'Choose for employee',
    choose_place_for_companion: 'Choose for companion',
    choose_place_error_title: 'It is not possible to select this location',
    choose_place_error_description: "You can't choose a seat in this compartment ({type} compartment)",
    choose_tariff: 'Choose tariff',
    class: 'Class {code}',
    female: 'Female',
    man: 'Male',
    mixed: 'Mixed',
    not_branded: 'not branded',
    not_defined: 'Not defined',
    places_from: 'Places from',
    places_next_step: 'Seat selection will be available at the next step.',
    select: 'Select',
    top: 'Top',
    top_side: 'Top side',
    wo_desc: 'Without description',
    tickets_not_found_title: 'There are no empty seats left',
    tickets_not_found_description: 'It seems someone has already bought this ticket. If an error has occurred, you can ask for help in the chat, or repeat the search again.',
    back_to_search: 'Back to search',
    go_to_trip: 'Go to trip',
    no_passengers_selected: 'Choose places for trip participants',
    passengers_selected: 'Participants who have been selected seats',
    passengers_list: 'Passenger list',
    car_and_seat: 'Car {carNumber} · seat {seatNumber}',
    passengers_added: {
        one: 'added to {count} participant',
        some: 'added to {count} participants',
        many: 'added to {count} participants',
        none: 'Choose places for trip participants',
    },
    choose_this_seat: 'Choose this seat',
    features: 'Features',
    seat: 'Seat {seatNumber}',
    price: 'Price',
    male_compartment: 'Male compartment',
    female_compartment: 'Female compartment',
    add_service_error_message: 'Failed to add service',
};
