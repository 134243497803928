import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_display-l.css';

export type WithTypographyDisplayLProps = {
    typography?: 'display-l';
};

export const withTypographyDisplayL = withBemMod<WithTypographyDisplayLProps>(
    cn(),
    { typography: 'display-l' },
);
