import { AddedServiceState, ServiceAction } from '../HotelDetails.types';

export const servicesAddReducer = (
    state: Partial<AddedServiceState>[],
    action: ServiceAction,
): Partial<AddedServiceState>[] => {
    const { type, payload } = action;

    switch (type) {
        case 'addService':
            return [
                ...state,
                {
                    ...payload,
                    isLoading: true,
                },
            ];

        case 'removeService':
            return state.filter(service => {
                const isSameServiceId = service.serviceId !== undefined && service.serviceId === payload.serviceId;
                const isSamePersonAndRoom =
                    service.roomId === payload.roomId &&
                    service.personId === payload.personId;

                if (isSameServiceId || isSamePersonAndRoom) {
                    return false;
                }

                return true;
            });

        case 'setLoading':
            return state.map(service => {
                if (service.serviceId === payload.serviceId) {
                    return {
                        ...service,
                        isLoading: true,
                    };
                }

                return service;
            });

        case 'unsetLoading':
            return state.map(service => {
                const isSamePersonAndRoom =
                    service.roomId === payload.roomId &&
                    service.roomId === payload.roomId &&
                    service.personId === payload.personId;

                if (isSamePersonAndRoom) {
                    return {
                        ...service,
                        isLoading: false,
                        serviceId: service.serviceId ? service.serviceId : payload.serviceId,
                    };
                }

                return service;
            });
    }
};
