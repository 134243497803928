import { ComponentProps } from 'react';

import { CellStatus } from 'components/TableCells/CellStatus';
import { EmployeeRoleType } from 'services/SwaggerApi';

import { i18n } from '../TableEmployees.i18n';

type StatusValue = ComponentProps<typeof CellStatus>['value'];

type GetStatusByRole = (role: EmployeeRoleType) => StatusValue;

export const getStatusByRole: GetStatusByRole = role => {
    switch (role) {
        case 'assistant':
            return {
                color: 'blue',
                text: i18n(`role_value_${role}`),
            };

        case 'coordinator':
            return {
                color: 'purple',
                text: i18n(`role_value_${role}`),
            };

        default: {
            return {
                color: 'default',
                text: i18n(`role_value_${role}`),
            };
        }
    }
};
