import { ComponentProps, ComponentType } from 'react';
import { compose, composeU } from '@bem-react/core';
import { Text as TextBase, TextColorValue, TextProps as TextBaseProps } from '@yandex-lego/components/Text';

import { withPropsV2 } from 'hocs/withPropsV2';
import { usePlatform } from 'hooks/usePlatform';

import { withTypographyBodyL } from './_typography/Text_typography_body-l';
import { withTypographyBodyM } from './_typography/Text_typography_body-m';
import { withTypographyBodyS } from './_typography/Text_typography_body-s';
import { withTypographyCaptionM } from './_typography/Text_typography_caption-m';
import { withTypographyCaptionS } from './_typography/Text_typography_caption-s';
import { withTypographyDisplayL } from './_typography/Text_typography_display-l';
import { withTypographyDisplayM } from './_typography/Text_typography_display-m';
import { withTypographyDisplayS } from './_typography/Text_typography_display-s';
import { withTypographyHeader } from './_typography/Text_typography_header';
import { withTypographyLabelM } from './_typography/Text_typography_label-m';
import { withTypographyLabelS } from './_typography/Text_typography_label-s';
import { withTypographyParagraphM } from './_typography/Text_typography_paragraph-m';
import { withTypographyParagraphS } from './_typography/Text_typography_paragraph-s';
import { withTypographyTitleL } from './_typography/Text_typography_title-l';
import { withTypographyTitleM } from './_typography/Text_typography_title-m';
import { withTypographyTitleS } from './_typography/Text_typography_title-s';
import { withWeightFat } from './_weight/Text_weight_fat';
import { withWeightMedium } from './_weight/Text_weight_medium';
import { withWeightRegular } from './_weight/Text_weight_regular';

import './Text.css';

type TextColor = TextColorValue | 'inherit';

const TextBundle = compose(
    composeU(
        withWeightFat,
        withWeightMedium,
        withWeightRegular,
    ),
    composeU(
        composeU(
            withTypographyCaptionS,
            withTypographyCaptionM,
            withTypographyBodyS,
            withTypographyBodyM,
            withTypographyBodyL,
            withTypographyParagraphS,
            withTypographyParagraphM,
            withTypographyTitleS,
            // если больше 8 HOC, то типы будут инферрится как any[]
        ),
        composeU(
            withTypographyTitleM,
            withTypographyTitleL,
            withTypographyDisplayS,
            withTypographyDisplayM,
            withTypographyDisplayL,
            withTypographyLabelS,
            withTypographyLabelM,
            withTypographyHeader,
        ),
    ),
)(TextBase as ComponentType<Omit<TextBaseProps, 'typography' | 'weight' | 'color'> & { color?: TextColor }>);

export type TextProps = ComponentProps<typeof TextBundle>;

export const Text = (props: TextProps) => {
    let { typography } = props;
    const { isMobile } = usePlatform();

    if (typography?.startsWith('display-') && isMobile) {
        typography = 'title-m';
    }

    return <TextBundle {...props} typography={typography} />;
};

// typography and optional weight
export const TextTitleS = withPropsV2(Text, { typography: 'title-s' });
export const TextTitleSMedium = withPropsV2(Text, { typography: 'title-s', weight: 'medium' });
export const TextTitleM = withPropsV2(Text, { typography: 'title-m' });
export const TextTitleMRegular = withPropsV2(Text, { typography: 'title-m', weight: 'regular' });
export const TextTitleMMedium = withPropsV2(Text, { typography: 'title-m', weight: 'medium' });
export const TextBodyS = withPropsV2(Text, { typography: 'body-s' });
export const TextBodyM = withPropsV2(Text, { typography: 'body-m' });
export const TextCaptionM = withPropsV2(Text, { typography: 'caption-m' });
export const TextCaptionMRegular = withPropsV2(Text, { typography: 'caption-m', weight: 'regular' });
export const TextBodyMMedium = withPropsV2(Text, { typography: 'body-m', weight: 'medium' });
