import { RoutePoint, RoutePointCreate } from 'services/SwaggerApi';
import { SegmentHotel, SegmentTransport, TripSegment } from 'types/TripSegment';

const mapToRoutePointCreate = (point: RoutePoint): RoutePointCreate => ({
    date: point.date,
    city: point.city.name.ru || '',
    country: point.country.name.ru || '',
    need_hotel: point.need_hotel,
    need_transfer: point.need_transfer,
    geoid: point.geoid || 1,
});

const getTransportSegment = (route: RoutePoint[], routeIndex: number): SegmentTransport => {
    const roundTripAvailable = route.length === 2 && routeIndex === 0;

    const currentPoint = route[routeIndex];
    const nextIndex = (routeIndex + 1) % route.length;
    const nextPoint = route[nextIndex];

    return {
        type: 'transport',
        city_from: currentPoint.city,
        from_id: currentPoint.geoid,
        city_to: nextPoint.city,
        to_id: nextPoint.geoid,
        country_from: currentPoint.country,
        date_from: currentPoint.date,
        date_to: nextPoint.date,
        country_to: nextPoint.country,
        need_transfer: currentPoint.need_transfer,
        services: [],
        events: [],
        round_trip_available: roundTripAvailable,
        getRouteWithToggledTransfer() {
            const updatedRoute = route.map(mapToRoutePointCreate);

            updatedRoute[routeIndex].need_transfer = !currentPoint.need_transfer;

            return updatedRoute;
        },
    };
};

const getHotelSegment = (route: RoutePoint[], routeIndex: number): SegmentHotel => {
    const currentPoint = route[routeIndex];
    const nextPoint = route[routeIndex + 1];

    return {
        to_id: nextPoint.geoid,
        type: 'hotel',
        date_from: currentPoint.date,
        city: nextPoint.city,
        country: nextPoint.country,
        date_to: nextPoint.date,
        need_hotel: currentPoint.need_hotel,
        services: [],
        events: [],
        getRouteWithToggledHotel() {
            const updatedRoute = route.map(mapToRoutePointCreate);

            updatedRoute[routeIndex].need_hotel = !currentPoint.need_hotel;

            return updatedRoute;
        },
    };
};

export const getSegmentsList = (route: RoutePoint[]) => {
    const segments: TripSegment[] = [];

    for (let routeIndex = 0; routeIndex < route.length - 1; routeIndex++) {
        segments.push(getTransportSegment(route, routeIndex));
        segments.push(getHotelSegment(route, routeIndex));
    }

    // Последний город - город отправления, поэтому отель не добавляем
    segments.push(getTransportSegment(route, route.length - 1));

    return segments;
};
