import { FC, useMemo } from 'react';
import { Divider } from '@yandex-lego/components/Divider';

import { CarriageServiceIcon } from 'components/CarriageServiceIcon';
import { HelpTooltip } from 'components/HelpTooltip';
import { TagHint } from 'components/TagHint';
import { TrainCarriage } from 'components/TrainCarriage';
import { i18nCarriageServiceType } from 'i18n/i18nCarriageServiceType';
import { i18nTravelPolicy } from 'i18n/i18nTravelPolicy';
import { TrainCarriageDetail } from 'services/SwaggerApi';
import { DropdownTooltip } from 'shared/ui/DropdownTooltip';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';

import { cn } from './TrainCarriageDetails.cn';
import { i18n, i18nRaw } from './TrainCarriageDetails.i18n';
import { TrainCarriageDetailsProps } from './TrainCarriageDetails.types';

import './TrainCarriageDetails.css';

const PlacesSummary: FC<{ carriage: TrainCarriageDetail; currency: string }> = props => {
    const {
        carriage: {
            max_price,
            min_price,
            place_count,
        },
    } = props;

    const isPriceTheSame = min_price === max_price;
    const priceIs = i18nRaw('price_is', {
        price: formatCurrency(min_price),
    });
    const priceFromTo = i18nRaw('price_from_to', {
        from: formatCurrency(min_price),
        to: max_price ? formatCurrency(max_price) : undefined,
    });

    return (
        <Text as="div" className={cn('PlacesSummary')} typography="body-s">
            {i18n('available_places')}: {place_count}
            {/* вернуть после BTRIP-3341
            Object.entries(summary).filter(([_, v]) => Boolean(v)).map(([k, v]) => (
                <div className={cn('PlacesSummaryEntry')} key={k}>
                    {i18n(k as ACTrainPlacesSummaryKey)}: {v}
                </div>
            ))*/}
            <div>
                {isPriceTheSame ? priceIs : priceFromTo}
            </div>
        </Text>
    );
};

export const TrainCarriageDetails: FC<TrainCarriageDetailsProps> = props => {
    const {
        trainDetails,
        carriage,
        onChange,
        chosenPlaces,
        currentSelectedSeat,
        canAddServices,
    } = props;

    const places = useMemo(() => carriage.places.map(place => place.place_number), [carriage]);

    const travelPolicyViolationsContent = carriage.travel_policy_violations.length > 0 ? (
        <Text className={cn('TooltipText')} typography="caption-m">{carriage.travel_policy_violations.join('\n')}</Text>
    ) : null;

    const classDesc = [i18n('class', { code: carriage.service_class_code || undefined }), carriage.service_class_name].filter(Boolean).join(' · ');

    return (
        <div className={cn(null, [props.className])}>
            <div className={cn('CarriageDescription')}>
                <div className={cn('CarriageRailcar')}>
                    <div className={cn('CarriageRailcarText')}>
                        <Text as="div" typography="title-s">
                            {i18n('carriage')} {carriage.carriage_number}
                        </Text>
                        {
                            carriage.two_storey && (
                                <Text as="div" color="secondary" typography="body-s">
                                    {i18n('two_storey')}
                                </Text>
                            )
                        }
                    </div>
                    {!carriage.is_travel_policy_compliant && (
                        <TagHint
                            inverse
                            color="red"
                            desktopTrigger="hover"
                            hintContent={<Text typography="body-s">{travelPolicyViolationsContent}</Text>}
                            tagSize="s"
                            tagText={i18nTravelPolicy('not_compliant_short')}
                        />
                    )}
                </div>
                <Divider className={cn('DividerMobile')} />
                <div className={cn('CarriageFeatures')}>
                    <Text as="div" typography="body-s">
                        {classDesc}
                        {carriage.service_class_description &&
                            <HelpTooltip
                                inverse
                                className={cn('HelpTooltip')}
                                content={<Text typography="body-s">{carriage.service_class_description}</Text>}
                                iconSize="s"
                            />
                        }
                    </Text>
                    <div className={cn('CarriageServices')}>
                        {(carriage.services || []).map(service => (
                            <DropdownTooltip
                                key={service}
                                inverse
                                content={i18nCarriageServiceType(service)}
                                direction={['top', 'bottom']}
                                trigger={(
                                    <CarriageServiceIcon
                                        key={service}
                                        service={service}
                                        size="m"
                                    />
                                )}
                            />
                        ))}
                    </div>
                </div>
                <Divider className={cn('DividerMobile')} />
                <PlacesSummary carriage={carriage} currency="RUB" />
            </div>
            <TrainCarriage
                availablePlaces={places}
                canAddServices={canAddServices}
                carriageNumber={carriage.carriage_number}
                chosenPlaces={chosenPlaces}
                currentSelectedSeat={currentSelectedSeat}
                train={trainDetails.train}
                onChange={onChange}
            />
        </div>
    );
};

TrainCarriageDetails.displayName = cn();
