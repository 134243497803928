import { GetTypo } from '../Bubble.interface';

export const getTypo: GetTypo = size => {
    switch (size) {
        case 'xs':

        case 's':
            return 'caption-s';

        case 'm':
            return 'caption-s';

        case 'l':
            return 'body-s';

        default:
            return 'body-s';
    }
};
