import { TSuggest_personsQuery } from 'services/SwaggerApi';

import { createUseQueryHook } from './createUseQueryHook';

type UseSuggestPersonsOptions = TSuggest_personsQuery & {
    isEnabled?: boolean;
};

export const useSuggestPersons = (options: UseSuggestPersonsOptions) => {
    const { isEnabled, ...restOptions } = options;

    return createUseQueryHook('suggest_persons', { enabled: isEnabled })({ ...restOptions });
};
