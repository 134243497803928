import { useMemo } from 'react';

import { HotelFilterKey } from 'api/models/filters/HotelFilterKey';
import { HotelFilterOptions } from 'api/models/filters/HotelFilterOptions';
import { FilterItem } from 'services/SwaggerApi';

type GetHotelFilterOptions = () => HotelFilterOptions;

const getHotelFilterOptions: GetHotelFilterOptions = () => ({
    hotel_name: [],
    stars: [],
    hotel_types: [],
    confirmation_type: [],
    payment_places: [],
});

type UsePrepareFilters = (filtersRaw: FilterItem[]) => HotelFilterOptions;

export const usePrepareFilters: UsePrepareFilters = filtersRaw => {
    return useMemo(() => {
        return filtersRaw.reduce((acc, filter) => {
            if (!acc[filter.name as HotelFilterKey] || !filter.values) {
                return acc;
            }

            return Object.assign(acc, { [filter.name]: filter.values });
        }, getHotelFilterOptions());
    }, [filtersRaw]);
};
