import { FC, useCallback } from 'react';

import { SelectAssistantForm } from 'components/SelectAssistantForm';
import { SelectAssistantFormSchema } from 'components/SelectAssistantForm/SelectAssistantForm.types';
import { withModalView } from 'hocs/withModalView';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { useEmployeeAssignAssistant } from './hooks/useEmployeeAssignAssistant';
import { cn } from './EmployeeAssignAssistantModal.cn';
import { i18n } from './EmployeeAssignAssistantModal.i18n';
import { EmployeeAssignAssistantModalProps } from './EmployeeAssignAssistantModal.types';

import './EmployeeAssignAssistantModal.css';

/**
 * Модалка назначения ассистента
 */
const EmployeeAssignAssistantModalPresenter: FC<EmployeeAssignAssistantModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        employee,
        className,
    } = props;

    const {
        company_id,
        employee_id,
    } = employee;

    const [assignAssistant, assigningState, { error }] = useEmployeeAssignAssistant({
        onSuccess: onSubmit,
        options: { invalidateOnSuccess: true },
    });

    const handleSubmit = useCallback((data: SelectAssistantFormSchema) => {
        assignAssistant(company_id, employee_id, {
            assistant_id: data.assistant.id,
        });
    }, [assignAssistant, company_id, employee_id]);
    const loadingState = isLoading(assigningState);

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('assign_assistant')}
            </Text>
            <SelectAssistantForm
                employee={employee}
                error={error}
                progress={loadingState}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

EmployeeAssignAssistantModalPresenter.displayName = cn();

export const EmployeeAssignAssistantModal = withModalView(EmployeeAssignAssistantModalPresenter);
