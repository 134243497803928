import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { fork } from 'effector';
import { Provider as EffectorProvider } from 'effector-react/scope';

import { Root } from 'components/Root';
import { SwaggerApi } from 'services/SwaggerApi';
import { queryClientConfig } from 'shared/constants/queryClientConfig';
import { root } from 'shared/models/root';
import { getBaobabState } from 'utils/baobab/getBaobabState';
import { withBaobabRoot } from 'utils/baobab/withBaobabRoot';

// Day.js локали
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
// RUM
import './utils/rum';
import './utils/error-counter';

import { SwaggerContext, TSwaggerContext } from './SwaggerContext';

// root переменные
import './index.css';

// React Query 3
const queryClient = new QueryClient(queryClientConfig);

// eslint-disable-next-line react-refresh/only-export-components
const BaobabRoot = withBaobabRoot(Root);
const baobabState = getBaobabState();

const context: TSwaggerContext = {
    api: new SwaggerApi(),
};
const scope = fork(root);

ReactDOM.render(
    <StrictMode>
        <EffectorProvider value={scope}>
            <SwaggerContext.Provider value={context}>
                <QueryClientProvider client={queryClient}>
                    <BrowserRouter>
                        <BaobabRoot baobab={baobabState} />
                    </BrowserRouter>
                    <ReactQueryDevtools />
                </QueryClientProvider>
            </SwaggerContext.Provider>
        </EffectorProvider>
    </StrictMode>,
    document.getElementById('root'),
);
