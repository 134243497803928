import { FC, useMemo } from 'react';

import { RouteTimeline } from 'components/RouteTimeline';
import { ServiceCard } from 'components/ServiceCard';
import { usePlatform } from 'hooks/usePlatform';
import { RouteServiceDetails } from 'routes/RouteServiceDetails';
import { transliterate } from 'shared/lib/text/transliterate';
import { ButtonActionM } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { getTpBanner } from 'utils/getTpBanner';
import { getTypedI18nLang } from 'utils/i18n';

import { CarriagePrices } from './CarriagePrices/CarriagePrices';
import { cn } from './TrainSearchCard.cn';
import { i18n } from './TrainSearchCard.i18n';
import { TrainSearchCardProps } from './TrainSearchCard.types';

import './TrainSearchCard.css';

export const TrainSearchCard: FC<TrainSearchCardProps> = props => {
    const { service: train, searchId, personId, tripId } = props;
    const pathDetails = RouteServiceDetails.getPath({ searchId, key: train.id }, {
        type: 'Rail',
        person_id: personId,
        trip_id: tripId,
        name: `${train.train_name || i18n('train')} ${train.train_number}`,
    });

    const language = getTypedI18nLang();
    const trainNameLocale = language === 'ru' ? train.train_name : transliterate(train.train_name || ' ');

    const { isDesktop } = usePlatform();

    const lowestPrice = useMemo(() => Math.min(...train.carriages.map(({ min_price }) => min_price)), [train]);

    const banner = getTpBanner(train);

    return (
        <ServiceCard
            banner={banner}
            contentClassName={cn(null, [props.className])}
        >
            <div className={cn('Info')}>
                <RouteTimeline className={cn('Timeline')} route={train} />
                <Text as="div" typography="caption-m">
                    {i18n('train')} {train.train_number} • {trainNameLocale}
                </Text>
            </div>
            {isDesktop && <div className={cn('Divider')} />}
            <div className={cn('Actions')}>
                <div className={cn('Prices')}>
                    <CarriagePrices carriages={train.carriages} />
                </div>
                <ButtonActionM className={cn('Action')} type="link" url={pathDetails} width="max">
                    <Text color="inherit" weight="medium">
                        {i18n('from')} {formatCurrency(lowestPrice)}
                    </Text>
                </ButtonActionM>
            </div>
        </ServiceCard>
    );
};

TrainSearchCard.displayName = cn();
