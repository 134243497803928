import { City } from 'services/SwaggerApi';
import { TripSegment } from 'types/TripSegment';

export const getSegmentCity = (segment: TripSegment, isDestination = false): City => {
    if (segment.type === 'hotel') {
        return segment.city;
    }

    if (isDestination) {
        return segment.city_to;
    }

    return segment.city_from;
};
