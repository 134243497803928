import { FC } from 'react';

import { Cell } from 'components/Cell';
import { PersonListItem } from 'components/PersonListItem';
import { withTdAttrs } from 'hocs/withTdAttrs';

import { cn } from './CellParticipant.cn';
import { CellParticipantProps } from './CellParticipant.types';

import './CellParticipant.css';

const CellParticipantBase: FC<CellParticipantProps> = props => {
    const { className, value, getAttrs } = props;
    const { login, title } = value;

    return (
        <Cell className={cn(null, [className])} getAttrs={getAttrs} size="m">
            <PersonListItem embed className={cn('ListItem')} login={login} name={title} />
        </Cell>
    );
};

export const CellParticipant = withTdAttrs(CellParticipantBase);
CellParticipant.displayName = cn();
