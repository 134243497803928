import { useMemo } from 'react';

import { config } from 'config';
import { Purpose } from 'services/SwaggerApi';

import { i18n } from '../Purposes.i18n';
import { PurposeOptionGroup } from '../Purposes.types';

import { confKinds } from './confKinds';
import { isOptionDisabled } from './isOptionDisabled';
import { tripKinds } from './tripKinds';

const { isB2B } = config;

export const usePurposeOptions = (purposesData: Purpose[], selectedOptions: number[]) => {
    const result = useMemo(() => purposesData.reduce((acc, purpose) => {
        const UNSET = -1;
        let kindIndex = UNSET;

        // работаем только с conf и trip целями
        // для остального нет групп в селекте, поэтому они будут проигнорированы
        if (confKinds.includes(purpose.kind)) {
            kindIndex = 0;
        } else if (tripKinds.includes(purpose.kind)) {
            kindIndex = 1;
        }

        if (kindIndex !== UNSET) {
            acc[kindIndex].items.push({
                value: purpose.purpose_id,
                kind: purpose.kind,
                content: purpose.name,
                disabled: false,
            });
        }

        return acc;
    }, [
        { title: i18n('conf'), items: [] },
        { title: i18n('trip'), items: [] },
    ] as PurposeOptionGroup[]), [purposesData]);

    const confItems = result[0].items;

    result.forEach(kind => {
        kind.items.forEach(item => {
            item.disabled = isOptionDisabled(item, selectedOptions, confItems);
        });
    });

    if (isB2B) {
        return result.flatMap(optionsGroup => optionsGroup.items);
    }

    return result;
};
