import { FC } from 'react';

import { Table } from 'components/Table';

import { useColumns } from './TableTransactions.hooks/useColumns';
import { cn } from './TableTransactions.cn';
import { TableTransactionsProps } from './TableTransactions.types';

import './TableTransactions.css';

const defaultColumn = {
    width: 160,
};

export const TableTransactions: FC<TableTransactionsProps> = props => {
    const {
        data: { data: transactions },
        actionsCallbacks,
        pageSize,
        pageIndex,
        pageCount,
        className,
    } = props;

    const columns = useColumns(actionsCallbacks);

    return (
        <Table
            hasStickyColumns
            className={cn('Table', [className])}
            columns={columns}
            data={transactions}
            defaultColumn={defaultColumn}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
        />
    );
};
