import { useEffect } from 'react';
import { useWatch } from 'react-hook-form';

type UseWatchCheckboxes = (
    checkboxFields: string[],
    allChecked: boolean,
    toggleAllChecked: (nextValue: boolean) => void
) => void;

export const useWatchCheckboxes: UseWatchCheckboxes = (checkboxFieldNames, allChecked, toggleAllChecked) => {
    const checkboxFieldValues = useWatch({ name: checkboxFieldNames });

    useEffect(() => {
        const shouldBeChecked = checkboxFieldValues.every(Boolean);

        if (shouldBeChecked !== allChecked) {
            toggleAllChecked(shouldBeChecked);
        }
    }, [checkboxFieldValues, allChecked, toggleAllChecked]);
};
