import { SwaggerApi } from 'services/SwaggerApi';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

type FetchCityOptions = {
    api: SwaggerApi;
    value: unknown;
};

export async function fetchCity({ api, value }: FetchCityOptions) {
    const result = await api.get_settlement(value as number)
        .then(throwHTTPErrors).then(response => response)
        .catch(() => null);

    if (!result) {
        return null;
    }

    const { geoid, localized_name, name: suggestName, ...restValues } = result;
    const suggestValue = {
        id: geoid,
        name: localized_name,
        ...restValues,
    };

    return suggestValue;
}
