import { FC } from 'react';

import { Remote } from 'components/Remote';
import { useSearchResultOptionCount } from 'hooks/useSearchResultOptionCount';
import { Text } from 'shared/ui/Text';

import { cn } from '../PageSearch.cn';

import { PageSearchCountProps } from './PageSearchCount.types';

export const PageSearchCount: FC<PageSearchCountProps> = props => {
    const { searchId, query } = props;

    const count = useSearchResultOptionCount(searchId, query);

    return (
        <Remote data={count} fallbackElement={<></>}>
            {({ data: { count } }) => (
                <Text className={cn()} color="disable">
                    {' '}
                    { count || 0}
                    {' '}
                </Text>
            )}
        </Remote>
    );
};
