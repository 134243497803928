import { AnyObjectSchema, reach } from 'yup';
import { Test } from 'yup/lib/util/createValidation';

export const checkIsFieldRequired = (schema: AnyObjectSchema, name: string, values: object): boolean => {
    const isSchemaEmpty = Object.keys(schema.fields).length === 0;

    if (isSchemaEmpty) {
        return false;
    }

    const fieldSchemaTests: Test[] = reach(schema, name)
        .resolve({ parent: values })
        .describe()
        .tests;

    const isFieldRequired = Boolean(fieldSchemaTests.find(test => test.name === 'required'));

    return isFieldRequired;
};
