import { FC } from 'react';
import {
    IInlineNotificationProps as IInlineNotificationBaseProps,
    InlineNotification as InlineNotificationBase } from '@yandex-int/hr-components/InlineNotification/InlineNotification';

import { cn } from './InlineNotification.cn';

import './InlineNotification.css';

type IInlineNotificationProps = IInlineNotificationBaseProps & {
    withButton?: boolean;
};

export const InlineNotification: FC<IInlineNotificationProps> = ({ className, ...props }) => {
    const {
        withButton,
    } = props;

    if (withButton) {
        return (
            <InlineNotificationBase className={cn('WithButton', [className])} {...props} />
        );
    }

    return (
        <InlineNotificationBase className={cn(null, [className])} {...props} />
    );
};
