export const ru = {
    title: 'Обратная связь',
    message_placeholder: 'Опишите вашу проблему',
    email: 'Почта',
    email_placeholder: 'Введите вашу почту',
    email_invalid: 'Неверный формат почты',
    phone: 'Телефон',
    phone_placeholder: 'Введите ваш телефон',
    contact_method_label: 'Как нам связаться с вами?',
    send: 'Отправить',
    close: 'Закрыть',
    we_received_request: 'Мы получили ваш запрос',
    manager_contact_you: 'Наш менеджер скоро с вами свяжется',
    eula: 'Я даю согласие ООО «ЯНДЕКС» на обработку указанных данных для целей обратной связи со мной и информирования о сервисе Яндекс Командировки на условиях',
    eula_policy: 'Политики конфиденциальности',
};
