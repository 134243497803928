import { ErrorName } from './ErrorName';

// Для ошибок, которые в принципе не возможны
// Служит для сужения типов
export class ImpossibleError extends Error {
    public readonly name = ErrorName.ImpossibleError;
    public readonly detail?: unknown;

    public constructor(message?: string, detail?: unknown) {
        super(message);
        this.detail = detail;
    }
}
