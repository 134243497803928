export const en = {
    good_place: 'Good place',
    show_all: 'Show all',
    wi_fi: 'Wi-Fi',
    gym: 'Gym',
    pool: 'Pool',
    laundry: 'Laundry',
    safe: 'Safe',
    room: 'Room service',
    has_restaurant: 'Restaurant',
    cafe: 'Cafe',
    has_bar: 'Bar',
    services_and_facilities: 'Services and facilities',
    popular_services: 'Popular services',
};
