import { FC, FocusEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import { DateRange, DayClickEventHandler, DayFocusEventHandler, DayMouseEventHandler, DayPicker as DayPickerBase } from 'react-day-picker';
import { useToggle } from 'react-use';
import dayjs from 'dayjs';

import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { Drawer } from 'shared/ui/Drawer';
import { Popup } from 'shared/ui/Popup';

import { Caption } from './Caption/Caption';
import { DatePresenter } from './DatePresenter/DatePresenter';
import { Day } from './Day/Day';
import { DrawerHeader } from './DrawerHeader/DrawerHeader';
import { useScrollToSelectedDate } from './hooks/useScrollToSelectedDate';
import { useWatchMonthScroll } from './hooks/useWatchMonthScroll';
import { MonthPicker } from './MonthPicker/MonthPicker';
import { getConfirmButtonText } from './utils/getConfirmButtonText';
import { getMonths } from './utils/getMonths';
import { Weekdays } from './Weekdays/Weekdays';
import { cn } from './DatePicker.cn';
import { RangeDatePickerProps } from './DatePicker.types';

import './DatePicker.css';

/**
 * Поле для выбора двух дат с попапом
 */
export const RangeDatePicker: FC<RangeDatePickerProps> = props => {
    const {
        value: range,
        onChange,
        onBlur,
        state,
        leftInputClassName,
        rightInputClassName,
        leftInputPlaceholder,
        rightInputPlaceholder,
        differentDates,
        showSubText,
    } = props;

    const { isMobile } = usePlatform();

    const months = useMemo(getMonths, []);
    const rangeMemoized = useMemo(() => ([range?.from, range?.to].filter(Boolean)), [range?.from, range?.to]);

    const [isPopupVisible, togglePopupVisible] = useToggle(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handlePopupClose = useCallback(() => {
        togglePopupVisible(false);
        setActiveField(null);
    }, [togglePopupVisible]);

    const [activeMonth, setActiveMonth] = useState(0);

    const { setIsScrolling, datePickerRef } = useWatchMonthScroll({ setActiveMonth, isPopupVisible });

    useScrollToSelectedDate({ date: range?.from, isPopupVisible, datePickerRef });

    const [activeField, setActiveField] = useState<'left' | 'right' | null>(null);

    const handleDayClick = useCallback<DayClickEventHandler>(selectedDayRaw => {
        const from = dayjs(range?.from).startOf('day');
        const to = dayjs(range?.to).startOf('day');
        const selectedDay = dayjs(selectedDayRaw).startOf('day');

        if (differentDates) {
            if (selectedDay.isSame(from)) {
                onChange?.({ from: undefined, to: selectedDayRaw });

                setActiveField('left');

                return;
            }

            if (selectedDay.isSame(to)) {
                onChange?.({ from: selectedDayRaw, to: undefined });

                setActiveField('right');

                return;
            }
        }

        if (activeField === 'left') {
            if (range?.to && selectedDayRaw > range.to) {
                onChange?.({ from: selectedDayRaw });

                setActiveField('right');

                return;
            }

            onChange?.({ from: selectedDayRaw, to: range?.to });
            setActiveField('right');

            if (range?.to && !isMobile) {
                handlePopupClose();
            }
        }

        if (activeField === 'right') {
            if (range?.from && selectedDayRaw < range.from) {
                onChange?.({ from: selectedDayRaw });

                return;
            }

            onChange?.({ from: range?.from, to: selectedDayRaw });

            if (range?.from && !isMobile) {
                handlePopupClose();
            } else {
                setActiveField('left');
            }
        }
    }, [activeField, differentDates, handlePopupClose, isMobile, onChange, range?.from, range?.to]);

    const [hoverRange, setHoverRange] = useState<DateRange>();

    const handleDayMouseEnter = useCallback<DayMouseEventHandler>(day => {
        if (
            range?.from && range.from > day && (!range.to || activeField === 'right') ||
            activeField === 'left' && range?.to && range.to < day
        ) {
            setHoverRange({ from: day, to: undefined });

            return;
        }

        if (activeField === 'left') {
            setHoverRange({ from: day, to: range?.to });
        } else {
            setHoverRange({ from: range?.from, to: day });
        }
    }, [activeField, range?.from, range?.to]);

    const handleDayMouseLeave = useCallback(() => {
        setHoverRange(undefined);
    }, []);

    const currentDate = useMemo(() => new Date(), []);
    const confirmButtonText = getConfirmButtonText(range?.from, range?.to);

    const customComponents = useMemo(() => ({ Caption, Day }), []);

    const handleInputBlur = useCallback<FocusEventHandler>(e => {
        const focusedElement = e.relatedTarget as Element;

        if (!focusedElement) {
            return;
        }

        const isFocusedElementInDatePicker = focusedElement.className.startsWith('DatePicker') ||
        focusedElement.matches('.DatePicker-ConfirmButton') ||
         focusedElement.className.startsWith('rdp') ||
         focusedElement.id === 'ResetButton';

        if (onBlur && !isFocusedElementInDatePicker) {
            onBlur();
            handlePopupClose();
        }
    }, [handlePopupClose, onBlur]);

    const handleDayBlur = useCallback<DayFocusEventHandler>((_, __, e) => {
        if (!('relatedTarget' in e)) {
            return;
        }

        handleInputBlur(e);
    }, [handleInputBlur]);

    const dayPicker = useMemo(() => {
        return (
            <DayPickerBase
                disableNavigation
                hideHead
                components={customComponents}
                defaultMonth={currentDate}
                fromDate={currentDate}
                mode="multiple"
                numberOfMonths={13}
                selected={rangeMemoized}
                weekStartsOn={1}
                onDayBlur={handleDayBlur}
                onDayClick={handleDayClick}
                onDayMouseEnter={isMobile ? undefined : handleDayMouseEnter}
            />
        );
    }, [currentDate, customComponents, handleDayBlur, handleDayClick, handleDayMouseEnter, isMobile, rangeMemoized]);

    return (
        <>
            <div
                ref={anchorRef}
                className={cn('DateFields')}
                tabIndex={0}
            >
                <DatePresenter
                    active={(activeField === 'left' && !hoverRange?.to || Boolean(hoverRange?.from)) && !dayjs(hoverRange?.from).isSame(range?.from) }
                    className={leftInputClassName}
                    placeholder={leftInputPlaceholder}
                    showSubText={showSubText}
                    state={activeField === null ? state : undefined}
                    testId="field-date-start"
                    value={hoverRange ? hoverRange.from : range?.from}
                    onClear={() => onChange?.({ from: undefined, to: undefined })}
                    onClick={() => {
                        togglePopupVisible(true);
                        setActiveField('left');
                    }}
                />
                <DatePresenter
                    active={activeField === 'right' && !hoverRange?.from || Boolean(hoverRange?.to) && !dayjs(hoverRange?.to).isSame(range?.to)}
                    className={rightInputClassName}
                    placeholder={rightInputPlaceholder}
                    showSubText={showSubText}
                    state={activeField === null ? state : undefined}
                    testId="field-date-end"
                    value={hoverRange ? hoverRange.to : range?.to}
                    onClear={() => onChange?.({ from: range?.from, to: undefined })}
                    onClick={() => {
                        togglePopupVisible(true);
                        setActiveField('right');
                    }}
                />
            </div>
            {isMobile ? (
                <Drawer
                    titleComponent={
                        <DrawerHeader
                            activeField={activeField}
                            leftInputPlaceholder={leftInputPlaceholder}
                            range={range}
                            rightInputPlaceholder={rightInputPlaceholder}
                            onChange={onChange}
                        />
                }
                    view="default"
                    visible={isPopupVisible}
                    onClose={handlePopupClose}
                >
                    <div className={cn()}>
                        <Weekdays />
                        <Button
                            className={cn('ConfirmButton')}
                            size="l"
                            view="primary"
                            width="max"
                            onClick={handlePopupClose}
                        >
                            {confirmButtonText}
                        </Button>
                        {dayPicker}
                    </div>
                </Drawer>
            ) : (
                <>
                    <Popup
                        anchor={anchorRef}
                        className={cn('Popup')}
                        target="anchor"
                        view="default"
                        visible={isPopupVisible}
                        onClose={handlePopupClose}
                    >
                        <div className={cn('Wrapper')} onMouseLeave={handleDayMouseLeave}>
                            <MonthPicker
                                activeMonth={activeMonth}
                                months={months}
                                setActiveMonth={setActiveMonth}
                                setIsScrolling={setIsScrolling}
                            />
                            <div ref={datePickerRef} className={cn()}>
                                <Weekdays />
                                {dayPicker}
                            </div>
                        </div>
                    </Popup>
                </>
            )}
        </>
    );
};

RangeDatePicker.displayName = cn();
