import { FC, useCallback } from 'react';
import { useToggle } from 'react-use';

import { Dialog } from 'components/Dialog';
import { getIconProvider, IconTrash } from 'components/Icon';
import { ViewTrainSeat } from 'components/ViewTrainSeat';
import { withModalView } from 'hocs/withModalView';
import { useDeleteService } from 'hooks/useDeleteService';
import { usePlatform } from 'hooks/usePlatform';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { TooltipStateful } from 'shared/ui/TooltipStateful';
import { errorToast } from 'utils/errorToast';

import { cn } from '../ServiceCardFooter.cn';
import { i18n } from '../ServiceCardFooter.i18n';

import { ActionButtonsProps } from './ActionButtons.types';

const iconTrashProvider = getIconProvider(IconTrash);
const ViewTrainSeatModal = withModalView(ViewTrainSeat);

/**
 * Действия с услугой (Удалить / Изменить место / Выбрать место)
 */
export const ActionButtons: FC<ActionButtonsProps> = props => {
    const { service, extraActions = {} } = props;

    const { actions, service_id } = service;

    const {
        delete: deleteAction,
        update,
    } = actions;

    const { shouldRemove, shouldChangeSeat } = extraActions;

    const { isDesktop, isMobile } = usePlatform();
    const [isDeleteDialogVisible, toggleDeleteDialogVisible] = useToggle(false);
    const [isSeatsModalVisible, toggleSeatsModalVisible] = useToggle(false);

    const [deleteService, _, { isLoading }] = useDeleteService({
        onError: e => {
            errorToast(e, { title: i18n('couldnt_delete_service') });
        },
        options: {
            hideErrorNotifier: true,
        },
    });

    const handleDeleteService = useCallback(() => {
        deleteService(service_id);
        toggleDeleteDialogVisible(false);
    }, [deleteService, toggleDeleteDialogVisible, service_id]);

    const canChangeSeat = update && !shouldRemove;
    const showRemoveButtonText = isMobile || shouldRemove;
    const removeServiceButtonContent = <><IconTrash className={cn('ActionIcon')} size="m" />{i18nAction('remove_service')}</>;

    return (
        <div className={cn('ActionButtons')}>
            {canChangeSeat && (
                <>
                    <Button
                        className={cn('ChooseSeat')}
                        data-testid="choose-seat-button"
                        size="m"
                        view={shouldChangeSeat ? 'brand' : 'outline'}
                        width={isDesktop ? 'auto' : 'max'}
                        onClick={toggleSeatsModalVisible}
                    >
                        {i18nAction('change_seat')}
                    </Button>

                    <ViewTrainSeatModal
                        modalViewOnClose={toggleSeatsModalVisible}
                        modalViewVisible={isSeatsModalVisible}
                        service={service}
                        onClose={toggleSeatsModalVisible}
                    />
                </>
            )}

            {deleteAction && (
                <>
                    <TooltipStateful
                        content={i18nAction('remove_service')}
                        direction={['top', 'bottom', 'top-end', 'bottom-end']}
                        enabled={!showRemoveButtonText}
                    >
                        <Button
                            className={cn('Remove')}
                            data-testid="remove-service-button"
                            disabled={isLoading}
                            icon={!showRemoveButtonText ? iconTrashProvider : undefined}
                            size="m"
                            view={shouldRemove ? 'brand' : 'outline'}
                            width={isDesktop ? 'auto' : 'max'}
                            onClick={toggleDeleteDialogVisible}
                        >
                            {showRemoveButtonText && removeServiceButtonContent}
                        </Button>
                    </TooltipStateful>

                    <Dialog
                        close={i18nAction('cancel')}
                        closeButtonView="ghost"
                        confirm={i18nAction('remove')}
                        dataTestId="remove-dialog"
                        subtitle={i18n('remove_hint')}
                        title={i18nAction('remove_service')}
                        visible={isDeleteDialogVisible}
                        onClose={toggleDeleteDialogVisible}
                        onConfirm={handleDeleteService}
                    />
                </>
            )}
        </div>
    );
};

ActionButtons.displayName = cn();
