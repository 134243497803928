import { FC, useCallback } from 'react';
import { isFirefox } from 'react-device-detect';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';

import { useMessenger } from 'hooks/useMessenger';
import { useMeta } from 'hooks/useMeta';
import { openURLInBrowser } from 'platform/bridge-methods';
import { isIOS } from 'platform/utils';
import { getMessengerUrl } from 'utils/urls';

import { cn } from './SupportChatButton.cn';
import { i18n } from './SupportChatButton.i18n';
import { SupportChatButtonProps } from './SupportChatButton.types';

export const SupportChatButton: FC<SupportChatButtonProps> = props => {
    const {
        className,
        children,
        ...buttonProps
    } = props;

    const { user: { chat_id } } = useMeta();

    const chatUrl = getMessengerUrl(chat_id);

    const { openWidget } = useMessenger();

    const openUrlInNewTab = useCallback(() => {
        openURLInBrowser(chatUrl, !isIOS());
    }, [chatUrl]);

    const shouldOpenInWindow = isFirefox || isIOS();

    return (
        <Button
            className={cn(null, [className])}
            size="m"
            view="primary"
            onClick={shouldOpenInWindow ? openUrlInNewTab : openWidget}
            {...buttonProps}
        >
            {children ? children : i18n('support_chat')}
        </Button>
    );
};

SupportChatButton.displayName = cn();
