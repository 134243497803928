import { FC } from 'react';

import { InlineNotification } from 'shared/ui/InlineNotification';
import { Text } from 'shared/ui/Text';

import { cn } from './ErrorFallback.cn';
import { ErrorFallbackProps } from './ErrorFallback.types';

import './ErrorFallback.css';

export const ErrorFallback: FC<ErrorFallbackProps> = props => {
    const {
        error,
        className,
    } = props;

    return (
        <InlineNotification
            className={cn(null, [className])}
            icon="error"
            view="error"
        >
            <Text color="inherit" weight="medium">
                {error.name}:
            </Text>
            {' '}
            {error.message}
        </InlineNotification>
    );
};

ErrorFallback.displayName = cn();
