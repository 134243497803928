import * as yup from 'yup';

import { i18nErrors } from 'i18n/i18nErrors';

import { i18n } from './InvoiceForm.i18n';
import { InvoiceFormType } from './InvoiceForm.types';

const positiveDigits = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;

export const schema: yup.SchemaOf<InvoiceFormType> = yup.object({
    amount: yup
        .string()
        .trim()
        .required(i18nErrors('required_field'))
        .matches(positiveDigits, () => i18n('digits_only')),
});
