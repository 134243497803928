import { FC } from 'react';

import { Card } from 'components/Card';
import { FieldSelect, FieldTextinput } from 'components/FormField2';
import { useAvailableTravelPolicies } from 'hooks/useAvailableTravelPolicies';
import { foldSuccess } from 'utils/Loadable';

import { cn } from '../EmployeesGroupForm.cn';
import { i18n } from '../EmployeesGroupForm.i18n';
import { getTravelPolicyOptions } from '../EmployeesGroupForm.utils/getTravelPolicyOptions';

import { SettingsProps } from './Settings.types';

import './Settings.css';

export const Settings: FC<SettingsProps> = () => {
    const travelPolicyList = useAvailableTravelPolicies();

    const options = foldSuccess(travelPolicyList, value => value, () => []);

    return (
        <Card title={i18n('settings')}>
            <div className={cn('Fieldset')}>
                <FieldTextinput
                    label={i18n('group_name')}
                    name="group_name"
                    placeholder={i18n('group_name_placeholder')}
                />
                <FieldSelect
                    label={i18n('travel_policy')}
                    name="travel_policy"
                    options={getTravelPolicyOptions(options)}
                    placeholder={i18n('travel_policy_placeholder')}
                    view="outline"
                />
            </div>
        </Card>
    );
};

Settings.displayName = cn();
