import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_weight_regular.css';

export interface WithWeightRegularProps {
    weight?: 'regular';
}

export const withWeightRegular = withBemMod<WithWeightRegularProps>(cn(), {
    weight: 'regular',
});
