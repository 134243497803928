import { isLeft } from 'fp-ts/lib/Either';
import * as t from 'io-ts';

import { config } from 'config';
import { DecodeError } from 'errors/DecodeError';
import { reportValidationErrors } from 'utils/reportValidationErrors';

import { logError } from './logError';

export function decode<T, U>(
    input: T,
    type: t.Type<U>,
): U {
    const decoded = type.decode(input);

    if (isLeft(decoded)) {
        const validationErrors = reportValidationErrors(decoded);

        if (config.env === 'production') {
            logError(new DecodeError(validationErrors.join('\n')));
        } else {
            console.warn(`Error decoding ${type.name}`);
            console.warn(validationErrors);
        }
    }

    return input as unknown as U;
}
