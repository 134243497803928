export function getPlatformValue<TKey extends keyof PlatformConfig>(key: TKey): PlatformConfig[TKey] {
    const platform = window.YC?.platform || ({} as Partial<PlatformConfig>);

    const value = platform[key] as PlatformConfig[TKey];

    if (value !== undefined) {
        return value;
    }

    switch (key) {
        case 'id':
            return 'browser' as PlatformConfig[TKey];

        case 'appVersion':
            return '0.0.0' as PlatformConfig[TKey];

        case 'darkTheme':
            return false as PlatformConfig[TKey];

        case 'features':
            return {
                basic: false,
                notifications: false,
                payment: false,
            } as PlatformConfig[TKey];

        case 'deviceName':

        case 'osVersion':

        case 'uuid':

        case 'lang':
            return '' as PlatformConfig[TKey];

        default:
            return undefined as PlatformConfig[TKey];
    }
}
