import React, { FC } from 'react';
import { Progress } from '@yandex-lego/components/Progress/Progress';

import { usePlatform } from 'hooks/usePlatform';
import { Text } from 'shared/ui/Text';
import { TooltipStateful } from 'shared/ui/TooltipStateful';

import { cn } from '../HotelDetails.cn';
import { i18n } from '../HotelDetails.i18n';

import { getColorProgress } from './getProgressStyle';
import { RatingProgressProps } from './Rating.types';

export const RatingProgress: FC<RatingProgressProps> = props => {
    const { value, title, totalCount } = props;

    const { isDesktop } = usePlatform();

    return (
        <div className={cn('RatingProgress')}>
            <div className={cn('RatingProgressInfo')}>
                <Text typography="body-s">
                    {title}
                </Text>
                <TooltipStateful
                    content={i18n('based_on_reviews', { count: totalCount })}
                    openDelay={isDesktop ? 300 : 0}
                >
                    <span>
                        <Text color="secondary" typography="body-s">
                            {value}%
                        </Text>
                    </span>
                </TooltipStateful>
            </div>
            <Progress
                maxValue={100}
                style={{
                    '--progress-inner-fill-color': getColorProgress(value),
                    '--progressbar-height': '4px',
                } as React.CSSProperties}
                value={value}
            />
        </div>
    );
};
