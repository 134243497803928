import { FC } from 'react';

import { Text } from 'shared/ui/Text';

import { cn } from '../DatePicker.cn';
import { i18n } from '../DatePicker.i18n';

const weekdays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;

export const Weekdays: FC = () => {
    return (
        <Text className={cn('WeekDays')} typography="caption-s">
            {weekdays.map(day => (
                <span key={day} className={cn('WeekDay')}>
                    {i18n(day)}
                </span>
            ))}
        </Text>
    );
};
