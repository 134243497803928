import * as t from 'io-ts';
import * as E from 'io-ts/Encoder';

export const TripsFiltersEncoder = E.partial({
    date_from__lte: t.string,
    date_from__gte: t.string,
    tracker_issue: t.string,
    city_to: t.number,
    person: t.string,
    manager: t.string,
});

export type TripsFiltersEncoder = E.TypeOf<typeof TripsFiltersEncoder>
