import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_paragraph-s.css';

export type WithTypographyParagraphSProps = {
    typography?: 'paragraph-s';
};

export const withTypographyParagraphS = withBemMod<WithTypographyParagraphSProps>(
    cn(),
    { typography: 'paragraph-s' },
);
