import { FC, useMemo } from 'react';

import { Card } from 'components/Card';
import { Participants } from 'components/Participants';
import { TripStatus } from 'components/TripStatus';
import { TripTickets } from 'components/TripTickets';
import { UsersGroupTrip } from 'components/UsersGroupTrip';
import { config } from 'config';
import { usePathToTrip } from 'hooks/usePathToTrip/usePathToTrip';
import { Text } from 'shared/ui/Text';
import { getTripCompactDates } from 'utils/getTripCompactDates';
import { getTripTitle } from 'utils/getTripTitle';
import { getParticipants } from 'utils/personToParticipant';

import { cn } from './TripInfoGroup.cn';
import { TripInfoGroupProps } from './TripInfoGroup.types';

import './TripInfoGroup.css';

const { isB2B } = config;

export const TripInfoGroup: FC<TripInfoGroupProps> = props => {
    const { trip, showTickets, showDetails } = props;

    const isGroupTrip = Number(trip.person_trips?.length) > 1;

    const participants = useMemo(() =>
        getParticipants(trip.person_trips),
    [trip.person_trips]);

    const purposes = useMemo(() => trip.purposes.map(purpose => (
        <div key={purpose.purpose_id} className={cn('Purpose')}>{purpose.name}</div>
    )), [trip.purposes]);

    const routeDates = getTripCompactDates(trip);

    const tripTitle = getTripTitle(trip);

    const { getPathToTrip } = usePathToTrip();
    const tripPath = getPathToTrip({ tripId: trip.trip_id, personTrips: trip.person_trips });

    return (
        <Card
            className={cn({ isCardClickable: true }, [props.className])}
            href={tripPath}
        >
            <div className={cn('GroupTripDetails')}>
                <div className={cn('Header')}>
                    <TripStatus trip={trip} />
                    <div className={cn('Title')}>
                        <Text as="div" className={cn('Cities')} typography="title-s" weight="medium">
                            {tripTitle}
                        </Text>
                        <Text
                            as="div"
                            className={cn('Dates')}
                            color="secondary"
                            typography="body-s"
                        >
                            {routeDates}
                        </Text>
                    </div>
                </div>

                <Text as="div" className={cn('Purposes')} typography="body-m">
                    {purposes}
                </Text>

                {showDetails && trip.comment && (
                    <Text as="div" className={cn('Comment')} color="secondary" typography="paragraph-s">
                        {trip.comment}
                    </Text>
                )}

                {showTickets && !isB2B && (
                    <TripTickets trip={trip} />
                )}
            </div>

            <div className={cn('GroupTripParticipants')}>
                {isGroupTrip ?
                    <UsersGroupTrip trip={trip} /> :
                    <Participants participants={participants} />
                    }
            </div>
        </Card>
    );
};

TripInfoGroup.displayName = cn();
