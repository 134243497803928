import { createUseQueryHook } from './createUseQueryHook';

export type UseCostCenterListOptions = {
    isEnabled?: boolean;
}

export const useCostCenterList = (
    companyId: number,
    queryParams = {},
    options: UseCostCenterListOptions = {},
) => {
    const { isEnabled = true } = options;

    return createUseQueryHook(
        'cost_center_list',
        { enabled: isEnabled },
    )(companyId, queryParams);
};
