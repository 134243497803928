import { FC, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from '@yandex-int/hr-components/Link/desktop/bundle';

import { FieldCheckbox, FieldRadioButton, FieldTextarea, FieldTextinput, FormProvider } from 'components/FormField2';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { useSendFeedback } from './RegistrationFeedbackForm.hooks/useSendFeedback';
import { cn } from './RegistrationFeedbackForm.cn';
import { i18n } from './RegistrationFeedbackForm.i18n';
import { contactMethods, schema } from './RegistrationFeedbackForm.schema';
import { ContactMethod, HandleSubmit, RegistrationFeedbackFormFields, RegistrationFeedbackFormProps } from './RegistrationFeedbackForm.types';

import './RegistrationFeedbackForm.css';

const noop = () => {};

const getContactMethodOption = (value: ContactMethod) => ({ value, children: i18n(value) });

export const RegistrationFeedbackForm: FC<RegistrationFeedbackFormProps> = props => {
    const {
        className,
        defaultValues,
        onClose = noop,
    } = props;

    const methods = useForm<RegistrationFeedbackFormFields>({
        defaultValues: {
            ...defaultValues,
            contactMethod: 'email',
            eulaAccepted: false,
        },
        resolver: yupResolver(schema),
    });

    const [isSentSuccess, setIsSentSuccess] = useState(false);

    const handleSubmitSuccess = useCallback(() => {
        setIsSentSuccess(true);
    }, [setIsSentSuccess]);

    const [sendFeedback, state, { error }] = useSendFeedback({
        onSuccess: handleSubmitSuccess,
        options: { hideValidationErrors: true },
    });

    const handleSubmit: HandleSubmit = values => {
        sendFeedback(values);
    };

    const contactMethod = methods.watch('contactMethod');

    const contactMethodOptions = useMemo(() => contactMethods.map(getContactMethodOption), []);

    if (isSentSuccess) {
        return (
            <div className={cn(null, [className])}>
                <Text
                    as="div"
                    className={cn('Title')}
                    typography="title-m"
                >
                    {i18n('we_received_request')}
                </Text>
                <Text
                    as="div"
                    className={cn('Description')}
                    color="secondary"
                    typography="body-s"
                >
                    {i18n('manager_contact_you')}
                </Text>
            </div>
        );
    }

    return (
        <FormProvider error={error} methods={methods} schema={schema}>
            <form
                className={cn(null, [className])}
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <Text
                    as="div"
                    className={cn('Title')}
                    typography="title-m"
                >
                    {i18n('title')}
                </Text>
                <FieldTextarea
                    className={cn('Message')}
                    name="message"
                    placeholder={i18n('message_placeholder')}
                />
                <FieldRadioButton
                    className={cn('ContactMethod')}
                    label={i18n('contact_method_label')}
                    name="contactMethod"
                    options={contactMethodOptions}
                />
                {contactMethod === 'email' && (
                <FieldTextinput
                    className={cn('Email')}
                    inputMode="email"
                    name="email"
                    placeholder={i18n('email_placeholder')}
                />
                )}
                {contactMethod === 'phone' && (
                <FieldTextinput
                    className={cn('Phone')}
                    inputMode="tel"
                    name="phone"
                    placeholder={i18n('phone_placeholder')}
                />
                )}
                <FieldCheckbox
                    checkboxLabel={
                        <Text
                            typography="caption-m"
                        >
                            {i18n('eula')}{' '}
                            <Link
                                href="https://yandex.ru/legal/confidential/"
                                target="_blank"
                                theme="link"
                            >
                                {i18n('eula_policy')}
                            </Link>
                        </Text>
                        }
                    className={cn('EulaAccepted')}
                    errorClassName={cn('EulaAcceptedError')}
                    name="eulaAccepted"
                />
                <div className={cn('Controls')}>
                    <Button
                        className={cn('Close')}
                        size="m"
                        view="clear"
                        onClick={onClose}
                    >
                        {i18n('close')}
                    </Button>
                    <Button
                        className={cn('Submit')}
                        progress={isLoading(state)}
                        size="m"
                        type="submit"
                        view="brand"
                    >
                        {i18n('send')}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

RegistrationFeedbackForm.displayName = cn();
