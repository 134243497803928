import { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Bubble } from 'components/Bubble';
import { HeaderPage } from 'components/HeaderPage';
import { Page } from 'components/Page';
import { config } from 'config';
import { withRemote } from 'hocs/withRemote';
import { useMeta } from 'hooks/useMeta';
import { usePersonDetails } from 'hooks/usePersonDetails';
import { RouteMyActiveTrips } from 'routes/RouteMyActiveTrips';
import { ProfileTab, RouteProfile } from 'routes/RouteProfile';
import { PersonDetails } from 'services/SwaggerApi';
import { Container } from 'shared/ui/Container';
import { ErrorState } from 'shared/ui/ErrorState';
import { Text } from 'shared/ui/Text';

import { cn } from './PageProfile.cn';
import { i18n } from './PageProfile.i18n';
import { PageProfileRendererProps } from './PageProfile.types';
import { TabAccess } from './TabAccess';
import { TabCompanions } from './TabCompanions';
import { TabDocuments } from './TabDocuments';
import { TabLoyaltyCards } from './TabLoyaltyCards';
import { TabProfile } from './TabProfile';
import { TabSettings } from './TabSettings';
import { TabTravelPolicy } from './TabTravelPolicy';

import './PageProfile.css';

const tabComponents: { [key in ProfileTab]: FC<{ personId: string; personDetails: PersonDetails }> } = {
    profile: TabProfile,
    documents: TabDocuments,
    loyalty_cards: TabLoyaltyCards,
    companions: TabCompanions,
    access: TabAccess,
    travel_policy: TabTravelPolicy,
    settings: TabSettings,
};

export const PageProfileRenderer: FC<PageProfileRendererProps> = props => {
    const {
        personDetails,
        personId,
        activeTab,
        isAccessTabVisible,
    } = props;

    const {
        login,
        first_name,
        last_name,
        email,
        travel_policy_id,
    } = personDetails;

    const { isB2B } = config;

    const {
        user: {
            consent: { status },
            is_coordinator,
            is_accountant,
            is_assistant,
            is_chief,
            is_tp_enabled,
            ext_persons_feature_enabled,
        },
    } = useMeta();

    const canEditAnalyticFields = isB2B && (is_coordinator || is_accountant);
    const showGapVisibilityTumbler = !isB2B && (is_assistant || is_chief);
    const isPDSigned = !isB2B && status === 'signed';
    const isSettingsTabVisible = canEditAnalyticFields || showGapVisibilityTumbler || isPDSigned;
    const isTravelPolicyTabVisible = travel_policy_id !== null && is_tp_enabled;

    const tabsList: ProfileTab[] = useMemo(
        () =>
            [
                'profile',
                'documents',
                'loyalty_cards',
                ext_persons_feature_enabled && 'companions',
                isAccessTabVisible && 'access',
                isTravelPolicyTabVisible && 'travel_policy',
                isSettingsTabVisible && 'settings',
            ].filter(Boolean)
        , [ext_persons_feature_enabled, isAccessTabVisible, isTravelPolicyTabVisible, isSettingsTabVisible]);

    const tabs = useMemo(
        () => tabsList.map(tab => ({
            id: tab,
            text: i18n(tab),
            href: RouteProfile.getPath({ personId }, { tab }),
        }))
        , [personId, tabsList]);

    const ActiveTab = tabComponents[activeTab];

    const userInfo = (
        <div className={cn('UserInfo')}>
            <Bubble
                bubble={{ login, personId, name: `${first_name} ${last_name}`, type: 'person' }}
                className={cn('Avatar')}
                size="l"
                type="person"
            />
            <div className={cn('Fio')}>
                <Text typography="display-s">{first_name} {last_name}</Text>
                <Text
                    color="secondary"
                    typography="body-m"
                >
                    {email}
                </Text>
            </div>
        </div>
    );

    return (
        <Page className={cn()}>
            <HeaderPage
                activeTab={activeTab}
                tabs={tabs}
                title={userInfo}
            />
            <Container className={cn('Content')}>
                <ActiveTab personDetails={personDetails} personId={personId} />
            </Container>
        </Page>
    );
};

const PageProfileRendererRemote = withRemote(PageProfileRenderer);

export const PageProfile: Page<RouteProfile> = props => {
    const {
        params: { personId: personIdParam },
        search: { tab = 'profile' },
    } = props;
    const { user: { person_id, is_assistant, is_chief } } = useMeta();
    const personId = personIdParam ?? person_id;

    const personDetails = usePersonDetails(personId);

    const { push } = useHistory();

    const isAccessTabVisible = Boolean(is_assistant || is_chief);

    const onActionClick = useCallback(() => {
        push(RouteMyActiveTrips.getPath({}, {}));
    }, [push]);

    return (
        <PageProfileRendererRemote
            activeTab={tab}
            fallbackElement={
                <div className={cn()}>
                    <div className={cn('Error')}>
                        <ErrorState
                            withChatSupport
                            actionTitle={i18n('to_the_main_action')}
                            description={i18n('not_found_error_description')}
                            imageType="not_found"
                            title={i18n('not_found_error_title')}
                            onActionClick={onActionClick}
                        />
                    </div>
                </div>
            }
            isAccessTabVisible={isAccessTabVisible}
            personDetails={personDetails}
            personId={personId}
        />
    );
};

PageProfile.displayName = cn();
