export const en = {
    add_passport: 'Add passport',
    couldnt_add_passport: "Couldn't add passport to the service",
    couldnt_change_passport: "Couldn't change the passport for the service",
    couldnt_delete_service: "Couldn't delete the service",
    executes_service_hint: 'You cannot change your passport after the service has been issued',
    select_passport: 'Select passport',
    remove_hint: 'This action cannot be undone. You will be able to add a new service after removing this one',
    choose_documents: 'Choose documents',
    change_documents: 'Change documents',
    can_not_change_documents: 'Documents cannot be changed',
    view_documents: 'View documents',
    documents_title: 'Documents',
    passengers_without_docs: {
        one: '{count} passenger do not have a document selected',
        some: '{count} passengers do not have a document selected',
        many: '{count} passengers do not have a document selected',
        none: 'Edit selected documents',
    },
};
