export const ru = {
    trip1: 'Услуги будут оформлены только после подтверждения командировки',
    trip2: 'Не забудьте скачать плейлист в дорогу',
    trip3: 'В 10-м и 20-м вагонах Сапсана есть wifi и розетки',
    trip4: 'В блок с документами можно добавить бонусные карты для использования в поездке',
    trip5: 'Чтобы купить билет на Аэроэкспресс, напишите в чат поддержки',
    trip6: 'Не забудьте собрать чемодан',
    trip7: 'В отеле можно запросить зубную щетку, халат и тапочки, если их сразу не положили в номер',
    trip8: 'Для оформления билета с багажом выберите в фильтрах нужные условия',
    trip9: 'Лучшие по стоимости билеты можно оформить за 14 дней до вылета',
};
