import { uuidv4 } from 'shared/lib/uuidv4';

import { responseValueParser } from './responseValueParser';
import { sync } from './sync';

export function async<T>(nativeMethodName: string) {
    const callbackId = uuidv4();

    window.BRIDGE = window.BRIDGE ?? {};

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (payload: any): Promise<T> => new Promise((resolve, reject) => {
        window.BRIDGE[callbackId] = {
            resolve: responseValueParser.bind(null, resolve),
            reject: responseValueParser.bind(null, reject),
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        sync(nativeMethodName)({ payload, callbackId });
    });
}
