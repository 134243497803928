import { FC, useCallback } from 'react';

import { SelectCompanyForm } from 'components/SelectCompanyForm';
import { withModalView } from 'hocs/withModalView';
import { withRemote } from 'hocs/withRemote';
import { useAvailableCompanies } from 'hooks/useAvailableCompanies';
import { i18nAction } from 'i18n/i18nAction';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { useEmployeeChangeCompany } from './EmployeeChangeCompanyModal.hooks/useEmployeeChangeCompany';
import { cn } from './EmployeeChangeCompanyModal.cn';
import { i18n } from './EmployeeChangeCompanyModal.i18n';
import { EmployeeChangeCompanyModalProps, HandleSubmit } from './EmployeeChangeCompanyModal.types';

import './EmployeeChangeCompanyModal.css';

const SelectCompanyFormRemote = withRemote(SelectCompanyForm);

const EmployeeChangeCompanyModalPresenter: FC<EmployeeChangeCompanyModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        className,
        employee,
    } = props;

    const {
        employee_id,
        company_id,
    } = employee;

    const availableCompanies = useAvailableCompanies();
    const defaultValues = { company_id };
    const [changeCompany, changingState, { error }] = useEmployeeChangeCompany({
        onSuccess: onSubmit,
        options: {
            invalidateOnSuccess: true,
            hideValidationErrors: true,
        },
    });

    const isChanging = isLoading(changingState);

    const handleSubmit = useCallback<HandleSubmit>(formFields => {
        changeCompany(company_id, employee_id, {
            new_company_id: formFields.company_id,
        });
    }, [changeCompany, company_id, employee_id]);

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <SelectCompanyFormRemote
                availableCompanies={availableCompanies}
                buttonSubmitText={i18nAction('save')}
                defaultValues={defaultValues}
                employee={employee}
                error={error}
                progress={isChanging}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

EmployeeChangeCompanyModalPresenter.displayName = cn();

export const EmployeeChangeCompanyModal = withModalView(EmployeeChangeCompanyModalPresenter);
