import { FC } from 'react';

import { Text } from 'shared/ui/Text';

import { cn } from './ItemsGroup.cn';
import { ItemsGroupProps } from './ItemsGroup.types';

import './ItemsGroup.css';

export const ItemsGroup: FC<ItemsGroupProps> = props => {
    const {
        items,
        size = 'm',
        maxItems = 3,
    } = props;

    const hasExtraItems = items.length > maxItems;
    const visibleItems = hasExtraItems ? items.slice(0, maxItems - 1) : items;

    const extraItemsCount = items.length - maxItems + 1;

    return (
        <div
            className={cn(null, [props.className])}
            data-testid="items-group"
        >
            {visibleItems.map((item, index) => (
                <div key={index} className={cn('Item')}>
                    {item}
                </div>
            ))}
            {hasExtraItems && (
                <div className={cn('More')}>
                    <Text
                        align="center"
                        as="div"
                        className={cn('MoreText', { size })}
                        color="primary"
                        typography="body-s"
                    >
                        +{extraItemsCount}
                    </Text>
                </div>
            )}
        </div>
    );
};

ItemsGroup.displayName = cn();
