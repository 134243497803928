export const en = {
    persons_placeholder: 'Start typing name or email',
    countries_placeholder: 'Start typing country name',
    bonus_placeholder: 'Start typing bonus card name',
    companies_placeholder: 'Start typing company name',
    kontur_companies_placeholder: 'Start entering the INN or company name',
    cities_placeholder: 'Start typing city name',
    travel_policies_placeholder: 'Start typing a travel policy name',
    inn: 'INN',
    kpp: 'KPP',
};
