import { FC } from 'react';

import { IconStarChecked } from 'components/Icon';

import { cn } from './HotelStars.cn';
import { HotelStarsProps } from './HotelStars.types';

import './HotelStars.css';

const fiveStars = Array(5).fill(null);

export const HotelStars: FC<HotelStarsProps> = props => {
    const {
        className,
        count,
        showAll,
        colorful,
    } = props;

    const shownStars = showAll ? fiveStars : fiveStars.slice(0, count);

    return (
        <div className={cn(null, [className])}>
            {shownStars.map((_, index) => (
                <IconStarChecked
                    key={index}
                    className={cn('Star', { colorful: colorful && index < count })}
                    size="m"
                />
            ))}
        </div>
    );
};

HotelStars.displayName = cn();
