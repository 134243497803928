import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import { DocumentForm } from 'components/DocumentForm';
import { FieldSelect, FormProvider } from 'components/FormField2';
import { getIconProvider, IconPlus } from 'components/Icon';
import { withModalView } from 'hocs/withModalView';
import { withRemote } from 'hocs/withRemote';
import { usePersonDetails } from 'hooks/usePersonDetails';
import { usePlatform } from 'hooks/usePlatform';
import { i18nDocumentType } from 'i18n/TT/i18nDocumentType';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { TooltipStateful } from 'shared/ui/TooltipStateful';

import { cn } from '../ServiceCardFooter.cn';
import { i18n } from '../ServiceCardFooter.i18n';

import { useApplyDefaultDocument } from './DocumentsActions.hooks/useApplyDefaultDocument';
import { useWatchDocument } from './DocumentsActions.hooks/useWatchDocument';
import { getDocumentOptions } from './DocumentsActions.utils/getDocumentOptions';
import { DocumentsActionsProps, DocumentsActionsViewProps, DocumentSelectProps, ExtPersonWithID } from './DocumentsActions.types';

const iconPlusProvider = getIconProvider(IconPlus);
const DocumentFormModalRemote = withRemote(withModalView(DocumentForm));

const DocumentsActionsView: FC<DocumentsActionsViewProps> = props => {
    const {
        attachedDocument,
        hasOptions,
        canChangeDocument,
        options,
        isMobile,
        showDocumentTooltip,
        toggleDocumentsModalVisible,
        isDocumentsModalVisible,
        personDetails,
        person,
        service,
        actions,
        personType,
    } = props;

    const { change_document } = actions;
    const { document_id } = service;

    const methods = useForm<DocumentSelectProps>({
        defaultValues: { document_id },
    });

    useApplyDefaultDocument(methods, document_id);

    const { changeDocument } = useWatchDocument({ methods, service, change_document });

    const onDocumentFormSubmit = useCallback((documentId: number) => {
        toggleDocumentsModalVisible();

        changeDocument(service.service_id, {}, { document_id: documentId });
    }, [changeDocument, service.service_id, toggleDocumentsModalVisible]);

    if (attachedDocument) {
        return (
            <div className={cn('AttachedDocument')}>
                <Text typography="body-s">
                    {`${i18nDocumentType(attachedDocument.type)}, ${attachedDocument.document_number}`}
                </Text>
                <Text color="secondary" typography="caption-m">
                    {i18n('executes_service_hint')}
                </Text>
            </div>
        );
    }

    const addPassportButtonContent = <><IconPlus className={cn('AddPassportIcon')} size="m" />{i18n('add_passport')}</>;

    return (
        <div className={cn('DocumentsActions', { hasOptions })} data-testid="documents-actions">
            {hasOptions && (
                <FormProvider methods={methods}>
                    <FieldSelect
                        hideOverflow
                        className={cn('DocumentsSelect')}
                        disabled={!canChangeDocument}
                        name="document_id"
                        options={options}
                        placeholder={i18n('select_passport')}
                        width={isMobile ? 'max' : 'auto'}
                    />
                </FormProvider>
            )}

            {canChangeDocument && (
                <TooltipStateful
                    content={i18n('add_passport')}
                    direction={['top', 'bottom']}
                    enabled={showDocumentTooltip}
                >
                    <Button
                        className={cn('AddPassport')}
                        data-testid={'add-passport-button'}
                        icon={hasOptions ? iconPlusProvider : undefined}
                        size="m"
                        view="outline"
                        onClick={toggleDocumentsModalVisible}
                    >
                        {!hasOptions && addPassportButtonContent}
                    </Button>
                </TooltipStateful>
            )}

            <DocumentFormModalRemote
                modalViewOnCloseOff
                modalViewOnClose={toggleDocumentsModalVisible}
                modalViewVisible={isDocumentsModalVisible}
                personInfo={person || personDetails}
                personType={personType}
                onCancel={toggleDocumentsModalVisible}
                onSubmit={onDocumentFormSubmit}
            />
        </div>
    );
};

/**
 * Селект выбора документа и кнопка добавления нового документа
 */
export const DocumentsActions: FC<DocumentsActionsProps> = props => {
    const {
        allowedDocuments,
        service,
        serviceDetails,
        extService,
    } = props;

    const {
        person,
        actions,
    } = service;

    const { isMobile, isDesktop } = usePlatform();

    const [isDocumentsModalVisible, toggleDocumentsModalVisible] = useToggle(false);
    const personDetails = usePersonDetails(service.person_id, { isEnabled: !person });

    const isTravellerDocument = Array.isArray(allowedDocuments);
    const isExtPerson = Boolean(extService);

    const extPersonInfo: ExtPersonWithID = { ...extService?.ext_person, person_id: extService?.ext_person_uuid || '' };

    const { change_document } = actions;

    const options = isTravellerDocument ?
        getDocumentOptions(allowedDocuments.filter(document =>
            document.ext_person_uuid === extService?.ext_person_uuid,
        )) :
        getDocumentOptions(allowedDocuments.person_documents);
    const hasOptions = options.length > 0;

    const attachedDocument = serviceDetails.travellers[0]?.documents[0];
    const canChangeDocument = change_document;
    const showDocumentTooltip = hasOptions && isDesktop;

    return <DocumentsActionsView
        actions={actions}
        attachedDocument={attachedDocument}
        canChangeDocument={canChangeDocument}
        hasOptions={hasOptions}
        isDocumentsModalVisible={isDocumentsModalVisible}
        isMobile={isMobile}
        options={options}
        person={isExtPerson ? extPersonInfo : person}
        personDetails={isExtPerson ? extPersonInfo : personDetails}
        personType={isExtPerson ? 'extPerson' : 'person'}
        service={isExtPerson ? extService : service}
        showDocumentTooltip={showDocumentTooltip}
        toggleDocumentsModalVisible={toggleDocumentsModalVisible}
    />;
};

DocumentsActions.displayName = cn();
