import { SearchOptions } from 'types/SearchOptions';

import { createUseQueryHook } from './createUseQueryHook';

const getAviaSearchFilterList = createUseQueryHook(
    'get_avia_search_filters',
    { refetchOnWindowFocus: false },
);

const getRailSearchFilterList = createUseQueryHook(
    'get_rail_search_filters',
    { refetchOnWindowFocus: false },
);

const getHotelSearchFilterList = createUseQueryHook(
    'get_hotel_search_filters',
    { refetchOnWindowFocus: false },
);

export const useSearchFilterList = (options: SearchOptions) => {
    const { type, searchId, person_id, trip_id } = options;

    switch (type) {
        case 'Avia':
            return getAviaSearchFilterList(searchId, { person_id, trip_id });

        case 'Hotel':
            return getHotelSearchFilterList(searchId, { person_id, trip_id });

        case 'Rail':
            return getRailSearchFilterList(searchId, { person_id, trip_id });
    }
};
