import { FC, useCallback } from 'react';
import { Radiobox } from '@yandex-lego/components/Radiobox/desktop/bundle';

import { cn } from './FilterFieldTransfers.cn';
import { i18n } from './FilterFieldTransfers.i18n';
import { FilterFieldTransfersProps, TransfersCount } from './FilterFieldTransfers.types';

import './FilterFieldTransfers.css';

export const FilterFieldTransfers: FC<FilterFieldTransfersProps> = props => {
    const { className, input, transfers } = props;

    const { value, onChange } = input;

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === TransfersCount.ANY_TRANSFERS) {
            onChange(undefined);

            return;
        }

        onChange(Number(event.target.value));
    }, [onChange]);

    const options = transfers.map(transfer => ({ value: transfer.target_id, label: transfer.caption }));

    const allTransferOptions = [
        { value: TransfersCount.ANY_TRANSFERS, label: i18n('any'), checked: !value },
        ...options,
    ];

    return (
        <div className={cn(null, [className])}>
            <Radiobox
                className={cn('Radiobox')}
                options={allTransferOptions}
                size="m"
                value={value.toString()}
                view="outline"
                onChange={handleChange}
            />
        </div>
    );
};

FilterFieldTransfers.displayName = cn();
