export const ru = {
    general_rules: 'Общие правила',
    avia_rules: 'Правила для авиабилетов',
    rail_rules: 'Правила для ж/д билетов',
    hotel_rules: 'Правила для отелей',
    name: 'Название',
    name_placeholder: 'Укажите название',
    available_classes: 'Доступные классы',
    available_categories: 'Доступные типы вагона',
    available_stars: 'Доступное количество звезд',
    additional_conditions: 'Дополнительные условия',
    suggest_country_placeholder: 'Введите страну',
    suggest_city_placeholder: 'Введите город',
    save_caption: 'Новая тревел-политика будет добавлена в компанию',
    save_changes_caption: 'Изменения будут действовать для новых командировок',
    countries: 'Страна',
    cities: 'Город',
    stars: {
        one: '{count} звезда',
        some: '{count} звезды',
        many: '{count} звезд',
        none: 'Без звезд',
    },
    no_more_expensive: 'Не дороже',
    one_way: '₽ в один конец',
    per_night: '₽ за ночь',
    for_direction: '₽ для направления',
    cheapest_more_percent: 'Дороже самых дешёвых не более, чем на',
    no_later_than: 'Не позднее, чем за',
    avia_before_days: 'дней до вылета',
    rail_before_days: 'дней до отправления',
    hotel_before_days: 'дней до заселения',
    avia_max_transfer_duration: 'Максимальная длительность пересадки',
    hours: 'часа',
    avia_refundable_only: 'Только с возможностью возврата',
    avia_exchangeable_only: 'Только с возможностью обмена',
    avia_with_baggage_only: 'Только с багажом',
    avia_business_days_only: 'Только с вылетом и прилетом в будние дни',
    rail_business_days_only: 'Только с отправлением и прибытием в будние дни',
    hotel_business_days_only: 'Только с бронированием в будние дни',
    add_condition: 'Добавить условие',
    for_certain_directions: 'для определенных направлений',
};
