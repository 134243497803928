import { FC, memo, useMemo } from 'react';
import { Palettes } from '@yandex-int/hr-components/PaletteGeneration';

import { Dropdown } from 'components/Dropdown';
import { formatCurrency } from 'utils/formatCurrency';

import { CostElement } from './CostElement';
import { CostExplanationElement } from './CostExplanationElement';
import { cn } from './ServiceCostDetails.cn';
import { i18n } from './ServiceCostDetails.i18n';
import { ServiceCostDetailsProps } from './ServiceCostDetails.types';

import './ServiceCostDetails.css';

/**
 * Детали костов для услуги
 */

export const ServiceCostDetails: FC<ServiceCostDetailsProps> = memo(props => {
    const {
        desktopTrigger,
        className,
        price: {
            service_part,
            additional_products_part,
            ext_persons_part,
        },
        inverse,
        triggerElement,
    } = props;

    const additionalProductsPart = Number(additional_products_part ?? 0);

    const companyPricePart = Number(service_part) + additionalProductsPart;

    const extPersonsPricePart = Number(ext_persons_part ?? 0);

    const ModalContent = useMemo(() => (
        <>
            <CostElement cost={formatCurrency(companyPricePart)} text={i18n('paid_by_the_company:')} />
            <CostElement cost={formatCurrency(extPersonsPricePart)} text={i18n('paid_by_you:')} />
            {additionalProductsPart !== 0 && (
                <CostExplanationElement cost={formatCurrency(additionalProductsPart)} text={i18n('additional_services:')} />
            )}
        </>
    ), [companyPricePart, extPersonsPricePart, additionalProductsPart]);

    return (
        <Dropdown
            className={cn(null, [className])}
            desktopTrigger={desktopTrigger}
            drawerProps={{
                scope: 'inplace',
            }}
            popupProps={{
                hasTail: true,
                direction: ['bottom'],
                className: inverse ? Palettes.inverse : undefined,
                mainOffset: 16,
                scope: 'inplace',
            }}
            triggerElement={triggerElement}
        >
            <div className={cn('Content')}>
                {ModalContent}
            </div>
        </Dropdown>
    );
});

ServiceCostDetails.displayName = cn();
