import { FC } from 'react';

import { FieldTextinput } from 'components/FormField2';

import { cn } from '../FormTravelPolicy.cn';

import { CheckboxInputLabelProps } from './CheckboxInputLabel.types';

import './CheckboxInputLabel.css';

export const CheckboxInputLabel: FC<CheckboxInputLabelProps> = ({ name, widthSize }) => (
    <FieldTextinput
        hideErrors
        className={cn('CheckboxInputLabel', { size: widthSize })}
        hasClear={false}
        inputMode="numeric"
        min={0}
        name={name}
        size="s"
        type="number"
        view="default"
    />
);
