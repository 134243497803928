import { useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';

import { RadioOption } from 'components/FormField';
import {
    FieldCheckbox,
    FieldDestination,
    FieldRadioToggle,
    FieldRangeDatePicker,
    FieldTextarea,
    FieldTextinput,
} from 'components/FormField/FormField.providers';
import { FieldLabel } from 'components/FormField2/utils';
import { withProps } from 'hocs/withProps';
import { Text } from 'shared/ui/Text';
import { validateRequiredField } from 'utils/form/validateRequiredField';

import { cn } from '../FormTripCreate.cn';
import { i18n } from '../FormTripCreate.i18n';
import { SearchFormValues } from '../FormTripCreate.types';
import { RouteFields } from '../FormTripCreate-RouteFields/RouteFields';

const CityField = withProps({
    component: FieldDestination,
}, cn('Field'))(Field);

export const ConfFields = () => {
    const {
        values,
    } = useFormState<SearchFormValues>();

    const isInAnotherCity = values.is_another_city === 'true';
    const isAnotherCityOptions = useMemo<RadioOption[]>(() => [
        { label: i18n('yes'), value: 'true' },
        { label: i18n('no'), value: 'false' },
    ], []);

    const isConfPaymentTypePaid = values.conf_payment_type === 'paid';
    const confPaymentTypeOptions = useMemo<RadioOption[]>(() => [
        { label: i18n('conf_payment_type_paid'), value: 'paid', hint: i18n('conf_payment_type_paid_hint') },
        { label: i18n('conf_payment_type_free'), value: 'free', hint: i18n('conf_payment_type_free_hint') },
    ], []);

    return (
        <>
            <Text className={cn('Subheader')} typography="title-s">{i18n('conf_details')}</Text>

            <CityField
                hint={i18n('conf_city_hint')}
                name="conf_city"
                placeholder={i18n('conf_city_placeholder')}
                searchSuggestParams={{ service_type: 'hotel' }}
                title={i18n('conf_city')}
            />

            <div className={cn('ConfDates')}>
                <FieldLabel
                    isRequired
                    className={cn('ConfDatesLabel')}
                    label={i18n('conf_dates')}
                />
                <Field
                    component={FieldRangeDatePicker}
                    leftInputClassName={cn('ConfDateFrom')}
                    leftInputPlaceholder={i18n('conf_date_from_placeholder')}
                    name="conf_dates"
                    rightInputClassName={cn('ConfDateTo')}
                    rightInputPlaceholder={i18n('conf_date_to_placeholder')}
                    validate={validateRequiredField}
                />
            </div>

            <div className={cn('ConfDetails')}>
                <Field
                    required
                    className={cn('Field', [cn('ConfName')])}
                    component={FieldTextinput}
                    name="conference_name"
                    placeholder={i18n('conf_name_placeholder')}
                    title={i18n('conf_name')}
                    validate={validateRequiredField}
                />
                <Field
                    required
                    className={cn('Field')}
                    component={FieldTextinput}
                    name="conference_url"
                    placeholder={i18n('conf_url_placeholder')}
                    title={i18n('conf_url')}
                    validate={validateRequiredField}
                />
                <Field
                    className={cn('Field')}
                    component={FieldTextinput}
                    name="program_url"
                    placeholder={i18n('program_url_placeholder')}
                    title={i18n('program_url')}
                />
                <Field
                    required
                    className={cn('Field', [cn('ConfParticipationTerms')])}
                    component={FieldTextarea}
                    name="participation_terms"
                    placeholder={i18n('participation_terms_placeholder')}
                    title={i18n('participation_terms')}
                    validate={validateRequiredField}
                />
            </div>

            <div className={cn('ConfPayment')}>
                <Field
                    multiline
                    required
                    className={cn('Field', [cn('ConfPaymentType')])}
                    component={FieldRadioToggle}
                    name="conf_payment_type"
                    options={confPaymentTypeOptions}
                    title={i18n('conf_payment_type_title')}
                    validate={validateRequiredField}
                />
                {isConfPaymentTypePaid ? (
                    <>
                        <div className={cn('ConfPaymentMainFields')}>
                            <Field
                                required
                                className={cn('Field')}
                                component={FieldTextinput}
                                name="price"
                                placeholder={i18n('conf_price_placeholder')}
                                title={i18n('conf_price')}
                                validate={validateRequiredField}
                            />
                            <Field
                                className={cn('Field', [cn('ConfPaymentOption')])}
                                component={FieldCheckbox}
                                name="need_compensation"
                                title={i18n('conf_payment_need_compensation')}
                                type="checkbox"
                            />
                        </div>
                        <Field
                            className={cn('Field')}
                            component={FieldTextinput}
                            name="ticket_type"
                            placeholder={i18n('conf_ticket_type_placeholder')}
                            title={i18n('conf_ticket_type')}
                        />
                        <Field
                            className={cn('Field')}
                            component={FieldTextinput}
                            name="promo_code"
                            placeholder={i18n('conf_promocode_placeholder')}
                            title={i18n('conf_promocode')}
                        />
                    </>
                ) : (
                    <Field
                        className={cn('Field', [cn('ConfPaymentOption')])}
                        component={FieldCheckbox}
                        name="need_help"
                        title={i18n('conf_payment_need_help')}
                        type="checkbox"
                    />
                )}
            </div>

            <Field
                className={cn('Field')}
                component={FieldTextarea}
                name="comment"
                placeholder={i18n('comment_placeholder')}
                title={i18n('comment')}
            />

            <Field
                required
                className={cn('Field')}
                component={FieldRadioToggle}
                name="is_another_city"
                options={isAnotherCityOptions}
                title={i18n('conf_another_city')}
                validate={validateRequiredField}
            />

            {isInAnotherCity && <RouteFields />}
        </>
    );
};
