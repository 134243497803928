import { RefObject, useEffect } from 'react';

type UsePositionStickyOptions = {
    containerRef: RefObject<HTMLElement>;
    targetRef: RefObject<HTMLElement>;
    boundingBottomClass: string;
    stickyClass: string;
    isEnabled?: boolean;
};

/**
 * Хук для реализации sticky позиционирования
 * @param containerRef - ссылка на контейнер, в котором находится целевой элемент
 * @param targetRef - ссылка на целевой элемент, который должен иметь позицию `sticky`
 * @param boundingBottomClass - класс для элемента, когда он достигает нижней границы контейнера.
 * @param stickyClass - класс для элемента, когда он имеет позицию `sticky`
 * @param isEnabled - флаг, указывающий, включено ли позиционирование `sticky`
 */
export const usePositionSticky = (options: UsePositionStickyOptions) => {
    const {
        containerRef,
        targetRef,
        boundingBottomClass,
        stickyClass,
        isEnabled = true,
    } = options;

    useEffect(() => {
        if (!isEnabled) {
            return;
        }

        const handleScroll = () => {
            if (!containerRef.current || !targetRef.current) {
                return;
            }

            const asideClientRect = containerRef.current.getBoundingClientRect();
            const topAside = asideClientRect.top;
            const bottomAside = asideClientRect.bottom;
            const menuHeight = targetRef.current.offsetHeight;
            const asideHeight = containerRef.current.offsetHeight;

            if (menuHeight !== asideHeight) {
                if (bottomAside <= menuHeight) {
                    targetRef.current.classList.add(boundingBottomClass);
                    targetRef.current.classList.remove(stickyClass);
                } else {
                    targetRef.current.classList.remove(boundingBottomClass);
                    targetRef.current.classList.toggle(stickyClass, topAside < 0);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [containerRef, isEnabled, boundingBottomClass, stickyClass, targetRef]);
};
