import { FC } from 'react';
import { Link } from '@yandex-int/hr-components/Link/desktop/bundle';

import { NearbyStop } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';
import { getTypedI18nLang } from 'utils/i18n';

import { cn } from '../HotelDetails.cn';
import { i18n } from '../HotelDetails.i18n';

import { yandexLogo } from './NearbyStops.icons/Logo';
import { NearbyStopsProps } from './NearbyStops.types';

import './NearbyStops.css';

const getStopTitle = (nearbyStop: NearbyStop) => {
    if (nearbyStop.type === 'station') {
        return i18n('m', { station: nearbyStop.name });
    }

    return nearbyStop.name;
};

export const NearbyStops: FC<NearbyStopsProps> = props => {
    const {
        nearbyStops,
        nearbyOffice,
        className,
    } = props;

    if (nearbyStops.length === 0 && !nearbyOffice) {
        return null;
    }

    const lang = getTypedI18nLang();

    return (
        <div className={cn('NearbyStops', [className])}>
            {nearbyOffice && (
                <Link
                    className={cn('NearbyOffice')}
                    href={`https://yandex.ru/maps/?ll=${nearbyOffice.longitude},${nearbyOffice.latitude}&text=${nearbyOffice.name}&z=17`}
                    target="_blank"
                    theme="link"
                >
                    <div className={cn('YandexLogo')}>
                        {yandexLogo[lang]}
                    </div>
                    {nearbyOffice.name}
                    <Text color="secondary">
                        {`  ·  ${nearbyOffice.distance.text}`}
                    </Text>
                </Link>
            )}
            {nearbyStops.map(nearbyStop => (
                <Text key={nearbyStop.id} typography="body-s">
                    {getStopTitle(nearbyStop)}
                    <Text color="secondary">
                        {`  ·  ${nearbyStop.distance.text}`}
                    </Text>
                </Text>
            ))}
        </div>
    );
};
