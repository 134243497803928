import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Image } from '@yandex-lego/components/Image/desktop';

import {
    getIconProvider,
    IconArrowShortLeft,
} from 'components/Icon';
import { RegistrationFeedbackForm } from 'components/RegistrationFeedbackForm';
import { withModalView } from 'hocs/withModalView';
import { RouteRegister } from 'routes/RouteRegister';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';
import { Text } from 'shared/ui/Text';
import { getTypedI18nLang } from 'utils/i18n';

import background from './assets/background.jpg';
import backgroundX2 from './assets/backgroundX2.jpg';
import { Logo } from './Logo';
import { cn } from './RegisterModalLayout.cn';
import { i18n } from './RegisterModalLayout.i18n';
import { RegisterModalLayoutProps } from './RegisterModalLayout.types';

import './RegisterModalLayout.css';

const iconProviderArrowLeft = getIconProvider(IconArrowShortLeft);
const RegistrationFeedbackFormModal = withModalView(RegistrationFeedbackForm);

export const RegisterModalLayout: FC<RegisterModalLayoutProps> = props => {
    const {
        className,
        title,
        children,
        onBackClick,
    } = props;

    const { push } = useHistory();
    const language = getTypedI18nLang();
    const [isFeedbackModalVisible, setFeedbackModalVisible] = useToggle(false);

    const handleFeedbackModalClose = useCallback(() => {
        setFeedbackModalVisible();
    }, [setFeedbackModalVisible]);

    const handleFeedbackButtonClick = useCallback(() => {
        setFeedbackModalVisible();
    }, [setFeedbackModalVisible]);

    const handleBackClick = useCallback(() => {
        if (onBackClick) {
            onBackClick();
        } else {
            push(RouteRegister.getPath({}, { changeEmail: true }));
        }
    }, [onBackClick, push]);

    return (
        <div className={cn(null, [className])}>
            <Image
                className={cn('Image')}
                src={background}
                src2x={backgroundX2}
            />
            <Modal
                visible
                autoFocus={false}
                className={cn('Modal')}
                theme="normal"
            >
                <Logo lang={language} />
                <Button
                    className={cn('BackButton')}
                    icon={iconProviderArrowLeft}
                    pin="circle-circle"
                    size="l"
                    view="raised"
                    onClick={handleBackClick}
                />
                <div className={cn('Content')}>
                    <Text
                        as="div"
                        className={cn('Title')}
                        typography="title-m"
                    >
                        {title}
                    </Text>
                    {children}
                </div>
                <Button
                    className={cn('FeedbackButton')}
                    size="l"
                    view="clear"
                    width="max"
                    onClick={handleFeedbackButtonClick}
                >
                    <Text>{i18n('contact_me')}</Text>
                </Button>
                <RegistrationFeedbackFormModal
                    className={cn('FeedbackModal')}
                    modalViewOnClose={handleFeedbackModalClose}
                    modalViewVisible={isFeedbackModalVisible}
                    onClose={handleFeedbackModalClose}
                />
            </Modal>
        </div>
    );
};

RegisterModalLayout.displayName = cn();
