import { FC, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { FieldDate, FormProvider } from 'components/FormField2';
import {
    getIconProvider,
    IconArrowShortDown,
    IconArrowShortUp,
} from 'components/Icon';
import { Popup } from 'shared/ui/Popup';
import { Text } from 'shared/ui/Text';

import { useLastApplied } from './DatesDropdown.hooks/useLastApplied';
import { cn } from './DatesDropdown.cn';
import { i18n } from './DatesDropdown.i18n';
import { schema } from './DatesDropdown.schema';
import {
    DatesDropdownContentProps,
    DatesDropdownFields,
    DatesDropdownProps,
    HandleSubmit,
} from './DatesDropdown.types';

import './DatesDropdown.css';

const iconProviderArrowDown = getIconProvider(IconArrowShortDown);
const iconProviderArrowUp = getIconProvider(IconArrowShortUp);

export const DatesDropdownContent: FC<DatesDropdownContentProps> = props => {
    const {
        handleReset,
    } = props;

    return (
        <div className={cn('Content')}>
            <div className={cn('Fields')}>
                <Text
                    className={cn('LabelFrom')}
                    color="secondary"
                    typography="caption-m"
                >
                    {i18n('from')}
                </Text>
                <Text
                    className={cn('LabelTo')}
                    color="secondary"
                    typography="caption-m"
                >
                    {i18n('to')}
                </Text>
                <FieldDate
                    className={cn('DateFrom')}
                    name="dateFrom"
                    size="s"
                />
                <span className={cn('Hypen')}>–</span>
                <FieldDate
                    className={cn('DateTo')}
                    name="dateTo"
                    size="s"
                />
            </div>
            <div className={cn('PopupButtons')}>
                <Button
                    className={cn('Reset')}
                    size="m"
                    view="clear"
                    onClick={handleReset}
                >
                    {i18n('reset')}
                </Button>
                <Button
                    className={cn('Submit')}
                    size="m"
                    type="submit"
                    view="default"
                >
                    {i18n('apply')}
                </Button>
            </div>
        </div>
    );
};

export const DatesDropdown: FC<DatesDropdownProps> = props => {
    const {
        buttonText,
        disabled,
        onSubmit,
        className,
        dataTestId,
    } = props;

    const anchorRef = useRef<HTMLButtonElement>(null);
    const [visible, setVisible] = useToggle(false);
    const buttonIcon = visible ? iconProviderArrowUp : iconProviderArrowDown;

    const methods = useForm<DatesDropdownFields>({ });

    const { resetToLastApplied, setLastApplied } = useLastApplied(methods);

    const handlePopupClose = useCallback(() => {
        setVisible();
        setTimeout(resetToLastApplied, 100);
    }, [resetToLastApplied, setVisible]);

    const handleSubmit: HandleSubmit = data => {
        setLastApplied(data);
        onSubmit(data);
    };

    const handleReset = useCallback(() => {
        const emptyDatesValues = {
            dateTo: null,
            dateFrom: null,
        };

        methods.reset(emptyDatesValues);
        setLastApplied(emptyDatesValues);
        onSubmit(emptyDatesValues);
    }, [methods, onSubmit, setLastApplied]);

    return (
        <FormProvider methods={methods} schema={schema}>
            <form
                className={cn(null, [className])}
                data-testid={dataTestId}
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <Button
                    className={cn('Trigger')}
                    disabled={disabled}
                    iconRight={buttonIcon}
                    innerRef={anchorRef}
                    size="m"
                    view="default"
                    onClick={handlePopupClose}
                >
                    {buttonText}
                </Button>
                <Popup
                    anchor={anchorRef}
                    direction="bottom-start"
                    scope="inplace"
                    target="anchor"
                    view="default"
                    visible={visible}
                    onClose={handlePopupClose}
                >
                    <DatesDropdownContent handleReset={handleReset} />
                </Popup>
            </form>
        </FormProvider>
    );
};

DatesDropdown.displayName = cn();
