import { FC } from 'react';
import { Divider } from '@yandex-lego/components/Divider';

import { FlightRouteTransferTag } from 'components/FlightRouteTransferTag';
import { usePlatform } from 'hooks/usePlatform';
import { Text } from 'shared/ui/Text';
import { DateFormat, formatDate } from 'utils/formatDate';
import { formatDuration } from 'utils/formatDuration';
import { getRouteDuration } from 'utils/getRouteDuration';

import { ArrivalDate } from './ArrivalDate/ArrivalDate';
import { cn } from './FlightRouteTimeline.cn';
import { FlightRouteTimelineProps } from './FlightRouteTimeline.types';

import './FlightRouteTimeline.css';

export const FlightRouteTimeline: FC<FlightRouteTimelineProps> = props => {
    const {
        leg: { segments, segments_count },
        collapsed,
        toggleCollapse,
        isServiceDisabled,
        className,
    } = props;

    const { departure, departure_at } = segments[0];
    const { arrival, arrival_at } = segments[segments.length - 1];

    const isArrivalDifferentDay = new Date(departure_at).getDate() !== new Date(arrival_at).getDate();

    const textColor = isServiceDisabled ? 'disable' : 'primary';
    const textColorSecondary = isServiceDisabled ? 'disable' : 'secondary';

    const { isMobile } = usePlatform();

    const duration = formatDuration(getRouteDuration(segments));
    const isDesktopCollapsed = !isMobile && collapsed;

    return (
        <div className={cn(null, [className])}>
            <div className={cn('Times')}>
                <Text as="div" color={textColor} typography="title-m" weight="regular">
                    {formatDate(departure_at, DateFormat.HH_mm)}
                </Text>
                <div className={cn('FlightTime')}>
                    <Divider />
                    <Text color={textColorSecondary} typography="caption-m">
                        {duration}
                    </Text>
                    <Divider />
                </div>
                <Text as="div" color={textColor} typography="title-m" weight="regular">
                    {formatDate(arrival_at, DateFormat.HH_mm)}
                </Text>
            </div>
            <div className={cn('Stations', { collapsed })}>
                <div className={cn('Column')}>
                    <Text as="div" color="secondary" typography="caption-m">
                        {departure.airport?.name}
                        {departure?.airport_iata_code && (` • ${departure?.airport_iata_code}`)}
                    </Text>
                    <Text as="span" color="secondary" typography="caption-m">
                        {formatDate(departure_at, DateFormat.D_MMMM_dd)}
                    </Text>
                </div>
                {isDesktopCollapsed && (
                    <div className={cn('Tags')}>
                        <FlightRouteTransferTag
                            segments={segments}
                            segmentsCount={segments_count}
                            onTagClick={segments_count > 1 ? toggleCollapse : undefined}
                        />
                    </div>
                )}
                <div className={cn('Column', { type: 'last' })}>
                    <Text
                        align="end"
                        as="div"
                        color="secondary"
                        typography="caption-m"
                    >
                        {arrival.airport?.name}
                        {arrival?.airport_iata_code && (` • ${arrival?.airport_iata_code}`)}
                    </Text>
                    <ArrivalDate
                        arrivalAt={arrival_at}
                        baseTextColor="secondary"
                        isArrivalDifferentDay={isArrivalDifferentDay}
                    />
                </div>
            </div>
        </div>
    );
};

FlightRouteTimeline.displayName = cn();
