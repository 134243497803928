import { FC, useCallback } from 'react';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';

import { ButtonConfirm } from 'components/ButtonConfirm';
import { Card } from 'components/Card';
import { useCancelPersonTrip } from 'hooks/useCancelPersonTrip';
import { Text } from 'shared/ui/Text';
import { addToast } from 'utils/addToast';
import { errorToast } from 'utils/errorToast';
import { isLoading } from 'utils/Loadable';

import { useApprove } from './Approvements.hooks/useApprove';
import { cn } from './Approvements.cn';
import { i18n } from './Approvements.i18n';
import { ApprovementsProps } from './Approvements.types';

import './Approvements.css';

export const Approvements: FC<ApprovementsProps> = props => {
    const {
        className,
        tripId,
        personId,
        tripComment,
    } = props;

    const [approve, approveState] = useApprove({
        onSuccess: () => addToast({ title: i18n('approve_success') }),
        onError: e => errorToast(e, { title: i18n('approve_error') }),
        options: {
            hideErrorNotifier: true,
        },
    });
    const [cancel, cancelState] = useCancelPersonTrip({
        onSuccess: () => addToast({ title: i18n('decline_success') }),
        onError: e => errorToast(e, { title: i18n('decline_error') }),
        options: {
            hideErrorNotifier: true,
        },
    });

    const handleApproveClick = useCallback(() => {
        approve(tripId, personId);
    }, [tripId, personId, approve]);

    const handleCancelClick = useCallback(({ reason }) => {
        cancel(tripId, personId, { cancellation_reason: reason });
    }, [tripId, personId, cancel]);

    return (
        <Card className={cn(null, [className])}>
            <Text
                as="div"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <Text
                as="div"
                className={cn('Description')}
                color="secondary"
                typography="body-s"
            >
                {i18n('description')}
            </Text>
            {tripComment && (
                <>
                    <Text
                        as="div"
                        className={cn('CommentCaption')}
                        color="secondary"
                        typography="body-s"
                    >
                        {i18n('comment')}
                    </Text>
                    <Text
                        as="div"
                        className={cn('TripComment')}
                        typography="body-s"
                    >
                        {tripComment}
                    </Text>
                </>
            )}
            <Button
                className={cn('ApproveButton')}
                progress={isLoading(approveState)}
                size="l"
                view="primary"
                width="max"
                onClick={handleApproveClick}
            >
                {i18n('approve')}
            </Button>
            <ButtonConfirm
                cancelText={i18n('no')}
                confirmClassName={cn('CancelModal')}
                confirmText={i18n('yes')}
                progress={isLoading(cancelState)}
                reason={i18n('decline_reason')}
                size="l"
                title={i18n('decline_confirmation')}
                triggerClassname={cn('CancelButton')}
                view="outline"
                width="max"
                onClick={handleCancelClick}
            >
                {i18n('decline')}
            </ButtonConfirm>
        </Card>
    );
};

Approvements.displayName = cn();
