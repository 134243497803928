/**
 * Возвращает куки с указанным name, или undefined, если ничего не найдено
 *
 * @param name
 */
export const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);

    if (parts.length > 0) {
        return (parts.pop() || '').split(';').shift();
    }

    return null;
};
