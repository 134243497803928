import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';

export const reportValidationErrors = <A>(v: t.Validation<A>): Array<string> => {
    return pipe(
        v,
        fold(
            errors => errors.map(error => {
                const { message = 'Invalid value', value } = error;
                const errorPath = error.context.map(({ key }) => key).join('.');

                return `${message} ${value} at ${errorPath}`;
            }),
            () => ['no errors'],
        ),
    );
};
