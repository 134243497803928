import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Card } from 'components/Card';
import { FieldRadioButton, FieldSelectExt, FormProvider } from 'components/FormField2';
import { withRemote } from 'hocs/withRemote';
import { RouteGroupAdd } from 'routes/RouteGroupAdd';
import { RouteTravelPolicyAdd } from 'routes/RouteTravelPolicyAdd';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { cn } from '../../PageProfile.cn';
import { i18n } from '../../PageProfile.i18n';

import { useTravelPolicyEditor } from './TravelPolicyEditorCard.hook/useTravelPolicyEditor';
import { TravelPolicyEditorCardProps } from './TravelPolicyEditorCard.types';

const TravelPolicyEditorCard: FC<TravelPolicyEditorCardProps> = props => {
    const { personTravelPolicy, companyId } = props;

    const { methods, type, travelPolicyOptions, groupsOptions, state } = useTravelPolicyEditor({
        personTravelPolicy,
        companyId,
    });

    const { push } = useHistory();

    const handleCreateGroup = useCallback(() => {
        push(RouteGroupAdd.getPath({}, {}));
    }, [push]);

    const handleCreateTravelPolicy = useCallback(() => {
        push(RouteTravelPolicyAdd.getPath({}, {}));
    }, [push]);

    const typeOptions = [
        {
            value: 'travel_policy',
            children: i18n('personal_tp'),
        },
        {
            value: 'group',
            children: i18n('group_tp'),
        },
    ];

    return (
        <Card title={i18n('travel_policy')}>
            <div className={cn('TabSettingsList')}>
                <Text as="div" color="secondary" typography="body-s">
                    {i18n('travel_policy_description')}
                </Text>

                <FormProvider methods={methods}>
                    <form className={cn('TravelPolicyForm')}>
                        <FieldRadioButton
                            disabled={isLoading(state)}
                            label={i18n('type')}
                            name="type"
                            options={typeOptions}
                            width="max"
                        />
                        {type === 'travel_policy' && (
                            <FieldSelectExt
                                addonButtonText={i18n('create_travel_policy')}
                                className={cn('SelectExt')}
                                disabled={isLoading(state)}
                                label={i18n('travel_policy')}
                                name="travel_policy"
                                options={travelPolicyOptions}
                                width="max"
                                onAddonButtonClick={handleCreateTravelPolicy}
                            />
                        )}
                        {type === 'group' && (
                            <FieldSelectExt
                                addonButtonText={i18n('create_group')}
                                className={cn('SelectExt')}
                                disabled={isLoading(state)}
                                label={i18n('group')}
                                name="group"
                                options={groupsOptions}
                                width="max"
                                onAddonButtonClick={handleCreateGroup}
                            />
                        )}
                    </form>
                </FormProvider>
            </div>
        </Card>
    );
};

export const RemoteTravelPolicyEditorCard = withRemote(TravelPolicyEditorCard);
