import { AviaServiceDetails, RoutePoint, ServiceStatus } from 'services/SwaggerApi';
import { ServicesDetailsWithId } from 'types/TripRoute';
import { TripSegment } from 'types/TripSegment';

type GetHideReturnSegment = (
    routes: RoutePoint[] | null | undefined,
    services: ServicesDetailsWithId[],
    segments: TripSegment[]
) => boolean;

const activeServiceState: ServiceStatus[] = ['draft', 'executed', 'in_progress', 'new', 'reserved', 'verification'];

export const getHideReturnSegment: GetHideReturnSegment = (routes, services, segments) => {
    const aviaServices = services.filter(service => service.service_type === 'avia') as AviaServiceDetails[];

    if (aviaServices.length === 1) {
        const aviaService = aviaServices[0];
        const isSimpleRoute = routes?.length === 2;
        const isRoundTrip = aviaService.flight.legs?.length === 2;
        const isActiveService = activeServiceState.includes(aviaService.service_state);
        const lastSegmentIsEmpty = segments[segments.length - 1].services.length === 0;

        return isSimpleRoute && isRoundTrip && isActiveService && lastSegmentIsEmpty;
    }

    return false;
};
