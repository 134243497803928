import { pipe } from 'fp-ts/lib/function';
import * as D from 'io-ts/Decoder';

import { ACOrderByDecoder } from 'services/AeroClubApi/decoders/ACOrderByDecoder';
import { ACSearchFiltersAviaDecoder } from 'services/AeroClubApi/decoders/ACSearchFiltersAviaDecoder';
import { ACSearchFiltersHotelDecoder } from 'services/AeroClubApi/decoders/ACSearchFiltersHotelDecoder';
import { ACSearchFiltersRailDecoder } from 'services/AeroClubApi/decoders/ACSearchFiltersRailDecoder';
import { OneToBoolean, ToNumber } from 'utils/io-ts/decoders';

const Avia = pipe(
    D.type({
        type: D.union(D.literal('Avia')),
    }),
    D.intersect(ACSearchFiltersAviaDecoder),
);

const Rail = pipe(
    D.type({
        type: D.union(D.literal('Rail')),
    }),
    D.intersect(ACSearchFiltersRailDecoder),
);

const Hotel = pipe(
    D.type({
        type: D.union(D.literal('Hotel')),
    }),
    D.intersect(ACSearchFiltersHotelDecoder),
);

const SearchTypes = D.sum('type')({ Avia, Rail, Hotel });

export const SearchResultsDecoder = pipe(
    D.type({
        person_id: D.string,
    }),
    D.intersect(D.partial({
        city_to: ToNumber,
        city_from: ToNumber,
        date_from: D.string,
        date_to: D.string,
        class_avia: D.union(
            D.literal('Econom'),
            D.literal('Comfort'),
            D.literal('Business'),
            D.literal('First'),
        ),
        trip_id: ToNumber,
        retpath: D.string,
        is_descending: OneToBoolean,
        order_by: ACOrderByDecoder,
        adult: ToNumber,
    })),
    D.intersect(SearchTypes),
);
