import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import stringify from 'fast-safe-stringify';

import { CompanionsAdd } from 'components/CompanionsAdd';
import { FareFamilyModal } from 'components/FareFamilyModal';
import { ServiceGroupAdd } from 'components/ServiceGroupAdd';
import { withRemote } from 'hocs/withRemote';
import { useAddService } from 'hooks/useAddService';
import { useMapLoadable } from 'hooks/useMapLoadable';
import { useTripOnce } from 'hooks/useTrip';
import { RoutePersonTrip } from 'routes/RoutePersonTrip';
import { ClassAvia, Flight } from 'services/SwaggerApi';
import { isLoading, mapLoadable } from 'utils/Loadable';
import { prepareServiceCreatePayload, prepareServiceCreatePayloadCompanions } from 'utils/prepareServiceCreatePayload';

import { useFlightOptions } from './FlightFareFamily.hooks/useFlightOptions';
import { Main } from './Main/Main';
import { Summary } from './Summary/Summary';
import { SummarySkeleton } from './Summary/SummarySkeleton';
import { cn } from './FlightFareFamily.cn';
import { FlightFareFamilyProps } from './FlightFareFamily.types';

import './FlightFareFamily.css';

const SummaryRemote = withRemote(Summary);
const ServiceGroupAddRemote = withRemote(ServiceGroupAdd);
const CompanionsAddRemote = withRemote(CompanionsAdd);

const getFlightClass = (flight: Flight): ClassAvia => flight.legs![0].segments[0].flight_class;

const filterFlightsByClass = (fligthClass: ClassAvia) => (flights: Flight[]) =>
    flights.filter(flight => getFlightClass(flight) === fligthClass);

/**
 * Модалка для выбора тарифа перелета
 */
export const FlightFareFamily: FC<FlightFareFamilyProps> = props => {
    const {
        className,
        tripId,
        searchId,
        personId,
        flightFromSearch,
        isVisible,
        onClose,
        adultsCount,
    } = props;

    const {
        fare_family: isFareFamily,
        id: serviceId,
    } = flightFromSearch;
    const history = useHistory();

    const trip = useTripOnce(tripId);

    const [lastOptions, setLastOptions] = useState<string>('');

    // поменять на id после починки на беке BTRIP-7910
    const [activeTariff, setActiveTariff] = useState(flightFromSearch);
    const [slideIndex, setSlideIndex] = useState(0);

    const cancelAddCompanions = useCallback(() => {
        setSlideIndex(0);
    }, [setSlideIndex]);

    const flightOptions = useFlightOptions({
        searchId,
        personId,
        tripId,
        serviceId,
        isFareFamily,
        isEnabled: isVisible,
    });

    const methods = useForm({
        defaultValues: {
            flightClass: getFlightClass(flightFromSearch),
        },
    });

    const flightClass = methods.watch('flightClass');

    const flightOptionsBackedUp = mapLoadable(
        flightOptions,
        options => options.length > 0 ? options : [flightFromSearch],
    );

    // не понимаю как сделать без useEffect
    // надо сбрасывать выбранный тариф на первый из отфильтрованных вариантов после загрузки
    useEffect(() => {
        mapLoadable(flightOptionsBackedUp, flightOptions => {
            const optionsString = stringify.stable(flightOptions);

            if (optionsString === lastOptions) {
                return;
            }

            setLastOptions(optionsString);

            const filtered = filterFlightsByClass(flightClass)(flightOptions);

            if (filtered.length > 0) {
                setActiveTariff(filtered[0]);
            }
        });
    }, [flightClass, flightOptionsBackedUp, lastOptions]);

    const flightClasses = useMapLoadable(flightOptionsBackedUp, options => [... new Set(options.map(getFlightClass))]);
    const filteredByClass = useMapLoadable(flightOptionsBackedUp, filterFlightsByClass(flightClass));

    const isViolatesTravelPolicy = !activeTariff.is_travel_policy_compliant;
    const getServiceCreatePayload = prepareServiceCreatePayload({
        isViolatesTravelPolicy,
        optionId: activeTariff.id,
        tripId,
        searchId,
        serviceType: 'avia',
    });

    const unsafeModalRef = useRef<HTMLDivElement | null>(null);

    const companionsInSearch = adultsCount - 1;

    // add companions
    const personTripUrl = RoutePersonTrip.getPath({ tripId, personId }, {});
    const goToPersonTrip = useCallback(() => {
        history.push(personTripUrl);
    }, [history, personTripUrl]);
    const [addService, addServiceState] = useAddService({
        onSuccess: goToPersonTrip,
        options: {
            hideErrorNotifier: true,
        },
    });
    const getServiceCreatePayloadCompanions = prepareServiceCreatePayloadCompanions({
        isViolatesTravelPolicy,
        optionId: activeTariff.id,
        tripId,
        searchId,
        serviceType: 'avia',
    })(personId);

    const addCompanions = useCallback(async sendList => {
        await addService(getServiceCreatePayloadCompanions(sendList));
    }, [getServiceCreatePayloadCompanions, addService]);

    return (
        <FareFamilyModal
            className={cn(null, [className])}
            companionsAdd={<CompanionsAddRemote
                cancel={cancelAddCompanions}
                companionsInSearch={companionsInSearch}
                createService={addCompanions}
                personId={personId}
                progress={isLoading(addServiceState)}
            />}
            innerRef={unsafeModalRef}
            left={<Main
                activeTariff={activeTariff}
                flightClasses={flightClasses}
                flightOptions={filteredByClass}
                formFlighClassMethods={methods}
                isFareFamily={isFareFamily}
                setActiveTariff={setActiveTariff}
                unsafeRef={unsafeModalRef}
            />}
            right={<SummaryRemote
                activeTariff={activeTariff}
                companionsInSearch={companionsInSearch}
                getServiceCreatePayload={getServiceCreatePayload}
                isViolatesTravelPolicy={isViolatesTravelPolicy}
                personId={personId}
                setSlideIndex={setSlideIndex}
                skeleton={<SummarySkeleton />}
                trip={trip}
            />}
            serviceGroupAdd={<ServiceGroupAddRemote
                getServiceCreatePayload={getServiceCreatePayload}
                isViolatesTravelPolicy={isViolatesTravelPolicy}
                trip={trip}
            />}
            setSlideIndex={setSlideIndex}
            slideIndex={slideIndex}
            visible={isVisible}
            onClose={onClose}
        />
    );
};

FlightFareFamily.displayName = cn();
