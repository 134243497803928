import { FC } from 'react';

import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';

export const ButtonPlatform: FC<Button> = props => {
    const { isDesktop } = usePlatform();

    const {
        size = isDesktop ? 'm' : 'l',
        width = isDesktop ? undefined : 'max',
        view = 'default',
    } = props;

    return (
        <Button size={size} view={view} width={width} {...props} />
    );
};
