export const ru = {
    in_process_of_cancelling: 'В процессе удаления',
    draft: 'Черновик',
    new: 'Новый',
    verification: 'Проверяется',
    in_progress: 'Обрабатывается',
    executed: 'Оформлено',
    cancelled: 'Отменено',
    deleted: 'Удалено',
    refunded: 'Возврат',
    reserved: 'Забронировано',
};
