/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { useUnit } from 'effector-react/scope';

import { config } from 'config';
import { $theme } from 'shared/models/theme';
import { getTranslation } from 'utils/getTranslation';
import { getTypedI18nLang } from 'utils/i18n';

import officesUntyped from './GeoMap.assets/offices-hand-made.json';
import { addYmapScript } from './GeoMap.utils/addYmapScript';
import { getAveragePoint } from './GeoMap.utils/getAveragePoint';
import { getBounds } from './GeoMap.utils/getBounds';
import { GeoMarkHotel } from './GeoMarkHotel/GeoMarkHotel';
import { cn } from './GeoMap.cn';
import { i18n } from './GeoMap.i18n';
import { GeoMapProps, HotelMark, YaOffice, YMap } from './GeoMap.types';

import './GeoMap.css';

const formatMarkHotel = (mark: HotelMark) => renderToString(<GeoMarkHotel mark={mark} />);

function getOfficeName(office: YaOffice): string {
    return [i18n('office'), getTranslation(office.name)].join(': ');
}

// Дока тут - https://yandex.ru/dev/jsapi-v2-1/doc/ru/
export const GeoMap: FC<GeoMapProps> = props => {
    const { onItemClick, onMapClick, marks, ymapOptions = {}, onReady, isDraggable = true } = props;
    const theme = useUnit($theme);
    const [objectManager, setObjectManager] = useState<any>(null);
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<YMap | null>(null);
    const [bounds] = useState<number[][]>(getBounds(marks) || [[0, 0], [0, 0]]);
    const [center] = useState<number[]>(getAveragePoint(marks) || [0, 0]);
    const mapLanguage = getTypedI18nLang() === 'ru' ? 'ru_RU' : 'en_US';
    const { isB2B } = config;

    const points = useMemo(() => ({
        type: 'FeatureCollection',
        features: (officesUntyped as unknown as YaOffice[]).map((office, index) => ({
            type: 'Feature',
            id: index,
            geometry: {
                type: 'Point',
                coordinates: [office.latitude, office.longitude],
            },
            properties: {
                iconCaption: getOfficeName(office),
            },
            options: {
                preset: office.is_dc ? 'islands#blueRepairShopCircleIcon' : 'islands#bluePocketIcon',
            },
        })),
    }), []);

    useEffect(() => {
        if (map) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        addYmapScript(mapLanguage, theme)
            .catch(e => console.error('YMaps init error', e))
            .then(() => {
                const ymaps = (window as any).ymaps;

                if (!ymaps) {
                    return console.error('YMaps not found');
                }

                ymaps.ready(() => {
                    const map = new ymaps.Map(mapContainerRef.current, {
                        center,
                        bounds,
                        zoom: 10,
                        controls: ['largeMapDefaultSet'],
                        ...ymapOptions,
                    }, {
                        searchControlProvider: 'yandex#search',
                        autoFitToViewport: 'always',
                    });

                    setMap(map);

                    const objectManager = new ymaps.ObjectManager({
                        clusterize: true,
                    });

                    setObjectManager(objectManager);
                    map.geoObjects.add(objectManager);

                    if (!isDraggable) {
                        map.behaviors.disable(['drag']);
                    }

                    // добавляем офисы Яндекса для yt
                    if (!isB2B) {
                        const yaOfficesMarks = new ymaps.ObjectManager({});

                        yaOfficesMarks.removeAll();
                        yaOfficesMarks.add(points);

                        map.geoObjects.add(yaOfficesMarks);
                    }
                });

                onReady && onReady();
            });

        return () => {
            map && (map as any).destroy();
        };
    }, [bounds, isB2B, center, map, mapLanguage, ymapOptions, points, onReady, theme, isDraggable]);

    useEffect(() => {
        if (objectManager) {
            const points = {
                type: 'FeatureCollection',
                features: marks.map((mark, idx) => ({
                    type: 'Feature',
                    id: idx,
                    geometry: {
                        type: 'Point',
                        coordinates: [mark.latitude, mark.longitude],
                    },
                    properties: {
                        iconCaption: mark.title,
                        balloonContent: onItemClick ? formatMarkHotel(mark) : null,
                    },
                    options: {
                        preset: `islands#${mark.isRecommended ? 'green' : 'grey'}HotelIcon`,
                        iconCaptionMaxWidth: '150',
                    },
                })),
            };

            objectManager.removeAll();
            objectManager.add(points);
        }

        return () => {
            objectManager && objectManager.removeAll();
        };
    }, [marks, objectManager, onItemClick]);

    const handleClick = useCallback((e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const hotelId = (e.target as HTMLDivElement).getAttribute('data-hotel-id');
        const hotelName = (e.target as HTMLDivElement).getAttribute('data-hotel-name');

        if (hotelId !== null && hotelName !== null) {
            onItemClick && onItemClick(hotelId, hotelName);
        }

        if (onMapClick) {
            onMapClick();
        }
    }, [onItemClick, onMapClick]);

    return (
        <div
            ref={mapContainerRef}
            className={cn({ clickable: Boolean(onMapClick) }, [props.className])}
            onClick={handleClick}
        />
    );
};
