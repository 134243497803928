export const ru = {
    action_delete: 'Удалить',
    action_update: 'Редактировать',
    action_set_transaction_paid: 'Оплачено',
    add_transaction: 'Добавить транзакцию',
    empty_table_title: 'Пока ничего не добавлено',
    empty_table_description: 'Добавляйте транзакции и управляйте ими в кабинете финансов',
    cost_center_header: 'Центр затрат',
    trip_id_header: 'Командировка',
    registry_id_header: 'ID реестра',
    general_service_id_header: 'Номер услуги',
    execution_date_header: 'Дата оформления',
    invoice_date_header: 'Дата в реестре',
    person_header: 'Сотрудник',
    service_type_header: 'Тип услуги',
    service_type_value_avia: 'Авиабилет',
    service_type_value_hotel: 'Отель',
    service_type_value_rail: 'ЖД билет',
    service_type_value_transfer: 'Трансфер',
    service_type_value_vip_lounge: 'VIP-зал',
    service_type_value_fast_track: 'Fast track',
    service_type_value_aeroexpress: 'Аэроэкспресс',
    service_type_value_insurance: 'Страховка',
    service_type_value_additional: 'Дополнительная услуга',
    service_type_value_convenient_service: 'Дополнительная услуга',
    type_header: 'Тип транзакции',
    type_value_refund: 'Возврат',
    type_value_purchase: 'Покупка',
    type_value_exchange: 'Изменение',
    is_offline_header: 'Тип оформления',
    is_offline_value_true: 'Офлайн',
    is_offline_value_false: 'Онлайн',
    is_penalty_header: 'Штраф',
    price_header: 'Цена услуги',
    fee_header: 'Сервисный сбор',
    full_price_header: 'Общая стоимость',
    status_header: 'Статус',
    status_value_completed: 'Отправлено в биллинг',
    status_value_verified: 'Сверено с реестром',
    status_value_paid: 'Оплачено',
    status_value_hold: 'Заморожено',
};
