import { FC } from 'react';
import {
    ILoggerProps,
    INodeContext,
    ISenderProps,
    Logger,
    Sender,
    withBaobabRoot as _withBaobabRoot,
} from '@yandex-int/react-baobab-logger';

export function withBaobabRoot<T>(App: FC<T>) {
    return _withBaobabRoot<T, INodeContext, ILoggerProps<ISenderProps>>(
        {
            name: '$page',
            attrs: {
                service: 'trip',
                ui: 'mobile',
            },
        },
        Logger,
        Sender,
    )(App);
}
