import { FC, useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { ButtonMenu } from 'components/ButtonMenu';
import { DatesDropdown } from 'components/DatesDropdown';
import { FieldSuggest, FormProvider } from 'components/FormField2';
import {
    getIconProvider,
    IconDownload,
    IconMoreVertical,
    IconPlus,
} from 'components/Icon';
import { useMeta } from 'hooks/useMeta';
import { EmployeeField, useWatchEmployeeSuggest } from 'hooks/useWatchEmployeeSuggest';
import { i18nAction } from 'i18n/i18nAction';
import { Direction } from 'shared/ui/Popup';

import { useMenuOptions } from './TableTransactionsHeader.hooks/useMenuOptions';
import { cn } from './TableTransactionsHeader.cn';
import { i18n } from './TableTransactionsHeader.i18n';
import {
    HandleDatesSubmit,
    TableTransactionsHeaderFields,
    TableTransactionsHeaderProps,
} from './TableTransactionsHeader.types';

import './TableTransactionsHeader.css';

const iconProviderMoveVertical = getIconProvider(IconMoreVertical);
const iconProviderIconPlus = getIconProvider(IconPlus);
const iconProviderIconDownload = getIconProvider(IconDownload);

const popupDirections: Direction[] = ['bottom-end', 'bottom-start'];

export const TableTransactionsHeader: FC<TableTransactionsHeaderProps> = props => {
    const {
        companyId,
        defaultFilters,
        disabled,
        onFiltersChange,
        onAddClick,
        onExportClick,
        onCollateRegistryClick,
        className,
    } = props;

    const filters = useRef(defaultFilters);
    const { user: { is_support } } = useMeta();
    const menuOptions = useMenuOptions(onCollateRegistryClick);

    const handleExecutionDatesSubmit = useCallback<HandleDatesSubmit>(dateFieldsValues => {
        const newValues: TableTransactionsHeaderFields = {
            ...filters.current,
            execution_date__gte: dateFieldsValues.dateFrom,
            execution_date__lte: dateFieldsValues.dateTo,
        };

        onFiltersChange(newValues);
        filters.current = newValues;
    }, [filters, onFiltersChange]);

    const handleInvoiceDatesSubmit = useCallback<HandleDatesSubmit>(dateFieldsValues => {
        const newValues: TableTransactionsHeaderFields = {
            ...filters.current,
            invoice_date__gte: dateFieldsValues.dateFrom,
            invoice_date__lte: dateFieldsValues.dateTo,
        };

        onFiltersChange(newValues);
        filters.current = newValues;
    }, [filters, onFiltersChange]);

    const methods = useForm<EmployeeField>();

    useWatchEmployeeSuggest({ methods, onFiltersChange, filters });

    return (
        <div className={cn(null, [className])}>
            <FormProvider methods={methods}>
                <div className={cn('Filters')}>
                    <FieldSuggest
                        className={cn('Search')}
                        companyId={companyId}
                        disabled={disabled}
                        name="employeeSuggest"
                        type="persons"
                    />
                    <DatesDropdown
                        buttonText={i18n('execution_date')}
                        className={cn('FiltersExecution')}
                        dataTestId="execution-date-dropdown"
                        disabled={disabled}
                        onSubmit={handleExecutionDatesSubmit}
                    />
                    <DatesDropdown
                        buttonText={i18n('registry_date')}
                        className={cn('FiltersRegistry')}
                        dataTestId="registry-date-dropdown"
                        disabled={disabled}
                        onSubmit={handleInvoiceDatesSubmit}
                    />
                </div>
            </FormProvider>
            <div className={cn('Actions')}>
                <Button
                    disabled={disabled}
                    iconLeft={iconProviderIconPlus}
                    size="m"
                    view="default"
                    onClick={onAddClick}
                >
                    {i18n('add')}
                </Button>
                {!is_support && (
                    <>
                        <Button
                            disabled={disabled}
                            iconLeft={iconProviderIconDownload}
                            size="m"
                            view="default"
                            onClick={onExportClick}
                        >
                            {i18nAction('export')}
                        </Button>
                        <ButtonMenu
                            direction={popupDirections}
                            disabled={disabled}
                            icon={iconProviderMoveVertical}
                            menu={menuOptions}
                            size="m"
                            view="clear"
                        />
                    </>
                )}
            </div>
        </div>
    );
};

TableTransactionsHeader.displayName = cn();
