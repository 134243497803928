export const en = {
    all_seats_sold_out: 'All seats in the selected car have been bought out, you need to delete the service and get a new one',
    car: 'car',
    choose_new_seat: 'The chosen place has already been bought out, you need to choose a new place',
    class: 'Class',
    seat: 'seat',
    train: 'Train',
    paid_by_the_company: 'Paid by the company',
    paid_by_you: 'You pay for',
};
