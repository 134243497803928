import { Children, FC, useCallback, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

import { usePlatform } from 'hooks/usePlatform';
import { Drawer } from 'shared/ui/Drawer';
import { Modal } from 'shared/ui/Modal';

import { cn } from './ModalSlider.cn';
import { ModalSliderProps } from './ModalSlider.types';

import './ModalSlider.css';

export const ModalSlider: FC<ModalSliderProps> = props => {
    const {
        className,
        visible,
        onModalClose,
        autoHeight = true,
        children,
        innerRef,
        testId,
    } = props;

    const { isDesktop } = usePlatform();

    const swiperRef = useRef<SwiperType>();

    const handleBeforeInit = useCallback(swiper => {
        swiperRef.current = swiper;
    }, []);

    const handleSlideChangeTransitionStart = useCallback((swiper: SwiperType) => {
        swiper.updateAutoHeight();
    }, []);

    const childrenArray = Children.toArray(children);
    const slides = childrenArray.map((slide, index) => {
        return (
            <SwiperSlide key={index} className={cn('Slide')}>
                {slide}
            </SwiperSlide>
        );
    });

    if (isDesktop) {
        return (
            <Modal
                autoFocus={false}
                className={cn('Modal', [className])}
                innerRef={innerRef}
                scope={'inplace'}
                theme="normal"
                visible={visible}
                onClose={onModalClose}
            >
                <div data-testid={testId}>
                    <Swiper
                        allowTouchMove={false}
                        autoHeight={autoHeight}
                        className={cn('Swiper')}
                        slidesPerView={1}
                        spaceBetween={16}
                        onBeforeInit={handleBeforeInit}
                        onSlideChangeTransitionStart={handleSlideChangeTransitionStart}
                    >
                        { slides }
                    </Swiper>
                </div>
            </Modal>
        );
    }

    return (
        <Drawer
            className={cn('Dropdown', [className])}
            innerRef={innerRef}
            view="default"
            visible={visible}
            onClose={onModalClose}
        >
            <Swiper
                autoHeight
                allowTouchMove={false}
                className={cn('Swiper')}
                slidesPerView={1}
                spaceBetween={16}
                onBeforeInit={handleBeforeInit}
                onSlideChangeTransitionStart={handleSlideChangeTransitionStart}
            >
                { slides }
            </Swiper>
        </Drawer>
    );
};

ModalSlider.displayName = cn();
