import { FC } from 'react';

import { Card } from 'components/Card';
import { IconCheckCircle, IconInfo } from 'components/Icon';
import { Text } from 'shared/ui/Text';

import { cn } from '../PagePersonTrip.cn';

import { i18n } from './ApprovalStatus.i18n';
import { ApprovalStatusProps } from './ApprovalStatus.types';

import './ApprovalStatus.css';

export const ApprovalStatus: FC<ApprovalStatusProps> = props => {
    const {
        className,
        approved,
    } = props;

    const statusText = i18n(approved ? 'request_approved' : 'request_not_approved');
    const Icon = approved ? IconCheckCircle : IconInfo;

    return (
        <Card>
            <Text as="div" className={cn('ApprovalStatus', [className])} typography="body-m" weight="medium">
                <div className={cn('ApprovalStatusIcon', { approved })}>
                    <Icon />
                </div>
                <div>
                    {statusText}
                </div>
            </Text>
        </Card>
    );
};
