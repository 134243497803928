export const ru = {
    cities_must_be_filled: 'Города всегда должны быть заполнены',
    city_not_found: 'Город не найден: {query}',
    data_undefined: 'Нет данных',
    error_default_title: 'Произошла ошибка',
    error_default_message: 'Пожалуйста, попробуйте позже',
    error_in_link_generation: 'Неправильно сформирована ссылка',
    error_in_page_params: 'Ошибка в параметрах страницы',
    error_in_search_create: 'Не удалось запустить поиск',
    error_in_save_data: 'Не удалось сохранить данные. Попробуйте позже или обратитесь в поддержку за помощью',
    error_occurred: 'Произошла ошибка, пожалуйста, попробуйте позже или обратитесь в чат поддержки',
    incorrect_data_format: 'Неправильный тип данных',
    no_render_function: 'Рендер функция не указана',
    option_code_undefined: 'Option code is undefined',
    zero_flight_segments: 'Количество сегментов не может быть меньше одного',
    not_recognizable_error: 'Нераспознанная ошибка',
    type_not_exist: 'Указанного типа не существует',
    required_field: 'Обязательное поле',
    digits_only: 'Допустимы только цифры',
    invalid_date: 'Некорректная дата',
};
