export const en = {
    common_b2b_problem: 'error in B2B (deprecated)',
    consent_already_signed: 'consent to PD processing has already been signed',
    consent_not_signed: 'consent to PD processing has not been signed',
    consent_not_unavailable_in_country: "consent is not available in the user's country",
    email_not_sent: 'email was not sent',
    internal_service_problem: 'internal service error',
    not_enough_perm: 'insufficient permissions to perform the operation',
    not_found: 'the requested object was not found',
    settlement_not_found_by_geoid: 'locality not found by geoid',
    unexpected_company: 'unexpected company value for AK',
    unknown: 'unknown error',
    calendar_error: 'error while interacting with Calendar',
    idm_error: 'error during interaction with IDM',
    ok_error: 'error during interaction with OK',
    s3_key_not_found: 'error in the process of interacting with MDS',
    taxi_error: 'error in the process of interacting with Taxi',
    tracker_error: 'error in the process of interacting with Tracker',
    travel_error: 'error in the process of interacting with Travel',
    travel_policy_error: 'travel policy microservice availability error',
    aeroclub_error: 'Aeroclub provider error',
    aeroclub_bad_document_error: 'invalid document type on the AK side',
    aeroclub_bad_profile_error: 'invalid profile on the AK side',
    aeroclub_failed_add_custom_properties: 'failed to set additional attributes of a business trip on the AK side',
    aeroclub_failed_add_travellers: 'failed to add a traveler on the AK side',
    aeroclub_failed_execute_service: 'failed it turned out to issue a service on the AK side',
    aeroclub_failed_get_trip_auth_state: 'failed to get the authorization status of a business trip on the AK side',
    aeroclub_failed_get_free_seats: 'failed to get free seats from AK',
    aeroclub_failed_get_service_auth_state: 'failed to get the authorization status of services on the AK side',
    aeroclub_failed_reserve_service: 'service reservation error on the AK side',
    aeroclub_service_not_authorized: 'the service is not authorized on the AK side',
    aeroclub_source_not_found_error: 'data not found on the AK side',
    aeroclub_trip_not_created: 'the business trip was not created on the AK side',
    aeroclub_trip_not_authorized: 'the business trip was not authorized on the AK side',
    aviacenter_error: 'error of the Aviacenter provider',
    aviacenter_failed_add_traveller: 'error adding a traveler to the AD',
    aviacenter_failed_execute_service: 'service registration error on the AC side',
    aviacenter_failed_reserve_service: 'service reservation error on the AC side',
    aviacenter_passenger_not_found_in_service: 'the passenger is not attached to the service on the AC side',
    empty_travellers_list: 'empty list of travelers on the AK side',
    empty_billing_number: 'empty billing_number on the AAC side',
    provider_incorrect_search_id: 'invalid service search id',
    unavailable_provider_in_pt: 'unavailable provider for personal application',
    unavailable_service_reservation_in_aeroclub: 'it is impossible to book a service in AK',
    unavailable_provider_city_id: "search for services on the supplier's side for this locality is not available",
    chat_already_created: 'chat with PLP has already been created',
    chat_not_created: 'chat with PLP has not been created',
    unable_create_chat: 'it is impossible to create a chat with a PLP (for example, if there is no manager for a business trip)',
    empty_date_of_birth: 'date of birth not filled in',
    empty_gender: 'the floor is not set',
    empty_phone_number: 'the phone number is not filled in',
    empty_services_for_execute: 'empty list of services for registration',
    ext_person_not_available: '+1 is not available in this application',
    inactive_users_in_pt: 'inactive users in the application',
    incorrect_first_city_in_route: 'incorrect value of the first city in the business trip route - cannot be changed',
    incorrect_waypoints_in_route: 'incorrect list of route points on a business trip - cannot be changed',
    no_services_to_verification: 'there are no services that need manual approval/verification',
    pt_not_need_verification: 'a business trip does not need manual approval/verification',
    pt_need_verification: 'manual approval/verification is required for a business trip',
    pt_in_terminated_status: 'the business trip is in terminal status (closed or canceled) and cannot be changed',
    service_workflow_disabled: 'service registration is disabled',
    service_workflow_not_available_for_offline: 'service registration is not available for offline business trips',
    several_holdings_in_pt: 'several holdings in one business trip',
    unavailable_company: 'unsuitable company (legal entity) for an online business trip',
    wrong_ext_persons: 'invalid list of people for +1',
    wrong_number_of_approvers: 'incorrect number of matching',
    wrong_pt_status: 'invalid personal business trip status',
    incorrect_service_type_for_update: 'invalid service type to update',
    seat_already_selected: 'it is impossible to arrange seats, since they are already occupied',
    service_no_longer_available_for_execute: 'the service/location is no longer available for registration - repeat the search',
    wrong_service_document: 'no document has been selected for the service or an unsuitable document has been selected',
    wrong_service_status: 'the service is in the wrong status',
    document_already_add: 'the document has already been added to the service',
    document_duplicate: 'duplicate documents cannot be added',
    incorrect_ru_passport_number: 'incorrect Russian passport number',
    incorrect_ru_passport_number_len: 'incorrect length of the Russian passport number',
    pt_not_approved: 'The business trip has not been approved',
};
