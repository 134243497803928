import { i18nTravelPolicy } from 'i18n/i18nTravelPolicy';
import { Flight, Hotel, Train } from 'services/SwaggerApi';
import { BannerInline } from 'types/Notifications';

// TODO: Выпилить oldIsTpCompliant после включения новых ТП - BTRIP-9669
export const getTpBanner = (service: Hotel | Train | Flight, oldIsTpCompliant: boolean = true): BannerInline | null => {
    const {
        is_travel_policy_compliant,
        travel_policy_violations,
    } = service;

    const isTravelPolicyViolation = !oldIsTpCompliant || !is_travel_policy_compliant;

    if (isTravelPolicyViolation) {
        return {
            title: i18nTravelPolicy('not_compliant'),
            type: 'error',
            tooltipText: travel_policy_violations && travel_policy_violations.join('\n'),
        };
    }

    return null;
};
