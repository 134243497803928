import * as D from 'io-ts/Decoder';

export const ACOrderByDecoder = D.union(
    D.literal('price'),
    D.literal('duration'),
    D.literal('departure_time'),
    D.literal('arrival_time'),
    D.literal('contract'),
    D.literal('favorite'),
);

export type ACOrderByDecoder = D.TypeOf<typeof ACOrderByDecoder>;
