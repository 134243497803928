import { FC, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { FieldSuggest, FormProvider } from 'components/FormField2';
import { SelectDropdown } from 'components/SelectDropdown';
import { useMeta } from 'hooks/useMeta';
import { Button } from 'shared/ui/Button';

import { cn } from './TableEmployeesHeader.cn';
import { i18n } from './TableEmployeesHeader.i18n';
import {
    TableEmployeesHeaderFields,
    TableEmployeesHeaderProps,
} from './TableEmployeesHeader.types';

import './TableEmployeesHeader.css';

const roleFields = ['user', 'coordinator', 'assistant'] as const;
const accessFields = ['activated', 'blocked'] as const;

export const TableEmployeesHeader: FC<TableEmployeesHeaderProps> = props => {
    const {
        companyId,
        defaultFilters,
        disabled,
        className,
        onFiltersChange,
        onAddEmployeeClick,
    } = props;

    const { user: { is_allowed_account_creation } } = useMeta();

    const roleOptions = useMemo(() => roleFields.map(name => ({ name, label: i18n(name) })), []);
    const accessOptions = useMemo(() => accessFields.map(name => ({ name, label: i18n(name) })), []);

    const methods = useForm<TableEmployeesHeaderFields>({
        defaultValues: defaultFilters,
    });

    const handleFieldsChange = useCallback(data => {
        methods.reset({
            ...methods.getValues(),
            ...data,
        });
    }, [methods]);

    useEffect(() => {
        const subscription = methods.watch(data => {
            onFiltersChange(data as TableEmployeesHeaderFields);
        });

        return () => subscription.unsubscribe();
    }, [methods, onFiltersChange]);

    return (
        <FormProvider methods={methods}>
            <form className={cn(null, [className])}>
                <FieldSuggest
                    className={cn('Search')}
                    companyId={companyId}
                    disabled={disabled}
                    name="employeeSuggest"
                    type="persons"
                />
                <SelectDropdown
                    buttonText={i18n('role')}
                    checkboxFields={roleOptions}
                    disabled={disabled}
                    handleFieldsChange={handleFieldsChange}
                    testId="role-filter"
                />
                <SelectDropdown
                    buttonText={i18n('access')}
                    checkboxFields={accessOptions}
                    disabled={disabled}
                    handleFieldsChange={handleFieldsChange}
                />
                {
                    is_allowed_account_creation && (
                        <Button
                            className={cn('EmployeeAddButton')}
                            size="m"
                            view="default"
                            onClick={onAddEmployeeClick}
                        >
                            {i18n('add_employee')}
                        </Button>
                    )
                }
            </form>
        </FormProvider>
    );
};

TableEmployeesHeader.displayName = cn();
