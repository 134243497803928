import { FC } from 'react';
import { Icon } from '@yandex-int/hr-components/Icon/desktop/bundle';

import { Card } from 'components/Card';
import { Button } from 'shared/ui/Button';
import { ErrorState } from 'shared/ui/ErrorState';

import { cn } from '../TableTransactions.cn';
import { i18n } from '../TableTransactions.i18n';

import { EmptyStateProps } from './EmptyState.types';

import './EmptyState.css';

export const EmptyState: FC<EmptyStateProps> = props => {
    const {
        handleAddClick,
        className,
    } = props;

    const actions = handleAddClick ? [(
        <Button
            key="create"
            className={cn('AddButton')}
            size="m"
            view="primary"
            onClick={handleAddClick}
        >
            {i18n('add_transaction')}
        </Button>
    )] : [];

    return (
        <Card className={cn('EmptyState', [className])}>
            <ErrorState
                actions={actions}
                description={i18n('empty_table_description')}
                image={
                    <div className={cn('IconWrapper')}>
                        <Icon
                            glyph="addToList"
                            size="s"
                        />
                    </div>
                }
                size="s"
                title={i18n('empty_table_title')}
            />
        </Card>
    );
};
