import { FC, useCallback } from 'react';
import { useToggle } from 'react-use';

import { Card } from 'components/Card';
import { IconArrowShortDown, IconArrowShortUp, IconInfo } from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { HotelFeatureItem } from 'services/SwaggerApi';
import { Button } from 'shared/ui/Button';
import { ModalPlatform } from 'shared/ui/ModalPlatform';
import { Text } from 'shared/ui/Text';

import { cn } from '../HotelDetails.cn';

import { isGoodPlaceGroup } from './Features.utils/isGoodPlaceGroup';
import { i18n, i18nUntyped } from './Features.i18n';
import { FeaturesProps } from './Features.types';

import './Features.css';

const Feature = (feature: HotelFeatureItem) => {
    const featureText = feature.type === 'bool' ?
        feature.name :
        [feature.name, (feature.value as string[]).join(', ')].filter(Boolean).join(': ');

    return (
        <Text
            as="li"
            className={cn('Feature')}
            color="secondary"
            typography="body-s"
        >
            {featureText.charAt(0).toUpperCase() + featureText.slice(1)}
        </Text>
    );
};

const FeatureGroup = (group: { name: string; features: HotelFeatureItem[]; visible?: boolean}) => {
    const { isMobile } = usePlatform();
    const [visible, toggleVisible] = useToggle(group.visible || false);
    const IconToggle = visible ? IconArrowShortUp : IconArrowShortDown;

    const handleToggle = useCallback(() => isMobile && toggleVisible(), [isMobile, toggleVisible]);

    return (
        <div className={cn('FeatureGroup', { visible })}>
            <div className={cn('FeatureGroupHeader')} onClick={handleToggle}>
                <Text typography="body-s" weight="medium">
                    {group.name}
                </Text>
                {isMobile && <IconToggle size="s" />}
            </div>
            <ul className={cn('FeatureGroupItems', { visible })}>
                {group.features.map(feature => <Feature key={feature.feature_id} {...feature} />)}
            </ul>
        </div>
    );
};

export const Features: FC<FeaturesProps> = props => {
    const {
        className,
        features,
        popularFeatures,
    } = props;

    const { isDesktop, isMobile } = usePlatform();
    const [visible, toggleModal] = useToggle(false);
    const filteredFeatures = features.filter(group => !isGoodPlaceGroup(group));

    return (
        <div className={cn('Section', [className])}>
            <Text className={cn('Title')} color="secondary" typography={isMobile ? 'title-s' : 'title-m'}>
                {i18n('services_and_facilities')}
            </Text>

            <Card className={cn('Features', [className])}>
                <Text as="div" typography="title-s" weight="medium">
                    {i18n('popular_services')}
                </Text>
                <div className={cn('PopularFeatures')}>
                    {popularFeatures.map(id => (
                        <Text key={id} as="div" className={cn('PopularFeature')} typography="body-m">
                            <IconInfo size="l" />
                            {i18nUntyped(id)}
                        </Text>
                    ))}
                </div>

                <Button className={cn('FeaturesShow')} size="m" view="outline" onClick={toggleModal}>
                    {i18n('show_all')}
                </Button>

                <ModalPlatform
                    className={cn('FeaturesModal')}
                    title={i18n('services_and_facilities')}
                    toggleVisible={toggleModal}
                    visible={visible}
                >
                    <div className={cn('FeatureGroups')}>
                        {filteredFeatures.map((feature, index) =>
                            <FeatureGroup key={feature.name} {...feature} visible={index === 0 || isDesktop} />)}
                    </div>
                </ModalPlatform>
            </Card>
        </div>
    );
};
