export const en = {
    addDocumentLink: 'Add Document',
    addHotelLink: 'Add Hotel',
    add_airline_ticket: 'Add airline ticket',
    add_airline_ticket_back: 'Add airline ticket back',
    add_participants: 'Add participants',
    add_train_ticket: 'Add train ticket',
    add_train_ticket_back: 'Add train ticket back',
    add_train_ticket_to_destination: 'Add train ticket to destination',
    arrival_to_airport: 'Arrival to airport',
    arrival_to_railway: 'Arrival to railway station',
    boarding_train: 'Board train {code} {train}, car No. {carriage}',
    business_trip: 'Business trip',
    cancel: 'No',
    cancel_confirmation: 'Are you sure you want to cancel your trip?',
    cancel_confirmation_with_services:
        "We'll cancel your bookings, but keep in mind that most bookings are non-refundable or are subject to cancelation fees. Please contact support to learn more about possible fees before canceling the trip.",
    cancel_trip: 'Cancel my trip',
    check_in: 'Check in at "{name}", {address}',
    check_out: 'Check out',
    confirm: 'Yes, cancel',
    documentsHeader: 'Documents',
    fix_application_date: 'Cant create a search, because the application dates are in the past tense, correct them',
    flight_arrival: 'Flight to {city}, {airport_w_terminal}',
    flight_departure: 'Flight from {city}, {airport_w_terminal}',
    group_trip: 'Group application',
    hotelHeader: 'Hotel',
    other_services: 'Other services',
    stay_in: 'Stay in the hotel {hotel}, {address}',
    taxi_from_airport: 'Taxi from the airport {airport_w_terminal}',
    taxi_from_hotel: 'Taxi from the hotel {hotel}, {address}',
    taxi_from_rail: 'Taxi from railway station {station}',
    taxi_home: 'Taxi home',
    taxi_to_airport: 'Taxi to the airport {airport_w_terminal}',
    taxi_to_hotel: 'Taxi to the hotel {hotel}, {address}',
    taxi_to_rail: 'Taxi to railway station {station}',
    terminal: 'terminal',
    ticketsHeader: 'Tickets',
    train_arrival: 'Train arrival to {city}, {station}',
    train_departure: 'Train departure from {city}, {station}',
    tripLoading: 'Loading business trip',
    trip_number: 'Аpplication number {trip_id}',
    taxi_hint: 'The time is indicated approximately excluding traffic jams.',
    yes_understand: 'Yes, I understand',
};
