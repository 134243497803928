import { FC, useCallback, useContext } from 'react';
import { withBemMod } from '@bem-react/core';

import { ServiceType } from 'services/SwaggerApi';
import { getTypedI18nLang } from 'utils/i18n';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../../../SwaggerContext';
import { cn } from '../Suggest.cn';
import { i18n } from '../Suggest.i18n';
import {
    RenderOptionContent,
    RenderSelectedValue,
    SuggestProps,
} from '../Suggest.types';

export interface WithTypeBonusProps {
    type?: 'bonus';
}

export type BonusCard = {
    id: string;
    code: string;
    service_type: ServiceType;
    name: string;
};

type FetchBonusCards = (queryText: string) => Promise<BonusCard[]>;

export const withTypeBonus = withBemMod<WithTypeBonusProps, SuggestProps>(
    cn(),
    { type: 'bonus' },
    (Suggest): FC<WithTypeBonusProps & SuggestProps> => props => {
        const { api } = useContext(SwaggerContext);

        const dataprovider = useCallback<FetchBonusCards>(async text => {
            if (!text) {
                return [];
            }

            const result = await api.service_provider_suggest({ search_query: text })
                .then(throwHTTPErrors);

            const lang = getTypedI18nLang();

            return result.map(item => {
                const name = lang === 'ru' ? item.name : item.name_en;

                return {
                    id: `${item.service_type}_${item.code}`,
                    code: item.code,
                    service_type: item.service_type,
                    name,
                };
            }).slice(0, 10);
        }, [api]);

        const renderOptionContent = useCallback<RenderOptionContent>(item => {
            return <>{item.name}</>;
        }, []);

        const renderSelectedValue = useCallback<RenderSelectedValue>(item => {
            return `${item.name}`;
        }, []);

        return (
            <Suggest
                {...props}
                dataprovider={dataprovider}
                placeholder={i18n('bonus_placeholder')}
                renderOptionContent={renderOptionContent}
                renderSelectedValue={renderSelectedValue}
            />
        );
    },
);
