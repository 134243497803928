import { FC } from 'react';

import { withRemote } from 'hocs/withRemote';
import { usePersonDetailPublic } from 'hooks/usePerson';

import { PersonFields } from './PersonFields';
import { PersonFieldsContainerProps } from './PersonFields.types';

const PersonFieldsRemote = withRemote(PersonFields);

export const PersonFieldsContainer: FC<PersonFieldsContainerProps> = props => {
    const { value: staffPerson } = props;
    const person = usePersonDetailPublic(staffPerson.staff_id);

    return (
        <PersonFieldsRemote
            {...props}
            person={person}
        />
    );
};
