import { FC } from 'react';
import { ListItem as ListItemBase } from '@yandex-int/hr-components/ListItem/desktop/bundle';

import { Text } from 'shared/ui/Text';

import { cn } from './ListItem.cn';
import { ListItemProps, ListItemTextParams } from './ListItem.types';

import './ListItem.css';

const getTextContent = (type: 'leading' | 'trailing', params: ListItemTextParams) => {
    const {
        label,
        caption,
        size = 'm',
        inverse,
        typography,
    } = params;

    return (
        <div className={cn('Text', { type, inverse, onlyLabel: Boolean(label && !caption) })}>
            <Text
                className={cn('Label')}
                typography={typography ? typography : `label-${size}`}
            >
                {label}
            </Text>
            {caption && (
                <Text
                    className={cn('Caption')}
                    color="secondary"
                    typography="caption-m"
                >
                    {caption}
                </Text>
            )}
        </div>
    );
};

export const ListItem: FC<ListItemProps> = props => {
    const {
        leadingTextParams,
        leading,
        trailingTextParams,
        trailing,
        className,
        testId,
        transparent = true,
        expanded,
        border = 's',
        padding = 's',
        clickable,
        role,
        noVerticalPadding,
        ...rest
    } = props;

    const leadingTextContent = getTextContent('leading', leadingTextParams);
    const trailingTextContent = trailingTextParams ? getTextContent('trailing', trailingTextParams) : null;

    return (
        <ListItemBase
            {...rest}
            border={border}
            className={cn({ transparent, expanded, noVerticalPadding, clickable }, [className])}
            leading={leading}
            padding={padding}
            trailing={trailing}
        >
            <div className={cn('Container')} data-testid={testId} role={role}>
                {leadingTextContent}
                {trailingTextContent}
            </div>
        </ListItemBase>
    );
};

ListItem.displayName = cn();
