import { FC, useCallback, useContext } from 'react';
import { withBemMod } from '@bem-react/core';

import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../../../SwaggerContext';
import { cn } from '../Suggest.cn';
import { i18n } from '../Suggest.i18n';
import {
    RenderOptionContent,
    RenderSelectedValue,
    SuggestProps,
} from '../Suggest.types';

export interface WithTypeTravelPoliciesProps {
    type?: 'travelPolicies';
}

export const withTypeTravelPolicies = withBemMod<WithTypeTravelPoliciesProps, SuggestProps>(
    cn(),
    { type: 'travelPolicies' },
    (Suggest): FC<WithTypeTravelPoliciesProps & SuggestProps> => props => {
        const { placeholder = i18n('travel_policies_placeholder') } = props;

        const { api } = useContext(SwaggerContext);

        const dataprovider = useCallback(async(text = '') => {
            const { found } = await api.suggest_travel_policies({ name: text }).then(throwHTTPErrors);

            return found.map(tp => ({
                id: tp.travel_policy_id,
                title: tp.name,
            }));
        }, [api]);

        const renderOptionContent = useCallback<RenderOptionContent>(item => {
            return <>{item.title}</>;
        }, []);

        const renderSelectedValue = useCallback<RenderSelectedValue>(item => {
            return `${item.title}`;
        }, []);

        return (
            <Suggest
                {...props}
                dataprovider={dataprovider}
                placeholder={placeholder}
                renderOptionContent={renderOptionContent}
                renderSelectedValue={renderSelectedValue}
            />
        );
    },
);
