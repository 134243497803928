import { FC } from 'react';

import { Table } from 'components/Table';

import { useColumns } from './hooks/useColumns';
import { cn } from './TableCompanies.cn';
import { TableCompaniesProps } from './TableCompanies.types';

import './TableCompanies.css';

const defaultColumn = {
    width: 180,
};

export const TableCompanies: FC<TableCompaniesProps> = props => {
    const {
        data: { data: companies },
        pageCount,
        pageIndex,
        pageSize,
        className,
    } = props;

    const columns = useColumns();

    return (
        <Table
            hasStickyColumns
            className={cn('Table', [className])}
            columns={columns}
            data={companies}
            defaultColumn={defaultColumn}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
        />
    );
};

TableCompanies.displayName = cn();
