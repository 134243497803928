import { getFlightEvents } from './getFlightEvents';
import { getHotelEvents } from './getHotelEvents';
import { getTrainEvents } from './getTrainEvents';
import { switchTypeFn } from './switchTypeFn';

export const generateEvents = switchTypeFn({
    rail: getTrainEvents,
    avia: getFlightEvents,
    hotel: getHotelEvents,
});
