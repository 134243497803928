import { FC } from 'react';

import { Card } from 'components/Card';
import { PersonContactFields } from 'components/FormPersonDetails';
import { Text } from 'shared/ui/Text';

import { cn } from '../PageCompanionAdd.cn';
import { i18n } from '../PageCompanionAdd.i18n';

export const ContactPersonalInfo: FC = () => {
    return (
        <Card className={cn('ContactInfo')}>
            <div className={cn('FieldsTitle')}>
                <Text typography="title-s">
                    {i18n('contact_info')}
                </Text>
                <Text color="secondary" typography="body-s">
                    {i18n('contact_info_for')}
                </Text>
            </div>
            <PersonContactFields allEditable />
        </Card>
    );
};
