import { useToggle } from 'react-use';

import { DropdownProps } from '../Dropdown.types';

type GetHandlersOptions = {
    desktopTrigger: DropdownProps['desktopTrigger'];
    disabled: DropdownProps['disabled'];
    isMobile: boolean;
    toggleVisible: ReturnType<typeof useToggle>[1];
};

export const getHandlers = (options: GetHandlersOptions) => {
    const { desktopTrigger, isMobile, toggleVisible, disabled } = options;

    if (disabled) {
        return {};
    }

    if (isMobile || desktopTrigger === 'click') {
        return {
            onClick: toggleVisible,
        };
    }

    return {
        onMouseEnter: () => toggleVisible(true),
        onMouseLeave: () => toggleVisible(false),
    };
};
