import { FC, useCallback } from 'react';
import { useToggle } from 'react-use';

import { RegistrationFeedbackForm } from 'components/RegistrationFeedbackForm';
import { withModalView } from 'hocs/withModalView';
import { Button } from 'shared/ui/Button';

import { cn } from './RegistrationFeedbackButton.cn';
import { i18n } from './RegistrationFeedbackButton.i18n';
import { RegistrationFeedbackButtonProps } from './RegistrationFeedbackButton.types';

import './RegistrationFeedbackButton.css';

const RegistrationFeedbackFormModal = withModalView(RegistrationFeedbackForm);

export const RegistrationFeedbackButton: FC<RegistrationFeedbackButtonProps> = props => {
    const {
        className,
    } = props;

    const [isModalVisible, setModalVisible] = useToggle(false);

    const handleButtonClick = useCallback(() => {
        setModalVisible(true);
    }, [setModalVisible]);

    const handleClose = useCallback(() => {
        setModalVisible(false);
    }, [setModalVisible]);

    return (
        <>
            <Button
                className={cn(null, [className])}
                pin="circle-circle"
                size="l"
                view="raised"
                onClick={handleButtonClick}
            >
                {i18n('need_help')}
            </Button>
            <RegistrationFeedbackFormModal
                modalViewOnCloseOff
                className={cn('Modal')}
                modalViewOnClose={handleClose}
                modalViewVisible={isModalVisible}
                onClose={handleClose}
            />
        </>
    );
};

RegistrationFeedbackButton.displayName = cn();
