import { TTransaction_listQuery } from 'services/SwaggerApi';
import { serializeDate } from 'utils/serializeDate';
import { serializePerson } from 'utils/serializePerson';

import { TableTransactionsFilters } from '../TableTransactions.types';

type SerializeFilters = (filters: TableTransactionsFilters) => TTransaction_listQuery;

export const serializeFilters: SerializeFilters = filters => ({
    execution_date__gte: serializeDate(filters.execution_date__gte),
    execution_date__lte: serializeDate(filters.execution_date__lte),
    invoice_date__gte: serializeDate(filters.invoice_date__gte),
    invoice_date__lte: serializeDate(filters.invoice_date__lte),
    person_id: serializePerson(filters.employeeSuggest),
});
