export const ru = {
    error_message: 'Пожалуйста, попробуйте позже',
    error_title: 'Произошла ошибка',
    execution_banned_title: 'Оформление услуг временно приостановлено',
    execution_banned_message: 'Обратитесь к координатору вашей компании',
    missing_document_message: 'Пожалуйста, добавьте паспорт в услуги и отправьте на оформление еще раз',
    missing_document_title: {
        one: 'Для {count} услуги не выбран паспорт',
        some: 'Для {count} услуг не выбран паспорт',
        many: 'Для {count} услуг не выбран паспорт',
    },
    send_services_confirmation: 'Все услуги будут отправлены на {target}',
    b2b_not_complaint_title: 'Оформим после согласования',
    b2b_not_complaint_message: 'Вы добавили услуги, которые превышают лимиты тревел-политики вашей компании. Всё будет оформлено только после согласования координатором.',
    send_services_success: 'Услуги отправлены на {target}',
    execution: 'оформление',
    verification: 'подтверждение',
    will_work: 'Буду работать',
    will_chill: 'Не буду работать',
    working_holidays_modal_title: 'Работа в выходные дни',
    choose_dates_label: 'Даты, в которые планируете работать',
    choose_dates_placeholder: 'Выбрать даты',
    reason_label: 'Почему будете работать в эти дни?',
    subheader_working_holidays_1: 'Обратите внимание:',
    recommendations_working_holidays: 'лучшее время для командировки — будние дни;\nрекомендуется выбирать рейсы с вылетом и прилетом в один и тот же день, без перехода на следующие сутки;\nпроживание в гостинице с субботы на воскресенье оплачивается сотрудником самостоятельно, если в этом нет рабочей необходимости.',
    working_holidays_1: 'Нажимайте кнопку "Буду работать" только в том случае, если вы действительно будете работать в выходной день и это сможет подтвердить руководитель в тикете TRAVEL.',
    subheader_working_holidays_2: 'Вы выбрали "Не буду работать"',
    working_holidays_2: 'Напоминаем, что стоимость проживания в отеле в выходные и праздничные дни, добавленные по вашей инициативе к началу или концу командировки, компания не компенсирует.',
    working_holidays_3: 'После нажатия кнопки подтвердить все услуги будут отправлены на оформление.',
    weekend_date: '{date}',
    holiday_date: '{date} (праздничный день)',
    weekday_date: '{date}',
    need_to_add_services: 'Нужно добавить услуги',
    execute_trip: 'Оформить командировку',
};
