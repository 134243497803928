import * as yup from 'yup';

import { BonusCard } from 'components/Suggest/_type/Suggest_type_bonus';

export const schema = yup.object({
    bonus_card: yup.mixed<BonusCard>().required(),
    card_number: yup.string().required().max(64),
});

export type BonusCardSchema = yup.InferType<typeof schema>;
