import { createContext } from 'react';
import { FieldValues, FormProvider as RHFProvider } from 'react-hook-form';
import * as yup from 'yup';

import { SchemaContext } from '../utils/yupconfig';

import { parseServerErrors } from './FormProvider.utils/parseServerErrors';
import { FormProviderProps, ServerErrorsContextProps } from './FormProvider.types';

export const ServerErrorsContext = createContext({} as ServerErrorsContextProps);

export function FormProvider<TFieldValues extends FieldValues>(props: FormProviderProps<TFieldValues>) {
    const {
        children,
        error,
        methods,
        schema = yup.object({}),
    } = props;

    return (
        <ServerErrorsContext.Provider value={parseServerErrors(error)}>
            <SchemaContext.Provider value={schema}>
                <RHFProvider {...methods}>
                    {children}
                </RHFProvider>
            </SchemaContext.Provider>
        </ServerErrorsContext.Provider>
    );
}
