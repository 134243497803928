import { FC } from 'react';

import { withRemote } from 'hocs/withRemote';
import { useTaxiDetails } from 'hooks/useTaxiDetails';

import { TaxiDetails } from './TaxiDetails';
import { cn } from './TaxiDetails.cn';
import { TaxiDetailsContainerProps } from './TaxiDetails.types';

const TaxiDetailsRemote = withRemote(TaxiDetails);

export const TaxiDetailsContainer: FC<TaxiDetailsContainerProps> = props => {
    const {
        tripId,
        personId,
    } = props;

    const taxiDetails = useTaxiDetails(tripId, personId);

    return (
        <TaxiDetailsRemote
            data={taxiDetails}
            fallbackElement={<></>}
        />
    );
};

TaxiDetailsContainer.displayName = cn();
