import { FC } from 'react';

import { AppLink } from 'components/AppLink';
import { RouteTripCreate } from 'routes/RouteTripCreate';
import { InlineNotification } from 'shared/ui/InlineNotification';

import { cn } from './MessageBanner.cn';
import { i18n } from './MessageBanner.i18n';
import { MessageBannerProps } from './MessageBanner.types';

import './MessageBanner.css';

export const MessageBanner: FC<MessageBannerProps> = props => {
    const { messageTag } = props;

    return (
        <InlineNotification
            className={cn(null, [props.className])}
            view="info"
        >
            {i18n('to_proceed')}
            {' '}
            {messageTag === 'create_trip' ? (
                <AppLink href={RouteTripCreate.getPath({}, {})} theme="link">
                    {i18n(messageTag)}
                </AppLink>
            ) : (
                i18n(messageTag)
            )}
        </InlineNotification>
    );
};

MessageBanner.displayName = cn();
