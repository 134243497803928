import { useCallback, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { DatesDropdownFields, SetLastApplied } from '../DatesDropdown.types';

export type UseLastApplied = (methods: UseFormReturn<DatesDropdownFields>) => {
    resetToLastApplied: FunctionVoid;
    setLastApplied: SetLastApplied;
};

export const useLastApplied: UseLastApplied = methods => {
    const { getValues, reset } = methods;

    const values = getValues();

    const lastApplied = useRef(values);

    const resetToLastApplied = useCallback(() => {
        reset(lastApplied.current);
    }, [reset]);

    const setLastApplied = useCallback<SetLastApplied>(datesValues => {
        lastApplied.current = datesValues;
    }, []);

    return { resetToLastApplied, setLastApplied };
};
