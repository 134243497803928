import { PageCompanies } from 'components/PageCompanies';
import { PageCompanion } from 'components/PageCompanion';
import { PageCompanionAdd } from 'components/PageCompanionAdd';
import { PageCompanyDetails } from 'components/PageCompanyDetails';
import { PageFinance } from 'components/PageFinance';
import { PageGroup } from 'components/PageGroup';
import { PageGroupAdd } from 'components/PageGroupAdd';
import { PageHotelRooms } from 'components/PageHotelRooms';
import { PageMyActiveTrips } from 'components/PageMyActiveTrips';
import { PageNotFound } from 'components/PageNotFound';
import { PagePersonTrip } from 'components/PagePersonTrip';
import { PageProfile } from 'components/PageProfile';
import { PageRegister } from 'components/PageRegister';
import { PageRegisterCompany } from 'components/PageRegisterCompany';
import { PageRegisterEmail } from 'components/PageRegisterEmail';
import { PageRegisterForbidden } from 'components/PageRegisterForbidden';
import { PageRegisterRejected } from 'components/PageRegisterRejected';
import { PageSearch } from 'components/PageSearch';
import { PageSearchMap } from 'components/PageSearchMap';
import { PageServiceDetails } from 'components/PageServiceDetails';
import { PageStaff } from 'components/PageStaff';
import { PageTravelPolicy } from 'components/PageTravelPolicy';
import { PageTravelPolicyAdd } from 'components/PageTravelPolicyAdd';
import { PageTripCreate } from 'components/PageTripCreate';
import { PageTrips } from 'components/PageTrips';
import { Page, PageParams } from 'types/Page';
import { Route, RouteConfig, RouteSearchParams } from 'types/Route';
import { RoutePathParams } from 'types/RoutePathParams';

import { RouteCompanies } from './RouteCompanies';
import { RouteCompanion } from './RouteCompanion';
import { RouteCompanionAdd } from './RouteCompanionAdd';
import { RouteCompanyDetails } from './RouteCompanyDetails';
import { RouteFinance } from './RouteFinance';
import { RouteGroup } from './RouteGroup';
import { RouteGroupAdd } from './RouteGroupAdd';
import { RouteHotelRooms } from './RouteHotelRooms';
import { RouteMyActiveTrips } from './RouteMyActiveTrips';
import { RouteNotFound } from './RouteNotFound';
import { RoutePersonTrip } from './RoutePersonTrip';
import { RouteProfile } from './RouteProfile';
import { RouteRegister } from './RouteRegister';
import { RouteRegisterCompany } from './RouteRegisterCompany';
import { RouteRegisterEmail } from './RouteRegisterEmail';
import { RouteRegisterForbidden } from './RouteRegisterForbidden';
import { RouteRegisterRejected } from './RouteRegisterRejected';
import { RouteSearch } from './RouteSearch';
import { RouteSearchMap } from './RouteSearchMap';
import { RouteServiceDetails } from './RouteServiceDetails';
import { RouteStaff } from './RouteStaff';
import { RouteTravelPolicy } from './RouteTravelPolicy';
import { RouteTravelPolicyAdd } from './RouteTravelPolicyAdd';
import { RouteTripCreate } from './RouteTripCreate';
import { RouteTrips } from './RouteTrips';

const merge = <
    P extends RoutePathParams,
    S extends RouteSearchParams
>(route: RouteConfig<P, S>, component: Page<PageParams<P, S>>): Route<P, S> => ({ ...route, component });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const routes: Route<any, any>[] = [
    merge(RouteCompanion, PageCompanion),
    merge(RouteCompanionAdd, PageCompanionAdd),
    merge(RouteCompanyDetails, PageCompanyDetails),
    merge(RouteFinance, PageFinance),
    merge(RouteGroupAdd, PageGroupAdd),
    merge(RouteGroup, PageGroup),
    merge(RouteTravelPolicyAdd, PageTravelPolicyAdd),
    merge(RouteTravelPolicy, PageTravelPolicy),
    merge(RouteMyActiveTrips, PageMyActiveTrips),
    merge(RouteProfile, PageProfile),
    merge(RouteTrips, PageTrips),
    merge(RouteTripCreate, PageTripCreate),
    merge(RoutePersonTrip, PagePersonTrip),
    merge(RouteRegister, PageRegister),
    merge(RouteRegisterCompany, PageRegisterCompany),
    merge(RouteRegisterEmail, PageRegisterEmail),
    merge(RouteRegisterForbidden, PageRegisterForbidden),
    merge(RouteRegisterRejected, PageRegisterRejected),
    merge(RouteSearchMap, PageSearchMap),
    merge(RouteSearch, PageSearch),
    merge(RouteStaff, PageStaff),
    merge(RouteHotelRooms, PageHotelRooms),
    merge(RouteServiceDetails, PageServiceDetails),
    merge(RouteCompanies, PageCompanies),
    merge(RouteNotFound, PageNotFound),
];
