import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { usePage } from 'hooks/usePage';
import { usePlatform } from 'hooks/usePlatform';
import { useRegisterRedirect } from 'hooks/useRegisterRedirect';

import { PageWrapper } from '../PageWrapper/PageWrapper';
import { useScrollTopOnNewPage } from '../Root.hooks/useScrollTopOnNewPage';

import { RootPage } from './Page.types';

export const Page: RootPage = props => {
    const {
        component: PageComponent,
        path,
        routeOptions,
        meta,
    } = props;

    const { params, search } = usePage(props);
    const { isDesktop } = usePlatform();
    const { shouldWrap } = routeOptions;

    useEffect(() => {
        if (window && window.Ya && window.Ya.Rum) {
            window.Ya.Rum.updateErrors({
                platform: isDesktop ? 'desktop' : 'touch',
                page: PageComponent.displayName,
            });
        }
    }, [PageComponent.displayName, isDesktop]);

    useScrollTopOnNewPage();

    const redirectPath = useRegisterRedirect(path, meta);

    if (redirectPath) {
        return <Redirect to={redirectPath} />;
    }

    // для роутов регистрации не нужна шапка и многое другое
    if (!shouldWrap) {
        return <PageComponent params={params} search={search} />;
    }

    const urlParamsCompanyId = params.companyId ? Number(params.companyId) : null;

    return (
        <PageWrapper
            displayName={PageComponent.displayName}
            routeOptions={routeOptions}
            urlParamsCompanyId={urlParamsCompanyId}
        >
            <PageComponent
                params={params}
                search={search}
            />
        </PageWrapper>
    );
};
