import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FieldSuggest, FormProvider } from 'components/FormField2';

import { cn } from './CompanySuggest.cn';
import { CompanyFormFields, CompanySuggestProps } from './CompanySuggest.types';

import './CompanySuggest.css';

export const CompanySuggest: FC<CompanySuggestProps> = props => {
    const { className, defaultValues, onChange } = props;

    const methods = useForm<CompanyFormFields>({
        defaultValues,
    });

    const currentCompany = methods.watch('currentCompany');

    useEffect(() => {
        if (currentCompany) {
            onChange(Number(currentCompany.id));
        }
    }, [currentCompany, onChange]);

    return (
        <FormProvider methods={methods}>
            <FieldSuggest
                className={cn(null, [className])}
                name="currentCompany"
                type="companies"
            />
        </FormProvider>
    );
};

CompanySuggest.displayName = cn();
