import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { getIconProvider, IconArrowShortRight, IconPlus } from 'components/Icon';
import { PersonListItem } from 'components/PersonListItem';
import { RouteCompanion } from 'routes/RouteCompanion';
import { RouteCompanionAdd } from 'routes/RouteCompanionAdd';
import { Button } from 'shared/ui/Button';

import { cn } from './CompanionsList.cn';
import { i18n } from './CompanionsList.i18n';
import { CompanionsListProps } from './CompanionsList.types';
import { EmptyState } from './EmptyState';

import './CompanionsList.css';

const addIcon = getIconProvider(IconPlus);

export const CompanionsList: FC<CompanionsListProps> = props => {
    const {
        companions,
        className,
    } = props;

    const { push } = useHistory();

    const handleAddClick = useCallback(() => {
        push(RouteCompanionAdd.getPath({}, {}));
    }, [push]);

    const handleViewClick = useCallback((id: string) => () => {
        push(RouteCompanion.getPath({ extPersonId: id }, {}));
    }, [push]);

    const isEmpty = companions.length === 0;

    return (
        <div className={cn(null, [className])}>
            {
                isEmpty ? (
                    <EmptyState handleAddClick={handleAddClick} />
                ) : (
                    <>
                        <div className={cn('List')}>
                            {companions.map(item => (
                                <PersonListItem
                                    key={item.ext_person_uuid}
                                    external
                                    className={cn('Item')}
                                    name={`${item.first_name} ${item.last_name}`}
                                    size="m"
                                    trailing={<IconArrowShortRight size="m" />}
                                    onClick={handleViewClick(item.ext_person_uuid)}
                                />
                            ))}
                        </div>
                        <Button
                            className={cn('AddButton')}
                            iconLeft={addIcon}
                            size="m"
                            view="outline"
                            onClick={handleAddClick}
                        >
                            {i18n('companion_add')}
                        </Button>
                    </>
                )
            }
        </div>
    );
};

CompanionsList.displayName = cn();
