import { CarriageType } from 'utils/aeroclub/getCarriageTypes';

export const getPreferredCarriageType = (carriageTypes: CarriageType[]): CarriageType => {
    const preferredOrder: CarriageType[] = ['coupe', 'reserved', 'seat', 'common', 'lux', 'vip'];

    for (const preferredType of preferredOrder) {
        if (carriageTypes.includes(preferredType)) {
            return preferredType;
        }
    }

    return carriageTypes[0];
};
