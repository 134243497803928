import dayjs from 'dayjs';

import { DateFormat } from '../formatDate';

type FieldDateValue = Date | undefined | null;

/**
 * Приводит поля `K` из типа `T` к типу Date для использования с компонентом Datepicker
 * ```ts
 * type TypeWithDateAsString = {
 *  expiresAt: string;
 * }
 * type TypeWithDate = StringsToDates<TypeWithDateAsString, 'expiresAt'> // { expiresAt: Date | undefined }
 * ```
 */
export type StringsToDates<T, K extends keyof T> = {
    [Key in keyof T]: Key extends K ? FieldDateValue : T[Key];
};

/**
 * Сериализирует дату и приводит ее к строке в формате 'yyyy-mm-dd'.
 * @param date Дата в формате Date.
 * @returns Сериализованная дата приведенная к строке.
*/

export function serializeDate (date: Date): string;
export function serializeDate (date: null | undefined): undefined;
export function serializeDate (date: Date | null | undefined): string | undefined;
export function serializeDate(date: FieldDateValue) {
    if (date instanceof Date && dayjs(date).isValid()) {
        return dayjs(date).format(DateFormat.YYYY_MM_DD);
    }

    return undefined;
}
