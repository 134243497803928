import { FC } from 'react';
import { SwiperSlide } from 'swiper/react';

import { ConfirmationSlide } from 'components/ConfirmationModal/ConfirmationModal';
import { ModalSlider } from 'components/ModalSlider';
import { i18nAction } from 'i18n/i18nAction';

import { cn } from '../EmployeeGroupAddModal.cn';
import { i18n } from '../EmployeeGroupAddModal.i18n';
import { FormSlide } from '../FormSlide';

import { ContentProps } from './Content.types';

export const Content: FC<ContentProps> = props => {
    const {
        visible,
        onModalClose,
        onExcludeClick,
        onReplaceClick,
        onSubmit,
        membersIds,
        companyId,
        setCheckedPersons,
    } = props;

    if (!visible) {
        return null;
    }

    return (
        <ModalSlider autoHeight={false} visible={visible} onModalClose={onModalClose}>
            <SwiperSlide className={cn('Slide')}>
                <div className={cn('Content')}>
                    <FormSlide
                        companyId={companyId}
                        membersIds={membersIds}
                        setCheckedPersons={setCheckedPersons}
                        onCancel={onModalClose}
                        onSubmit={onSubmit}
                    />
                </div>
            </SwiperSlide>
            <SwiperSlide className={cn('Slide')}>
                <div className={cn('Content', { size: 's' })}>
                    <ConfirmationSlide
                        cancelText={i18nAction('no')}
                        confirmText={i18nAction('yes')}
                        message={i18n('confirmation_info')}
                        title={i18n('confirmation_title')}
                        onCancel={onExcludeClick}
                        onSubmit={onReplaceClick}
                    />
                </div>
            </SwiperSlide>
        </ModalSlider>
    );
};
