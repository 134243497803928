import { FC } from 'react';

import { SupportChatButton } from 'components/SupportChatButton';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { ErrorState } from 'shared/ui/ErrorState';

import { cn } from '../PageGroup.cn';
import { i18n } from '../PageGroup.i18n';

import './EmptyState.css';

const refreshCurrentPage = () => {
    window.location.reload();
};

export const EmptyState: FC = () => {
    const actions = [
        <Button key="refresh-button" size="l" view="primary" width="max" onClick={refreshCurrentPage}>
            {i18nAction('refresh_page')}
        </Button>,
        <SupportChatButton
            key="support-button"
            className={cn('SupportChatButton')}
            size="l"
            view="ghost"
            width="max"
        >
            {i18nAction('chat_with_support')}
        </SupportChatButton>,
    ];

    return (
        <div className={cn('EmptyState')}>
            <ErrorState
                actions={actions}
                description={i18n('error_description')}
                size="m"
                title={i18n('error_title')}
            />
        </div>
    );
};
