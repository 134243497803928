import * as t from 'io-ts';

const PlaceType = t.type({
    type: t.string,
    id: t.string,
    name: t.string,
}, 'PlaceType');

const FlightArrivalDeparture = t.type({
    type: t.string,
    id: t.string,
    name: t.string,
    terminal: t.string,
    airport: PlaceType,
    country: PlaceType,
    city: PlaceType,
}, 'FlightArrival');

const FlightLegSegment = t.type({
    arrival: FlightArrivalDeparture,
    arrival_at: t.string,
    arrival_at_timezone_offset: t.string,
    departure: FlightArrivalDeparture,
    departure_at: t.string,
    departure_at_timezone_offset: t.string,
    seats: t.number,
    flight_number: t.string,
    flight_duration: t.number,
    transfer_duration: t.number,
    is_baggage_included: t.boolean,
    comment: t.string,
    baggage: t.unknown,
    cabin_baggage: t.unknown,
    flight_class: t.string,
    carrier: PlaceType,
    fare_code: t.string,
    aircraft: PlaceType,
}, 'FlightLegSegment');

const FlightLeg = t.type({
    segments: t.array(FlightLegSegment),
    segments_count: t.number,
    route_duration: t.number,
}, 'FlightLeg');

export const FlightService = t.type({
    id: t.string,
    price: t.string,
    is_refundable: t.boolean,
    is_changeable: t.boolean,
    is_travel_policy_compliant: t.boolean,
    legs: t.array(FlightLeg),
}, 'FlightService');

export type FlightService = t.TypeOf<typeof FlightService>;
