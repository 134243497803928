export const en = {
    error_message: 'Please try again later',
    error_title: 'An error occurred',
    execution_banned_title: 'Execution of services is temporarily suspended',
    execution_banned_message: "Contact your company's coordinator",
    missing_document_message: 'Please add your passport to the services and book them again',
    missing_document_title: {
        one: 'Passport not selected for {count} service',
        some: 'Passport not selected for {count} services',
        many: 'Passport not selected for {count} services',
    },
    send_services_confirmation: 'All services will be sent for {target}',
    b2b_not_complaint_title: "We'll do it after approval",
    b2b_not_complaint_message: "You have added services that exceed the limits of your company's travel policy. Everything will be processed only after approval by the coordinator.",
    send_services_success: 'Services sent for {target}',
    execution: 'execution',
    verification: 'verification',
    will_work: 'Will work',
    will_chill: 'Won`t work',
    working_holidays_modal_title: 'Working on weekends',
    choose_dates_label: 'Dates on which you plan to work',
    choose_dates_placeholder: 'Select dates',
    reason_label: 'Why will you work these days?',
    subheader_working_holidays_1: 'Pay attention:',
    recommendations_working_holidays: 'the best time for a business trip is on weekdays;\nit is recommended to choose flights with departure and arrival on the same day, without switching to the next day;\nstaying at the hotel from Saturday to Sunday is paid by the employee independently, if there is no work need.',
    working_holidays_1: 'Click the "I will work" button only if you will actually work on a day off and this can be confirmed by the manager in the TRAVEL ticket.',
    subheader_working_holidays_2: 'You have chosen "I will not work"',
    working_holidays_2: 'We remind you that the cost of staying at the hotel on weekends and holidays, added on your initiative to the beginning or end of a business trip, will not be compensated by the company.',
    working_holidays_3: 'After clicking the confirm button, all services will be sent for registration.',
    weekend_date: '{date}',
    holiday_date: '{date} (holiday)',
    weekday_date: '{date}',
    need_to_add_services: 'You need to add services',
    execute_trip: 'Execute business trip',
};
