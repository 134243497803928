import { i18nFlightDirection } from 'i18n/i18nFlightDirection';
import { Flight } from 'services/SwaggerApi';

export const getFlightDatesText = (flight: Flight) => {
    if (flight.legs?.length === 1) {
        return i18nFlightDirection('one_way');
    }

    if (flight.legs?.length === 2) {
        return i18nFlightDirection('round_trip');
    }

    return '';
};
