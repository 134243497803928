import { MetaResponse, UserResponse } from 'services/SwaggerApi';

export function overrideMetaUser(meta: MetaResponse, user: Partial<UserResponse>): MetaResponse {
    return {
        ...meta,
        user: {
            ...meta.user,
            ...user,
        },
    };
}
