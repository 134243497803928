import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_paragraph-m.css';

export type WithTypographyParagraphMProps = {
    typography?: 'paragraph-m';
};

export const withTypographyParagraphM = withBemMod<WithTypographyParagraphMProps>(
    cn(),
    { typography: 'paragraph-m' },
);
