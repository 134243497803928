import { FC, useEffect } from 'react';
import { useToggle } from 'react-use';
import { Skeleton } from '@yandex-int/hr-components/Skeleton';

import { Card } from 'components/Card';
import { RestrictionsDetail } from 'components/CovidRestrictions/RestrictionsDetail';
import { getIconProvider, IconArrowShortRight, IconClose } from 'components/Icon';
import { Remote } from 'components/Remote';
import { UnknownError } from 'errors';
import { useCovidRestrictions } from 'hooks/useCovidRestrictions';
import { usePlatform } from 'hooks/usePlatform';
import countries from 'shared/constants/countries.json';
import { Button } from 'shared/ui/Button';
import { Drawer } from 'shared/ui/Drawer';
import { Modal } from 'shared/ui/Modal';
import { Text } from 'shared/ui/Text';
import { getTypedI18nLang } from 'utils/i18n';
import { isFailure } from 'utils/Loadable';
import { logError } from 'utils/logError';

import { getIcon } from './utils/getIcon';
import { getRestrictionText } from './utils/getRestrictionText';
import { cn } from './CovidRestrictions.cn';
import { CovidRestrictionsProps } from './CovidRestrictions.types';

import './CovidRestrictions.css';

const CovidInfoSkeleton = <Skeleton style={{ height: '84px', borderRadius: '16px' }} type="control" />;

const iconProviderArrowRight = getIconProvider(IconArrowShortRight, { size: 'm' });
const iconProviderClose = getIconProvider(IconClose, { size: 'l' });

export const CovidRestrictions: FC<CovidRestrictionsProps> = props => {
    const { country: countryName } = props;
    const countryNameWithoutYE = countryName.replace('ё', 'е');
    const country = countries.find(c => c.title_ru === countryNameWithoutYE || c.title_en === countryNameWithoutYE);
    const country_code = country?.id || 0;
    const language = getTypedI18nLang();

    const [visible, setVisible] = useToggle(false);

    const { isDesktop } = usePlatform();
    const covidRestrictions = useCovidRestrictions({ country_code });

    useEffect(() => {
        if (!country) {
            logError(new UnknownError(`Не найдена страна с названием ${countryName}`));
        }
    }, [country, countryName]);

    if (country_code === 0 || isFailure(covidRestrictions)) {
        return null;
    }

    return (
        <Remote data={covidRestrictions} skeleton={CovidInfoSkeleton}>
            {({ data: covidRestrictions }) => {
                const { entranceForRussians } = covidRestrictions;

                const tourismText = getRestrictionText('tourismAvailability', covidRestrictions);
                const countryText = language === 'ru' ? country?.title_ru : country?.title_en;
                const visaText = getRestrictionText('visaRequired', covidRestrictions);
                const pcrText = getRestrictionText('pcrExpirationPeriodInHoursOrRequired', covidRestrictions);

                const shortRestrictionText = [visaText, pcrText].join(' · ');

                const isFreeEntrance = entranceForRussians.value !== 'noEntrance';

                const { IconComponent, iconColor } = getIcon(isFreeEntrance);

                return (
                    <>
                        <Card className={cn()} onClick={setVisible}>
                            <Text className={cn('Info')} color={iconColor} typography="body-m">
                                <IconComponent size="l" />
                            </Text>
                            <div>
                                <Text as="div" className={cn('Title')} typography="body-m" weight="regular">
                                    {countryText}: {tourismText}
                                </Text>
                                <Text color="secondary" typography="caption-m">
                                    {shortRestrictionText}
                                </Text>
                            </div>
                            <Button
                                iconLeft={iconProviderArrowRight}
                                size="l"
                                view="clear"
                                onClick={setVisible}
                            />
                        </Card>
                        {isDesktop ?
                            <Modal
                                autoFocus={false}
                                theme="normal"
                                visible={visible}
                                onClose={setVisible}
                            >
                                <Button
                                    className={cn('Action', { type: 'close' })}
                                    icon={iconProviderClose}
                                    size="m"
                                    view="clear"
                                    onClick={setVisible}
                                />
                                <RestrictionsDetail
                                    countryName={countryText}
                                    restrictions={covidRestrictions}
                                />
                            </Modal> :
                            <Drawer
                                view="default"
                                visible={visible}
                                onClose={setVisible}
                            >
                                <RestrictionsDetail
                                    countryName={countryText}
                                    restrictions={covidRestrictions}
                                />
                            </Drawer>
                        }
                    </>
                );
            }}
        </Remote>

    );
};

CovidRestrictions.displayName = cn();
