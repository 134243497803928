import { FC } from 'react';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';

import { Card } from 'components/Card';
import { IconTravelConnection } from 'components/Icon';
import { Text } from 'shared/ui/Text';

import { cn } from './ExecutedTripStatus.cn';
import { i18n } from './ExecutedTripStatus.i18n';
import { ExecutedTripStatusProps } from './ExecutedTripStatus.types';

import './ExecutedTripStatus.css';

export const ExecutedTripStatus: FC<ExecutedTripStatusProps> = props => {
    const {
        className,
        toggleVisibleTimelineModal,
    } = props;

    return (
        <Card className={cn(null, [className])}>
            <Text
                as="div"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <Text
                as="div"
                className={cn('Description')}
                color="secondary"
                typography="body-s"
            >
                {i18n('description')}
            </Text>
            <Button
                className={cn('ApproveButton')}
                size="l"
                view="brand"
                width="max"
                onClick={toggleVisibleTimelineModal}
            >
                <IconTravelConnection className={cn('ButtonIcon')} />
                {i18n('trip_route')}
            </Button>
        </Card>
    );
};

ExecutedTripStatus.displayName = cn();
