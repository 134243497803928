export const en = {
    baggage: 'Baggage',
    quantity: ' ×{count}',
    hand_baggage: 'Carry‑on',
    no_baggage: 'Baggage not included',
    no_baggage_info: 'No baggage information',
    no_hand_baggage: 'Carry‑on not included',
    no_hand_baggage_info: 'No carry-on information',
    weight: '{weight}kg',
};
