import { FC, useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { config } from 'config';
import { YaAuthDataProviderError } from 'errors/YaAuthDataProviderError';
import { useMeta } from 'hooks/useMeta';
import { usePlatform } from 'hooks/usePlatform';
import { addToast } from 'utils/addToast';
import { logError } from 'utils/logError';
import { getPassportAutofillUrl } from 'utils/urls';

import { cn } from './SyncYandexIdDocumentsButton.cn';
import { i18n } from './SyncYandexIdDocumentsButton.i18n';
import { SyncYandexIdDocumentsButtonProps } from './SyncYandexIdDocumentsButton.types';

import './SyncYandexIdDocumentsButton.css';

export const SyncYandexIdDocumentsButton: FC<SyncYandexIdDocumentsButtonProps> = props => {
    const {
        className,
    } = props;

    const [isAutofilLoading, setIsAutofilLoading] = useState(false);
    const { isMobile } = usePlatform();
    const queryClient = useQueryClient();
    const { user: { is_allowed_store_documents } } = useMeta();
    const showSyncButton = config.isB2B && !is_allowed_store_documents;

    const showStoreDocumentsAcceptance = useCallback(() => {
        setIsAutofilLoading(true);

        // документация https://wiki.yandex-team.ru/users/xtave/edinoe-soglasie/
        const initAutoFill = window.YaAuthDataProvider && window.YaAuthDataProvider.initAutoFill;

        if (!initAutoFill) {
            return;
        }

        initAutoFill({ isDrawer: isMobile }, { hostname: getPassportAutofillUrl() })
            .then(res => res.handler())
            .then(res => {
                if (res?.action === 'accepted') {
                    queryClient.invalidateQueries('person_document_list');
                    // уберем после этого тикета BTRIP-3660
                    queryClient.invalidateQueries('auth_meta');
                }
            })
            .catch(e => {
                logError(new YaAuthDataProviderError('initAutoFill', e));
                addToast({ title: i18n('something_went_wrong') });
            })
            .finally(() => setIsAutofilLoading(false));
    }, [isMobile, queryClient]);

    if (!showSyncButton) {
        return null;
    }

    return (
        <ButtonPlatform
            className={cn(null, [className])}
            disabled={isAutofilLoading}
            progress={isAutofilLoading}
            size="m"
            view="pseudo"
            width="auto"
            onClick={showStoreDocumentsAcceptance}
        >
            {i18n('sync_documents')}
        </ButtonPlatform>
    );
};

SyncYandexIdDocumentsButton.displayName = cn();
