import { useQueryClient } from 'react-query';

import { useCreateDocument } from 'hooks/useCreateDocument';
import { useExtPersonCreateDocument } from 'hooks/useExtPersonCreateDocument';
import { useMeta } from 'hooks/useMeta';

import { DocumentFormProps } from '../DocumentForm.types';

type Options = Pick<DocumentFormProps, 'personType' | 'onSubmit'>;

export const useCreateDocumentHook = (options: Options) => {
    const { onSubmit, personType } = options;
    const queryClient = useQueryClient();

    const { user: { is_allowed_store_documents } } = useMeta();

    const [createDocumentExtPerson, createExtState, { error: createExtError }] = useExtPersonCreateDocument({
        onSuccess: data => {
            onSubmit(data.document_id);
        }, options: {
            hideValidationErrors: true,
        },
    });

    const [createDocument, createState, { error: createError }] = useCreateDocument({
        onSuccess: data => {
            queryClient.invalidateQueries([
                'get_allowed_documents_with_ext_persons',
            ]);

            // уберем после этого тикета BTRIP-3660
            if (!is_allowed_store_documents) {
                queryClient.invalidateQueries('auth_meta');
            }

            onSubmit(data.document_id);
        }, options: {
            hideValidationErrors: true,
        } });

    if (personType === 'extPerson') {
        return {
            createState: createExtState,
            error: createExtError,
            createDocumentFn: createDocumentExtPerson,
        };
    }

    return {
        createState: createState,
        error: createError,
        createDocumentFn: createDocument,
    };
};
