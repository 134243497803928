import { FC } from 'react';

import { ChiefsDropdownContent } from 'components/ChiefsDropdownContent';
import { Remote } from 'components/Remote';
import { useGetChiefs } from 'hooks/useGetChiefs';

import { AssistantTagProps } from './AssistantTag.types';

export const AssistantTag: FC<AssistantTagProps> = props => {
    const {
        employee,
    } = props;

    const chief = useGetChiefs(employee.employee_id, {});

    return (
        <Remote spinner data={chief}>
            {({ data: chief }) => (<ChiefsDropdownContent chiefs={chief.data} />)}
        </Remote>
    );
};
