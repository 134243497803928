import { NearbyStop } from 'services/SwaggerApi';

export const getNearbyOffice = (nearStops?: NearbyStop[]): NearbyStop[] => {
    if (!nearStops) return [];

    return nearStops
        .filter(nearStop => nearStop.type === 'yandex_office')
        .sort((prevOffice, nextOffice) => prevOffice.distance.value - nextOffice.distance.value)
        .slice(0, 1);
};
