import * as z from 'zod';

import { ActivePage } from 'shared/constants/ActivePage';
import { numberInString } from 'shared/lib/zod/numberInString';
import { checkAccessFactory } from 'utils/checkAccessFactory';

import { route } from './route';

export const RouteTravelPolicyDecoder = z.object({
    params: z.object({
        travelPolicyId: numberInString,
    }),
    search: z.object({}),
});

export type RouteTravelPolicy = z.output<typeof RouteTravelPolicyDecoder>;

const checkAccess = checkAccessFactory(
    meta => Boolean(meta.is_coordinator && meta.is_tp_enabled),
    'forbidden',
);

export const RouteTravelPolicy = route(
    '/travel-policy/:travelPolicyId',
    RouteTravelPolicyDecoder,
    {
        isAdminPanel: true,
        activePage: ActivePage.STAFF,
        checkAccess,
    },
);
