import { CurrentCompanyId } from 'components/CurrentCompanyProvider/CurrentCompanyProvider.types';
import { RouteCompanies } from 'routes/RouteCompanies';
import { RouteCompanyDetails } from 'routes/RouteCompanyDetails';
import { RouteFinance } from 'routes/RouteFinance';
import { RouteMyActiveTrips } from 'routes/RouteMyActiveTrips';
import { RouteProfile } from 'routes/RouteProfile';
import { RouteStaff } from 'routes/RouteStaff';
import { RouteTrips } from 'routes/RouteTrips';
import { UserResponse } from 'services/SwaggerApi';
import { ActivePage } from 'shared/constants/ActivePage';

import { i18n } from '../Header.i18n';
import { ItemConfig } from '../Header.types';

type GetMenuItemsProps = {
    currentCompanyId: CurrentCompanyId;
    currentPage: ActivePage;
    user: UserResponse;
};

export const getMenuItems = (props: GetMenuItemsProps) => {
    const {
        currentCompanyId,
        currentPage,
        user,
    } = props;
    const items: ItemConfig[] = [
        {
            slug: 'main',
            activePage: ActivePage.ACTIVE_TRIPS_LIST,
            url: RouteMyActiveTrips.getPath({}, {}),
        },
        {
            slug: 'trips',
            activePage: ActivePage.ALL_TRIPS_LIST,
            url: RouteTrips.getPath({}, {}),
            isVisible: RouteTrips.routeOptions.checkAccess(user).hasAccess,
        },
        {
            slug: 'profile',
            activePage: ActivePage.PROFILE,
            url: RouteProfile.getPath({}, {}),
        },
        {
            slug: 'staff',
            activePage: ActivePage.STAFF,
            url: RouteStaff.getPath({ companyId: currentCompanyId }, {}),
            isVisible: RouteStaff.routeOptions.checkAccess(user).hasAccess,
        },
        {
            slug: 'finance',
            activePage: ActivePage.FINANCE,
            url: RouteFinance.getPath({ companyId: currentCompanyId }, {}),
            isVisible: RouteFinance.routeOptions.checkAccess(user).hasAccess,
        },
        {
            slug: 'details',
            activePage: ActivePage.COMPANY_DETAILS,
            url: RouteCompanyDetails.getPath({ companyId: currentCompanyId }, {}),
            isVisible: RouteCompanyDetails.routeOptions.checkAccess(user).hasAccess,
        },
        {
            slug: 'companies',
            activePage: ActivePage.COMPANIES,
            url: RouteCompanies.getPath({}, {}),
            isVisible: RouteCompanies.routeOptions.checkAccess(user).hasAccess,
        },
    ];

    return items
        .filter(({ isVisible = true }) => isVisible)
        .map(({ slug, url, activePage }) => ({
            id: activePage,
            name: i18n(slug),
            isActive: activePage === currentPage,
            url,
        }));
};
