import { FC } from 'react';

import { AppLink } from 'components/AppLink';
import { Card } from 'components/Card';
import { PersonListItem } from 'components/PersonListItem';
import { withRemote } from 'hocs/withRemote';
import { useGetAssistants } from 'hooks/useGetAssistants';
import { useGetChiefs } from 'hooks/useGetChiefs';
import { RouteProfile } from 'routes/RouteProfile';
import { PaginatedResponse_PersonDetailsPublic_, PersonDetailsPublic } from 'services/SwaggerApi';

import { cn } from '../PageProfile.cn';
import { i18n } from '../PageProfile.i18n';

import { TabDocumentsProps } from './TabAccess.types';

import './TabAccess.css';

type PersonsListProps = {
    persons: PersonDetailsPublic[];
    withLinks?: boolean;
};

const PersonsList: FC<PersonsListProps> = props => {
    const { persons, withLinks } = props;

    return (
        <div className={cn('PersonsList')}>
            {persons.map(({ login, first_name, last_name, person_id }) => {
                const profileLink = RouteProfile.getPath({ personId: person_id }, {});

                return (
                    <AppLink key={login} className="PersonListLink" disabled={!withLinks} href={profileLink}>
                        <PersonListItem
                            login={login}
                            name={`${first_name} ${last_name}`}
                        />
                    </AppLink>
                );
            })}

        </div>
    );
};

type TabAccessContentProps = {
    assistants: PaginatedResponse_PersonDetailsPublic_;
    directors: PaginatedResponse_PersonDetailsPublic_;
};

const TabAccessContent: FC<TabAccessContentProps> = props => {
    const { assistants, directors } = props;

    return (
        <div className={cn('TabAccess')} data-testid="tab-access">
            <div className={cn('Main')}>
                {assistants.data.length > 0 && (
                    <Card
                        description={i18n('director_description')}
                        title={i18n('director_title')}
                    >
                        <PersonsList persons={assistants.data} />
                    </Card>
                )}
                {directors.data.length > 0 && (
                    <Card
                        description={i18n('assistant_description')}
                        title={i18n('assistant_title')}
                    >
                        <PersonsList withLinks persons={directors.data} />
                    </Card>
                )}
            </div>
        </div>
    );
};

const TabAccessContentRemote = withRemote(TabAccessContent);

export const TabAccess: FC<TabDocumentsProps> = props => {
    const { personId } = props;

    const assistants = useGetAssistants(personId, {});
    const directors = useGetChiefs(personId, {});

    return (
        <TabAccessContentRemote assistants={assistants} directors={directors} />
    );
};
