import { useEffect, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useServiceDocumentChange } from 'hooks/useServiceDocumentChange';
import { ExtPersonServiceInfo, Service, ServiceDocumentIn, TService_document_changeQuery } from 'services/SwaggerApi';
import { errorToast } from 'utils/errorToast';

import { i18n } from '../../ServiceCardFooter.i18n';
import { DocumentSelectProps } from '../DocumentsActions.types';

type UseWatchDocument = (
    options: {
        methods: UseFormReturn<DocumentSelectProps>;
        service: Service | ExtPersonServiceInfo;
        change_document: boolean;
    }
) => {
    changeDocument: (
        service_id: number,
        queryParams: TService_document_changeQuery,
        payload: ServiceDocumentIn
    ) => void;
};

export const useWatchDocument: UseWatchDocument = props => {
    const {
        methods,
        service,
        change_document,
    } = props;

    const { document_id, service_id } = service;

    const prevDocumentId = useRef<DocumentSelectProps['document_id']>(document_id);

    const [changeDocument] = useServiceDocumentChange({
        onSuccess: (_, __, ___, payload) => {
            prevDocumentId.current = payload.document_id;
        },
        onError: e => {
            methods.reset({ document_id: prevDocumentId.current });
            errorToast(e, { title: i18n('couldnt_change_passport') });
        },
        options: { hideErrorNotifier: true },
    });

    useEffect(() => {
        const subscription = methods.watch(((data, { type }) => {
            if (type !== 'change') return;

            if (change_document) {
                // добавили необязательный параметр на беке
                changeDocument(service_id, {}, data);

                return;
            }
        }));

        return () => subscription.unsubscribe();
    }, [changeDocument, methods, change_document, service_id]);

    return { changeDocument };
};
