import { IconTravelPlane, IconTravelTrain } from 'components/Icon';
import { fromLocalDate } from 'utils/date/fromLocalDate';
import { getOrderCity } from 'utils/getOrderCity';

import { i18n } from '../TripRoute.yt.i18n';

import { getAirportWithTerminal } from './getAirportNameTerminal';
import { segmentEvent } from './segmentEvent';
import { switchTransportFn } from './switchTransportFn';
import { TransportEventCreator } from './types';

export const arrival: TransportEventCreator = service => {
    const date = switchTransportFn({
        rail: order => fromLocalDate(order.arrival_at),
        avia: order => fromLocalDate(order.flight.legs?.[0].segments[0]?.arrival_at || ''),
    })(service);

    const city = getOrderCity(service, true) || '';

    const icon = switchTransportFn({
        rail: () => IconTravelTrain,
        avia: () => IconTravelPlane,
    })(service);

    const text = switchTransportFn({
        rail: order => {
            const station = order.train.arrival?.train_station?.name;

            return i18n('train_arrival', { city, station });
        },
        avia: order => {
            const aviaLocation = order.flight.legs?.[0].segments[0]?.arrival;
            const airport_w_terminal = getAirportWithTerminal(aviaLocation);

            return i18n('flight_arrival', { city, airport_w_terminal });
        },
    })(service);

    return segmentEvent({
        date,
        text,
        city,
        icon,
    });
};
