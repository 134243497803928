import { FC } from 'react';

import { Dropdown } from 'components/Dropdown';

import { cn } from './Cell.cn';
import { CellProps } from './Cell.types';

import './Cell.css';

export const Cell: FC<CellProps> = props => {
    const {
        as: Tag = 'td',
        justifyContent = 'start',
        size = 'm',
        sizeHorizontal = size,
        sizeVertical = size,
        title,
        className,
        children,
        getAttrs,
        dropDownContent,
    } = props;

    if (!dropDownContent) {
        return (
            <Tag
                {...getAttrs?.()}
                className={cn({
                    justifyContent,
                    sizeHorizontal,
                    sizeVertical,
                }, [className])}
                title={title}
            >
                {children}
            </Tag>
        );
    }

    return (
        <Dropdown
            {...getAttrs?.()}
            stopPropagation
            className={cn('Dropdown')}
            drawerProps={{
                scope: 'inplace',
            }}
            popupProps={{
                hasTail: true,
                direction: ['left', 'right'],
                scope: 'inplace',
            }}
            triggerElement={
                <Tag
                    {...getAttrs?.()}
                    className={cn({
                        justifyContent,
                        sizeHorizontal,
                        sizeVertical,
                        clickable: Boolean(dropDownContent),
                    }, [className])}

                    data-testid="cell-status"
                    title={title}
                >
                    {children}
                </Tag>
            }
        >
            {dropDownContent}
        </Dropdown>
    );
};

Cell.displayName = cn();
