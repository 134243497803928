import { FC } from 'react';

import { Text } from 'shared/ui/Text';

import { cn } from './FormField.cn';
import { FormFieldProps } from './FormField.types';

import './FormField.css';

export const FormField: FC<FormFieldProps> = props => {
    const {
        className,
        title,
        hint,
        required,
        appendAfter,
        replacer,
        error,
    } = props;

    return (
        <div className={cn(null, [className])}>
            {
                replacer || (
                    <>
                        {
                            title && (
                                <Text
                                    as="div"
                                    className={cn('Label')}
                                    typography="body-s"
                                    weight="medium"
                                >
                                    { title }
                                    { required && <span className={cn('Asteriks')} /> }
                                </Text>
                            )
                        }
                        { appendAfter }
                        {
                            hint && (
                                <Text
                                    as="div"
                                    className={cn('Hint')}
                                    color="secondary"
                                    typography="caption-m"
                                >
                                    { hint }
                                </Text>
                            )
                        }
                        {
                            error && (
                                <Text
                                    as="div"
                                    className={cn('Error')}
                                    color="alert"
                                    typography="caption-m"
                                >
                                    { error }
                                </Text>
                            )
                        }
                    </>
                )
            }
        </div>
    );
};

FormField.displayName = cn();
