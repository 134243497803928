export const ru = {
    waiting_approval_title: 'Готовимся оформлять',
    waiting_approval_description: 'Нам нужно согласовать поездку, после чего вы сможете выбрать услуги',
    waiting_approval: 'Ожидаем согласования',
    under_approval_title: 'Оформляем командировку',
    under_approval_description: 'Нам нужно согласовать поездку и оформить добавленные услуги',
    under_approval: 'Ожидаем согласования',
    executing_title: 'Оформляем командировку',
    executing_description: 'Поездка согласована, нам осталось оформить добавленные услуги',
    executing: 'Оформляем услуги',
};
