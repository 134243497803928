import { FC, useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { TrainSeatSelect } from 'components/TrainSeatSelect';
import { withRemote } from 'hocs/withRemote';
import { useServiceUpdate } from 'hooks/useServiceUpdate';
import { useTrainPlacesAvailability } from 'hooks/useTrainPlacesAvailability';
import { i18nCarriageType } from 'i18n/i18nCarriageType';
import { Text } from 'shared/ui/Text';
import { View } from 'types/View';
import { isLoading, mapLoadable } from 'utils/Loadable';
import { nullToUndefined } from 'utils/nullToUndefined';

import { cn } from './ViewTrainSeat.cn';
import { i18n } from './ViewTrainSeat.i18n';
import { TrainSeatsRenderProps, ViewTrainSeatProps } from './ViewTrainSeat.types';

import './ViewTrainSeat.css';

const TrainSeatsRender: FC<TrainSeatsRenderProps> = props => {
    const {
        carriage,
        currentSeat,
        tripId,
        personId,
        serviceId,
        train,
        onClose,
    } = props;

    const [selected, setSelected] = useState<number | undefined>(currentSeat);
    const queryClient = useQueryClient();
    const [updatePlace, updatePlaceState] = useServiceUpdate({ onSuccess: () => {
        queryClient.invalidateQueries(['person_trip_detail', tripId, personId]);
        onClose();
    } });

    const handleUpdatePlace = useCallback(() => {
        if (selected) {
            updatePlace(serviceId, { seat_number: selected });
        }
    }, [selected, serviceId, updatePlace]);

    if (!carriage) {
        return null;
    }

    return (
        <>
            <Text typography="title-s">
                {i18n('carriage')} {carriage.carriage_number} {i18nCarriageType(carriage.carriage_type)}
            </Text>
            <TrainSeatSelect
                carriage={carriage}
                chosenPlace={selected}
                train={train}
                onChange={setSelected}
            />
            <div className={cn('Actions')}>
                <ButtonPlatform
                    className={cn('Submit')}
                    disabled={!selected}
                    progress={isLoading(updatePlaceState)}
                    view="brand"
                    onClick={handleUpdatePlace}
                >
                    {i18n('choose')}
                </ButtonPlatform>
            </div>
        </>
    );
};

const TrainSeatsRenderWithRemote = withRemote(TrainSeatsRender);

export const ViewTrainSeat: View<ViewTrainSeatProps> = props => {
    const {
        service,
        onClose,
    } = props;

    const {
        trip_id,
        service_id,
        person_id,
        seat_number,
    } = service;

    const seatsResponse = useTrainPlacesAvailability(service_id);

    const carriage = useMemo(() => {
        return mapLoadable(seatsResponse, ({ carriage }) => carriage);
    }, [seatsResponse]);
    const train = useMemo(() => {
        return mapLoadable(seatsResponse, ({ train }) => train ?? null);
    }, [seatsResponse]);

    return (
        <div className={cn()}>
            <div className={cn('Content')}>
                <Text as="div" className={cn('PageTitle')} typography="title-s">
                    {i18n('choose_seat')}
                </Text>
                <TrainSeatsRenderWithRemote
                    spinner
                    carriage={carriage}
                    currentSeat={nullToUndefined(seat_number)}
                    personId={person_id}
                    serviceId={service_id}
                    train={train}
                    tripId={trip_id}
                    onClose={onClose}
                />
            </div>
        </div>
    );
};

ViewTrainSeat.displayName = cn();
