import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import { DocumentForm } from 'components/DocumentForm';
import { FormProvider } from 'components/FormField2';
import { FieldTravellerDocument } from 'components/FormField2/Fields/FieldTravellerDocument';
import { ModalPlatform } from 'shared/ui/ModalPlatform';

import { useApplyDefaultDocument } from '../DocumentsActions/DocumentsActions.hooks/useApplyDefaultDocument';
import { useWatchDocument } from '../DocumentsActions/DocumentsActions.hooks/useWatchDocument';
import { DocumentSelectProps } from '../DocumentsActions/DocumentsActions.types';
import { cn } from '../ServiceCardFooter.cn';

import { TravellerDocumentsProps } from './TravellerDocuments.types';

import './TravellerDocuments.css';

export const TravellerDocuments: FC<TravellerDocumentsProps> = props => {
    const {
        person,
        allowedDocuments,
        actions,
        personInfo,
        personType,
        service,
    } = props;

    const { change_document } = actions;
    const { document_id } = service;

    const [isDocumentAddModalVisible, toggleIsDocumentAddModalVisible] = useToggle(false);

    const methods = useForm<DocumentSelectProps>({
        defaultValues: { document_id },
    });

    useApplyDefaultDocument(methods, document_id);

    const { changeDocument } = useWatchDocument({ methods, service, change_document });

    const onAddDocumentClick = useCallback(() => {
        toggleIsDocumentAddModalVisible();
    }, [toggleIsDocumentAddModalVisible]);

    const onDocumentFormSubmit = useCallback((documentId: number) => {
        toggleIsDocumentAddModalVisible();

        changeDocument(service.service_id, {}, { document_id: documentId });
    }, [changeDocument, service.service_id, toggleIsDocumentAddModalVisible]);

    return (
        <>
            <FormProvider methods={methods}>
                <FieldTravellerDocument
                    allowedDocuments={allowedDocuments}
                    className={cn('TravellerDocuments')}
                    disabled={!change_document}
                    name="document_id"
                    person={person}
                    onAddDocumentClick={onAddDocumentClick}
                />
            </FormProvider>
            <ModalPlatform
                className={cn('DocumentAddModal')}
                toggleVisible={toggleIsDocumentAddModalVisible}
                visible={isDocumentAddModalVisible}
            >
                <DocumentForm
                    personInfo={personInfo}
                    personType={personType}
                    onCancel={toggleIsDocumentAddModalVisible}
                    onSubmit={onDocumentFormSubmit}
                />
            </ModalPlatform>
        </>
    );
};
