import { FC } from 'react';
import { IClassNameProps } from '@bem-react/core';

import { Text } from 'shared/ui/Text';

import { cn } from '../FormField2.cn';

type FieldLabelProps = IClassNameProps & {
    htmlFor?: string;
    label: string;
    isRequired?: boolean;
};

export const FieldLabel: FC<FieldLabelProps> = props => {
    const {
        label,
        htmlFor,
        isRequired,
        className,
    } = props;

    return (
        <label
            className={cn('Label', [className])}
            data-testid={`field-${htmlFor}-label`}
            htmlFor={htmlFor}
        >
            <Text typography="body-s">
                {label}
                {isRequired && <span className={cn('Asterisk')} />}
            </Text>
        </label>

    );
};
