import { FC, useEffect, useState } from 'react';

import { Text } from 'shared/ui/Text';
import { getTypedI18nLang } from 'utils/i18n';

import { cn } from './TripTips.cn';
import { TripTipsProps } from './TripTips.types';
import * as keyset from './TripTips.yt.i18n';

import './TripTips.css';

const TIP_INTERVAL = 1000 * 10;

const getRandomTip = (): string => {
    const lang = getTypedI18nLang();

    const tipsList = Object.values(keyset[lang]);

    const randomIndex = Math.round(Math.random() * (tipsList.length - 1));

    return tipsList[randomIndex];
};

export const TripTips: FC<TripTipsProps> = props => {
    const [tip, setTip] = useState<string>(getRandomTip());

    useEffect(() => {
        const tipIntervalId = setInterval(() => setTip(getRandomTip()), TIP_INTERVAL);

        return () => clearInterval(tipIntervalId);
    }, []);

    return (
        <div className={cn(null, [props.className])}>
            <Text color="secondary">
                {tip}
            </Text>
        </div>
    );
};

TripTips.displayName = cn();
