import { i18nBaggage } from 'i18n/i18nBaggage';
import { FlightBaggage } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';

type GetBaggageInfo = (baggage: FlightBaggage['baggage'], options?: GetBaggageInfoOptions) => {
    baggageText: string | JSX.Element;
    hasBaggage: boolean;
};

type GetBaggageInfoOptions = {
    handBaggage?: boolean;
    withReplace?: boolean;
};

export const getBaggageInfo: GetBaggageInfo = (baggage, options = {}) => {
    const { handBaggage, withReplace } = options;

    if (!baggage) {
        const noBaggageText = handBaggage ? i18nBaggage('no_hand_baggage_info') : i18nBaggage('no_baggage_info');

        return {
            baggageText: noBaggageText,
            hasBaggage: false,
        };
    }

    if (!baggage.quantity) {
        const noBaggageText = handBaggage ? i18nBaggage('no_hand_baggage') : i18nBaggage('no_baggage');

        return {
            baggageText: noBaggageText,
            hasBaggage: false,
        };
    }

    const {
        quantity: count,
        weight,
        dimensions,
    } = baggage;

    const baggageFormatted = handBaggage ? i18nBaggage('hand_baggage') : i18nBaggage('baggage');
    const quantityFormatted = i18nBaggage('quantity', { count });
    const weightFormatted = weight && i18nBaggage('weight', { weight });

    const baggageText = [baggageFormatted, weightFormatted].filter(Boolean).join(' ');
    const formattedBaggagetext = (
        <div>
            {baggageText}
            <Text as="span" color="secondary">
                {quantityFormatted}
            </Text>
            {dimensions && (
                <Text as="div" color="secondary">
                    {withReplace ? dimensions.replaceAll('x', '×') : dimensions}
                </Text>
            )}
        </div>
    );

    return {
        baggageText: formattedBaggagetext,
        hasBaggage: true,
    };
};
