import { FC, useCallback } from 'react';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';

import { HelpTooltip } from 'components/HelpTooltip';
import {
    IconBaggageOff,
    IconBaggageOn,
    IconCheckCircle,
    IconCloseCircle,
    IconHandBaggageOff,
    IconHandBaggageOn,
} from 'components/Icon';
import { OptionInfoItem } from 'components/OptionInfoItem';
import { i18nServiceInfo } from 'i18n/i18nServiceInfo';
import { i18nTravelPolicy } from 'i18n/i18nTravelPolicy';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { getBaggageInfo } from 'utils/getBaggageInfo';
import { getFareFamilyType } from 'utils/getFareFamilyType';

import { useSlideToOption } from './hooks/useSlideToOption';
import { cn } from './FlightInfoFareFamilyOption.cn';
import { i18n } from './FlightInfoFareFamilyOption.i18n';
import { FlightInfoFareFamilyOptionProps } from './FlightInfoFareFamilyOption.types';

import './FlightInfoFareFamilyOption.css';

export const FlightInfoFareFamilyOption: FC<FlightInfoFareFamilyOptionProps> = props => {
    const {
        checked,
        className,
        onTariffChange,
        flight,
        swiperRef,
        optionNumber,
    } = props;

    const {
        is_changeable: isChangeable,
        is_refundable: isRefundable,
        is_travel_policy_compliant: isTravelPolicyCompliant,
        baggage_summary: baggage,
        price,
        travel_policy_violations: travelPolicyViolations,
    } = flight;

    const handleChooseTariff = useCallback(() => {
        onTariffChange(flight);
    }, [flight, onTariffChange]);

    const priceFormatted = formatCurrency(price);
    const { hasBaggage, baggageText } = getBaggageInfo(baggage?.baggage, { withReplace: true });
    const {
        hasBaggage: hasHandBaggage,
        baggageText: handBaggageText,
    } = getBaggageInfo(baggage?.hand_baggage, { handBaggage: true });

    useSlideToOption({ checked, optionNumber, swiperRef });

    const fareFamilyType = getFareFamilyType(flight);
    const title = fareFamilyType ? fareFamilyType.toUpperCase() : i18n('option', { number: optionNumber + 1 });
    const travelPolicyViolationsContent = travelPolicyViolations.length > 0 ? (
        <Text className={cn('TooltipText')} typography="caption-m">{travelPolicyViolations.join('\n')}</Text>
    ) : null;

    return (
        <div className={cn({ checked }, [className])}>
            <div>
                <Text typography="title-s" weight="medium">
                    {title}
                </Text>

                <div className={cn('TariffInfo')}>
                    <OptionInfoItem
                        hasSuccessColor
                        condition={hasHandBaggage}
                        failureIcon={IconHandBaggageOff}
                        failureText={handBaggageText}
                        successIcon={IconHandBaggageOn}
                        successText={handBaggageText}
                    />
                    <OptionInfoItem
                        hasFailureColor
                        hasSuccessColor
                        condition={hasBaggage}
                        failureIcon={IconBaggageOff}
                        failureText={baggageText}
                        successIcon={IconBaggageOn}
                        successText={baggageText}
                    />
                    <OptionInfoItem
                        hasSuccessColor
                        condition={Boolean(isChangeable)}
                        failureIcon={IconCloseCircle}
                        failureText={i18nServiceInfo('not_changeable')}
                        successIcon={IconCheckCircle}
                        successText={i18nServiceInfo('changeable')}
                    />
                    <OptionInfoItem
                        hasSuccessColor
                        condition={Boolean(isRefundable)}
                        failureIcon={IconCloseCircle}
                        failureText={i18nServiceInfo('not_refundable')}
                        successIcon={IconCheckCircle}
                        successText={i18nServiceInfo('refundable')}
                    />
                </div>
            </div>

            <div className={cn('BottomPart')}>
                {!isTravelPolicyCompliant && (
                    <Text
                        as="div"
                        className={cn('TravelPolicy')}
                        color="alert"
                        typography="caption-m"
                    >
                        {i18nTravelPolicy('not_compliant_short')}
                        {travelPolicyViolationsContent && (
                            <HelpTooltip
                                hasTail
                                inverse
                                stopPropagation
                                className={cn('Tooltip')}
                                content={travelPolicyViolationsContent}
                                iconSize="m"
                            />
                        )}
                    </Text>
                )}

                <Button
                    checked={checked}
                    className={cn('ChooseTariff')}
                    size="m"
                    view="secondary"
                    width="max"
                    onClick={handleChooseTariff}
                >
                    {i18n(checked ? 'chose_for' : 'choose_for')} {priceFormatted}
                </Button>
            </div>
        </div>
    );
};

FlightInfoFareFamilyOption.displayName = cn();
