import { FC, useCallback } from 'react';

import { Dialog } from 'components/Dialog/Dialog';
import { i18nAction } from 'i18n/i18nAction';

import { useRemoveAssistant } from './hooks/useRemoveAssistant';
import { cn } from './EmployeeRemoveAssistantModal.cn';
import { i18n } from './EmployeeRemoveAssistantModal.i18n';
import { EmployeeRemoveAssistantModalProps } from './EmployeeRemoveAssistantModal.types';

import './EmployeeRemoveAssistantModal.css';

/**
 * Модалка подтверждения удаления ассистента
 */
export const EmployeeRemoveAssistantModal: FC<EmployeeRemoveAssistantModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        className,
        employee,
    } = props;

    const { employee_id, company_id } = employee;

    const [removeAssistant] = useRemoveAssistant({
        onSuccess: onSubmit,
        options: { invalidateOnSuccess: true },
    });

    const handleClick = useCallback(() => {
        removeAssistant(company_id, employee_id);
    }, [removeAssistant, company_id, employee_id]);

    return (
        <Dialog
            visible
            className={className}
            close={i18nAction('no')}
            closeButtonView="ghost"
            confirm={i18nAction('yes')}
            subtitle={i18n('confirmation_description')}
            title={i18n('confirmation_title')}
            onClose={onCancel}
            onConfirm={handleClick}
        />

    );
};

EmployeeRemoveAssistantModal.displayName = cn();
