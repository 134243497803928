import { FC } from 'react';
import { ErrorState as ErrorStateBase } from '@yandex-int/hr-components/ErrorState';
import { IErrorStateProps } from '@yandex-int/hr-components/ErrorState/ErrorState.types';

import { SupportChatButton } from 'components/SupportChatButton';
import { Button } from 'shared/ui/Button';

import { cn } from './ErrorState.cn';
import { ErrorStateProps, ImageType } from './ErrorState.types';
import { accessDeniedImage, notFoundErrorImage } from './images';

import './ErrorState.css';

const images: { [key in ImageType]: JSX.Element } = {
    not_found: notFoundErrorImage,
    forbidden: accessDeniedImage,
};

const getImage = (image?: IErrorStateProps['image'], imageType?: ImageType) => {
    if (imageType) {
        return (
            <div className={cn('Image')}>
                {images[imageType]}
            </div>
        );
    }

    return image;
};

export const ErrorState: FC<ErrorStateProps> = props => {
    const {
        actions,
        actionTitle,
        onActionClick,
        image,
        imageType,
        title,
        withChatSupport,
        description,
        size = 's',
        className,
    } = props;

    return (
        <div className={cn(null, [className])}>
            {getImage(image, imageType)}
            <ErrorStateBase
                actions={actions || [
                    withChatSupport && (
                        <SupportChatButton
                            key="action1"
                            className={cn('Link')}
                            view="outline"
                        />
                    ),
                    actionTitle && (
                        <Button
                            key="action2"
                            size="m"
                            view="primary"
                            onClick={onActionClick}
                        >
                            {actionTitle}
                        </Button>
                    ),
                ].filter(Boolean)}
                description={description}
                size={size}
                title={title}
            />
        </div>
    );
};

ErrorState.displayName = cn();
