import { FC } from 'react';

import { FlightServiceCard } from 'components/FlightServiceCard';
import { HotelServiceCard } from 'components/HotelServiceCard';
import { TrainServiceCard } from 'components/TrainServiceCard';
import { withRemote } from 'hocs/withRemote';
import { useServiceDetailByType } from 'hooks/useServiceDetailByType';

import { cn } from './ServiceOrder.cn';
import { ServiceOrderProps, ServiceOrderRendererProps } from './ServiceOrder.types';

const ServiceOrderRenderer: FC<ServiceOrderRendererProps> = props => {
    const { service, serviceDetails } = props;

    switch (serviceDetails.service_type) {
        case 'hotel':
            return <HotelServiceCard service={service} serviceDetails={serviceDetails} />;

        case 'avia':
            return <FlightServiceCard service={service} serviceDetails={serviceDetails} />;

        case 'rail':
            return <TrainServiceCard service={service} serviceDetails={serviceDetails} />;

        default:
            return null;
    }
};

const ServiceOrderRendererRemote = withRemote(ServiceOrderRenderer);

export const ServiceOrder: FC<ServiceOrderProps> = props => {
    const { service } = props;

    const serviceDetails = useServiceDetailByType(service.type, service.service_id);

    return serviceDetails && (
        <ServiceOrderRendererRemote
            service={service}
            serviceDetails={serviceDetails}
        />
    );
};

ServiceOrder.displayName = cn();
