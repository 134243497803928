import { Flight } from 'services/SwaggerApi';

import { getRouteCities } from './getRouteCities';

export const getFlightCitiesText = (flight: Flight) => {
    const leg = flight.legs?.[0];
    const cities = [
        leg?.segments[0].departure.city?.name,
        leg?.segments[leg.segments.length - 1].arrival.city?.name,
    ];

    return getRouteCities(...cities);
};
