import { ComponentProps, FC } from 'react';

import { useHandleChange } from 'components/FormField2/utils/useHandleChange';
import { Checkbox } from 'shared/ui/Checkbox';

import { withFormField } from '../../FormField2';
import { cn } from '../../FormField2.cn';
import { FormFieldProps } from '../../FormField2.types';

import './FieldCheckbox.css';

type CheckboxProps = ComponentProps<typeof Checkbox>;

export type WithTypeCheckboxProps = CheckboxProps & {
    checkboxLabel?: CheckboxProps['label'];
};

const Control: FC<WithTypeCheckboxProps & FormFieldProps> = props => {
    const {
        size = 'm',
        lines = 'multi',
        name,
        deps,
        controller,
        context,
        checkboxLabel,
        ...checkboxProps
    } = props;

    const {
        field: {
            onBlur,
            value,
        },
    } = controller;

    const handleChange = useHandleChange({ controller, context, deps });

    return (
        <Checkbox
            {...checkboxProps}
            checked={value}
            className={cn('Control', { name })}
            id={name}
            label={checkboxLabel}
            lines={lines}
            name={name}
            size={size}
            view="outline"
            onBlur={onBlur}
            onChange={handleChange}
        />
    );
};

export const FieldCheckbox = withFormField(Control);
