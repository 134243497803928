import { FC, useCallback, useEffect, useState } from 'react';
import { throttle } from 'lodash';

import { getIconProvider, IconArrowShortUp } from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { Container } from 'shared/ui/Container';

import { cn } from './ScrollButton.cn';
import { ScrollButtonProps } from './ScrollButton.types';

import './ScrollButton.css';

const iconArrowUpProvider = getIconProvider(IconArrowShortUp);

export const ScrollButton: FC<ScrollButtonProps> = props => {
    const { className } = props;
    const [isVisible, setIsVisible] = useState(false);

    const { isMobile } = usePlatform();

    useEffect(() => {
        const handleScrollButtonVisibility = throttle(() => {
            window.scrollY > window.innerHeight ? setIsVisible(true) : setIsVisible(false);
        }, 100);

        window.addEventListener('scroll', handleScrollButtonVisibility);

        return () => window.removeEventListener('scroll', handleScrollButtonVisibility);
    }, []);

    const handleScrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Container className={cn('Container')} wrapperClassName={cn(null, [className])}>
            <Button
                className={cn('Arrow', { isVisible })}
                icon={iconArrowUpProvider}
                pin="circle-circle"
                size={isMobile ? 'l' : 'm'}
                view="contrast"
                onClick={handleScrollToTop}
            />
        </Container>
    );
};

ScrollButton.displayName = cn();
