export const en = {
    account_balance_title_prepayment: 'Account balance',
    account_balance_title_postpayment: 'Debt',
    top_up: 'Top up',
    last_top_up: 'Last top up: {date}',
    convenient_service: 'Excluding the «Convenient Service» service',
    convenient_service_description: 'The full cost of services for the period can be found in the closing documents for the period',
    notification_warning_description: 'The account balance is less than 30%. It is recommended to top up your balance',
    notification_danger_description: 'At the moment, execution of services is not possible. Please top up your balance to use all the features of the service',
    no_data: 'no data',
};
