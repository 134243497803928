import { useEffect } from 'react';
import { throttle } from 'lodash';

export type Item = {
    id: string;
};

const offsetTopElement = 24;

export const useScrollObserver = (items: Item[], setCurrentItem: (id: string) => void) => {
    useEffect(() => {
        const handleScroll = throttle(() => {
            const fromTop = window.scrollY;

            const firstVisibleItem = items.find(item => {
                const section = document.querySelector<HTMLElement>(`#${item.id}`);

                if (!section) {
                    return;
                }

                const offsetFromWindowTop = section.offsetTop - fromTop;

                if (offsetFromWindowTop + offsetTopElement > 0 && offsetFromWindowTop < window.innerHeight / 4) {
                    return item;
                }
            });

            if (firstVisibleItem) {
                setCurrentItem(firstVisibleItem.id);
            }
        }, 100);

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [items, setCurrentItem]);
};
