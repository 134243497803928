import { FC } from 'react';
import {
    IPopupProps as IPopupPropsBase,
    Popup as PopupBase,
} from '@yandex-int/hr-components/Popup/desktop/bundle';

import { baseModifiers } from './Popup.constants';

import './Popup.css';

export type IPopupProps = IPopupPropsBase;

export const Popup: FC<IPopupProps> = props => {
    return (
        <PopupBase
            modifiers={baseModifiers}
            {...props}
        />
    );
};
