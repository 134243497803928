import { ComponentProps } from 'react';

import { OptionInfoItem } from '../OptionInfoItem';

type OptionInfoItemProps = ComponentProps<typeof OptionInfoItem>;

type GetItemColor = (
    condition: OptionInfoItemProps['condition'],
    hasSuccessColor: OptionInfoItemProps['hasSuccessColor'],
    hasFailureColor: OptionInfoItemProps['hasFailureColor'],
) => 'green' | 'red' | undefined;

export const getItemColor: GetItemColor = (condition, hasSuccessColor, hasFailureColor) => {
    if (condition && hasSuccessColor) {
        return 'green';
    }

    if (!condition && hasFailureColor) {
        return 'red';
    }

    return;
};
