import { Skeleton } from '@yandex-int/hr-components/Skeleton';

import { cn } from '../HotelDetails.cn';

const StatSkeleton = () => {
    return (
        <div>
            <Skeleton size="s" style={{ width: 60, margin: 0, display: 'block' }} />
            <Skeleton size="s" style={{ height: 4 }} />
        </div>
    );
};

export const StatsSkeleton = () => {
    return (
        <div className={cn('Stats')}>
            <Skeleton size="m" style={{ width: 120, height: 16 }} />
            {Array(4).fill(4).map((_, index) => (<StatSkeleton key={index} />))}

            <Skeleton size="m" style={{ width: 150 }} type="control" />
        </div>
    );
};
