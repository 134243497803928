import { ImpossibleError } from 'errors';
import { PersonTripLite } from 'services/SwaggerApi';
import { Participant, ParticipantMarkType } from 'types/Participant';

export const personToParticipant = (personTrip: PersonTripLite): Participant => {
    const { person, status, actions } = personTrip;

    if (!person) {
        throw new ImpossibleError("Person doesn't exist");
    }

    const {
        first_name,
        last_name,
        login,
        person_id: id,
        gender,
    } = person;

    const fullName = first_name && last_name ? `${first_name} ${last_name}` : login;
    const mark: ParticipantMarkType | undefined = status === 'cancelled' ? 'dash' : undefined;
    const canCreateService = actions?.create_service;

    return {
        id,
        login,
        gender,
        mark,
        canCreateService,
        name: fullName,
        type: 'person',
        profile_id: person?.provider_profile_id || 0,
    };
};

export const getParticipants = (personTrips: PersonTripLite[] | null | undefined): Participant[] => {
    if (!personTrips) return [];

    return personTrips
        .filter(personTrip => personTrip.person)
        .map(personTrip => personToParticipant(personTrip));
};
