import { FC, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { FieldPerson } from 'components/FormField/FormField.providers';
import { usePersonTripPut } from 'hooks/usePersonTripPut';
import { Text } from 'shared/ui/Text';
import { validateRequiredField } from 'utils/form/validateRequiredField';
import { isLoading } from 'utils/Loadable';

import { cn } from './AddParticipantForm.cn';
import { i18n } from './AddParticipantForm.i18n';
import { AddParticipantFormFormProps, AddParticipantFormProps } from './AddParticipantForm.types';

import './AddParticipantForm.css';

const AddParticipantFormForm: FC<AddParticipantFormFormProps> = props => {
    const {
        className,
        handleSubmit,
        handleCancel,
        progress,
    } = props;

    return (
        <form className={cn(null, [className])} onSubmit={handleSubmit}>
            <Text typography="title-s">
                {i18n('add_participant')}
            </Text>
            <Field
                singleChoice
                component={FieldPerson}
                idType="staff_id"
                name="person_id"
                validate={validateRequiredField}
            />
            <div className={cn('Controls')}>
                <ButtonPlatform
                    className={cn('Cancel')}
                    view="default"
                    onClick={handleCancel}
                >
                    {i18n('cancel')}
                </ButtonPlatform>
                <ButtonPlatform
                    className={cn('Submit')}
                    progress={progress}
                    type="submit"
                    view="brand"
                >
                    {i18n('save')}
                </ButtonPlatform>
            </div>
        </form>
    );
};

export const AddParticipantForm: FC<AddParticipantFormProps> = props => {
    const {
        className,
        onSubmit,
        onCancel,
        tripId,
    } = props;

    const [personTripPut, state] = usePersonTripPut({ onSuccess: onSubmit });

    const handleSubmit = useCallback(values => {
        personTripPut(tripId, values.person_id, {
            purposes: [],
            documents: [],
            is_hidden: false,
            with_days_off: false,
            description: '',
        });
    }, [personTripPut, tripId]);

    const getForm = useCallback(({ handleSubmit }) => (
        <AddParticipantFormForm
            className={className}
            handleCancel={onCancel}
            handleSubmit={handleSubmit}
            progress={isLoading(state)}
        />
    ), [className, onCancel, state]);

    return (
        <Form
            mutators={{ ...arrayMutators }}
            render={getForm}
            onSubmit={handleSubmit}
        />
    );
};

AddParticipantForm.displayName = cn();
