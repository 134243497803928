import { FieldValues, UseFormTrigger } from 'react-hook-form';

/**
 * Ревалидирует поля deps.
 * Поля, которые не находятся в соcтоянии touched ревалидироваться не будут.
 * После submit'a ревалидируются все поля.
 * @param deps Поля, которые необходимо провалидировать после изменения значения текущего поля
 * @param touchedFields Поля, на которых сработал onBlur event
 * @param trigger Функция, которая провалидирует переданные в нее поля
 * @param isSubmitted Состояние отображает засабмитчена ли форма
 * @returns {void}
 */
export const validateDependentFields = (
    deps: string[],
    touchedFields: object,
    trigger: UseFormTrigger<FieldValues>,
    isSubmitted: boolean,
) => {
    if (isSubmitted) {
        trigger(deps);

        return;
    }

    const fieldsToTrigger = Object.keys(touchedFields)
        .filter(field => deps.includes(field));

    trigger(fieldsToTrigger);
};
