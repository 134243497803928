import { useContext, useMemo } from 'react';
import { QueryFunctionContext, useInfiniteQuery, UseInfiniteQueryOptions, UseInfiniteQueryResult } from 'react-query';

import { FetchError, ImpossibleError } from 'errors';
import { i18nErrors } from 'i18n/i18nErrors';
import { ServiceType, SwaggerApi } from 'services/SwaggerApi';
import { PaginatedRequest } from 'types/PaginatedRequest';
import { ExtractPaginatedServiceResponse, PaginatedServiceResponse } from 'types/PaginatedResponse';
import { failure, initial, loading, RemoteData, success } from 'utils/Loadable';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../SwaggerContext';

import { QUERY_LIMIT } from './useSearchResults';

/** Надо переписать этот хук на более специфичный подробнее тут - BTRIP-3114 */
export function createUseServiceInfiniteQueryHook<
    K extends keyof SwaggerApi,
    TItem,
    TFetchResponse extends ReturnType<SwaggerApi[K]>,
    TArgs extends Parameters<SwaggerApi[K]>,
>(
    key: K,
    queryConfig?: UseInfiniteQueryOptions<PaginatedServiceResponse<TItem>, FetchError>,
) {
    type TSuccess = ExtractPaginatedServiceResponse<ExtractResponseSuccess<TFetchResponse>>;

    return function useInfiniteQueryHook(...args: TArgs): [
        RemoteData<{ items: TSuccess[]; count: number; service_type?: ServiceType | null }>,
        UseInfiniteQueryResult<PaginatedServiceResponse<TItem>, FetchError>,
    ] {
        const { api } = useContext(SwaggerContext);
        const fetchFn = api[key];

        const queryFn = ({ pageParam = 1 }: QueryFunctionContext): Promise<PaginatedServiceResponse<TItem>> => {
            const params = { ...(args[args.length - 1] as PaginatedRequest) };

            params.page = pageParam;

            //@ts-ignore
            return fetchFn(...(args.slice(0, args.length - 1).concat(params) as unknown as TArgs))
                .then(throwHTTPErrors) as Promise<PaginatedServiceResponse<TItem>>;
        };

        const infiniteQueryResult = useInfiniteQuery([key, ...args], queryFn,
            // @ts-ignore
            {
                ...queryConfig,
                getNextPageParam: ({ data, page, limit, total }) => (
                    limit && data.length === limit && page && (QUERY_LIMIT * page) < total ? page + 1 : undefined
                ),
            },
        );

        return useMemo(() => {
            const {
                data,
                isError,
                isIdle,
                isLoading,
                error,
            } = infiniteQueryResult;

            if (isIdle) return [initial(), infiniteQueryResult];
            if (isLoading) return [loading(), infiniteQueryResult];
            if (isError && error !== null) return [failure(error), infiniteQueryResult];

            if (data === undefined) {
                throw new ImpossibleError(i18nErrors('data_undefined'));
            }

            const items = data.pages.flatMap(({ data }) => data) as TSuccess[];
            const count = data.pages[data.pages.length - 1].total;
            const service_type = data.pages[0].service_type as ServiceType;

            return [success({ items, count, service_type }), { ...infiniteQueryResult }];
        }, [infiniteQueryResult]);
    };
}
