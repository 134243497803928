const getFromLocalStorage = <T>(key: string, defaults: T, unserializer: (str: string) => T) => {
    try {
        const value = localStorage.getItem(key) || undefined;

        return value ? unserializer(value) : defaults;
    } catch (e) {
        return defaults;
    }
};

const putToLocalStorage = <T>(key: string, value: T, serializer: (value: T) => string = String) => {
    localStorage.setItem(key, serializer(value));
};

const removeFromLocalStorage = (key: string) => {
    localStorage.removeItem(key);
};

interface ILocalStorageHelper {
    getData: <T extends Record<keyof T, string>>(key: string) => T | null;
    setData: (value: object, key: string) => { error?: unknown };
    clear: (key: string) => { error?: unknown };
}

export const localStorageHelper: ILocalStorageHelper = {
    getData: key => {
        return getFromLocalStorage(key, null, JSON.parse);
    },
    setData: (value, key) => {
        try {
            putToLocalStorage(key, value, JSON.stringify);

            return {};
        } catch (error) {
            return { error };
        }
    },
    clear: (key: string) => {
        try {
            removeFromLocalStorage(key);

            return {};
        } catch (error) {
            return { error };
        }
    },
};
