export function getPlaceNumbers(node: SVGGElement): number[] {
    const { id } = node;

    if (!id) {
        return [];
    }

    const numbers = id
        .split('/')
        .pop()
        ?.split('-')
        .pop()
        ?.split(',');

    if (!numbers) {
        return [];
    }

    return numbers.map(Number);
}
