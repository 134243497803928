import { FormValidationError } from 'utils/responseToHTTPError';

import { ClientBaseError } from './ClientBaseError';
import { ErrorName } from './ErrorName';

export class UnprocessableEntityError extends ClientBaseError {
    public readonly validationErrors: FormValidationError[] = [];

    public constructor(message: string, requestId: string, validationErrors: FormValidationError[], detail?: unknown) {
        super(422, ErrorName.UnprocessableEntityError, { message, requestId, detail });
        this.validationErrors = validationErrors;
    }
}
