import { generatePath, matchPath } from 'react-router-dom';
import { isLeft } from 'fp-ts/lib/Either';
import { Decoder } from 'io-ts/Decoder';
import * as z from 'zod';

import { PageParamsDecodeError } from 'errors';
import { i18nErrors } from 'i18n/i18nErrors';
import { RouteParams } from 'types/Route';
import { RoutePathParams } from 'types/RoutePathParams';
import { buildUrl } from 'utils/buildUrl';
import { formatParams } from 'utils/formatParams';
import { getQueryParamsFromSearch } from 'utils/getQueryParams';

// Это функция проверит и оставит в адресе только те параметры,
// которые принимает компонент страницы
export function generatePathTyped<P extends RoutePathParams, S extends {}>(
    path: string,
    params: P,
    search: S,
    decoder: Decoder<unknown, RouteParams<P, S>> | z.ZodType<RouteParams<P, S>, z.ZodTypeDef, unknown>,
) {
    const pathname = generatePath(path, params);

    const parsedParams = matchPath(pathname, { path, exact: true })?.params || {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const parsedQuery = getQueryParamsFromSearch(formatParams(search as any));

    if (decoder instanceof z.ZodType) {
        try {
            const query = decoder.parse({ params: parsedParams, search: parsedQuery });

            return buildUrl({
                pathname,
                search: query.search,
            });
        } catch (e) {
            console.error(e);
            throw new PageParamsDecodeError(i18nErrors('error_in_link_generation'));
        }
    }

    const query = decoder.decode({ params: parsedParams, search: parsedQuery });

    if (isLeft(query)) {
        console.error(query.left);
        throw new PageParamsDecodeError(i18nErrors('error_in_link_generation'));
    }

    return buildUrl({
        pathname,
        search: query.right.search,
    });
}
