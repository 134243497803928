import { FC } from 'react';

import { Cell } from 'components/Cell';
import { withTdAttrs } from 'hocs/withTdAttrs';
import { Text } from 'shared/ui/Text';

import { cn } from './CellText.cn';
import { CellTextProps } from './CellText.types';

const CellTextBase: FC<CellTextProps> = props => {
    const {
        className,
        value,
        getAttrs,
        color,
        overflow,
        typography,
    } = props;

    return (
        <Cell
            className={cn(null, [className])}
            getAttrs={getAttrs}
            size="m"
        >
            <Text color={color} overflow={overflow} typography={typography}>
                {value}
            </Text>
        </Cell>
    );
};

export const CellText = withTdAttrs(CellTextBase);
CellText.displayName = cn();
