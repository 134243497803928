import { FC, useCallback } from 'react';
import { DateRange } from 'react-day-picker';

import { Button } from 'shared/ui/Button';

import { cn } from '../DatePicker.cn';
import { i18n } from '../DatePicker.i18n';

type DrawerHeaderProps = {
   activeField?: 'left' | 'right' | null;
   range: DateRange | undefined;
   onChange: ((value: DateRange | undefined) => void) | ((value: Date | undefined) => void) | undefined;
   leftInputPlaceholder: string;
   rightInputPlaceholder?: string;
}

export const DrawerHeader:FC<DrawerHeaderProps> = props => {
    const {
        activeField,
        onChange,
        range,
        leftInputPlaceholder,
        rightInputPlaceholder,
    } = props;
    const drawerTitleText = (() => {
        if (activeField === 'left') {
            return leftInputPlaceholder;
        }

        if (activeField === 'right') {
            return rightInputPlaceholder;
        }

        return i18n('done');
    })();

    const handleResetDates = useCallback(() => {
        onChange?.(undefined);
    }, [onChange]);

    return (

        <div className={cn('DrawerHeader')}>
            <div className={cn('DrawerTitle')}>
                {drawerTitleText}
            </div>
            {(range?.from || range?.to) && (
            <Button
                className={cn('ResetButton')}
                id="ResetButton"
                size="m"
                view="ghost"
                onClick={handleResetDates}
            >
                {i18n('reset')}
            </Button>
            )}
        </div>
    );
};
