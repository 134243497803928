import { FC } from 'react';
import { TextColorValue } from '@yandex-lego/components/Text';

import { IconProps } from 'components/Icon';

import { IconCheckCircle, IconCloseCircle } from '../../Icon/Icons';

type GetIcon = (isFreeEntrance: boolean) => {
    IconComponent: FC<IconProps>;
    iconColor: TextColorValue;
};

export const getIcon: GetIcon = isFreeEntrance => {
    const IconComponent = isFreeEntrance ? IconCheckCircle : IconCloseCircle;
    const iconColor = isFreeEntrance ? 'success' : 'alert';

    return {
        IconComponent,
        iconColor,
    };
};
