import * as z from 'zod';

import { ActivePage } from 'shared/constants/ActivePage';
import { numberInString } from 'shared/lib/zod/numberInString';

import { route } from './route';

export const TABS = ['active', 'cancelled'] as const;
export type Tab = (typeof TABS)[number];

export const RoutePersonTripDecoder = z.object({
    params: z.object({
        tripId: numberInString,
        personId: z.string(),
    }),
    search: z.object({
        tab: z.enum(TABS).optional(),
    }),
});

export type RoutePersonTrip = z.output<typeof RoutePersonTripDecoder>;

export const RoutePersonTrip = route(
    '/trips/:tripId(\\d+)/person/:personId/',
    RoutePersonTripDecoder,
    {
        activePage: ActivePage.ALL_TRIPS_LIST,
    },
);
