import { useEffect } from 'react';

import { createUseQueryHook } from 'hooks/createUseQueryHook';
import { Employee } from 'services/SwaggerApi';
import { addToast } from 'utils/addToast';
import {
    isFailure,
    isLoading,
    isSuccess,
    mapLoadable,
} from 'utils/Loadable';

import { i18n } from '../CellEmployeeEmail.i18n';

type UseEmail = (
    companyId: Employee['company_id'],
    employeeId: Employee['employee_id'],
    isEmailMasked: boolean,
    emailMasked: Employee['email'],
) => {
    isLoading: boolean;
    hasUnmaskedEmail: boolean;
    email: string;
};

export const useEmail: UseEmail = (companyId, employeeId, isEmailMasked, emailMasked) => {
    const employee = createUseQueryHook(
        'employee_detail',
        {
            enabled: !isEmailMasked,
        },
    )(companyId, employeeId);
    const emailUnmasked = mapLoadable(employee, ({ email }) => email);

    useEffect(() => {
        if (isFailure(emailUnmasked)) {
            addToast({ title: i18n('failed_to_receive_email') });
        }
    }, [emailUnmasked]);

    const isEmailLoading = isLoading(emailUnmasked);
    const hasUnmaskedEmail = !isEmailMasked && isSuccess(emailUnmasked);
    const email = hasUnmaskedEmail ? emailUnmasked.result : emailMasked;

    return {
        isLoading: isEmailLoading,
        hasUnmaskedEmail,
        email,
    };
};
