import { useContext } from 'react';

import { CompanyFinanceWidgets } from 'components/CompanyFinanceWidgets';
import { CurrentCompanyContext } from 'components/CurrentCompanyProvider/CurrentCompanyProvider.context';
import { HeaderPage } from 'components/HeaderPage';
import { Page } from 'components/Page';
import { TableTransactions } from 'components/TableTransactions';
import { TableTransactionsLite } from 'components/TableTransactionsLite';
import { useMeta } from 'hooks/useMeta';
import { RouteFinance } from 'routes/RouteFinance';
import { Container } from 'shared/ui/Container';

import { cn } from './PageFinance.cn';
import { i18n } from './PageFinance.i18n';

import './PageFinance.css';

export const PageFinance: Page<RouteFinance> = () => {
    const {
        user: {
            is_support,
            is_coordinator,
            is_accountant,
            is_yandex_employee,
        },
    } = useMeta();
    const { currentCompanyId } = useContext(CurrentCompanyContext);
    const showFullTableTransactions = (is_coordinator && is_yandex_employee) || is_support || is_accountant;

    return (
        <Page className={cn()}>
            <HeaderPage title={i18n('transaction_history')} />
            <Container>
                {!is_support && (
                    <CompanyFinanceWidgets className={cn('Widgets')} companyId={currentCompanyId} />
                )}
                { showFullTableTransactions ?
                    <TableTransactions className={cn('Table')} companyId={currentCompanyId} /> :
                    <TableTransactionsLite className={cn('Table')} companyId={currentCompanyId} />
                }
            </Container>
        </Page>
    );
};

PageFinance.displayName = cn();
