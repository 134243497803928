import { FC } from 'react';
import { Image } from '@yandex-lego/components/Image/desktop';

import { HelpTooltip } from 'components/HelpTooltip';
import { Text } from 'shared/ui/Text';
import { getTypedI18nLang } from 'utils/i18n';

import failedToLoadPlaceholderEn from './ServiceCard.assets/failedToLoadPlaceholderEn.svg';
import failedToLoadPlaceholderRu from './ServiceCard.assets/failedToLoadPlaceholderRu.svg';
import { cn } from './ServiceCard.cn';
import { ServiceCardProps } from './ServiceCard.types';

import './ServiceCard.css';

export const ServiceCard: FC<ServiceCardProps> = props => {
    const {
        className,
        contentClassName,
        children,
        imageUrl,
        banner,
        imageElement,
    } = props;

    const { title, tooltipText, type } = banner ?? {};

    const lang = getTypedI18nLang();
    const failedToLoadPlaceholder = lang === 'en' ? failedToLoadPlaceholderEn : failedToLoadPlaceholderRu;

    return (
        <div className={cn(null, [className])}>
            {title && (
                <Text
                    as="div"
                    className={cn('Banner', { type })}
                    color="inverse"
                    typography="body-s"
                    weight="medium"
                >
                    {title}
                    {tooltipText && (
                        <HelpTooltip
                            hasTail
                            inverse
                            className={cn('Tooltip')}
                            content={<Text className={cn('TooltipText')}>{tooltipText}</Text>}
                            direction={['left']}
                            iconSize="m"
                        />)
                    }
                </Text>)}
            {/* предлагаю оставить пока здесь, для обратной совместимости,
                    после убрать заменив на новый просмоторщик */}
            {imageUrl && (
                <div className={cn('ImageWrapper')}>
                    <Image
                        className={cn('Image', { withBanner: Boolean(title) })}
                        fallbackSrc={failedToLoadPlaceholder}
                        src={imageUrl}
                    />
                </div>
            )}

            {imageElement && (<div className={cn('ImageContainer')}>{imageElement}</div>)}

            <div className={cn('Main', [contentClassName])}>
                {children}
            </div>
        </div>
    );
};

ServiceCard.displayName = cn();
