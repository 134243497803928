import { UnknownError } from 'errors';
import { useTrainDetailsTravel } from 'hooks/useTrainDetailsTravel';
import { BaseTrain, TrainCarriageDetail } from 'services/SwaggerApi';
import { TYTrainDetails } from 'services/TravelApi/TYTrainDetails';
import { decodeLoadable } from 'utils/decodeLoadable';
import { failure } from 'utils/Loadable';

import { getTrainDetailsQuery } from '../TrainCarriage.utils/getTrainDetailsQuery';

import { useMapToTrainTravel } from './useMapToTrainTravel';

type UseTrainTravel = (
    train: BaseTrain | null,
    carriageNumber: TrainCarriageDetail['carriage_number']
) => ReturnType<typeof useMapToTrainTravel>;

export const useTrainTravel: UseTrainTravel = (train, carriageNumber) => {
    const {
        hasAllTrainDetails,
        query: trainDetailsQuery,
    } = getTrainDetailsQuery(train);

    let trainDetailsUntyped = useTrainDetailsTravel(
        { enabled: hasAllTrainDetails },
        trainDetailsQuery,
    );

    if (!hasAllTrainDetails) {
        const error = new UnknownError('Not enough train details to get schema');

        trainDetailsUntyped = failure(error);
    }

    const trainDetails = decodeLoadable(trainDetailsUntyped, TYTrainDetails);

    const trainTravel = useMapToTrainTravel(trainDetails, carriageNumber);

    return trainTravel;
};
