import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_title-l.css';

export type WithTypographyTitleLProps = {
    typography?: 'title-l';
};

export const withTypographyTitleL = withBemMod<WithTypographyTitleLProps>(
    cn(),
    { typography: 'title-l' },
);
