import * as D from 'io-ts/Decoder';

import { ActivePage } from 'shared/constants/ActivePage';

import { route } from './route';
import { RouteSearchDecoder } from './RouteSearch';

export type RouteSearchMap = D.TypeOf<typeof RouteSearchDecoder>;

export const RouteSearchMap = route(
    '/search/:searchId/map/',
    RouteSearchDecoder,
    {
        activePage: ActivePage.ALL_TRIPS_LIST,
    },
);
