import { i18nErrors } from 'i18n/i18nErrors';
import { ToastContentProps } from 'shared/ui/ToastContent';

export const getErrorInfo = (errorDetail: unknown): ToastContentProps => {
    if (typeof errorDetail === 'string') {
        return {
            title: i18nErrors('error_default_title'),
            message: errorDetail,
        };
    }

    return {
        title: i18nErrors('error_default_title'),
        message: i18nErrors('error_default_message'),
    };
};
