import { useMemo } from 'react';

import { CellCheck } from 'components/TableCells/CellCheck';
import { CellCurrency } from 'components/TableCells/CellCurrency';
import { CellDate } from 'components/TableCells/CellDate';
import { CellHeader } from 'components/TableCells/CellHeader';
import { CellPerson } from 'components/TableCells/CellPerson';
import { CellStatus } from 'components/TableCells/CellStatus';
import { CellText } from 'components/TableCells/CellText';
import { CellTripLink } from 'components/TableCells/CellTripLink';

import { i18n } from '../../TableTransactionsLite.i18n';

import { StatusToColor, UseColumns } from './useColumns.types';

const statusToColor: StatusToColor = {
    paid: 'default',
    verified: 'blue',
    completed: 'green',
    hold: 'blue',
};

export const useColumns: UseColumns = () => {
    return useMemo(() => [
        {
            accessor: 'trip_id',
            Header: props => <CellHeader {...props} value={i18n('trip_id_header')} />,
            Cell: props => {
                const {
                    value: tripId,
                    row: { original: transaction },
                } = props;

                const { person: { person_id } } = transaction;

                return (
                    <CellTripLink
                        {...props}
                        personId={person_id}
                        tripId={tripId}
                    />
                );
            },
            sticky: 'left',
        },
        {
            id: 'person',
            accessor: ({
                person: {
                    login,
                    first_name: firstName,
                    last_name: lastName,
                },
            }) => ({
                login,
                firstName,
                lastName,
            }),
            Header: props => <CellHeader {...props} value={i18n('person_header')} />,
            Cell: CellPerson,
            width: 260,
        },
        {
            id: 'service_type',
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            accessor: ({ service_type }) => i18n(`service_type_value_${service_type}` as any),
            Header: props => <CellHeader {...props} value={i18n('service_type_header')} />,
            Cell: CellText,
        },
        {
            id: 'execution_date',
            accessor: ({ execution_date }) => new Date(execution_date),
            Header: props => <CellHeader {...props} value={i18n('execution_date_header')} />,
            Cell: CellDate,
        },
        {
            id: 'type',
            accessor: ({ type }) => i18n(`type_value_${type}`),
            Header: props => <CellHeader {...props} value={i18n('type_header')} />,
            Cell: CellText,
        },
        {
            accessor: 'is_penalty',
            Header: props => <CellHeader {...props} value={i18n('is_penalty_header')} />,
            Cell: CellCheck,
            width: 80,
        },
        {
            id: 'cost_center',
            accessor: ({ cost_center }) => cost_center?.name,
            Header: props => <CellHeader {...props} value={i18n('cost_center_header')} />,
            Cell: CellText,
        },
        {
            accessor: 'price',
            Header: props => <CellHeader {...props} align="right" value={i18n('price_header')} />,
            Cell: CellCurrency,
        },
        {
            accessor: 'fee',
            Header: props => <CellHeader {...props} align="right" value={i18n('fee_header')} />,
            Cell: CellCurrency,
        },
        {
            accessor: 'full_price',
            Header: props => <CellHeader {...props} align="right" value={i18n('full_price_header')} />,
            Cell: CellCurrency,
        },
        {
            id: 'status',
            accessor: ({ status }) => ({ text: i18n(`status_value_${status}`), color: statusToColor[status] }),
            Header: props => <CellHeader {...props} value={i18n('status_header')} />,
            Cell: CellStatus,
            width: 180,
        },
    ], []);
};
