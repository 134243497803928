import { FC, useMemo } from 'react';

import { Text } from 'shared/ui/Text';
import { getRouteCities } from 'utils/aeroclub/getRouteCities';
import { compactDates } from 'utils/formatDate';
import { getFlightDatesText } from 'utils/formatDate/getFlightDatesText';

import { cn } from './ServiceHeader.cn';
import { ServiceHeaderProps } from './ServiceHeader.types';

import './ServiceHeader.css';

export const ServiceHeader: FC<ServiceHeaderProps> = props => {
    const { serviceDetails } = props;

    const cities = useMemo(() => {
        switch (serviceDetails.service_type) {
            case 'rail':
                const railOrder = serviceDetails;
                const departureCity = railOrder.train.departure?.city?.name;
                const arrivalCity = railOrder.train.arrival?.city?.name;

                if (departureCity && arrivalCity) {
                    return [departureCity, arrivalCity];
                }

                return [];

            case 'avia':
                const aviaOrder = serviceDetails;

                if (!aviaOrder.flight.legs) {
                    return [];
                }

                const leg = aviaOrder.flight.legs[0];

                return [
                    leg.segments[0].departure.city?.name,
                    leg.segments[leg.segments.length - 1].arrival.city?.name,
                ];

            case 'hotel':
                const hotelOrder = serviceDetails;

                return [hotelOrder.hotel.location.city?.name ?? hotelOrder.location_name];

            default:
                return [];
        }
    }, [serviceDetails]);

    const datesText = useMemo(() => {
        switch (serviceDetails.service_type) {
            case 'rail':
                const railOrder = serviceDetails;

                return compactDates(railOrder.departure_at, railOrder.arrival_at);

            case 'avia':
                return getFlightDatesText(serviceDetails.flight);

            case 'hotel':
                const hotelOrder = serviceDetails;

                return compactDates(hotelOrder.start_at, hotelOrder.end_at);
        }
    }, [serviceDetails]);

    const citiesText = getRouteCities(...cities);

    return (
        <div className={cn(null, [props.className])}>
            <Text className={cn('Cities')} typography="title-m">
                {citiesText}
            </Text>
            <Text className={cn('Dates')} color="secondary" typography="body-s">
                {datesText}
            </Text>
        </div>
    );
};

ServiceHeader.displayName = cn();
