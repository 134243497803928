import { FC } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';

import { FlightFilterKey } from 'api/models/filters/FlightFilterKey';
import { FieldSort, getSortOptionValue } from 'components/FieldSort';
import { FilterFieldAirlines } from 'components/FilterFieldAirlines';
import { FilterFieldAirports } from 'components/FilterFieldAirports';
import { FilterFieldCheckbox } from 'components/FilterFieldCheckbox';
import { FilterFieldTime } from 'components/FilterFieldTime';
import { FilterFieldTransfers } from 'components/FilterFieldTransfers';
import { FilterGroup } from 'components/FilterGroup';
import { withProps } from 'hocs/withProps';
import { useAvailableFilters } from 'hooks/useAvailableFilters';
import { useCallbackWithScrollToTop } from 'hooks/useCallbackWithScrollToTop';
import { useMeta } from 'hooks/useMeta';
import { i18nAction } from 'i18n/i18nAction';
import { i18nTravelPolicy } from 'i18n/i18nTravelPolicy';
import { Divider } from 'shared/ui//Divider';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import { usePrepareFilters } from './FlightFilters.hooks/usePrepareFilters';
import { cn } from './FlightFilters.cn';
import { i18n } from './FlightFilters.i18n';
import { FlightFiltersFormValues, FlightFiltersProps } from './FlightFilters.types';

import './FlightFilters.css';

const Header: FC<{ slug: Parameters<typeof i18n>[0] }> = ({ slug }) =>
    <Text as="div" className={cn('Header')} typography="body-m">{i18n(slug)}</Text>;
const FieldAirports = withProps({ component: FilterFieldAirports }, cn('FilterOptions'))(Field);
const FieldTime = withProps({ component: FilterFieldTime }, cn('FilterOptions'))(Field);

const defaultValues: FlightFiltersFormValues = {
    cabin_classes: [],
    air_companies: [],
    departure_there_timespan: [],
    arrival_there_timespan: [],
    departure_back_timespan: [],
    arrival_back_timespan: [],
    departure_from_there: [],
    arrival_to_there: [],
    departure_from_back: [],
    arrival_to_back: [],
};

export const FlightFilters: FC<FlightFiltersProps> = props => {
    const {
        filters: filtersRaw,
        search,
        instant,
        onSubmit,
        onChange,
        isOneWay,
    } = props;

    const { user: { is_tp_enabled } } = useMeta();

    const isAvailable = useAvailableFilters<FlightFilterKey>(filtersRaw);
    const filters = usePrepareFilters(filtersRaw);

    const initialValues = {
        ...defaultValues,
        ...search,
        sortOptionValue: getSortOptionValue({ order_by: search.order_by, is_descending: search.is_descending }, 'Avia'),
    };

    const isRoundTrip = !isOneWay;
    const hasTimeSpanFilters = (
        isAvailable('departure_there_timespan') &&
        isAvailable('arrival_there_timespan')
    );
    const hasTimeSpanRoundFilters = (
        isRoundTrip &&
        isAvailable('departure_back_timespan') &&
        isAvailable('arrival_back_timespan')
    );
    const hasMaximumTransfersFilters = isAvailable('maximum_transfers_count');
    const hasAirlinesFilters = isAvailable('air_companies');
    const hasAirportsFilters = (
        isAvailable('departure_from_there') &&
        isAvailable('arrival_to_there')
    );
    const hasAirportsRoundFilters = (
        isAvailable('departure_from_back') &&
        isAvailable('arrival_to_back') &&
        hasAirportsFilters &&
        isRoundTrip
    );

    const compliantTravelPolicyFilterVisible = !is_tp_enabled;

    const onSubmitWithScrollToTop = useCallbackWithScrollToTop(onSubmit);

    return (
        <Form
            {...props}
            initialValues={initialValues}
            onSubmit={onSubmitWithScrollToTop}
        >
            {render => {
                return (
                    <form
                        className={cn(null, [props.className])}
                        onSubmit={render.handleSubmit}
                    >
                        {instant && <FormSpy onChange={onChange} />}
                        <Field
                            className={cn('FieldSort')}
                            component={FieldSort}
                            name="sortOptionValue"
                            type="Avia"
                        />

                        <div className={cn('QuickFilters')}>
                            <Field component={FilterFieldCheckbox} label={i18n('with_baggage')} name="has_baggage" />
                            <Field component={FilterFieldCheckbox} label={i18n('with_refundable')} name="is_refundable" />
                            <Field component={FilterFieldCheckbox} label={i18n('with_changeable')} name="is_changeable" />
                            {compliantTravelPolicyFilterVisible && (
                            <Field activeValue="0" component={FilterFieldCheckbox} label={i18nTravelPolicy('compliant')} name="is_restricted_by_travel_policy" />
                            )}
                        </div>

                        {hasMaximumTransfersFilters && (
                            <>
                                <Divider />
                                <FilterGroup label={i18n('transfers')}>
                                    <Field component={FilterFieldTransfers} name="maximum_transfers_count" transfers={filters.maximum_transfers_count} />
                                </FilterGroup>
                            </>
                        )}

                        {hasTimeSpanFilters && (
                            <>
                                <Divider />
                                <FilterGroup label={i18n('time_there')}>
                                    <Header slug="departure" />
                                    <FieldTime name="departure_there_timespan" time={filters.departure_there_timespan} />
                                    <Header slug="arrival" />
                                    <FieldTime name="arrival_there_timespan" time={filters.arrival_there_timespan} />
                                </FilterGroup>
                            </>
                        )}

                        {hasTimeSpanRoundFilters && (
                            <>
                                <Divider />
                                <FilterGroup label={i18n('time_back')}>
                                    <Header slug="departure" />
                                    <FieldTime name="departure_back_timespan" time={filters.departure_back_timespan} />
                                    <Header slug="arrival" />
                                    <FieldTime name="arrival_back_timespan" time={filters.arrival_back_timespan} />
                                </FilterGroup>
                            </>
                        )}

                        {hasAirlinesFilters && (
                            <>
                                <Divider />
                                <FilterGroup label={i18n('airlines')}>
                                    <Field airlines={filters.air_companies} component={FilterFieldAirlines} name="air_companies" />
                                </FilterGroup>
                            </>
                        )}

                        {hasAirportsFilters && (
                            <>
                                <Divider />
                                <FilterGroup label={i18n('flight_there')}>
                                    <Header slug="departure" />
                                    <FieldAirports airports={filters.departure_from_there} name="departure_from_there" />
                                    <Header slug="arrival" />
                                    <FieldAirports airports={filters.arrival_to_there} name="arrival_to_there" />
                                </FilterGroup>
                            </>
                        )}

                        {hasAirportsRoundFilters && (
                            <>
                                <Divider />
                                <FilterGroup label={i18n('flight_back')}>
                                    <Header slug="departure" />
                                    <FieldAirports airports={filters.departure_from_back} name="departure_from_back" />
                                    <Header slug="arrival" />
                                    <FieldAirports airports={filters.arrival_to_back} name="arrival_to_back" />
                                </FilterGroup>
                            </>
                        )}

                        {!instant && (
                        <div className={cn('ActionButtons')}>
                            {/* eslint-disable-next-line react/jsx-no-bind */}
                            <Button
                                className={cn('ActionButton')}
                                size="m"
                                view="outline"
                                width="max"
                                onClick={() => render.form.reset(defaultValues)}
                            >
                                {i18nAction('reset')}
                            </Button>
                            <Button
                                className={cn('ActionButton', { type: 'submit' })}
                                size="m"
                                type="submit"
                                view="brand"
                                width="max"
                            >
                                {i18nAction('apply')}
                            </Button>
                        </div>
                        )}
                    </form>
                );
            }}
        </Form>
    );
};

FlightFilters.displayName = cn();
