import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Spin } from '@yandex-lego/components/Spin/bundle';

import { Bubble } from 'components/Bubble';
import { Card } from 'components/Card';
import { getIconProvider, IconClose } from 'components/Icon';
import { useDeleteService } from 'hooks/useDeleteService';
import { i18nTravelPolicy } from 'i18n/i18nTravelPolicy';
import { RoutePersonTrip } from 'routes/RoutePersonTrip';
import { Button } from 'shared/ui/Button';
import { Dot } from 'shared/ui/Dot';
import { InlineNotification } from 'shared/ui/InlineNotification';
import { ListItem } from 'shared/ui/ListItem';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { formatTime } from 'utils/formatDate';

import { cn } from '../HotelDetails.cn';
import { i18n } from '../HotelDetails.i18n';

import { PassengersProps } from './Passengers.types';

import '../HotelDetails.css';

const iconCloseProvider = getIconProvider(IconClose, { size: 'm' });

export const Passengers: FC<PassengersProps> = props => {
    const {
        addedServices,
        className,
        dispatchService,
        personId,
        tripId,
    } = props;

    const history = useHistory();
    const goToPersonTrip = useCallback(() => {
        const personTripUrl = RoutePersonTrip.getPath({ tripId, personId }, {});

        history.push(personTripUrl);
    }, [history, personId, tripId]);

    const [deleteService] = useDeleteService({
        onSuccess: (_, serviceId) => {
            dispatchService({
                type: 'removeService',
                payload: { serviceId },
            });
        },
        onError: (_, serviceId) => {
            dispatchService({
                type: 'unsetLoading',
                payload: { serviceId },
            });
        },
    });

    const handleRemoveService = useCallback((serviceId: number) => () => {
        dispatchService({
            type: 'setLoading',
            payload: { serviceId },
        });
        deleteService(serviceId);
    }, [deleteService, dispatchService]);

    const canGoToPersonTrip = addedServices.length > 0 &&
       addedServices.filter(service => service.isLoading).length === 0;

    const hasServicesOverTP = addedServices.find(service => service.isTPViolation);

    return (
        <Card
            className={cn('ParticipantsList')}
            description={addedServices.length === 0 ?
                i18n('no_rooms_selected') :
                i18n('rooms_selected')
            }
            size="s"
            title={i18n('passengers_list')}
        >
            <div className={cn('Passengers', [className])}>
                <div className={cn('PassengersGroup')}>
                    {addedServices.map(service => {
                        const plusOne = (service.adultsCount && service.adultsCount > 1) ? ` + ${service.adultsCount - 1}` : '';

                        return (
                            <div key={service.roomId} className={cn('Passenger')} data-testid="added-passenger">
                                <div className={cn('PassengerName')}>
                                    <Bubble
                                        bubble={{
                                            personId: '',
                                            type: 'person',
                                            login: service.login || '',
                                            name: service.personName || '',
                                        }}
                                        className={cn('Avatar')}
                                        size="xs"
                                        type={service.isExternal ? 'external' : 'person'}
                                    />
                                    <Text color="secondary" typography="body-s">{service.personName}{plusOne}</Text>
                                </div>

                                <ListItem
                                    key={String(service.roomName) + String(service.personId)}
                                    expanded
                                    transparent
                                    leadingTextParams={{
                                        label: (
                                            <span className={cn('RoomDescription')}>
                                                <Text className={cn('RoomName')}>{service.roomName}</Text>
                                                <Text weight="medium">{formatCurrency(service.price)}</Text>
                                            </span>
                                        ),
                                        caption: (
                                            <Text>
                                                {service.isTPViolation && (
                                                    <Text as="div" color="alert">{i18nTravelPolicy('not_compliant_short')}</Text>
                                                )}
                                                {[
                                                    service.mealIncluded ? i18n('meal_included') : i18n('no_meal'),
                                                    service.noFee ? i18n('free_cancelation') : i18n('cancelation_with_fee'),
                                                ].filter(Boolean)
                                                    .join(` ${Dot} `)}
                                            </Text>
                                        ),
                                    }}
                                    trailing={service.isLoading ?
                                        <Button
                                            disabled
                                            data-testid="service-spinner"
                                            icon={getIconProvider(Spin, {
                                                progress: true,
                                                size: 'xxs',
                                                view: 'default',
                                                className: cn('AddServiceSpinner'),
                                            })}
                                            size="m"
                                            view="outline"
                                        /> :
                                        <Button
                                            icon={iconCloseProvider}
                                            size="m"
                                            view="outline"
                                            onClick={handleRemoveService(service.serviceId!)}
                                        />
                                    }
                                />
                                {service.early_check_in && (
                                    <ListItem
                                        expanded
                                        transparent
                                        leadingTextParams={{
                                            label: (
                                                <span className={cn('CheckIn')}>
                                                    <Text>{i18n('early_check_in')}</Text>
                                                    <Text weight="medium">{`${formatCurrency(service.early_check_in.price)}`}</Text>
                                                </span>
                                            ),
                                            caption: i18n('early_check_in_from', { time: formatTime(service.early_check_in.hour) }),
                                        }}
                                    />
                                )}
                                {service.late_check_out && (
                                    <ListItem
                                        expanded
                                        transparent
                                        leadingTextParams={{
                                            label: (
                                                <span className={cn('CheckOut')}>
                                                    <Text>{i18n('late_check_out')}</Text>
                                                    <Text weight="medium">{`${service.late_check_out.price} ₽`}</Text>
                                                </span>
                                            ),
                                            caption: i18n('late_check_out_before', { time: formatTime(service.late_check_out.hour) }),
                                        }}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>

                {hasServicesOverTP && (
                    <InlineNotification view="error">
                        {i18nTravelPolicy('additional_confirm_info')}
                    </InlineNotification>
                )}

                <Button
                    className={cn('GoToTripButton')}
                    disabled={!canGoToPersonTrip}
                    size="l"
                    view="brand"
                    width="max"
                    onClick={goToPersonTrip}
                >
                    {i18n('go_to_trip')}
                </Button>
            </div>
        </Card>
    );
};
