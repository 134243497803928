import { i18n } from 'components/PageProfile/PageProfile.i18n';
import { AvailableGroupsResponse } from 'services/SwaggerApi';

export const getAvailableGroupOptions = (groupsList: AvailableGroupsResponse) => {
    const options = groupsList.found.map(item => ({
        value: item.group_id,
        content: item.name,
    }));

    const notSelectedOption = {
        value: 0,
        content: i18n('not_selected'),
    };

    return [notSelectedOption, ...options];
};
