import * as yup from 'yup';

import { i18nErrors } from 'i18n/i18nErrors';

import { i18n } from './RegistrationFeedbackForm.i18n';
import { ContactMethod } from './RegistrationFeedbackForm.types';

export const contactMethods: ContactMethod[] = ['email', 'phone'];

export const schema = yup.object({
    message: yup.string().trim().required(),
    contactMethod: yup.mixed().oneOf(contactMethods),
    email: yup.string().trim().when(['contactMethod'], {
        is: (contactMethod: ContactMethod) => contactMethod === 'email',
        then: yup.string()
            .required()
            .email(i18n('email_invalid')),
    }),
    phone: yup.string().trim().when(['contactMethod'], {
        is: (contactMethod: ContactMethod) => contactMethod === 'phone',
        then: yup.string()
            .max(15)
            .required(),
    }),
    eulaAccepted: yup.boolean().isTrue(i18nErrors('required_field')),
});
