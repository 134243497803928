import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { i18nErrors } from 'i18n/i18nErrors';
import { GroupAvailablePersonsResponse } from 'services/SwaggerApi';

type UseEmployeeDirectoryProps = {
    membersIds: string[];
    persons: GroupAvailablePersonsResponse[];
    setCheckedPersons: Dispatch<SetStateAction<GroupAvailablePersonsResponse[]>>;
};

export function useEmployeeDirectory(props: UseEmployeeDirectoryProps) {
    const { persons, membersIds, setCheckedPersons } = props;

    const [selectedPersonsIds, setSelectedPersonsIds] = useState<string[]>(membersIds);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();

            const code = event.target.dataset.code;

            if (code === undefined) {
                throw new Error(i18nErrors('option_code_undefined'));
            }

            setSelectedPersonsIds(prev =>
                prev.includes(code) ? prev.filter(selectedCode => selectedCode !== code) : [...prev, code],
            );

            setCheckedPersons(prev => {
                const isPersonInList = prev.some(person => person.person_id === code);

                if (isPersonInList) {
                    return prev.filter(person => person.person_id !== code);
                }

                const pickedPerson = persons?.find(person => person.person_id === code);

                return pickedPerson ? [...prev, pickedPerson] : prev;
            });
        },
        [persons, setCheckedPersons],
    );

    return {
        selectedPersonsIds,
        handleChange,
    };
}
