import { createContext, FC, useCallback, useContext } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import { FieldSelect } from 'components/FormField/FormField.providers';
import { validateRequiredField } from 'utils/form/validateRequiredField';

import { getIdsByKind } from './Purposes.utils/getIdsByKind';
import { handleConfOptions } from './Purposes.utils/handleConfOptions';
import { usePurposeOptions } from './Purposes.utils/usePurposeOptions';
import { cn } from './Purposes.cn';
import { i18n } from './Purposes.i18n';
import { PurposesProps } from './Purposes.types';

import './Purposes.css';

const PurposesContext = createContext<number[]>([]);

const PurposesSelect: FC<FieldRenderProps<number[], HTMLElement>> = props => {
    const confIds = useContext(PurposesContext);
    const onChange = props.input.onChange;
    const handleChange = useCallback((e: { target: { value: number[] }}) => {
        onChange({
            target: {
                value: handleConfOptions(e.target.value, confIds),
            },
        });
    }, [confIds, onChange]);

    return (
        <FieldSelect
            {...props}
            input={{
                ...props.input,
                onChange: handleChange,
            }}
        />
    );
};

export const Purposes: FC<PurposesProps> = props => {
    const {
        purposes,
        selected,
        setPurposesSelected,
    } = props;
    const { confIds } = getIdsByKind(purposes);
    const purposesOptions = usePurposeOptions(purposes, selected);

    return (
        <>
            <PurposesContext.Provider value={confIds}>
                <Field
                    required
                    className={cn(null, [props.className])}
                    component={PurposesSelect}
                    name="purposes"
                    options={purposesOptions}
                    placeholder={i18n('choose_goal')}
                    title={i18n('goal')}
                    validate={validateRequiredField}
                    valueOC={selected}
                />
            </PurposesContext.Provider>
            <OnChange name="purposes">{setPurposesSelected}</OnChange>
        </>
    );
};

Purposes.displayName = cn();
