import * as D from 'io-ts/Decoder';

import { ActivePage } from 'shared/constants/ActivePage';

import { route } from './route';

export const ProfileTab = D.union(
    D.literal('profile'),
    D.literal('documents'),
    D.literal('loyalty_cards'),
    D.literal('companions'),
    D.literal('access'),
    D.literal('travel_policy'),
    D.literal('settings'),
);

export type ProfileTab = D.TypeOf<typeof ProfileTab>;

export const RouteProfileDecoder = D.type({
    params: D.partial({
        personId: D.string,
    }),
    search: D.partial({
        retpath: D.string,
        tab: ProfileTab,
    }),
});

export type RouteProfile = D.TypeOf<typeof RouteProfileDecoder>;

export const RouteProfile = route(
    '/profile/:personId?',
    RouteProfileDecoder,
    {
        activePage: ActivePage.PROFILE,
    },
);
