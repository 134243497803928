import { TrainCarriageDetail } from 'services/SwaggerApi';

export function sortCarriages(a: TrainCarriageDetail, b: TrainCarriageDetail) {
    // Сначала сортируем по тревел полиси,
    // а потом по цене от меньшего к большему
    if (a.is_travel_policy_compliant && !b.is_travel_policy_compliant) {
        return -1;
    }

    if (!a.is_travel_policy_compliant && b.is_travel_policy_compliant) {
        return 1;
    }

    return a.min_price - b.min_price;
}
