import { async } from './async';
import type { SupportedBiometryType } from './constants';
import { sync } from './sync';
import type { ConfirmOperationResult, DomainVerificationData, ReportError } from './types';
import { isAndroid, isIOS, isMethodSupported } from './utils';

export function openURLInBrowser(url: string, external = false) {
    sync('openURLInBrowser')({ url, external });
}

export function showToast(toast: string) {
    sync('showToast')(toast);
}

/**
 * Открывает нативным методом файл. Скачивание файла браузером при этом не происходит, загрузка вынесена в натив.
 * В браузере просто скачивает файл
 * @param url должна быть обязательно абсолютной ссылкой с указанием протокола, без редиректов, отдающая сразу файл
 * @param filename Если не передан, попытается вывести имя из url
 * @param mime Влияет на то, с помощью каких приложений будет предложено открыть передаваемый файл.
 * Попытается вывести mime из расширения файла если не передан.
 * @returns промис, разрешаемый после завершения нативного диалога
 */
export function openFile(url: string, filename?: string, mime?: string) {
    return async<void>('openFile')({ url, filename, mime });
}

export function showAlert(
    title: string,
    message: string,
    defaultActionTitle?: string,
    cancelActionTitle?: string,
) {
    return async<void>('showAlert')({
        title,
        message,
        defaultActionTitle,
        cancelActionTitle,
    });
}

export function switchAccount() {
    sync('switchAccount')();
}

export function reportError(name = 'Unknown error', error: ReportError) {
    sync('reportError')({ name, error });
}

export function reportEvent(message: string, data: Object) {
    sync('reportEvent')({ message, data });
}

export type UpdateUserAttributesData = {
    isStaff?: boolean;
    organizationId?: string;
    trackerUid?: string;
};

export function updateUserAttributes(data: UpdateUserAttributesData) {
    sync('updateUserAttributes')({ data });
}

export function setNotificationCounter(value: number) {
    if (!isIOS()) {
        return;
    }

    try {
        sync('setNotificationCounter')(value);
    } catch {}
}

export function shareText(text: string) {
    sync('shareText')({ text });
}

export function setStartPage(text: string) {
    const name = 'setStartPage';

    if (isMethodSupported(name)) {
        sync(name)(text);
    }
}

export function getSupportedBiometryType() {
    return async<SupportedBiometryType>('getSupportedBiometryType')({});
}

export function confirmOperation(id: string, title?: string, isBiometryAllowed = true) {
    return async<ConfirmOperationResult>('confirmOperation')({
        operationId: id,
        title,
        isBiometryAllowed,
    });
}

export function setupPINAuth() {
    return async<boolean>('setupPINAuth')({});
}

export function removePINAuth() {
    return async<boolean>('removePINAuth')({});
}

export function checkPINAuthEnabled() {
    return async<boolean>('checkPINAuthEnabled')({});
}

export function setupBiometryAuth() {
    return async<boolean>('setupBiometryAuth')({});
}

export function removeBiometryAuth() {
    return async<boolean>('removeBiometryAuth')({});
}

export function checkBiometryAuthEnabled() {
    return async<boolean>('checkBiometryAuthEnabled')({});
}

export function makeWebAppVisible() {
    const name = 'makeWebAppVisible';

    if (isMethodSupported(name)) {
        sync(name)({});
    }
}

export function setWebViewUrlHandling(allowedUrls: ReadonlyArray<string>, enabled = true) {
    const name = 'setWebViewUrlHandling';

    if (isMethodSupported(name)) {
        sync(name)({
            allowedUrls,
            enabled,
        });
    }
}

export function getNotificationData() {
    const name = 'getNotificationData';

    if (!isIOS() || !isMethodSupported(name)) {
        return;
    }

    return async<PushNotificationData>(name)({});
}

export function getDomainVerificationData() {
    const name = 'getDomainVerificationData';

    if (!isAndroid() || !isMethodSupported(name)) {
        return Promise.resolve<DomainVerificationData>({
            verified: [],
            selected: [],
            unapproved: [],
        });
    }

    return async<DomainVerificationData>(name)(undefined);
}

export function showOpenByDefaultSettings() {
    const name = 'showOpenByDefaultSettings';

    if (!isAndroid() || !isMethodSupported(name)) {
        return;
    }

    sync(name)();
}
