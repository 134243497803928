import { useCallback } from 'react';
import { useToggle } from 'react-use';
import { Link } from '@yandex-lego/components/Link/desktop/bundle';

import { RegisterModalLayout } from 'components/RegisterModalLayout';
import { RegistrationFeedbackForm } from 'components/RegistrationFeedbackForm';
import { withModalView } from 'hocs/withModalView';
import { Text } from 'shared/ui/Text';
import { Page } from 'types/Page';

import { cn } from './PageRegisterCompany.cn';
import { i18n } from './PageRegisterCompany.i18n';

const RegistrationFeedbackFormModal = withModalView(RegistrationFeedbackForm);

export const PageRegisterCompany: Page = () => {
    const [isModalVisible, setModalVisible] = useToggle(false);

    const handleClick = useCallback(() => {
        setModalVisible(true);
    }, [setModalVisible]);

    const handleClose = useCallback(() => {
        setModalVisible(false);
    }, [setModalVisible]);

    return (
        <RegisterModalLayout
            className={cn()}
            title={i18n('title')}
        >
            <Text
                className={cn('Description')}
                color="secondary"
                typography="body-s"
            >
                {i18n('description')}{' '}
                <Link
                    pseudo
                    view="default"
                    onClick={handleClick}
                >
                    {i18n('contact_us')}
                </Link>
            </Text>
            <RegistrationFeedbackFormModal
                className={cn('Modal')}
                modalViewOnClose={handleClose}
                modalViewVisible={isModalVisible}
                onClose={handleClose}
            />
        </RegisterModalLayout>
    );
};

PageRegisterCompany.displayName = cn();
