import React, { FC, useCallback } from 'react';

import { ButtonPlatform } from 'components/ButtonPlatform';

import { cn } from '../ActionsMenu.cn';

import { OptionProps } from './Option.types';

export const Option: FC<OptionProps> = props => {
    const { hidePopup, option } = props;
    const {
        onClick,
        label,
        className,
        progress,
        disabled,
        view = 'clear',
        customContent,
        icon,
    } = option;

    const handleClick = useCallback(() => {
        hidePopup();
        onClick();
    }, [hidePopup, onClick]);

    if (customContent) {
        const customContentElem = React.cloneElement(customContent, {
            onClick: handleClick,
            className: cn('Option', [className]),
            role: 'option',
        });

        return customContentElem;
    }

    return (
        <ButtonPlatform
            className={cn('Option', [className])}
            disabled={disabled}
            iconLeft={icon}
            progress={progress}
            role="option"
            view={view}
            onClick={handleClick}
        >
            {label}
        </ButtonPlatform>
    );
};
