import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useToggle } from 'react-use';

import { SearchOptions } from 'types/SearchOptions';
import { isFailure, isInitial, isSuccess, mapLoadable } from 'utils/Loadable';

import { createUseQueryHook } from './createUseQueryHook';

export const REFETCH_INTERVAL = 10000;

type UseSearchStatusSuccessResult = {
    isSearchCompleted: boolean;
    isSearchFailed: boolean;
    resetLastSearch: () => void;
};

const statusQueries = ['get_avia_search_status', 'get_hotel_search_status', 'get_rail_search_status'];

export const useSearchStatusSuccess = (options: SearchOptions): UseSearchStatusSuccessResult => {
    const { searchId, type, person_id, trip_id } = options;

    const [isSearchCompleted, setIsSearchCompleted] = useToggle(false);
    const queryClient = useQueryClient();

    const resetLastSearch = useCallback(() => {
        statusQueries.forEach(query => queryClient.removeQueries(query));
        setIsSearchCompleted(false);
    }, [queryClient, setIsSearchCompleted]);

    const refetchInterval = !isSearchCompleted && REFETCH_INTERVAL;

    const responseAvia = createUseQueryHook('get_avia_search_status', {
        enabled: type === 'Avia',
        refetchInterval,
    })(searchId, { person_id, trip_id });
    const responseHotel = createUseQueryHook('get_hotel_search_status', {
        enabled: type === 'Hotel',
        refetchInterval,
    })(searchId, { person_id, trip_id });
    const responseRail = createUseQueryHook('get_rail_search_status', {
        enabled: type === 'Rail',
        refetchInterval,
    })(searchId, { person_id, trip_id });

    const responseAviaStatus = mapLoadable(responseAvia, ({ status }) => status);
    const responseHotelStatus = mapLoadable(responseHotel, ({ status }) => status);
    const responseRailStatus = mapLoadable(responseRail, ({ status }) => status);

    const activeStatus = [responseAviaStatus, responseHotelStatus, responseRailStatus]
        .find(status => !isInitial(status));

    const isStatusFailed = activeStatus && isFailure(activeStatus);
    const isStatusWithError = activeStatus && isSuccess(activeStatus) && activeStatus.result === 'error';
    const isStatusCompleted = activeStatus && isSuccess(activeStatus) && activeStatus.result === 'completed';

    const isSearchFailed = Boolean(isStatusFailed || isStatusWithError);

    if (isStatusCompleted && !isSearchCompleted) {
        setIsSearchCompleted(true);
    }

    return {
        isSearchCompleted: Boolean(isStatusCompleted),
        isSearchFailed,
        resetLastSearch,
    };
};
