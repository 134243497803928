import { META_HOTEL_NAME_EN, META_HOTEL_NAME_RU } from 'hooks/useSearchResults';
import { Hotel, ShortHotelFacilities } from 'services/SwaggerApi';

import { i18n } from './HotelInfo.i18n';

export const getTags = (hotel: Hotel): string[] => {
    const tags: string[] = [];
    const isMetaHotel = [META_HOTEL_NAME_EN, META_HOTEL_NAME_RU].includes(hotel.hotel_name);

    if (isMetaHotel) {
        tags.push(i18n('special_project_ya_travel'));
    }

    if (hotel.is_recommended) {
        tags.push(i18n('colleagues_recommend'));
    }

    return tags;
};

export const getFacilitiesText = (facilities: ShortHotelFacilities | null | undefined): string => {
    const FACILITIES_VISIBLE_COUNT = 5;
    let facilitiesInService: string[] = [];

    if (facilities) {
        const facilitiesArray = Object.entries(facilities) as [keyof ShortHotelFacilities, boolean][];

        facilitiesInService = facilitiesArray
            .filter(([_, hasFacility]) => hasFacility)
            .map(([facility]) => i18n(facility))
            .slice(0, FACILITIES_VISIBLE_COUNT);
    }

    return facilitiesInService.join(' · ');
};
