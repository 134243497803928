export const ru = {
    title: 'Корпоративное такси',
    description: 'Доступ к такси откроется автоматически за день до начала поездки',
    taxi_activated: 'Подключено',
    taxi_not_activated: 'Не подключено',
    valid_until: 'Действует до',
    to_phone: 'На номер',
    contact_support: 'Обратитесь в чат поддержки',
    taxi_is_disabled_for_company: 'Корпоративный способ оплаты не подключен',
    not_suitable_routes: 'Корпоративный способ оплаты доступен только в РФ, Беларуси и Казахстане',
    no_ya_start_ya_end: 'Для данного типа поездки корпоративный способ оплаты такси недоступен',
    no_phone_number: 'Не указан номер телефона в профиле',
    trip_is_not_approved: 'Командировка не согласована',
    trip_status_is_not_suitable: 'Командировка отменена или завершена',
    current_date_not_in_taxi_dates_range: '{translate}',
    date_from: 'Такси будет подключено\nc {taxi_date_from}',
    date_to: 'Такси было подключено\nдо {taxi_date_to}',
    show_more: 'ещё {count}',
};
