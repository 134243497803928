import { HeaderPage } from 'components/HeaderPage';
import { Page } from 'components/Page';
import { ScrollButton } from 'components/ScrollButton';
import { SearchMain } from 'components/SearchMain';
import { useMeta } from 'hooks/useMeta';
import { Container } from 'shared/ui/Container';

import { ActiveTrips } from './ActiveTrips';
import { CreateTrip } from './CreateTrip';
import { cn } from './PageMyActiveTrips.cn';
import { i18n } from './PageMyActiveTrips.i18n';

import './PageMyActiveTrips.css';

export const PageMyActiveTrips: Page = () => {
    const meta = useMeta();
    const { user: { person_id } } = meta;

    return (
        <Page className={cn()} data-testid="my-active-trips">
            <HeaderPage title={i18n('my_trips')} />
            <Container>
                <SearchMain person_id={person_id} />
            </Container>
            <Container className={cn('Trips')}>
                <CreateTrip />
                <ActiveTrips user={meta.user} />
            </Container>
            <ScrollButton />
        </Page>
    );
};

PageMyActiveTrips.displayName = cn();
