import { ErrorName } from './ErrorName';

// Ошибка при декодировании с использованием io-ts
// Можно было бы ValidationError, но нам бек присылает 422 ошибку,
// для которой хочется оставить ValidationError
export class DecodeError extends Error {
    public readonly name = ErrorName.DecodeError;

    public constructor(message?: string) {
        super(message);
    }
}
