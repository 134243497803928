import { FC } from 'react';

import { Bubble } from 'components/Bubble';
import { Tag } from 'shared/ui/Tag';

import { cn } from './PersonTag.cn';
import { PersonTagProps } from './PersonTag.types';

import './PersonTag.css';

export const PersonTag: FC<PersonTagProps> = props => {
    const {
        className,
        login,
        name,
        rightSlot,
        size = 'm',
        personId,
    } = props;

    return (
        <Tag
            className={cn(null, [className])}
            leftSlot={
                <Bubble
                    bubble={{
                        type: 'person',
                        login,
                        name,
                        personId,
                    }}
                    className={cn('Avatar')}
                    size={size}
                    type="person"
                />
            }
            rightSlot={rightSlot}
            size={size}
            text={name}
        />
    );
};

PersonTag.displayName = cn();
