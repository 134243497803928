import { FC, useCallback } from 'react';
import { Platform } from '@yandex-int/hr-components/utils';
import { useSwiper } from 'swiper/react';

import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { ModalLayout } from 'shared/ui/ModalLayout';
import { Text } from 'shared/ui/Text';

import { i18n } from '../ExecuteTripButton.i18n';
import { TripHolidaysScenario } from '../ExecuteTripButton.types';

import { FinalSlideProps } from './FinalSlide.types';

import './FinalSlide.css';

export const FinalSlide: FC<FinalSlideProps> = props => {
    const {
        message,
        title,
        handleExecute,
        onClose,
        scenario,
        platform,
        isExecuting,
        willChill,
    } = props;

    const swiper = useSwiper();

    const shouldShowCancel =
        scenario === TripHolidaysScenario.NO_HOLIDAYS ||
        scenario === TripHolidaysScenario.REASON_ALREADY_SPECIFIED;

    const onCancelClick = useCallback(() => {
        switch (scenario) {
            case TripHolidaysScenario.NO_HOLIDAYS:

            case TripHolidaysScenario.REASON_ALREADY_SPECIFIED:
                onClose();
                break;

            case TripHolidaysScenario.WORKING_ON_HOLIDAYS:
                swiper.slideTo(1);
                break;

            case TripHolidaysScenario.NOT_WORKING_ON_HOLIDAYS:
                swiper.slideTo(0);
                break;
        }
    }, [onClose, scenario, swiper]);

    return (
        <ModalLayout
            actions={[
                <Button
                    key="cancel"
                    disabled={isExecuting}
                    size={platform === Platform.DESKTOP ? 'm' : 'l'}
                    view="default"
                    onClick={onCancelClick}
                >
                    {shouldShowCancel ? i18nAction('cancel') : i18nAction('back')}
                </Button>,
                <Button
                    key="submit"
                    progress={isExecuting}
                    size={platform === Platform.DESKTOP ? 'm' : 'l'}
                    view="brand"
                    onClick={handleExecute}
                >
                    {i18nAction('confirm')}
                </Button>,
            ]}
            platform={platform}
            subtitle={message}
            title={title}
            onClose={onClose}
        >
            {willChill && (
                <Text as="div" typography="body-s">
                    {i18n('working_holidays_2')}
                    <br /> <br />
                    <Text as="i"> {i18n('working_holidays_3')}</Text>
                </Text>
            )}
        </ModalLayout>
    );
};
