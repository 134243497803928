export const en = {
    trip1: 'Services will be issued only after confirmation of the trip',
    trip2: "Don't forget to download the playlist on the go",
    trip3: 'Sapsan cars 10 and 20 have wifi and power sockets',
    trip4: 'You can add bonus cards to the block with documents for use on the trip',
    trip5: 'To buy an Aeroexpress ticket, write to the support chat',
    trip6: "Don't forget to pack your suitcase",
    trip7: 'The hotel may provide a toothbrush, bathrobe and slippers if they are not immediately put in the room',
    trip8: 'To issue a ticket with baggage, select the necessary conditions in the filters',
    trip9: 'The best cost tickets can be issued 14 days before departure',
};
