export const en = {
    page_title: 'Companies',
    companies: 'Companies',
    travel_policies: 'Travel policies',
    groups: 'Groups',
    add_travel_policy: 'Add travel policy',
    travel_policies_not_added: 'Travel policies not added',
    show_more: 'Show more',
    error_title: 'Something went wrong',
    error_description: 'Try reloading the page or contacting support if the error persists.',
    add_group: 'Add group',
    groups_not_added: 'Groups not added',
};
