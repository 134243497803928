import { FC } from 'react';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';

import { Text } from 'shared/ui/Text';

import { cn } from '../CompanionsAdd.cn';
import { i18n } from '../CompanionsAdd.i18n';

import { EmptyCompanionsProps } from './EmptyCompanions.types';

import '../CompanionsAdd.css';

export const EmptyCompanions: FC<EmptyCompanionsProps> = ({ className, cancel, createCompanions }) => {
    return (
        <div className={cn(null, [className])}>
            <Text
                className={cn('Title')}
                color="primary"
                typography="title-s"
            >
                {i18n('title_empty')}
            </Text>
            <Text
                className={cn('Description')}
                color="secondary"
                typography="body-s"
            >
                {i18n('description_empty')}
            </Text>

            <div className={cn('Buttons')}>
                <Button
                    size="m"
                    view="outline"
                    onClick={cancel}
                >
                    {i18n('add_later')}
                </Button>
                <Button

                    size="m"
                    view="primary"
                    onClick={createCompanions}
                >
                    {i18n('companion_add')}
                </Button>
            </div>
        </div>
    );
};
