import { FC, useCallback, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

import { getIconProvider, IconArrowShortLeft } from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { Drawer } from 'shared/ui/Drawer';
import { Modal } from 'shared/ui/Modal';
import { Text } from 'shared/ui/Text';

import { DoubleLayout } from './DoubleLayout';
import { cn } from './FareFamilyModal.cn';
import { i18n } from './FareFamilyModal.i18n';
import { FareFamilyModalProps } from './FareFamilyModal.types';
import { SingleLayout } from './SingleLayout';

import './FareFamilyModal.css';

const iconProviderArrowLeft = getIconProvider(IconArrowShortLeft, { size: 'l' });

export const FareFamilyModal: FC<FareFamilyModalProps> = props => {
    const {
        className,
        left,
        right,
        serviceGroupAdd,
        visible,
        slideIndex,
        onClose,
        setSlideIndex,
        innerRef,
        companionsAdd,
    } = props;

    const { isDesktop } = usePlatform();

    const swiperRef = useRef<SwiperType>();

    const handleBeforeInit = useCallback(swiper => {
        swiperRef.current = swiper;
    }, [swiperRef]);

    const handleSlideChangeTransitionStart = useCallback((swiper: SwiperType) => {
        swiper.updateAutoHeight();
    }, []);

    const onPrevClick = useCallback(() => {
        setSlideIndex(0);
    }, [setSlideIndex]);

    const onModalClose = useCallback(() => {
        if (slideIndex === 0) {
            onClose();
        } else {
            setSlideIndex(0);
        }
    }, [onClose, setSlideIndex, slideIndex]);

    useEffect(() => {
        swiperRef.current?.slideTo(slideIndex);
    }, [slideIndex]);

    if (isDesktop) {
        return (
            <Modal
                autoFocus={false}
                className={cn(null, [className])}
                innerRef={innerRef}
                theme="normal"
                visible={visible}
                onClose={onModalClose}
            >
                <Swiper
                    allowTouchMove={false}
                    className={cn('Swiper')}
                    slidesPerView={1}
                    spaceBetween={16}
                    onBeforeInit={handleBeforeInit}
                    onSlideChangeTransitionStart={handleSlideChangeTransitionStart}
                >
                    <SwiperSlide className={cn('Slide')}>
                        <div className={cn('Content')}>
                            <Button
                                className={cn('Action', { type: 'close' })}
                                icon={iconProviderArrowLeft}
                                size="m"
                                view="clear"
                                onClick={onClose}
                            />
                            <DoubleLayout left={left} right={right} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={cn('Slide')}>
                        <div className={cn('Content')}>
                            <Button
                                className={cn('Action', { type: 'close' })}
                                icon={iconProviderArrowLeft}
                                size="m"
                                view="clear"
                                onClick={onPrevClick}
                            />
                            <SingleLayout>
                                {serviceGroupAdd}
                            </SingleLayout>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className={cn('Slide')}>
                        <div className={cn('Content')}>
                            <Button
                                className={cn('Action', { type: 'close' })}
                                icon={iconProviderArrowLeft}
                                size="m"
                                view="clear"
                                onClick={onPrevClick}
                            />
                            <SingleLayout>
                                {companionsAdd}
                            </SingleLayout>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </Modal>
        );
    }

    return (
        <Drawer
            className={cn(null, [className])}
            innerRef={innerRef}
            view="default"
            visible={visible}
            onClose={onModalClose}
        >
            <Swiper
                allowTouchMove={false}
                className={cn('Swiper')}
                slidesPerView={1}
                spaceBetween={16}
                onBeforeInit={handleBeforeInit}
                onSlideChangeTransitionStart={handleSlideChangeTransitionStart}
            >
                <SwiperSlide className={cn('Slide')}>
                    <div className={cn('Content')}>
                        <DoubleLayout left={left} right={right} />
                    </div>
                </SwiperSlide>
                <SwiperSlide className={cn('Slide')}>
                    <div className={cn('Content')}>
                        <SingleLayout>
                            <div className={cn('Back')} onClick={() => setSlideIndex(0)}>
                                <IconArrowShortLeft className={cn('IconBack')} size="l" />
                                <Text typography="body-m" weight="medium">
                                    {i18n('tariff_choose')}
                                </Text>
                            </div>
                            {serviceGroupAdd}
                        </SingleLayout>
                    </div>
                </SwiperSlide>
                <SwiperSlide className={cn('Slide')}>
                    <div className={cn('Content')}>
                        <Button
                            className={cn('Action', { type: 'close' })}
                            icon={iconProviderArrowLeft}
                            size="l"
                            view="clear"
                            onClick={onPrevClick}
                        />
                        <SingleLayout>
                            {companionsAdd}
                        </SingleLayout>
                    </div>
                </SwiperSlide>
            </Swiper>
        </Drawer>
    );
};

FareFamilyModal.displayName = cn();
