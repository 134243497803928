import { FC } from 'react';

import { RouteTimeline } from 'components/RouteTimeline';
import { ServiceCard } from 'components/ServiceCard';
import { ServiceCardFooter } from 'components/ServiceCardFooter';
import { ServiceHeader } from 'components/ServiceHeader';
import { ServiceState } from 'components/ServiceState';
import { i18nCarriageType } from 'i18n/i18nCarriageType';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { getTrainServiceAction } from 'utils/getTrainServiceAction';

import { cn } from './TrainServiceCard.cn';
import { i18n } from './TrainServiceCard.i18n';
import { TrainServiceCardProps } from './TrainServiceCard.types';

import './TrainServiceCard.css';

/**
 * Карточка жд услуги в заявке
 */
export const TrainServiceCard: FC<TrainServiceCardProps> = props => {
    const {
        service,
        serviceDetails,
        className,
    } = props;

    const {
        carriages,
        train_name,
        train_number,
    } = serviceDetails.train;

    const { place, train, price } = serviceDetails;

    const companyPricePart = Number(price.service_part) + Number(price.additional_products_part ?? 0);
    const clientPricePart = Number(price.ext_persons_part ?? 0);

    const tariffTotal = companyPricePart + clientPricePart;

    const priceFormatted = formatCurrency(tariffTotal);

    const carNumber = carriages[0].carriage_number;
    const carType = carriages[0].carriage_type;

    const { banner, extraActions } = getTrainServiceAction(serviceDetails);

    const isCompanyCost = companyPricePart && !clientPricePart;

    return (
        <ServiceCard
            banner={banner}
            contentClassName={cn(null, [className])}
        >
            <div className={cn('Header')}>
                <ServiceHeader
                    className={cn('ServiceHeader')}
                    serviceDetails={serviceDetails}
                />
                <ServiceState
                    isCancelling={service.in_process_of_cancelling}
                    serviceType={service.type}
                    state={serviceDetails.service_state}
                />
            </div>
            <div className={cn('Main')}>
                <div className={cn('RouteInfo')}>
                    <RouteTimeline className={cn('Timeline')} route={train} />

                    <Text as="div" typography="caption-m">
                        {i18n('train')} {train_number} • {train_name}
                    </Text>
                </div>
                <div className={cn('SeatInfo')}>
                    <div className={cn('Class')}>
                        <Text typography="body-s">
                            {i18n('class')} • {i18nCarriageType(carType)}
                        </Text>
                        <Text color="secondary" typography="caption-m">
                            {isCompanyCost ? i18n('paid_by_the_company') : i18n('paid_by_you')}
                        </Text>
                        <Text typography="body-l">
                            {priceFormatted}
                        </Text>
                    </div>
                    <div className={cn('Places')}>
                        <div>
                            <Text className={cn('PlaceInfo')} typography="body-l">
                                {carNumber}
                            </Text>
                            <Text typography="body-s">
                                {i18n('car')}
                            </Text>
                        </div>
                        {place && (
                            <div>
                                <Text className={cn('PlaceInfo')} typography="body-l">
                                    {place}
                                </Text>
                                <Text typography="body-s">
                                    {i18n('seat')}
                                </Text>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ServiceCardFooter
                extraActions={extraActions}
                service={service}
                serviceDetails={serviceDetails}
            />
        </ServiceCard>
    );
};

TrainServiceCard.displayName = cn();
