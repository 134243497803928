import { useMemo } from 'react';

import { FlightFilterKey } from 'api/models/filters/FlightFilterKey';
import { FlightFilterOptions } from 'api/models/filters/FlightFilterOptions';
import { travelTimeOptions } from 'api/models/search/filter';
import { FilterItem } from 'services/SwaggerApi';

type GetFlightFilterOptions = () => FlightFilterOptions;

const getFlightFilterOptions: GetFlightFilterOptions = () => ({
    air_companies: [],
    cabin_classes: [],
    departure_from_there: [],
    arrival_to_there: [],
    departure_from_back: [],
    arrival_to_back: [],
    departure_there_timespan: [],
    arrival_there_timespan: [],
    departure_back_timespan: [],
    arrival_back_timespan: [],
    maximum_transfers_count: [],
});

type UsePrepareFilters = (filtersRaw: FilterItem[]) => FlightFilterOptions;

export const usePrepareFilters: UsePrepareFilters = filtersRaw => {
    return useMemo(() => {
        return filtersRaw.reduce((acc, filter) => {
            if (!acc[filter.name as FlightFilterKey] || !filter.values) {
                return acc;
            }

            if (
                filter.name === 'departure_there_timespan' ||
                filter.name === 'arrival_there_timespan' ||
                filter.name === 'departure_back_timespan' ||
                filter.name === 'arrival_back_timespan'
            ) {
                return Object.assign(acc, {
                    [filter.name]: travelTimeOptions,
                });
            }

            return Object.assign(acc, { [filter.name]: filter.values });
        }, getFlightFilterOptions());
    }, [filtersRaw]);
};
