import { TrainCarriageDetail } from 'services/SwaggerApi';

export const carriagesSorted = ['seat', 'coupe', 'lux', 'reserved', 'common', 'vip'] as const;

export type CarriageType = typeof carriagesSorted[number];

export const getCarriageTypes = (carriages: TrainCarriageDetail[]) => {
    const carriageTypes = carriages.map(({ carriage_type }) => carriage_type);
    const uniqueCarriageTypes = new Set(carriageTypes);

    return carriagesSorted.filter(carType => uniqueCarriageTypes.has(carType));
};
