import { CompanySuggest } from 'components/CompanySuggest';
import { config } from 'config';
import { UserResponse } from 'services/SwaggerApi';

import { cn } from '../Header.cn';
import { SwitchProvider } from '../SwitchProvider';
import { UserId } from '../UserId';

type GetActionsProps = {
    isMobile: boolean;
    avatarId: UserResponse['avatar_id'];
    isB2B?: boolean;
    isCompanySuggestVisible?: boolean;
};

const { env } = config;
const canSwitchProvider = ['development', 'testing'].includes(env);

const getDefaultDesktopActions = (isCompanySuggestVisible?: boolean) => ([
    isCompanySuggestVisible && <CompanySuggest className={cn('Companies')} />,
    canSwitchProvider && <SwitchProvider key="provider" />,
].filter(Boolean));

export const getActions = ({ isMobile, isB2B, avatarId, isCompanySuggestVisible }: GetActionsProps) => {
    if (isB2B) {
        return [
            ...getDefaultDesktopActions(isCompanySuggestVisible),
            <UserId key={avatarId} />,
        ];
    }

    if (isMobile) {
        return [];
    }

    return getDefaultDesktopActions(isCompanySuggestVisible);
};
