import { FC, useCallback } from 'react';

import { PersonTag } from 'components/PersonTag';
import { withModalView } from 'hocs/withModalView';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { useEmployeeGrantCoordinator } from './EmployeeGrantCoordinatorModal.hooks/useEmployeeGrantCoordinator';
import { cn } from './EmployeeGrantCoordinatorModal.cn';
import { i18n } from './EmployeeGrantCoordinatorModal.i18n';
import { EmployeeGrantCoordinatorModalProps, HandleSubmit } from './EmployeeGrantCoordinatorModal.types';

import './EmployeeGrantCoordinatorModal.css';

const EmployeeGrantCoordinatorModalPresenter: FC<EmployeeGrantCoordinatorModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        employee,
        className,
    } = props;

    const {
        company_id,
        employee_id,
        first_name,
        last_name,
    } = employee;

    const [grantCoordinator, grantingState] = useEmployeeGrantCoordinator({
        onSuccess: onSubmit,
        options: { invalidateOnSuccess: true },
    });

    const isGranting = isLoading(grantingState);

    const handleSubmit = useCallback<HandleSubmit>(() => {
        grantCoordinator(company_id, employee_id);
    }, [grantCoordinator, company_id, employee_id]);

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <div className={cn('Info')}>
                <Text
                    className={cn('ActionFor')}
                    typography="body-s"
                >
                    {i18n('grant_to')}
                </Text>
                <PersonTag
                    login="-"
                    name={`${first_name} ${last_name}`}
                    personId={employee_id}
                    size="m"
                />
            </div>

            <div className={cn('Controls')}>
                <Button
                    className={cn('Cancel')}
                    size="m"
                    view="ghost"
                    onClick={onCancel}
                >
                    {i18nAction('cancel')}
                </Button>
                <Button
                    className={cn('Submit')}
                    progress={isGranting}
                    size="m"
                    view="primary"
                    onClick={handleSubmit}
                >
                    {i18nAction('save')}
                </Button>
            </div>

        </div>
    );
};

EmployeeGrantCoordinatorModalPresenter.displayName = cn();

export const EmployeeGrantCoordinatorModal = withModalView(EmployeeGrantCoordinatorModalPresenter);
