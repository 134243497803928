import { FC } from 'react';

import { TabbedFormsLayout } from 'components/TabbedFormsLayout';
import { withRemote } from 'hocs/withRemote';
import { useTravelPolicyDetails } from 'hooks/useTravelPolicyDetails';

import { cn } from '../PageProfile.cn';

import { getAviaRules, getHotelRules, getRailRules } from './TabTravelPolicy.utils/getRules';
import { GeneralRules } from './GeneralRules';
import { Rules } from './Rules';
import { i18n } from './TabTravelPolicy.i18n';
import { TabTravelPolicyProps, TravelPolicyDetailsProps } from './TabTravelPolicy.types';

import './TabTravelPolicy.css';

const TravelPolicyDetails: FC<TravelPolicyDetailsProps> = props => {
    const { details } = props;

    const aviaRules = getAviaRules(details.avia);
    const railRules = getRailRules(details.rail);
    const hotelRules = getHotelRules(details.hotel);

    const tabbedItems = [
        {
            id: 'general',
            title: i18n('general_rules'),
            content: <GeneralRules details={details} />,
        },
        {
            id: 'avia',
            title: i18n('avia_rules'),
            content: <Rules rules={aviaRules} title={i18n('avia_rules')} />,
        },
        {
            id: 'rail',
            title: i18n('rail_rules'),
            content: <Rules rules={railRules} title={i18n('rail_rules')} />,
        },
        {
            id: 'hotel',
            title: i18n('hotel_rules'),
            content: <Rules rules={hotelRules} title={i18n('hotel_rules')} />,
        },
    ];

    return (
        <div className={cn('TabTravelPolicy')}>
            <TabbedFormsLayout items={tabbedItems} />
        </div>
    );
};

const TravelPolicyDetailsRemote = withRemote(TravelPolicyDetails);

export const TabTravelPolicy: FC<TabTravelPolicyProps> = props => {
    const { personDetails } = props;

    const travelPolicyId = personDetails.travel_policy_id;

    const details = useTravelPolicyDetails(travelPolicyId as number);

    return <TravelPolicyDetailsRemote details={details} fallbackElement={<></>} />;
};
