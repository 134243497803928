import { FC, useCallback, useMemo } from 'react';

import { ActionsMenu, MenuOption } from 'components/ActionsMenu';
import { useHandleChange } from 'components/FormField2/utils/useHandleChange';
import { getIconProvider, IconArrowShortDown, IconPlus } from 'components/Icon';
import { PersonListItem } from 'components/PersonListItem';
import { getDocumentTitle } from 'components/ServiceCardFooter/utils/getDocumentTitle';
import { usePlatform } from 'hooks/usePlatform';
import { ExtPerson, Person, TravellerDocument } from 'services/SwaggerApi';
import { getFullName } from 'shared/lib/person/getFullName';
import { ListItem } from 'shared/ui/ListItem';
import { NumberCounterInputProps } from 'shared/ui/NumberCounterInput';
import { isExtPerson } from 'types/guards/isExtPerson';

import { withFormField } from '../../FormField2';
import { cn as cnFormField } from '../../FormField2.cn';
import { i18n } from '../../FormField2.i18n';
import { FormFieldProps } from '../../FormField2.types';

import { getDocumentItemTextParams } from './utils/getDocumentItemTextParams';
import { cn } from './FieldTravellerDocument.cn';

import './FieldTravellerDocument.css';

type FieldTravellerDocumentProps = {
    person: Person | ExtPerson;
    allowedDocuments: TravellerDocument[];
    dataTestId?: NumberCounterInputProps['dataTestId'];
    onAddDocumentClick?: VoidFunction;
    disabled?: boolean;
} & FormFieldProps;

const Control: FC<FieldTravellerDocumentProps> = props => {
    const {
        name,
        deps,
        controller,
        context,
        allowedDocuments,
        onAddDocumentClick,
        disabled,
        person,
    } = props;

    const {
        field: { value },
    } = controller;

    const handleChange = useHandleChange({ controller, context, deps });

    const handleClick = useCallback((documentId: number) => {
        return () => {
            handleChange(documentId);
        };
    }, [handleChange]);

    const menu: MenuOption[] = allowedDocuments.map(document => {
        const { caption, label } = getDocumentItemTextParams(document);

        return ({
            className: cn('DocumentOption'),
            customContent: (
                <ListItem
                    leadingTextParams={{
                        label,
                        caption,
                    }}
                />
            ),
            onClick: handleClick(document.document_id),
        });
    });

    const additionalMenu: MenuOption[] = useMemo(() =>
        onAddDocumentClick ? [
            {
                label: i18n('add_document'),
                onClick: onAddDocumentClick,
                icon: getIconProvider(IconPlus),
            },
        ] : []
    , [onAddDocumentClick]);

    const chooseDocument = useMemo(() =>
        allowedDocuments.find(document => document.document_id === value)
    , [allowedDocuments, value]);

    const chooseDocumentTitle = getDocumentTitle(chooseDocument);

    const { isDesktop } = usePlatform();

    return (
        <div className={cnFormField('Control', { name }, [cn()])}>
            <div className={cn('Container')}>
                <div className={cn('TravellerInfo')}>
                    <PersonListItem
                        transparent
                        external={isExtPerson(person)}
                        login={isExtPerson(person) ? '' : person.login}
                        name={getFullName(person)}
                    />
                </div>
                <ActionsMenu
                    additionalMenu={additionalMenu}
                    className={cn('TravellerDocumentChoose')}
                    direction={'bottom-start'}
                    disabled={disabled}
                    menu={menu}
                    scope={isDesktop ? 'inplace' : undefined}
                    trigger={(
                        <ListItem
                            leadingTextParams={{
                                label: chooseDocumentTitle || i18n('choose_document'),
                            }}
                            trailing={disabled ? null : <IconArrowShortDown />}
                        />
                    )}
                />
            </div>
        </div>
    );
};

export const FieldTravellerDocument = withFormField(Control);
