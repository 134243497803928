import * as t from 'io-ts';

import { nullable } from 'utils/io-ts';

const PlaceType = t.type({
    name: t.string,
    type: t.string,
}, 'PlaceType');

export const HotelService = t.intersection([
    t.type({
        id: t.string,
        hotel_name: t.string,
        description: t.string,
        currency: t.string,
        min_price_per_night: t.number,
        address: t.string,
        location: (t.partial({
            city: nullable(PlaceType),
            country: nullable(PlaceType),
        })),
    }),
    t.partial({
        geo_position: nullable(t.type({
            latitude: t.number,
            longitude: t.number,
        })),
        is_recommended: nullable(t.boolean),
        stars: nullable(t.number),
        image_url: nullable(t.string),
    }),
], 'HotelService');

export type HotelService = t.TypeOf<typeof HotelService>;
