import { useCallback, useMemo } from 'react';
import { Column } from 'react-table';

import { useCompaniesUpdate } from 'api/hooks/useCompaniesUpdate';
import { CellHeader } from 'components/TableCells/CellHeader';
import { CellSuggest } from 'components/TableCells/CellSuggest/CellSuggest';
import { CellText } from 'components/TableCells/CellText';
import { CompanyLite } from 'services/SwaggerApi';
import { addToast } from 'utils/addToast';
import { errorToast } from 'utils/errorToast';

import { i18n } from '../TableCompanies.i18n';

export const useColumns = (): Column<CompanyLite>[] => {
    return useMemo(() => [
        {
            id: 'name',
            accessor: ({ name }) => name,
            Header: props => <CellHeader {...props} value={i18n('company_name_header')} />,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Cell: (props: any) => {
                const {
                    value,
                } = props;

                return <CellText
                    {...props}
                    value={value}
                />;
            },
            width: 200,
            sticky: 'left',
        },
        {
            id: 'travelPolicySuggest',
            accessor: ({ travel_policy, company_id }) => ({ travel_policy, company_id }),
            Header: props => <CellHeader {...props} value={i18n('tp_header')} />,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            Cell: (props: any) => {
                const {
                    value: {
                        travel_policy,
                        company_id,
                    },
                } = props;

                const [updateCompany] = useCompaniesUpdate({
                    onSuccess: () => {
                        addToast({ title: i18n('tp_add_success') });
                    },
                    onError: e => {
                        errorToast(e, { title: i18n('tp_add_error') });
                    },
                });

                const onChange = useCallback(data => {
                    if (travel_policy?.travel_policy_id !== data.suggest.id) {
                        updateCompany(company_id, { travel_policy_id: data.suggest.id });
                    }
                }, [company_id, travel_policy?.travel_policy_id, updateCompany]);

                const onClear = useCallback(() => {
                    updateCompany(company_id, { travel_policy_id: null });
                }, [company_id, updateCompany]);

                return <CellSuggest
                    {...props}
                    suggestProps={{
                        placeholder: i18n('choose_tp_placeholder'),
                        onClearClick: onClear,
                    }}
                    type="travelPolicies"
                    value={travel_policy?.travel_policy_id ?? undefined}
                    onChange={onChange}
                />;
            },
            width: 200,
        },
    ], []);
};
