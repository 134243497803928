import { FC, useCallback, useContext, useMemo } from 'react';

import { CurrentCompanyContext } from 'components/CurrentCompanyProvider/CurrentCompanyProvider.context';
import { useAvailableCompanies } from 'hooks/useAvailableCompanies';
import { getValueOrNull } from 'utils/Loadable';

import { CompanySuggest } from './CompanySuggest';
import { cn } from './CompanySuggest.cn';
import { CompanySuggestContainerProps, HandleSelectChange } from './CompanySuggest.types';

import './CompanySuggest.css';

export const CompanySuggestContainer: FC<CompanySuggestContainerProps> = props => {
    const {
        className,
    } = props;

    const availableCompanies = useAvailableCompanies();

    const { currentCompanyId, setCurrentCompanyId } = useContext(CurrentCompanyContext);

    const companies = getValueOrNull(availableCompanies);

    const defaultValues = useMemo(() => {
        if (!companies || companies.length === 0) {
            return;
        }

        const currentCompany = companies.find(
            company => company.company_id === currentCompanyId,
        );

        if (!currentCompany) {
            return;
        }

        return {
            currentCompany: {
                id: currentCompany.company_id,
                title: currentCompany.name,
            },
        };
    }, [companies, currentCompanyId]);

    const handleSelectChange = useCallback<HandleSelectChange>(id => {
        setCurrentCompanyId(id);
    }, [setCurrentCompanyId]);

    if (!companies) {
        return null;
    }

    return (
        <CompanySuggest
            className={className}
            defaultValues={defaultValues}
            onChange={handleSelectChange}
        />
    );
};

CompanySuggestContainer.displayName = `${cn()}Container`;
