import { FC } from 'react';
import { useToggle } from 'react-use';

import { Button } from 'shared/ui/Button';
import { ModalPlatform } from 'shared/ui/ModalPlatform';
import { Text } from 'shared/ui/Text';

import { CompanionDocuments } from '../CompanionDocuments';
import { EmployeeDocuments } from '../EmployeeDocuments';
import { cn } from '../ServiceCardFooter.cn';
import { i18n } from '../ServiceCardFooter.i18n';

import { ExtendedDocumentsActionsProps } from './ExtendedDocumentsActions.types';

import './ExtendedDocumentsActions.css';

export const ExtendedDocumentsActions: FC<ExtendedDocumentsActionsProps> = props => {
    const {
        service,
        allowedDocuments,
        className,
    } = props;

    const [isModalVisible, toggleModalVisible] = useToggle(false);

    const { change_document: canChangeDocument } = service.actions;

    const extPersonServices = service.ext_person_services?.sort((extServiceA, extServiceB) =>
        extServiceA.service_id - extServiceB.service_id,
    ).map(extService => (
        <CompanionDocuments
            key={service.service_id}
            allowedDocuments={allowedDocuments.ext_persons_documents}
            extService={extService}
            service={service}
        />
    )) || null;

    const isEmployeeWithoutDocument = !service.document_id;
    const extPersonWithoutDocuments = service.ext_person_services?.filter(
        extService => !extService.document_id,
    ).length || 0;
    const personsWithoutDocuments = extPersonWithoutDocuments + (isEmployeeWithoutDocument ? 1 : 0);

    const canChangeDocumentsButtonText =
        personsWithoutDocuments === 0 ?
            i18n('change_documents') :
            i18n('choose_documents');

    return (
        <div
            className={cn('ExtendedDocumentsActions', [className])}
            data-testid="extended-documents-actions"
        >
            <Button
                size="m"
                view="outline"
                onClick={toggleModalVisible}
            >
                {
                    canChangeDocument ?
                        canChangeDocumentsButtonText :
                        i18n('view_documents')
                }
            </Button>
            {
                <Text
                    color="secondary"
                    typography="body-s"
                >
                    {
                        canChangeDocument ?
                            i18n('passengers_without_docs', { count: personsWithoutDocuments }) :
                            i18n('can_not_change_documents')
                    }

                </Text>
            }
            <ModalPlatform
                title={i18n('documents_title')}
                toggleVisible={toggleModalVisible}
                visible={isModalVisible}
            >
                <div className={cn('ExtendedDocumentsActionsContent')}>
                    <EmployeeDocuments
                        allowedDocuments={allowedDocuments.person_documents}
                        service={service}
                    />
                    {extPersonServices}
                </div>
            </ModalPlatform>
        </div>
    );
};
