export const en = {
    add_eilo: 'Add early check-in or late check-out',
    toggle_hide: 'Hide',
    toggle_show: 'Show more {count}',
    button_get_number: 'Choose room',
    room_booked: 'Room booked',
    meal_included: 'Meal included',
    meal_not_included: 'Meal is not included',
    nights: {
        one: 'for {count} night',
        some: 'for {count} nights',
        many: 'for {count} nights',
        none: '',
    },
    best_price: 'Best price',
    best_price_with_meal: 'Best price with meal',
    cancellation_no_info: 'No refund information',
    cancellation_without_penalty: 'Free cancellation',
    cancellation_with_penalty: 'Cancellation with a fine',
    cancellation_no: 'Non-refundable rate',
    to: 'before {date}',
    from_to: 'from {date_from} to ${date_to}',
    penalty: 'Cancellation penalty — {penalty}',
    room_on_request: 'Room upon request',
    room_on_request_hint: 'Accommodation is not guaranteed. It may take up to 24 hours to receive the voucher',
};
