import { HotelFeatureItem } from 'services/SwaggerApi';

const MAX_POPULAR_FEATURES = 6;

const popularFeaturesIds = [
    'wi_fi',
    'gym',
    'pool',
    'laundry',
    'safe',
    'room',
    'has_restaurant',
    'cafe',
    'has_bar',
];
const popularFeaturesIdsSet = new Set(popularFeaturesIds);

export const getPopularFeatures = (
    features: { name: string; features: HotelFeatureItem[]}[],
): string[] => {
    const availableFeaturesSet = new Set();
    const featureNames = {};

    for (const group of features) {
        for (const feature of group.features) {
            if (popularFeaturesIdsSet.has(feature.feature_id)) {
                availableFeaturesSet.add(feature.feature_id);
                featureNames[feature.feature_id] = feature.name;
            }
        }
    }

    return popularFeaturesIds
        .slice(0, MAX_POPULAR_FEATURES)
        .filter(id => availableFeaturesSet.has(id));
};
