type GetAbsoluteAttachmentPath = (
    url: string,
    name: string
) => string;

export const getAbsoluteAttachmentPath: GetAbsoluteAttachmentPath = (url, name) => {
    const isDifferentHost = url.startsWith('https://');
    const baseUrl = new URL(url, window.location.origin).href;

    if (isDifferentHost) {
        return encodeURI([baseUrl, name].join('/'));
    }

    return baseUrl;
};
