import { config } from 'config';

import '@yandex-int/error-counter/dist/interfaceOverRum.js';
import '@yandex-int/error-counter/dist/implementation.js';
import '@yandex-int/error-counter/dist/filters.js';
import '@yandex-int/error-counter/dist/logError';

window.Ya && window.Ya.Rum.initErrors({
    // @ts-ignore: не знаю как доопределить неймспейс Ya.Rum функцией getSetting
    reqid: window.Ya.Rum.getSetting('reqid'),
    project: 'trip',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    env: config.env as any,
    version: config.version,
    host: window.location.hostname,
    additional: {
        bundle: config.isB2B ? 'b2b' : 'yandex-team',
    },
});
