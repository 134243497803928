import * as D from 'io-ts/Decoder';

import { config } from 'config';
import { ActivePage } from 'shared/constants/ActivePage';
import { OneToBoolean } from 'utils/io-ts/decoders';

import { checkAccessIsB2B } from './checkAccessIsB2B';
import { route } from './route';

const { isB2B } = config;

export const RouteRegisterSearch = D.type({
    params: D.type({}),
    search: D.intersect(
        D.type({}),
    )(
        D.partial({
            changeEmail: OneToBoolean,
        }),
    ),
});

export type RouteRegister = D.TypeOf<typeof RouteRegisterSearch>;

export const RouteRegister = route(
    '/register',
    RouteRegisterSearch,
    {
        shouldWrap: !isB2B,
        activePage: ActivePage.REGISTER_PAGE,
        checkAccess: checkAccessIsB2B,
    },
);
