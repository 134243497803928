import * as t from 'io-ts';

const Carriage = t.type({
    min_price: t.string,
    carriage_type: t.string,
    carriage_owner: t.string,
    is_travel_policy_compliant: t.boolean,
    seat_count: t.number,
    travel_policy_violations: t.array(t.string),
}, 'Carriage');

export const TrainService = t.type({
    id: t.string,
    train_name: t.string,
    train_number: t.string,
    train_category: t.string,
    departure: t.type({
        train_station: t.string,
        country: t.string,
        city: t.string,
    }),
    departure_at: t.string,
    departure_at_timezone_offset: t.string,
    arrival: t.type({
        train_station: t.string,
        country: t.string,
        city: t.string,
    }),
    arrival_at: t.string,
    arrival_at_timezone_offset: t.string,
    ride_duration: t.number,
    carriages: t.array(Carriage),
}, 'TrainService');

export type TrainService = t.TypeOf<typeof TrainService>;
