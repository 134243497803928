import dayjs from 'dayjs';

import { IconTravelConnection, IconTravelHotel } from 'components/Icon';

import { i18n } from '../TripRoute.yt.i18n';

import { getEvents } from './getEvents';
import { segmentEvent } from './segmentEvent';
import { HotelEventCreator } from './types';

export const hotelCheckIn: HotelEventCreator = order => {
    const date = dayjs(order.start_at, 'YYYY-MM-DDTHH:mm:ss');
    const name = order.hotel.hotel_name;
    const address = order.address_name;

    return segmentEvent({
        city: order.location_name,
        date,
        text: i18n('check_in', { name, address }),
        icon: IconTravelHotel,
    });
};

export const hotelCheckOut: HotelEventCreator = order => {
    const date = dayjs(order.end_at, 'YYYY-MM-DDTHH:mm:ss');

    return segmentEvent({
        city: order.location_name,
        date,
        text: i18n('check_out'),
        icon: IconTravelConnection,
    });
};

export const getHotelEvents = getEvents(
    hotelCheckIn,
    hotelCheckOut,
);
