import { FC } from 'react';

import { FieldTextinput } from 'components/FormField2';

import { i18n } from '../FormPersonDetails.i18n';

type PersonBaseFieldsProps = {
    allEditable?: boolean;
};

export const PersonContactFields: FC<PersonBaseFieldsProps> = props => {
    const { allEditable } = props;

    return (
        <>
            <FieldTextinput
                inputMode="tel"
                label={i18n('phone_number')}
                name="phone_number"
            />
            <FieldTextinput
                disabled={!allEditable}
                inputMode="email"
                label={i18n('email')}
                name="email"
            />
        </>
    );
};
