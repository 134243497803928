import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_body-l.css';

export type WithTypographyBodyLProps = {
    typography?: 'body-l';
};

export const withTypographyBodyL = withBemMod<WithTypographyBodyLProps>(
    cn(),
    { typography: 'body-l' },
);
