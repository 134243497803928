import { FC, useCallback } from 'react';
import { DialogLayout } from '@yandex-int/hr-components/DialogLayout/DialogLayout';
import { useSwiper } from 'swiper/react';

import { cn } from '../AddCompanionDocument.cn';
import { ModalSlides } from '../AddCompanionDocument.types';

import { i18n } from './HintSlide.i18n';
import { HintSlideProps } from './HintSlide.types';

import './HintSlide.css';

export const HintSlide: FC<HintSlideProps> = props => {
    const {
        onClose,
    } = props;

    const swiper = useSwiper();

    const continueHandle = useCallback(() => {
        swiper.slideTo(ModalSlides.FORM_SLIDE);
    }, [swiper]);

    // Используется DialogLayout, а не Dialog,
    // потому что Dialog это модальное окно, оно не в потоке,
    // поэтому слайдер воспринимает его 0 размера.
    return (
        <div className={cn('HintSlide')}>
            <DialogLayout
                close={i18n('add_later')}
                closeButtonView="ghost"
                confirm={i18n('add_now')}
                subtitle={i18n('description')}
                title={i18n('title')}
                onClose={onClose}
                onConfirm={continueHandle}
            />
        </div>
    );
};
