import { FetchError } from 'errors';
import { createUseQueryHook } from 'hooks/createUseQueryHook';
import { ExtPerson } from 'services/SwaggerApi';
import { RemoteData } from 'utils/Loadable';

type UseExtPersonGetOptions = {
   isEnabled?: boolean;
};

type UseExtPersonGet = (extPersonId: string, options?: UseExtPersonGetOptions) =>
   RemoteData<ExtPerson, FetchError>;

export const useExtPersonGet: UseExtPersonGet = (extPersonId, options = {}) => {
    const { isEnabled } = options;

    return createUseQueryHook(
        'ext_person_get',
        { enabled: isEnabled },
    )(extPersonId);
};
