export const en = {
    cancel: 'Cancel',
    date_of_birth: 'Date of birth',
    email: 'E-mail',
    first_name: 'First name',
    first_name_ac_en: 'First name (in Latin)',
    gender: 'Gender',
    gender_placeholder: 'Select gender',
    last_name: 'Last name',
    last_name_ac_en: 'Last name (in Latin)',
    middle_name: 'Middle name',
    middle_name_ac_en: 'Middle name (in Latin)',
    phone_number: 'Phone number',
    profile: 'Profile',
    save: 'Save',
};
