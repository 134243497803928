import { FC, useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { IClassNameProps } from '@bem-react/core';

import { Text } from 'shared/ui/Text';

import { cn } from '../FormField2.cn';
import { ServerErrorsContext } from '../FormProvider/FormProvider';

export type ErrorMessageProps = IClassNameProps & {
    fieldName: string;
    errorTestId?: string;
};

export const ErrorMessage: FC<ErrorMessageProps> = props => {
    const {
        className,
        fieldName,
        errorTestId,
    } = props;

    const {
        setError,
        formState: { errors },
    } = useFormContext();

    const serverErrorMessage = useContext(ServerErrorsContext)[fieldName];

    useEffect(() => {
        if (serverErrorMessage) {
            setError(fieldName, {
                type: 'server',
                message: serverErrorMessage,
            });
        }
    }, [serverErrorMessage, fieldName, setError]);

    const validationErrorMessage = errors[fieldName]?.message;

    if (!validationErrorMessage) {
        return null;
    }

    return (
        <Text
            as="div"
            color="alert"
            typography="caption-m"
        >
            <div
                className={cn('Error', [className])}
                data-testid={errorTestId}
                role="alert"
            >
                {validationErrorMessage}
            </div>
        </Text>
    );
};
