export const ru = {
    account_balance_title_prepayment: 'Остаток средств',
    account_balance_title_postpayment: 'Задолженность',
    top_up: 'Пополнить',
    last_top_up: 'Последнее пополнение: {date}',
    convenient_service: 'Без учёта услуги «Удобный сервис»',
    convenient_service_description: 'Полную стоимость услуг за период можно узнать в закрывающих документах за период',
    notification_warning_description: 'Остаток средств менее 30%. Рекомендуется пополнить баланс',
    notification_danger_description: 'В данный момент оформление услуг невозможно. Пожалуйста, пополните баланс, чтобы использовать все возможности сервиса',
    no_data: 'нет данных',
};
