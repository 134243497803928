import { FC, useCallback } from 'react';
import { FieldArray } from 'react-final-form-arrays';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { IconPlus } from 'components/Icon';
import { Text } from 'shared/ui/Text';

import { cn } from '../FormTripCreate.cn';
import { i18n } from '../FormTripCreate.i18n';
import { getEmptyRoutePoint } from '../FormTripCreate-RouteFields/RouteFields';

import { ComplexRouteProps } from './ComplexRoute.types';
import { ComplexRoutePoint } from './ComplexRoutePoint';

export const ComplexRoute: FC<ComplexRouteProps> = props => {
    const { fields } = props;

    const removeLastRoutePoint = useCallback(() => {
        const lastRouteIndex = fields.value.length - 1;
        const firstRouteOrigin = fields.value[0]?.cityFrom;
        const prevRouteDestination = fields.value.length > 3 ? firstRouteOrigin : undefined;

        fields.update(lastRouteIndex - 1, { ...fields.value[lastRouteIndex - 1], cityTo: prevRouteDestination });
        fields.remove(lastRouteIndex);
    }, [fields]);

    const addRoutePoint = useCallback(() => {
        //в FFA не работает insert, поэтому здесь самодельный https://github.com/final-form/final-form-arrays/issues/44
        const lastRoute = fields.pop();

        fields.push({ ...getEmptyRoutePoint(), cityFrom: lastRoute.cityFrom });
        fields.push({ ...lastRoute, cityFrom: undefined });
    }, [fields]);

    return (
        <div className={cn('ComplexRoute')}>
            <FieldArray name="route">
                {({ fields }) => fields.map((name, index) =>
                    <ComplexRoutePoint
                        key={fields.value[index].id}
                        fields={fields}
                        index={index}
                        name={name}
                    />,
                )}
            </FieldArray>
            <div className={cn('RouteActions')}>
                <Button view="clear" onClick={addRoutePoint}>
                    <Text color="secondary" typography="body-s" weight="medium">
                        <IconPlus size="m" /> {i18n('add_route_point')}
                    </Text>
                </Button>
                <Button view="clear" onClick={removeLastRoutePoint}>
                    <Text color="secondary" typography="body-s" weight="medium">
                        {i18n('remove_route_point')}
                    </Text>
                </Button>
            </div>
        </div>
    );
};
