import { FC } from 'react';
import { Divider } from '@yandex-lego/components/Divider';

import { AviaLocation, HotelLocation, RailLocation } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';
import { DateFormat, formatDate } from 'utils/formatDate';
import { formatDuration } from 'utils/formatDuration';

import { cn } from './RouteTimeline.cn';
import { RouteTimelineProps } from './RouteTimeline.types';

import './RouteTimeline.css';

type GetLocation = (location?: AviaLocation | RailLocation | HotelLocation | null) => string;

const getLocation: GetLocation = location => {
    if (!location) {
        return '';
    }

    if ('airport' in location && location.airport) {
        return location.airport?.name;
    }

    if ('train_station' in location && location.train_station) {
        return location.train_station.name;
    }

    if ('city' in location && location.city) {
        return location.city?.name;
    }

    return '';
};

export const RouteTimeline: FC<RouteTimelineProps> = props => {
    const {
        departure_at,
        arrival_at,
        departure_at_utc,
        arrival_at_utc,
        arrival,
        departure,
    } = props.route;

    const dateA = new Date(departure_at_utc || 0);
    const dateB = new Date(arrival_at_utc || 0);

    const totalMinutes = (dateB.valueOf() - dateA.valueOf()) / 1000 / 60;
    const minutes = totalMinutes % 60;
    const hours = (totalMinutes - minutes) / 60;

    const duration = `${hours}:${minutes}:00`;

    return (
        <Text as="div" className={cn(null, [props.className])} typography="body-s">
            <div className={cn('Times')}>
                <Text typography="title-m" weight="regular">
                    {formatDate(departure_at, DateFormat.HH_mm)}
                </Text>
                <div className={cn('Duration')}>
                    <Divider />
                    <Text color="secondary" typography="caption-m">
                        {formatDuration(duration)}
                    </Text>
                    <Divider />
                </div>
                <Text typography="title-m" weight="regular">
                    {formatDate(arrival_at, DateFormat.HH_mm)}
                </Text>
            </div>
            <div className={cn('Stations')}>
                <Text color="secondary" typography="caption-m">
                    {getLocation(departure)}
                </Text>
                <Text align="end" color="secondary" typography="caption-m">
                    {getLocation(arrival)}
                </Text>
            </div>
            <div className={cn('Dates')}>
                <Text color="secondary" typography="caption-m">
                    {formatDate(departure_at, DateFormat.D_MMMM_dd)}
                </Text>
                <Text align="end" color="secondary" typography="caption-m">
                    {formatDate(arrival_at, DateFormat.D_MMMM_dd)}
                </Text>
            </div>
        </Text>
    );
};

RouteTimeline.displayName = cn();
