import { createContext } from 'react';

import { SwaggerApi } from 'services/SwaggerApi';

export type TSwaggerContext = {
    api: SwaggerApi;
};

export const defaultSwaggerContext: TSwaggerContext = { api: new SwaggerApi() };

export const SwaggerContext = createContext<TSwaggerContext>(defaultSwaggerContext);
