import * as yup from 'yup';

import { MaxPriceRule, ServiceClassesEnum, StarsAllowedEnum, TrainCarCategoriesEnum } from 'services/SwaggerApi';

export const schema = yup.object({
    name: yup.string().trim().max(255).required(),
    avia_max_price: yup.string().when('is_avia_max_price', {
        is: true,
        then: schema => schema.required(),
    }),
    avia_cheapest_more_percent: yup.string().when('is_avia_cheapest_more_percent', {
        is: true,
        then: schema => schema.required(),
    }),
    avia_book_before_days: yup.string().when('is_avia_book_before_days', {
        is: true,
        then: schema => schema.required(),
    }),
    avia_max_transfer_duration_hours: yup.string().when('is_avia_max_transfer_duration_hours', {
        is: true,
        then: schema => schema.required(),
    }),
    is_avia_max_price: yup.boolean(),
    is_avia_cheapest_more_percent: yup.boolean(),
    is_avia_book_before_days: yup.boolean(),
    is_avia_max_transfer_duration_hours: yup.boolean(),
    avia_refundable_only: yup.boolean(),
    avia_exchangeable_only: yup.boolean(),
    avia_with_baggage_only: yup.boolean(),
    avia_business_days_only: yup.boolean(),
    avia_service_classes: yup.mixed<ServiceClassesEnum[]>(),
    avia_max_price_directions: yup.mixed<MaxPriceRule[]>().required(),

    rail_max_price: yup.string().when('is_rail_max_price', {
        is: true,
        then: schema => schema.required(),
    }),
    rail_cheapest_more_percent: yup.string().when('is_rail_cheapest_more_percent', {
        is: true,
        then: schema => schema.required(),
    }),
    rail_book_before_days: yup.string().when('is_rail_book_before_days', {
        is: true,
        then: schema => schema.required(),
    }),
    is_rail_max_price: yup.boolean(),
    is_rail_cheapest_more_percent: yup.boolean(),
    is_rail_book_before_days: yup.boolean(),
    rail_business_days_only: yup.boolean(),
    rail_categories: yup.mixed<TrainCarCategoriesEnum[]>(),
    rail_max_price_directions: yup.mixed<MaxPriceRule[]>().required(),

    hotel_max_price_per_night: yup.string().when('is_hotel_max_price_per_night', {
        is: true,
        then: schema => schema.required(),
    }),
    hotel_cheapest_more_percent: yup.string().when('is_hotel_cheapest_more_percent', {
        is: true,
        then: schema => schema.required(),
    }),
    hotel_book_before_days: yup.string().when('is_hotel_book_before_days', {
        is: true,
        then: schema => schema.required(),
    }),
    is_hotel_max_price_per_night: yup.boolean(),
    is_hotel_cheapest_more_percent: yup.boolean(),
    is_hotel_book_before_days: yup.boolean(),
    hotel_business_days_only: yup.boolean(),
    hotel_stars: yup.mixed<StarsAllowedEnum[]>(),
    hotel_max_price_directions: yup.mixed<MaxPriceRule[]>().required(),
});

export type TravelPolicySchema = yup.InferType<typeof schema>;
