import { Link } from '@yandex-int/hr-components/Link/desktop/bundle';
import { Platform } from '@yandex-int/hr-components/utils';

import { IconProfile } from 'components/Icon';
import { ThemeToggler } from 'components/ThemeToggler';
import { getStaffUrl } from 'utils/urls';

import { cn } from '../Header.cn';
import { i18n } from '../Header.i18n';

export const getPopupActions = (login: string, platform: Platform) => [
    {
        value: 'profile',
        content: (
            <Link
                className={cn('MenuItem')}
                href={getStaffUrl(login)}
                target="_blank"
            >
                <IconProfile className={cn('MenuIcon')} size="m" />
                <span>{i18n('me_on_staff')}</span>
            </Link>
        ),
    },
    platform === Platform.MOBILE && {
        value: 'theme',
        content: (
            <ThemeToggler
                className={cn('MenuItem')}
                platform={Platform.MOBILE}
            />
        ),
    },
].filter(Boolean);
