import { FC } from 'react';

import { config } from 'config';
import { TooltipStateful } from 'shared/ui/TooltipStateful';

import { ParticipantsSingle } from './Single/Participants-Single';
import { cn } from './Participants.cn';
import { i18n } from './Participants.i18n';
import { ParticipantsProps } from './Participants.types';

import './Participants.css';

const { isB2B } = config;

export const Participants: FC<ParticipantsProps> = props => {
    const { participants, size = 'm' } = props;

    return (
        <TooltipStateful
            className={cn('Tooltip')}
            content={i18n('go_to_staff')}
            direction="bottom"
            enabled={!isB2B}
            openDelay={300}
            size="s"
        >
            <div className={cn('Wrapper')}>
                <div className={cn(null, [props.className])}>
                    {participants.length === 0 && i18n('no_participants')}
                    {participants.length === 1 &&
                    <ParticipantsSingle participant={participants[0]} size={size} />}
                </div>
            </div>
        </TooltipStateful>
    );
};

Participants.displayName = cn();
