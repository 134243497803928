import { FC, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { CurrentCompanyContext } from 'components/CurrentCompanyProvider/CurrentCompanyProvider.context';
import { FormTravelPolicy } from 'components/FormTravelPolicy';
import { HeaderPage } from 'components/HeaderPage';
import { getIconProvider, IconTrash } from 'components/Icon';
import { Page } from 'components/Page';
import { withRemote } from 'hocs/withRemote';
import { useDeleteTravelPolicy } from 'hooks/useDeleteTravelPolicy';
import { useTravelPolicyDetails } from 'hooks/useTravelPolicyDetails';
import { RouteCompanies } from 'routes/RouteCompanies';
import { RouteTravelPolicy } from 'routes/RouteTravelPolicy';
import { Button } from 'shared/ui/Button';
import { Container } from 'shared/ui/Container';
import { isLoading } from 'utils/Loadable';

import { EmptyState } from './EmptyState';
import { cn } from './PageTravelPolicy.cn';
import { i18n } from './PageTravelPolicy.i18n';
import { PageTravelPolicyRendererProps } from './PageTravelPolicy.types';

const iconProviderTrash = getIconProvider(IconTrash);

export const PageTravelPolicyRenderer: FC<PageTravelPolicyRendererProps> = props => {
    const { details } = props;

    const { currentCompanyId } = useContext(CurrentCompanyContext);

    const [isDeleteConfrimationVisible, setDeleteConfirmationVisible] = useToggle(false);

    const { push } = useHistory();

    const [deleteTravelPolicy, deleteState] = useDeleteTravelPolicy({
        onSuccess: () => {
            push(RouteCompanies.getPath({ companyId: currentCompanyId }, { tab: 'travel_policies' }));
        },
    });

    const handleConfirmationSubmit = useCallback(() => {
        deleteTravelPolicy(details.travel_policy_id);
        setDeleteConfirmationVisible(false);
    }, [deleteTravelPolicy, details.travel_policy_id, setDeleteConfirmationVisible]);

    const deleteButton = (
        <Button
            iconLeft={iconProviderTrash}
            progress={isLoading(deleteState)}
            size="m"
            view="outline"
            onClick={setDeleteConfirmationVisible}
        >
            {i18n('delete_travel_policy')}
        </Button>
    );

    return (
        <Page className={cn()}>
            <HeaderPage actions={deleteButton} title={details.name} />
            <Container>
                <FormTravelPolicy companyId={currentCompanyId} details={details} />

                <ConfirmationModal
                    message={i18n('confirmation_delete_message')}
                    modalViewHasCloseIcon={false}
                    modalViewOnClose={setDeleteConfirmationVisible}
                    modalViewSize="s"
                    modalViewVisible={isDeleteConfrimationVisible}
                    title={i18n('confirmation_delete_title')}
                    visible={isDeleteConfrimationVisible}
                    onCancel={setDeleteConfirmationVisible}
                    onSubmit={handleConfirmationSubmit}
                />
            </Container>
        </Page>
    );
};

const PageTravelPolicyRendererRemote = withRemote(PageTravelPolicyRenderer);

export const PageTravelPolicy: Page<RouteTravelPolicy> = props => {
    const {
        params: { travelPolicyId },
    } = props;

    const details = useTravelPolicyDetails(travelPolicyId);

    return <PageTravelPolicyRendererRemote details={details} fallbackElement={<EmptyState />} />;
};

PageTravelPolicy.displayName = cn();
