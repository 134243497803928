import { MaxPricePerNightRule, MaxPriceRule, TravelPolicyCreate } from 'services/SwaggerApi';

import { TravelPolicySchema } from '../FormTravelPolicy.schema';

const formatPrices = <T extends MaxPriceRule | MaxPricePerNightRule>(
    array: MaxPriceRule[],
    maxPriceFieldName: keyof T,
): T[] =>
    array.map(item => ({
        location_id: item.location_id,
        location_type: item.location_type,
        [maxPriceFieldName]: item.max_price,
    })) as unknown as T[];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createArrayValue = (prop: string, value: any, additionalValues: Record<string, any>[] = []) => {
    const otherValues = additionalValues.filter(obj => obj.location_id !== null && obj.location_type !== null);

    if (value === null || value === undefined) {
        return otherValues;
    }

    const firstValue = {
        [prop]: value,
    };

    return [firstValue, ...otherValues];
};

const setPayloadRule = (isChecked: boolean | undefined, value: Record<string, unknown>[]) => {
    if (!isChecked) {
        return [];
    }

    return value;
};

export const getCreatePayload = (values: TravelPolicySchema): TravelPolicyCreate => {
    return {
        name: values.name,
        // свойства не меняются через интерфейс, в будущем добавятся на форму
        is_companion_allowed: false,
        is_default_approval_needed: true,
        is_violation_allowed: true,
        avia: {
            max_price: setPayloadRule(
                values.is_avia_max_price || values.avia_max_price_directions.length > 0,
                createArrayValue(
                    'max_price',
                    values.is_avia_max_price ? Number(values.avia_max_price) : null,
                    formatPrices<MaxPriceRule>(values.avia_max_price_directions, 'max_price'),
                ),
            ),
            cheapest_more_percent: setPayloadRule(
                values.is_avia_cheapest_more_percent,
                createArrayValue('cheapest_more_percent', Number(values.avia_cheapest_more_percent)),
            ),
            book_before_days: setPayloadRule(
                values.is_avia_book_before_days,
                createArrayValue('book_before_days', Number(values.avia_book_before_days)),
            ),
            max_transfer_duration_hours: setPayloadRule(
                values.is_avia_max_transfer_duration_hours,
                createArrayValue('max_transfer_duration_hours', Number(values.avia_max_transfer_duration_hours)),
            ),
            refundable_only: createArrayValue('refundable_only', Boolean(values.avia_refundable_only)),
            exchangeable_only: createArrayValue('exchangeable_only', Boolean(values.avia_exchangeable_only)),
            with_baggage_only: createArrayValue('with_baggage_only', Boolean(values.avia_with_baggage_only)),
            business_days_only: createArrayValue('business_days_only', Boolean(values.avia_business_days_only)),
            service_classes: setPayloadRule(
                values.avia_service_classes && values.avia_service_classes.length > 0,
                createArrayValue('service_classes', values.avia_service_classes),
            ),
            // свойства не меняются через интерфейс, в будущем добавятся на форму
            round_trips_only: createArrayValue('round_trips_only', false),
            cheapest_more_price: [],
        },
        rail: {
            max_price: setPayloadRule(
                values.is_rail_max_price || values.rail_max_price_directions.length > 0,
                createArrayValue(
                    'max_price',
                    values.is_rail_max_price ? Number(values.rail_max_price) : null,
                    formatPrices<MaxPriceRule>(values.rail_max_price_directions, 'max_price'),
                ),
            ),
            cheapest_more_percent: setPayloadRule(
                values.is_rail_cheapest_more_percent,
                createArrayValue('cheapest_more_percent', Number(values.rail_cheapest_more_percent)),
            ),
            book_before_days: setPayloadRule(
                values.is_rail_book_before_days,
                createArrayValue('book_before_days', Number(values.rail_book_before_days)),
            ),
            business_days_only: createArrayValue('business_days_only', Boolean(values.rail_business_days_only)),
            train_car_categories: setPayloadRule(
                values.rail_categories && values.rail_categories.length > 0,
                createArrayValue('train_car_categories', values.rail_categories),
            ),
            // свойства не меняются через интерфейс, в будущем добавятся на форму
            refundable_only: createArrayValue('refundable_only', false),
            cheapest_more_price: [],
        },
        hotel: {
            max_price_per_night: setPayloadRule(
                values.is_hotel_max_price_per_night || values.hotel_max_price_directions.length > 0,
                createArrayValue(
                    'max_price_per_night',
                    values.is_hotel_max_price_per_night ? Number(values.hotel_max_price_per_night) : null,
                    formatPrices<MaxPricePerNightRule>(values.hotel_max_price_directions, 'max_price_per_night'),
                ),
            ),
            cheapest_more_percent: setPayloadRule(
                values.is_hotel_cheapest_more_percent,
                createArrayValue('cheapest_more_percent', Number(values.hotel_cheapest_more_percent)),
            ),
            book_before_days: setPayloadRule(
                values.is_hotel_book_before_days,
                createArrayValue('book_before_days', Number(values.hotel_book_before_days)),
            ),
            business_days_only: createArrayValue('business_days_only', Boolean(values.hotel_business_days_only)),
            stars_allowed: setPayloadRule(
                values.hotel_stars && values.hotel_stars.length > 0,
                createArrayValue('stars_allowed', values.hotel_stars),
            ),
            // свойства не меняются через интерфейс, в будущем добавятся на форму
            no_resorts: createArrayValue('no_resorts', false),
            refundable_only: createArrayValue('refundable_only', false),
            cheapest_more_price: [],
        },
    };
};
