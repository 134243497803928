import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { SearchMainFormFields, SearchMainProps } from '../SearchMain.types';

export const useWatchSearchType = (
    methods: UseFormReturn<SearchMainFormFields>,
    onTypeChange: SearchMainProps['onTypeChange'],
) => {
    useEffect(() => {
        const subscription = methods.watch(({ type }) => {
            onTypeChange?.(type!);
        });

        return () => subscription.unsubscribe();
    }, [methods, onTypeChange]);
};
