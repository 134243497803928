import * as D from 'io-ts/Decoder';

import { config } from 'config';
import { ActivePage } from 'shared/constants/ActivePage';
import { checkAccessFactory } from 'utils/checkAccessFactory';

import { route } from './route';

const { isB2B } = config;

export const RouteGroupAddDecoder = D.type({
    params: D.type({}),
    search: D.type({}),
});

export type RouteGroupAdd = D.TypeOf<typeof RouteGroupAddDecoder>;

const checkAccess = checkAccessFactory(
    meta => Boolean(isB2B && meta.is_coordinator && meta.is_tp_enabled),
    isB2B ? 'forbidden' : 'not_found',
);

export const RouteGroupAdd = route(
    '/groups/new',
    RouteGroupAddDecoder,
    {
        isAdminPanel: true,
        activePage: ActivePage.GROUP,
        checkAccess,
    },
);
