import * as yup from 'yup';

import { Gender } from 'services/SwaggerApi';
import { isLatinRegexp } from 'shared/constants/isLatinRegexp';
import { i18n } from 'utils/form/SchemaErrors.i18n';

const todayDate = new Date();

const genders: Gender[] = ['male', 'female'];

export const schema = yup.object({
    first_name: yup.string().trim().required(),
    last_name: yup.string().trim().required(),
    first_name_ac_en: yup.string().trim()
        .matches(isLatinRegexp, () => i18n('latin_required'))
        .max(64)
        .required(),
    last_name_ac_en: yup.string().trim()
        .matches(isLatinRegexp, () => i18n('latin_required'))
        .max(64)
        .required(),
    date_of_birth: yup.date().nullable()
        .max(todayDate, () => i18n('futureDate'))
        .required(),
    gender: yup.mixed<Gender>().oneOf(genders).required(),
    phone_number: yup.string().trim().max(16).required(),
    email: yup.string().email().required(),
});

export type SchemaType = yup.InferType<typeof schema>;
