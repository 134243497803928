import { FC } from 'react';

import { Card } from 'components/Card';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import { cn } from '../FormTravelPolicy.cn';

import { ActionsCardProps } from './ActionsCard.types';

import './ActionsCard.css';

export const ActionsCard: FC<ActionsCardProps> = props => {
    const { actionText, progress, actionCaption, actionDisabled } = props;

    return (
        <Card className={cn('ActionsCard')}>
            <Button disabled={actionDisabled} progress={progress} size="l" type="submit" view="brand" width="max">
                {actionText}
            </Button>
            <Text align="center" color="secondary" typography="caption-m">
                {actionCaption}
            </Text>
        </Card>
    );
};

ActionsCard.displayName = cn();
