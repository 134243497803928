import { FC, useCallback } from 'react';

import { TransactionForm } from 'components/TransactionForm';
import { withModalView } from 'hocs/withModalView';
import { TransactionPartialUpdate } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';
import { serializeDate } from 'utils/serializeDate';
import { serializeNumber } from 'utils/serializeNumber';
import { serializePerson } from 'utils/serializePerson';

import { cn } from '../TransactionForm.cn';

import { useDefaultValues } from './TransactionEditModal.hooks/useDefaultValues';
import { useTransactionUpdate } from './TransactionEditModal.hooks/useTransactionUpdate';
import { i18n } from './TransactionEditModal.i18n';
import { HandleSubmit, TransactionEditModalProps } from './TransactionEditModal.types';

export const TransactionEditModalPresenter: FC<TransactionEditModalProps> = props => {
    const {
        transaction,
        className,
        onCancel,
        onSubmit,
    } = props;
    const {
        editable_fields,
        company_id,
        transaction_id,
    } = transaction;

    const [updateTransaction, updateState, { error }] = useTransactionUpdate({
        onSuccess: onSubmit,
        options: {
            invalidateOnSuccess: true,
            hideValidationErrors: true,
        },
    });

    const handleSubmit = useCallback<HandleSubmit>(formFields => {
        const {
            general_service_id,
            is_penalty,
            service_type,
            trip_id,
            cost_center,
            type,
        } = formFields;

        const formFieldsSerialized: TransactionPartialUpdate = {
            general_service_id,
            is_penalty,
            service_type,
            trip_id,
            type,
            person_id: serializePerson(formFields.person),
            price: serializeNumber(formFields.price),
            fee: serializeNumber(formFields.fee),
            execution_date: serializeDate(formFields.execution_date),
            invoice_date: serializeDate(formFields.invoice_date),
            cost_center_id: Number(cost_center) || null,
        };

        updateTransaction(company_id, transaction_id, formFieldsSerialized);
    }, [company_id, transaction_id, updateTransaction]);
    const isLoadingUpdate = isLoading(updateState);

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('ModalTitle')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <TransactionForm
                companyId={company_id}
                defaultValues={useDefaultValues(transaction)}
                editableFields={editable_fields}
                error={error}
                isLoading={isLoadingUpdate}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

TransactionEditModalPresenter.displayName = cn();

export const TransactionEditModal = withModalView(TransactionEditModalPresenter);
