export const ru = {
    direct_flight: 'Прямой рейс',
    transfers: {
        one: '{count} пересадка',
        some: '{count} пересадки',
        many: '{count} пересадок',
        none: 'Прямой',
    },
    night_transfer: 'Ночная пересадка в {city}',
    transfer_in: 'Пересадка в {city}',
};
