import { FC } from 'react';
import { Link } from '@yandex-int/hr-components/Link/desktop/bundle';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { FieldTextinput, FormProvider } from 'components/FormField2';
import { FieldLabel } from 'components/FormField2/utils';
import { useCreateCompany } from 'hooks/useCreateCompany';
import { i18nAction } from 'i18n/i18nAction';
import { ListItem } from 'shared/ui/ListItem';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { useFormCompanyRegister } from './FormCompanyRegister.hooks/useFormCompanyRegister';
import { cn } from './FormCompanyRegister.cn';
import { i18n, i18nRaw } from './FormCompanyRegister.i18n';
import { FormCompanyRegisterProps } from './FormCompanyRegister.types';

import './FormCompanyRegister.css';

const offerUrl = 'https://yandex.ru/legal/oferta_komandirovki/';
const privacyPolicyUrl = 'https://yandex.ru/legal/confidential/';

const offerLink = (
    <Link href={offerUrl} target="_blank" theme="link">
        {i18n('offer')}
    </Link>
);

const privacyPolicyLink = (
    <Link href={privacyPolicyUrl} target="_blank" theme="link">
        {i18n('privacy_policy')}
    </Link>
);

/**
 * Форма регистрации компании
 */
export const FormCompanyRegister: FC<FormCompanyRegisterProps> = props => {
    const {
        className,
        companyDetails,
    } = props;

    const [registerCompanyPost, state, { error }] = useCreateCompany({ options: { hideValidationErrors: true } });

    const { methods, schema, onSubmit } = useFormCompanyRegister(companyDetails, registerCompanyPost);

    return (
        <FormProvider error={error} methods={methods} schema={schema}>
            <form
                className={cn(null, [className])}
                data-testid={cn()}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <div className={cn('AddCompanyTitle')}>
                    <Text as="div" typography="display-s">
                        {i18n('form_title')}
                    </Text>
                    <Text as="div" color="secondary" typography="body-m">
                        {i18n('form_subtitle')}
                    </Text>
                </div>
                <div className={cn('Inputs')}>
                    <FieldTextinput
                        inputMode="tel"
                        label={i18n('phone_number')}
                        name="phone_number"
                    />
                    <FieldTextinput
                        inputMode="email"
                        label={i18n('email')}
                        name="accounting_email"
                    />
                    <div className={cn('PostAddress')}>
                        <FieldLabel
                            isRequired
                            className={cn('PostAddressLabel')}
                            label={i18n('postal_address')}
                        />
                        <FieldTextinput
                            autoComplete="false"
                            className={cn('Postcode')}
                            inputMode="numeric"
                            name="postcode"
                            placeholder="Индекс"
                        />
                        <FieldTextinput
                            className={cn('Address')}
                            inputMode="text"
                            name="post_address"
                            placeholder="Адрес"
                        />
                    </div>
                    {
                        !companyDetails.name && (
                            <FieldTextinput
                                inputMode="text"
                                label={i18n('company_name')}
                                name="name"
                            />
                        )
                    }
                    {
                        !companyDetails.inn && (
                            <FieldTextinput
                                inputMode="text"
                                label={i18n('inn')}
                                name="inn"
                            />
                        )
                    }
                    {
                        !companyDetails.kpp && (
                            <FieldTextinput
                                inputMode="text"
                                label={i18n('kpp')}
                                name="kpp"
                            />
                        )
                    }
                    {
                        !companyDetails.legal_address && (
                            <FieldTextinput
                                inputMode="text"
                                label={i18n('legal_address')}
                                name="legal_address"
                            />
                        )
                    }
                </div>
                <div className={cn('ListItems')}>
                    {
                        companyDetails.name && (
                            <ListItem
                                expanded
                                noVerticalPadding
                                transparent
                                leadingTextParams={{
                                    caption: i18n('company_name'),
                                    label: companyDetails.name,
                                    inverse: true,
                                }}
                                testId="company-name-label"
                            />
                        )
                    }
                    {
                        companyDetails.inn && (
                            <ListItem
                                expanded
                                noVerticalPadding
                                transparent
                                leadingTextParams={{
                                    caption: i18n('inn'),
                                    label: companyDetails.inn,
                                    inverse: true,
                                }}
                                testId="inn-label"
                            />
                        )
                    }
                    {
                        companyDetails.kpp && (
                            <ListItem
                                expanded
                                noVerticalPadding
                                transparent
                                leadingTextParams={{
                                    caption: i18n('kpp'),
                                    label: companyDetails.kpp,
                                    inverse: true,
                                }}
                                testId="kpp-label"
                            />
                        )
                    }
                    {
                        companyDetails.legal_address && (
                            <ListItem
                                expanded
                                noVerticalPadding
                                transparent
                                leadingTextParams={{
                                    caption: i18n('legal_address'),
                                    label: companyDetails.legal_address,
                                    inverse: true,
                                }}
                                testId="legal-address-label"
                            />
                        )
                    }
                </div>
                <div className={cn('Controls')}>
                    <ButtonPlatform
                        className={cn('Submit')}
                        progress={isLoading(state)}
                        type="submit"
                        view="brand"
                        width="max"
                    >
                        {i18nAction('continue')}
                    </ButtonPlatform>
                    <Text as="div" typography="caption-m">
                        {i18nRaw('submit_subtitle', { offerLink })}
                    </Text>
                    <Text as="div" typography="caption-m">
                        {i18nRaw('submit_privacy_subtitle', { privacyPolicyLink })}
                    </Text>
                </div>
            </form>
        </FormProvider>
    );
};

FormCompanyRegister.displayName = cn();
