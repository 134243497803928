import * as D from 'io-ts/Decoder';

import { ActivePage } from 'shared/constants/ActivePage';
import { checkAccessFactory } from 'utils/checkAccessFactory';
import { ToNumber } from 'utils/io-ts/decoders';

import { route } from './route';

export const RouteCompanyDetailsDecoder = D.type({
    params: D.type({
        companyId: ToNumber,
    }),
    search: D.type({}),
});

export type RouteCompanyDetails = D.TypeOf<typeof RouteCompanyDetailsDecoder>;

const checkAccess = checkAccessFactory(() => false, 'not_found');

export const RouteCompanyDetails = route(
    '/company/:companyId(\\d+)/details/',
    RouteCompanyDetailsDecoder,
    {
        isAdminPanel: true,
        activePage: ActivePage.COMPANY_DETAILS,
        checkAccess,
    },
);
