export const ru = {
    cancel: 'Отмена',
    date_of_birth: 'Дата рождения',
    email: 'E-mail',
    first_name: 'Имя',
    first_name_ac_en: 'Имя (латиницей)',
    gender: 'Пол',
    gender_placeholder: 'Выберите пол',
    last_name: 'Фамилия',
    last_name_ac_en: 'Фамилия (латиницей)',
    middle_name: 'Отчество',
    middle_name_ac_en: 'Отчество (латиницей)',
    phone_number: 'Телефон',
    profile: 'Профиль',
    save: 'Сохранить',
};
