import { ComponentProps, FC, ReactElement } from 'react';
import { withBemMod } from '@bem-react/core';
import { Textarea } from '@yandex-int/hr-components/Textarea/desktop/bundle';

import { cn } from '../FormField.cn';
import { FormFieldProps as BaseProps } from '../FormField.types';

import './FormField_type_textarea.css';

type TextareaProps = ComponentProps<typeof Textarea>;

export interface WithTypeTextareaProps {
    type?: 'textarea';
    placeholder?: TextareaProps['placeholder'];
    onChange?: TextareaProps['onChange'];
    onBlur?: TextareaProps['onBlur'];
    value?: TextareaProps['value'];
    state?: TextareaProps['state'];
    name?: string;
}

export const withTypeTextarea = withBemMod<WithTypeTextareaProps, BaseProps>(
    cn(),
    { type: 'textarea' },
    (Base): FC<BaseProps & WithTypeTextareaProps> => (props): ReactElement => {
        const {
            placeholder,
            state,
            onChange,
            onBlur,
            value,
            name,
        } = props;
        const appendAfter = (
            <Textarea
                hasClear
                className={cn('Control', { name })}
                placeholder={placeholder}
                size="m"
                state={state}
                value={value}
                view="default"
                onBlur={onBlur}
                onChange={onChange}
            />
        );

        return (
            <Base
                { ...props }
                appendAfter={appendAfter}
            />
        );
    },
);
