import { FetchError } from 'errors';
import { createUseQueryHook } from 'hooks/createUseQueryHook';
import { ExtPerson } from 'services/SwaggerApi';
import { RemoteData } from 'utils/Loadable';

type UseExtPersonListOptions = {
   isEnabled?: boolean;
};

type UseExtPersonList = (options?: UseExtPersonListOptions) => RemoteData<ExtPerson[], FetchError>;

export const useExtPersonList: UseExtPersonList = (options = {}) => {
    const { isEnabled = true } = options;

    return createUseQueryHook('ext_person_list', {
        enabled: isEnabled,
    })();
};
