import { FC } from 'react';
import { Link } from '@yandex-int/hr-components/Link/desktop/bundle';

import { Text } from 'shared/ui/Text';
import { getYandexMapsUrl } from 'utils/urls';

import { cn } from './Address.cn';
import { AddressProps } from './Address.types';

export const Address: FC<AddressProps> = props => {
    const {
        address,
        city,
        className,
        children,
        hotelName,
        orgId,
        as = 'div',
        typography = 'body-s',
        ...textProps
    } = props;

    if (!address) return null;

    const mapUrl = getYandexMapsUrl([city, address, hotelName].join(', '), orgId);

    return (
        <Text {...textProps} as={as} className={cn(null, [className])} typography={typography}>
            <Link
                className={cn('Link')}
                href={mapUrl}
                target="_blank"
                theme="link"
            >
                {children || address}
            </Link>
        </Text>
    );
};

Address.displayName = cn();
