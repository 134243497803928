import { config } from 'config';

import { i18n } from '../ExecuteTripButton.i18n';

const { isB2B } = config;

export const getTravelPolicyDialogContent = (needVerification: boolean) => {
    const target = i18n(needVerification ? 'verification' : 'execution');

    if (isB2B && needVerification) {
        return {
            title: i18n('b2b_not_complaint_title'),
            message: i18n('b2b_not_complaint_message'),
        };
    }

    return {
        title: i18n('send_services_confirmation', { target }),
    };
};
