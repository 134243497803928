import { FC, useRef, useState } from 'react';

import { usePlatform } from 'hooks/usePlatform';
import { useScrollObserver } from 'hooks/useScrollObserver';

import { cn } from './TabbedFormsLayout.cn';
import { TabbedFormsLayoutProps } from './TabbedFormsLayout.types';
import { usePositionSticky } from './TabbedFormsLayout.utils';
import { TabbedMenu } from './TabbedMenu';

import './TabbedFormsLayout.css';

export const TabbedFormsLayout: FC<TabbedFormsLayoutProps> = props => {
    const { className, items, actions } = props;

    const [currentTab, setCurrentTab] = useState<string>(items[0].id);
    const asideRef = useRef<HTMLElement | null>(null);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const { isDesktop } = usePlatform();

    const stickyOptions = {
        containerRef: asideRef,
        targetRef: menuRef,
        boundingBottomClass: cn('Menu_absolute'),
        stickyClass: cn('Menu_fixed'),
        isEnabled: isDesktop,
    };

    usePositionSticky(stickyOptions);

    useScrollObserver(items, setCurrentTab);

    return (
        <div className={cn(null, [className])}>
            <div className={cn('Content')}>
                {items.map(({ id, content }) => (
                    <article key={id} id={id}>
                        {content}
                    </article>
                ))}
            </div>
            <aside ref={asideRef} className={cn('Aside')}>
                <div ref={menuRef} className={cn('Menu')}>
                    {isDesktop && <TabbedMenu changeTab={setCurrentTab} currentTab={currentTab} tabs={items} />}
                    {actions}
                </div>
            </aside>
        </div>
    );
};

TabbedFormsLayout.displayName = cn();
