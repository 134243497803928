export const en = {
    goToMap: 'View on map',
    list: 'List',
    map: 'Map',
    press_search: 'Click to see results',
    results_count: {
        one: '{count} option',
        some: '{count} options',
        many: '{count} options',
        none: '{count} options',
    },
    searchDetails: 'It may take a few minutes',
    searchMessage: 'Searching for travel options',
    search_failed: 'Something went wrong. Please try again.',
    search_results: 'Search results',
    show_more: 'Show more',
    back_to_trip: 'Back to trip',
};
