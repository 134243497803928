import { FC, ReactNode } from 'react';

import { CorporateTariff } from 'components/CorporateTariff';
import { HelpTooltip } from 'components/HelpTooltip';
import { IconCheck, IconClose } from 'components/Icon';
import { Text } from 'shared/ui/Text';

import { CancellationRulesList } from '../CancellationRulesList';
import { cn } from '../HotelRoom.cn';
import { i18n } from '../HotelRoom.i18n';
import { getCancellationTerm } from '../utils/getCancellationTerm';

import { TariffTermsProps } from './TariffTerms.types';

import './TariffTerms.css';

const SuccessTermIcon = (
    <Text color="success">
        <IconCheck size={'m'} />
    </Text>
);

const FailureTermIcon = (
    <Text color="secondary">
        <IconClose size={'m'} />
    </Text>
);

type GetTermsListItemProps = {
    isTermSuccess: boolean;
    termTitle: string;
    termTooltipContent?: ReactNode;
};

const getTermsListItem = (props: GetTermsListItemProps) => {
    const {
        isTermSuccess,
        termTitle,
        termTooltipContent,
    } = props;

    return (
        <div className={cn('TariffTermsItem')}>
            {isTermSuccess ? SuccessTermIcon : FailureTermIcon}
            {termTitle}
            {termTooltipContent && (
                <HelpTooltip
                    inverse
                    className={cn('TariffTermTooltip')}
                    content={termTooltipContent}
                    hasTail={false}
                    iconSize="m"
                />
            )}
        </div>
    );
};

export const TariffTerms: FC<TariffTermsProps> = props => {
    const {
        isMealIncluded,
        cancellationRules,
        mealNames,
        priceTotal,
        isCorporateTariff,
        className,
    } = props;

    const {
        title: cancellationTermTitle,
        noPenaltyRule,
        penaltyRules,
    } = getCancellationTerm(cancellationRules, priceTotal);

    const mealIncludedTerm = getTermsListItem({
        isTermSuccess: isMealIncluded,
        termTitle: isMealIncluded ? i18n('meal_included') : i18n('meal_not_included'),
        termTooltipContent: mealNames.join(', '),
    });

    const cancellationTerm = getTermsListItem({
        isTermSuccess: Boolean(noPenaltyRule),
        termTitle: cancellationTermTitle,
        termTooltipContent: (
            <CancellationRulesList
                noPenaltyRule={noPenaltyRule}
                penaltyRules={penaltyRules}
            />
        ),
    });

    return (
        <div className={cn('TariffTerms', [className])}>
            <div className={cn('TariffTermsItem')}>
                {mealIncludedTerm}
            </div>
            <div
                className={cn('TariffTermsItem')}
                data-testid="cancellation-term"
            >
                {cancellationTerm}
            </div>
            {
                isCorporateTariff && (
                    <div className={cn('TariffTermsItem')}>
                        <CorporateTariff
                            inverse
                            className={cn('CorporateTariff')}
                            typography="body-m"
                        />
                    </div>
                )
            }
        </div>
    );
};
