import { Option as SelectOption } from '@yandex-lego/components/Select/Select';

import { TransactionServiceType } from 'services/SwaggerApi';

import { i18n } from '../TransactionForm.i18n';

type GetServiceTypeOption = (value: TransactionServiceType) => SelectOption;

export const getServiceTypeOption: GetServiceTypeOption = value => {
    return {
        value,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        content: i18n(`service_type_value_${value}` as any),
    };
};
