import { IClassNameProps } from '@bem-react/core';

export type CompanyInvoiceProps = IClassNameProps & {
    visible: boolean;
    onModalClose: VoidFunction;
};

export enum ModalSlides {
    FORM_SLIDE,
    DOWNLOAD_SLIDE
}
