import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

export function useParamsPathname(path: string, exact?: boolean): Record<string, string> {
    const location = useLocation();

    return useMemo(() => {
        const r = matchPath(location.pathname, { path, exact });

        return r?.params || {};
    }, [location.pathname, path, exact]);
}
