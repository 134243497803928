import { FC, useCallback, useContext, useState } from 'react';
import { useToggle } from 'react-use';

import { Card } from 'components/Card';
import { ConfirmationModal } from 'components/ConfirmationModal';
import { CurrentCompanyContext } from 'components/CurrentCompanyProvider/CurrentCompanyProvider.context';
import { EmployeeGroupAddModal } from 'components/EmployeeGroupAddModal';
import { getIconProvider, IconPlus } from 'components/Icon';
import { withRemote } from 'hocs/withRemote';
import { useGroupMembersInfinite } from 'hooks/useGroupMembersInfinite';
import { GroupAvailablePersonsResponse } from 'services/SwaggerApi';
import { Button } from 'shared/ui/Button';
import { mapLoadable } from 'utils/Loadable';

import { cn } from '../EmployeesGroupForm.cn';
import { i18n } from '../EmployeesGroupForm.i18n';
import { EmptyState } from '../EmptyState';
import { MembersList } from '../MembersList';
import { MembersListSkeleton } from '../MembersList/MembersListSkeleton';

import { UsersInGroupProps } from './UsersInGroup.types';

import './UsersInGroup.css';

const iconProviderPlus = getIconProvider(IconPlus);

const MembersListRemote = withRemote(MembersList);

export const UsersInGroup: FC<UsersInGroupProps> = props => {
    const { membersIds, groupDetails, setMembersIds } = props;

    const { currentCompanyId } = useContext(CurrentCompanyContext);
    const [tempMembers, setTempMembers] = useState<GroupAvailablePersonsResponse[]>([]);

    const [isMembersAddModalVisible, toggleMembersAddModalVisible] = useToggle(false);
    const [memberIdToDelete, setMemberIdToDelete] = useState<string | null>(null);

    const canFetchMembersList = Boolean(groupDetails) && groupDetails.person_ids.length > 0;

    const [membersList, { isFetchingNextPage, fetchNextPage, hasNextPage }] = useGroupMembersInfinite(
        groupDetails?.group_id as number,
        {
            limit: 10,
        },
        {
            isEnabled: canFetchMembersList,
        },
    );

    const membersListLoadable = mapLoadable(membersList, list => list.items);

    const companyId = groupDetails ? groupDetails.company_id : currentCompanyId;

    const hasMembers = membersIds.length > 0;
    const canLoadMoreMembers = hasNextPage;

    const handleLoadMoreClick = useCallback(() => {
        void fetchNextPage();
    }, [fetchNextPage]);

    const handleSubmitModal = useCallback(
        (checkedPersons: GroupAvailablePersonsResponse[], uncheckedPersons: GroupAvailablePersonsResponse[]) => {
            const checkedPersonsIds = checkedPersons.map(person => person.person_id);
            const uncheckedPersonsIds = uncheckedPersons.map(person => person.person_id);

            setTempMembers(prev => [
                ...prev.filter(person => !uncheckedPersonsIds.includes(person.person_id)),
                ...checkedPersons,
            ]);
            setMembersIds(prev => [...prev.filter(id => !uncheckedPersonsIds.includes(id)), ...checkedPersonsIds]);
        },
        [setMembersIds],
    );

    const handleConfirmationCancel = useCallback(() => {
        setMemberIdToDelete(null);
    }, [setMemberIdToDelete]);

    const handleConfirmationSubmit = useCallback(() => {
        if (memberIdToDelete) {
            setTempMembers(prev => prev.filter(member => member.person_id !== memberIdToDelete));
            setMembersIds(prev => prev.filter(memberId => memberId !== memberIdToDelete));
        }

        setMemberIdToDelete(null);
    }, [memberIdToDelete, setMembersIds]);

    return (
        <>
            {hasMembers ? (
                <Card description={i18n('users_conditions')} size="s" title={i18n('users_in_group')}>
                    <Button
                        className={cn('AddPersons')}
                        iconLeft={iconProviderPlus}
                        size="m"
                        view="outline"
                        onClick={toggleMembersAddModalVisible}
                    >
                        {i18n('add_user')}
                    </Button>

                    <MembersListRemote
                        membersList={canFetchMembersList ? membersListLoadable : []}
                        newMembersList={tempMembers}
                        setMemberIdToDelete={setMemberIdToDelete}
                        skeleton={<MembersListSkeleton />}
                    />

                    {canLoadMoreMembers && (
                        <Button
                            className={cn('LoadMore')}
                            progress={isFetchingNextPage}
                            size="m"
                            view="outline"
                            onClick={handleLoadMoreClick}
                        >
                            {i18n('load_more')}
                        </Button>
                    )}

                    <ConfirmationModal
                        message={i18n('confirmation_message')}
                        modalViewHasCloseIcon={false}
                        modalViewOnClose={handleConfirmationCancel}
                        modalViewSize="s"
                        modalViewVisible={Boolean(memberIdToDelete)}
                        title={i18n('confirmation_title')}
                        visible={Boolean(memberIdToDelete)}
                        onCancel={handleConfirmationCancel}
                        onSubmit={handleConfirmationSubmit}
                    />
                </Card>
            ) : (
                <EmptyState onAddClick={toggleMembersAddModalVisible} />
            )}

            <EmployeeGroupAddModal
                companyId={companyId}
                groupName={groupDetails?.name || null}
                membersIds={membersIds}
                travelPolicy={groupDetails?.travel_policy_name || null}
                visible={isMembersAddModalVisible}
                onClose={toggleMembersAddModalVisible}
                onSubmit={handleSubmitModal}
            />
        </>
    );
};

UsersInGroup.displayName = cn();
