import { FC } from 'react';
import { ToastContainer as ToastContainerLocal } from 'react-toastify';
import { ToastContainer as ToastContainerProduction } from '@yandex-int/hr-components/Toastify/Toastify';

import { CurrentCompanyProvider } from 'components/CurrentCompanyProvider';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { NotificationBannerRoot } from 'components/NotificationBannerRoot';
import { config } from 'config';
import { getPlatformShowTabs } from 'platform/utils';
import { Divider } from 'shared/ui/Divider';
import { UXFeedback } from 'shared/ui/UXFeedback';

import { cn } from '../Root.cn';

import { PageWrapperProps } from './PageWrapper.types';

// ToastContainer сломан в HRDS, не работает локльно, если импортировать из hr-components
// Можно убрать после тикета: https://st.yandex-team.ru/HRDS-609
const { env } = config;
const ToastContainer = env === 'development' ? ToastContainerLocal : ToastContainerProduction;

export const PageWrapper: FC<PageWrapperProps> = props => {
    const {
        routeOptions,
        urlParamsCompanyId,
        children,
    } = props;

    const {
        activePage,
        isAdminPanel,
    } = routeOptions;

    // если показываем в обертке, в которой есть табы - то убираем header и footer
    const showHeaderAndFooter = !getPlatformShowTabs();

    return (
        <CurrentCompanyProvider urlParamsCompanyId={urlParamsCompanyId}>
            <div className={cn()}>
                <div className={cn('Top')}>
                    {showHeaderAndFooter && (
                        <Header
                            activePage={activePage}
                            isAdminPanel={isAdminPanel}
                        />
                    )}
                    <NotificationBannerRoot />
                    <div className={cn('PageWrapper')}>
                        {children}
                    </div>
                </div>
                <ToastContainer
                    closeButton
                    enableMultiContainer
                    newestOnTop
                    limit={5}
                />
                {showHeaderAndFooter && (
                    <>
                        <Divider />
                        <Footer />
                    </>
                )}
            </div>
            <UXFeedback />
        </CurrentCompanyProvider>
    );
};
