import { useMemo } from 'react';
import { Column } from 'react-table';

import { CellActionsHeader } from 'components/TableCells/CellActionsHeader';
import { CellCheckbox } from 'components/TableCells/CellCheckbox';
import { CellHeader } from 'components/TableCells/CellHeader';
import { CellParticipant } from 'components/TableCells/CellParticipant';
import { CellText } from 'components/TableCells/CellText';
import { GroupAvailablePersonsResponse } from 'services/SwaggerApi';

import { i18n } from '../FormEmployeeGroupAdd.i18n';

export const useColumns = (
    onSelectEmployee: (event: React.ChangeEvent<HTMLInputElement>) => void,
    selectedEmployeesIds: string[],
    employeesLength: number,
): Column<GroupAvailablePersonsResponse>[] => {
    return useMemo(
        () => [
            {
                id: 'person',
                accessor: ({ login, last_name, first_name }) => ({ login, title: `${last_name} ${first_name}` }),
                Header: props => (
                    <CellHeader {...props} value={i18n('person_header') + ' • ' + employeesLength} />
                ),
                Cell: CellParticipant,
                width: 376,
            },
            {
                accessor: 'group_name',
                Header: props => (
                    <CellHeader {...props} value={i18n('group_header')} />
                ),
                Cell: props => {
                    const {
                        value,
                        row: { original: employee },
                    } = props;

                    const isEmptyGroupAndPolicy = !value && !employee.travel_policy_name;

                    return (
                        <CellText
                            {...props}
                            color={isEmptyGroupAndPolicy ? 'secondary' : undefined}
                            overflow="ellipsis"
                            typography="label-m"
                            // по бизнес-логике у пользователя не может быть одновременно групповая и персональная ТП,
                            // но одна и та же ТП может быть назначена группе и при этом же быть у кого-то персональной.
                            // чтобы видеть, что человек в группе, сначала отображается группа
                            value={value || employee.travel_policy_name || i18n('no_group')}
                        />
                    );
                },
                width: 320,
            },
            {
                id: 'actions',
                accessor: ({ person_id }) => ({
                    code: person_id,
                    checked: selectedEmployeesIds.includes(person_id),
                    onChange: onSelectEmployee,
                }),
                Header: CellActionsHeader,
                Cell: CellCheckbox,
                width: 48,
            },
        ],
        [employeesLength, onSelectEmployee, selectedEmployeesIds],
    );
};
