import { createContext } from 'react';

import { CurrentCompanyContextValue } from './CurrentCompanyProvider.types';

const noop = () => {};

export const CurrentCompanyContext = createContext<CurrentCompanyContextValue>({
    currentCompanyId: -1,
    setCurrentCompanyId: noop,
});
