export enum ActivePage {
    ACTIVE_TRIPS_LIST = 'active_trips_list',
    ALL_TRIPS_LIST = 'all_trips_list',
    PROFILE = 'profile',
    NEW_TRIP = 'new_trip',
    FINANCE = 'finance',
    GROUP = 'group',
    STAFF = 'staff',
    COMPANY_DETAILS = 'company_details',
    COMPANIES = 'companies',
    REGISTER_PAGE = 'register_page',
    NOT_FOUND = 'not_found',
    ERROR = 'error',
}
