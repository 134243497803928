import { FC } from 'react';
import { useToggle } from 'react-use';

import { AlertAction } from 'components/AlertAction';
import { Card } from 'components/Card';
import { FormPersonDetails } from 'components/FormPersonDetails';
import {
    getIconProvider,
    IconCheckCircle,
    IconEdit,
} from 'components/Icon';
import { config } from 'config';
import { withRemote } from 'hocs/withRemote';
import { useMeta } from 'hooks/useMeta';
import { usePersonDetails } from 'hooks/usePersonDetails';
import { usePersonTrip } from 'hooks/usePersonTrip';
import { useTrip } from 'hooks/useTrip';
import { Button } from 'shared/ui/Button';
import { ModalPlatform } from 'shared/ui/ModalPlatform';
import { Text } from 'shared/ui/Text';

import { getPersonalDataErrorMessage } from './utils/getPersonalDataErrorMessage';
import { cn } from './PersonalData.cn';
import { i18n } from './PersonalData.i18n';
import { PersonalDataProps, PersonalDataViewProps } from './PersonalData.types';

import './PersonalData.css';

const PersonalDataView: FC<PersonalDataViewProps> = props => {
    const { trip, person, personTrip } = props;
    const { env } = config;

    const [isFormPersonDetailsVisible, toggleIsFormPersonDetailsVisible] = useToggle(false);

    const { user: { is_coordinator, is_support } } = useMeta();
    const shoudShowPersonalData = env !== 'production' || is_coordinator || is_support;

    const errorMessage = getPersonalDataErrorMessage(trip, person, personTrip);

    const hasMissingData = Boolean(errorMessage);

    const PersonInfoEditModal = (
        <ModalPlatform
            toggleVisible={toggleIsFormPersonDetailsVisible}
            visible={isFormPersonDetailsVisible}
        >
            <FormPersonDetails
                personDetails={person}
                onCancel={toggleIsFormPersonDetailsVisible}
                onSubmit={toggleIsFormPersonDetailsVisible}
            />
        </ModalPlatform>
    );

    if (hasMissingData) {
        return (
            <Card className={cn()} dataTestId="personal-data-card">
                <Text as="div" typography="body-m">
                    {i18n('personal_data')}
                </Text>
                <AlertAction className={cn('Info')} label={i18n(errorMessage)} />
                <Button
                    className={cn('Choose')}
                    data-testid="choose-data-button"
                    size="m"
                    view="default"
                    width="max"
                    onClick={toggleIsFormPersonDetailsVisible}
                >
                    {i18n('fill')}
                </Button>
                {PersonInfoEditModal}
            </Card>
        );
    }

    if (shoudShowPersonalData) {
        return (
            <Card className={cn()} dataTestId="personal-data-card">
                <Text as="div" typography="body-m">
                    {i18n('personal_data')}
                </Text>
                <Button
                    className={cn('Pencil')}
                    data-testid="pencil-data-button"
                    icon={getIconProvider(IconEdit)}
                    size="m"
                    view="clear"
                    onClick={toggleIsFormPersonDetailsVisible}
                />
                <Text className={cn('Info')} color="success" typography="body-m">
                    <IconCheckCircle size="m" /> {i18n('filled')}
                </Text>
                {PersonInfoEditModal}
            </Card>
        );
    }

    return null;
};

const PersonalDataViewRemote = withRemote(PersonalDataView);

export const PersonalData: FC<PersonalDataProps> = props => {
    const { tripId, personId } = props;
    const trip = useTrip(tripId);
    const personTrip = usePersonTrip(tripId, personId);
    const person = usePersonDetails(personId);

    return (
        <>
            <PersonalDataViewRemote
                fallbackElement={<></>}
                person={person}
                personTrip={personTrip}
                trip={trip}
            />
        </>
    );
};

PersonalData.displayName = cn();
