import { FC } from 'react';

import { MessageBar } from 'components/MessageBar';
import { Container } from 'shared/ui/Container';
import { Text } from 'shared/ui/Text';

import { cn } from './NotificationBanner.cn';
import { NotificationBannerProps } from './NotificationBanner.types';

import './NotificationBanner.css';

export const NotificationBanner: FC<NotificationBannerProps> = props => {
    const {
        type = 'info',
        title,
        description,
        className,
        onClose,
    } = props;

    const children = (
        <>
            {title &&
                <Text
                    as="p"
                    className={cn('Title')}
                    typography="body-m"
                >
                    {title}
                </Text>
            }
            <Text
                as="p"
                className={cn('Description')}
                typography="body-s"
            >
                {description}
            </Text>
        </>
    );

    return (
        <div className={cn({ type }, [className])}>
            <Container>
                <MessageBar
                    className={cn('Content')}
                    hasIcon={false}
                    pin="brick-brick"
                    size="m"
                    onClose={onClose}
                >
                    {children}
                </MessageBar>
            </Container>
        </div>
    );
};

NotificationBanner.displayName = cn();
