import { Purpose } from 'services/SwaggerApi';

import { confKinds } from './confKinds';
import { tripKinds } from './tripKinds';

export function getIdsByKind(purposesData: Purpose[]) {
    const confIds: number[] = [];
    const tripIds: number[] = [];

    purposesData.forEach(purpose => {
        if (confKinds.includes(purpose.kind)) {
            confIds.push(purpose.purpose_id);
        } else if (tripKinds.includes(purpose.kind)) {
            tripIds.push(purpose.purpose_id);
        }
    });

    return { confIds, tripIds };
}
