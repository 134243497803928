import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_weight_medium.css';

export interface WithWeightMediumProps {
    weight?: 'medium';
}

export const withWeightMedium = withBemMod<WithWeightMediumProps>(cn(), {
    weight: 'medium',
});
