import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';

import { Dialog } from 'components/Dialog';
import { FlightSummary } from 'components/FlightSummary';
import { useAddService } from 'hooks/useAddService';
import { i18nAction } from 'i18n/i18nAction';
import { i18nTravelPolicy } from 'i18n/i18nTravelPolicy';
import { RoutePersonTrip } from 'routes/RoutePersonTrip';
import { errorToast } from 'utils/errorToast';
import { getFareFamilyType } from 'utils/getFareFamilyType';
import { getTravelPolicyMessage } from 'utils/getTravelPolicyMessage';

import { i18n } from '../FlightFareFamily.i18n';

import { SummaryProps } from './Summary.types';

export const Summary: FC<SummaryProps> = props => {
    const {
        activeTariff,
        trip,
        personId,
        isViolatesTravelPolicy,
        companionsInSearch,
        setSlideIndex,
        getServiceCreatePayload,

    } = props;

    const {
        baggage_summary,
        is_changeable,
        is_refundable,
        price,
    } = activeTariff;

    const { trip_id, person_trips } = trip;

    const tariffTitle = getFareFamilyType(activeTariff);

    const [isDialogVisible, setDialogVisible] = useToggle(false);

    const isGroupTrip = Boolean(person_trips && person_trips.length > 1);
    const canAddService = Boolean(person_trips?.[0].actions?.create_service);

    const history = useHistory();
    const personTripUrl = RoutePersonTrip.getPath({ tripId: trip_id, personId }, {});
    const goToPersonTrip = useCallback(() => {
        history.push(personTripUrl);
    }, [history, personTripUrl]);

    const [addServiceRequest, , { isLoading }] = useAddService({
        onSuccess: goToPersonTrip,
        onError: e => {
            errorToast(e, { title: i18n('add_service_error_message') });
        },
        options: { hideErrorNotifier: true },
    });

    const addService = useCallback(() => {
        addServiceRequest(getServiceCreatePayload(personId));
    }, [addServiceRequest, getServiceCreatePayload, personId]);

    const handleExecute = useCallback(() => {
        if (isViolatesTravelPolicy) {
            setDialogVisible();
        } else if (companionsInSearch) {
            setSlideIndex(2);
        } else {
            addService();
        }
    }, [
        addService,
        companionsInSearch,
        isViolatesTravelPolicy,
        setDialogVisible,
        setSlideIndex,
    ]);

    const handleDialogConfirm = useCallback(() => {
        if (companionsInSearch) {
            setSlideIndex(2);
        } else {
            addService();
        }

        setDialogVisible();
    }, [addService, companionsInSearch, setDialogVisible, setSlideIndex]);

    const showServiceGroupAdd = useCallback(() => {
        setSlideIndex(1);
    }, [setSlideIndex]);

    return (
        <>
            <FlightSummary
                baggage={baggage_summary}
                canAddService={canAddService}
                isChangeable={is_changeable}
                isGroupTrip={isGroupTrip}
                isRefundable={is_refundable}
                price={price}
                progress={isLoading}
                title={tariffTitle}
                onClick={handleExecute}
                onClickGroupTrip={showServiceGroupAdd}
            />
            <Dialog
                close={i18nAction('cancel')}
                closeButtonView="ghost"
                confirm={i18nTravelPolicy('add_service')}
                scope={null}
                subtitle={getTravelPolicyMessage()}
                title={i18nTravelPolicy('add_not_compliant_service')}
                visible={isDialogVisible}
                onClose={setDialogVisible}
                onConfirm={handleDialogConfirm}
            />
        </>
    );
};
