export const uxfbB2BScript = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>UX FEEDBACK SANDBOX PAGE</title>
    <script>
        (function(w, d, u, h, s) {
            w._uxsSettings = {id: '647j486ediisvsgmmel4ktqy', mode: 1};
            h = d.getElementsByTagName('head')[0];
            s = d.createElement('script');
            s.async = 1;
            s.src = u;
            h.appendChild(s);
        })(window, document, 'https://uxfeedback-cdn.s3.yandex.net/_static/widget/widget.js');
    </script>
</head>
<body></body>
</html>
`;

export const uxfbScript = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <title>UX FEEDBACK SANDBOX PAGE</title>
    <script>
        (function(w, d, u, h, s) {
            w._uxsSettings = {id: '05riupjpt1rlewzbvr8zsh6t', mode: 1};
            h = d.getElementsByTagName('head')[0];
            s = d.createElement('script');
            s.async = 1;
            s.src = u;
            h.appendChild(s);
        })(window, document, 'https://uxfeedback-cdn.s3.yandex.net/_static/widget/widget.js');
    </script>
</head>
<body></body>
</html>
`;
