export const en = {
    users: {
        one: '{count} user',
        some: '{count} users',
        many: '{count} users',
        none: '{count} users',
    },
    avia: 'Flights',
    rail: 'Trains',
    hotel: 'Hotels',
    price_to: 'to',
    and: 'and',
    stars: {
        one: 'stars',
        some: 'stars',
        many: 'stars',
    },
    without_stars: 'without stars',
    no_restrictions: 'no restrictions',
};
