import { FC } from 'react';

import { HotelDetails } from 'components/HotelDetails';
import { Page } from 'components/Page';
import { TrainDetails } from 'components/TrainDetails';
import { withRemote } from 'hocs/withRemote';
import { usePersonTrip } from 'hooks/usePersonTrip';
import { useSearchOptionDetail } from 'hooks/useSearchOptionDetail';
import { useTrip } from 'hooks/useTrip';
import { RouteServiceDetails } from 'routes/RouteServiceDetails';
import { isInitial } from 'utils/Loadable';

import { cn } from './PageServiceDetails.cn';
import { PageServiceDetailsContentProps } from './PageServiceDetails.types';

const HotelDetailsRemote = withRemote(HotelDetails);

export const PageServiceDetailsContent: FC<PageServiceDetailsContentProps> = props => {
    const { search: { trip_id }, personTrip } = props;
    const trip = useTrip(trip_id ?? 0, { isEnabled: Boolean(trip_id) });

    const canAddServices = personTrip ? personTrip.is_approved : false;

    switch (props.option.service_type) {
        case 'hotel': return (
            <HotelDetailsRemote
                {...props}
                canAddServices={canAddServices}
                className={cn('Details')}
                hotelDetails={props.option}
                trip={isInitial(trip) ? null : trip}
            />
        );

        case 'rail': return (
            <TrainDetails
                {...props}
                canAddServices={canAddServices}
                className={cn('Details')}
                trainDetails={props.option}
            />
        );

        default: return null;
    }
};

const PageServiceDetailsRemote = withRemote(PageServiceDetailsContent);

export const PageServiceDetails: Page<RouteServiceDetails> = props => {
    const { params: { searchId, key }, search: { type, trip_id, person_id } } = props;
    const searchResultOptions = { searchId, key, type, trip_id, person_id };
    const option = useSearchOptionDetail(searchResultOptions);
    const personTrip = usePersonTrip(trip_id ?? 0, person_id, { isEnabled: trip_id !== undefined });

    return (
        <PageServiceDetailsRemote
            {...props}
            spinner
            option={option}
            personTrip={trip_id === undefined ? undefined : personTrip}
        />
    );
};

PageServiceDetails.displayName = cn();
