import { FC } from 'react';

import { Table } from 'components/Table';

import { useColumns } from './TableEmployees.hooks/useColumns';
import { cn } from './TableEmployees.cn';
import { TableEmployeesProps } from './TableEmployees.types';

import './TableEmployees.css';

const defaultColumn = {
    width: 180,
};

export const TableEmployees: FC<TableEmployeesProps> = props => {
    const {
        data: { data: employees },
        actionsCallbacks,
        pageSize,
        pageIndex,
        pageCount,
        className,
    } = props;

    const columns = useColumns(actionsCallbacks);

    return (
        <Table
            hasStickyColumns
            className={cn('Table', [className])}
            columns={columns}
            data={employees}
            defaultColumn={defaultColumn}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
        />
    );
};
