import { FC, useState } from 'react';

import { useMeta } from 'hooks/useMeta';

import { useReflectInUrl } from './CurrentCompanyProvider.hooks/useReflectInUrl';
import { CurrentCompanyContext } from './CurrentCompanyProvider.context';
import { CurrentCompanyId, CurrentCompanyProviderProps } from './CurrentCompanyProvider.types';

export const CurrentCompanyProvider: FC<CurrentCompanyProviderProps> = props => {
    const {
        children,
        urlParamsCompanyId,
    } = props;

    const meta = useMeta();

    const initialCompanyId = urlParamsCompanyId || meta.user.company_id;
    const [currentCompanyId, setCurrentCompanyId] = useState<CurrentCompanyId>(initialCompanyId);

    useReflectInUrl(urlParamsCompanyId, currentCompanyId);

    return (
        <CurrentCompanyContext.Provider value={{ currentCompanyId, setCurrentCompanyId }}>
            {children}
        </CurrentCompanyContext.Provider>
    );
};
