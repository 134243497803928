import { Skeleton } from '@yandex-int/hr-components/Skeleton';

import { cn } from '../EmployeesGroupForm.cn';

import './MembersList.css';

export const MembersListSkeleton = () => (
    <>
        {Array(4)
            .fill(0)
            .map((_, index) => (
                <div key={index} className={cn('ListSkeletonItem')}>
                    <Skeleton size="l" type="avatar" />
                    <div className={cn('ListSkeletonItemContent')}>
                        <Skeleton className={cn('ListSkeletonItemTitle')} />
                        <Skeleton className={cn('ListSkeletonItemSubtitle')} />
                    </div>
                </div>
            ))}
    </>
);
