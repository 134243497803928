export const ru = {
    check_info: 'После завершения сверки вы получите письмо с результатами',
    file_is_too_large: 'Размер файла не должен превышать {sizeInMB} МБ',
    file_required: 'Необходимо загрузить файл',
    file_upload_info: 'Чтобы сверить данные таблицы с реестром, загрузите файл с актуальными данными в формате .XLS, .XLSX или .XLSM',
    no_file_selected: 'Файл не выбран',
    select_file: 'Выберите файл',
    sent_to_check: 'Файл отправлен на сверку',
    title: 'Сверка реестра',
    wrong_file_extension: 'Допустимы только файлы в форматaх .XLS, .XLSX или .XLSM',
};
