import { FC } from 'react';

import { usePlatform } from 'hooks/usePlatform';
import { Text } from 'shared/ui/Text';

import { cn } from '../Card.cn';

import { HeaderProps } from './Header.types';

import './Header.css';

export const Header: FC<HeaderProps> = props => {
    const {
        title,
        description,
        className,
        size,
    } = props;

    const { isMobile } = usePlatform();

    return (
        <div className={cn('Header', [className])}>
            {title && (
                <Text
                    className={cn('Title')}
                    typography={isMobile ? 'title-s' : `title-${size}`}
                >
                    {title}
                </Text>
            )}
            {description && (
                <Text
                    className={cn('Description')}
                    color="secondary"
                    typography={isMobile ? 'body-s' : `body-${size}`}
                >
                    {description}
                </Text>
            )}
        </div>
    );
};
