import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useToggle } from 'react-use';

import { Card } from 'components/Card';
import { Dialog } from 'components/Dialog';
import { CostCenterModal } from 'components/FormCostCenter';
import { FieldSelectExt, FieldTextinput, FormProvider } from 'components/FormField2';
import { config } from 'config';
import { useCostCenterList } from 'hooks/useCostCenterList';
import { useMeta } from 'hooks/useMeta';
import { usePersonAnalyticsDataUpdate } from 'hooks/usePersonAnalyticsDataUpdate';
import { usePersonTravelPolicy } from 'hooks/usePersonTravelPolicy';
import { useUnsignConsent } from 'hooks/useUnsignConsent';
import { i18nAction } from 'i18n/i18nAction';
import { i18nCombinedStatus } from 'i18n/i18nCombinedStatus';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { addToast } from 'utils/addToast';
import { getCostCenterOptions } from 'utils/getCostCenterOptions';
import { getErrorInfo } from 'utils/getErrorInfo';
import { foldSuccess, isLoading } from 'utils/Loadable';

import { cn } from '../PageProfile.cn';
import { i18n } from '../PageProfile.i18n';

import { RemoteHideOnStaffCard } from './HideOnStaffCard/HideOnStaffCard';
import { TabSettingsProps } from './TabSettings.types';
import { RemoteTravelPolicyEditorCard } from './TravelPolicyEditorCard';

import './TabSettings.css';

const { isB2B } = config;

type AnalyticsFormFields = {
    personnel_number?: string | null;
    default_cost_center_id?: number | null;
};

export const TabSettings: FC<TabSettingsProps> = props => {
    const {
        className,
        personId,
        personDetails,
    } = props;

    const {
        default_cost_center_id,
        personnel_number,
        person_id,
    } = personDetails;

    const {
        user: {
            consent: { status },
            is_assistant,
            is_coordinator,
            is_accountant,
            company_id,
            is_chief,
            is_tp_enabled,
        },
    } = useMeta();

    // TODO: https://st.yandex-team.ru/BTRIP-11842
    const canEditTravelPolicy = isB2B && is_coordinator && is_tp_enabled;
    const canEditAnalyticFields = isB2B && (is_coordinator || is_accountant);
    const showGapVisibilityTumbler = !isB2B && (is_assistant || is_chief);
    const isPDSigned = !isB2B && status === 'signed';

    const showSecurityCard = isPDSigned || showGapVisibilityTumbler;
    const showAddCostCenterButton = is_coordinator || is_accountant;

    const [dialogVisible, toggleDialogVisible] = useToggle(false);
    const [unsignConsent] = useUnsignConsent({
        onSuccess: () => toggleDialogVisible(),
        onError: error => {
            const { title, message } = getErrorInfo(error.detail);

            addToast({ title, message });
        },
    });

    const [updateAnalyticsData, updateAnalyticsDataState, { error }] = usePersonAnalyticsDataUpdate({
        onSuccess: () => addToast({ title: i18n('submit_data_success_notifier') }),
    });

    const personTravelPolicy = usePersonTravelPolicy(person_id, { isEnabled: Boolean(canEditTravelPolicy) });

    const costCenterList = useCostCenterList(company_id, {}, { isEnabled: isB2B });
    const costCenterOptions = foldSuccess(costCenterList, getCostCenterOptions, () => []);

    const methods = useForm<AnalyticsFormFields>({
        defaultValues: {
            default_cost_center_id,
            personnel_number,
        },
    });

    const handleAnalyticsDataSubmit: SubmitHandler<AnalyticsFormFields> = fields => {
        updateAnalyticsData(personId, fields);
    };

    const [isCostCenterModalVisible, toggleCostCenterModalVisible] = useToggle(false);

    return (
        <div className={cn('TabSettings', [className])}>
            <div className={cn('Main')}>
                {showSecurityCard && (
                    <Card title={i18n('security')}>
                        <div className={cn('TabSettingsList')}>
                            {isPDSigned && (
                                <div className={cn('Description')}>
                                    <div className={cn('PersonalDetails')}>
                                        <Text as="div" typography="label-m">
                                            {i18n('permision_to_process_pd')}
                                        </Text>
                                        <Text color="secondary" typography="caption-m">
                                            {i18n('permision_to_process_pd_description')}
                                        </Text>
                                    </div>
                                    <Button
                                        size="m"
                                        view="outline"
                                        onClick={toggleDialogVisible}
                                    >
                                        {i18nAction('recall')}
                                    </Button>
                                </div>
                            )}
                            {showGapVisibilityTumbler && (
                                <RemoteHideOnStaffCard personDetails={personDetails} />
                            )}
                        </div>
                    </Card>
                )}

                {canEditAnalyticFields && (
                    <Card title={i18n('analytics_data_title')}>
                        <div className={cn('TabSettingsList')}>
                            <Text as="div" color="secondary" typography="body-s">
                                {i18n('analytics_data_description')}
                            </Text>

                            <FormProvider error={error} methods={methods}>
                                <form className={cn('AnalyticsForm')}>
                                    <FieldTextinput
                                        disabled={!canEditAnalyticFields}
                                        label={i18n('personnel_number_label')}
                                        name="personnel_number"
                                        placeholder={i18n('personnel_number_placeholder')}
                                    />
                                    <FieldSelectExt
                                        addonButtonText={showAddCostCenterButton ? i18n('default_cost_center_addon_text') : undefined}
                                        className={cn('SelectExt')}
                                        disabled={!canEditAnalyticFields}
                                        label={i18n('default_cost_center_label')}
                                        name="default_cost_center_id"
                                        options={costCenterOptions}
                                        width="max"
                                        onAddonButtonClick={toggleCostCenterModalVisible}
                                    />
                                </form>
                            </FormProvider>
                            <CostCenterModal
                                companyId={company_id}
                                modalViewOnClose={toggleCostCenterModalVisible}
                                modalViewVisible={isCostCenterModalVisible}
                                onCancel={toggleCostCenterModalVisible}
                                onSubmit={toggleCostCenterModalVisible}
                            />
                        </div>
                    </Card>
                )}

                {canEditTravelPolicy && (
                    <RemoteTravelPolicyEditorCard companyId={company_id} personTravelPolicy={personTravelPolicy} />
                )}

            </div>
            {canEditAnalyticFields && (
                <div className={cn('Controls')}>
                    <Card className={cn('SubmitContainer')}>
                        <Button
                            className={cn('Submit')}
                            progress={isLoading(updateAnalyticsDataState)}
                            size="m"
                            view="brand"
                            onClick={methods.handleSubmit(handleAnalyticsDataSubmit)}
                        >
                            {i18nAction('save_changes')}
                        </Button>
                        <Text align="center" typography="caption-m">
                            {i18n('submit_button_hint')}
                        </Text>
                    </Card>
                </div>
            )}
            {dialogVisible && (
                <Dialog
                    close={i18nAction('no_leave')}
                    closeButtonView="ghost"
                    confirm={i18nAction('yes_recall')}
                    subtitle={i18n('security_confirmation_subtitle')}
                    title={i18nCombinedStatus('verification')}
                    visible={dialogVisible}
                    onClose={toggleDialogVisible}
                    onConfirm={unsignConsent}
                />
            )}
        </div>
    );
};
