import { FC } from 'react';

import { HotelInfo } from 'components/HotelInfo';
import { ImageWithZoom } from 'components/ImageWithZoom';
import { ServiceCard } from 'components/ServiceCard';
import { RouteServiceDetails } from 'routes/RouteServiceDetails';
import { Button } from 'shared/ui/Button';
import { TextBodyS, TextTitleMMedium } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { getTpBanner } from 'utils/getTpBanner';
import { getTypedI18nLang } from 'utils/i18n';

import noPhotoPlaceholderEn from './HotelSearchCard.assets/placeholderEn.svg';
import noPhotoPlaceholderRu from './HotelSearchCard.assets/placeholderRu.svg';
import { cn } from './HotelSearchCard.cn';
import { i18n } from './HotelSearchCard.i18n';
import { HotelCardProps } from './HotelSearchCard.types';

import './HotelSearchCard.css';

export const HotelSearchCard: FC<HotelCardProps> = props => {
    const {
        service: hotel,
        searchId,
        personId,
        tripId,
        adultsCount,
    } = props;

    const {
        image_url,
        min_price_per_night,
        is_recommended,
        ymap_info,
    } = hotel;

    const lang = getTypedI18nLang();
    const noPhotoPlaceholder = lang === 'en' ? noPhotoPlaceholderEn : noPhotoPlaceholderRu;

    const banner = getTpBanner(hotel);

    const pathDetails = RouteServiceDetails.getPath({ searchId, key: hotel.id }, {
        type: 'Hotel',
        person_id: personId,
        trip_id: tripId,
        tariff_index: [0],
        name: hotel.hotel_name,
        adult: adultsCount,
    });
    const priceFormatted = formatCurrency(min_price_per_night);

    // не показываем отели из черного списка
    if (is_recommended === false) {
        return null;
    }

    const hasYaMapPhotos = ymap_info?.photos && ymap_info?.photos.length > 0;

    const basicImageUrl = image_url || noPhotoPlaceholder;

    const hasPhoto = Boolean(hasYaMapPhotos) || Boolean(image_url);

    return (
        <ServiceCard
            banner={banner}
            className={cn()}
            contentClassName={cn('Content')}
            imageElement={
                <ImageWithZoom
                    images={hasYaMapPhotos ? ymap_info?.photos : [basicImageUrl]}
                    withZoom={hasPhoto}
                />
            }
        >
            <HotelInfo className={cn('HotelInfo')} hotel={hotel} />

            <div className={cn('Actions')}>
                <div className={cn('Price')}>
                    <TextTitleMMedium as="div">
                        {i18n('from')} {priceFormatted}
                    </TextTitleMMedium>
                    <TextBodyS as="div" color="secondary">
                        {i18n('per_night')}
                    </TextBodyS>
                </div>
                <Button
                    className={cn('Choose')} size="m"
                    type="link"
                    url={pathDetails}
                    view="primary"
                    width="max"
                >
                    {i18n('more_details')}
                </Button>
            </div>
        </ServiceCard>

    );
};

HotelSearchCard.displayName = cn();
