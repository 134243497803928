import { config } from 'config';
import { UserResponse } from 'services/SwaggerApi';
import { buildUrl } from 'utils/buildUrl';

export function getStaffUrl(pathname?: string, search?: Record<string, string>): string {
    const { host } = config.hosts.staff || {};

    if (!host) return '';

    return buildUrl({
        host,
        pathname: pathname || '',
        search: search || {},
    });
}

const getChatUrl = (chatIdExt: UserResponse['chat_id']) => {
    const { env } = config;

    if (env === 'production') {
        return chatIdExt ? 'https://yandex.ru/chat/#/user/' : 'https://q.yandex-team.ru/#/';
    }

    return chatIdExt ? 'https://yandex.ru/chat?config=alpha#/user/' : 'https://q.yandex-team.ru/?config=alpha#/';
};

export const ALAPANA_MSGR_ID = 'staff/robot-alapana-msgr';

export function getMessengerUrl(chatIdExt: UserResponse['chat_id'], agent?: boolean): string {
    const chatUrl = getChatUrl(chatIdExt); //TODO: После миграции чатов на беке брать url из кода ниже
    const chatId = chatIdExt || ALAPANA_MSGR_ID;

    if (!agent) {
        return chatUrl + chatId;
    }

    const { host } = config.hosts.messenger;

    const searchParameter = config.env !== 'production' ? {
        search: {
            config: 'alpha',
        },
    } : undefined;

    if (chatIdExt) {
        return buildUrl({
            host,
            pathname: 'chat',
            ...searchParameter,
            hash: `/chats/${encodeURIComponent(chatIdExt)}`,
        });
    }

    return buildUrl({
        host,
        ...searchParameter,
        hash: `/${ALAPANA_MSGR_ID}`,
    });
}

export function getTrackerUrl(issueId: string): string {
    const { host } = config.hosts.tracker || {};

    if (!host) return '';

    return buildUrl({
        host,
        pathname: `${encodeURIComponent(issueId)}`,
        search: {},
    });
}

export function getPassportUrl(retpath: string = ''): string {
    const { host } = config.hosts.passport;

    return buildUrl({
        host,
        pathname: 'auth',
        search: { retpath },
    });
}

export function getPassportPorgUrl(retpath: string = ''): string {
    const { host } = config.hosts.passport;

    return buildUrl({
        host,
        pathname: 'auth/reg/org',
        search: { retpath },
    });
}

export function getPassportAutofillUrl(): string {
    const { host } = config.hosts.passportAutofill || {};

    if (!host) return '';

    return buildUrl({ host });
}

export function getYandexMapsUrl(text: string, orgId?: string): string {
    if (orgId) {
        return buildUrl({
            host: 'yandex.ru',
            pathname: `maps/org/${orgId}`,
        });
    }

    return buildUrl({
        host: 'yandex.ru',
        pathname: 'maps',
        search: { text },
    });
}
