import { FC, FocusEventHandler, useCallback, useMemo, useRef, useState } from 'react';
import {
    DayClickEventHandler,
    DayFocusEventHandler,
    DayPicker as DayPickerBase,
} from 'react-day-picker';
import { useToggle } from 'react-use';

import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { Drawer } from 'shared/ui/Drawer';
import { Popup } from 'shared/ui/Popup';

import { Caption } from './Caption/Caption';
import { DatePresenter } from './DatePresenter/DatePresenter';
import { Day } from './Day/Day';
import { DrawerHeader } from './DrawerHeader/DrawerHeader';
import { useScrollToSelectedDate } from './hooks/useScrollToSelectedDate';
import { useWatchMonthScroll } from './hooks/useWatchMonthScroll';
import { MonthPicker } from './MonthPicker/MonthPicker';
import { getConfirmButtonText } from './utils/getConfirmButtonText';
import { getMonths } from './utils/getMonths';
import { Weekdays } from './Weekdays/Weekdays';
import { cn } from './DatePicker.cn';
import { DatePickerProps } from './DatePicker.types';

import './DatePicker.css';

/**
 * Поле для выбора даты с попапом
 */
export const DatePicker: FC<DatePickerProps> = props => {
    const {
        fromDate,
        toDate,
        inputPlaceholder,
        inputClassName,
        showSubText,
        value,
        state,
        scope,
        onBlur,
        onChange,
    } = props;

    const [isPopupVisible, togglePopupVisible] = useToggle(false);

    const anchorRef = useRef<HTMLDivElement>(null);

    const [activeMonth, setActiveMonth] = useState(0);

    const { setIsScrolling, datePickerRef } = useWatchMonthScroll({ setActiveMonth, isPopupVisible });

    useScrollToSelectedDate({ date: value, isPopupVisible, fromDate, datePickerRef });

    const { isMobile } = usePlatform();

    const months = getMonths();

    const currentDate = useMemo(() => new Date(), []);
    const customComponents = useMemo(() => ({ Caption, Day }), []);

    const confirmButtonText = getConfirmButtonText(value);

    const closePopup = useCallback(() => togglePopupVisible(false), [togglePopupVisible]);
    const openPopup = useCallback(() => togglePopupVisible(true), [togglePopupVisible]);

    const handleDayClick = useCallback<DayClickEventHandler>(day => {
        onChange?.(day);
        closePopup();
    }, [onChange, closePopup]);

    const handleInputBlur = useCallback<FocusEventHandler>(e => {
        const focusedElement = e.relatedTarget as Element;

        if (!focusedElement) {
            return;
        }

        const isFocusedElementInDatePicker = focusedElement.className.startsWith('DatePicker') ||
         focusedElement.matches('.DatePicker-ConfirmButton') ||
         focusedElement.className.startsWith('rdp') ||
         focusedElement.id === 'ResetButton';

        if (onBlur && !isFocusedElementInDatePicker) {
            onBlur();
            togglePopupVisible(false);
        }
    }, [onBlur, togglePopupVisible]);

    const handleDayBlur = useCallback<DayFocusEventHandler>((_, __, e) => {
        if (!('relatedTarget' in e)) {
            return;
        }

        handleInputBlur(e);
    }, [handleInputBlur]);

    const DayPicker = useMemo(() => (
        <DayPickerBase
            disableNavigation
            hideHead
            components={customComponents}
            fromDate={fromDate || currentDate}
            mode="single"
            month={currentDate}
            numberOfMonths={13}
            selected={value && new Date(value)}
            toDate={toDate}
            weekStartsOn={1}
            onDayBlur={handleDayBlur}
            onDayClick={isMobile ? onChange : handleDayClick}
            {...props}
        />
    ), [
        currentDate, customComponents, fromDate, handleDayBlur,
        handleDayClick, isMobile, onChange, props, toDate, value,
    ]);

    return (
        <>
            <div
                ref={anchorRef}
                tabIndex={0}
            >
                <DatePresenter
                    active={isPopupVisible}
                    className={inputClassName}
                    placeholder={inputPlaceholder}
                    showSubText={showSubText}
                    state={state}
                    testId="field-date"
                    value={value}
                    onClear={() => onChange?.(undefined)}
                    onClick={openPopup}
                />
            </div>
            {isMobile ? (
                <Drawer
                    titleComponent={
                        <DrawerHeader
                            activeField={'left'}
                            leftInputPlaceholder={inputPlaceholder}
                            range={{ from: value }}
                            onChange={onChange}
                        />
                }
                    view="default"
                    visible={isPopupVisible}
                    onClose={togglePopupVisible}
                >
                    <div className={cn()}>
                        <Weekdays />
                        <Button
                            className={cn('ConfirmButton')}
                            size="l"
                            view="primary"
                            width="max"
                            onClick={closePopup}
                        >
                            {confirmButtonText}
                        </Button>
                        {DayPicker}
                    </div>
                </Drawer>
            ) : (
                <>
                    <Popup
                        anchor={anchorRef}
                        className={cn('Popup')}
                        scope={scope}
                        target="anchor"
                        view="default"
                        visible={isPopupVisible}
                        onClose={togglePopupVisible}
                    >
                        <div className={cn('Wrapper')}>
                            <MonthPicker
                                activeMonth={activeMonth}
                                months={months}
                                setActiveMonth={setActiveMonth}
                                setIsScrolling={setIsScrolling}
                            />
                            <div ref={datePickerRef} className={cn()}>
                                <Weekdays />
                                {DayPicker}
                            </div>
                        </div>
                    </Popup>
                </>
            )}
        </>
    );
};

DatePicker.displayName = cn();
