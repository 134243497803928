export const ru = {
    available_places: 'Свободных мест',
    bottom: 'Нижние',
    bottom_side: 'Нижние боковые',
    carriage: 'Вагон',
    choose_carriage_class: 'Выберите тип и класс вагона',
    choose_place_for_employee: 'Выбрать для сотрудника',
    choose_place_for_companion: 'Выбрать для попутчика',
    choose_place_error_title: 'Невозможно выбрать данное место',
    choose_place_error_description: 'Вы не можете выбрать место в данном купе ({type} купе)',
    choose_tariff: 'Выберите тариф',
    class: 'Класс {code}',
    female: 'Женские',
    man: 'Мужские',
    mixed: 'Смешанные',
    not_branded: 'не фирменный',
    not_defined: 'Не определено',
    places_from: 'Места от',
    places_next_step: 'Выбор места будет доступен на следующем шаге.',
    select: 'Выбрать',
    top: 'Верхние',
    top_side: 'Верхние боковые',
    wo_desc: 'Без описания',
    tickets_not_found_title: 'Не осталось свободных мест',
    tickets_not_found_description: 'Кажется кто-то уже купил этот билет. Если произошла ошибка вы можете обратиться за помощью в чат, либо повторить поиск снова.',
    back_to_search: 'Назад к поиску',
    go_to_trip: 'Перейти в заявку',
    no_passengers_selected: 'Выберите места для участников командировки',
    passengers_selected: 'Пользователи, которым были выбраны места',
    passengers_list: 'Список пассажиров',
    car_and_seat: 'Вагон {carNumber} · место {seatNumber}',
    passengers_added: {
        one: 'добавлен {count} пользователь',
        some: 'добавлено {count} пользователя',
        many: 'добавлено {count} пользователей',
        none: 'Выберите места для участников командировки',
    },
    choose_this_seat: 'Выбрать это место',
    features: 'Особенности места',
    seat: 'Место {seatNumber}',
    price: 'Стоимость',
    male_compartment: 'Мужское купе',
    female_compartment: 'Женское купе',
    add_service_error_message: 'Не удалось добавить услугу',
};
