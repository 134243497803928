import { HeaderPage } from 'components/HeaderPage';
import { Page } from 'components/Page';
import { TableEmployees } from 'components/TableEmployees';
import { RouteStaff } from 'routes/RouteStaff';
import { Container } from 'shared/ui/Container';

import { cn } from './PageStaff.cn';
import { i18n } from './PageStaff.i18n';

export const PageStaff: Page<RouteStaff> = props => {
    const {
        params: { companyId },
    } = props;

    return (
        <Page className={cn()}>
            <HeaderPage title={i18n('employees')} />
            <Container>
                <TableEmployees companyId={companyId} />
            </Container>
        </Page>
    );
};

PageStaff.displayName = cn();
