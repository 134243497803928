import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { PersonDetails, PersonDetailsUpdate } from 'services/SwaggerApi';
import { serializeDate } from 'utils/serializeDate';

import { schema } from '../FormPersonDetails.schema';
import { FormPersonDetailsFields } from '../FormPersonDetails.types';

export const useFormPersonDetails = (
    personDetails: PersonDetails,
    personDetailsPut: (person_id: string, payload: PersonDetailsUpdate) => void,
) => {
    const {
        first_name,
        last_name,
        date_of_birth,
        middle_name,
        gender,
        email,
        phone_number,
        first_name_ac_en,
        last_name_ac_en,
        middle_name_ac_en,
        person_id,
    } = personDetails;

    const onSubmit: SubmitHandler<FormPersonDetailsFields> = values => {
        personDetailsPut(person_id, {
            first_name: values.first_name,
            last_name: values.last_name,
            middle_name: values.middle_name,
            first_name_ac_en: values.first_name_ac_en,
            last_name_ac_en: values.last_name_ac_en,
            middle_name_ac_en: values.middle_name_ac_en,
            date_of_birth: serializeDate(values.date_of_birth),
            gender: values.gender,
            phone_number: values.phone_number,
        });
    };

    const methods = useForm<FormPersonDetailsFields>({
        values: {
            ...(date_of_birth && { date_of_birth: new Date(date_of_birth) }),
            first_name,
            last_name,
            middle_name,
            gender,
            email,
            phone_number,
            first_name_ac_en,
            last_name_ac_en,
            middle_name_ac_en,
        },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });

    return { methods, schema, onSubmit };
};
