import { FC } from 'react';
import { Palettes } from '@yandex-int/hr-components/PaletteGeneration';

import { Dropdown } from 'components/Dropdown';
import { Tag } from 'shared/ui/Tag';

import { cn } from './TagHint.cn';
import { TagHintProps } from './TagHint.types';

import './TagHint.css';

export const TagHint: FC<TagHintProps> = props => {
    const {
        color,
        tagText,
        hintContent,
        inverse,
        tagSize = 'm',
        desktopTrigger,
        className,
        view,
    } = props;

    const tag = (
        <span data-testid="tag-hint-trigger">
            <Tag
                rounded
                className={cn('Tag', { desktopTrigger })}
                color={color}
                maxWidth="full"
                size={tagSize}
                text={tagText}
                view={view}
            />
        </span>
    );

    if (!hintContent) {
        return tag;
    }

    return (
        <Dropdown
            className={cn(null, [className])}
            desktopTrigger={desktopTrigger}
            drawerProps={{
                scope: 'inplace',
            }}
            popupProps={{
                hasTail: true,
                direction: ['bottom-end', 'left', 'top-end'],
                className: inverse ? Palettes.inverse : undefined,
                scope: 'inplace',
            }}
            triggerElement={tag}
        >
            <div className={cn('Hint')}>
                {hintContent}
            </div>
        </Dropdown>
    );
};

TagHint.displayName = cn();
