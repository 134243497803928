import { ComponentProps, FC, useCallback } from 'react';

import { TransactionForm } from 'components/TransactionForm';
import { withModalView } from 'hocs/withModalView';
import { TransactionCreate } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';
import { serializeDate } from 'utils/serializeDate';
import { serializeNumber } from 'utils/serializeNumber';
import { serializePerson } from 'utils/serializePerson';

import { cn } from '../TransactionForm.cn';

import { useTransactionCreate } from './TransactionCreateModal.hooks/useTransactionCreate';
import { i18n } from './TransactionCreateModal.i18n';
import { HandleSubmit, TransactionCreateModalProps } from './TransactionCreateModal.types';

type TransactionFormProps = ComponentProps<typeof TransactionForm>;

const editableFields: TransactionFormProps['editableFields'] = {
    trip_id: true,
    execution_date: true,
    fee: true,
    general_service_id: true,
    invoice_date: true,
    is_penalty: true,
    person_id: true,
    price: true,
    service_type: true,
    type: true,
    status: true,
    cost_center_id: true,
};

const defaultValues: TransactionFormProps['defaultValues'] = {
    is_penalty: false,
};

export const TransactionCreateModalPresenter: FC<TransactionCreateModalProps> = props => {
    const {
        className,
        companyId,
        onCancel,
        onSubmit,
    } = props;

    const [createTransaction, createState, { error }] = useTransactionCreate({
        onSuccess: onSubmit,
        options: {
            invalidateOnSuccess: true,
            hideValidationErrors: true,
        },
    });

    const handleSubmit = useCallback<HandleSubmit>(formFields => {
        const {
            is_penalty,
            service_type,
            trip_id,
            type,
            status,
            general_service_id,
            execution_date,
            invoice_date,
            cost_center,
        } = formFields;

        const formFieldsSerialized: TransactionCreate = {
            is_penalty,
            service_type,
            trip_id,
            type,
            general_service_id,
            status: status || 'hold',
            person_id: serializePerson(formFields.person)!,
            price: serializeNumber(formFields.price)!,
            fee: serializeNumber(formFields.fee)!,
            execution_date: serializeDate(execution_date)!,
            invoice_date: serializeDate(invoice_date),
            cost_center_id: Number(cost_center) || null,
        };

        createTransaction(companyId, formFieldsSerialized);
    }, [companyId, createTransaction]);

    const isLoadingUpdate = isLoading(createState);

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('ModalTitle')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <TransactionForm
                companyId={companyId}
                defaultValues={defaultValues}
                editableFields={editableFields}
                error={error}
                isLoading={isLoadingUpdate}
                mode="create"
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

TransactionCreateModalPresenter.displayName = cn();

export const TransactionCreateModal = withModalView(TransactionCreateModalPresenter);
