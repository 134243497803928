import { FC, memo } from 'react';

import { Text } from 'shared/ui/Text';

import { cn } from '../ServiceCostDetails.cn';

import type { CostElementProps } from './CostElement.types';

import '../ServiceCostDetails.css';

export const CostElement: FC<CostElementProps> = memo(props => {
    const { text, cost } = props;

    return (
        <div className={cn('CostElement')}>
            <Text
                align="center"
                as="span"

                color="ghost"
                typography="caption-m"
            >
                {text}
            </Text>
            <Text
                align="center"
                as="span"

                color="primary"
                typography="title-s"
            >
                {cost}
            </Text>
        </div>
    );
});

CostElement.displayName = cn('CostElement');
