import { useEffect } from 'react';

import { useBanners } from 'hooks/useBanners';
import { Balance } from 'services/SwaggerApi';

import { i18n } from '../AccountBalance.i18n';

type UseBalanceNotification = (status: Balance['status']) => void;

export const useBalanceNotification: UseBalanceNotification = status => {
    const { addBanner, deleteAllBanners } = useBanners();

    useEffect(() => {
        switch (status) {
            case 'warning':
                addBanner({ type: 'info', description: i18n('notification_warning_description') });
                break;

            case 'empty':
                addBanner({ type: 'error', description: i18n('notification_danger_description') });
                break;
        }

        return () => deleteAllBanners();
    }, [addBanner, deleteAllBanners, status]);
};
