import { FC } from 'react';

import { ModalSlider } from 'components/ModalSlider';

import { cn } from './AddCompanionDocument.cn';
import { AddCompanionDocumentProps } from './AddCompanionDocument.types';
import { FormSlide } from './FormSlide';
import { HintSlide } from './HintSlide';

import './AddCompanionDocument.css';

export const AddCompanionDocument: FC<AddCompanionDocumentProps> = props => {
    const {
        visible,
        onModalClose,
        extPersonId,
        className,
    } = props;

    return (
        <ModalSlider
            className={cn(null, [className])}
            visible={visible}
        >
            <HintSlide onClose={onModalClose} />
            <FormSlide
                personId={extPersonId}
                onCancel={onModalClose}
                onSubmit={onModalClose}
            />
        </ModalSlider>
    );
};

AddCompanionDocument.displayName = cn();
