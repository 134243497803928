import { FC } from 'react';

import { HeaderLite } from 'components/HeaderLite';
import { Container } from 'shared/ui/Container';
import { ErrorState } from 'shared/ui/ErrorState';

import { cn } from '../Register.cn';
import { i18n } from '../Register.i18n';

import { ErrorProps } from './Error.types';

import './Error.css';

export const Error: FC<ErrorProps> = props => {
    const {
        className,
    } = props;

    return (
        <div
            className={cn('Error', [className])}
            data-testid="register-error"
        >
            <HeaderLite />
            <Container wrapperClassName={cn('ErrorContainer')}>
                <ErrorState
                    description={i18n('error_description')}
                    imageType="not_found"
                    title={i18n('error_title')}
                />
            </Container>
        </div>
    );
};
