import { useEffect } from 'react';
import { matchRoutes } from 'react-router-config';
import { generatePath, useHistory, useLocation } from 'react-router-dom';

import { routes } from 'routes';

import { CurrentCompanyId } from '../CurrentCompanyProvider.types';

type UseReflectInUrl = (
    urlParamsCompanyId: CurrentCompanyId | null,
    currentCompanyId: CurrentCompanyId,
) => void;

const routesConfigs = routes.map(route => ({ path: route.path, exact: route.exact }));

export const useReflectInUrl: UseReflectInUrl = (urlParamsCompanyId, currentCompanyId) => {
    const { pathname, search } = useLocation();
    const { replace } = useHistory();

    useEffect(() => {
        const isUrlParamsCompanyIdDefined = typeof urlParamsCompanyId === 'number';
        const isCurrentCompanyIdDefined = typeof currentCompanyId === 'number';
        const hasCompanyIdChanged = urlParamsCompanyId !== currentCompanyId;

        const noChanges = !isUrlParamsCompanyIdDefined || !isCurrentCompanyIdDefined || !hasCompanyIdChanged;

        if (noChanges) return;

        const matched = matchRoutes(routesConfigs, pathname);

        if (!matched.length) return;

        const { path, params } = matched[0].match;

        const newPath = generatePath(path,
            { ...params, companyId: currentCompanyId },
        ) + search;

        replace(newPath);
    }, [currentCompanyId, pathname, replace, search, urlParamsCompanyId]);
};
