import { FC } from 'react';
import { HostsContextProvider } from '@yandex-int/hr-components/Context/HostsContext';
import { Host } from '@yandex-int/hr-components/utils/types';

import { HeaderLogo } from 'components/Header/HeaderLogo/HeaderLogo';
import { UserId } from 'components/Header/UserId/UserId';
import { ThemeToggler } from 'components/ThemeToggler';
import { config } from 'config';
import { usePlatform } from 'hooks/usePlatform';
import { PageHeader } from 'shared/ui/PageHeader';

import { cn } from './HeaderLite.cn';
import { HeaderLiteProps } from './HeaderLite.types';

import './HeaderLite.css';

const { hosts: tripHosts } = config;

const hosts = {
    staff: tripHosts.staff as Host,
    center: tripHosts.center as Host,
};

export const HeaderLite: FC<HeaderLiteProps> = props => {
    const {
        showAvatar = true,
        overlay,
        logoColor,
        className,
    } = props;
    const { platform, isMobile } = usePlatform();

    const actions = [
        <ThemeToggler key="theme-toggler" />,
        showAvatar && <UserId key={'avatar'} isMobile={isMobile} />,
    ];

    return (
        <HostsContextProvider hosts={hosts}>
            <PageHeader
                actions={actions}
                className={cn({ overlay, logoColor }, [className])}
                desktopLogo={<HeaderLogo />}
                mobileLogo={<HeaderLogo />}
                platform={platform}
            />
        </HostsContextProvider>
    );
};
