import { FC } from 'react';

import { Cell } from 'components/Cell';
import { IconCheck } from 'components/Icon';
import { withTdAttrs } from 'hocs/withTdAttrs';

import { cn } from './CellCheck.cn';
import { CellCheckProps } from './CellCheck.types';

const CellCheckBase: FC<CellCheckProps> = props => {
    const {
        className,
        value,
        getAttrs,
    } = props;

    return (
        <Cell
            className={cn(null, [className])}
            getAttrs={getAttrs}
            size="m"
        >
            {value && <IconCheck size="m" />}
        </Cell>
    );
};

export const CellCheck = withTdAttrs(CellCheckBase);
CellCheck.displayName = cn();
