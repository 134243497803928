import { ComponentProps, FC } from 'react';

import { IconProps } from 'components/Icon';

import { OptionInfoItem } from '../OptionInfoItem';

type OptionInfoItemProps = ComponentProps<typeof OptionInfoItem>;

type GetItemIcon = (
    condition: OptionInfoItemProps['condition'],
    successIcon: OptionInfoItemProps['successIcon'],
    failureIcon: OptionInfoItemProps['failureIcon'],
) => FC<IconProps> | undefined;

export const getItemIcon: GetItemIcon = (condition, successIcon, failureIcon) => {
    if (condition && successIcon) {
        return successIcon;
    }

    if (!condition && failureIcon) {
        return failureIcon;
    }

    return;
};
