import { FC, useCallback } from 'react';

import { withFormField } from 'components/FormField2/FormField2';
import { FormFieldProps } from 'components/FormField2/FormField2.types';
import { i18nErrors } from 'i18n/i18nErrors';
import { Checkbox } from 'shared/ui/Checkbox';

type Option = {
    target_id: string;
    caption: string;
};

export type WithTypeOptionsProps = {
    options: Option[];
};

const Control: FC<WithTypeOptionsProps & FormFieldProps> = props => {
    const { options, controller, name } = props;

    const {
        field: { onChange, value, onBlur },
    } = controller;

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            event.stopPropagation();

            const { code } = event.target.dataset;

            if (code === undefined) throw new Error(i18nErrors('option_code_undefined'));

            onBlur();

            const codeIndex = value.indexOf(code);

            if (codeIndex === -1) return onChange([...value, code]);

            return onChange([...value.slice(0, codeIndex), ...value.slice(codeIndex + 1)]);
        },
        [onBlur, onChange, value],
    );

    return (
        <>
            {options.map(option => (
                <Checkbox
                    key={option.target_id}
                    checked={value && value.includes(option.target_id)}
                    data-code={option.target_id}
                    data-testid={`field-${name}-${option.target_id}`}
                    label={option.caption}
                    lines="one"
                    size="m"
                    title={option.caption}
                    view="outline"
                    onChange={handleChange}
                />
            ))}
        </>
    );
};

export const FieldOptions = withFormField(Control);
