import { i18n } from '../FieldSort.i18n';
import { Option } from '../FieldSort.types';

export const getOptions = () => {
    const options: Option[] = [
        {
            content: i18n('optimal_train_flight'),
            is_descending: false,
            types: ['Avia', 'Rail'],
        },
        {
            content: i18n('optimal_hotel'),
            is_descending: false,
            types: ['Hotel'],
        },
        {
            content: i18n('early_departures_first'),
            order_by: 'departure_time',
            is_descending: false,
            types: ['Avia', 'Rail'],
        },
        {
            content: i18n('late_departures_first'),
            order_by: 'departure_time',
            is_descending: true,
            types: ['Avia', 'Rail'],
        },
        {
            content: i18n('economical_first'),
            order_by: 'price',
            is_descending: false,
            types: ['Avia', 'Rail', 'Hotel'],
        },
        {
            content: i18n('expensive_first'),
            order_by: 'price',
            is_descending: true,
            types: ['Avia', 'Rail', 'Hotel'],
        },
        {
            content: i18n('fast_first'),
            order_by: 'duration',
            is_descending: false,
            types: ['Avia', 'Rail'],
        },
    ];

    return options.map((option, value) => ({ ...option, value }));
};
