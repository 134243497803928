import { FC, useCallback, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Pagination } from '@yandex-int/hr-components/Pagination/desktop/bundle';

import { TableSkeleton } from 'components/TableSkeleton';
import { EmptyState } from 'components/TableTransactions/EmptyState';
import { TableTransactionsHeaderLite } from 'components/TableTransactionsHeaderLite';
import { withRemote } from 'hocs/withRemote';
import { useTablePagination } from 'hooks/useTablePagination';
import { TTransaction_list_for_clientQuery } from 'services/SwaggerApi';
import { hasData } from 'utils/Loadable';

import { useTransactionListLite } from './TableTransactionsLite.hooks/useTransactionListLite';
import { serializeFilters } from './TableTransactionsLite.utils/serializeFilters';
import { TableTransactionsLite } from './TableTransactionsLite';
import { cn } from './TableTransactionsLite.cn';
import { TableTransactionsContainerLiteProps, TableTransactionsLiteFilters } from './TableTransactionsLite.types';

const TableTransactionsLiteRemote = withRemote(TableTransactionsLite);

const defaultFilters = {};

export const TableTransactionsContainerLite: FC<TableTransactionsContainerLiteProps> = props => {
    const {
        companyId,
        className,
    } = props;

    const [filters, setFilters] = useState<TableTransactionsLiteFilters>(defaultFilters);
    const {
        paginationProps,
        computePagesCount,
        resetCurrentPage,
    } = useTablePagination();
    const {
        pageSize,
        currentPage,
    } = paginationProps;

    const handleFiltersChange = useCallback(data => {
        unstable_batchedUpdates(() => {
            resetCurrentPage();
            setFilters(data);
        });
    }, [resetCurrentPage]);

    const queryParams: TTransaction_list_for_clientQuery = useMemo(() => ({
        ...serializeFilters(filters),
        page: currentPage,
        limit: pageSize,
    }), [currentPage, pageSize, filters]);

    const transactions = useTransactionListLite(companyId, queryParams);
    const transactionsCount = hasData(transactions) ? transactions.result.count : 0;
    const pagesCount = computePagesCount(transactionsCount);

    const isHeaderDisabled = !hasData(transactions);
    const tableContent = !transactionsCount && hasData(transactions) ?
        <EmptyState className={cn(null, [className])} /> :
        <div className={cn(null, [className])}>
            <TableTransactionsHeaderLite
                className={cn('Header')}
                companyId={companyId}
                defaultFilters={defaultFilters}
                disabled={isHeaderDisabled}
                onFiltersChange={handleFiltersChange}
            />
            <TableTransactionsLiteRemote
                data={transactions}
                errorFallbackClassName={cn('ErrorFallback')}
                pageCount={pagesCount}
                pageIndex={currentPage}
                pageSize={pageSize}
                skeleton={
                    <TableSkeleton
                        className={cn('Skeleton')}
                        columns={7}
                        rows={10}
                    />
                }
            />
        </div>;

    return (
        <>
            {tableContent}
            {pagesCount > 0 && <Pagination
                {...paginationProps}
                className={cn('Pagination')}
                pagesCount={pagesCount}
            />}
        </>
    );
};

TableTransactionsContainerLite.displayName = cn();
