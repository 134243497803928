import { EntryRestrictions } from '../RestrictionsDecoder';

type GetRestrictionText = (
    key: keyof EntryRestrictions,
    restrictionObject: EntryRestrictions,
) => string;

export const getAdditionalText: GetRestrictionText = (key, restrictionObject) => {
    return restrictionObject[key].additions[0]?.data[0].data.text.toLowerCase();
};
