export const ru = {
    role: 'Роль',
    access: 'Права доступа',
    assistant: 'Ассистент',
    user: 'Сотрудник',
    coordinator: 'Координатор',
    activated: 'Открыт',
    blocked: 'Закрыт',
    add_employee: 'Добавить сотрудника',
};
