export const en = {
    direct_flight: 'Direct flight',
    transfers: {
        one: '{count} transfer',
        some: '{count} transfers',
        many: '{count} transfers',
        none: 'Direct',
    },
    night_transfer: 'Night transfer in {city}',
    transfer_in: 'Transfer in {city}',
};
