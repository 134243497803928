import { Skeleton } from '@yandex-int/hr-components/Skeleton';

import { cn } from '../FlightInfoFareFamilyOption.cn';

import './FlightInfoFareFamilyOptionSkeleton.css';

export const FlightInfoFareFamilyOptionSkeleton = () => {
    return (
        <div className={cn(null, ['FlightInfoFareFamilyOptionSkeleton'])}>
            <div>
                <Skeleton className={cn('SkeletonTitle')} size="m" />
                <Skeleton className={cn('SkeletonSubtitle')} size="s" />
                <Skeleton className={cn('SkeletonSubtitle2')} size="s" />
            </div>
            <Skeleton size="m" type="control" />
        </div>
    );
};
