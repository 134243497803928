import * as D from 'io-ts/Decoder';

export const RouteUntyped = D.type({
    params: D.type({}),
    search: D.intersect(
        D.type({}),
    )(
        D.partial({}),
    ),
});
