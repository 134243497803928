import { Card } from 'components/Card';
import { FormTripCreate } from 'components/FormTripCreate';
import { HeaderPage } from 'components/HeaderPage';
import { Page } from 'components/Page';
import { SupportChat } from 'components/SupportChat';
import { useMeta } from 'hooks/useMeta';
import { RouteTripCreate } from 'routes/RouteTripCreate';
import { Container } from 'shared/ui/Container';

import { cn } from './PageTripCreate.cn';
import { i18n } from './PageTripCreate.i18n';

import './PageTripCreate.css';

export const PageTripCreate: Page<RouteTripCreate> = () => {
    const meta = useMeta();

    return (
        <Page className={cn()}>
            <HeaderPage title={i18n('new_application')} />
            <Container className={cn('Container')}>
                <Card className={cn('Form')}>
                    <FormTripCreate user={meta.user} />
                </Card>
                <div className={cn('Aside')}>
                    <SupportChat />
                </div>
            </Container>
        </Page>
    );
};

PageTripCreate.displayName = cn();
