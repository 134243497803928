export const ru = {
    add_hotel: 'Добавить отель',
    accommodation: 'Проживание',
    placeholder_hotel_tip: 'Если услуга не нужна, нажмите «Отель не нужен». Это решение можно будет изменить',
    placeholder_transfer_tip: 'Если услуга не нужна, нажмите «Билет не нужен». Это решение можно будет изменить',
    no_hotel_needed: 'Отель не нужен',
    no_transfer_needed: 'Билет не нужен',
    no_hotel: 'Вы сами организовываете проживание',
    no_hotel_tip: 'Для компенсации обратитесь к координатору вашей компании',
    no_transfer: 'Вы добираетесь сами',
    no_transfer_tip: 'Для компенсации обратитесь к координатору вашей компании',
    hotel_not_added: 'Отель не добавлен',
    transfer_not_added: 'Билет не добавлен',
    avia: 'Авиа',
    avia_round_trip: 'Авиа туда-обратно',
    hotel: 'Отель',
    rail: 'Ж/д',
    need_transfer: 'Билет нужен',
    need_hotel: 'Отель нужен',
    fix_application_date: 'Не получается создать поиск, так как даты заявки в прошедшем времени, поправьте их',
    yes_understand: 'Да, понятно',
    good: 'Хорошо',
};
