import { FC, memo, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useExtPersonList } from 'components/PageProfile/TabCompanions/TabCompanions.hooks/useExtPersonList';
import { withRemote } from 'hocs/withRemote';
import { i18nAction } from 'i18n/i18nAction';
import { RouteCompanionAdd } from 'routes/RouteCompanionAdd';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { addToast } from 'utils/addToast';

import { CompanionList } from './CompanionList';
import { cn } from './CompanionsAdd.cn';
import { i18n } from './CompanionsAdd.i18n';
import { CompanionsAddProps } from './CompanionsAdd.types';
import { EmptyCompanions } from './EmptyCompanions';

import './CompanionsAdd.css';

/**
 * Список компаньонов для добавления в поездку
*/

export const CompanionsAddBasic: FC<CompanionsAddProps> = memo(props => {
    const {
        className,
        companionsInSearch,
        cancel,
        companions,
        createService,
        progress,
    } = props;

    const history = useHistory();

    const [sendList, setSendList] = useState(new Set<string>());
    const createCompanions = useCallback(() => {
        history.push(RouteCompanionAdd.getPath({}, {}));
    }, [history]);

    const addCompanions = useCallback(async() => {
        try {
            await createService([...sendList]);
        } catch (err) {
            addToast({ title: i18n('add_service_error_message') });
        }
    }, [createService, sendList]);

    if (!companions.length) {
        return (
            <EmptyCompanions cancel={cancel} className={className} createCompanions={createCompanions} />
        );
    }

    return (
        <div className={cn(null, [className])}>
            <Text
                className={cn('Title')}
                color="primary"
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <Text
                className={cn('Description')}
                color="secondary"
                typography="body-s"
            >
                {i18n('description')}
            </Text>

            <CompanionList
                companions={companions}
                companionsInSearch={companionsInSearch}
                sendList={sendList}
                setSendList={setSendList}
            />

            <div className={cn('Buttons')}>
                <Button
                    size="m"
                    view="outline"
                    onClick={cancel}
                >
                    {i18nAction('cancel')}
                </Button>
                <Button
                    progress={progress}
                    size="m"
                    view="primary"
                    onClick={addCompanions}
                >
                    {i18nAction('continue')}
                </Button>
            </div>

        </div>
    );
});

const CompanionsAddWithRemote = withRemote(CompanionsAddBasic);

export const CompanionsAdd: FC<Omit<CompanionsAddProps, 'companions'>> = props => {
    const companions = useExtPersonList();

    return <CompanionsAddWithRemote {...props} companions={companions} />;
};

CompanionsAdd.displayName = cn();
