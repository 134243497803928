import { FC, useCallback } from 'react';
import { Platform } from '@yandex-int/hr-components/utils';
import { useSwiper } from 'swiper/react';

import { Button } from 'shared/ui/Button';
import { ModalLayout } from 'shared/ui/ModalLayout';
import { Text } from 'shared/ui/Text';

import { cn } from '../ExecuteTripButton.cn';
import { i18n } from '../ExecuteTripButton.i18n';
import { TripHolidaysScenario } from '../ExecuteTripButton.types';

import { InfoSlideProps } from './InfoSlide.types';

import './InfoSlide.css';

export const InfoSlide: FC<InfoSlideProps> = props => {
    const {
        onClose,
        setScenario,
        platform,
    } = props;

    const swiper = useSwiper();

    const onCancelClick = useCallback(() => {
        setScenario(TripHolidaysScenario.NOT_WORKING_ON_HOLIDAYS);
        swiper.slideTo(1);
    }, [setScenario, swiper]);

    const onNextClick = useCallback(() => {
        setScenario(TripHolidaysScenario.WORKING_ON_HOLIDAYS);
        swiper.slideTo(1);
    }, [setScenario, swiper]);

    return (
        <ModalLayout
            actions={[
                <Button
                    key="cancel"
                    size={platform === Platform.DESKTOP ? 'm' : 'l'}
                    view="default"
                    onClick={onCancelClick}
                >
                    {i18n('will_chill')}
                </Button>,
                <Button
                    key="next"
                    size={platform === Platform.DESKTOP ? 'm' : 'l'}
                    view="default"
                    onClick={onNextClick}
                >
                    {i18n('will_work')}
                </Button>,
            ]}
            className={cn('InfoSlide')}
            platform={platform}
            title={i18n('working_holidays_modal_title')}
            onClose={onClose}
        >
            <Text typography="body-s">
                {i18n('working_holidays_1')}
            </Text>
            <Text typography="title-s">
                {i18n('subheader_working_holidays_1')}
            </Text>
            <Text as="ul" typography="body-s">
                {i18n('recommendations_working_holidays').split('\n').map((item, index) => (
                    <li key={index}>
                        {item}
                    </li>
                ))}
            </Text>
        </ModalLayout>
    );
};
