import { cloneElement, FC, MouseEvent, useRef } from 'react';
import { useToggle } from 'react-use';

import {
    getIconProvider,
    IconArrowShortDown,
    IconArrowShortUp,
} from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { Drawer } from 'shared/ui/Drawer';

import { getHandlers } from './Dropdown.utils/getHandlers';
import { Popup } from './Popup/Popup';
import { cn } from './Dropdown.cn';
import { DropdownProps } from './Dropdown.types';

import './Dropdown.css';

const iconArrowDownProvider = getIconProvider(IconArrowShortDown);
const iconArrowUpProvider = getIconProvider(IconArrowShortUp);

/**
 * Адаптивный дропдаун ( drawer / popup )
 */
export const Dropdown: FC<DropdownProps> = props => {
    const {
        children,
        showIcon,
        drawerProps,
        popupProps,
        disabled,
        triggerElement,
        stopPropagation = false,
        desktopTrigger = 'click',
        className,
        shrink = true,
        isPopupVisible: isPopupVisibleExt,
        togglePopupVisible: togglePopupVisibleExt,
        ...rest
    } = props;

    const { isMobile } = usePlatform();
    const [visibleInt, toggleVisibleInt] = useToggle(false);

    const isVisible = isPopupVisibleExt !== undefined ? isPopupVisibleExt : visibleInt;
    const toggleVisible = togglePopupVisibleExt !== undefined ? togglePopupVisibleExt : toggleVisibleInt;

    const AdaptivePopup = isMobile ? Drawer : Popup;

    const anchorRef = useRef<HTMLDivElement>(null);
    const buttonIcon = isVisible ? iconArrowUpProvider : iconArrowDownProvider;

    const handlers = getHandlers({ desktopTrigger, isMobile, toggleVisible, disabled });

    const stopPropagationFn = (e: MouseEvent) => {
        if (stopPropagation) {
            e.stopPropagation();
            e.preventDefault();
        }
    };

    const triggerWithProps = cloneElement(triggerElement, {
        ...(showIcon && { iconRight: buttonIcon }),
    });

    return (
        <div className={cn({ shrink }, [className])} {...rest} onClick={stopPropagationFn}>
            <div {...handlers} ref={anchorRef}>
                {triggerWithProps}
            </div>
            <AdaptivePopup
                anchorRef={anchorRef}
                drawerProps={drawerProps}
                popupProps={popupProps}
                view="default"
                visible={isVisible}

                onClose={toggleVisible}
            >
                {children}
            </AdaptivePopup>
        </div>
    );
};

Dropdown.displayName = cn();
