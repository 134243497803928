import { Employee } from 'services/SwaggerApi';

export type FieldPersonValue = {
    id: string;
    title: string;
    login: string;
} | undefined;

type SerializePerson = (person: FieldPersonValue) => NonNullable<FieldPersonValue>['id'] | undefined;
type MapApproverToFieldPersonValue = (approver: Employee['approver']) => FieldPersonValue;

/**
 * Сериализирует пользователя – достаёт id.
 * @param person
 * @returns id пользователя
 */
export const serializePerson: SerializePerson = person => {
    return person?.id;
};

export const mapApproverToFieldPersonValue: MapApproverToFieldPersonValue = approver => {
    if (!approver) return undefined;

    return {
        id: approver.approver_id,
        title: `${approver.first_name} ${approver.last_name}`,
        login: '-',
    };
};
