import { FC, useCallback, useMemo } from 'react';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';
import { ButtonGroup } from '@yandex-lego/components/ButtonGroup/desktop/bundle';

import { Text } from 'shared/ui/Text';
import { getTypedI18nLang } from 'utils/i18n';

import { cn } from './FilterFieldTime.cn';
import { FilterFieldTimeOptionProps, FilterFieldTimeProps } from './FilterFieldTime.types';

import './FilterFieldTime.css';

const FilterFieldTimeOption: FC<FilterFieldTimeOptionProps> = props => {
    const { time, onClick, checked } = props;
    const lang = getTypedI18nLang();
    const handleClick = useCallback(() => onClick(time.target_id), [onClick, time.target_id]);

    return (
        <div key={time.target_id} className={cn('TimeOption')}>
            <Button
                checked={checked}
                className={cn('Checkbox')}
                data-code={time.target_id}
                size="m"
                view="default"
                onClick={handleClick}
            >
                {time.name[lang]}
            </Button>
            <Text className={cn('OptionTime')} color="secondary" typography="body-s">
                {time.hours}
            </Text>
        </div>
    );
};

export const FilterFieldTime: FC<FilterFieldTimeProps> = props => {
    const { value: selected, onChange } = props.input;

    const handleChange = useCallback((code: string) => {
        const codeIndex = selected.indexOf(code);

        if (codeIndex === -1) return onChange([...selected, code]);

        return onChange([
            ...selected.slice(0, codeIndex),
            ...selected.slice(codeIndex + 1),
        ]);
    }, [selected, onChange]);

    const selectedIndexes = useMemo(
        () => selected.map(value => props.time.findIndex(({ target_id }) => target_id === value)),
        [props.time, selected],
    );

    return (
        <ButtonGroup
            className={cn(null, [props.className])}
            gap="s"
            pin="round"
            selected={selectedIndexes}
        >
            {props.time.map(time => (
                <FilterFieldTimeOption
                    key={time.target_id}
                    checked={selected.includes(time.target_id)}
                    time={time}
                    onClick={handleChange}
                />
            ))}
        </ButtonGroup>
    );
};

FilterFieldTime.displayName = cn();
