import i18nFactory, { I18nOptions } from '@yandex-int/i18n';

export function typedI18nFactory<Ten, Tru>(keyset: { ru: Tru; en: Ten }) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return i18nFactory(keyset as any) as (
        key: keyof Tru & keyof Ten,
        options?: I18nOptions
    ) => string;
}
export type I18nKeys<T extends ReturnType<typeof typedI18nFactory>> = Parameters<T>[0];
