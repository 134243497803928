import * as z from 'zod';

import { ActivePage } from 'shared/constants/ActivePage';

import { route } from './route';

export const RouteCompanionAddDecoder = z.object({
    params: z.object({}),
    search: z.object({}),
});

export type RouteCompanionAdd = z.output<typeof RouteCompanionAddDecoder>;

export const RouteCompanionAdd = route(
    '/companion/add',
    RouteCompanionAddDecoder,
    {
        activePage: ActivePage.PROFILE,
    },
);
