import { useCallback, useMemo, useState } from 'react';
import { RadioButton } from '@yandex-lego/components/RadioButton/desktop/bundle';

import { Card } from 'components/Card';
import { HeaderPage } from 'components/HeaderPage';
import { getIconProvider, IconArrowShortLeft } from 'components/Icon';
import { MessageBanner } from 'components/MessageBanner';
import { Page } from 'components/Page';
import { ScrollButton } from 'components/ScrollButton';
import { SearchFilter } from 'components/SearchFilter';
import { SearchFilters } from 'components/SearchFilters';
import { SearchMain } from 'components/SearchMain';
import { Spinner } from 'components/Spinner';
import { SupportChat } from 'components/SupportChat';
import { SupportChatLink } from 'components/SupportChatLink';
import { withRemote } from 'hocs/withRemote';
import { usePersonTrip } from 'hooks/usePersonTrip';
import { usePlatform } from 'hooks/usePlatform';
import { useSearchStatusSuccess } from 'hooks/useSearchStatusSuccess';
import { isIOS } from 'platform/utils';
import { RoutePersonTrip } from 'routes/RoutePersonTrip';
import { RouteSearch } from 'routes/RouteSearch';
import { ACServiceType } from 'services/AeroClubApi/ACServiceType';
import { Button } from 'shared/ui/Button';
import { Container } from 'shared/ui/Container';
import { Text } from 'shared/ui/Text';
import { isFailure, isPending, isSuccess } from 'utils/Loadable';

import { PageSearchCount } from './Count/PageSearch-Count';
import { PageSearchResults } from './Results/PageSearch-Results';
import { cn } from './PageSearch.cn';
import { i18n } from './PageSearch.i18n';
import { RadioOption, SearchLayoutType } from './PageSearch.types';

import './PageSearch.css';

const SearchFilterRemote = withRemote(SearchFilter);
const iconLeft = getIconProvider(IconArrowShortLeft);

export const PageSearch: Page<RouteSearch> = props => {
    const options: RadioOption<SearchLayoutType>[] = [
        { value: 'list', children: i18n('list') },
        { value: 'map', children: i18n('map') },
    ];
    const { params, search } = props;
    const { searchId } = params;
    const { trip_id, person_id, type } = search;
    const [selectedType, setSelectedType] = useState<ACServiceType>(search.type);
    const { isMobile, isDesktop } = usePlatform();

    const showSupportChat = !isIOS();

    const [value, setValue] = useState<SearchLayoutType>('list');
    const handleToggleLayout = useCallback(event => setValue(event.target.value as SearchLayoutType), [setValue]);

    const {
        isSearchCompleted,
        isSearchFailed,
        resetLastSearch,
    } = useSearchStatusSuccess({ searchId, type, person_id, trip_id });

    const personTrip = usePersonTrip(trip_id ?? 0, person_id, { isEnabled: trip_id !== undefined });

    const filtersDisabled = !isSearchCompleted;
    const view = selectedType !== 'Hotel' ? 'list' : value;

    const makeNewSearchText = useMemo(() => {
        const isTypeChanged = selectedType !== search.type;

        if (isTypeChanged) {
            return i18n('press_search');
        }

        if (isSearchFailed || isFailure(personTrip)) {
            return (
                <>
                    {i18n('search_failed')}
                    <br />
                    <SupportChatLink firstLetterUppercase />
                </>
            );
        }
    }, [isSearchFailed, search.type, selectedType, personTrip]);

    const showFilters = isDesktop && isSearchCompleted;
    const showMapToggle = isDesktop && search.type === 'Hotel';
    const personTripUrl = RoutePersonTrip.getPath({ tripId: trip_id || 0, personId: person_id }, {});
    const showPersonTripButton = Boolean(trip_id);
    const isPersonTripLoading = (trip_id !== undefined) && isPending(personTrip);
    const canAddServices = (trip_id !== undefined) && isSuccess(personTrip) && personTrip.result.is_approved;

    return (
        <Page className={cn()}>
            <HeaderPage
                title={
                    <div className={cn('HeaderPaneTitle')}>
                        {showPersonTripButton && (
                            <div>
                                <Button
                                    iconLeft={iconLeft}
                                    size="s"
                                    type="link"
                                    url={personTripUrl}
                                    view="outline"
                                >
                                    {i18n('back_to_trip')}
                                </Button>
                            </div>
                        )}
                        <SearchMain
                            className={cn('SearchMain')}
                            person_id={person_id}
                            search={search}
                            tooltipText={makeNewSearchText}
                            onStartSearch={resetLastSearch}
                            onTypeChange={setSelectedType}
                        />
                    </div>
                }
            />
            {!trip_id && (
                <Container>
                    <MessageBanner messageTag="create_trip" />
                </Container>
            )}
            {trip_id && isSuccess(personTrip) && !personTrip.result.is_approved && (
                <Container>
                    <MessageBanner messageTag="wait_for_approval" />
                </Container>
            )}

            {makeNewSearchText ?
                (
                    <Container className={cn('PressSearch')}>
                        <Text
                            as="div"
                            className={cn('CreateTrip')}
                            typography="title-s"
                            weight="regular"
                        >
                            {makeNewSearchText}
                        </Text>
                    </Container>
                ) : (
                    <>
                        {(!isSearchCompleted || isPersonTripLoading) ? (
                            <Spinner showTips details={i18n('searchDetails')} message={i18n('searchMessage')} />
                        ) : (
                            <>
                                <Container className={cn('Header')}>
                                    <Text as="div" typography="title-m">
                                        {i18n('search_results')}
                                        {isSearchCompleted && <PageSearchCount query={search} searchId={searchId} />}
                                    </Text>
                                    {showMapToggle && (
                                        <RadioButton
                                            options={options}
                                            size="m"
                                            value={value}
                                            view="default"
                                            onChange={handleToggleLayout}
                                        />
                                    )}
                                    {isMobile && (
                                        <SearchFilterRemote
                                            className={cn('SearchFilter')}
                                            disabled={filtersDisabled}
                                            params={params}
                                            search={search}
                                        />
                                    )}
                                </Container>
                                <Container className={cn('Container', { showFilters })}>
                                    <PageSearchResults
                                        canAddServices={canAddServices}
                                        personId={person_id}
                                        query={search}
                                        searchId={searchId}
                                        tripId={trip_id}
                                        type={view}
                                    />

                                    {showFilters && (
                                        <div className={cn('Aside')}>
                                            <Card>
                                                <SearchFilters
                                                    params={params}
                                                    search={search}
                                                />
                                            </Card>

                                            {showSupportChat && <SupportChat />}
                                        </div>
                                    )}
                                </Container>
                            </>
                        )}
                    </>
                )}

            <ScrollButton />
        </Page>
    );
};

PageSearch.displayName = cn();
