import { FC, useCallback } from 'react';
import { Form } from 'react-final-form';

import { AddParticipantForm } from 'components/AddParticipantForm';
import { Confirm } from 'components/Confirm';
import { ModalView } from 'components/ModalView';
import { ViewPersonTripChangeDate } from 'components/ViewPersonTripChangeDate';
import { ViewTimeline } from 'components/ViewTimeline';
import { withModalView } from 'hocs/withModalView';
import { withRemote } from 'hocs/withRemote';
import { useCancelPersonTrip } from 'hooks/useCancelPersonTrip';
import { PersonTrip } from 'services/SwaggerApi';
import { ModalPlatform } from 'shared/ui/ModalPlatform';

import { cn } from '../TripInfoPersonHeader.cn';
import { i18n } from '../TripInfoPersonHeader.i18n';

const ViewPersonTripChangeDateRemote = withRemote(ViewPersonTripChangeDate);

type ModalsProps = {
    visibleModalDateChange: boolean;
    toggleModalDateChange: VoidFunction;
    visibleConfirmation: boolean;
    toggleConfirmationVisible: VoidFunction;
    visibleTimelineModal: boolean;
    toggleVisibleTimelineModal: VoidFunction;
    setVisibleAddParticipantModal: Function;
    visibleAddParticipantModal: boolean;
    tripId: number;
    personTrip: PersonTrip;
    personId: string;

};

const TimelineModal = withModalView(ViewTimeline);

export const Modals: FC<ModalsProps> = props => {
    const {
        visibleModalDateChange,
        toggleModalDateChange,
        visibleConfirmation,
        toggleConfirmationVisible,
        visibleTimelineModal,
        toggleVisibleTimelineModal,
        setVisibleAddParticipantModal,
        visibleAddParticipantModal,
        tripId,
        personTrip,
        personId,
    } = props;

    const cancelMessage = personTrip.need_warning_on_cancel ? i18n('cancel_confirmation_with_services') : undefined;

    const [cancel] = useCancelPersonTrip();
    const canEditPersonTripRoute = personTrip.actions?.edit && Boolean(personTrip.route?.length);

    const handleCloseModal = useCallback(() => {
        setVisibleAddParticipantModal(false);
    }, [setVisibleAddParticipantModal]);

    const handleCancel = useCallback(values => {
        cancel(tripId, personId, { cancellation_reason: values.reason });
        toggleConfirmationVisible();
    }, [tripId, personId, cancel, toggleConfirmationVisible]);

    const getConfirmForm = useCallback(({ handleSubmit }) => (
        <Confirm
            cancelText={i18n('cancel')}
            className={cn('CancelTripConfirmationModal')}
            confirmText={i18n('confirm')}
            message={cancelMessage}
            reason={i18n('cancel_confirmation_reason')}
            title={i18n('cancel_confirmation')}
            visible={visibleConfirmation}
            onCancel={toggleConfirmationVisible}
            onConfirm={handleSubmit}
        />
    ), [cancelMessage, visibleConfirmation, toggleConfirmationVisible]);

    return (
        <>
            {visibleConfirmation &&
                <Form
                    render={getConfirmForm}
                    onSubmit={handleCancel}
                />
            }
            <TimelineModal
                modalViewOnClose={toggleVisibleTimelineModal}
                modalViewVisible={visibleTimelineModal}
                personTrip={personTrip}
                trip_id={tripId}
                onClose={toggleVisibleTimelineModal}
            />
            <ModalView
                autoFocus
                className={cn('Modal')}
                visible={visibleAddParticipantModal}
                onClose={handleCloseModal}
            >
                <AddParticipantForm
                    className={cn('AddParticipantForm')}
                    tripId={tripId}
                    onCancel={handleCloseModal}
                    onSubmit={handleCloseModal}
                />
            </ModalView>
            {canEditPersonTripRoute && (
                <ModalPlatform
                    toggleVisible={toggleModalDateChange}
                    visible={visibleModalDateChange}
                    onClose={toggleModalDateChange}
                >
                    <ViewPersonTripChangeDateRemote
                        className={cn('EditTripDatesModal')}
                        personTrip={personTrip}
                        tripId={tripId}
                        onCancel={toggleModalDateChange}
                        onSubmit={toggleModalDateChange}
                    />
                </ModalPlatform>
            )}
        </>
    );
};
