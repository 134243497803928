import { FC, useCallback, useMemo } from 'react';

import { Checkbox } from 'shared/ui/Checkbox';

import { cn } from './FilterFieldCheckbox.cn';
import { FilterFieldCheckboxProps } from './FilterFieldCheckbox.types';

export const FilterFieldCheckbox: FC<FilterFieldCheckboxProps> = props => {
    const { activeValue, label, title } = props;
    const { value: active, onChange } = props.input;
    const checked = useMemo(() => {
        if (typeof active === 'boolean') return active;

        if (Array.isArray(active)) {
            return active.includes(activeValue);
        }

        return Boolean(active);
    }, [active, activeValue]);

    const handleChange = useCallback(event => {
        event.stopPropagation();

        if (activeValue) {
            if (Array.isArray(active)) {
                return onChange(checked ?
                    active.filter(value => value !== activeValue) :
                    [...active, activeValue],
                );
            }

            return onChange(checked ? undefined : activeValue);
        }

        return onChange(!checked);
    }, [activeValue, onChange, checked, active]);

    return (
        <Checkbox
            checked={checked}
            className={cn(null, [props.className])}
            label={label}
            lines="one"
            size="m"
            title={title || label}
            view="outline"
            onChange={handleChange}
        />
    );
};

FilterFieldCheckbox.displayName = cn();
