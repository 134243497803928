import { ComponentProps, MouseEvent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { compose, composeU } from '@bem-react/core';
import { withViewBrand } from '@yandex-int/hr-components/Button/_view/Button_view_brand';
import { withViewContrast } from '@yandex-int/hr-components/Button/_view/Button_view_contrast';
import { withViewGhost } from '@yandex-int/hr-components/Button/_view/Button_view_ghost';
import { withViewMedia } from '@yandex-int/hr-components/Button/_view/Button_view_media';
import { withViewOutline } from '@yandex-int/hr-components/Button/_view/Button_view_outline';
import { withViewPrimary } from '@yandex-int/hr-components/Button/_view/Button_view_primary';
import { withViewSecondary } from '@yandex-int/hr-components/Button/_view/Button_view_secondary';
import { ILoggableProps, logClick, withBaobab } from '@yandex-int/react-baobab-logger';
import {
    Button as ButtonPresenter,
    cnButton,
    withPinBrickRound,
    withPinCircleCircle,
    withSizeL,
    withSizeM,
    withSizeS,
    withTypeLink,
    withTypeSubmit,
    withViewClear,
    withViewDefault,
    withViewLink,
    withViewPseudo,
    withViewRaised,
    withWidthAuto,
    withWidthMax,
} from '@yandex-lego/components/Button';
import { ContainerElement } from '@yandex-lego/components/Button/desktop/bundle';
import { Spin } from '@yandex-lego/components/Spin/bundle';

import { withDefaultProps } from 'hocs/withDefaultProps';
import { i18nErrors } from 'i18n/i18nErrors';

import { withViewDanger } from './_view/Button_view_danger';

import './Button.css';

const ButtonBase = compose(
    composeU(
        composeU(
            withViewClear,
            withViewDefault,
            withViewLink,
            withViewPseudo,
            withViewRaised,
            withViewDanger,
        ),
        composeU(
            withViewBrand,
            withViewContrast,
            withViewGhost,
            withViewMedia,
            withViewOutline,
            withViewPrimary,
            withViewSecondary,
        ),
    ),
    composeU(
        withSizeS,
        withSizeM,
        withSizeL,
    ),
    composeU(
        withWidthAuto,
        withWidthMax,
    ),
    composeU(
        withTypeLink,
        withTypeSubmit,
    ),
    composeU(
        withPinBrickRound,
        withPinCircleCircle,
    ),
)(ButtonPresenter);

// Настройки для Baobab
const baobabButtonOptions = {
    name: 'Button',
    events: {
        onFocus: logClick({ type: 'focus' }),
        onBlur: logClick({ type: 'blur' }),
        onClick: logClick({ type: 'click' }),
    },
};

type AppButtonProps =
    & ComponentProps<typeof ButtonBase>
    & ({ type: 'link'; url: string } | { type?: 'submit'; url?: string })
;

const spinIconProvider = (className: string) => (
    <div className={cnButton('Spinner', [className])}>
        <Spin
            progress
            size="xxs"
            view="default"
        />
    </div>
);

const AppButton = (props: AppButtonProps) => {
    const { type, url, target, onClick, children, ...restButtonProps } = props;

    if ((type === 'link' && url === undefined) || (type !== 'link' && url !== undefined)) {
        // Если указан url, но не указан type="link" или наоборот, то это скорее всего ошибка
        throw new Error(i18nErrors('incorrect_data_format'));
    }

    const isLink = type === 'link' && url;
    const isExternalLink = type === 'link' && url?.startsWith('https://') || target === '_blank';
    const modifiedTarget = (url && isExternalLink) ? '_blank' : target;
    const { push } = useHistory();

    const handleLinkClick = useCallback((e: MouseEvent) => {
        e.stopPropagation();

        const isModifierPressed = e.altKey || e.ctrlKey || e.shiftKey || e.metaKey;

        if (isExternalLink || isModifierPressed) return;

        e.preventDefault();
        if (url) push(url);
    }, [isExternalLink, push, url]);

    const handleButtonClick = useCallback((e: MouseEvent<ContainerElement>) => {
        if (!onClick || type === 'submit') return;

        onClick(e);
    }, [onClick, type]);

    const handleClick = isLink ? handleLinkClick : handleButtonClick;

    const { view, progress } = restButtonProps;
    let { icon, iconLeft } = restButtonProps;

    if (progress && view !== 'brand') {
        if (icon) {
            icon = spinIconProvider;
        } else {
            iconLeft = spinIconProvider;
        }
    }

    return (
        <ButtonBase
            target={modifiedTarget}
            type={type}
            url={url}
            onClick={handleClick}
            {...restButtonProps}
            icon={icon}
            iconLeft={iconLeft}
        >
            {children}
        </ButtonBase>
    );
};

// Это основной компонент с Baobab, по умолчанию используем его
export type Button = ILoggableProps<AppButtonProps>;
export const Button = withBaobab<AppButtonProps>(baobabButtonOptions)(AppButton);

export const ButtonDefaultM = withDefaultProps<Button>({
    size: 'm',
    view: 'default',
})(Button);

export const ButtonClearM = withDefaultProps<Button>({
    size: 'm',
    view: 'clear',
})(Button);

export const ButtonClearL = withDefaultProps<Button>({
    size: 'l',
    view: 'clear',
})(Button);

export const ButtonActionM = withDefaultProps<Button>({
    size: 'm',
    view: 'brand',
})(Button);

export const ButtonActionL = withDefaultProps<Button>({
    size: 'l',
    view: 'brand',
})(Button);

export const ButtonDefaultL = withDefaultProps<Button>({
    size: 'l',
    view: 'default',
})(Button);

export const ButtonSelect = withDefaultProps<Button>({
    size: 'm',
    view: 'default',
    width: 'max',
})(Button);
