export const en = {
    approved: 'Approved',
    cancelled: 'Cancelled',
    closed: 'Completed',
    draft: 'Draft',
    executed: 'Executed',
    executing: 'Booking in progress',
    under_approval: 'Pending approval',
    verification: 'Confirmation',
    new: 'New',
};
