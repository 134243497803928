import { FetchError } from 'errors';
import { TravelPolicyDetailed } from 'services/SwaggerApi';
import { RemoteData } from 'utils/Loadable';

import { createUseQueryHook } from './createUseQueryHook';

type UseTravelPolicyDetails = (travelPolicyId: number) => RemoteData<TravelPolicyDetailed, FetchError>;

export const useTravelPolicyDetails: UseTravelPolicyDetails = travelPolicyId => {
    return createUseQueryHook('get_travel_policy_detailed', {
        cacheTime: Infinity,
    })(travelPolicyId, {});
};
