import * as D from 'io-ts/Decoder';

import { config } from 'config';
import { ActivePage } from 'shared/constants/ActivePage';
import { checkAccessFactory } from 'utils/checkAccessFactory';
import { ToNumber } from 'utils/io-ts/decoders';

import { route } from './route';

const { isB2B } = config;

export const RouteFinanceDecoder = D.type({
    params: D.type({
        companyId: ToNumber,
    }),
    search: D.type({}),
});

export type RouteFinance = D.TypeOf<typeof RouteFinanceDecoder>;

const checkAccess = checkAccessFactory(
    meta => Boolean(isB2B && (meta.is_coordinator || meta.is_yandex_employee || meta.is_support)),
    isB2B ? 'forbidden' : 'not_found',
);

export const RouteFinance = route(
    '/company/:companyId(\\d+)/finance/',
    RouteFinanceDecoder,
    {
        isAdminPanel: true,
        activePage: ActivePage.FINANCE,
        checkAccess,
    },
);
