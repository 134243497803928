import { FC } from 'react';

import { ErrorState } from 'shared/ui/ErrorState';

import { cn } from './EmptyState.cn';
import { EmptyStateProps } from './EmptyState.types';

export const EmptyState: FC<EmptyStateProps> = props => {
    const {
        className,
        ...rest
    } = props;

    return <ErrorState className={cn(null, [className])} {...rest} />;
};

EmptyState.displayName = cn();
