import { FC, useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { Select } from '@yandex-int/hr-components/Select/desktop/bundle';

import { getIconProvider, IconClose, IconPlus } from 'components/Icon';
import { LocationType, MaxPriceRule } from 'services/SwaggerApi';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { Textinput } from 'shared/ui/Textinput';

import { DirectionSuggest } from '../DirectionSuggest';
import { DirectionItem } from '../DirectionSuggest/DirectionSuggest.types';
import { cn } from '../FormTravelPolicy.cn';
import { i18n } from '../FormTravelPolicy.i18n';
import { getDirectionOptions } from '../FormTravelPolicy.utils/getFormOptions';

import {
    AdditionalConditionsProps,
    ConditionItemProps,
    HandleChangeLocation,
    HandleChangeLocationType,
    HandleChangeMaxPrice,
} from './AdditionalConditions.types';

import './AdditionalConditions.css';

const iconProviderClose = getIconProvider(IconClose);
const iconProviderPlus = getIconProvider(IconPlus);

const newRowData: MaxPriceRule = {
    max_price: 5000,
    location_type: 'country',
    location_id: null,
    location_name: null,
};

const ConditionItem: FC<ConditionItemProps> = props => {
    const {
        index,
        field,
        name,
        handleChangeMaxPrice,
        handleChangeLocation,
        handleChangeLocationType,
        handleRemoveRow,
    } = props;

    const changeMaxPrice = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            handleChangeMaxPrice(Number(event.target.value), index);
        },
        [handleChangeMaxPrice, index],
    );

    const changeLocationType = useCallback(
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            handleChangeLocationType(event.target.value as LocationType, index);
        },
        [handleChangeLocationType, index],
    );

    const changeLocation = useCallback(
        (value: DirectionItem) => {
            handleChangeLocation(value, index);
        },
        [handleChangeLocation, index],
    );

    const removeRow = useCallback(() => {
        handleRemoveRow(index);
    }, [handleRemoveRow, index]);

    const directionValue = {
        id: field.location_id,
        name: field.location_name,
        title: field.location_name,
    };

    return (
        <div className={cn('Condition')}>
            <div className={cn('ConditionContent')}>
                <Text typography="body-s">{i18n('no_more_expensive')}</Text>
                <Textinput
                    className={cn('ConditionPriceField')}
                    data-testId={`${name}-${index}-price`}
                    inputMode="numeric"
                    min={0}
                    name="max_price"
                    size="s"
                    type="number"
                    value={field.max_price || 0}
                    view="default"
                    onBlur={changeMaxPrice}
                />
                <Text typography="body-s">{i18n('for_direction')}</Text>
                <div className={cn('ConditionDirection')}>
                    <Select
                        className={cn('DirectionSelect')}
                        iconProps={{ glyph: 'arrowShortDown' }}
                        name="location_type"
                        options={getDirectionOptions()}
                        size="s"
                        value={field.location_type}
                        view="outline"
                        width="max"
                        onChange={changeLocationType}
                    />
                    <DirectionSuggest
                        dataTestId={`${name}-${index}-suggest`}
                        type={field.location_type as LocationType}
                        value={directionValue as DirectionItem}
                        onChange={changeLocation}
                    />
                </div>
            </div>
            <Button icon={iconProviderClose} size="s" view="clear" onClick={removeRow} />
        </div>
    );
};

export const AdditionalConditions: FC<AdditionalConditionsProps> = props => {
    const { methods, name } = props;

    const { fields, append, remove, update } = useFieldArray({
        control: methods.control,
        name: name,
    });

    const handleAddRow = useCallback(() => {
        append({ ...newRowData });
    }, [append]);

    const handleChangeMaxPrice: HandleChangeMaxPrice = useCallback(
        (value, rowIndex) => {
            update(rowIndex, {
                location_type: fields[rowIndex].location_type,
                location_name: fields[rowIndex].location_name,
                location_id: fields[rowIndex].location_id,
                max_price: Number(value),
            });
        },
        [fields, update],
    );

    const handleChangeLocationType: HandleChangeLocationType = useCallback(
        (value, rowIndex) => {
            update(rowIndex, {
                location_type: value,
                location_name: null,
                location_id: null,
                max_price: fields[rowIndex].max_price,
            });
        },
        [fields, update],
    );

    const handleChangeLocation: HandleChangeLocation = useCallback(
        (value, rowIndex) => {
            update(rowIndex, {
                location_name: value.name || value.title,
                location_id: value.id,
                location_type: fields[rowIndex].location_type,
                max_price: fields[rowIndex].max_price,
            });
        },
        [fields, update],
    );

    return (
        <div className={cn('Conditions')}>
            <div className={cn('ConditionsLabel')}>
                <Text typography="title-s" weight="medium">
                    {i18n('additional_conditions')}
                </Text>
            </div>
            {fields.length > 0 && (
                <div className={cn('ConditionsList')}>
                    {fields.map((field, index) => (
                        <ConditionItem
                            key={field.id}
                            field={field}
                            handleChangeLocation={handleChangeLocation}
                            handleChangeLocationType={handleChangeLocationType}
                            handleChangeMaxPrice={handleChangeMaxPrice}
                            handleRemoveRow={remove}
                            index={index}
                            name={name}
                        />
                    ))}
                </div>
            )}
            <div className={cn('ConditionsActions')}>
                <Button
                    data-testId={`${name}-add-button`}
                    iconLeft={iconProviderPlus}
                    size="m"
                    view="outline"
                    onClick={handleAddRow}
                >
                    {i18n('add_condition')}
                </Button>
                <Text typography="body-s">{i18n('for_certain_directions')}</Text>
            </div>
        </div>
    );
};
