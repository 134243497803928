export const en = {
    bed: 'Bedclothes',
    food: 'Food',
    wifi: 'Wifi',
    tv: 'TV',
    sanitary_set: 'Sanitary set',
    newspaper: 'Newspaper',
    air_conditioner: 'Air conditioner',
    bio_toilet: 'Bio toilet',
    blanket: 'Blanket',
    sink_in_compartment: 'Sink in compartment',
    shower_in_compartment: 'Shower in compartment',
    hygienic_shower: 'Hygienic shower',
    socket_220: 'Socket 220',
    slippers: 'Slippers',
    pets: 'Transportation of pets',
    baby: 'Seats for passengers with children',
    transfer: 'Transfer',
    restaurant_car: 'Restaurant car',
    luggage_compartment: 'Luggage compartment',
    shower_in_train: 'Shower in train',
    safe: 'Safe',
    improved_service: '',
    comfortable_waiting_room: 'Comfortable waiting room',
    multimedia_portal: 'Multimedia portal (movies, books, music)',
    hot_drinks: 'Hot drinks',
    socket_usb: 'Socket usb',
    sightseeing_service: 'Sightseeing service',
    panoramic_view: 'Panoramic view',
};
