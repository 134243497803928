import { Skeleton } from '@yandex-int/hr-components/Skeleton';

import { Card } from 'components/Card';

import { cn } from '../HotelDetails.cn';

import './Reviews.css';

const ReviewSkeleton = () => {
    return (
        <div className={cn('Review')}>
            <div className={cn('ReviewHeader')}>
                <Skeleton size="m" style={{ width: 120 }} />
                <div className={cn('ReviewInfo')}>
                    <Skeleton size="m" style={{ width: 96, margin: 0 }} />
                    <Skeleton size="m" style={{ width: 80, margin: 0 }} />
                </div>
            </div>
            <div>
                <Skeleton size="s" style={{ width: 320, display: 'block' }} />
                <Skeleton size="s" style={{ width: 320, display: 'block' }} />
                <Skeleton size="s" style={{ width: 240, display: 'block' }} />
            </div>
        </div>
    );
};

export const ReviewsSkeleton = () => {
    return (
        <Card>
            <div className={cn('Reviews')}>
                <ReviewSkeleton />
                <ReviewSkeleton />
            </div>
            <Skeleton size="m" style={{ width: 120, marginTop: 40 }} type="control" />
        </Card>
    );
};
