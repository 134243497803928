import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Service } from 'services/SwaggerApi';

import { DocumentSelectProps } from '../DocumentsActions.types';

/**
 * Выставляет документ как выбранный, если он был выбран в другой услуге
 */
export const useApplyDefaultDocument = (
    methods: UseFormReturn<DocumentSelectProps>,
    documentId: Service['document_id'],
) => {
    useEffect(() => {
        methods.reset({ document_id: documentId });
    }, [documentId, methods]);
};
