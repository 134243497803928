import { useCallback } from 'react';

import { i18nAction } from 'i18n/i18nAction';
import { addToast } from 'utils/addToast';

export const useCopyToClipboard = () => {
    const copyToClipboard = useCallback((copiedValue: string, title?: string) => {
        navigator.clipboard.writeText(copiedValue);

        const toastTitle = title || i18nAction('copied');

        addToast({ title: toastTitle });
    }, []);

    return { copyToClipboard };
};
