import { ComponentProps } from 'react';
import {
    compose,
    composeU,
} from '@bem-react/core';

import { withTypeBonus } from './_type/Suggest_type_bonus';
import { withTypeCities } from './_type/Suggest_type_cities';
import { withTypeCompanies } from './_type/Suggest_type_companies';
import { withTypeCountries } from './_type/Suggest_type_countries';
import { withTypeKonturCompanies } from './_type/Suggest_type_konturCompanies';
import { withTypePersons } from './_type/Suggest_type_persons';
import { withTypeTravelPolicies } from './_type/Suggest_type_travelPolicies';
import { Suggest as SuggestBase } from './Suggest';

export const Suggest = compose(
    composeU(
        withTypeCountries,
        withTypePersons,
        withTypeBonus,
        withTypeCompanies,
        withTypeCities,
        withTypeKonturCompanies,
        withTypeTravelPolicies,
    ),
)(SuggestBase);

export type SuggestProps = ComponentProps<typeof Suggest>;
