import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { CompanyCreateIn, CompanyDetails } from 'services/SwaggerApi';

import { schema } from '../FormCompanyRegister.schema';
import { FormCompanyRegisterFields } from '../FormCompanyRegister.types';

export const useFormCompanyRegister = (
    companyDetails: CompanyDetails,
    submitHandler: (payload: CompanyCreateIn) => void,
) => {
    const onSubmit: SubmitHandler<FormCompanyRegisterFields> = values => {
        submitHandler({
            ...companyDetails,
            ...values,
            is_offer_accepted: true,

        });
    };

    const methods = useForm<FormCompanyRegisterFields>({
        defaultValues: {
            accounting_email: '',
            postcode: companyDetails.postcode || '',
            phone_number: companyDetails.phone_number ?? '',
            post_address: companyDetails.post_address ?? '',
            legal_address: companyDetails.legal_address ?? '',
            name: companyDetails.name ?? '',
            inn: companyDetails.inn,
            kpp: companyDetails.kpp,
        },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });

    return { methods, schema, onSubmit };
};
