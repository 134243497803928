import { useMeta } from 'hooks/useMeta';
import { RoutePersonTrip } from 'routes/RoutePersonTrip';
import { Trip, TripCreate } from 'services/SwaggerApi';

type GetPathToTripOptions = {
    tripId: number;
    personTrips?: Trip['person_trips'];
    personTripsCreate?: TripCreate['person_trips'];
};

type GetPersonIdOptions = Pick<GetPathToTripOptions, 'personTrips' | 'personTripsCreate'> & {
    sessionPersonId: string;
};

const getPersonId = (options: GetPersonIdOptions) => {
    const { sessionPersonId, personTrips, personTripsCreate } = options;

    if (personTrips) {
        const firstPersonInTrip = personTrips?.[0].person?.person_id;
        const isPersonInTrip = personTrips?.find(trip => trip.person?.person_id === sessionPersonId);

        return isPersonInTrip ? sessionPersonId : firstPersonInTrip;
    }

    if (personTripsCreate) {
        const firstPersonInTrip = personTripsCreate[0].person_uid;
        const isPersonInTrip = personTripsCreate.find(trip => trip.person_uid === sessionPersonId);

        return isPersonInTrip ? sessionPersonId : firstPersonInTrip;
    }

    return null;
};

const getPathToTrip = (sessionPersonId: string) =>
    (options: GetPathToTripOptions) => {
        const { tripId, personTrips, personTripsCreate } = options;

        const personId = getPersonId({ personTrips, personTripsCreate, sessionPersonId });

        if (!personId) {
            return '/not_found';
        }

        return RoutePersonTrip.getPath({ tripId, personId }, {});
    };

/**
 * Хук для получения пути к персональной командировке, когда не известен personId
 */
export const usePathToTrip = () => {
    const { user: { person_id } } = useMeta();

    return { getPathToTrip: getPathToTrip(person_id) };
};
