import { RouteSearch } from 'routes/RouteSearch';

import { createUseQueryHook } from './createUseQueryHook';

const getAviaSearchResultOptionCount = createUseQueryHook(
    'get_avia_search_result_count',
    { refetchOnWindowFocus: false },
);

const getHotelSearchResultOptionCount = createUseQueryHook(
    'get_hotel_search_result_count',
    { refetchOnWindowFocus: false },
);

const getRailSearchResultOptionCount = createUseQueryHook(
    'get_rail_search_result_count',
    { refetchOnWindowFocus: false },
);

export const useSearchResultOptionCount = (searchId: string, query: RouteSearch['search']) => {
    switch (query.type) {
        case 'Avia':
            return getAviaSearchResultOptionCount(searchId, query);

        case 'Rail':
            return getRailSearchResultOptionCount(searchId, query);

        case 'Hotel':
            return getHotelSearchResultOptionCount(searchId, query);
    }
};
