import { useMemo } from 'react';

import { config } from 'config';
import { FetchError } from 'errors';
import { createUseQueryHook } from 'hooks/createUseQueryHook';
import { getAviaDetailToFlight } from 'hooks/serviceDetailToService';
import { useMeta } from 'hooks/useMeta';
import { AviaDetailResponse, AviaSearchResult, Flight } from 'services/SwaggerApi';
import { mapLoadable, RemoteData } from 'utils/Loadable';

type UseFlightOptionsOptions = {
    isEnabled: boolean;
    isFareFamily: Flight['fare_family'];
    serviceId: Flight['id'];
    searchId: string;
    tripId: number;
    personId: string;
};

export const useFlightOptions = (options: UseFlightOptionsOptions) => {
    const {
        searchId,
        serviceId,
        personId,
        tripId,
        isFareFamily,
        isEnabled,
    } = options;

    const meta = useMeta();
    const hasMoreDataInDetails = meta.user.provider === 'aeroclub';

    const cacheTime = config.env === 'production' ? undefined : 0;
    const useAviaFareFamilies = createUseQueryHook('get_avia_fare_families', { enabled: isEnabled, cacheTime });
    const useAviaSearchDetails = createUseQueryHook('get_avia_search_details', { enabled: hasMoreDataInDetails && isEnabled, cacheTime });

    const useGetFlightOptions = isFareFamily ? useAviaFareFamilies : useAviaSearchDetails;
    const flightOptionsRemote = useGetFlightOptions(serviceId, searchId, { person_id: personId, trip_id: tripId });

    return useMemo(() => {
        return isFareFamily ?
            mapLoadable(flightOptionsRemote as RemoteData<AviaSearchResult, FetchError>, ({ data }) => data) :
            mapLoadable(
                flightOptionsRemote as RemoteData<AviaDetailResponse, FetchError>,
                flightDetails => [getAviaDetailToFlight(flightDetails)],
            );
    }, [flightOptionsRemote, isFareFamily]);
};
