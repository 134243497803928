import { FC } from 'react';
import { Skeleton } from '@yandex-int/hr-components/Skeleton';

import { cn } from './FinanceWidgetSkeleton.cn';
import { FinanceWidgetSkeletonProps } from './FinanceWidgetSkeleton.types';

import './FinanceWidgetSkeleton.css';

export const FinanceWidgetSkeleton: FC<FinanceWidgetSkeletonProps> = props => {
    const { className } = props;

    return (
        <div className={cn(null, [className])}>
            <Skeleton className={cn('Header')} size="m" type="text" />
            <Skeleton className={cn('Data')} size="m" type="text" />
        </div>
    );
};
