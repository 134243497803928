import { FC, memo } from 'react';
import dayjs from 'dayjs';

import { Card } from 'components/Card';
import { TagHint } from 'components/TagHint';
import { Tag } from 'shared/ui/Tag';
import { Text } from 'shared/ui/Text';

import { getTagHints } from './utils/TaxiDetails.utils';
import { ReasonsDropdown } from './Reasons';
import { cn } from './TaxiDetails.cn';
import { i18n } from './TaxiDetails.i18n';
import { TaxiDetailsProps } from './TaxiDetails.types';

import './TaxiDetails.css';

/**
 * Виджет статуса подключения такси
 */
export const TaxiDetails: FC<TaxiDetailsProps> = memo(props => {
    const {
        className,
        data,
    } = props;

    const {
        current_phone_number,
        taxi_date_to,
        is_taxi_activated,
        not_activated_reasons,
    } = data;

    const { firstReason, restReasons } = getTagHints(not_activated_reasons, data);

    const showTagHints = !is_taxi_activated && restReasons.length > 0;
    const showPhoneNumber = is_taxi_activated && current_phone_number;
    const showDateTo = is_taxi_activated && taxi_date_to;
    const showDefaultHint = !is_taxi_activated && not_activated_reasons.length === 0;

    return (
        <Card className={cn(null, [className])}>
            <Card
                className={cn('Content')}
                description={showDefaultHint && i18n('description')}
                size="s"
                title={i18n('title')}
            >
                <div className={cn('Details')}>
                    <Tag
                        rounded
                        color={is_taxi_activated ? 'green' : 'red'}
                        maxWidth="full"
                        size="m"
                        text={i18n(is_taxi_activated ? 'taxi_activated' : 'taxi_not_activated')}
                    />
                    {showPhoneNumber && (
                        <Text className={cn('SubBlock')} color="secondary" typography="body-s">
                            {i18n('to_phone')}
                            <Text color="primary" typography="body-m">
                                {current_phone_number}
                            </Text>
                        </Text>
                    )}
                    {showDateTo && (
                        <Text className={cn('SubBlock')} color="secondary" typography="body-s">
                            {i18n('valid_until')}
                            <Text color="primary" typography="body-m">
                                {dayjs(taxi_date_to).format('DD MMMM, dd 00:00')}
                            </Text>
                        </Text>
                    )}
                    {firstReason && (
                        <div className={cn('ReasonsBlock')}>
                            <Text
                                as="span"
                                className={cn('FirstReason')}
                                color="secondary"
                                typography="body-s"
                            >
                                {firstReason}
                            </Text>
                            {restReasons.length > 0 && (
                                <TagHint
                                    inverse
                                    color="default"
                                    desktopTrigger={showTagHints ? 'hover' : undefined}
                                    hintContent={<ReasonsDropdown reasons={restReasons} />}
                                    tagSize="s"
                                    tagText={
                                        <Text color="link-hover" typography="body-s">
                                            {i18n('show_more', { count: restReasons.length })}
                                        </Text>
                                    }
                                    view="clear"
                                />
                            )}
                        </div>
                    )}
                </div>
            </Card>
        </Card>
    );
});

TaxiDetails.displayName = cn();
