import { FC, useCallback } from 'react';

import { PersonTag } from 'components/PersonTag';
import { withModalView } from 'hocs/withModalView';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { useEmployeeBlock } from './EmployeeBlockModal.hooks/useEmployeeBlock';
import { cn } from './EmployeeBlockModal.cn';
import { i18n } from './EmployeeBlockModal.i18n';
import { EmployeeBlockModalProps, HandleSubmit } from './EmployeeBlockModal.types';

import './EmployeeBlockModal.css';

const EmployeeBlockModalPresenter: FC<EmployeeBlockModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        employee,
        className,
    } = props;

    const {
        company_id,
        employee_id,
        first_name,
        last_name,
    } = employee;

    const [blockEmployee, blockingState] = useEmployeeBlock({
        onSuccess: onSubmit,
        options: { invalidateOnSuccess: true },
    });

    const isBlocking = isLoading(blockingState);

    const handleSubmit = useCallback<HandleSubmit>(() => {
        blockEmployee(company_id, employee_id);
    }, [blockEmployee, company_id, employee_id]);

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <div className={cn('Info')}>
                <Text
                    as="div"
                    className={cn('ActionFor')}
                    typography="body-s"
                >
                    {i18n('info')}
                </Text>
                <PersonTag
                    login="-"
                    name={`${first_name} ${last_name}`}
                    personId={employee_id}
                    size="m"
                />
            </div>
            <div className={cn('Controls')}>
                <Button
                    className={cn('Cancel')}
                    size="m"
                    view="ghost"
                    onClick={onCancel}
                >
                    {i18nAction('no')}
                </Button>
                <Button
                    className={cn('Submit')}
                    progress={isBlocking}
                    size="m"
                    view="primary"
                    onClick={handleSubmit}
                >
                    {i18nAction('yes')}
                </Button>
            </div>

        </div>
    );
};

EmployeeBlockModalPresenter.displayName = cn();

export const EmployeeBlockModal = withModalView(EmployeeBlockModalPresenter);
