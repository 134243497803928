import * as D from 'io-ts/Decoder';

import { config } from 'config';
import { ActivePage } from 'shared/constants/ActivePage';
import { checkAccessFactory } from 'utils/checkAccessFactory';
import { ToNumber } from 'utils/io-ts/decoders';

import { route } from './route';

const { isB2B } = config;

export const RouteGroupDecoder = D.type({
    params: D.type({
        groupId: ToNumber,
    }),
    search: D.type({}),
});

export type RouteGroup = D.TypeOf<typeof RouteGroupDecoder>;

const checkAccess = checkAccessFactory(
    meta => Boolean(isB2B && meta.is_coordinator && meta.is_tp_enabled),
    isB2B ? 'forbidden' : 'not_found',
);

export const RouteGroup = route(
    '/groups/:groupId',
    RouteGroupDecoder,
    {
        isAdminPanel: true,
        activePage: ActivePage.GROUP,
        checkAccess,
    },
);
