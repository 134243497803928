import { MaxPricePerNightRule, MaxPriceRule } from 'services/SwaggerApi';
import { formatCurrency } from 'utils/formatCurrency';

import { i18n } from './TravelPolicyCard.i18n';

const BULLET = '  •  ';

export const formatMaxPrice = (maxPrice: MaxPriceRule[] & MaxPricePerNightRule[]) => {
    if (!maxPrice || !maxPrice[0]) return;

    return `${i18n('price_to')} ${formatCurrency(maxPrice[0].max_price || maxPrice[0].max_price_per_night || 0)}`;
};

export const joinRestrictionsWithBullet = (restrictions: (string | undefined)[]) => {
    return restrictions.filter(b => Boolean(b)).join(BULLET);
};
