export const en = {
    fill: 'Fill',
    filled: 'Filled',
    fill_missing_data: 'Fill in missing data',
    personal_data: 'Personal data',
    missing_conf_fields: 'Fill in missing conference data',
    missing_date_of_birth: 'Fill in date of birth',
    missing_first_name_en: 'Fill in your first name',
    missing_last_name_en: 'Fill in your last name',
    missing_phone_number: 'Fill in your phone number',
    missing_gender: 'Specify your sex',
};
