import { useCallback, useState } from 'react';

type Position = {
   top: number;
   left: number;
};

export const useContextMenu = () => {
    const [position, setPosition] = useState<Position | null>(null);
    const visible = position !== null;

    const openContextMenu = useCallback((pos: Position) => {
        setPosition(pos);
    }, []);

    const closeContextMenu = useCallback(() => {
        setPosition(null);
    }, []);

    return {
        visible,
        position,
        openContextMenu,
        closeContextMenu,
    } as const;
};
