import { FC } from 'react';
import { cn as classname } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';

import { AppLink } from 'components/AppLink';
import { Tag } from 'shared/ui/Tag';
import { getTrackerUrl } from 'utils/urls';

type StartrekIssueProps = IClassNameProps & {
    issue: string;
    size: 's' | 'm';
};

const cn = classname('StartrekIssue');

export const StartrekIssue: FC<StartrekIssueProps> = props => {
    const { issue, size } = props;
    const href = getTrackerUrl(issue);

    return (
        <div className={cn(null, [props.className])}>
            <AppLink href={href} target="_blank">
                <Tag
                    color="blue"
                    maxWidth="full"
                    size={size}
                    text={issue}
                />
            </AppLink>
        </div>
    );
};
