export const en = {
    check_info: 'After the reconciliation is completed, you will receive an email with the results',
    file_is_too_large: 'The file must not exceed the size of {sizeInMB} MB',
    file_required: 'You need to upload a file',
    file_upload_info: 'To collate the table data with the registry, upload a file with up-to-date data in the format .XLS, .XLSX or .XLSM',
    no_file_selected: 'No file selected',
    select_file: 'Select a file',
    sent_to_check: 'The file has been sent for reconciliation',
    title: 'Collate registry',
    wrong_file_extension: 'Only files with .XLS, .XLSX or .XLSM extensions are allowed',
};
