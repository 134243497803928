import { FC } from 'react';

import { Dropdown } from 'components/Dropdown';
import { FieldNumberCounter, FieldRadiobox } from 'components/FormField2';
import { IconArrowShortDown } from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { i18nAviaClass } from 'i18n/i18nAviaClass';
import { ClassAvia } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';

import { cn } from '../SearchMain.cn';
import { MAX_HOTEL_ADULT, MAX_TRANSPORT_ADULT } from '../SearchMain.constants';
import { i18n } from '../SearchMain.i18n';
import { getFlightClassOptions } from '../SearchMain.utils/getFlightClassOptions';

import { PassengersInputProps } from './PassengersInput.types';

import './PassengersInput.css';

export const PassengersInput: FC<PassengersInputProps> = props => {
    const {
        classAvia,
        adult,
        searchType,
        isPlusOneEnabled,
    } = props;

    const { isMobile, isDesktop } = usePlatform();

    const aviaClassTitle = i18nAviaClass(`avia_class_${classAvia?.toLowerCase() as Lowercase<ClassAvia>}`);

    const triggerText = (
        <Text className={cn('TriggerText')} typography="body-s">
            {isPlusOneEnabled && (
                searchType === 'Hotel' ? i18n('adult_persons', { count: adult }) : i18n('adult_passengers', { count: adult })
            )}
            <Text color="secondary" typography={isPlusOneEnabled && isDesktop ? 'caption-m' : 'body-s'}>
                {searchType === 'Avia' && (
                    isPlusOneEnabled && isMobile ? ` · ${aviaClassTitle}` : aviaClassTitle
                )}
            </Text>
        </Text>
    );

    const maxPassengers = searchType === 'Hotel' ? MAX_HOTEL_ADULT : MAX_TRANSPORT_ADULT;

    return (
        <Dropdown
            className={cn('PassengersInput', { isPlusOneEnabled: isPlusOneEnabled })}
            popupProps={{
                direction: ['bottom-end'],
            }}
            triggerElement={(
                <div
                    className={cn('PassengersInputTrigger')}
                    data-testid="passengers-input-trigger"
                    tabIndex={0}
                >
                    {triggerText}
                    <IconArrowShortDown size="m" />
                </div>
            )}
        >
            <div className={cn('PassengersInputParams')}>
                {
                    isPlusOneEnabled && (
                        <div className={cn('PassengersInputGroup')}>
                            <Text
                                color="secondary"
                                typography="label-s"
                            >
                                {i18n('passengers_title')}
                            </Text>
                            <div>
                                <FieldNumberCounter
                                    caption={i18n('adult_caption')}
                                    max={maxPassengers}
                                    min={1}
                                    name="adult"
                                    title={i18n('adult')}
                                />
                            </div>
                        </div>
                    )
                }
                {searchType === 'Avia' && (
                    <div className={cn('PassengersInputGroup')}>
                        <Text
                            color="secondary"
                            typography="label-s"
                        >
                            {i18n('avia_class_title')}
                        </Text>
                        <div>
                            <FieldRadiobox
                                vertical
                                className={cn('ClassAvia')}
                                name="class_avia"
                                options={getFlightClassOptions()}
                                size="m"
                                view="outline"
                            />
                        </div>
                    </div>
                )}
            </div>
        </Dropdown>
    );
};
