/* eslint-disable @typescript-eslint/no-floating-promises */
import { useContext, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import { PersonTrip } from 'services/SwaggerApi';
import { ServicesDetailsWithId } from 'types/TripRoute';
import { allSettled } from 'utils/allSettled';
import { Loadable, loading, success } from 'utils/Loadable';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../../SwaggerContext';

export function useOrdersDetails(tripId: number, personTrip: PersonTrip):
        Loadable<ServicesDetailsWithId[]> {
    const queryClient = useQueryClient();
    const { api } = useContext(SwaggerContext);
    const { services } = personTrip;

    const queryKey = useMemo(
        () => ['person_trip_services_details', tripId, personTrip.person?.person_id],
        [personTrip.person?.person_id, tripId],
    );
    const [servicesDetails, setServicesDetails] = useState(
        queryClient.getQueryData(queryKey),
    );

    useEffect(() => {
        setServicesDetails(queryClient.getQueryData(queryKey));

        allSettled(services.map(async({ service_id, type }) => {
            switch (type) {
                case 'avia':
                    const aviaResult = await queryClient.fetchQuery(
                        ['get_avia_service_details', service_id],
                        () => api.get_avia_service_details(service_id).then(throwHTTPErrors),
                    );

                    return { ...aviaResult, service_id };

                case 'hotel':
                    const hotelResult = await queryClient.fetchQuery(
                        ['get_hotel_service_details', service_id],
                        () => api.get_hotel_service_details(service_id).then(throwHTTPErrors),
                    );

                    return { ...hotelResult, service_id };

                case 'rail':
                    const railResult = await queryClient.fetchQuery(
                        ['get_rail_service_details', service_id],
                        () => api.get_rail_service_details(service_id).then(throwHTTPErrors),
                    );

                    return { ...railResult, service_id };
            }
        })).then(services => {
            const successfullyDownloadedServices = services
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                .filter(({ status }) => status === 'fulfilled').map(({ value }) => value);

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setServicesDetails(successfullyDownloadedServices as any);
            queryClient.setQueryData(queryKey, successfullyDownloadedServices);
        });
    }, [api, queryClient, queryKey, services]);

    if (services.length === 0) {
        return success([]);
    }

    if (!servicesDetails) {
        return loading();
    }

    return success((servicesDetails as unknown[]).filter(Boolean));
}
