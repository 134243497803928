import { FetchError } from 'errors';
import { PersonDetails } from 'services/SwaggerApi';
import { RemoteData } from 'utils/Loadable';

import { createUseQueryHook } from './createUseQueryHook';

type UsePersonDetailsOptions = {
    isEnabled?: boolean;
};

type UsePersonDetails = (person_id: string, options?: UsePersonDetailsOptions) => RemoteData<PersonDetails, FetchError>;

export const usePersonDetails: UsePersonDetails = (person_id, options = {}) => {
    const { isEnabled } = options;

    return createUseQueryHook(
        'get_person_details',
        {
            enabled: isEnabled,
            cacheTime: Infinity,
            retry: false,
        },
    )(person_id);
};
