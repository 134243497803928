type FieldNumberValue = number | undefined;

type SerializeNumber = (date: FieldNumberValue) => string | undefined;

/**
 * Сериализирует число и приводит ее к строке оставляя два знака после запятой.
 * @param number
 * @returns Сериализованное число, приведённое к строке.
 */
export const serializeNumber: SerializeNumber = number => {
    if (typeof number === 'number') {
        return number.toFixed(2);
    }

    return undefined;
};
