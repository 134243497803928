import { FC } from 'react';

import { FormProvider } from 'components/FormField2';
import { FieldTumbler } from 'components/FormField2/Fields/FieldTumbler';
import { withRemote } from 'hocs/withRemote';
import { ListItem } from 'shared/ui/ListItem';

import { cn } from '../../PageProfile.cn';
import { i18n } from '../../PageProfile.i18n';

import { useHideOnStaff } from './HideOnStaff.hook/useHideOnStaff';
import { HideOnStaffCardProps } from './HideOnStaffCard.types';

const HideOnStaffCard: FC<HideOnStaffCardProps> = ({ personDetails }) => {
    const { methods, isLoading } = useHideOnStaff(
        {
            personId: personDetails.person_id,
            isHiddenGap: Boolean(personDetails?.is_hidden_gap),
        });

    return (
        <FormProvider methods={methods}>
            <ListItem
                expanded
                noVerticalPadding
                transparent
                className={cn('Item')}
                leadingTextParams={{
                    label: i18n('hide_trip_on_staff'),
                    caption: i18n('hide_trip_on_staff_description'),
                }}
                testId="hide_trip"
                trailing={<FieldTumbler disabled={isLoading} name="is_hidden" />}
            />
        </FormProvider>
    );
};

export const RemoteHideOnStaffCard = withRemote(HideOnStaffCard);
