import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
    FieldSuggest,
    FieldTextinput,
    FormProvider,
} from 'components/FormField2';

import { cn } from '../TableCompanies.cn';
import { i18n } from '../TableCompanies.i18n';

import {
    HeaderProps,
    TableCompaniesHeaderFields,
} from './Header.types';

import './Header.css';

export const Header: FC<HeaderProps> = props => {
    const {
        defaultFilters,
        className,
        onFiltersChange,
    } = props;

    const methods = useForm<TableCompaniesHeaderFields>({
        defaultValues: defaultFilters,
    });

    useEffect(() => {
        const subscription = methods.watch(data => {
            onFiltersChange(data as TableCompaniesHeaderFields);
        });

        return () => subscription.unsubscribe();
    }, [methods, onFiltersChange]);

    return (
        <FormProvider methods={methods}>
            <form className={cn('Header', [className])}>
                <FieldTextinput
                    debounceTimeout={300}
                    name="companyName"
                    placeholder={i18n('company_name_placeholder')}
                />
                <FieldSuggest
                    className={cn('Search')}
                    name="travelPolicy"
                    placeholder={i18n('tp_header')}
                    type="travelPolicies"
                />
            </form>
        </FormProvider>
    );
};
