import { FC, memo } from 'react';
import { CaptionProps } from 'react-day-picker';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { usePlatform } from 'hooks/usePlatform';
import { DateFormat, formatDate } from 'utils/formatDate';

/**
 * Кастомный компонент отображения месяцев в Дейтпикере
 */
export const Caption: FC<CaptionProps> = memo(props => {
    const { displayMonth, id, displayIndex } = props;

    const { isMobile } = usePlatform();
    const year = dayjs(displayMonth).year();

    const capitalizedMonth = capitalize(formatDate(displayMonth, DateFormat.MMMM));

    const caption = isMobile ? `${capitalizedMonth} ${year}` : capitalizedMonth;

    return (
        <div className="rdp-caption" id={`active-month-${displayIndex}`}>
            <div aria-live="polite" className="rdp-caption_label" id={`react-day-picker-${id}`} role="presentation">
                {caption}
            </div>
        </div>
    );
});
