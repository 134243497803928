import { ErrorName } from './ErrorName';

// Базовая ошибка, если не можем использовать что-то специфичнее
export class UnknownError extends Error {
    public readonly name = ErrorName.UnknownError;
    public readonly detail?: unknown;

    public constructor(message?: string, detail?: unknown) {
        super(message);
        this.detail = detail;
    }
}
