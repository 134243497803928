import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { FloatingIsland } from 'components/FloatingIsland';
import { IconArrowShortRight, IconTravelHotel } from 'components/Icon';
import { RouteHotelRooms } from 'routes/RouteHotelRooms';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';

import { cn } from '../HotelDetails.cn';
import { i18n } from '../HotelDetails.i18n';

import { ActionBarProps } from './ActionBar.types';

import './ActionBar.css';

export const ActionBar: FC<ActionBarProps> = props => {
    const {
        optionId,
        tripId,
        personId,
        searchId,
        minPricePerNight,
        count,
        adult,
    } = props;

    const { push } = useHistory();

    const onActionBarClick = useCallback(() => {
        const hotelRoomsPageUrl = RouteHotelRooms.getPath({ searchId, key: optionId }, {
            trip_id: tripId,
            person_id: personId,
            adult,
        });

        push(hotelRoomsPageUrl);
    }, [optionId, personId, push, searchId, tripId, adult]);

    return (
        <>
            <FloatingIsland
                className={cn('ActionBar')}
                leading={(
                    <div className={cn('ActionBarIcon')}>
                        <IconTravelHotel size="m" />
                    </div>
                )}
                leadingTextParams={{
                    label: <Text weight="medium">{i18n('choose_room')}</Text>,
                    caption: i18n('general_rooms_info', {
                        count,
                        minPricePerNight: formatCurrency(minPricePerNight),
                    }),
                }}
                trailing={(
                    <IconArrowShortRight size="m" />
                )}
                onClick={onActionBarClick}
            />
        </>
    );
};
