import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FieldSuggest, FormProvider } from 'components/FormField2';
import { PersonTag } from 'components/PersonTag';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import { cn } from './SelectAssistantForm.cn';
import { i18n } from './SelectAssistantForm.i18n';
import { schema } from './SelectAssistantForm.schema';
import { SelectAssistantFormProps, SelectAssistantFormSchema } from './SelectAssistantForm.types';

import './SelectAssistantForm.css';

/**
 * Форма назначения ассистента
 */
export const SelectAssistantForm: FC<SelectAssistantFormProps> = props => {
    const {
        className,
        onCancel,
        onSubmit,
        error,
        progress,
        employee,
        defaultValues,
    } = props;

    const {
        company_id,
        first_name,
        last_name,
        employee_id,
    } = employee;

    const methods = useForm<SelectAssistantFormSchema>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues,
    });

    return (
        <FormProvider error={error} methods={methods} schema={schema}>
            <form
                className={cn(null, [className])}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <div className={cn('Fieldset')}>
                    <div className={cn('Info')}>
                        <Text
                            className={cn('ActionFor')}
                            typography="body-s"
                        >
                            {i18n('assistant_for')}
                        </Text>
                        <PersonTag
                            login="-"
                            name={`${first_name} ${last_name}`}
                            personId={employee_id}
                            size="m"
                        />
                    </div>
                    <FieldSuggest
                        className={cn('FieldPerson')}
                        companyId={company_id}
                        name="assistant"
                        type="persons"
                    />

                </div>
                <div className={cn('Controls')}>
                    <Button
                        className={cn('Cancel')}
                        size="m"
                        view="ghost"
                        onClick={onCancel}
                    >
                        {i18nAction('cancel')}
                    </Button>
                    <Button
                        className={cn('Submit')}
                        progress={progress}
                        size="m"
                        type="submit"
                        view="primary"
                    >
                        {i18nAction('save')}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

SelectAssistantForm.displayName = cn();
