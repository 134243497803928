import { FC, Fragment } from 'react';

import { IconClock } from 'components/Icon';
import { Text } from 'shared/ui/Text';

import { cn } from '../ExecutingTripStatus/ExecutingTripStatus.cn';

import { StatusProgressBarProps } from './StatusProgressBar.types';

import './StatusProgressBar.css';

export const StatusProgressBar: FC<StatusProgressBarProps> = props => {
    const {
        className,
        unitsNumber,
        iconUnit,
        text,
    } = props;

    const allUnits = unitsNumber - 1;

    const realIconUnit = iconUnit - 1;
    const inactiveUnits = allUnits - realIconUnit - 1;

    const captionColumn = realIconUnit * 2 || 1;

    const containerStyle = {
        gridTemplateColumns: `repeat(${allUnits}, min-content 1fr) min-content`,
    };

    const first = iconUnit === 1;

    const captionStyle = {
        gridColumn: `${captionColumn} / span ${first ? 4 : 3}`,
    };

    return (
        <div className={cn(null, [className])} style={containerStyle}>
            {[...Array(realIconUnit)].map((_, i) => (
                <Fragment key={i}>
                    <div className={cn('Step')} />
                    <div className={cn('Line')} />
                </Fragment>
            ))}

            <IconClock className={cn('IconClock')} size="l" />

            <div className={cn('Line', { inactive: true })} />

            <div className={cn('IconCaption', { first })} style={captionStyle}>
                <Text typography="caption-m" weight="medium">{text}</Text>
            </div>

            {[...Array(inactiveUnits)].map((_, i) => (
                <Fragment key={i}>
                    <div className={cn('Step', { inactive: true })} />
                    <div className={cn('Line', { inactive: true })} />
                </Fragment>
            ))}

            <div className={cn('Step', { inactive: true })} />
        </div>
    );
};

StatusProgressBar.displayName = cn();
