import { ComponentProps, FC } from 'react';

import { Textarea } from 'components/Textarea';

import { withFormField } from '../FormField2';
import { cn } from '../FormField2.cn';
import { FormFieldProps } from '../FormField2.types';

import './FieldTextarea.css';

export type WithTypeTextareaProps = ComponentProps<typeof Textarea>;

const Control: FC<WithTypeTextareaProps & FormFieldProps> = props => {
    const {
        hasClear = true,
        size = 'm',
        disabled,
        name,
        controller,
        context,
        ...textAreaProps
    } = props;

    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = controller;

    let state: WithTypeTextareaProps['state'];

    if (error) {
        state = 'error';
    }

    return (
        <Textarea
            {...textAreaProps}
            className={cn('Control', { name })}
            controlRef={ref}
            disabled={disabled}
            hasClear={hasClear}
            id={name}
            name={name}
            size={size}
            state={state}
            value={value}
            view="default"
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};

export const FieldTextarea = withFormField(Control);
