export const en = {
    settings: 'General settings',
    users_in_group: 'Users in the group',
    users_conditions: 'The conditions of this group apply to all added users. Each user can only be in one group',
    group_name: 'Group name',
    group_name_placeholder: 'Enter the name',
    travel_policy: 'Travel policy',
    travel_policy_placeholder: 'Choose...',
    travel_policy_not_selected: 'Not selected',
    save_caption: 'A new user group will be added to the company',
    save_changes_caption: 'The changes will only apply to new applications',
    add_user: 'Add a participant',
    add_users: 'Add users',
    go_to_profile: 'Go to profile',
    remove_from_group: 'Remove from group',
    load_more: 'Load more',
    error_title: 'There are no users in this group',
    error_description: 'The travel policy of this group will apply to all users added to the group',
    confirmation_title: 'Confirmation',
    confirmation_message: 'Are you sure you want to remove a user from this group?',
};
