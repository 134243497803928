import { FC } from 'react';
import { Palettes } from '@yandex-int/hr-components/PaletteGeneration';

import { Dropdown } from 'components/Dropdown';
import { IconHelp, IconInfo } from 'components/Icon';

import { cn } from './HelpTooltip.cn';
import { HelpTooltipProps } from './HelpTooltip.types';

import './HelpTooltip.css';

export const HelpTooltip: FC<HelpTooltipProps> = props => {
    const {
        iconSize,
        desktopTrigger = 'hover',
        inverse,
        content,
        direction = ['top', 'right', 'top-end', 'left'],
        hasTail = true,
        stopPropagation,
        className,
        info,
        popupProps,
    } = props;

    return (
        <Dropdown
            className={cn(null, [className])}
            desktopTrigger={desktopTrigger}
            drawerProps={{
                scope: 'inplace',
            }}
            popupProps={{
                hasTail,
                direction,
                className: inverse ? Palettes.inverse : undefined,
                scope: 'inplace',
                ...popupProps,
            }}
            stopPropagation={stopPropagation}
            triggerElement={(
                <span className={cn('Icon')} data-testid="help-tooltip">
                    {info ? <IconInfo size={iconSize} /> : <IconHelp size={iconSize} />}
                </span>
            )}
        >
            <div className={cn('Content')} data-testid="helptooltip-content">
                {content}
            </div>
        </Dropdown>
    );
};

HelpTooltip.displayName = cn();
