import { FC, useCallback, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { FieldCheckbox } from 'components/FormField2';
import {
    getIconProvider,
    IconArrowShortDown,
    IconArrowShortUp,
} from 'components/Icon';
import { Checkbox } from 'shared/ui/Checkbox';
import { Popup } from 'shared/ui/Popup';

import { useWatchCheckboxes } from './SelectDropdown.hooks/useWatchCheckboxes';
import { cn } from './SelectDropdown.cn';
import { i18n } from './SelectDropdown.i18n';
import {
    FieldValues,
    SelectDropdownContentProps,
    SelectDropdownProps,
} from './SelectDropdown.types';

import './SelectDropdown.css';

const iconProviderArrowDown = getIconProvider(IconArrowShortDown);
const iconProviderArrowUp = getIconProvider(IconArrowShortUp);

export const SelectDropdownContent: FC<SelectDropdownContentProps> = props => {
    const {
        handleFieldsChange,
        checkboxFields,
    } = props;

    const [allChecked, toggleAllChecked] = useToggle(false);

    const checkboxFieldNames = useMemo(() =>
        checkboxFields.map(({ name }) => name),
    [checkboxFields]);

    useWatchCheckboxes(checkboxFieldNames, allChecked, toggleAllChecked);

    const handleChange = useCallback(() => {
        const reversedFields = checkboxFieldNames.reduce<FieldValues>((fields, name) => {
            fields[name] = !allChecked;

            return fields;
        }, {});

        handleFieldsChange(reversedFields);
        toggleAllChecked();
    }, [allChecked, checkboxFieldNames, handleFieldsChange, toggleAllChecked]);

    return (
        <div className={cn()}>
            <Checkbox
                checked={allChecked}
                className={cn('SelectAll')}
                label={i18n('select_all')}
                lines="multi"
                size="m"
                view="outline"
                onChange={handleChange}
            />
            {checkboxFields.map(field => (
                <FieldCheckbox
                    key={field.name}
                    checkboxLabel={field.label}
                    className={cn('Field')}
                    name={field.name}
                />
            ))}
        </div>
    );
};

export const SelectDropdown: FC<SelectDropdownProps> = props => {
    const {
        buttonText,
        disabled,
        checkboxFields,
        handleFieldsChange,
        testId,
    } = props;

    const anchorRef = useRef<HTMLButtonElement>(null);
    const [visible, setVisible] = useToggle(false);
    const buttonIcon = visible ? iconProviderArrowUp : iconProviderArrowDown;

    const handleClose = useCallback(() => {
        setVisible();
    }, [setVisible]);

    return (
        <>
            <Button
                data-testid={testId}
                disabled={disabled}
                iconRight={buttonIcon}
                innerRef={anchorRef}
                size="m"
                view="default"
                onClick={setVisible}
            >
                {buttonText}
            </Button>
            <Popup
                anchor={anchorRef}
                direction="bottom-start"
                scope="inplace"
                target="anchor"
                view="default"
                visible={visible}
                onClose={handleClose}
            >
                <SelectDropdownContent
                    checkboxFields={checkboxFields}
                    handleFieldsChange={handleFieldsChange}
                />
            </Popup>
        </>
    );
};

SelectDropdown.displayName = cn();
