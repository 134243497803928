import * as yup from 'yup';

import { i18n } from './CompanyEmailForm.i18n';

type ValidateEmailDomain = (domain: string) => (email?: string) => boolean;

const validateEmailDomain: ValidateEmailDomain = domain =>
    email => !email?.includes(domain);

export const schema = yup.object({
    email: yup.string().trim().lowercase()
        .required(i18n('email_required'))
        .email(i18n('email_invalid'))
        .test('yandex', () => i18n('domain_support', { domain: '@yandex' }), validateEmailDomain('@yandex.'))
        .test('mailru', () => i18n('domain_support', { domain: '@mail' }), validateEmailDomain('@mail.'))
        .test('gmail', () => i18n('domain_support', { domain: '@gmail' }), validateEmailDomain('@gmail.')),
});
