import * as yup from 'yup';

import { i18nErrors } from 'i18n/i18nErrors';

export const schema = yup.object({
    phone_number: yup.string()
        .trim()
        .nullable()
        .required()
        .max(16),
    accounting_email: yup.string()
        .trim()
        .email()
        .nullable()
        .required(),
    post_address: yup.string()
        .trim()
        .nullable()
        .required(),
    postcode: yup.string()
        .nullable()
        .required()
        .max(6)
        .matches(/^\d+$/, () => i18nErrors('digits_only')),
    legal_address: yup.string()
        .trim()
        .nullable()
        .required(),
    name: yup.string()
        .trim()
        .nullable()
        .required(),
    inn: yup.string()
        .trim()
        .nullable()
        .required(),
    kpp: yup.string()
        .trim()
        .nullable(),
});
