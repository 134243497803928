import { formatParams } from './formatParams';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TQuery = Record<string, any>;

export interface BuildUrlOptions {
    protocol?: string;
    host?: string;
    pathname?: string;
    search?: string | TQuery;
    hash?: string;
}

/**@deprecated использовать только там, где без неё не обойтись,
 * в остальных случаях \<RouteName\>.getPath */
export const buildUrl = (options: BuildUrlOptions) => {
    const {
        host,
        protocol = 'https',
    } = options;

    let {
        pathname = '',
        search = '',
        hash = '',
    } = options;

    if (pathname !== '' && !pathname.startsWith('/')) {
        pathname = '/' + pathname;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    search = formatParams(search as any);

    if (search && !search.startsWith('?')) {
        search = '?' + search;
    }

    if (hash && !hash.startsWith('#')) {
        hash = '#' + hash;
    }

    if (protocol && host) {
        return `${protocol}://${host}${pathname}${search}${hash}`;
    }

    return `${pathname}${search}${hash}`;
};
