import { SwaggerApi } from 'services/SwaggerApi';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { DestinationSuggestItem } from '../Destination.types';

function trim(value: string) {
    return value.replace(/^\s*/, '').replace(/\s*$/, '');
}

export function dataProvider(value: string, settlementsSuggestFn: SwaggerApi['settlement_suggest']) {
    if (trim(value) === '') {
        return Promise.resolve([]);
    }

    // Toolssuggest ожидает данные в таком формате, поэтому меняем формат данных а не храним как есть
    return settlementsSuggestFn({ query: value }).then(throwHTTPErrors)
        .then(({ result }) => result)
        .then(references => {
            return references.map<DestinationSuggestItem>(ref => ({
                id: ref.geoid,
                value: ref,
            }));
        });
}
