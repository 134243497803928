export const ru = {
    monday: 'ПН',
    tuesday: 'ВТ',
    wednesday: 'СР',
    thursday: 'ЧТ',
    friday: 'ПТ',
    saturday: 'СБ',
    sunday: 'ВС',
    done: 'Готово',
    reset: 'Сбросить',
    select_one: 'Выбрать {date}',
    select_two: 'Выбрать {dateFrom} - {dateTo}',
    select_date: 'Выбрать дату',
};
