import { FC, useCallback, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { useToggle } from 'react-use';
import { Pagination } from '@yandex-int/hr-components/Pagination/desktop/bundle';

import { CollateRegistryModal } from 'components/CollateRegistryModal';
import { TableSkeleton } from 'components/TableSkeleton';
import { TableTransactionsHeader } from 'components/TableTransactionsHeader';
import { TransactionDeleteModal } from 'components/TransactionDeleteModal';
import { TransactionCreateModal } from 'components/TransactionForm/TransactionCreateModal';
import { TransactionEditModal } from 'components/TransactionForm/TransactionEditModal';
import { TransactionPaidModal } from 'components/TransactionPaidModal';
import { withRemote } from 'hocs/withRemote';
import { useTablePagination } from 'hooks/useTablePagination';
import { openFile } from 'platform/bridge-methods';
import { Transaction, TTransaction_list_xlsxQuery, TTransaction_listQuery } from 'services/SwaggerApi';
import { buildUrl } from 'utils/buildUrl';
import { hasData } from 'utils/Loadable';

import { useTransactionList } from './TableTransactions.hooks/useTransactionList';
import { serializeFilters } from './TableTransactions.utils/serializeFilters';
import { EmptyState } from './EmptyState';
import { TableTransactions } from './TableTransactions';
import { cn } from './TableTransactions.cn';
import {
    ActionsCallbacks,
    TableTransactionsContainerProps,
    TableTransactionsFilters,
} from './TableTransactions.types';

const TableTransactionsRemote = withRemote(TableTransactions);

const defaultFilters = {};

export const TableTransactionsContainer: FC<TableTransactionsContainerProps> = props => {
    const {
        companyId,
        className,
    } = props;

    const [filters, setFilters] = useState<TableTransactionsFilters>(defaultFilters);
    const [editableTransaction, setEditableTransaction] = useState<Transaction | null>(null);
    const [transactionToDelete, setTransactionToDelete] = useState<Transaction | null>(null);
    const [transactionToPaid, setTransactionToPaid] = useState<Transaction | null>(null);
    const [isRegistryModalVisible, setRegistryModalVisible] = useToggle(false);
    const [isTransactionCreateModalVisible, setTransactionCreateModalVisible] = useToggle(false);
    const {
        paginationProps,
        computePagesCount,
        resetCurrentPage,
    } = useTablePagination();
    const {
        pageSize,
        currentPage,
    } = paginationProps;

    const handleFiltersChange = useCallback(data => {
        unstable_batchedUpdates(() => {
            setFilters(data);
            resetCurrentPage();
        });
    }, [resetCurrentPage]);

    const queryParams: TTransaction_listQuery = useMemo(() => ({
        ...serializeFilters(filters),
        page: currentPage,
        limit: pageSize,
    }), [currentPage, pageSize, filters]);

    const queryParamsForExport: TTransaction_list_xlsxQuery = useMemo(() => (
        serializeFilters(filters)
    ), [filters]);

    const transactions = useTransactionList(companyId, queryParams);
    const transactionsCount = hasData(transactions) ? transactions.result.count : 0;
    const pagesCount = computePagesCount(transactionsCount);

    const handleExportTransactions = useCallback(() => {
        openFile(buildUrl({ pathname: `/api/billing/companies/${companyId}/export_transactions`, search: queryParamsForExport }));
    }, [companyId, queryParamsForExport]);

    const handleCloseEditable = useCallback(() => {
        setEditableTransaction(null);
    }, []);
    const handleSubmitEditable = handleCloseEditable;

    const handleCloseDelete = useCallback(() => {
        setTransactionToDelete(null);
    }, []);
    const handleClosePaid = useCallback(() => {
        setTransactionToPaid(null);
    }, []);
    const handleSubmitDelete = handleCloseDelete;
    const handleSubmitPaid = handleClosePaid;

    const isHeaderDisabled = !hasData(transactions);

    const actionsCallbacks = useMemo<ActionsCallbacks>(() => ({
        onDelete: setTransactionToDelete,
        onUpdate: setEditableTransaction,
        onHold: setTransactionToPaid,
    }), []);
    const tableContent = !transactionsCount && hasData(transactions) ?
        <EmptyState className={cn(null, [className])} handleAddClick={setTransactionCreateModalVisible} /> :
        <div className={cn(null, [className])}>
            <TableTransactionsHeader
                className={cn('Header')}
                companyId={companyId}
                defaultFilters={defaultFilters}
                disabled={isHeaderDisabled}
                onAddClick={setTransactionCreateModalVisible}
                onCollateRegistryClick={setRegistryModalVisible}
                onExportClick={handleExportTransactions}
                onFiltersChange={handleFiltersChange}
            />
            <TableTransactionsRemote
                actionsCallbacks={actionsCallbacks}
                data={transactions}
                errorFallbackClassName={cn('ErrorFallback')}
                pageCount={pagesCount}
                pageIndex={currentPage}
                pageSize={pageSize}
                skeleton={
                    <TableSkeleton
                        className={cn('Skeleton')}
                        columns={10}
                        rows={10}
                    />
                }
            />
        </div>;

    return (
        <>
            {tableContent}
            {pagesCount > 0 && <Pagination
                {...paginationProps}
                className={cn('Pagination')}
                pagesCount={pagesCount}
            />}
            {editableTransaction && (
                <TransactionEditModal
                    modalViewOnCloseOff
                    modalViewVisible
                    modalViewOnClose={handleCloseEditable}
                    transaction={editableTransaction}
                    onCancel={handleCloseEditable}
                    onSubmit={handleSubmitEditable}
                />
            )}
            {transactionToDelete && (
                <TransactionDeleteModal
                    transaction={transactionToDelete}
                    onCancel={handleCloseDelete}
                    onSubmit={handleSubmitDelete}
                />
            )}
            {transactionToPaid && (
                <TransactionPaidModal
                    modalViewVisible
                    modalViewOnClose={handleClosePaid}
                    transaction={transactionToPaid}
                    onCancel={handleClosePaid}
                    onSubmit={handleSubmitPaid}
                />
            )}
            <CollateRegistryModal
                modalViewOnClose={setRegistryModalVisible}
                modalViewVisible={isRegistryModalVisible}
                onCancel={setRegistryModalVisible}
                onSubmit={setRegistryModalVisible}
            />
            <TransactionCreateModal
                modalViewOnCloseOff
                companyId={companyId}
                modalViewOnClose={setTransactionCreateModalVisible}
                modalViewVisible={isTransactionCreateModalVisible}
                onCancel={setTransactionCreateModalVisible}
                onSubmit={setTransactionCreateModalVisible}
            />
        </>
    );
};

TableTransactionsContainer.displayName = cn();
