import produce from 'immer';

import { TQuickFilter } from 'components/QuickFilter';
import { ACServiceType } from 'services/AeroClubApi/ACServiceType';

export function getQuickFilters<T extends ACServiceType>(type: T): TQuickFilter[] {
    switch (type) {
        case 'Avia':
            return [
                {
                    type: 'Avia',
                    slug: 'aviaFilter_baggage',
                    isQueryActiveFn: query => Boolean(query.has_baggage),
                    enableQueryFn: query => ({ ...query, has_baggage: true }),
                    disableQueryFn: ({ has_baggage, ...query }) => query,
                },
            ];

        case 'Rail':
            return [
                {
                    type: 'Rail',
                    slug: 'departureAtThereTimes_Morning',
                    isQueryActiveFn: query => Boolean(query.departure_there_timespan?.includes('Morning')),
                    enableQueryFn: query => produce(query, draftQuery => {
                        if (!draftQuery.departure_there_timespan) {
                            draftQuery.departure_there_timespan = [];
                        }

                        if (!draftQuery.departure_there_timespan.includes('Morning')) {
                            draftQuery.departure_there_timespan.push('Morning');
                        }

                        return draftQuery;
                    }),
                    disableQueryFn: query => produce(query, draftQuery => {
                        if (!draftQuery.departure_there_timespan) {
                            draftQuery.departure_there_timespan = [];
                        }

                        const index = draftQuery.departure_there_timespan.indexOf('Morning');

                        if (index !== -1) {
                            draftQuery.departure_there_timespan.splice(index, 1);
                        }

                        return draftQuery;
                    }),
                },
                {
                    type: 'Rail',
                    slug: 'departureAtThereTimes_Evening',
                    isQueryActiveFn: query => Boolean(query.departure_there_timespan?.includes('Evening')),
                    enableQueryFn: query => produce(query, draftQuery => {
                        if (!draftQuery.departure_there_timespan) {
                            draftQuery.departure_there_timespan = [];
                        }

                        if (!draftQuery.departure_there_timespan.includes('Evening')) {
                            draftQuery.departure_there_timespan.push('Evening');
                        }

                        return draftQuery;
                    }),
                    disableQueryFn: query => produce(query, draftQuery => {
                        if (!draftQuery.departure_there_timespan) {
                            draftQuery.departure_there_timespan = [];
                        }

                        const index = draftQuery.departure_there_timespan.indexOf('Evening');

                        if (index !== -1) {
                            draftQuery.departure_there_timespan.splice(index, 1);
                        }

                        return draftQuery;
                    }),
                },
            ];

        case 'Hotel':
            return [
                {
                    type: 'Hotel',
                    slug: 'hotelFilter_confirmationTypes_Instant',
                    isQueryActiveFn: query => Boolean(query.confirmation_types),
                    enableQueryFn: query => ({ ...query, confirmation_types: ['InstantConfirmation'] }),
                    disableQueryFn: ({ confirmation_types, ...query }) => query,
                },
            ];

        default:
            return [];
    }
}
