export const ru = {
    compartment: 'купе',
    compartment_female: 'женское',
    compartment_male: 'мужское',
    compartment_mixed: 'смешанное',
    compartment_single: '',
    schema_loading: 'Загружаем схему вагона',
    schema_not_available: 'Схема вагона отсутствует',
    train_direction: 'Движение поезда',
};
