export const en = {
    colleagues_recommend: 'Colleagues recommend',
    show_on_map: 'Show on map',
    special_project_ya_travel: 'Ya.Travel special project',
    wifi: 'Wi-Fi',
    breakfast: 'Breakfast',
    parking: 'Parking',
    registration_24: 'Round-the-clock registration',
    gym: 'Gym',
    safe: 'Safe',
    conditioning: 'Conditioner',
    luggage_storage: 'Luggage storage',
    hotel: 'Hotel',
    rating: 'Rating',
};
