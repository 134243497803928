import { FC } from 'react';

import { i18nDocument } from 'i18n/i18nDocument';
import { i18nDocumentType } from 'i18n/TT/i18nDocumentType';
import { Divider } from 'shared/ui/Divider';
import { ListItem } from 'shared/ui/ListItem';
import { ModalPlatform } from 'shared/ui/ModalPlatform';
import { DateFormat, formatDate } from 'utils/formatDate';

import { cn } from '../DocumentsList.cn';
import { i18n } from '../DocumentsList.i18n';
import { getPersonCountry } from '../DocumentsList.utils/getDocumentCountry';

import { DetailsProps } from './Details.types';

import './Details.css';

export const Details: FC<DetailsProps> = props => {
    const {
        className,
        document,
        onClose,
        visible,
    } = props;

    const {
        first_name,
        last_name,
        middle_name,
        document_type,
        expires_on,
        issued_on,
        number,
        series,
    } = document;

    const title = [i18nDocumentType(document_type), number.slice(number.length - 4)].filter(Boolean).join(' · ');

    const country = getPersonCountry(document);

    return (
        <ModalPlatform
            className={cn('Details', [className])}
            keepMounted={false}
            title={title}
            toggleVisible={onClose}
            visible={visible}
        >
            <div className={cn('DetailsContent')}>
                <div className={cn('DetailsRow')}>
                    <ListItem
                        className={cn('DetailsItem')}
                        leadingTextParams={{
                            label: [last_name, first_name, middle_name].filter(Boolean).join(' '),
                            caption: i18nDocument('fio'),
                            inverse: true,
                        }}
                    />
                </div>
                <Divider className={cn('Divider')} />
                <div className={cn('DetailsRow')}>
                    {
                        country && (
                            <ListItem
                                className={cn('DetailsItem')}
                                leadingTextParams={{
                                    label: country,
                                    caption: i18nDocument('citizenship'),
                                    inverse: true,
                                }}
                            />
                        )
                    }
                    <ListItem
                        className={cn('DetailsItem')}
                        leadingTextParams={{
                            label: i18nDocumentType(document_type),
                            caption: i18n('document_type'),
                            inverse: true,
                        }}
                    />
                </div>
                <Divider className={cn('Divider')} />
                <div className={cn('DetailsRow')}>
                    <ListItem
                        className={cn('DetailsItem')}
                        leadingTextParams={{
                            label: formatDate(issued_on, DateFormat.DD_MM_YYYY),
                            caption: i18nDocument('issued_on'),
                            inverse: true,
                        }}
                    />
                    <ListItem
                        className={cn('DetailsItem')}
                        leadingTextParams={{
                            label: formatDate(expires_on, DateFormat.DD_MM_YYYY),
                            caption: i18nDocument('expires_on'),
                            inverse: true,
                        }}
                    />
                </div>
                <Divider className={cn('Divider')} />
                <div className={cn('DetailsRow')}>
                    <ListItem
                        className={cn('DetailsItem')}
                        leadingTextParams={{
                            label: [series, number].filter(Boolean).join(' '),
                            caption: i18nDocument('series_and_number'),
                            inverse: true,
                        }}
                    />
                </div>
            </div>
        </ModalPlatform>
    );
};
