import { FC } from 'react';

import { Card } from 'components/Card';
import { UXFeedbackEvents } from 'shared/constants/UXFeedbackEvents';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { createUxFeedbackEvent } from 'shared/ui/UXFeedback';

import { cn } from './ClosedTripStatus.cn';
import { i18n } from './ClosedTripStatus.i18n';
import { ClosedTripStatusProps } from './ClosedTripStatus.types';

import './ClosedTripStatus.css';

const sendFeedback = () => {
    createUxFeedbackEvent(UXFeedbackEvents.TRIP_FEEDBACK_SEND);
};

export const ClosedTripStatus: FC<ClosedTripStatusProps> = props => {
    const {
        className,
    } = props;

    return (
        <Card className={cn(null, [className])}>
            <Text
                as="div"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <Text
                as="div"
                className={cn('Description')}
                color="secondary"
                typography="body-s"
            >
                {i18n('description')}
            </Text>
            <Button
                className={cn('ApproveButton')}
                size="l"
                view="brand"
                width="max"
                onClick={sendFeedback}
            >
                {i18n('send_feedback')}
            </Button>
        </Card>
    );
};

ClosedTripStatus.displayName = cn();
