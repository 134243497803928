import { FC, useCallback, useMemo, useState } from 'react';
import { Pagination } from '@yandex-int/hr-components/Pagination/desktop/bundle';

import { useCompaniesList } from 'api/hooks/useCompaniesList';
import { TableSkeleton } from 'components/TableSkeleton';
import { withRemote } from 'hocs/withRemote';
import { useTablePagination } from 'hooks/useTablePagination';
import { TGet_companies_listQuery } from 'services/SwaggerApi';
import { hasData } from 'utils/Loadable';

import { TableCompaniesHeaderFields } from './Header/Header.types';
import { serializeFilters } from './utils/serializeFilters';
import { Header } from './Header';
import { TableCompanies } from './TableCompanies';
import { cn } from './TableCompanies.cn';
import {
    TableCompaniesContainerProps,
} from './TableCompanies.types';

const TableCompaniesRemote = withRemote(TableCompanies);

const defaultFilters: TableCompaniesHeaderFields = {
    companyName: '',
};

export const TableCompaniesContainer: FC<TableCompaniesContainerProps> = props => {
    const {
        className,
    } = props;

    const [filters, setFilters] = useState(defaultFilters);

    const {
        paginationProps,
        computePagesCount,
        resetCurrentPage,
    } = useTablePagination();

    const {
        pageSize,
        currentPage,
    } = paginationProps;

    const queryParams: TGet_companies_listQuery = useMemo(() => ({
        ...serializeFilters(filters),
        page: currentPage,
        limit: pageSize,
    }), [currentPage, filters, pageSize]);

    const companies = useCompaniesList(queryParams);
    const companiesCount = hasData(companies) ? companies.result.count : 0;
    const pagesCount = computePagesCount(companiesCount);

    const handleFiltersChange = useCallback(data => {
        resetCurrentPage();
        setFilters(data);
    }, [resetCurrentPage]);

    return (
        <>
            <div className={cn(null, [className])}>
                <Header
                    onFiltersChange={handleFiltersChange}
                />
                <TableCompaniesRemote
                    data={companies}
                    pageCount={pagesCount}
                    pageIndex={currentPage}
                    pageSize={pageSize}
                    skeleton={
                        <TableSkeleton
                            className={cn('Skeleton')}
                            columns={5}
                            rows={10}
                        />
                    }
                />
            </div>
            {pagesCount > 0 && (
                <Pagination
                    {...paginationProps}
                    className={cn('Pagination')}
                    pagesCount={pagesCount}
                />
            )}
        </>
    );
};

TableCompaniesContainer.displayName = cn();
