import { i18nConf } from 'i18n/i18nConf';
import { Trip } from 'services/SwaggerApi';
import { transliterate } from 'shared/lib/text/transliterate';
import { Dot } from 'shared/ui/Dot';
import { getTranslatedRouteCities } from 'utils/aeroclub/getRouteCities';
import { getTypedI18nLang } from 'utils/i18n';

const transliterateInEn = (city: string) => {
    const needTranslit = getTypedI18nLang() === 'en';

    return needTranslit ? transliterate(city) : city;
};

export const getTripTitle = (trip: Trip) => {
    const { route, conf_details } = trip;
    const { conf_city } = conf_details || {};

    if (route && route.length > 0) {
        const cities = route.map(point => point.city.name);

        return getTranslatedRouteCities(...cities);
    }

    if (conf_city) {
        const city = transliterateInEn(conf_city.split(',')[0]);

        return [i18nConf('conference'), city].join(` ${Dot} `);
    }

    return [i18nConf('conference'), i18nConf('online')].join(` ${Dot} `);
};
