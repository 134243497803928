export const ru = {
    addDocumentLink: 'Добавить документ',
    addHotelLink: 'Выбрать гостиницу',
    add_airline_ticket: 'Добавить авиабилет',
    add_airline_ticket_back: 'Добавить авиабилет обратно',
    add_participants: 'Добавить участников',
    add_train_ticket: 'Добавить ЖД билет',
    add_train_ticket_back: 'Добавить ЖД билет обратно',
    add_train_ticket_to_destination: 'Добавить ЖД билет туда',
    arrival_to_airport: 'Прибытие в аэропорт',
    arrival_to_railway: 'Прибытие на вокзал',
    boarding_train: 'Посадка в поезд {code} {train}, вагон {carriage}',
    business_trip: 'Командировка',
    cancel: 'Нет',
    cancel_confirmation: 'Вы уверены, что хотите отменить заявку?',
    cancel_confirmation_with_services:
        'Мы запустим отмену оформленных услуг, если такая возможность будет доступна. Как правило, отмена услуг невозможна или происходит со штрафами. Для уточнения возможных штрафов перед отменой, обратитесь в поддержку.',
    cancel_trip: 'Отменить мою заявку',
    check_in: 'Заселение в отель "{name}", {address}',
    check_out: 'Выезд из отеля',
    confirm: 'Да, отменить',
    documentsHeader: 'Документ',
    fix_application_date: 'Не получается создать поиск, так как даты заявки в прошедшем времени, поправьте их',
    flight_arrival: 'Прилет в {city}, {airport_w_terminal}',
    flight_departure: 'Вылет из {city}, {airport_w_terminal}',
    group_trip: 'Групповая заявка',
    hotelHeader: 'Гостиница',
    other_services: 'Другие услуги',
    stay_in: 'Проживание в отеле {hotel}, {address}',
    taxi_from_airport: 'Такси от аэропорта {airport_w_terminal}',
    taxi_from_hotel: 'Такси от отеля {hotel}, {address}',
    taxi_from_rail: 'Такси от вокзала {station}',
    taxi_home: 'Такси домой',
    taxi_to_airport: 'Такси до аэропорта {airport_w_terminal}',
    taxi_to_hotel: 'Такси до отеля {hotel}, {address}',
    taxi_to_rail: 'Такси до вокзала {station}',
    terminal: 'терминал',
    ticketsHeader: 'Билеты',
    train_arrival: 'Прибытие поезда в {city}, {station}',
    train_departure: 'Отправление поезда из {city}, {station}',
    tripLoading: 'Загрузка командировки',
    trip_number: 'Заявка номер {trip_id}',
    taxi_hint: 'Время указано ориентировочно без учета пробок.',
    yes_understand: 'Да, понятно',
};
