export interface IFilterOption<T = string> {
    target_id: T;
    caption: string;
}

export interface IFilterOptionTime<T = string> {
    target_id: T;
    name: {
        en: string;
        ru: string;
    };
    time: string;
    hours: string;
}

const generateTimeOption = (
    target_id: string,
    en: string,
    ru: string,
    time: string,
    hours: string,
): IFilterOptionTime =>
    ({ target_id, name: { en, ru }, time, hours });

export const travelTimeOptions = [
    generateTimeOption('Night', 'AM', 'Ночь', '00:00 - 06:00', '00 - 06'),
    generateTimeOption('Morning', 'AM', 'Утро', '06:00 - 12:00', '06 - 12'),
    generateTimeOption('Afternoon', 'PM', 'День', '12:00 - 18:00', '12 - 18'),
    generateTimeOption('Evening', 'PM', 'Вечер', '18:00 - 00:00', '18 - 00'),
];
