import { FC } from 'react';
import { IClassNameProps } from '@bem-react/core';

import { FlightSearchCard } from 'components/FlightSearchCard';
import { HotelSearchCard } from 'components/HotelSearchCard';
import { TrainSearchCard } from 'components/TrainSearchCard';
import { Flight, Hotel, Train } from 'services/SwaggerApi';

type ServiceCardTypedProps = IClassNameProps & {
    searchId: string;
    tripId?: number;
    personId: string;
    service: AviaService | HotelService | TrainService;
    adultsCount: number;
    canAddServices: boolean;
};

export type AviaService = {
    service: Flight;
    service_type: 'avia';
};

export type HotelService = {
    service: Hotel;
    service_type: 'hotel';
};

export type TrainService = {
    service: Train;
    service_type: 'rail';
};

export const ServiceCardTyped: FC<ServiceCardTypedProps> = props => {
    const { service } = props;

    switch (service.service_type) {
        case 'rail':
            return <TrainSearchCard {...props} service={service.service} />;

        case 'avia':
            return <FlightSearchCard {...props} service={service.service} />;

        case 'hotel':
            return <HotelSearchCard {...props} service={service.service} />;

        default:
            return null;
    }
};
