import { FC } from 'react';

import { UserPane } from 'components/UserPane';

import { cn } from './UserSelected.cn';
import { UserSelectedProps } from './UserSelected.interface';

import './UserSelected.css';

export const UserSelected: FC<UserSelectedProps> = props => {
    return (
        <div className={cn(null, [props.className])}>
            <UserPane {...props} />
            <button className={cn('Exclude')} type="button" onClick={props.onCloseClick} />
        </div>
    );
};
