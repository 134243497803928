import { FC, useCallback } from 'react';
import { Slider } from '@yandex-lego/components/Slider/desktop/bundle';

import { Text } from 'shared/ui/Text';
import { Textinput } from 'shared/ui/Textinput';

import { cn } from './FilterFieldPriceRange.cn';
import { i18n } from './FilterFieldPriceRange.i18n';
import { FilterFieldPriceRangeProps } from './FilterFieldPriceRange.types';

import './FilterFieldPriceRange.css';

export const FilterFieldPriceRange: FC<FilterFieldPriceRangeProps> = props => {
    const { value: selected, onChange } = props.input;
    const { range } = props;

    const handleInput = useCallback((_event: React.ChangeEvent<HTMLInputElement>, value: number[]) => {
        return onChange([...value]);
    }, [onChange]);

    const handleFromInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value) > selected[1] ? selected[1] : Number(event.target.value);

        return onChange([newValue, selected[1]]);
    }, [onChange, selected]);

    const handleToInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(event.target.value) < selected[0] ? selected[0] : Number(event.target.value);

        return onChange([selected[0], newValue]);
    }, [onChange, selected]);

    return (
        <div className={cn(null, [props.className])}>
            <div className={cn('PriceInput')}>
                <Text>{i18n('from')}</Text>
                <Textinput
                    inputMode="numeric"
                    max={selected[1]}
                    min={range[0]}
                    size="m"
                    type="number"
                    value={selected[0]}
                    view="default"
                    onInput={handleFromInput}
                />
                <Text>{i18n('to')}</Text>
                <Textinput
                    inputMode="numeric"
                    min={selected[0]}
                    size="m"
                    type="number"
                    value={selected[1]}
                    view="default"
                    onInput={handleToInput}
                />
            </div>
            <Slider
                filled
                max={range[1]}
                min={range[0]}
                step={500}
                value={selected}
                view="default"
                onInput={handleInput}
            />
        </div>
    );
};

FilterFieldPriceRange.displayName = cn();
