import * as z from 'zod';

import { ActivePage } from 'shared/constants/ActivePage';
import { checkAccessFactory } from 'utils/checkAccessFactory';

import { route } from './route';

export const TABS = ['companies', 'travel_policies', 'groups'] as const;
export type Tab = (typeof TABS)[number];

export const RouteCompaniesDecoder = z.object({
    params: z.object({}),
    search: z.object({
        tab: z.enum(TABS).optional(),
    }),
});

export type RouteCompanies = z.output<typeof RouteCompaniesDecoder>;

const checkAccess = checkAccessFactory(
    meta => Boolean(meta.is_coordinator),
    'not_found',
);

export const RouteCompanies = route(
    '/companies',
    RouteCompaniesDecoder,
    {
        activePage: ActivePage.COMPANIES,
        checkAccess,
    },
);
