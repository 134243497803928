import { Page } from 'components/Page';
import { RouteCompanyDetails } from 'routes/RouteCompanyDetails';
import { Text } from 'shared/ui/Text';

import { cn } from './PageCompanyDetails.cn';

export const PageCompanyDetails: Page<RouteCompanyDetails> = () => {
    return (
        <Page className={cn()}>
            <Text align="center" as="h3" weight="medium">Company details page</Text>
        </Page>
    );
};

PageCompanyDetails.displayName = cn();
