import { EntryRestrictions } from 'components/CovidRestrictions/RestrictionsDecoder';
import { SwaggerApi } from 'services/SwaggerApi';

import { createUseQueryHook } from './createUseQueryHook';

export const useCovidRestrictions = createUseQueryHook<
    'get_restrictions',
    EntryRestrictions,
    Parameters<SwaggerApi['get_restrictions']>
    >(
        'get_restrictions',
        {
            staleTime: Infinity,
            retry: false,
            refetchOnWindowFocus: false,
        },
    );
