import { FC } from 'react';

import { Card } from 'components/Card';
import { Text } from 'shared/ui/Text';

import { cn } from '../../PageProfile.cn';
import { i18n } from '../TabTravelPolicy.i18n';
import { RulesProps } from '../TabTravelPolicy.types';

import './Rules.css';

export const Rules: FC<RulesProps> = props => {
    const { title, rules } = props;

    return (
        <Card title={title}>
            <div className={cn('Rules')}>
                <div className={cn('RulesList')}>
                    {rules.main.length > 0 ? (
                        rules.main.map((rule, index) => (
                            <Text key={index} className={cn('RulesItem')} typography="body-m">
                                {rule}
                            </Text>
                        ))
                    ) : (
                        <Text typography="body-m">{i18n('no_restrictions')}</Text>
                    )}
                </div>
                {rules.additional.length > 0 && (
                    <div className={cn('AdditionalRules')}>
                        <Text typography="body-m" weight="medium">
                            {i18n('additional_conditions')}
                        </Text>
                        <div className={cn('RulesList')}>
                            {rules.additional.map((rule, index) => (
                                <Text key={index} className={cn('RulesItem')} typography="body-m">
                                    {rule}
                                </Text>
                            ))}
                        </div>
                    </div>
                )}
                {}
            </div>
        </Card>
    );
};
