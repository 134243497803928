export const ru = {
    tourismAvailability_true: 'туризм разрешён',
    tourismAvailability_false: 'туризм временно невозможен',

    entranceForRussians_freeEntrance: 'Нет ограничений для въезда',
    entranceForRussians_hasRestrictions: 'Есть ограничения для въезда граждан России',
    entranceForRussians_noEntrance: 'Въезд для граждан России запрещён',

    flightsAvailability_true: 'Есть прямые рейсы',
    flightsAvailability_false: 'Прямых рейсов нет',

    pcrExpirationPeriodInHoursOrRequired_true: 'Требуется ПЦР',
    pcrExpirationPeriodInHoursOrRequired_false: 'ПЦР не обязателен',
    pcrExpirationPeriodInHoursOrRequired: 'Требуется ПЦР, сделанный не более {hours} часов назад',
    quarantineRequired_true: 'Карантин по прилёту',
    quarantineRequired_false: 'Нет карантина',
    vaccineRequired_true: 'Вакцина необходима для посещения страны',
    vaccineRequired_false: 'Нет необходимости вакцинации для посещения страны',
    visaRequired_true: 'Нужна виза',
    visaRequired_false: 'Виза не нужна',
};
