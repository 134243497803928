export const ru = {
    airlines: 'Авиакомпании',
    airports: 'Аэропорты',
    airports_there_back: 'Аэропорты вылета/прилета',
    apply: 'Применить',
    arrival: 'Прибытие',
    arrival_to_back: 'Прибытие обратно',
    arrival_to_there: 'Прибытие туда',
    departure: 'Отправление',
    departure_from_back: 'Отправление обратно',
    departure_from_there: 'Отправление туда',
    flight_back: 'Перелет обратно',
    flight_there: 'Перелет туда',
    flight_time_there_back: 'Время вылета/прилета',
    reset: 'Сбросить',
    search_type_error: 'Неправильный тип поиска, ожидаем Avia, Rail или Hotel',
    services_search: 'Поиск услуг',
    there_back: 'туда/обратно',
    time: 'Время',
    time_back: 'Время обратно',
    time_hint: 'Утро: 06:00 - 12:00, день: 12:00 - 18:00, вечер: 18:00 - 00:00, ночь: 00:00 - 06:00',
    time_there: 'Время туда',
    transfers: 'Пересадки',
    with_baggage: 'С багажом',
    with_changeable: 'С возможностью обмена',
    with_refundable: 'С возможностью возврата',
};
