import { ComponentProps, FC } from 'react';

import { SelectExt } from 'components/SelectExt';

import { withFormField } from '../FormField2';
import { cn } from '../FormField2.cn';
import { FormFieldProps } from '../FormField2.types';

export type WithTypeSelectExtProps = Omit<ComponentProps<typeof SelectExt>, 'value' | 'onChange'> & {
    name: string;
};

const Control: FC<WithTypeSelectExtProps & FormFieldProps> = props => {
    const {
        name,
        controller,
        context,
        ...selectProps
    } = props;

    const {
        field: { value, onChange },
    } = controller;

    return (
        <SelectExt
            {...selectProps}
            className={cn('Control', { name })}
            value={value}
            onChange={onChange}
        />
    );
};

export const FieldSelectExt = withFormField(Control);
