import { FC } from 'react';

import { Approvements } from 'components/Approvements';
import { ExecuteTrip } from 'components/ExecuteTrip';

import { ClosedTripStatus } from './ClosedTripStatus';
import { ExecutedTripStatus } from './ExecutedTripStatus';
import { ExecutingTripStatus } from './ExecutingTripStatus';
import { cn } from './TripCallToAction.cn';
import { TripCallToActionProps } from './TripCallToAction.types';

export const TripCallToAction: FC<TripCallToActionProps> = props => {
    const {
        personTrip,
        personId,
        trip_id,
        trip,
        toggleVisibleTimelineModal,
    } = props;

    const status = personTrip.combined_status;

    const noServices = personTrip.services.filter(service => service.status !== 'cancelled').length === 0;

    const isInExecuteStatus = personTrip.actions?.execute_services || personTrip.actions?.execute_extra_services;
    const canExecute = isInExecuteStatus || personTrip.services.some(service => service.status === 'draft');

    /**
     * 1. Командировка завершена
     */
    if (status === 'closed') {
        return (
            <ClosedTripStatus />
        );
    }

    /**
     * 2. Согласование командировки
     */
    if (personTrip.actions?.approve) {
        return (
            <Approvements
                personId={personId}
                tripComment={trip.comment}
                tripId={trip_id}
            />
        );
    }

    if (status === 'draft' || status === 'under_approval') {
        return (
            <ExecutingTripStatus status={status} />
        );
    }

    /**
     * 3. Можно оформлять
     * 4. Добавьте услуги
     */
    if (noServices || canExecute) {
        return (
            <ExecuteTrip
                canExecute={canExecute}
                personId={personId}
                personTrip={personTrip}
                tripId={trip_id}
            />
        );
    }

    /**
     * 5. Оформляем командировку (Согласование)
     * 6. Оформляем командировку (Оформление услуг)
     */
    if (status === 'executing' || status === 'verification') {
        return (
            <ExecutingTripStatus status={status} />
        );
    }

    /**
     * 7. Командировка оформлена
     */
    return (
        <ExecutedTripStatus toggleVisibleTimelineModal={toggleVisibleTimelineModal} />
    );
};

TripCallToAction.displayName = cn();
