import { FC } from 'react';

import { Remote } from 'components/Remote';
import { TripInfoGroup } from 'components/TripInfoGroup';
import { useTripsInfinite } from 'hooks/useTripsInfinite';

import { cn } from '../PageMyActiveTrips.cn';

import { ActiveTripsProps } from './ActiveTrips.types';

export const ActiveTrips: FC<ActiveTripsProps> = props => {
    const { uid, person_id } = props.user;

    const [trips] = useTripsInfinite({ limit: 50, active_trips_for_person: uid });

    return (
        <Remote data={trips}>
            {({ data: { items: trips } }) => (
                <>
                    {trips.map(trip => {
                        const personTrip = trip.person_trips?.find(personTrip =>
                            personTrip.person?.person_id === person_id);

                        if (!personTrip) return;

                        return (
                            <TripInfoGroup
                                key={trip.staff_trip_uuid || trip.trip_id}
                                className={cn('Trip')}
                                trip={trip}
                            />
                        );
                    })}
                </>
            )}
        </Remote>);
};
