import { ComponentProps } from 'react';
import { compose, composeU } from '@bem-react/core';
import { ILoggableProps, logClick, withBaobab } from '@yandex-int/react-baobab-logger';
import {
    Textarea as LegoTextareaBase,
    withAutoResize,
    withHasClear,
    withSizeM,
    withSizeS,
    withViewDefault,
} from '@yandex-lego/components/Textarea/desktop';

export const LegoTextarea = compose(
    composeU(withSizeM, withSizeS),
    withViewDefault,
    withHasClear,
    withAutoResize,
)(LegoTextareaBase);

export type LegoTextarea = ComponentProps<typeof LegoTextarea>;

// Настройки для Baobab
const baobabInputOptions = {
    name: 'Textarea',
    events: {
        onFocus: logClick({ type: 'focus' }),
        onBlur: logClick({ type: 'blur' }),
    },
};

// Это основной компонент с Baobab, по умолчанию используем его
export type Textarea = ILoggableProps<LegoTextarea>;
export const Textarea = withBaobab<LegoTextarea>(baobabInputOptions)(LegoTextarea);
