import { i18nDocumentType } from 'i18n/TT/i18nDocumentType';
import { ExtPersonDocument, PersonDocument } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';
import { DateFormat, formatDate } from 'utils/formatDate';

import { i18n } from '../DocumentsList.i18n';

import { isDocumentExpired } from './isDocumentExpired';

export const getDocumentItemTextParams = (document: PersonDocument | ExtPersonDocument) => {
    const {
        first_name,
        last_name,
        middle_name,
        expires_on,
        document_type,
        number,
    } = document;

    const label = [i18nDocumentType(document_type), number.slice(number.length - 4)].join(' · ');

    const fio = [last_name, first_name, middle_name].filter(Boolean).join(' ');

    if (!expires_on) {
        return {
            caption: fio,
            label,
        };
    }

    const expiredCaption = isDocumentExpired(document) ?
        <Text color="alert">{i18n('expired_message')}</Text> :
        i18n('expires_date', { date: formatDate(expires_on, DateFormat.DD_MM_YYYY) });

    return {
        caption: <>{fio} · {expiredCaption}</>,
        label,
    };
};
