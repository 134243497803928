import { useCallback } from 'react';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { HeaderPage } from 'components/HeaderPage';
import { Page } from 'components/Page';
import { Remote } from 'components/Remote';
import { ScrollButton } from 'components/ScrollButton';
import { TripInfoGroup } from 'components/TripInfoGroup';
import { TripsFilter } from 'components/TripsFilter';
import { useTripsInfinite } from 'hooks/useTripsInfinite';
import { isIOS } from 'platform/utils';
import { RouteTrips } from 'routes/RouteTrips';
import { TripsFiltersEncoder } from 'services/TripApi/encoders/TripsFilters';
import { Container } from 'shared/ui/Container';
import { foldSuccess } from 'utils/Loadable';

import { cn } from './PageTrips.cn';
import { i18n } from './PageTrips.i18n';

import './PageTrips.css';

export const PageTrips: Page<RouteTrips> = props => {
    const { search } = props;
    const filters = TripsFiltersEncoder.encode(search);
    const [trips, {
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    }] = useTripsInfinite({
        tracker_issue: filters.tracker_issue,
        city_to_geoid: filters.city_to,
        date_from__gte: filters.date_from__gte,
        date_from__lte: filters.date_from__lte,
        manager: filters.manager,
        person: filters.person,
        limit: isIOS() ? 5 : 50,
    });
    const handleFetchMore = useCallback(() => fetchNextPage(), [fetchNextPage]);

    const tripsCount = foldSuccess(trips, trips => String(trips.count), () => '');

    return (
        <Page className={cn()}>
            <HeaderPage title={i18n('trips')} titleSecondary={tripsCount}>
                <TripsFilter {...props} />
            </HeaderPage>

            <Container grid className={cn('Container')}>
                <Remote spinner data={trips}>
                    {({ data: { items } }) => (
                        <div className={cn('Trips')}>
                            {items.map(trip =>
                                <TripInfoGroup
                                    key={trip.staff_trip_uuid || trip.trip_id}
                                    trip={trip}
                                />,
                            )}
                        </div>
                    )}
                </Remote>
                {hasNextPage && (
                <ButtonPlatform className={cn('ShowMore')} progress={Boolean(isFetchingNextPage)} onClick={handleFetchMore}>
                    {i18n('show-more')}
                </ButtonPlatform>
                )}
            </Container>
            <ScrollButton />
        </Page>
    );
};

PageTrips.displayName = cn();
