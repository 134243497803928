export enum ErrorName {
    BadRequestError = 'BadRequestError',
    ConnectionError = 'ConnectionError',
    DecodeError = 'DecodeError',
    ForbiddenError = 'ForbiddenError',
    GatewayTimeoutError = 'GatewayTimeoutError',
    ImpossibleError = 'ImpossibleError',
    InternalServerError = 'InternalServerError',
    NotFoundError = 'NotFoundError',
    PageParamsDecodeError = 'PageParamsDecodeError',
    UnauthorizedError = 'UnauthorizedError',
    UnknownError = 'UnknownError',
    UnprocessableEntityError = 'UnprocessableEntityError',
    RoomDescriptionTextError = 'RoomDescriptionTextError',
    YaAuthDataProviderError = 'YaAuthDataProviderError',
}
