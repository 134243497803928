import { logError } from './logError';
import { responseToHTTPError } from './responseToHTTPError';

export function throwHTTPErrors<T extends ApiResponse>(response: T) {
    switch (response.status) {
        case 200:

        case 201:

        case 204:
            return response.body as ExtractResponseSuccess<T>;

        default:
            const error = responseToHTTPError(response as ResponseOnStatus<ErrorCode, unknown>);

            console.error(error);
            logError(error);
            throw error;
    }
}
