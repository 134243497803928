import { FC } from 'react';
import { compose, withBemMod } from '@bem-react/core';
import { Button, cnButton } from '@yandex-lego/components/Button';

import { IconMessage } from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { Text } from 'shared/ui/Text';

import { cn } from './MessengerButton.cn';
import { i18n } from './MessengerButton.i18n';
import { MessengerButtonProps } from './MessengerButton.types';

import './MessengerButton.css';

export interface ButtonViewMessengerProps {
    view?: 'messenger';
}

const withViewMessengerButton = withBemMod<ButtonViewMessengerProps>(cnButton(), { view: 'messenger' });

const MessengerButtonView = compose(
    withViewMessengerButton,
)(Button);

const messengerIcon = () => <IconMessage className={cn('MessengerIcon')} size="l" />;

/**
 * Кнопка для общения первой линии поддержки с поставщиком
 */
export const MessengerButton: FC<MessengerButtonProps> = props => {
    const { isDesktop } = usePlatform();
    const { onClick, isBreakInChat, className } = props;

    return (
        <MessengerButtonView
            className={cn(null, [className])}
            iconLeft={messengerIcon}
            view="messenger"
            onClick={onClick}
        >
            {isDesktop && (
                <Text className={cn('Text')} color="inherit" typography="body-m">
                    {isBreakInChat ? i18n('break_in_chat') : i18n('chat')}
                </Text>
            )}
        </MessengerButtonView>
    );
};

MessengerButton.displayName = cn();
