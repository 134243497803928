import { FC, useCallback, useReducer, useState } from 'react';
import { useToggle } from 'react-use';
import { RadioButton } from '@yandex-lego/components/RadioButton/touch-phone/bundle';

import { Card } from 'components/Card';
import { FloatingIsland } from 'components/FloatingIsland';
import { HeaderPage } from 'components/HeaderPage';
import { IconArrowShortRight, IconGroup } from 'components/Icon';
import { MessageBanner } from 'components/MessageBanner';
import { Page } from 'components/Page';
import { UnknownError } from 'errors';
import { useDeleteService } from 'hooks/useDeleteService';
import { usePlatform } from 'hooks/usePlatform';
import { i18nCarriageType } from 'i18n/i18nCarriageType';
import { Container } from 'shared/ui/Container';
import { Drawer } from 'shared/ui/Drawer';
import { Text } from 'shared/ui/Text';
import { getCarriageTypes } from 'utils/aeroclub/getCarriageTypes';
import { logError } from 'utils/logError';

import { TicketsNotFoundError } from './TicketsNotFoundError/TicketsNotFoundError';
import { getPreferredCarriageType } from './TrainDetails.utils/getPreferredCarriageType';
import { servicesAddReducer } from './TrainDetails.utils/serviceAddReducer';
import { sortCarriages } from './TrainDetails.utils/sortCarriages';
import { Passengers } from './Passengers';
import { TrainCarriage } from './TrainCarriage';
import { cn } from './TrainDetails.cn';
import { i18n } from './TrainDetails.i18n';
import { TrainDetailsProps } from './TrainDetails.types';

import './TrainDetails.css';

export const TrainDetails: FC<TrainDetailsProps> = props => {
    const { trainDetails, search: { trip_id, person_id, name }, canAddServices } = props;

    const carriages = trainDetails.data;
    const carriageTypes = getCarriageTypes(carriages);
    const [carriageType, setCarriageType] = useState(getPreferredCarriageType(carriageTypes));

    const [isServicesDrawerVisible, toggleServicesDrawerVisible] = useToggle(false);
    const [addedServices, dispatchService] = useReducer(servicesAddReducer, []);

    const [deleteService] = useDeleteService({
        onSuccess: (_, serviceId) => dispatchService({
            type: 'removeService',
            payload: { serviceId },
        }),
    });

    const handleRemoveService = useCallback((serviceId: number) => () => {
        dispatchService({
            type: 'setLoading',
            payload: { serviceId: serviceId },
        });
        deleteService(serviceId);
    }, [deleteService]);

    const { isMobile, isDesktop } = usePlatform();
    const handleChangeCarriageType = useCallback(event => {
        setCarriageType(event.target.value);
    }, [setCarriageType]);

    const carriageOptions = carriageTypes.map(value => ({
        value,
        children: i18nCarriageType(value),
    }));

    const carriagesFiltered = carriages
        .filter(carriage => carriage.carriage_type === carriageType)
        .sort(sortCarriages);

    if (carriages.length === 0) {
        logError(new UnknownError('Пришел пустой список на странице выбора мест'));

        return (
            <TicketsNotFoundError />
        );
    }

    const passengersContent = (
        <Card
            className={cn('ParticipantsList')}
            description={addedServices.length === 0 ?
                i18n('no_passengers_selected') :
                i18n('passengers_selected')
            }
            size="s"
            title={i18n('passengers_list')}
        >
            <Passengers
                addedServices={addedServices}
                personId={person_id}
                tripId={trip_id!}
                onRemoveServiceClick={handleRemoveService}
            />
        </Card>
    );

    return (
        <Page>
            <HeaderPage retButton title={name} />
            <Container className={cn(null, [props.className])}>
                <div className={cn('Main')}>
                    <Text as="div" className={cn('Title')} typography="title-l">
                        {i18n('choose_tariff')}
                    </Text>
                    <div className={cn('Content')}>
                        {!trip_id &&
                            <MessageBanner messageTag="create_trip" />
                        }
                        {trip_id && !canAddServices &&
                            <MessageBanner messageTag="wait_for_approval" />
                        }
                        <div>
                            <RadioButton
                                className={cn('CarriageType')}
                                options={carriageOptions}
                                size="m"
                                value={carriageType}
                                view="default"
                                onChange={handleChangeCarriageType}
                            />
                        </div>
                        {carriagesFiltered.map(carriage => (
                            <TrainCarriage
                                key={carriage.id}
                                addedServices={addedServices}
                                dispatchService={dispatchService}
                                {...props}
                                canAddServices={canAddServices}
                                carriage={carriage}
                                trainDetails={trainDetails}
                            />
                        ))}
                    </div>
                </div>

                {isDesktop && (
                    <div className={cn('Aside')}>
                        {passengersContent}
                    </div>
                )}

                {isMobile && (
                    <>
                        <FloatingIsland
                            leading={<IconGroup className={cn('FloatingIslandIcon')} size="m" />}
                            leadingTextParams={{
                                label: i18n('passengers_list'),
                                caption: i18n('passengers_added', { count: addedServices.length }),
                            }}
                            trailing={<IconArrowShortRight size="m" />}
                            onClick={toggleServicesDrawerVisible}
                        />
                        <Drawer
                            view="default"
                            visible={isServicesDrawerVisible}
                            onClose={toggleServicesDrawerVisible}
                        >
                            {passengersContent}
                        </Drawer>
                    </>
                )}
            </Container>
        </Page>
    );
};

TrainDetails.displayName = cn();
