import { useMemo } from 'react';

import { MenuOption } from 'components/ActionsMenu';
import {
    getIconProvider,
    IconBan,
    IconCategory,
    IconChat,
    IconDislike,
    IconFlag,
    IconGroup,
    IconLike,
    IconUnlock } from 'components/Icon';
import { Employee, EmployeeActions } from 'services/SwaggerApi';

import { i18n } from '../TableEmployees.i18n';
import { ActionsCallbacks } from '../TableEmployees.types';

type UseMenuOptions = (
    actions: EmployeeActions,
    actionsCallbacks: ActionsCallbacks,
    employee: Employee,
) => MenuOption[];

const option = (option: MenuOption): MenuOption => option;

export const useMenuOptions: UseMenuOptions = (actions, actionsCallbacks, employee) => {
    const {
        onActivate,
        onChangeApprover,
        onGrantCoordinator,
        onAssignAssistant,
        onRestore,
        onRevokeCoordinator,
        onBlock,
        onReject,
        onRemoveAssistant,
        onChangeCompany,
    } = actionsCallbacks;

    return useMemo<MenuOption[]>(() => [
        actions.assign_assistant && option({
            label: i18n('action_assign_assistant'),
            icon: getIconProvider(IconGroup),
            onClick: () => onAssignAssistant(employee),
        }),
        actions.activate && option({
            label: i18n('action_activate'),
            icon: getIconProvider(IconLike),
            onClick: () => onActivate(employee),
        }),
        actions.change_approver && option({
            label: i18n('action_change_approver'),
            icon: getIconProvider(IconChat),
            onClick: () => onChangeApprover(employee),
        }),
        actions.change_company && option({
            label: i18n('action_change_company'),
            icon: getIconProvider(IconCategory),
            onClick: () => onChangeCompany(employee),
        }),
        actions.grant_coordinator && option({
            label: i18n('action_grant_coordinator'),
            icon: getIconProvider(IconFlag),
            onClick: () => onGrantCoordinator(employee),
        }),
        actions.restore && option({
            label: i18n('action_restore'),
            icon: getIconProvider(IconUnlock),
            onClick: () => onRestore(employee),
        }),
        actions.revoke_coordinator && option({
            label: i18n('action_revoke_coordinator'),
            icon: getIconProvider(IconFlag),
            onClick: () => onRevokeCoordinator(employee),
        }),
        actions.remove_assistant && option({
            label: i18n('action_remove_assistant'),
            view: 'danger',
            icon: getIconProvider(IconGroup),
            onClick: () => onRemoveAssistant(employee),
        }),
        actions.block && option({
            label: i18n('action_block'),
            view: 'danger',
            icon: getIconProvider(IconBan),
            onClick: () => onBlock(employee),
        }),
        actions.reject && option({
            label: i18n('action_reject'),
            view: 'danger',
            icon: getIconProvider(IconDislike),
            onClick: () => onReject(employee),
        }),
    ].filter(Boolean),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions, actionsCallbacks, employee]);
};
