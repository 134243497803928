import { useMemo } from 'react';

import { TrainCarriageDetail } from 'services/SwaggerApi';
import { TYCarriageSchema, TYCoach, TYTrainDetails } from 'services/TravelApi/TYTrainDetails';
import { mapLoadable, RemoteData } from 'utils/Loadable';

type TrainTravel = {
    coach: TYCoach;
    schema: TYCarriageSchema;
};

type UseMapToTrainTravel = (
    trainDetails: RemoteData<TYTrainDetails>,
    carriageNumber: TrainCarriageDetail['carriage_number']
) => RemoteData<TrainTravel | null>;

export const useMapToTrainTravel: UseMapToTrainTravel = (trainDetails, carriageNumber) => {
    return useMemo(() => mapLoadable(
        trainDetails,
        ({ trainDetails: { coaches, schemas } }) => {
            const coach = coaches.find(coach => Number(coach.number) === carriageNumber);
            const schema = schemas.find(schema => schema.id === coach?.schemaId);

            if (!coach || !schema) {
                return null;
            }

            return {
                coach,
                schema,
            };
        },
    ), [trainDetails, carriageNumber]);
};
