import { FC } from 'react';

import { SupportChatLink } from 'components/SupportChatLink';
import { ErrorName } from 'errors/ErrorName';
import { createUseQueryHook } from 'hooks/createUseQueryHook';
import { InlineNotification } from 'shared/ui/InlineNotification';
import { getFailureError } from 'utils/getFailureError';

import { cn } from './FailedSyncInfo.cn';
import { i18nRaw } from './FailedSyncInfo.i18n';
import { FailedSyncInfoProps } from './FailedSyncInfo.types';

export const FailedSyncInfo: FC<FailedSyncInfoProps> = props => {
    const { personId } = props;

    const aeroclubProfile = createUseQueryHook(
        'get_aeroclub_profile_by_person_id',
        { retry: false },
    )(personId);

    // Ошибка синхронизации приходит со статусом 422 UnprocessableEntityError.
    const hasSyncFailure = getFailureError(aeroclubProfile)?.errorName === ErrorName.UnprocessableEntityError;

    if (!hasSyncFailure) {
        return null;
    }

    const supportChatLink = <SupportChatLink />;

    return (
        <InlineNotification
            className={cn(null, [props.className])}
            icon="error"
            view="error"
        >
            {i18nRaw('ak_sync_error', { supportChatLink })}
        </InlineNotification>
    );
};

FailedSyncInfo.displayName = cn();
