import { SwaggerApi } from 'services/SwaggerApi';
import { Item } from 'shared/ui/Suggest';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

type FetchTravelPolicyOptions = {
    api: SwaggerApi;
    value: unknown;
};

export async function fetchTravelPolicy({ api, value }: FetchTravelPolicyOptions): Promise<Item | null> {
    const result = await api.get_travel_policy_detailed(value as number, {})
        .then(throwHTTPErrors).then(response => response)
        .catch(() => null);

    if (!result) {
        return null;
    }

    const { travel_policy_id, name } = result;

    return {
        id: travel_policy_id,
        title: name,
    };
}
