import { compose, composeU } from '@bem-react/core';
import { withMarker, withSizeL } from '@yandex-int/hr-components/TabsMenu';
import { TabsMenu as TabsMenuDesktop, withLayoutHoriz, withSizeM, withViewDefault } from '@yandex-lego/components/TabsMenu/desktop';

import './TabsMenu.css';

export const TabsMenu = compose(
    composeU(withSizeL, withSizeM),
    withLayoutHoriz,
    withMarker,
    withViewDefault,
)(TabsMenuDesktop);
