import { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { Card } from 'components/Card';
import { SupportChatButton } from 'components/SupportChatButton';
import { i18nNavigation } from 'i18n/i18nNavigation';
import { Button } from 'shared/ui/Button';
import { ErrorState } from 'shared/ui/ErrorState';

import { cn } from '../PagePersonTrip.cn';
import { i18n } from '../PagePersonTrip.i18n';

import { EmptyStateProps } from './EmptyState.types';

import './EmptyState.css';

export const EmptyState: FC<EmptyStateProps> = props => {
    const {
        type,
        className,
    } = props;

    const history = useHistory();

    const onListLinkButtonClick = useCallback(event => {
        event.stopPropagation();
        event.preventDefault();

        history.push('/trips');
    }, [history]);

    const actions = useMemo(() => {
        switch (type) {
            case 'cancelled':
                return [
                    <Button
                        key="trips-list"
                        size="l"
                        view="brand"
                        onClick={onListLinkButtonClick}
                    >
                        {i18nNavigation('to_trips_list')}
                    </Button>,
                    <SupportChatButton
                        key="support-button"
                        className={cn('SupportChatButton')}
                        view="ghost"
                    >
                        {i18n('chat_with_support')}
                    </SupportChatButton>,
                ];

            case 'conference_without_trip':
                return [
                    <SupportChatButton
                        key="support-button"
                        className={cn('SupportChatButton')}
                    >
                        {i18n('chat_with_support')}
                    </SupportChatButton>,
                ];
        }
    }, [onListLinkButtonClick, type]);

    return (
        <Card className={cn('EmptyState', [className])}>
            <ErrorState
                actions={actions}
                description={i18n(`${type}_description`)}
                size="m"
                title={i18n(`${type}_title`)}
            />
        </Card>
    );
};
