import { HotelMark } from '../GeoMap.types';

const MIN_MAP_SIZE = 500; // метров

export function getBounds(marks: HotelMark[]) {
    if (marks.length === 0) return undefined;

    const min = [
        Math.min(...marks.map(m => m.latitude)),
        Math.min(...marks.map(m => m.longitude)),
    ];
    const max = [
        Math.max(...marks.map(m => m.latitude)),
        Math.max(...marks.map(m => m.longitude)),
    ];

    // 111км в 1 градусе широты
    const LATITUDE_1_IN_KM = 111000;
    // 70км в 1 градусе долготы
    const LONGITUDE_1_IN_KM = 70000;

    const boundsLatitudeMeters = (max[0] - min[0]) * LATITUDE_1_IN_KM;

    // если меньше MIN_MAP_SIZE метров
    if (boundsLatitudeMeters < MIN_MAP_SIZE) {
        const avgLatitude = (max[0] + min[0]) / 2;

        max[0] = avgLatitude + MIN_MAP_SIZE / 2 / LATITUDE_1_IN_KM;
        min[0] = avgLatitude - MIN_MAP_SIZE / 2 / LATITUDE_1_IN_KM;
    }

    const boundsLongitudeMeters = (max[0] - min[0]) * LONGITUDE_1_IN_KM;

    // если меньше MIN_MAP_SIZE метров
    if (boundsLongitudeMeters < MIN_MAP_SIZE) {
        const avgLatitude = (max[0] + min[0]) / 2;

        max[0] = avgLatitude + MIN_MAP_SIZE / 2 / LONGITUDE_1_IN_KM;
        min[0] = avgLatitude - MIN_MAP_SIZE / 2 / LONGITUDE_1_IN_KM;
    }

    return [min, max];
}
