import { ActivePage } from 'shared/constants/ActivePage';
import { checkAccessFactory } from 'utils/checkAccessFactory';

import { CheckAccess } from './Route';

export type RouteOptions = {
    activePage: ActivePage;
    shouldWrap?: boolean;
    isAdminPanel?: boolean;
    checkAccess: CheckAccess;
};

export const defaultRouteOptions = {
    shouldWrap: true,
    isAdminPanel: false,
    activePage: ActivePage.ALL_TRIPS_LIST,
    checkAccess: checkAccessFactory(() => true, 'not_found'),
};
