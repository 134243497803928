import { FC } from 'react';

import { Cell } from 'components/Cell';
import { withThAttrs } from 'hocs/withThAttrs';

import { cn } from './CellHeader.cn';
import { CellHeaderProps } from './CellHeader.types';

import './CellHeader.css';

const CellHeaderBase: FC<CellHeaderProps> = props => {
    const {
        className,
        align = 'left',
        value,
        getAttrs,
    } = props;

    const justifyContent = align === 'left' ? 'start' : 'end';

    return (
        <Cell
            as="th"
            className={cn({ align }, [className])}
            getAttrs={getAttrs}
            justifyContent={justifyContent}
            size="m"
        >
            {value}
        </Cell>
    );
};

export const CellHeader = withThAttrs(CellHeaderBase);
CellHeader.displayName = cn();
