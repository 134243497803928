import { FC } from 'react';
import { Skeleton } from '@yandex-int/hr-components/Skeleton';

import { Cell } from 'components/Cell';
import { useArrayWithReactNode } from 'hooks/useArrayWithReactNode';

import { cn } from './TableSkeleton.cn';
import { TableBodySkeletonProps, TableRowSkeletonProps } from './TableSkeleton.types';

import './TableSkeleton.css';

const TableCellSkeleton: FC = () => {
    return (
        <Cell
            className={cn('Cell')}
            justifyContent="stretch"
            size="m"
        >
            <Skeleton
                className={cn('Text')}
                size="m"
                type="text"
            />
        </Cell>
    );
};

const TableRowSkeleton: FC<TableRowSkeletonProps> = props => {
    const {
        className,
        columns,
    } = props;

    const children = useArrayWithReactNode(columns, TableCellSkeleton, {});

    return (
        <tr className={cn('Row', [className])}>
            {children}
        </tr>
    );
};

export const TableSkeleton: FC<TableBodySkeletonProps> = props => {
    const {
        className,
        columns,
        rows,
    } = props;

    const rowsContent = useArrayWithReactNode(rows, TableRowSkeleton, { columns });

    return (
        <table className={cn(null, [className])}>
            <thead className={cn('Head')}>
                <TableRowSkeleton columns={columns} />
            </thead>
            <tbody>
                {rowsContent}
            </tbody>
        </table>
    );
};

TableSkeleton.displayName = cn();
