import { FC } from 'react';

import { SegmentHotel } from './_type/TripRouteSegment_type_hotel';
import { SegmentTransport } from './_type/TripRouteSegment_type_transport';
import { cn } from './TripRouteSegment.cn';
import { TripRouteSegmentProps } from './TripRouteSegment.types';

import './TripRouteSegment.css';

export const TripRouteSegment: FC<TripRouteSegmentProps> = props => {
    switch (props.segment.type) {
        case 'hotel':
            return <SegmentHotel {...props} segment={props.segment} />;

        case 'transport':
            return <SegmentTransport {...props} segment={props.segment} />;
    }
};

TripRouteSegment.displayName = cn();
