import { ExtPersonDocument, PersonDocument } from 'services/SwaggerApi';
import { isPersonDocument } from 'types/guards/isPersonDocument';
import { getTypedI18nLang } from 'utils/i18n';

export const getPersonCountry = (document: PersonDocument | ExtPersonDocument) => {
    if (isPersonDocument(document)) {
        const lang = getTypedI18nLang();

        return document.country.name[lang];
    }

    return null;
};
