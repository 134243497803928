import { PersonTrip } from 'services/SwaggerApi';

import { PersonTripContentType } from '../PagePersonTrip.types';

export const getPersonTripContentType = (personTrip: PersonTrip): PersonTripContentType => {
    const hasRoute = personTrip.route && personTrip.route.length > 0;
    const isCancelled = personTrip.status === 'cancelled';

    if (isCancelled) {
        return 'cancelled';
    }

    if (!hasRoute) {
        return 'conference_without_trip';
    }

    return 'general';
};
