import { FC } from 'react';

import { DocumentForm } from 'components/DocumentForm';
import { withRemote } from 'hocs/withRemote';
import { useExtPersonGet } from 'hooks/useExtPersonGet';
import { usePlatform } from 'hooks/usePlatform';
import { ModalLayout } from 'shared/ui/ModalLayout';
import { mapLoadable } from 'utils/Loadable';

import { cn } from '../AddCompanionDocument.cn';

import { FormSlideProps } from './FormSlide.types';

import './FormSlide.css';

const DocumentViewRemote = withRemote(DocumentForm);

export const FormSlide: FC<FormSlideProps> = props => {
    const {
        onSubmit,
        onCancel,
        personId,
    } = props;

    const { platform } = usePlatform();

    const extPerson = useExtPersonGet(personId, { isEnabled: true });

    const person = mapLoadable(extPerson, extPerson => ({
        ...extPerson,
        person_id: extPerson.ext_person_uuid,
    }));

    return (
        <ModalLayout
            className={cn('FormSlide')}
            platform={platform}
            title=""
            onClose={onCancel}
        >
            <DocumentViewRemote
                personInfo={person}
                personType="extPerson"
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
        </ModalLayout>
    );
};
