import * as E from 'io-ts/Encoder';

import { NumberToBooleanInverse } from 'utils/io-ts/encoders/NumberToBooleanInverse';
import { StringToArray } from 'utils/io-ts/encoders/StringToArray';

export const SearchParamsToTrainFilters = E.partial({
    departure_there_timespan: StringToArray,
    arrival_there_timespan: StringToArray,
    carriers: StringToArray,
    departurefromthere: StringToArray,
    arrivaltothere: StringToArray,
    carriage_types: StringToArray,
    train_categories: StringToArray,
    train_names: StringToArray,
    is_travel_policy_compliant: NumberToBooleanInverse,
});
