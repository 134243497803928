import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { FormState } from 'final-form';

import { getOptionValue } from 'components/FieldSort/FieldSort.utils/getOptionValue';
import { FlightFilters } from 'components/FlightFilters';
import { HotelFilters } from 'components/HotelFilters';
import { TrainFilters } from 'components/TrainFilters';
import { RouteSearch } from 'routes/RouteSearch';
import { buildUrl } from 'utils/buildUrl';

import { cn } from './ServiceFilters.cn';
import { SearchParams, ServiceFiltersProps, ServiceFormValues } from './ServiceFilters.types';

const FilterComponents = {
    Avia: FlightFilters,
    Rail: TrainFilters,
    Hotel: HotelFilters,
} as const;

export const ServiceFilters: FC<ServiceFiltersProps> = props => {
    const {
        search,
        params: { searchId },
        instant,
        filters,
        className,
    } = props;

    // У АК и АЦ фильтрация по цене происходит по всему периоду, поэтому умножаем на количество ночей
    const tripNights = Math.abs(dayjs(search.date_from).diff((search.date_to || 0), 'day'));

    const history = useHistory();

    const onSubmit = useCallback((values: ServiceFormValues) => {
        const {
            retpath = undefined,
            person_id,
            trip_id,
            type,
        } = search;

        const {
            sortOptionValue,
            ...restValues
        } = values;

        const { order_by, is_descending } = getOptionValue(sortOptionValue);

        if (values.price_range && type === 'Hotel') {
            const [price_from, price_to] = values.price_range;

            restValues.price_from = price_from * tripNights;
            restValues.price_to = price_to * tripNights;
        }

        const searchParams: SearchParams = {
            ...restValues,
            order_by,
            is_descending,
            person_id,
            trip_id,
            type,
        };

        const url = retpath ? buildUrl({ pathname: retpath, search: searchParams }) :
            RouteSearch.getPath({ searchId }, searchParams);

        history.push(url);
    }, [history, search, searchId, tripNights]);

    const onChange = useCallback(({ values, dirty }: FormState<ServiceFormValues>) => {
        if (!dirty) return;

        onSubmit(values);
    }, [onSubmit]);

    const FilterComponent = FilterComponents[search.type];
    const isOneWay = search.type === 'Avia' && !search.date_to;

    return (
        <FilterComponent
            className={cn(null, [className])}
            filters={filters}
            instant={instant}
            isOneWay={isOneWay}
            search={search}
            tripNights={tripNights}
            onChange={onChange}
            onSubmit={onSubmit}
        />
    );
};

ServiceFilters.displayName = cn();
