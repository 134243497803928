import { BaseTrain, TGet_train_detailsQuery } from 'services/SwaggerApi';

import { getTimeWithMoscowOffset } from './getTimeWithMoscowOffset';

type GetTrainDetailsQuery = (train: BaseTrain | null) => {
    query: TGet_train_detailsQuery;
    hasAllTrainDetails: boolean;
};

const DEFAULT_TRAIN_DETAILS_QUERY: TGet_train_detailsQuery = {
    number: '',
    station_from: 0,
    station_to: 0,
    when: '',
};

export const getTrainDetailsQuery: GetTrainDetailsQuery = train => {
    if (
        train &&
        train.arrival?.train_station_code &&
        train.departure?.train_station_code &&
        train.departure_at_utc &&
        train.train_number
    ) {
        return {
            hasAllTrainDetails: true,
            query: {
                station_to: train.arrival.train_station_code,
                station_from: train.departure.train_station_code,
                number: train.train_number,
                when: getTimeWithMoscowOffset(train.departure_at_utc),
            },
        };
    }

    return {
        hasAllTrainDetails: false,
        query: DEFAULT_TRAIN_DETAILS_QUERY,
    };
};
