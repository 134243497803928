import { FC } from 'react';

import { FieldDate, FieldRadioButton, FieldTextinput } from 'components/FormField2';
import { config } from 'config';
import { i18nGender } from 'i18n/i18nGender';
import { Gender } from 'services/SwaggerApi';

import { cn } from '../FormPersonDetails.cn';
import { i18n } from '../FormPersonDetails.i18n';

const { isB2B } = config;

type PersonBaseFieldsProps = {
    allEditable?: boolean;
    withMiddleName?: boolean
};

const genders: Gender[] = ['male', 'female'];

export const PersonBaseFields: FC<PersonBaseFieldsProps> = props => {
    const {
        allEditable,
        withMiddleName = false,
    } = props;

    const genderOptions = genders.map(value => ({ value, children: i18nGender(value) }));

    return (
        <>
            <div className={cn('FieldsBlock')}>
                <FieldTextinput
                    disabled={!allEditable}
                    label={i18n('first_name')}
                    name="first_name"
                />
                <FieldTextinput
                    disabled={!allEditable}
                    label={i18n('last_name')}
                    name="last_name"
                />
                {withMiddleName && (
                    <FieldTextinput
                        disabled={!allEditable}
                        label={i18n('middle_name')}
                        name="middle_name"
                    />
                )}
            </div>
            <div className={cn('FieldsBlock')}>
                <FieldTextinput
                    label={i18n('first_name_ac_en')}
                    name="first_name_ac_en"
                />
                <FieldTextinput
                    label={i18n('last_name_ac_en')}
                    name="last_name_ac_en"
                />
                {withMiddleName && (
                    <FieldTextinput
                        label={i18n('middle_name_ac_en')}
                        name="middle_name_ac_en"
                    />
                )}
            </div>
            <div className={cn('FieldsBlock')}>
                <FieldDate
                    label={i18n('date_of_birth')}
                    name="date_of_birth"
                />
                <FieldRadioButton
                    disabled={!isB2B && !allEditable}
                    label={i18n('gender')}
                    name="gender"
                    options={genderOptions}
                    width="max"
                />
            </div>
        </>
    );
};
