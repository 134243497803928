import { FC } from 'react';

import { cn } from '../FareFamilyModal.cn';

import './SingleLayout.css';

export const SingleLayout: FC = props => {
    const {
        children,
    } = props;

    return (
        <div className={cn('SingleLayout')}>
            <div className={cn('Block')}>
                {children}
            </div>
        </div>
    );
};
