import { RefObject, useEffect } from 'react';
import dayjs from 'dayjs';

type UseScrollToSelectedDateProps = {
   isPopupVisible: boolean;
   date: Date | undefined;
   fromDate?: Date | undefined;
   datePickerRef: RefObject<HTMLDivElement>
}

/**
 * Проскролл до выбранной или первой доступной для выбора даты при открытии попапа
 */
export const useScrollToSelectedDate = (props: UseScrollToSelectedDateProps) => {
    const { date, isPopupVisible, fromDate, datePickerRef } = props;

    useEffect(() => {
        if (!isPopupVisible || (!date && !fromDate) || !datePickerRef.current) {
            return;
        }

        const scrollableDiv = datePickerRef.current;

        const startDate = date || fromDate;

        if (scrollableDiv) {
            const monthDiff = Math.abs(dayjs().startOf('month').diff(startDate, 'month'));

            const month = scrollableDiv.querySelectorAll<HTMLElement>('.rdp-month')[monthDiff];

            const scrollToY = monthDiff === 0 ? 0 : month.offsetTop - 52;

            scrollableDiv.scrollTo({ behavior: 'smooth', top: scrollToY });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPopupVisible]);
};
