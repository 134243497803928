import { useCallback, useEffect, useRef } from 'react';
import {
    buttonUIFactory,
    Options,
    PresetOptions,
    Widget,
    YandexSingleChatConfig,
    YandexTeamConfig,
    yandexUnreadCounterFactory,
} from '@yandex-int/messenger.widget';

import { i18n } from 'components/MessengerButton/MessengerButton.i18n';
import { config } from 'config';
import { UserResponse } from 'services/SwaggerApi';
import { getTypedI18nLang } from 'utils/i18n';
import { ALAPANA_MSGR_ID, getMessengerUrl } from 'utils/urls';

export type IframeOpenData = {
    deeplink: string;
    filterByMemberGuid: string;
};

let widgetGlobal: Widget | null = null;

const { messengerWidgetConfigPreset, messengerWidgetID } = config;

function useMessenger(chatIdExt?: UserResponse['chat_id']): {
    mountWidget: (ref: React.RefObject<HTMLElement>) => void;
    destroyWidget: () => void;
    openWidget: () => void;
};
function useMessenger(chatIdExt?: UserResponse['chat_id']) {
    const messengerConfig = useRef<Partial<PresetOptions & Options>>();
    const lang = getTypedI18nLang();

    const mountWidget = useCallback((ref: React.RefObject<HTMLElement>) => {
        if (!messengerConfig.current) return;

        const buttonText = i18n('chat');
        const unreadCounterPlugin = yandexUnreadCounterFactory();
        const ui = buttonUIFactory({
            // Описание всех опций https://a.yandex-team.ru/arcadia/frontend/packages/messenger.widget/src/ui/Button/Button.ts?rev=0a07aef8deddb473ce8412a998594ad43c16e8e7#L115
            unreadCounterPlugin,
            buttonText,
            collapsedDesktop: 'never',
            collapsedTouch: 'never',
        });

        const ChatConfig = chatIdExt ? YandexSingleChatConfig : YandexTeamConfig;

        if (widgetGlobal) {
            widgetGlobal.destroy();
            widgetGlobal = null;
        }

        widgetGlobal = new Widget(
            /** https://a.yandex-team.ru/svn/trunk/arcadia/frontend/packages/messenger.widget/doc/config.md#chasto-ispolzuemye-konfigi */
            new ChatConfig(messengerConfig.current),
        );

        widgetGlobal.addPlugin(unreadCounterPlugin).setUI(ui).init();

        if (ref.current) {
            ui.mount(ref.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const destroyWidget = useCallback(() => {
        if (!widgetGlobal) {
            throw new Error('Widget is not init');
        }

        widgetGlobal.destroy();
        widgetGlobal = null;
    }, []);

    const openWidget = useCallback(() => {
        if (!widgetGlobal) {
            throw new Error('Widget is not init');
        }

        widgetGlobal.show();
    }, []);

    useEffect(() => {
        const chatUrl = getMessengerUrl(chatIdExt);
        const chatId = chatIdExt || ALAPANA_MSGR_ID;

        const iframeOpenData: IframeOpenData = {
            deeplink: chatUrl,
            filterByMemberGuid: chatId,
        };

        const config: Partial<PresetOptions & Options> = {
            ...messengerWidgetConfigPreset,
            unreadCounterChatId: chatId,
            lang,
            ...{
                serviceId: messengerWidgetID,
                iframeOpenData,
            },
        };

        messengerConfig.current = config;
    }, [chatIdExt, lang]);

    return { mountWidget, destroyWidget, openWidget };
}

export { useMessenger };
