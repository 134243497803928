import { ComponentProps, FC } from 'react';
import { TableHeaderProps } from 'react-table';

import { Cell } from 'components/Cell';

type CellProps = ComponentProps<typeof Cell>;
type PropsGetAttrs = Pick<CellProps, 'getAttrs'>;

type CellWithThAttrsProps<T extends PropsGetAttrs> = T & {
    column: {
        getHeaderProps: () => TableHeaderProps;
    };
};

export const withThAttrs = <T extends PropsGetAttrs>(Cell: FC<T>) => {
    const CellWithThAttrs: FC<CellWithThAttrsProps<T>> = props => {
        const {
            column,
            ...rest
        } = props;

        // @ts-ignore
        const cellProps: T = rest;

        return (
            <Cell
                getAttrs={column.getHeaderProps}
                {...cellProps}
            />
        );
    };

    return CellWithThAttrs;
};
