import { FC, useCallback, useContext } from 'react';
import { withBemMod } from '@bem-react/core';

import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../../../SwaggerContext';
import { cn } from '../Suggest.cn';
import { i18n } from '../Suggest.i18n';
import {
    RenderOptionContent,
    RenderSelectedValue,
    SuggestProps,
} from '../Suggest.types';

export interface WithTypeCompaniesProps {
    type?: 'companies';
}

export const withTypeCompanies = withBemMod<WithTypeCompaniesProps, SuggestProps>(
    cn(),
    { type: 'companies' },
    (Suggest): FC<WithTypeCompaniesProps & SuggestProps> => props => {
        const { placeholder = i18n('companies_placeholder') } = props;

        const { api } = useContext(SwaggerContext);

        const dataprovider = useCallback(async text => {
            if (!text || text && text.length < 2) {
                return [];
            }

            const { result } = await api.company_suggest({ text }).then(throwHTTPErrors);

            return result.map(company => ({
                id: company.company_id,
                title: company.name,
            }));
        }, [api]);

        const renderOptionContent = useCallback<RenderOptionContent>(item => {
            return <>{item.title}</>;
        }, []);

        const renderSelectedValue = useCallback<RenderSelectedValue>(item => {
            return `${item.title}`;
        }, []);

        return (
            <Suggest
                {...props}
                dataprovider={dataprovider}
                placeholder={placeholder}
                renderOptionContent={renderOptionContent}
                renderSelectedValue={renderSelectedValue}
            />
        );
    },
);
