export const ru = {
    colleagues_recommend: 'Коллеги рекомендуют',
    show_on_map: 'Показать на карте',
    special_project_ya_travel: 'Специальный проект Я.Путешествий',
    wifi: 'Wi-Fi',
    breakfast: 'Завтрак',
    parking: 'Парковка',
    registration_24: 'Регистрация 24 часа',
    gym: 'Тренажерный зал',
    safe: 'Сейф',
    conditioning: 'Кондиционер',
    luggage_storage: 'Камера хранения',
    hotel: 'Отель',
    rating: 'Рейтинг',
};
