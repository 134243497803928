export const en = {
    fio: 'FIO',
    citizenship: 'Citizenship',
    expires_on: 'Valid until',
    first_name: 'First name',
    issued_on: 'Issue date',
    last_name: 'Last name',
    middle_name: 'Patronymic',
    number: 'Number',
    series: 'Series',
    series_and_number: 'Series and number',
};
