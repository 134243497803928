import { FC } from 'react';

import { cn } from '../FareFamilyModal.cn';

import { DoubleLayoutProps } from './DoubleLayout.types';

import './DoubleLayout.css';

export const DoubleLayout: FC<DoubleLayoutProps> = props => {
    const {
        left,
        right,
    } = props;

    return (
        <div className={cn('DoubleLayout')}>
            <div className={cn('Block', { type: 'left' })}>
                {left}
            </div>
            <div className={cn('Block', { type: 'right' })}>
                {right}
            </div>
        </div>
    );
};
