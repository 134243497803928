import { FC } from 'react';

import { Card } from 'components/Card';
import { LoyaltyCards } from 'components/LoyaltyCards';
import { withRemote } from 'hocs/withRemote';
import { usePersonBonusCards } from 'hooks/usePersonBonusCards';
import { BonusCard } from 'services/SwaggerApi';

import { cn } from '../PageProfile.cn';
import { i18n } from '../PageProfile.i18n';

import { TabLoyaltyCardsProps } from './TabLoyaltyCards.types';

import './TabLoyaltyCards.css';

type LoyaltyCardsProps = {loyaltyCards: BonusCard[]; personId: string};

const LoyaltyCardsView = withRemote(({ loyaltyCards, personId }: LoyaltyCardsProps) => {
    const isEmpty = loyaltyCards.length === 0;

    return (
        <div className={cn('TabLoyaltyCards', { empty: isEmpty })}>
            <div className={cn('Main')}>
                <Card
                    description={isEmpty ? '' : i18n('loyalty_cards_description')}
                    title={isEmpty ? '' : i18n('loyalty_cards')}
                >
                    <LoyaltyCards
                        loyaltyCards={loyaltyCards}
                        personId={personId}
                    />
                </Card>
            </div>
        </div>
    );
});

export const TabLoyaltyCards: FC<TabLoyaltyCardsProps> = props => {
    const { personId } = props;

    const loyaltyCards = usePersonBonusCards(personId, {});

    return (
        <LoyaltyCardsView
            loyaltyCards={loyaltyCards}
            personId={personId}
        />
    );
};
