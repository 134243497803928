import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { usePersonDetailsPut } from 'hooks/usePersonDetailsPut';
import { isLoading } from 'utils/Loadable';

import { UseHideOnStaffData } from './useHideOnStaff.types';

export const useHideOnStaff = ({ isHiddenGap, personId }: UseHideOnStaffData) => {
    const methods = useForm<{is_hidden: boolean}>({
        defaultValues: { is_hidden: isHiddenGap },
    });
    const { resetField, getValues } = methods;

    const [personDetailsPut, state] = usePersonDetailsPut(
        { onError: () => {
            resetField('is_hidden', { defaultValue: !getValues('is_hidden') });
        } },
    );

    useEffect(() => {
        const subscription = methods.watch(((data, { type }) => {
            if (type !== 'change') return;
            personDetailsPut(personId, { is_hidden_gap: data.is_hidden });
        }));

        return () => subscription.unsubscribe();
    }, [methods, personId, personDetailsPut]);

    return { methods, isLoading: isLoading(state) };
};
