import { FC } from 'react';

import { AppLink } from 'components/AppLink';

import { OptionalLinkProps } from './OptionalLink.types';

export const OptionalLink: FC<OptionalLinkProps> = props => {
    const { href, children } = props;

    if (!href) return <div {...props}>{children}</div>;

    return (
        <AppLink {...props} href={href}>
            {children}
        </AppLink>
    );
};
