// были ошибки в CI, пришлось импортировать сорсы
// MessageBar будем переводить на hr-components BTRIP-8067
import { FC } from 'react';
import { cnMessageBar, IMessageBarProps, MessageBar as MessageBarBase } from '@yandex-int/tools-components/__internal__/src/components/MessageBar/MessageBar.bundle/desktop';

export const MessageBar: FC<IMessageBarProps> = props => {
    const { type = 'info', size = 'm' } = props;

    return (
        <MessageBarBase
            {...props}
            size={size}
            type={type}
        />
    );
};

MessageBar.displayName = cnMessageBar();
