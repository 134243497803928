import * as D from 'io-ts/Decoder';

import { OneToBoolean, ToArray, ToNumber, ZeroToBoolean } from 'utils/io-ts/decoders';

import { ACOrderByDecoder } from './ACOrderByDecoder';
import { ACTimeOptionDecoder } from './ACTimeOptionDecoder';

export const ACSearchFiltersAviaDecoder = D.partial({
    order_by: ACOrderByDecoder,
    is_descending: OneToBoolean,
    has_baggage: OneToBoolean,
    is_restricted_by_travel_policy: ZeroToBoolean,
    is_changeable: OneToBoolean,
    is_refundable: OneToBoolean,
    cabin_classes: ToArray(D.string),
    air_companies: ToArray(D.string),
    departure_there_timespan: ToArray(ACTimeOptionDecoder),
    arrival_there_timespan: ToArray(ACTimeOptionDecoder),
    departure_back_timespan: ToArray(ACTimeOptionDecoder),
    arrival_back_timespan: ToArray(ACTimeOptionDecoder),
    departure_from_there: ToArray(D.string),
    arrival_to_there: ToArray(D.string),
    departure_from_back: ToArray(D.string),
    arrival_to_back: ToArray(D.string),
    maximum_transfers_count: ToNumber,
});

export type ACSearchFiltersAvia = D.TypeOf<typeof ACSearchFiltersAviaDecoder>;
