import { FC } from 'react';

import { AppLink } from 'components/AppLink';
import { config } from 'config';
import { usePlatform } from 'hooks/usePlatform';
import { RouteMyActiveTrips } from 'routes/RouteMyActiveTrips';
import { getTypedI18nLang } from 'utils/i18n';

import { cn } from '../Header.cn';

import { desktopLogo, mobileLogo } from './HeaderLogo.icons/Logo.yt';
import { IHeaderLogoProps } from './HeaderLogo.types';

import './HeaderLogo.css';

const { isB2B } = config;

export const HeaderLogo: FC<IHeaderLogoProps> = props => {
    const { className } = props;

    const { isMobile } = usePlatform();
    const lang = getTypedI18nLang();

    const logoUrl = RouteMyActiveTrips.getPath({}, {});
    const logo = isMobile ? mobileLogo[lang] : desktopLogo[lang];

    return (
        <AppLink
            className={cn('Logo', { b2b: isB2B }, [className])}
            href={logoUrl}
        >
            {logo}
        </AppLink>
    );
};
