export const en = {
    tourismAvailability_true: 'open for tourism',
    tourismAvailability_false: 'closed for tourism',

    entranceForRussians_freeEntrance: 'No entry restrictions',
    entranceForRussians_hasRestrictions: 'There are restrictions for the entry of Russian citizens',
    entranceForRussians_noEntrance: 'Entry for Russian citizens is prohibited',

    flightsAvailability_true: 'Direct flights',
    flightsAvailability_false: 'No direct flights',

    pcrExpirationPeriodInHoursOrRequired_true: 'PCR is required',
    pcrExpirationPeriodInHoursOrRequired_false: 'PCR is not required',
    pcrExpirationPeriodInHoursOrRequired: 'Requires PCR done no more than {hours} hours ago',
    quarantineRequired_true: 'Quarantine is required',
    quarantineRequired_false: 'No quarantine',
    vaccineRequired_true: 'Vaccine is necessary',
    vaccineRequired_false: 'Vaccination is not required',
    visaRequired_true: 'Need visa',
    visaRequired_false: 'Visa is not needed',
};
