import { FC } from 'react';

import { ServiceFilters } from 'components/ServiceFilters';
import { withRemote } from 'hocs/withRemote';
import { useSearchFilterList } from 'hooks/useSearchFilterList';

import { cn } from './SearchFilters.cn';
import { SearchFiltersProps } from './SearchFilters.types';
import { Skeleton } from './Skeleton';

const ServiceFiltersRemote = withRemote(ServiceFilters);

export const SearchFilters: FC<SearchFiltersProps> = props => {
    const {
        className,
        search: { type, trip_id, person_id },
        params: { searchId },
    } = props;

    const filters = useSearchFilterList({ searchId, type, trip_id, person_id });

    return (
        <ServiceFiltersRemote
            {...props}
            instant
            className={cn(null, [className])}
            filters={filters}
            skeleton={<Skeleton />}
        />
    );
};

SearchFilters.displayName = cn();
