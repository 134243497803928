import * as E from 'io-ts/Encoder';

import { NumberToBooleanInverse } from 'utils/io-ts/encoders/NumberToBooleanInverse';
import { OneToBoolean } from 'utils/io-ts/encoders/OneToBoolean';
import { StringToArray } from 'utils/io-ts/encoders/StringToArray';
import { StringToNumber } from 'utils/io-ts/encoders/StringToNumber';

export const SearchParamsToHotelFilters = E.partial({
    price_from: StringToNumber,
    price_to: StringToNumber,
    is_travel_policy_compliant: OneToBoolean,
    is_recommended: OneToBoolean,
    hotel_name: StringToArray,
    stars: StringToArray,
    payment_places: StringToArray,
    hotel_types: StringToArray,
    confirmation_type: StringToArray,
    is_restricted_by_travel_policy: NumberToBooleanInverse,
});
