import { FC, useCallback } from 'react';

import { cn } from './TrainPlaceSelect.cn';
import { TrainPlaceSelectProps } from './TrainPlaceSelect.types';

import './TrainPlaceSelect.css';

export const TrainPlaceSelect: FC<TrainPlaceSelectProps> = props => {
    const {
        number,
        onClick = () => {},
        selected,
        currentSelected,
        isReadOnly,
    } = props;

    const handleClick = useCallback(e => {
        onClick(number, e);
    }, [number, onClick]);

    const isDisabled = isReadOnly || selected;

    return (
        <button
            className={cn({ selected: selected, currentSelected }, [props.className])}
            disabled={isDisabled}
            onClick={handleClick}
        >
            {props.number}
        </button>
    );
};

TrainPlaceSelect.displayName = cn();
