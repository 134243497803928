import * as yup from 'yup';

import { DocumentType } from 'services/SwaggerApi';
import { isLatinRegexp } from 'shared/constants/isLatinRegexp';
import { yupFieldDate } from 'shared/lib/yup/yupFieldDate';
import { i18n } from 'utils/form/SchemaErrors.i18n';

import { DocumentFormFields } from './DocumentForm.types';

type CitizenshipItem = DocumentFormFields['citizenship'];

export const documentTypes: DocumentType[] = ['passport', 'external_passport'];

export const RUSSIA_COUNTRY_ID = 173;

const isRussianCitizenship = (citizenship?: CitizenshipItem) => citizenship?.id === RUSSIA_COUNTRY_ID;

const isCyrillic = /^[а-яё\s-]*$/i;

const validateNameField = () => {
    return yup.string().when(['document_type', 'citizenship'], {
        is: (docType: DocumentType, citizenship?: CitizenshipItem) =>
            isRussianCitizenship(citizenship) && docType === 'passport',
        then: yup.string().trim().matches(isCyrillic, () => i18n('cyrillic_required')),
        otherwise: yup.string().trim().matches(isLatinRegexp, () => i18n('latin_required')),
    }).max(50);
};

export const schema: yup.SchemaOf<DocumentFormFields> = yup.object({
    citizenship: yup.mixed<CitizenshipItem>().required(),
    document_type: yup.mixed().oneOf(documentTypes).required(),
    first_name: validateNameField().required(),
    last_name: validateNameField().required(),
    middle_name: validateNameField().when(['no_middle_name'], {
        is: (noMiddleName: boolean) => noMiddleName,
        then: yup.string(),
        otherwise: yup.string().required(),
    }),
    no_middle_name: yup.boolean(),
    series: yup.string().trim().when(['citizenship'], {
        is: isRussianCitizenship,
        then: yup.string().when(['document_type'], {
            is: (docType: DocumentType) => docType === 'passport',
            then: yup.string().required().length(4),
            otherwise: yup.string().required().length(2),
        }),
        otherwise: yup.string().max(8),
    }),
    number: yup.string().required().trim().when(['citizenship'], {
        is: isRussianCitizenship,
        then: yup.string().when('document_type', {
            is: (val: DocumentType) => val === 'passport',
            then: yup.string().length(6),
            otherwise: yup.string().length(7),
        }),
        otherwise: yup.string().max(32),
    }),
    issued_on: yupFieldDate().required().moreThan('expires_on'),
    expires_on: yup.date().when(['document_type', 'citizenship'], {
        is: (docType: DocumentType, citizenship?: CitizenshipItem) =>
            isRussianCitizenship(citizenship) && docType === 'passport',
        then: yupFieldDate(),
        otherwise: yupFieldDate().required(),
    }),
});
