import { TravelPolicyName } from 'services/SwaggerApi';

import { i18n } from '../EmployeesGroupForm.i18n';

export const getTravelPolicyOptions = (policies: TravelPolicyName[]) => {
    const options = policies.map(policy => ({
        value: policy.travel_policy_id,
        content: policy.name,
    }));

    const defaultOption = {
        value: 0,
        content: i18n('travel_policy_not_selected'),
    };

    return [defaultOption, ...options];
};
