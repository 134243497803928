import { useEffect } from 'react';

export const useScrollTopOnNewPage = () => {
    useEffect(() => {
        window.scrollTo({
            top: -1,
            behavior: 'smooth',
        });
    }, []);
};
