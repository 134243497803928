import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FieldSelect, FieldSuggest, FormProvider } from 'components/FormField2';
import { PersonTag } from 'components/PersonTag';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { mapCompaniesToOptions } from 'utils/mapCompaniesToOptions';

import { cn } from './SelectApproverForm.cn';
import { i18n } from './SelectApproverForm.i18n';
import { schema } from './SelectApproverForm.schema';
import { SelectApproverFormProps, SelectApproverFormSchema } from './SelectApproverForm.types';

import './SelectApproverForm.css';

export const SelectApproverForm: FC<SelectApproverFormProps> = props => {
    const {
        className,
        onCancel,
        onSubmit,
        textBeforeTag = i18n('access_for'),
        buttonSubmitText,
        error,
        progress,
        employee,
        defaultValues,
        availableCompanies = [],
    } = props;

    const {
        company_id,
        first_name,
        last_name,
        employee_id,
    } = employee;

    const methods = useForm<SelectApproverFormSchema>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const canChangeCompany = availableCompanies.length > 1;
    const selectOptions = mapCompaniesToOptions(availableCompanies);

    return (
        <FormProvider error={error} methods={methods} schema={schema}>
            <form
                className={cn(null, [className])}
                onSubmit={methods.handleSubmit(onSubmit)}
            >
                <div className={cn('Fieldset')}>
                    <div className={cn('Info')}>
                        <Text
                            className={cn('ActionFor')}
                            typography="body-s"
                        >
                            {textBeforeTag}
                        </Text>
                        <PersonTag
                            login="-"
                            name={`${first_name} ${last_name}`}
                            personId={employee_id}
                            size="m"
                        />
                    </div>
                    <FieldSuggest
                        className={cn('FieldPerson')}
                        companyId={company_id}
                        label={i18n('approver')}
                        name="approver"
                        type="persons"
                    />
                    {canChangeCompany && (
                        <FieldSelect
                            label={i18n('company')}
                            name="new_company_id"
                            options={selectOptions}
                            view="outline"
                        />
                    )}
                </div>
                <div className={cn('Controls')}>
                    <Button
                        className={cn('Cancel')}
                        size="m"
                        view="ghost"
                        onClick={onCancel}
                    >
                        {i18nAction('cancel')}
                    </Button>
                    <Button
                        className={cn('Submit')}
                        progress={progress}
                        size="m"
                        type="submit"
                        view="primary"
                    >
                        {buttonSubmitText}
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

SelectApproverForm.displayName = cn();
