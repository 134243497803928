import { FetchError } from 'errors';
import { AviaServiceDetails, HotelServiceDetails, RailServiceDetails, ServiceType } from 'services/SwaggerApi';
import { isInitial, Loadable, RemoteData, RemoteInitial } from 'utils/Loadable';

import { createUseQueryHook } from './createUseQueryHook';

type UseAviaServiceDetail = (
    isEnabled: boolean, service_id: number
) => RemoteData<AviaServiceDetails, FetchError>;

const useAviaServiceDetail: UseAviaServiceDetail = (isEnabled, service_id) => {
    return createUseQueryHook(
        'get_avia_service_details',
        {
            enabled: isEnabled,
        },
    )(service_id);
};

type UseHotelServiceDetail = (
    isEnabled: boolean, service_id: number
) => RemoteData<HotelServiceDetails, FetchError>;

const useHotelServiceDetail: UseHotelServiceDetail = (isEnabled, service_id) => {
    return createUseQueryHook(
        'get_hotel_service_details',
        {
            enabled: isEnabled,
        },
    )(service_id);
};

type UseRailServiceDetail = (
    isEnabled: boolean, service_id: number
) => RemoteData<RailServiceDetails, FetchError>;

const useRailServiceDetail: UseRailServiceDetail = (isEnabled, service_id) => {
    return createUseQueryHook(
        'get_rail_service_details',
        {
            enabled: isEnabled,
        },
    )(service_id);
};

type ServicesDetails = AviaServiceDetails | RailServiceDetails | HotelServiceDetails;

type UseServiceDetailByType = (serviceType: ServiceType, service_id: number) =>
    RemoteInitial | Loadable<ServicesDetails, FetchError> | null;

export const useServiceDetailByType: UseServiceDetailByType = (serviceType, service_id) => {
    const railResult = useRailServiceDetail(serviceType === 'rail', service_id);
    const aviaResult = useAviaServiceDetail(serviceType === 'avia', service_id);
    const hotelResult = useHotelServiceDetail(serviceType === 'hotel', service_id);

    const activeResult = [railResult, aviaResult, hotelResult].find(
        result => !isInitial<ServicesDetails, FetchError>(result),
    );

    return activeResult ?? null;
};
