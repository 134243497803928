import { FC } from 'react';
import { Icon } from '@yandex-int/hr-components/Icon/desktop/bundle';

import { ErrorState } from 'shared/ui/ErrorState';

import { cn } from '../HotelDetails.cn';
import { i18n } from '../HotelDetails.i18n';

export const EmptyState: FC = () => {
    return (
        <ErrorState
            className={cn('ReviewsEmptyState')}
            description={i18n('empty_reviews_description')}
            image={<Icon glyph="comment" size="m" />}
            size="m"
            title={i18n('empty_reviews_title')}
        />
    );
};
