import { useHistory } from 'react-router-dom';

import { Card } from 'components/Card';
import { ErrorState } from 'shared/ui/ErrorState';

import { cn } from '../TrainDetails.cn';
import { i18n } from '../TrainDetails.i18n';

export const TicketsNotFoundError = () => {
    const history = useHistory();

    return (
        <Card className={cn('ErrorCard')}>
            <ErrorState
                withChatSupport
                actionTitle={i18n('back_to_search')}
                description={i18n('tickets_not_found_description')}
                imageType="not_found"
                title={i18n('tickets_not_found_title')}
                onActionClick={history.goBack}
            />
        </Card>
    );
};
