import { AviaServiceDetails, RailServiceDetails } from 'services/SwaggerApi';

export function switchTransportFn<TReturn>(options: {
    rail: (service: RailServiceDetails) => TReturn;
    avia: (service: AviaServiceDetails) => TReturn;
}) {
    return (service: AviaServiceDetails | RailServiceDetails) => {
        switch (service.service_type) {
            case 'avia':
                return options.avia(service);

            case 'rail':
                return options.rail(service);
        }
    };
}
