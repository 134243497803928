import { UseQueryOptions } from 'react-query';

import { FetchError } from 'errors';
import { TGet_train_detailsQuery } from 'services/SwaggerApi';
import { RemoteData } from 'utils/Loadable';

import { createUseQueryHook } from './createUseQueryHook';

type UseTrainDetailsTravel = (
    queryConfig: UseQueryOptions<unknown, FetchError>,
    trainDetailsQuery: TGet_train_detailsQuery,
) => RemoteData<unknown>;

export const useTrainDetailsTravel: UseTrainDetailsTravel = (queryConfig, trainDetailsQuery) => {
    return createUseQueryHook(
        'get_train_details',
        {
            ...queryConfig,
            staleTime: Infinity,
            refetchOnWindowFocus: false,
        },
    )(trainDetailsQuery);
};
