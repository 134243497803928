/* eslint-disable ascii/valid-name */
const dictionary: { [key: string]: string } = {
    А: 'A', Б: 'B', В: 'V', Г: 'G', Д: 'D', Е: 'E', Ё: 'YO', Ж: 'ZH', З: 'Z',
    И: 'I', Й: 'Y', К: 'K', Л: 'L', М: 'M', Н: 'N', О: 'O', П: 'P', Р: 'R',
    С: 'S', Т: 'T', У: 'U', Ф: 'F', Х: 'H', Ц: 'TS', Ч: 'CH', Ш: 'SH', Щ: 'SHCH',
    Ъ: '', Ы: 'Y', Ь: '', Э: 'E', Ю: 'YU', Я: 'YA',
    а: 'a', б: 'b', в: 'v', г: 'g', д: 'd', е: 'e', ё: 'yo', ж: 'zh', з: 'z',
    и: 'i', й: 'y', к: 'k', л: 'l', м: 'm', н: 'n', о: 'o', п: 'p', р: 'r',
    с: 's', т: 't', у: 'u', ф: 'f', х: 'h', ц: 'ts', ч: 'ch', ш: 'sh', щ: 'shch',
    ъ: '', ы: 'y', ь: '', э: 'e', ю: 'yu', я: 'ya',
};
/* eslint-enable ascii/valid-name */

const transliterateWord = (word: string) => {
    if (word.length === 0) {
        return '';
    }

    const result = word.split('').map(letter => dictionary[letter]).join('');

    if (word === word.toUpperCase()) return result.toUpperCase();

    if (word === word[0].toUpperCase() + word.slice(1).toLowerCase()) {
        return result[0].toUpperCase() + result.slice(1).toLowerCase();
    }

    return result;
};

export const transliterate = (text: string) => {
    if (typeof text !== 'string') {
        return text;
    }

    return text.replace(/[А-Яа-яёЁ]+/g, transliterateWord);
};
