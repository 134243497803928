import { FC } from 'react';

import { Skeleton as SkeletonBase } from 'shared/ui/Skeleton';

import { cn } from '../SearchFilters.cn';

import { SkeletonProps } from './Skeleton.types';

export const Skeleton: FC<SkeletonProps> = props => {
    const {
        className,
    } = props;

    return (
        <div className={cn('Skeleton', [className])}>
            <SkeletonBase size="m" />
            <SkeletonBase size="m" />
            <SkeletonBase size="m" />
        </div>
    );
};
