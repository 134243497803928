import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { RouteTripCreate } from 'routes/RouteTripCreate';
import { Button } from 'shared/ui/Button';

import { cn } from '../Header.cn';
import { i18n } from '../Header.i18n';

import { ActionButtonProps } from './ActionButton.types';

export const ActionButton: FC<ActionButtonProps> = () => {
    const history = useHistory();

    const newTripUrl = RouteTripCreate.getPath({}, {});

    const handleBtnClick = useCallback(e => {
        const comboKey = e.metaKey || e.altKey || e.ctrlKey || e.shiftKey;

        if (!comboKey) {
            e.preventDefault();
            history.push(newTripUrl);
        }
    }, [history, newTripUrl]);

    return (
        <div className={cn('ActionButtons')}>
            <Button
                className={cn('NewTripButton')}
                data-testId="new-trip-button"
                size="m"
                type="link"
                url={newTripUrl}
                view="default"
                onClick={handleBtnClick}
            >
                {i18n('new_trip')}
            </Button>
        </div>
    );
};
