export const en = {
    title: 'Corporate taxi',
    description: 'Taxi access will be opened automatically the day before the start of the trip',
    taxi_activated: 'Activated',
    taxi_not_activated: 'Not activated',
    valid_until: 'Valid until',
    to_phone: 'To phone',
    contact_support: 'Contact the support chat',
    taxi_is_disabled_for_company: 'Corporate payment method is not currently enabled ',
    not_suitable_routes: 'The corporate payment method is available only in Russia, Belarus and Kazakhstan',
    no_ya_start_ya_end: 'A corporate taxi payment method is not available for this type of trip',
    no_phone_number: 'The phone number is not specified in the profile',
    trip_is_not_approved: 'The business trip has not been agreed upon',
    trip_status_is_not_suitable: 'The business trip has been cancelled or completed',
    current_date_not_in_taxi_dates_range: '{translate}',
    date_from: 'The taxi will be connected\nfrom {taxi_date_from}',
    date_to: 'The taxi will be connected\nto {taxi_date_to}',
    show_more: '{count} more',
};
