import { i18n } from '../CovidRestrictions.i18n';
import { EntryRestrictions } from '../RestrictionsDecoder';

type GetRestrictionText = (
    key: keyof EntryRestrictions,
    restrictionObject: EntryRestrictions,
) => string;

export const getRestrictionText: GetRestrictionText = (key, restrictionObject): string => {
    if (key === 'entranceForRussians') {
        const entranceStatus = restrictionObject[key].value;

        return i18n(`${key}_${entranceStatus}`);
    }

    const value = restrictionObject[key].value;

    if (typeof value === 'number') {
        return i18n('pcrExpirationPeriodInHoursOrRequired', { hours: value });
    }

    return i18n(`${key}_${value}`);
};
