import { FC } from 'react';

import { Text } from 'shared/ui/Text';

import { cn } from '../DatePicker.cn';

type MonthPickerProps = {
   setActiveMonth: (month: number) => void;
   setIsScrolling: (isScrolling: boolean) => void;
   activeMonth: number;
   months: string[];
}

const visibleMonthCount = 13;
const weekdaysHeight = 52;
const singleMonthHeightSide = 32;
const indicatorHeight = 16;

export const MonthPicker: FC<MonthPickerProps> = props => {
    const { months, activeMonth, setActiveMonth, setIsScrolling } = props;

    const handleMonthClick = (index: number) => {
        const scrollableDiv = document.querySelector('.DatePicker');
        const month = document.querySelectorAll<HTMLElement>('.rdp-month')[index];

        setActiveMonth(index);

        if (scrollableDiv && month) {
            const scrollToY = index === 0 ? 0 : month.offsetTop - weekdaysHeight;

            scrollableDiv.scrollTo({ behavior: 'smooth', top: scrollToY });
        }

        setIsScrolling(true);
        setTimeout(() => setIsScrolling(false), 700);
    };

    const indicatorOffset = Math.floor((indicatorHeight / visibleMonthCount) * activeMonth);

    return (
        <div className={cn('MonthPicker')}>
            <div
                className={cn('ActiveMonthIndicator')}
                style={{ transform: `translateY(${activeMonth * singleMonthHeightSide + indicatorOffset}px)` }}
            />
            <Text className={cn('Months')} typography="caption-m">
                {months.map((month, index) => (
                    <div
                        key={index}
                        className={cn('Month')}
                        onClick={() => handleMonthClick(index)}
                    >
                        {month}
                    </div>
                ))}
            </Text>
        </div>
    );
};
