import * as D from 'io-ts/Decoder';

import { ActivePage } from 'shared/constants/ActivePage';

import { SearchResultsDecoder } from './decoders/SearchResultsDecoder';
import { route } from './route';

export const RouteSearchDecoder = D.type({
    params: D.type({
        searchId: D.string,
    }),
    search: SearchResultsDecoder,
});

export type RouteSearch = D.TypeOf<typeof RouteSearchDecoder>;

export const RouteSearch = route(
    '/search/:searchId/',
    RouteSearchDecoder,
    {
        activePage: ActivePage.ALL_TRIPS_LIST,
    },
);
