import { ACServiceType } from 'services/AeroClubApi/ACServiceType';

import { OrderParams } from '../FieldSort.types';

import { getOptions } from './getOptions';

export const getSortOptionValue = ({
    order_by, is_descending = false,
}: OrderParams, type: ACServiceType): number => {
    const options = getOptions();

    return options.findIndex(option =>
        option.is_descending === is_descending && option.order_by === order_by && option.types.includes(type));
};
