import { isExtraValidationError } from 'utils/responseToHTTPError';

import { ServerError, ServerErrorsContextProps } from '../FormProvider.types';

import { getServerErrorMessage } from './getServerErrorMessage';

export const tripIdField = 'trip_id';
export const tripIdError = 'trip does not exist';

/**
 * Преобразует ошибку 404 в ошибку валидации для отображения в поле формы
 * Временный хак, пока бекенд присылает ошибку валидации как ошибку "Not Found"
 */
export const getExtraErrors = (error: ServerError['error']): ServerErrorsContextProps => {
    const validationErrors: ServerErrorsContextProps = {};

    if (isExtraValidationError(error)) {
        validationErrors[tripIdField] = getServerErrorMessage(tripIdField, tripIdError);
    }

    return validationErrors;
};
