export const en = {
    cyrillic_required: 'Only cyrillic characters, spaces and dashes are allowed',
    latin_required: 'Only latin characters, spaces and dashes are allowed',
    length: {
        one: 'Must be exact {count} symbol',
        some: 'Must be exact {count} symbols',
        many: 'Must be exact {count} symbols',
    },
    minLength: {
        one: 'The contents of this field must be at least {count} character',
        some: 'The contents of this field must be at least {count} characters',
        many: 'The contents of this field must be at least {count} characters',
    },
    maxLength: {
        one: 'The contents of this field must not exceed {count} character',
        some: 'The contents of this field must not exceed {count} characters',
        many: 'The contents of this field must not exceed {count} characters',
    },
    required: 'Fill in this field',
    positive: 'The value of the field must be positive',
    negative: 'The value of the field must be negative',
    integer: 'The value of the field must be an integer',
    email: 'Invalid email format',
    phone: 'Invalid phone number',
    url: 'The field must contain URL',
    minNumber: 'The value of the field must be greater than or equal to {count}',
    maxNumber: 'The value of the field must be less than or equal to {count}',
    lessThan: 'The value of the field must be less than {count}',
    moreThan: 'The value of the field must be greater than {count}',
    minDate: 'The date must be later than {date}',
    maxDate: 'The date must be earlier than {date}',
    futureDate: 'The date of birth cannot be in the future',
    minArrayLength: {
        one: 'The field must have at least {count} item',
        some: 'The field must have at least {count} items',
        many: 'The field must have at least {count} items',
    },
    maxArrayLength: {
        one: 'The field must have less than or equal to {count} item',
        some: 'The field must have less than or equal to {count} items',
        many: 'The field must have less than or equal to {count} items',
    },
    arrayLength: {
        one: 'The field must have {count} item',
        some: 'The field must have {count} items',
        many: 'The field must have {count} items',
    },
};
