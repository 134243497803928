import { FC } from 'react';

import { Card } from 'components/Card';
import { DocumentsList } from 'components/DocumentsList';
import { withRemote } from 'hocs/withRemote';
import { usePersonDocuments } from 'hooks/usePersonDocuments';
import { PersonDocument } from 'services/SwaggerApi';

import { cn } from '../PageProfile.cn';
import { i18n } from '../PageProfile.i18n';

import { TabDocumentsProps } from './TabDocuments.types';

import './TabDocuments.css';

type DocumentsProps = {documents: PersonDocument[]; personId: string};

const Documents = withRemote(({ documents, personId }: DocumentsProps) => {
    const isEmpty = documents.length === 0;

    return (
        <div className={cn('TabDocuments', { empty: isEmpty })} data-testid="TabDocuments">
            <div className={cn('Main')}>
                <Card
                    description={isEmpty ? '' : i18n('documents_description')}
                    title={isEmpty ? '' : i18n('documents')}
                >
                    <DocumentsList
                        items={documents}
                        personId={personId}
                    />
                </Card>
            </div>
        </div>
    );
});

export const TabDocuments: FC<TabDocumentsProps> = props => {
    const { personId } = props;

    const documents = usePersonDocuments(personId);

    return (
        <Documents
            documents={documents}
            personId={personId}
        />
    );
};
