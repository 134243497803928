import * as D from 'io-ts/Decoder';

import { config } from 'config';
import { ActivePage } from 'shared/constants/ActivePage';

import { RouteUntyped } from './decoders/RouteUntyped';
import { checkAccessIsB2B } from './checkAccessIsB2B';
import { route } from './route';

const { isB2B } = config;

export type RouteRegisterRejected = D.TypeOf<typeof RouteUntyped>;

export const RouteRegisterRejected = route(
    '/register/rejected',
    RouteUntyped,
    {
        shouldWrap: !isB2B,
        activePage: ActivePage.REGISTER_PAGE,
        checkAccess: checkAccessIsB2B,
    },
);
