import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_header.css';

export type WithTypographyHeaderProps = {
    typography?: 'header';
};

export const withTypographyHeader = withBemMod<WithTypographyHeaderProps>(
    cn(),
    { typography: 'header' },
);
