import { FC } from 'react';

import { Cell } from 'components/Cell';
import { withTdAttrs } from 'hocs/withTdAttrs';
import { DateFormat, formatDate } from 'utils/formatDate';

import { cn } from './CellDate.cn';
import { CellDateProps } from './CellDate.types';

const CellDateBase: FC<CellDateProps> = props => {
    const {
        className,
        value,
        getAttrs,
    } = props;

    const valueFormatted = formatDate(value, DateFormat.L);

    return (
        <Cell
            className={cn(null, [className])}
            getAttrs={getAttrs}
            size="m"
        >
            {valueFormatted}
        </Cell>
    );
};

export const CellDate = withTdAttrs(CellDateBase);
CellDate.displayName = cn();
