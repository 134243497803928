import { FC } from 'react';
import { uniqBy } from 'lodash';

import { i18nTransfers } from 'i18n/i18nTransfers';
import { ProviderLeg } from 'services/SwaggerApi';
import { ListItem } from 'shared/ui/ListItem';
import { Text } from 'shared/ui/Text';

import { FlightRouteTimeline } from '../../FlightRouteTimeline/FlightRouteTimeline';
import { CarriersInfo } from '../CarriersInfo';
import { getTransferDuration } from '../FightInfoRoute.utils/getTransferDuration';
import { cn } from '../FlightInfoRoute.cn';

import '../FlightInfoRoute.css';

type FlightInfoRouteContentProps = {
    isCollapsed: boolean;
    leg: ProviderLeg;
    toggleCollapse?: () => void;
};

export const FlightInfoRouteContent: FC<FlightInfoRouteContentProps> = props => {
    const {
        leg,
        isCollapsed,
        toggleCollapse,
    } = props;

    const {
        segments,
        segments_count,
        route_duration,
    } = leg;

    const transfersCount = segments.length - 1;
    const carriers = segments.map(segment => segment.carrier);
    const uniqCarriers = uniqBy(carriers, 'name');
    const hasTransferData = segments.length === segments_count;
    const aircraft = segments_count === 1 ? segments[0].aircraft : null;

    if (isCollapsed) {
        return (
            <>
                <div className={cn('Info')}>
                    <CarriersInfo aircraft={aircraft} carriers={uniqCarriers} />
                    <FlightRouteTimeline collapsed={isCollapsed} leg={leg} toggleCollapse={toggleCollapse} />
                </div>
            </>
        );
    }

    return (
        <>
            {segments.map((segment, index) => {
                const segmentFrom = segment;
                const segmentTo = segments[index + 1];
                const shouldShowTransferTag = (index !== transfersCount);

                const {
                    transferDuration,
                    textContent,
                } = getTransferDuration({
                    airportArrivalAt: segmentFrom.arrival_at,
                    airportDepartureAt: segmentTo?.departure_at,
                    city: segmentFrom.arrival?.city,
                });

                return (

                    <div key={index}>
                        <div className={cn('Info')}>
                            <CarriersInfo aircraft={segment.aircraft} carriers={[segment.carrier]} />
                            <FlightRouteTimeline
                                collapsed={isCollapsed}
                                leg={{
                                    segments: [segment],
                                    segments_count,
                                    route_duration,
                                }}
                                toggleCollapse={toggleCollapse}
                            />
                        </div>
                        {shouldShowTransferTag && (
                            <ListItem
                                className={cn('TransferInfo')}
                                leadingTextParams={{
                                    label: textContent,
                                    caption: transferDuration,
                                    size: 's',
                                }}
                            />
                        )}
                        {!hasTransferData && (
                            <div className={cn('TransfersTag')}>
                                <Text typography="paragraph-s">
                                    {i18nTransfers('transfers', { count: segments_count - 1 })}
                                </Text>
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
};
