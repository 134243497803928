import {
    MaxPricePerNightRule,
    MaxPriceRule,
    TravelPolicyAviaCondition,
    TravelPolicyHotelCondition,
    TravelPolicyRailCondition,
} from 'services/SwaggerApi';
import { getCabinClasses, getHotelStars, getRailCategories } from 'utils/getFormatedPolicyRules';

import { i18n } from '../TabTravelPolicy.i18n';

const getAdditionalRules = <T extends MaxPriceRule | MaxPricePerNightRule>(rules: T[], type: keyof T) => {
    return rules
        .filter(item => item.location_id)
        .map(item => i18n('max_price_direction', { price: Number(item[type]), direction: String(item.location_name) }));
};

export const getAviaRules = (rules: TravelPolicyAviaCondition) => {
    const totalMaxPrice = rules.max_price && rules.max_price.filter(item => !item.location_id)[0]?.max_price;

    const main = [
        totalMaxPrice && i18n('max_price', { price: totalMaxPrice }),
        rules.cheapest_more_percent &&
            rules.cheapest_more_percent[0] &&
            i18n('cheapest_more_percent', { percent: Number(rules.cheapest_more_percent[0].cheapest_more_percent) }),
        rules.book_before_days &&
            rules.book_before_days[0] &&
            i18n('avia_book_before_days', { days: Number(rules.book_before_days[0].book_before_days) }),
        rules.max_transfer_duration_hours &&
            rules.max_transfer_duration_hours[0] &&
            i18n('max_transfer_duration_hours', {
                hours: Number(rules.max_transfer_duration_hours[0].max_transfer_duration_hours),
            }),
        rules.refundable_only[0]?.refundable_only && i18n('refundable_only'),
        rules.exchangeable_only[0]?.exchangeable_only && i18n('exchangeable_only'),
        rules.with_baggage_only[0]?.with_baggage_only && i18n('with_baggage_only'),
        rules.round_trips_only[0]?.round_trips_only && i18n('round_trips_only'),
        rules.business_days_only[0]?.business_days_only && i18n('avia_business_days_only'),
        rules.service_classes && rules.service_classes[0] && i18n('available_classes', { classes: getCabinClasses(rules.service_classes) }),
    ].filter(Boolean);

    const additional = rules.max_price ? getAdditionalRules(rules.max_price, 'max_price') : [];

    return { main, additional };
};

export const getRailRules = (rules: TravelPolicyRailCondition) => {
    const totalMaxPrice = rules.max_price && rules.max_price.filter(item => !item.location_id)[0]?.max_price;

    const main = [
        totalMaxPrice && i18n('max_price', { price: totalMaxPrice }),
        rules.cheapest_more_percent &&
            rules.cheapest_more_percent[0] &&
            i18n('cheapest_more_percent', { percent: Number(rules.cheapest_more_percent[0].cheapest_more_percent) }),
        rules.book_before_days &&
            rules.book_before_days[0] &&
            i18n('rail_book_before_days', { days: Number(rules.book_before_days[0].book_before_days) }),
        rules.refundable_only[0]?.refundable_only && i18n('refundable_only'),
        rules.business_days_only[0]?.business_days_only && i18n('rail_business_days_only'),
        rules.train_car_categories && rules.train_car_categories[0] &&
            i18n('available_categories', {
                categories: getRailCategories(rules.train_car_categories),
            }),
    ].filter(Boolean);

    const additional = rules.max_price ? getAdditionalRules(rules.max_price, 'max_price') : [];

    return { main, additional };
};

export const getHotelRules = (rules: TravelPolicyHotelCondition) => {
    const totalMaxPricePerNight =
        rules.max_price_per_night &&
        rules.max_price_per_night.filter(item => !item.location_id)[0]?.max_price_per_night;

    const main = [
        totalMaxPricePerNight && i18n('max_price_per_night', { price: totalMaxPricePerNight }),
        rules.cheapest_more_percent &&
            rules.cheapest_more_percent[0] &&
            i18n('cheapest_more_percent', { percent: Number(rules.cheapest_more_percent[0].cheapest_more_percent) }),
        rules.book_before_days &&
            rules.book_before_days[0] &&
            i18n('hotel_book_before_days', { days: Number(rules.book_before_days[0].book_before_days) }),
        rules.refundable_only[0]?.refundable_only && i18n('hotel_refundable_only'),
        rules.business_days_only[0]?.business_days_only && i18n('hotel_business_days_only'),
        rules.no_resorts[0]?.no_resorts && i18n('no_resorts'),
        rules.stars_allowed && rules.stars_allowed[0] &&
            i18n('available_stars', {
                stars: getHotelStars(rules.stars_allowed),
            }),
    ].filter(Boolean);

    const additional = rules.max_price_per_night ?
        getAdditionalRules(rules.max_price_per_night, 'max_price_per_night') :
        [];

    return { main, additional };
};
