import { useContext } from 'react';

import { CurrentCompanyContext } from 'components/CurrentCompanyProvider/CurrentCompanyProvider.context';
import { FormTravelPolicy } from 'components/FormTravelPolicy';
import { HeaderPage } from 'components/HeaderPage';
import { Page } from 'components/Page';
import { RouteTravelPolicyAdd } from 'routes/RouteTravelPolicyAdd';
import { Container } from 'shared/ui/Container';

import { cn } from './PageTravelPolicyAdd.cn';
import { i18n } from './PageTravelPolicyAdd.i18n';

export const PageTravelPolicyAdd: Page<RouteTravelPolicyAdd> = () => {
    const { currentCompanyId } = useContext(CurrentCompanyContext);

    return (
        <Page className={cn()}>
            <HeaderPage title={i18n('new_travel_policy')} />
            <Container className={cn('Content')}>
                <FormTravelPolicy companyId={currentCompanyId} details={null} />
            </Container>
        </Page>
    );
};

PageTravelPolicyAdd.displayName = cn();
