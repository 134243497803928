import { FC, useCallback, useState } from 'react';

import { Dialog } from 'components/Dialog';
import { Popup } from 'components/Dropdown/Popup/Popup';
import { useExtPersonList } from 'components/PageProfile/TabCompanions/TabCompanions.hooks/useExtPersonList';
import { PersonListItem } from 'components/PersonListItem';
import { config } from 'config';
import { withRemote } from 'hocs/withRemote';
import { useMeta } from 'hooks/useMeta';
import { usePlatform } from 'hooks/usePlatform';
import { i18nAction } from 'i18n/i18nAction';
import { i18nTravelPolicy } from 'i18n/i18nTravelPolicy';
import { ExtPerson } from 'services/SwaggerApi';
import { getFullName } from 'shared/lib/person/getFullName';
import { Drawer } from 'shared/ui/Drawer';
import { Text } from 'shared/ui/Text';
import { getTravelPolicyMessage } from 'utils/getTravelPolicyMessage';

import { i18n } from '../TrainDetails.i18n';

import { cn } from './TrainChooseSeatsPopup.cn';
import { ParticipantInfo, TrainChooseSeatsPopupProps } from './TrainChooseSeatsPopup.types';

import './TrainChooseSeatsPopup.css';

const { isB2B } = config;

type CompanionsListProps = {
    companions: ExtPerson[];
    onCompanionSelect: TrainChooseSeatsPopupProps['onParticipantClick'];
};

const CompanionsList: FC<CompanionsListProps> = props => {
    const { companions, onCompanionSelect } = props;

    const handleCompanionSelect = useCallback((companion: ExtPerson) => () => {
        onCompanionSelect({
            fullName: `${companion.first_name} ${companion.last_name}`,
            login: String(companion.email),
            personId: companion.ext_person_uuid,
            isExternal: true,
            gender: companion.gender,
        });
    }, [onCompanionSelect]);

    if (companions.length === 0) {
        return null;
    }

    return (
        <>
            <Text as="div" className={cn('CompanionTitle')} color="secondary" typography="label-s">
                {i18n('choose_place_for_companion')}
            </Text>
            {companions.map(companion => (
                <div key={companion.ext_person_uuid}>
                    <PersonListItem
                        external
                        border="m"
                        login={companion.email || ''}
                        name={getFullName(companion)}
                        padding="m"
                        size="m"
                        onClick={handleCompanionSelect(companion)}
                    />
                </div>
            ))}
        </>
    );
};

const CompanionsListRemote = withRemote(CompanionsList);

export const TrainChooseSeatsPopup: FC<TrainChooseSeatsPopupProps> = props => {
    const {
        participants = [],
        anchorRef,
        onClose,
        visible,
        onParticipantClick,
        isInTravelPolicy,
    } = props;

    const { user: { ext_persons_feature_enabled } } = useMeta();

    const companionsList = useExtPersonList({ isEnabled: ext_persons_feature_enabled && !isB2B });

    const { isMobile } = usePlatform();

    const AdaptivePopup = isMobile ? Drawer : Popup;

    const [tpDialogPersonId, setTpDialogPersonId] = useState<string | null>(null);

    const handlePersonClick = (person: ParticipantInfo) => {
        if (!isInTravelPolicy) {
            return () => {
                onClose();
                setTpDialogPersonId(person.personId);
            };
        }

        return () => onParticipantClick(person);
    };

    const handleTPConfirm = (person: ParticipantInfo) => () => {
        onParticipantClick(person);
        setTpDialogPersonId(null);
    };

    const handleTpClose = () => {
        setTpDialogPersonId(null);
    };

    return (
        <>
            <AdaptivePopup
                anchorRef={anchorRef}
                className={cn(null)}
                dataTestId="choose-seats-popup"
                drawerProps={{
                    scope: 'inplace',
                }}
                popupProps={{
                    scope: 'inplace',
                    motionless: true,
                }}
                view="default"
                visible={visible}
                onClose={onClose}
            >
                <div className={cn('PopupContent')}>
                    <Text as="div" className={cn('EmployeeTitle')} color="secondary" typography="label-s">
                        {i18n('choose_place_for_employee')}
                    </Text>
                    {participants.map(participant => (
                        <div key={participant.id} className={cn('MenuItem')}>
                            <PersonListItem
                                border="m"
                                login={participant.login}
                                name={participant.name}
                                padding="m"
                                showMark={participant.mark === 'dash'}
                                onClick={handlePersonClick({
                                    fullName: participant.name,
                                    login: participant.login,
                                    personId: participant.id,
                                    gender: participant.gender,
                                })}
                            />
                            <Dialog
                                close={i18nAction('cancel')}
                                confirm={i18nTravelPolicy('add_service')}
                                dataTestId="confirm-add-not-compliant-service-modal"
                                keepMounted={false}
                                scope={null}
                                subtitle={getTravelPolicyMessage()}
                                title={i18nTravelPolicy('add_not_compliant_service')}
                                visible={tpDialogPersonId === participant.id}
                                onClose={handleTpClose}
                                onConfirm={handleTPConfirm({
                                    fullName: participant.name,
                                    login: participant.login,
                                    personId: participant.id,
                                    gender: participant.gender,
                                })}
                            />
                        </div>
                    ))}

                    <CompanionsListRemote
                        companions={companionsList}
                        onCompanionSelect={onParticipantClick}
                    />
                </div>
            </AdaptivePopup>
        </>
    );
};

TrainChooseSeatsPopup.displayName = cn();
