import { FC, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';

import { ConfirmationModal } from 'components/ConfirmationModal';
import { CurrentCompanyContext } from 'components/CurrentCompanyProvider/CurrentCompanyProvider.context';
import { EmployeesGroupForm } from 'components/EmployeesGroupForm';
import { HeaderPage } from 'components/HeaderPage';
import { getIconProvider, IconTrash } from 'components/Icon';
import { Page } from 'components/Page';
import { withRemote } from 'hocs/withRemote';
import { useDeleteGroup } from 'hooks/useDeleteGroup';
import { useGroupDetails } from 'hooks/useGroupDetails';
import { RouteCompanies } from 'routes/RouteCompanies';
import { RouteGroup } from 'routes/RouteGroup';
import { Button } from 'shared/ui/Button';
import { Container } from 'shared/ui/Container';
import { isLoading } from 'utils/Loadable';

import { EmptyState } from './EmptyState';
import { cn } from './PageGroup.cn';
import { i18n } from './PageGroup.i18n';
import { PageGroupRendererProps } from './PageGroup.types';

const iconProviderTrash = getIconProvider(IconTrash);

export const PageGroupRenderer: FC<PageGroupRendererProps> = props => {
    const { groupDetails } = props;

    const { currentCompanyId } = useContext(CurrentCompanyContext);

    const { push } = useHistory();

    const [isDeleteConfrimationVisible, setDeleteConfirmationVisible] = useToggle(false);

    const [deleteGroup, deleteState] = useDeleteGroup({
        onSuccess: () => {
            push(RouteCompanies.getPath({ companyId: currentCompanyId }, { tab: 'groups' }));
        },
    });

    const handleConfirmationSubmit = useCallback(() => {
        deleteGroup(groupDetails.group_id);
        setDeleteConfirmationVisible(false);
    }, [deleteGroup, groupDetails.group_id, setDeleteConfirmationVisible]);

    const deleteButton = (
        <Button
            iconLeft={iconProviderTrash}
            progress={isLoading(deleteState)}
            size="m"
            view="outline"
            onClick={setDeleteConfirmationVisible}
        >
            {i18n('delete_group')}
        </Button>
    );

    return (
        <Page className={cn()}>
            <HeaderPage actions={deleteButton} title={groupDetails.name} />
            <Container>
                <EmployeesGroupForm companyId={currentCompanyId} groupDetails={groupDetails} />

                <ConfirmationModal
                    message={i18n('confirmation_delete_message')}
                    modalViewHasCloseIcon={false}
                    modalViewOnClose={setDeleteConfirmationVisible}
                    modalViewSize="s"
                    modalViewVisible={isDeleteConfrimationVisible}
                    title={i18n('confirmation_delete_title')}
                    visible={isDeleteConfrimationVisible}
                    onCancel={setDeleteConfirmationVisible}
                    onSubmit={handleConfirmationSubmit}
                />
            </Container>
        </Page>
    );
};

const PageGroupRendererRemote = withRemote(PageGroupRenderer);

export const PageGroup: Page<RouteGroup> = props => {
    const {
        params: { groupId },
    } = props;

    const groupDetails = useGroupDetails(groupId);

    return (
        <PageGroupRendererRemote
            fallbackElement={<EmptyState />}
            groupDetails={groupDetails}
        />
    );
};

PageGroup.displayName = cn();
