import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useToggle } from 'react-use';
import { Badge } from '@yandex-int/hr-components/Badge/desktop/bundle';

import {
    getIconProvider,
    IconFilter,
    IconLocation,
} from 'components/Icon';
import { QuickFilter } from 'components/QuickFilter';
import { SearchFilters } from 'components/SearchFilters';
import { RouteSearchMap } from 'routes/RouteSearchMap';
import { Button } from 'shared/ui/Button';
import { Drawer } from 'shared/ui/Drawer';

import { getFilters } from './getFilters';
import { getQuickFilters } from './getQuickFilters';
import { cn } from './SearchFilter.cn';
import { SearchFilterProps } from './SearchFilter.types';

import './SearchFilter.css';

const iconProviderLocation = getIconProvider(IconLocation);
const iconProviderFilter = getIconProvider(IconFilter);

export const SearchFilter: FC<SearchFilterProps> = props => {
    const { disabled, params, search, innerRef } = props;
    const { type } = search;

    const [drawerVisible, drawerToggle] = useToggle(false);
    const history = useHistory();
    const searchMapUrl = RouteSearchMap.getPath(params, search);
    const gotoMap = useCallback(() => history.push(searchMapUrl), [history, searchMapUrl]);

    const quickFilters = getQuickFilters(type);

    const filters = getFilters(type);
    const filterKeys = Object.keys(filters);
    const hasActiveHotelRangeFilter = filterKeys.includes('price_from') && filterKeys.includes('price_to');
    const filtersCount = filterKeys.length - Number(hasActiveHotelRangeFilter);

    return (
        <div className={cn(null, [props.className])}>
            {quickFilters.map(filter => <QuickFilter {...props} key={filter.slug} className={cn('Button', { quickFilter: true })} disabled={disabled} quickFilter={filter} />)}
            {type === 'Hotel' && (
                <Button
                    className={cn('Button')}
                    disabled={disabled}
                    icon={iconProviderLocation}
                    size="l"
                    view="contrast"
                    onClick={gotoMap}
                />
            )}
            <Badge
                className={cn('Button')}
                content={filtersCount}
                outlineColor="transparent"
            >
                <Button
                    disabled={disabled}
                    icon={iconProviderFilter}
                    size="l"
                    view="contrast"
                    onClick={drawerToggle}
                />
            </Badge>

            <Drawer
                innerRef={innerRef}
                view="default"
                visible={drawerVisible}
                onClose={drawerToggle}
            >
                <div className={cn('SearchFilters')}>
                    <SearchFilters
                        {...props}
                    />
                </div>
            </Drawer>
        </div>
    );
};

SearchFilter.displayName = cn();
