export const en = {
    add_participant: 'Add participant',
    edit_trip_date: 'Сhange travel dates',
    trip_number: 'Travel request No. {trip_id}',
    copied: 'The number has been copied',
    cancel: 'No',
    cancel_confirmation: 'Are you sure you want to cancel your trip?',
    cancel_confirmation_with_services:
        "We'll cancel your bookings, but keep in mind that most bookings are non-refundable or are subject to cancelation fees. Please contact support to learn more about possible fees before canceling the trip.",
    cancel_confirmation_reason: "Please tell us why you're canceling the trip request",
    cancel_trip: 'Cancel my trip',
    confirm: 'Yes, cancel',
    close: 'Close',
    route: 'Route of the trip',
    trip_details: 'Trip details',
    active: 'Active',
    cancelled: 'Cancelled',
};
