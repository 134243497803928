import { FC } from 'react';

import { Bubble } from 'components/Bubble';
import { Card } from 'components/Card';
import { ItemsGroup } from 'components/ItemsGroup';
import { RouteGroup } from 'routes/RouteGroup';
import { Text } from 'shared/ui/Text';

import { cn } from './EmployeesGroupCard.cn';
import { i18n } from './EmployeesGroupCard.i18n';
import { EmployeesGroupCardProps } from './EmployeesGroupCard.types';

import './EmployeesGroupCard.css';

export const EmployeesGroupCard: FC<EmployeesGroupCardProps> = props => {
    const { className, group } = props;
    const { group_id, name, persons, person_count } = group;

    const viewGroupUrl = RouteGroup.getPath({ groupId: group_id }, {});

    const items = (persons || []).map(person => (
        <Bubble
            key={person.person_id}
            bubble={{
                login: person.login,
                name: person.login,
                type: 'person',
                personId: String(person.person_id),
            }}
            size="l"
            type="person"
        />
    ));

    return (
        <Card className={cn(null, [className])} href={viewGroupUrl}>
            <div className={cn('CardContent')}>
                <div className={cn('Title')}>
                    <Text color="primary" overflow="ellipsis" typography="title-s" weight="medium">
                        {name}
                    </Text>
                    <Text color="secondary" typography="body-s" weight="regular">
                        {i18n('users', { count: person_count || 0 })}
                    </Text>
                </div>
                <div className={cn('Users')}>
                    <ItemsGroup
                        items={items}
                        maxItems={4}
                        size="l"
                    />
                </div>
            </div>
        </Card>
    );
};

EmployeesGroupCard.displayName = cn();
