import { RouteRegisterCompany } from 'routes/RouteRegisterCompany';
import { RouteRegisterEmail } from 'routes/RouteRegisterEmail';

import { GetRedirectPath } from '../CompanyEmailForm.types';

enum PersonCreateError {
    UNKNOWN_COMPANY = 'UNKNOWN_COMPANY',
    SAME_EMAIL = 'SAME_EMAIL'
}

export const getRedirectPath: GetRedirectPath = errorDetail => {
    if (errorDetail === PersonCreateError.UNKNOWN_COMPANY) {
        return RouteRegisterCompany.path;
    }

    if (errorDetail === PersonCreateError.SAME_EMAIL) {
        return RouteRegisterEmail.path;
    }

    return null;
};
