import { useMemo } from 'react';

import { TransactionFormFields } from 'components/TransactionForm';
import { Transaction } from 'services/SwaggerApi';

import { parseDate } from '../TransactionEditModal.utils/parseDate';

type UseDefaultValues = (transaction: Transaction) => TransactionFormFields;

export const useDefaultValues: UseDefaultValues = transaction => {
    const {
        execution_date,
        invoice_date,
        price,
        fee,
        general_service_id,
        trip_id,
        person,
        service_type,
        type,
        is_penalty,
        cost_center,
        status,
    } = transaction;

    return useMemo<TransactionFormFields>(() => ({
        execution_date: new Date(execution_date),
        invoice_date: parseDate(invoice_date),
        price: parseFloat(price),
        fee: parseFloat(fee),
        general_service_id,
        trip_id,
        person: {
            id: person.person_id,
            title: [person.first_name, person.last_name].join(' '),
            login: person.login,
        },
        cost_center: cost_center?.cost_center_id,
        service_type,
        type,
        status,
        is_penalty,
    }), [
        cost_center?.cost_center_id, execution_date, fee, general_service_id, invoice_date, is_penalty,
        person.first_name, person.last_name, person.login, person.person_id, status, price,
        service_type, trip_id, type,
    ]);
};
