import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import { FieldRadioButton, FormProvider } from 'components/FormField2';
import { useMeta } from 'hooks/useMeta';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';
import { overrideMetaUser } from 'utils/overrideMetaUser';

import { cn } from '../Header.cn';

import { useChangeCompany } from './hooks/useChangeCompany';

import './SwitchProvider.css';

const options = [{
    children: <Text className={cn('Aeroclub')}>АК</Text>,
    value: '1',
},
{
    children: <Text className={cn('Aviacenter')}>АЦ</Text>,
    value: '41',
},
{
    children: <Text className={cn('Fake')}>F</Text>,
    value: '100000',
}];

export const SwitchProvider: FC = () => {
    const meta = useMeta();
    const { user: { company_id, person_id } } = meta;

    const queryClient = useQueryClient();

    const methods = useForm<{company_id: string}>({
        defaultValues: { company_id: String(company_id) },
    });

    const [changeProvider, changeState] = useChangeCompany({
        onSuccess: () => {
            queryClient.setQueryData('auth_meta', overrideMetaUser(meta, { company_id: Number(methods.getValues().company_id) }));
        },
    });

    useEffect(() => {
        const subscription = methods.watch((data => {
            changeProvider(person_id, { company_id: Number(data.company_id) });
        }));

        return () => subscription.unsubscribe();
    }, [changeProvider, meta, methods, person_id, queryClient]);

    return (
        <FormProvider methods={methods}>
            <form className={cn('SwitchProvider')}>
                <FieldRadioButton
                    disabled={isLoading(changeState)}
                    name="company_id"
                    options={options}
                    view="default"
                />
            </form>
        </FormProvider>

    );
};
