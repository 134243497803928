import { FC } from 'react';
import { Status } from '@yandex-int/hr-components/Status/bundle';

import { Cell } from 'components/Cell';
import { withTdAttrs } from 'hocs/withTdAttrs';

import { cn } from './CellStatus.cn';
import { CellStatusProps } from './CellStatus.types';

import './CellStatus.css';

const CellStatusBase: FC<CellStatusProps> = props => {
    const {
        className,
        value: { color, text },
        getAttrs,
        dropDownContent,
    } = props;

    return (
        <Cell
            className={cn(null, [className])}
            dropDownContent={dropDownContent}
            getAttrs={getAttrs}
            size="m"
        >
            <Status
                className={cn('Status')}
                color={color}
                size="m"
                text={text}
                type="label"
            />
        </Cell>
    );
};

export const CellStatus = withTdAttrs(CellStatusBase);
CellStatus.displayName = cn();
