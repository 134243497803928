import { FC, useCallback, useMemo } from 'react';
import { Field } from 'react-final-form';

import { AppLink } from 'components/AppLink';
import { RadioOption } from 'components/FormField';
import { FieldCheckbox, FieldRadioToggle, FieldTextinput } from 'components/FormField/FormField.providers';
import { ConferenceParticiationType } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';
import { validateRequiredField } from 'utils/form/validateRequiredField';

import { cn } from '../FormTripCreate.cn';
import { i18n, i18nRaw } from '../FormTripCreate.i18n';

import { ConfUserInfoFieldsProps } from './ConfFields.types';

const roleIsSpeaker = (role: ConferenceParticiationType | undefined): boolean => role === 'speaker';
const roleIsOther = (role: ConferenceParticiationType | undefined): boolean => role === 'other';

export const ConfUserInfoFields: FC<ConfUserInfoFieldsProps> = props => {
    const { name, value } = props;

    const roleOptions = useMemo<RadioOption[]>(() => [
        { value: 'listener', content: i18n('listener'), label: i18n('listener') },
        { value: 'speaker', content: i18n('speaker'), label: i18n('speaker') },
        { value: 'other', content: i18n('other_role'), label: i18n('other_role') },
    ], []);

    const attendanceOptions = useMemo<RadioOption[]>(() => [
        { value: 'true', content: i18n('offline'), label: i18n('offline') },
        { value: 'false', content: i18n('online'), label: i18n('online') },
    ], []);

    // https://github.com/final-form/react-final-form/issues/130
    const identity = useCallback(value => value, []);

    return (
        <div className={cn('ConfUserInfoFields')}>
            <Field
                required
                className={cn('Field')}
                component={FieldTextinput}
                initialValue={value.title}
                name={`${name}.badge_name`}
                parse={identity}
                placeholder={i18n('name_for_badge_placeholder')}
                title={i18n('name_for_badge')}
                validate={validateRequiredField}
            />
            <Field
                required
                className={cn('Field')}
                component={FieldTextinput}
                initialValue=""
                name={`${name}.badge_position`}
                parse={identity}
                placeholder={i18n('position_for_badge_placeholder')}
                title={i18n('position_for_badge')}
                validate={validateRequiredField}
            />
            <div className={cn('ConfRoleGroup')}>
                <Field
                    multiline
                    required
                    className={cn('Field', ['ConfRoleField'])}
                    component={FieldRadioToggle}
                    name={`${name}.role`}
                    options={roleOptions}
                    parse={identity}
                    title={i18n('role')}
                    validate={validateRequiredField}
                />
                {roleIsOther(value.role) &&
                    <Field
                        required
                        className={cn('ThinField', ['ConfOtherRoleField'])}
                        component={FieldTextinput}
                        name={`${name}.other_role`}
                        placeholder={i18n('other_role_placeholder')}
                        validate={validateRequiredField}
                    />
                }
            </div>
            {roleIsSpeaker(value.role) &&
                <div className={cn('PresentationTopicGroup')}>
                    <Field
                        required
                        className={cn('Field', ['ConfPresentationTopicField'])}
                        component={FieldTextinput}
                        name={`${name}.presentation_topic`}
                        placeholder={i18n('presentation_topic_placeholder')}
                        title={i18n('presentation_topic')}
                        validate={validateRequiredField}
                    />
                    <Field
                        className={cn('ThinField')}
                        component={FieldCheckbox}
                        hint={
                            <Text
                                as="div"
                                className={cn('Description')}
                                typography="paragraph-s"
                            >
                                {i18nRaw('is_pr_approved_description', { link: (
                                    <AppLink
                                        href="https://pr.yandex-team.ru/"
                                    >
                                        {i18n('is_pr_approved_description_link')}
                                    </AppLink>
                                ) })}
                            </Text>
                        }
                        name={`${name}.is_hr_approved`}
                        title={i18n('is_pr_approved')}
                        type="checkbox"
                    />
                    <Field
                        className={cn('IsPaidByHostField')}
                        component={FieldCheckbox}
                        hint={value.is_paid_by_host && (
                            <Text
                                as="div"
                                className={cn('Description')}
                                typography="paragraph-s"
                            >
                                {i18n('is_paid_by_host_description')}{' '}
                                <AppLink
                                    href="https://forms.yandex-team.ru/surveys/30131/"
                                >
                                    {i18n('form_for_approval')}
                                </AppLink>
                            </Text>
                        )}
                        name={`${name}.is_paid_by_host`}
                        title={i18n('is_paid_by_host')}
                        type="checkbox"
                    />
                </div>
            }
            <Field
                multiline
                required
                className={cn('Field', ['ConfAttendanceOptionsField'])}
                component={FieldRadioToggle}
                name={`${name}.is_offline`}
                options={attendanceOptions}
                parse={identity}
                title={i18n('attendance_options')}
                validate={validateRequiredField}
            />
        </div>
    );
};
