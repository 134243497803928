import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormProvider } from 'components/FormField2';
import { TabbedFormsLayout } from 'components/TabbedFormsLayout';
import { useCreateGroup } from 'hooks/useCreateGroup';
import { useEditGroup } from 'hooks/useEditGroup';
import { i18nAction } from 'i18n/i18nAction';
import { i18nErrors } from 'i18n/i18nErrors';
import { RouteGroup } from 'routes/RouteGroup';
import { errorToast } from 'utils/errorToast';
import { isLoading } from 'utils/Loadable';

import { ActionsCard } from './ActionsCard';
import { cn } from './EmployeesGroupForm.cn';
import { i18n } from './EmployeesGroupForm.i18n';
import { schema } from './EmployeesGroupForm.schema';
import { EmployeesGroupFormFields, EmployeesGroupFormProps } from './EmployeesGroupForm.types';
import { Settings } from './Settings';
import { UsersInGroup } from './UsersInGroup';

export const EmployeesGroupForm: FC<EmployeesGroupFormProps> = props => {
    const { className, companyId, groupDetails } = props;

    const { push } = useHistory();

    const [membersIds, setMembersIds] = useState<string[]>(groupDetails?.person_ids || []);

    const methods = useForm<EmployeesGroupFormFields>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: {
            ...(groupDetails && {
                group_name: groupDetails.name,
                travel_policy: groupDetails.travel_policy_id || 0,
            }),
        },
    });

    const [createGroup, createGroupStatus] = useCreateGroup({
        onSuccess: ({ group_id: groupId }) => push(RouteGroup.getPath({ groupId }, {})),
        onError: e => {
            errorToast(e, { title: i18nErrors('error_in_save_data') });
        },
        options: {
            hideErrorNotifier: true,
        },
    });

    const [editGroup, editGroupStatus] = useEditGroup({
        onSuccess: () => {
            methods.reset({}, { keepValues: true });
        },
        onError: e => {
            errorToast(e, { title: i18nErrors('error_in_save_data') });
        },
        options: {
            hideErrorNotifier: true,
        },
    });

    const isNewGroup = !groupDetails;

    const isFormEdited = methods.formState.isDirty;

    const tabbedItems = [
        {
            id: 'settings',
            title: i18n('settings'),
            content: <Settings companyId={companyId} />,
        },
        {
            id: 'users',
            title: i18n('users_in_group'),
            content: (
                <UsersInGroup
                    groupDetails={groupDetails}
                    membersIds={membersIds}
                    setMembersIds={setMembersIds}
                />
            ),
        },
    ];

    const handleSubmit = (values: EmployeesGroupFormFields) => {
        const payload = {
            name: values.group_name,
            person_ids: membersIds,
            travel_policy_id: values.travel_policy || null,
        };

        if (isNewGroup) {
            createGroup({ ...payload, company_id: companyId });
        } else {
            editGroup(groupDetails.group_id, payload);
        }
    };

    return (
        <FormProvider methods={methods} schema={schema}>
            <form className={cn(null, [className])} onSubmit={methods.handleSubmit(handleSubmit)}>
                <TabbedFormsLayout
                    actions={

                        <ActionsCard
                            actionCaption={isNewGroup ? i18n('save_caption') : i18n('save_changes_caption')}
                            actionDisabled={!isFormEdited && !isNewGroup}
                            actionText={isNewGroup ? i18nAction('save') : i18nAction('save_changes')}
                            progress={isLoading(createGroupStatus) || isLoading(editGroupStatus)}
                        />
                    }
                    items={tabbedItems}
                />
            </form>
        </FormProvider>
    );
};

EmployeesGroupForm.displayName = cn();
