export function addYmapScript(lang: string, theme: string = 'light') {
    if (document.getElementById('geomap')) return Promise.resolve();

    return new Promise<void>((resolve, reject) => {
        const scriptElement = document.createElement('script');

        scriptElement.setAttribute('src', `https://api-maps.yandex.ru/2.1/?apikey=&lang=${lang}&theme=${theme}`);
        scriptElement.setAttribute('type', 'text/javascript');
        scriptElement.setAttribute('id', 'geomap');

        scriptElement.onload = () => {
            resolve();
        };

        scriptElement.onerror = () => {
            reject();
        };

        document.body.appendChild(scriptElement);
    });
}
