import {
    AviaServiceDetails,
    HotelServiceDetails,
    RailServiceDetails,
} from 'services/SwaggerApi';
import { ServiceDetails } from 'types/TripRoute';

export function switchTypeFn<TReturn>(options: {
  rail: (order: RailServiceDetails) => TReturn;
  hotel: (order: HotelServiceDetails) => TReturn;
  avia: (order: AviaServiceDetails) => TReturn;
}) {
    return (service: ServiceDetails) => {
        switch (service.service_type) {
            case 'avia':
                return options.avia(service);

            case 'hotel':
                return options.hotel(service);

            case 'rail':
                return options.rail(service);
        }
    };
}
