import { FC } from 'react';
import { useToggle } from 'react-use';

import {
    FlightInfoFareFamily,
    FlightInfoFareFamilySkeleton,
} from 'components/FlightInfoFareFamily';
import { FlightInfoRoute } from 'components/FlightInfoRoute';
import { CollapseButton } from 'components/FlightServiceCard/CollapseButton/CollapseButton';
import { FormProvider } from 'components/FormField2';
import { withRemote } from 'hocs/withRemote';
import { Text } from 'shared/ui/Text';
import { getFlightCitiesText } from 'utils/aeroclub/getFlightCitiesText';
import { getFlightDatesText } from 'utils/formatDate/getFlightDatesText';

import { FieldFlightClass } from '../FieldFlightClass';
import { cn } from '../FlightFareFamily.cn';
import { i18n } from '../FlightFareFamily.i18n';

import { MainProps } from './Main.types';

import './Main.css';

const FlightInfoFareFamilyRemote = withRemote(FlightInfoFareFamily);
const FieldFlightClassRemote = withRemote(FieldFlightClass);

export const Main: FC<MainProps> = props => {
    const {
        isFareFamily,
        flightOptions,
        activeTariff,
        setActiveTariff,
        unsafeRef,
        flightClasses,
        formFlighClassMethods,
    } = props;

    const [isCollapsed, toggleCollapse] = useToggle(true);

    const showCollapseButton = activeTariff.legs?.filter(leg => leg.segments.length > 1).length !== 0;

    const citiesText = getFlightCitiesText(activeTariff);
    const datesText = getFlightDatesText(activeTariff);

    return (
        <>
            <div className={cn('Header')}>
                <div className={cn('RouteInfo')}>
                    <Text className={cn('Cities')} typography="title-m">
                        {citiesText}
                    </Text>
                    <Text className={cn('Dates')} color="secondary" weight="regular">
                        {datesText}
                    </Text>
                </div>
                {showCollapseButton && (
                    <CollapseButton
                        className={cn('CollapseButton')}
                        isCollapsed={isCollapsed}
                        onButtonClick={toggleCollapse}
                    />
                )}
            </div>
            <div className={cn('Route')}>
                {activeTariff.legs?.map((leg, index) => {
                    const isRoundTrip = activeTariff.legs?.length === 2;
                    const direction = (isRoundTrip && index === 0) ? 'from' : 'to';

                    return (
                        <FlightInfoRoute
                            key={index}
                            extendable
                            direction={isRoundTrip ? direction : null}
                            isCollapsed={isCollapsed}
                            leg={leg}
                            toggleCollapse={toggleCollapse}
                        />
                    );
                })}
            </div>
            {isFareFamily && (
                <div className={cn('Tariffs')}>
                    <div className={cn('TariffsHeader')}>
                        <Text
                            as="div"
                            className={cn('TariffsTitle')}
                            typography="title-s"
                            weight="medium"
                        >
                            {i18n('choose_tariff')}
                        </Text>
                        <FormProvider methods={formFlighClassMethods}>
                            <FieldFlightClassRemote
                                fallbackElement={<></>}
                                flightClasses={flightClasses}
                                unsafeRef={unsafeRef}
                            />
                        </FormProvider>
                    </div>

                    <FlightInfoFareFamilyRemote
                        activeTariff={activeTariff}
                        flightOptions={flightOptions}
                        skeleton={<FlightInfoFareFamilySkeleton />}
                        onTariffChange={setActiveTariff}
                    />
                </div>
            )}
        </>
    );
};
