import { FC, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ButtonPlatform } from 'components/ButtonPlatform';
import {
    FieldCheckbox,
    FieldDate,
    FieldSelect,
    FieldSuggest,
    FieldTextinput,
    FormProvider,
} from 'components/FormField2';
import { config } from 'config';
import { YaAuthDataProviderError } from 'errors/YaAuthDataProviderError';
import { useMeta } from 'hooks/useMeta';
import { i18nCitizenship } from 'i18n/TT/i18nCitizenship';
import { i18nDocumentType } from 'i18n/TT/i18nDocumentType';
import { PersonDocumentCreate } from 'services/SwaggerApi';
import { InlineNotification } from 'shared/ui/InlineNotification';
import { Text } from 'shared/ui/Text';
import { errorToast } from 'utils/errorToast';
import { isLoading } from 'utils/Loadable';
import { logError } from 'utils/logError';
import { serializeDate } from 'utils/serializeDate';

import { useCreateDocumentHook } from './DocumentForm.hooks/useCreateDocumentHook';
import { useYaAuthInitSave } from './DocumentForm.utils/useYaAuthInitSave';
import { cn } from './DocumentForm.cn';
import { i18n } from './DocumentForm.i18n';
import {
    documentTypes,
    RUSSIA_COUNTRY_ID,
    schema,
} from './DocumentForm.schema';
import { DocumentFormFields, DocumentFormProps } from './DocumentForm.types';

// import { useGetCreateDocument } from './useGetCreateDocument.hook';
import './DocumentForm.css';

const selectDependencies = ['first_name', 'last_name', 'middle_name', 'expires_on', 'series', 'number'];

export const DocumentForm: FC<DocumentFormProps> = props => {
    const {
        personInfo,
        personType = 'person',
        className,
        onSubmit,
        onCancel,
    } = props;

    const {
        first_name,
        last_name,
        middle_name,
        person_id,
    } = personInfo;

    const yaAuthInitSave = useYaAuthInitSave();
    const { user: { is_allowed_store_documents } } = useMeta();

    const documentOptions = documentTypes.map(documentType => ({
        value: documentType,
        content: i18nDocumentType(documentType),
    }));

    const methods = useForm<DocumentFormFields>({
        defaultValues: {
            ...(first_name && { first_name }),
            ...(last_name && { last_name }),
            ...(middle_name && { middle_name }),
            document_type: 'passport',
            no_middle_name: false,
            citizenship: {
                id: RUSSIA_COUNTRY_ID,
                title: i18nCitizenship('RU'),
            },
        },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { createDocumentFn, createState, error } = useCreateDocumentHook({ onSubmit, personType });

    const [documentType, noMiddleName] = methods.watch(['document_type', 'no_middle_name']);

    const handleSubmit: SubmitHandler<DocumentFormFields> = values => {
        const { issued_on, expires_on, citizenship, ...restValues } = values;
        const serializedData: PersonDocumentCreate = {
            ...restValues,
            issued_on: serializeDate(issued_on),
            expires_on: serializeDate(values.expires_on),
            country_id: Number(values.citizenship?.id),
        };

        const mustAskToStoreDocuments = config.isB2B && !is_allowed_store_documents && personType === 'person';

        if (mustAskToStoreDocuments) {
            yaAuthInitSave(() => createDocumentFn(person_id, serializedData)).catch(e => {
                logError(new YaAuthDataProviderError('initSave', e));
                errorToast(e, { title: i18n('something_went_wrong') });
            });

            return;
        }

        createDocumentFn(person_id, serializedData);
    };

    useEffect(() => {
        if (noMiddleName) {
            methods.setValue('middle_name', '');
            methods.clearErrors('middle_name');
        }
    }, [methods, noMiddleName]);

    return (
        <FormProvider error={error} methods={methods} schema={schema}>
            <form
                className={cn(null, [className])}
                data-testid={cn()}
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <Text className={cn('AddDocumentTitle')} typography="title-m">
                    {i18n('add_document')}
                </Text>
                {documentType === 'passport' && (
                    <InlineNotification
                        className={cn('Warning')}
                        view="info"
                    >
                        {i18n('latin_name_warning')}
                    </InlineNotification>
                )}
                <FieldSelect
                    deps={selectDependencies}
                    label={i18n('document_type')}
                    name="document_type"
                    options={documentOptions}
                />
                <FieldSuggest
                    deps={selectDependencies}
                    label={i18n('citizenship')}
                    name="citizenship"
                    type="countries"
                />
                <FieldTextinput
                    label={i18n('first_name')}
                    name="first_name"
                />
                <FieldTextinput
                    label={i18n('last_name')}
                    name="last_name"
                />
                <FieldTextinput
                    className={cn('MiddleName')}
                    disabled={noMiddleName}
                    label={i18n('middle_name')}
                    name="middle_name"
                />
                <FieldCheckbox
                    checkboxLabel={i18n('no_middle_name')}
                    className={cn('NoMiddleName')}
                    name="no_middle_name"
                />
                <FieldTextinput
                    label={i18n('series')}
                    name="series"
                />
                <FieldTextinput
                    label={i18n('number')}
                    name="number"
                />
                <FieldDate
                    label={i18n('issued_on')}
                    name="issued_on"
                />
                <FieldDate
                    label={i18n('expires_on')}
                    name="expires_on"
                />
                <div className={cn('Controls')}>
                    <ButtonPlatform
                        className={cn('Cancel')}
                        view="ghost"
                        onClick={onCancel}
                    >
                        {i18n('cancel')}
                    </ButtonPlatform>
                    <ButtonPlatform
                        className={cn('Submit')}
                        progress={isLoading(createState)}
                        type="submit"
                        view="primary"
                    >
                        {i18n('save')}
                    </ButtonPlatform>
                </div>
            </form>
        </FormProvider>
    );
};

DocumentForm.displayName = cn();
