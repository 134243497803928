import { FC } from 'react';
import { IServicesList, Services } from '@yandex-int/hr-components/Services';

import { getTypedI18nLang } from 'utils/i18n';

import { IHeaderServicesProps } from './HeaderServices.types';

/* Пока что нет общего места для списка сервисов, hr-components рекомендуют зашивать на проектах */
const services: IServicesList = {
    main: [
        {
            href: 'https://wiki.yandex-team.ru/?utm_source=headburger',
            icon: <img height={44} src={'https://yastatic.net/s3/frontend/yandex-lego/serp-header/_/39J96k61.svg'} width={44} />,
            name: { en: 'Wiki', ru: 'Вики' },
        },
        {
            href: 'https://st.yandex-team.ru/?utm_source=headburger',
            icon: <img height={44} src={'https://yastatic.net/s3/frontend/yandex-lego/serp-header/_/pUkSMmRV.svg'} width={44} />,
            name: { ru: 'Трекер', en: 'Tracker' },
        },
        {
            href: 'https://mail.yandex-team.ru/',
            icon: <img height={44} src={'https://yastatic.net/s3/frontend/yandex-lego/serp-header/_/aLGMZL9E.svg'} width={44} />,
            name: { ru: 'Почта', en: 'Mail' },
        },
        {
            href: 'https://staff.yandex-team.ru/?utm_source=headburger',
            icon: <img height={44} src={'https://yastatic.net/s3/frontend/yandex-lego/serp-header/_/3cfCtaRp.svg'} width={44} />,
            name: { ru: 'Стафф', en: 'Staff' },
        },
        {
            href: 'https://my.at.yandex-team.ru/?utm_source=headburger',
            icon: <img height={44} src={'https://yastatic.net/s3/frontend/yandex-lego/serp-header/_/SPcdJGrF.svg'} width={44} />,
            name: { ru: 'Этушка', en: 'Atushka' },
        },
        {
            href: 'https://calendar.yandex-team.ru/',
            icon: <img height={44} src={'https://yastatic.net/s3/frontend/yandex-lego/serp-header/_/2FKbMk44.svg'} width={44} />,
            name: { ru: 'Календарь', en: 'Calendar' },
        },
        {
            href: 'https://vconf.yandex-team.ru/?utm_source=headburger',
            icon: <img height={44} src={'https://yastatic.net/s3/frontend/yandex-lego/serp-header/_/35eye1Qd.svg'} width={44} />,
            name: { ru: 'VConf', en: 'VConf' },
        },
        {
            href: 'https://help.yandex-team.ru',
            icon: <img height={44} src={'https://yastatic.net/s3/frontend/yandex-lego/serp-header/_/33TU3mv4.svg'} width={44} />,
            name: { ru: 'ServiceDesk', en: 'ServiceDesk' },
        },
    ],
    secondary: [
        {
            name: {
                ru: 'ABC',
                en: 'ABC',
            },
            href: 'https://abc.yandex-team.ru/?utm_source=headburger',
        },
        {
            name: {
                ru: 'Telegram Tool',
                en: 'Telegram Tool',
            },
            href: 'https://tg.yandex-team.ru/?utm_source=headburger',
        },
        {
            name: {
                ru: 'IDM',
                en: 'IDM',
            },
            href: 'https://idm.yandex-team.ru/?utm_source=headburger',
        },
        {
            name: {
                ru: 'Яндекс Командировки',
                en: 'Yandex Trip',
            },
            href: 'https://trip.yandex-team.ru/',
        },
        {
            name: {
                ru: 'Переговорки',
                en: 'Invite',
            },
            href: 'https://calendar.yandex-team.ru/invite/',
        },
        {
            name: {
                ru: 'Фемида',
                en: 'Femida',
            },
            href: 'https://femida.yandex-team.ru/?utm_source=headburger',
        },
        {
            name: {
                ru: 'Документация',
                en: 'Documentation',
            },
            href: 'https://doc.yandex-team.ru/',
        },
        {
            name: {
                ru: 'Лего',
                en: 'Lego',
            },
            href: 'https://lego.yandex-team.ru/',
        },
        {
            name: {
                ru: 'Журнал',
                en: 'Magazine',
            },
            href: 'https://clubs.at.yandex-team.ru/mag/?from=header&utm_source=headburger',
        },
        {
            name: {
                ru: 'Дизайн',
                en: 'Design',
            },
            href: 'https://wiki.yandex-team.ru/design/Design-code/?utm_source=headburger',
        },
        {
            name: {
                ru: 'Рассылки',
                en: 'Maillists',
            },
            href: 'https://ml.yandex-team.ru/',
        },
        {
            name: {
                ru: 'Цели',
                en: 'Goals',
            },
            href: 'https://goals.yandex-team.ru/?utm_source=headburger',
        },
        {
            name: {
                ru: 'Статистика',
                en: 'Statistics',
            },
            href: 'https://stat.yandex-team.ru/',
        },
        {
            name: {
                ru: 'Мёбиус',
                en: 'Möbius',
            },
            href: 'https://moe.yandex-team.ru/courses/my',
        },
        {
            name: {
                ru: 'Каталог обучения',
                en: 'Studying program',
            },
            href: 'https://lms.yandex-team.ru/',
        },
        {
            name: {
                ru: 'Ментор',
                en: 'Mentor',
            },
            href: 'https://mentor.yandex-team.ru/',
        },
        {
            name: {
                ru: 'Порекомендовать',
                en: 'Recommend',
            },
            href: 'https://femida.yandex-team.ru/vacancies/publications/recommend/?utm_source=headburger',
        },
        {
            name: {
                ru: 'YT',
                en: 'YT',
            },
            href: 'https://yt.yandex-team.ru/',
        },
        {
            name: {
                ru: 'YDB',
                en: 'YDB',
            },
            href: 'https://ydb.yandex-team.ru/',
        },
        {
            name: {
                ru: 'Monitoring',
                en: 'Monitoring',
            },
            href: 'https://monitoring.yandex-team.ru/',
        },
        {
            name: {
                ru: 'YQL',
                en: 'YQL',
            },
            href: 'https://yql.yandex-team.ru/',
        },
        {
            name: {
                ru: 'Yandex.Cloud',
                en: 'Yandex.Cloud',
            },
            href: 'https://yc.yandex-team.ru/',
        },
        {
            name: {
                ru: 'Crowd',
                en: 'Crowd',
            },
            href: 'https://wiki.yandex-team.ru/crowd/?utm_wiki=wikimenu_link_launch1',
        },
    ],
};

export const HeaderServices: FC<IHeaderServicesProps> = () => {
    const lang = getTypedI18nLang();

    return (
        <Services lang={lang} services={services} />
    );
};
