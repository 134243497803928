export const getQueryParamsFromSearch = (search: string) => {
    const paramsObj: Record<string, string | string[]> = {};
    const searchParams = new URLSearchParams(decodeURI(search));
    const keys = new Set(searchParams.keys());

    for (const key of keys) {
        const params = searchParams.getAll(key);

        paramsObj[key] = params.length === 1 ? params[0] : params;
    }

    return paramsObj;
};

export const getQueryParams = () => {
    return getQueryParamsFromSearch(window.location.search);
};
