import { EmployeeRoleType, EmployeeStatusForFilter, TEmployee_listQuery } from 'services/SwaggerApi';
import { serializePerson } from 'utils/serializePerson';

import { TableEmployeesFilters } from '../TableEmployees.types';

type SerializeFilters = (filters: TableEmployeesFilters) => TEmployee_listQuery;

export const serializeFilters: SerializeFilters = filters => {
    const roles: EmployeeRoleType[] = [];

    if (filters.coordinator) roles.push('coordinator');
    if (filters.user) roles.push('user');
    if (filters.assistant) roles.push('assistant');

    const statuses: EmployeeStatusForFilter[] = [];

    if (filters.activated) statuses.push('activated');
    if (filters.blocked) statuses.push('blocked');

    const employee_id = serializePerson(filters.employeeSuggest);

    return {
        roles,
        statuses,
        employee_id,
    };
};
