import { ServerError } from 'components/FormField2';
import { UnknownError } from 'errors';
import { BadRequestError } from 'errors/BadRequestError';
import { ForbiddenError } from 'errors/ForbiddenError';
import { GatewayTimeoutError } from 'errors/GatewayTimeoutError';
import { HTTPError } from 'errors/HTTPError';
import { InternalServerError } from 'errors/InternalServerError';
import { NotFoundError } from 'errors/NotFoundError';
import { UnauthorizedError } from 'errors/UnauthorizedError';
import { UnprocessableEntityError } from 'errors/UnprocessableEntityError';
import { ValidationError } from 'services/SwaggerApi';

export function isValidationError(errorDetail: unknown): errorDetail is ValidationError[] {
    if (!Array.isArray(errorDetail)) {
        return false;
    }

    return errorDetail[0]?.type?.includes('value_error');
}

export const isExtraValidationError = (error: ServerError['error']) => (error?.status === 404 && error?.detail === 'PersonTrip does not exist');

export const isFormError = (error: ServerError['error']) => {
    return isValidationError(error?.detail) || isExtraValidationError(error);
};

export type FormValidationError = {
    msg: string;
    formInputName: string;
};

export const responseToHTTPError = (error: ResponseOnStatus<ErrorCode, unknown>): HTTPError => {
    const { requestId, body } = error;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const unknownErrorDetail = (body as any)?.detail;

    let message: string;
    const validationErrors: FormValidationError[] = [];

    if (typeof unknownErrorDetail === 'string') {
        // В таком виде приходит ошибка от АК
        message = unknownErrorDetail;
    } else if (Array.isArray(unknownErrorDetail)) {
        // В таком виде приходит ошибка он нашего бека
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        message = (unknownErrorDetail)
            .map(detail => detail?.msg)
            .filter(msg => typeof msg === 'string')
            .join('\n');
    } else {
        message = JSON.stringify(body);
    }

    if (isValidationError(unknownErrorDetail)) {
        unknownErrorDetail.forEach(errorDetail => {
            validationErrors.push({
                msg: errorDetail.msg,
                formInputName: String(errorDetail.loc[errorDetail.loc.length - 1]),
            });
        });
    }

    switch (error.status) {
        case 400:
            return new BadRequestError(message, requestId, unknownErrorDetail);

        case 401:
            return new UnauthorizedError(message, requestId, unknownErrorDetail);

        case 403:
            return new ForbiddenError(message, requestId, unknownErrorDetail);

        case 404:
            return new NotFoundError(message, requestId, unknownErrorDetail);

        case 422:
            return new UnprocessableEntityError(message, requestId, validationErrors, unknownErrorDetail);

        case 500:
            return new InternalServerError(message, requestId, unknownErrorDetail);

        case 504:
            return new GatewayTimeoutError(message, requestId, unknownErrorDetail);

        default:
            throw new UnknownError(message, unknownErrorDetail);
    }
};
