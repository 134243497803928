import { ComponentProps, FC } from 'react';
import { RadioButton } from '@yandex-lego/components/RadioButton/desktop/bundle';

import { useHandleChange } from 'components/FormField2/utils/useHandleChange';

import { withFormField } from '../../FormField2';
import { cn as cnFormField } from '../../FormField2.cn';
import { FormFieldProps } from '../../FormField2.types';

import { cn } from './FieldRadioButton.cn';

import './FieldRadioButton.css';

export type WithTypeRadioButtonProps = Partial<ComponentProps<typeof RadioButton>> & {
    width?: 'max' | 'auto';
};

const Control: FC<WithTypeRadioButtonProps & FormFieldProps> = props => {
    const {
        name,
        size = 'm',
        deps,
        controller,
        context,
        width = 'auto',
        options = [],
        ...radioButtonProps
    } = props;

    const {
        field: { value },
    } = controller;

    const handleChange = useHandleChange({ controller, context, deps });

    return (
        <RadioButton
            {...radioButtonProps}
            className={cnFormField('Control', { name }, [cn({ maxWidth: width === 'max' })])}
            name={name}
            options={options}
            size={size}
            value={value}
            view="default"
            onChange={handleChange}
        />
    );
};

export const FieldRadioButton = withFormField(Control);
