import { FC } from 'react';

import { TrainCarriage } from 'components/TrainCarriage';

import { cn } from './TrainSeatSelect.cn';
import { TrainSeatSelectProps } from './TrainSeatSelect.types';

import './TrainSeatSelect.css';

export const TrainSeatSelect: FC<TrainSeatSelectProps> = props => {
    const {
        carriage: {
            carriage_number: carriageNumber,
            places = [],
        },
        train,
        chosenPlace,
        onChange,
        className,
    } = props;

    const availablePlaces = places.map(({ place_number }) => place_number);

    const chosenPlaces = chosenPlace ? [chosenPlace] : [];

    return (
        <div className={cn(null, [className])}>
            <TrainCarriage
                canAddServices
                availablePlaces={availablePlaces}
                carriageNumber={carriageNumber}
                chosenPlaces={chosenPlaces}
                currentSelectedSeat={null}
                train={train}
                onChange={onChange}
            />
        </div>
    );
};

TrainSeatSelect.displayName = cn();
