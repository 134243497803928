import { FC, useMemo } from 'react';
import { useToggle } from 'react-use';

import { FlightInfoRoute } from 'components/FlightInfoRoute';
import { IconArrowShortRight } from 'components/Icon';
import { ServiceCard } from 'components/ServiceCard';
import { ServiceCardFooter } from 'components/ServiceCardFooter';
import { ServiceCostDetails } from 'components/ServiceCostDetails';
import { ServiceHeader } from 'components/ServiceHeader';
import { ServiceState } from 'components/ServiceState';
import { usePersonDetailPublic } from 'hooks/usePerson';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { getFlightCardValues } from 'utils/getFlightCardValues';

import { CollapseButton } from './CollapseButton/CollapseButton';
import { FlightCardOptions } from './FlightCardOptions/FlightCardOptions';
import { cn } from './FlightServiceCard.cn';
import { i18n } from './FlightServiceCard.i18n';
import { FlightServiceCardProps } from './FlightServiceCard.types';

import './FlightServiceCard.css';

export const FlightServiceCard: FC<FlightServiceCardProps> = props => {
    const {
        className,
        service,
        serviceDetails,
    } = props;

    const {
        is_changeable,
        is_refundable,
    } = serviceDetails.flight;

    const { price } = serviceDetails;
    const tariffTotal =
        Number(price.service_part) +
        Number(price.additional_products_part ?? 0) +
        Number(price.ext_persons_part ?? 0);

    const person = usePersonDetailPublic(service.person_id);

    const {
        legs,
        baggage,
        banner,
    } = getFlightCardValues(
        serviceDetails.flight,
        serviceDetails.is_travel_policy_compliant,
        serviceDetails.reservation_expires_at_utc,
        person,
    );

    const [isCollapsed, toggleCollapse] = useToggle(true);

    const showCollapseButton = legs.filter(leg => leg.segments.length > 1).length > 0;

    const triggerElement = useMemo(() =>
        <>
            {i18n('passengers_count', { count: (serviceDetails.ext_persons_count || 0) + 1 })}
            <IconArrowShortRight className={cn('Icon')} data-testid="cost_details" />
        </>, [serviceDetails.ext_persons_count]);

    return (
        <div className={cn(null, [className])}>
            <ServiceCard
                banner={banner}
            >
                <div className={cn('Header')}>
                    <ServiceHeader
                        className={cn('ServiceHeader')}
                        serviceDetails={serviceDetails}
                    />
                    <div className={cn('StateAndCollapse', { oneItem: !showCollapseButton })}>
                        {showCollapseButton && (
                            <CollapseButton
                                className={cn('CollapseTrigger')}
                                isCollapsed={isCollapsed}
                                onButtonClick={toggleCollapse}
                            />
                        )}
                        <ServiceState
                            className={cn('ServiceState')}
                            isCancelling={service.in_process_of_cancelling}
                            serviceType={service.type}
                            state={serviceDetails.service_state}
                        />
                    </div>
                </div>
                <div className={cn('CardContent')}>
                    <div className={cn('Cards')}>
                        {legs.map((leg, index) => {
                            const isRoundTrip = legs.length === 2;
                            const direction = (isRoundTrip && index === 0) ? 'from' : 'to';

                            return (
                                <div key={index} className={cn('Card')}>
                                    <FlightInfoRoute
                                        extendable
                                        direction={isRoundTrip ? direction : null}
                                        isCollapsed={isCollapsed}
                                        leg={leg}
                                        toggleCollapse={toggleCollapse}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className={cn('Divider')} />
                    <div className={cn('Options')}>

                        <Text
                            align="center"
                            as="span"
                            color="primary"
                            typography="body-s"
                        >
                            <ServiceCostDetails
                                desktopTrigger="hover"
                                price={serviceDetails.price}
                                triggerElement={triggerElement}
                            />
                        </Text>

                        <Text
                            align="center"
                            as="span"
                            color="primary"
                            typography="body-l"
                        >
                            {formatCurrency(tariffTotal)}
                        </Text>
                        <FlightCardOptions
                            baggage={baggage}
                            isChangeable={is_changeable}
                            isRefundable={is_refundable}
                        />
                    </div>
                </div>
                <ServiceCardFooter {...props} />
            </ServiceCard>
        </div>
    );
};

FlightServiceCard.displayName = cn();
