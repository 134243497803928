type QueryParams = string | {
    [key: string]: undefined | null | string | boolean | number | (string | number)[];
};

const stringify = (value: undefined | null | string | boolean | number): string => encodeURIComponent(String(value));

export function formatParams(queryParams: QueryParams) {
    if (typeof queryParams === 'string') {
        return queryParams;
    }

    const validQueryEntries = Object.entries(queryParams)
        .filter(([key, value]) => !(
            key === 'undefined' ||
            (Array.isArray(value) && value.length === 0) ||
            value === undefined ||
            value === null ||
            value === false ||
            (typeof value === 'string' && value.trim() === '')
        ));

    if (validQueryEntries.length === 0) return '';

    return '?' + validQueryEntries.map(([key, value]) => {
        if (Array.isArray(value)) {
            return value.map(value => `${key}=${stringify(value)}`).join('&');
        }

        if (typeof value === 'boolean') {
            // TODO выпилить условие после тикета от бека BTRIP-7657
            // это условие дичь какая-то и быть его в этой функции не должно
            if (key === 'is_restricted_by_travel_policy') {
                return `${key}=${value ? 0 : 1}`;
            }

            return `${key}=1`;
        }

        return `${key}=${stringify(value)}`;
    }).filter(Boolean).join('&');
}
