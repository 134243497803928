import { withAddToListIcon } from '@yandex-int/hr-components/Icon/_glyph/AddToList';
import { withArrowShortDownIcon } from '@yandex-int/hr-components/Icon/_glyph/ArrowShortDown';
import { withArrowShortLeftIcon } from '@yandex-int/hr-components/Icon/_glyph/ArrowShortLeft';
import { withArrowShortRightIcon } from '@yandex-int/hr-components/Icon/_glyph/ArrowShortRight';
import { withArrowShortUpIcon } from '@yandex-int/hr-components/Icon/_glyph/ArrowShortUp';
import { withBaggageOffIcon } from '@yandex-int/hr-components/Icon/_glyph/BaggageOff';
import { withBaggageOnIcon } from '@yandex-int/hr-components/Icon/_glyph/BaggageOn';
import { withBanIcon } from '@yandex-int/hr-components/Icon/_glyph/Ban';
import { withBugIcon } from '@yandex-int/hr-components/Icon/_glyph/Bug';
import { withBulbIcon } from '@yandex-int/hr-components/Icon/_glyph/Bulb';
import { withCalendarIcon } from '@yandex-int/hr-components/Icon/_glyph/Calendar';
import { withCalendarGridIcon } from '@yandex-int/hr-components/Icon/_glyph/CalendarGrid';
import { withCategoryIcon } from '@yandex-int/hr-components/Icon/_glyph/Category';
import { withChatIcon } from '@yandex-int/hr-components/Icon/_glyph/Chat';
import { withCheckIcon } from '@yandex-int/hr-components/Icon/_glyph/Check';
import { withCheckCircleIcon } from '@yandex-int/hr-components/Icon/_glyph/CheckCircle';
import { withClockIcon } from '@yandex-int/hr-components/Icon/_glyph/Clock';
import { withCloseIcon } from '@yandex-int/hr-components/Icon/_glyph/Close';
import { withCloseCircleIcon } from '@yandex-int/hr-components/Icon/_glyph/CloseCircle';
import { withDayIcon } from '@yandex-int/hr-components/Icon/_glyph/Day';
import { withDislikeIcon } from '@yandex-int/hr-components/Icon/_glyph/Dislike';
import { withDownloadIcon } from '@yandex-int/hr-components/Icon/_glyph/Download';
import { withEditIcon } from '@yandex-int/hr-components/Icon/_glyph/Edit';
import { withErrorIcon } from '@yandex-int/hr-components/Icon/_glyph/Error';
import { withErrorTriangleIcon } from '@yandex-int/hr-components/Icon/_glyph/ErrorTriangle';
import { withEyeIcon } from '@yandex-int/hr-components/Icon/_glyph/Eye';
import { withEyeOffIcon } from '@yandex-int/hr-components/Icon/_glyph/EyeOff';
import { withFilterIcon } from '@yandex-int/hr-components/Icon/_glyph/Filter';
import { withFlagIcon } from '@yandex-int/hr-components/Icon/_glyph/Flag';
import { withGeolocationCircleIcon } from '@yandex-int/hr-components/Icon/_glyph/GeolocationCircle';
import { withGroupIcon } from '@yandex-int/hr-components/Icon/_glyph/Group';
import { withHandBaggageOffIcon } from '@yandex-int/hr-components/Icon/_glyph/HandBaggageOff';
import { withHandBaggageOnIcon } from '@yandex-int/hr-components/Icon/_glyph/HandBaggageOn';
import { withHelpIcon } from '@yandex-int/hr-components/Icon/_glyph/Help';
import { withInfoIcon } from '@yandex-int/hr-components/Icon/_glyph/Info';
import { withLikeIcon } from '@yandex-int/hr-components/Icon/_glyph/Like';
import { withLinkIcon } from '@yandex-int/hr-components/Icon/_glyph/Link';
import { withLocationIcon } from '@yandex-int/hr-components/Icon/_glyph/Location';
import { withMessageIcon } from '@yandex-int/hr-components/Icon/_glyph/Message';
import { withMinusIcon } from '@yandex-int/hr-components/Icon/_glyph/Minus';
import { withMoreVerticalIcon } from '@yandex-int/hr-components/Icon/_glyph/MoreVertical';
import { withNightIcon } from '@yandex-int/hr-components/Icon/_glyph/Night';
import { withPlusIcon } from '@yandex-int/hr-components/Icon/_glyph/Plus';
import { withProfileIcon } from '@yandex-int/hr-components/Icon/_glyph/Profile';
import { withRepeatIcon } from '@yandex-int/hr-components/Icon/_glyph/Repeat';
import { withSearchIcon } from '@yandex-int/hr-components/Icon/_glyph/Search';
import { withSortIcon } from '@yandex-int/hr-components/Icon/_glyph/Sort';
import { withStarCheckedIcon } from '@yandex-int/hr-components/Icon/_glyph/StarChecked';
import { withSwapIcon } from '@yandex-int/hr-components/Icon/_glyph/Swap';
import { withTrashIcon } from '@yandex-int/hr-components/Icon/_glyph/Trash';
import { withTravelAirConditionerIcon } from '@yandex-int/hr-components/Icon/_glyph/TravelAirConditioner';
import { withTravelCarIcon } from '@yandex-int/hr-components/Icon/_glyph/TravelCar';
import { withTravelConnectionIcon } from '@yandex-int/hr-components/Icon/_glyph/TravelConnection';
import { withTravelEntertaimentIcon } from '@yandex-int/hr-components/Icon/_glyph/TravelEntertaiment';
import { withTravelHotelIcon } from '@yandex-int/hr-components/Icon/_glyph/TravelHotel';
import { withTravelHotelFeaturesIcon } from '@yandex-int/hr-components/Icon/_glyph/TravelHotelFeatures';
import { withTravelPlaneIcon } from '@yandex-int/hr-components/Icon/_glyph/TravelPlane';
import { withTravelRestaurantIcon } from '@yandex-int/hr-components/Icon/_glyph/TravelRestaurant';
import { withTravelTrainIcon } from '@yandex-int/hr-components/Icon/_glyph/TravelTrain';
import { withTravelWiFiIcon } from '@yandex-int/hr-components/Icon/_glyph/TravelWiFi';
import { withUnlockIcon } from '@yandex-int/hr-components/Icon/_glyph/Unlock';

import { getComposedIcon } from './getComposedIcon';

export const IconBug = getComposedIcon(withBugIcon, 'bug');
export const IconPlus = getComposedIcon(withPlusIcon, 'plus');
export const IconMinus = getComposedIcon(withMinusIcon, 'minus');
export const IconTrash = getComposedIcon(withTrashIcon, 'trash');
export const IconHelp = getComposedIcon(withHelpIcon, 'help');
export const IconGeolocationCircle = getComposedIcon(withGeolocationCircleIcon, 'geolocationCircle');
export const IconRepeat = getComposedIcon(withRepeatIcon, 'repeat');
export const IconEdit = getComposedIcon(withEditIcon, 'edit');
export const IconLink = getComposedIcon(withLinkIcon, 'link');
export const IconArrowShortDown = getComposedIcon(withArrowShortDownIcon, 'arrowShortDown');
export const IconArrowShortUp = getComposedIcon(withArrowShortUpIcon, 'arrowShortUp');
export const IconNight = getComposedIcon(withNightIcon, 'night');
export const IconArrowShortLeft = getComposedIcon(withArrowShortLeftIcon, 'arrowShortLeft');
export const IconStarChecked = getComposedIcon(withStarCheckedIcon, 'starChecked');
export const IconBulb = getComposedIcon(withBulbIcon, 'bulb');
export const IconMessage = getComposedIcon(withMessageIcon, 'message');
export const IconMoreVertical = getComposedIcon(withMoreVerticalIcon, 'moreVertical');
export const IconClose = getComposedIcon(withCloseIcon, 'close');
export const IconFilter = getComposedIcon(withFilterIcon, 'filter');
export const IconLocation = getComposedIcon(withLocationIcon, 'location');
export const IconClock = getComposedIcon(withClockIcon, 'clock');
export const IconCheckCircle = getComposedIcon(withCheckCircleIcon, 'checkCircle');
export const IconTravelAirConditioner = getComposedIcon(withTravelAirConditionerIcon, 'travelAirConditioner');
export const IconTravelCar = getComposedIcon(withTravelCarIcon, 'travelCar');
export const IconTravelConnection = getComposedIcon(withTravelConnectionIcon, 'travelConnection');
export const IconTravelEntertaiment = getComposedIcon(withTravelEntertaimentIcon, 'travelEntertaiment');
export const IconTravelHotel = getComposedIcon(withTravelHotelIcon, 'travelHotel');
export const IconTravelHotelFeatures = getComposedIcon(withTravelHotelFeaturesIcon, 'travelHotelFeatures');
export const IconTravelPlane = getComposedIcon(withTravelPlaneIcon, 'travelPlane');
export const IconTravelRestaurant = getComposedIcon(withTravelRestaurantIcon, 'travelRestaurant');
export const IconTravelTrain = getComposedIcon(withTravelTrainIcon, 'travelTrain');
export const IconTravelWiFi = getComposedIcon(withTravelWiFiIcon, 'travelWiFi');
export const IconErrorTriangle = getComposedIcon(withErrorTriangleIcon, 'errorTriangle');
export const IconError = getComposedIcon(withErrorIcon, 'error');
export const IconEye = getComposedIcon(withEyeIcon, 'eye');
export const IconEyeOff = getComposedIcon(withEyeOffIcon, 'eyeOff');
export const IconCheck = getComposedIcon(withCheckIcon, 'check');
export const IconLike = getComposedIcon(withLikeIcon, 'like');
export const IconDislike = getComposedIcon(withDislikeIcon, 'dislike');
export const IconFlag = getComposedIcon(withFlagIcon, 'flag');
export const IconChat = getComposedIcon(withChatIcon, 'chat');
export const IconBan = getComposedIcon(withBanIcon, 'ban');
export const IconUnlock = getComposedIcon(withUnlockIcon, 'unlock');
export const IconCategory = getComposedIcon(withCategoryIcon, 'category');
export const IconProfile = getComposedIcon(withProfileIcon, 'profile');
export const IconCloseCircle = getComposedIcon(withCloseCircleIcon, 'closeCircle');
export const IconBaggageOn = getComposedIcon(withBaggageOnIcon, 'baggageOn');
export const IconBaggageOff = getComposedIcon(withBaggageOffIcon, 'baggageOff');
export const IconHandBaggageOff = getComposedIcon(withHandBaggageOffIcon, 'handBaggageOff');
export const IconHandBaggageOn = getComposedIcon(withHandBaggageOnIcon, 'handBaggageOn');
export const IconArrowShortRight = getComposedIcon(withArrowShortRightIcon, 'arrowShortRight');
export const IconDownload = getComposedIcon(withDownloadIcon, 'download');
export const IconDay = getComposedIcon(withDayIcon, 'day');
export const IconInfo = getComposedIcon(withInfoIcon, 'info');
export const IconSwap = getComposedIcon(withSwapIcon, 'swap');
export const IconSort = getComposedIcon(withSortIcon, 'sort');
export const IconGroup = getComposedIcon(withGroupIcon, 'group');
export const IconAddToList = getComposedIcon(withAddToListIcon, 'addToList');
export const IconSearch = getComposedIcon(withSearchIcon, 'search');
export const IconCalendarGrid = getComposedIcon(withCalendarGridIcon, 'calendarGrid');
export const IconCalendar = getComposedIcon(withCalendarIcon, 'calendar');
