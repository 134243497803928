import { useCallback } from 'react';
import { useEvent } from 'effector-react/scope';

import {
    createBannerNotification,
    deleteAllBannerNotifications,
    deleteBannerNotification,
} from 'shared/models/banner';
import {
    AddBanner,
    DeleteAllBanners,
    DeleteBanner,
} from 'types/Notifications';

type UseBanners = () => {
    addBanner: AddBanner;
    deleteBanner: DeleteBanner;
    deleteAllBanners: DeleteAllBanners;
};

let notificationId = 0;

export const useBanners: UseBanners = () => {
    const addBannerNotification = useEvent(createBannerNotification);
    const removeBannerNotification = useEvent(deleteBannerNotification);
    const removeAllBannerNotifications = useEvent(deleteAllBannerNotifications);

    const addBanner = useCallback<AddBanner>(({ type, description, title, onClose }) => {
        const id = notificationId++;
        const payload = { id, type, description, title, onClose };

        addBannerNotification(payload);

        return id;
    }, [addBannerNotification]);

    const deleteBanner = useCallback<DeleteBanner>(id => {
        removeBannerNotification(id);
    }, [removeBannerNotification]);

    const deleteAllBanners = useCallback(() => {
        removeAllBannerNotifications();
    }, [removeAllBannerNotifications]);

    return {
        addBanner,
        deleteBanner,
        deleteAllBanners,
    };
};
