import { toast, ToastOptions } from 'react-toastify';

import { ToastContent } from 'shared/ui/ToastContent';

export const addToast = (payload: {
    title: string;
    message?: string;
    options?: ToastOptions;
}) => {
    const {
        title,
        message,
        options,
    } = payload;

    toast(<ToastContent message={message} title={title} />, options);
};
