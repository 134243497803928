export const ru = {
    trip_id_label: 'Командировка',
    trip_id_placeholder: 'Номер командировки',
    general_service_id_label: 'Номер услуги',
    general_service_id_placeholder: 'Номер услуги',
    person_label: 'Сотрудник',
    execution_date_label: 'Дата оформления',
    service_type_label: 'Тип услуги',
    service_type_value_avia: 'Авиабилет',
    service_type_value_hotel: 'Отель',
    service_type_value_rail: 'ЖД билет',
    service_type_value_transfer: 'Трансфер',
    service_type_value_vip_lounge: 'VIP-зал',
    service_type_value_fast_track: 'Fast track',
    service_type_value_aeroexpress: 'Аэроэкспресс',
    service_type_value_insurance: 'Страховка',
    service_type_value_additional: 'Дополнительная услуга',
    invoice_date_label: 'Дата в реестре',
    type_label: 'Тип транзакции',
    status_label: 'Статус транзакции',
    type_value_refund: 'Возврат',
    type_value_purchase: 'Покупка',
    type_value_exchange: 'Изменение',
    status_value_paid: 'Оплачено',
    status_value_hold: 'Заморожено',
    status_value_completed: '',
    status_value_verified: '',
    price_label: 'Цена услуги',
    price_placeholder: '000 000 ₽',
    fee_label: 'Сервисный сбор',
    fee_placeholder: '000 000 ₽',
    is_penalty_label: 'Отметить как штраф',
    group_heading_service: 'Услуга',
    group_heading_transaction: 'Транзакция',
    action_alter: 'Изменить',
    action_create: 'Создать',
    add_cost_center: 'Добавить центр затрат',
    cost_center: 'Центр затрат',
    cost_center_not_specified: 'Не выбран',
};
