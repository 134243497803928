import { FC, useCallback, useEffect, useState } from 'react';
import { useToggle } from 'react-use';

import { HotelImage } from 'components/HotelImage';
import { getFormattedImages } from 'components/HotelImages/utils/getFormattedImages';
import { getIconProvider, IconArrowShortLeft, IconArrowShortRight } from 'components/Icon';
import { ImageViewer } from 'components/ImageViewer';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';

import { cn } from '../HotelDetails.cn';

import { ImagesGaleryProps } from './ImagesGalery.types';

import './ImagesGalery.css';

const rigthIconProvider = getIconProvider(IconArrowShortRight);
const leftIconProvider = getIconProvider(IconArrowShortLeft);

export const ImagesGalery: FC<ImagesGaleryProps> = props => {
    const {
        images,
        refContainer,
    } = props;

    const [showGalleryModal, toggleShowGalleryModal] = useToggle(false);
    const { isDesktop } = usePlatform();

    const [showArrows, setShowArrows] = useState(false);
    const [leftArrowHidden, setLeftArrowHidden] = useState(true);
    const [rightArrowHidden, setRightArrowHidden] = useState(false);

    const onNextClick = useCallback(() => {
        refContainer?.current?.scrollTo({
            behavior: 'smooth',
            left: refContainer?.current.scrollLeft + refContainer?.current.clientWidth,
        });
    }, [refContainer]);

    const onPrevClick = useCallback(() => {
        refContainer?.current?.scrollTo({
            behavior: 'smooth',
            left: refContainer?.current.scrollLeft - refContainer?.current.clientWidth,
        });
    }, [refContainer]);

    const handleScroll = useCallback(() => {
        const galleryContainer = refContainer?.current;

        if (galleryContainer) {
            setRightArrowHidden(
                galleryContainer.scrollWidth - galleryContainer.scrollLeft === galleryContainer.clientWidth,
            );
            setLeftArrowHidden(galleryContainer.scrollLeft === 0);
        }
    }, [refContainer]);

    useEffect(() => {
        const galleryContainer = refContainer?.current;

        if (galleryContainer) {
            const hasScrollContent = (
                galleryContainer.scrollLeft + galleryContainer.clientWidth < galleryContainer.scrollWidth
            );

            if (hasScrollContent) {
                setShowArrows(true);
            } else {
                setShowArrows(false);
            }

            galleryContainer.addEventListener('scroll', handleScroll);

            return () => {
                galleryContainer.removeEventListener('scroll', handleScroll);
            };
        }
    }, [handleScroll, refContainer]);

    return (
        <div ref={refContainer} className={cn('ImagesGalery')}>
            {isDesktop && showArrows && (
                <>
                    <Button
                        className={cn('PrevBtn')}
                        disabled={leftArrowHidden}
                        icon={leftIconProvider}
                        pin="circle-circle"
                        size="l"
                        view="media"
                        onClick={onPrevClick}
                    />
                    <Button
                        className={cn('NextBtn')}
                        disabled={rightArrowHidden}
                        icon={rigthIconProvider}
                        pin="circle-circle"
                        size="l"
                        view="media"
                        onClick={onNextClick}
                    />
                </>
            )}
            {images.length > 0 && (
                <>
                    {getFormattedImages(images).map(item => item &&
                        <div onClick={toggleShowGalleryModal}>
                            <HotelImage
                                key={item.preview_url}
                                className={cn('Image')}
                                image={item.preview_url}
                            />
                        </div>,
                    )}
                </>
            )}
            <ImageViewer
                closeModal={toggleShowGalleryModal}
                images={images}
                visible={showGalleryModal}
            />
        </div>
    );
};
