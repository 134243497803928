import { withBemMod } from '@bem-react/core';

import { Text } from 'shared/ui/Text';

import { cn } from '../Bubble.cn';
import { BubbleParticipantPerson, BubbleProps } from '../Bubble.interface';
import {
    getBubbleThumbnail,
    getTypo,
} from '../Bubble.utils';

import './Bubble_type_external.css';

export type WithTypeExternal = {
    type?: 'external';
    bubble?: BubbleParticipantPerson;
};

export const withTypeExternal = withBemMod<WithTypeExternal, BubbleProps>(
    cn(),
    { type: 'external' },
    Bubble => (props: WithTypeExternal & BubbleProps) => {
        const { name } = props.bubble;
        const { size } = props;

        const thumbnail = getBubbleThumbnail(name);

        return (
            <Bubble {...props}>
                <Text typography={getTypo(size)}>
                    {thumbnail}
                </Text>
            </Bubble>
        );
    },
);
