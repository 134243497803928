import { DocumentType } from 'services/SwaggerApi';
import { getTypedI18nLang } from 'utils/i18n';

type Locale = { [key in DocumentType]: string };

const locales: { en: Locale; ru: Locale } = {
    en: {
        passport: 'Passport',
        external_passport: 'International passport',
        visa: 'Visa',
        bonus_card: 'Bonus card',
        other: 'Other document',
        birth_certificate: 'Birth certificate',
        seaman_passport: 'Seaman passport',
        military_passport: 'Military passport',
    },
    ru: {
        passport: 'Паспорт',
        external_passport: 'Заграничный паспорт',
        visa: 'Виза',
        bonus_card: 'Бонусная карта',
        other: 'Другой документ',
        birth_certificate: 'Свидетельство о рождении',
        seaman_passport: 'Паспорт моряка',
        military_passport: 'Военный билет',
    },
} as const;

export const i18nDocumentType = (key: DocumentType) => {
    const lang = getTypedI18nLang();

    const dictionary = locales[lang];

    return dictionary[key];
};
