export const ru = {
    good_place: 'Хорошее место',
    show_all: 'Показать полностью',
    wi_fi: 'Wi-Fi',
    gym: 'Тренажерный зал',
    pool: 'Бассейн',
    laundry: 'Прачечная',
    safe: 'Сейф',
    room: 'Room сервис',
    has_restaurant: 'Ресторан',
    cafe: 'Кафе',
    has_bar: 'Бар',
    services_and_facilities: 'Услуги и удобства',
    popular_services: 'Популярные услуги',
};
