import { i18nAviaClass } from 'i18n/i18nAviaClass';
import { ClassAvia } from 'services/SwaggerApi';

const classAvia: ClassAvia[] = ['Econom', 'Comfort', 'Business', 'First'];

export const getFlightClassOptions = () => classAvia.map(value => {
    const lowerCased = value.toLowerCase() as Lowercase<ClassAvia>;

    return {
        value,
        label: i18nAviaClass(`avia_class_${lowerCased}`),
    };
});
