import { i18nDocumentType } from 'i18n/TT/i18nDocumentType';
import { TravellerDocument } from 'services/SwaggerApi';

export const getDocumentItemTextParams = (document: TravellerDocument) => {
    const {
        series,
        document_type,
        number,
    } = document;

    const label = [i18nDocumentType(document_type)];

    return {
        caption: [series, number].join(''),
        label,
    };
};
