import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Spin } from '@yandex-lego/components/Spin/bundle';

import { getIconProvider, IconClose } from 'components/Icon';
import { PersonListItem } from 'components/PersonListItem';
import { RoutePersonTrip } from 'routes/RoutePersonTrip';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import { cn } from '../TrainDetails.cn';
import { i18n } from '../TrainDetails.i18n';

import { PassengersProps } from './Passengers.types';

const iconCloseProvider = getIconProvider(IconClose, { size: 'm' });

export const Passengers: FC<PassengersProps> = props => {
    const {
        addedServices,
        className,
        onRemoveServiceClick,
        personId,
        tripId,
    } = props;

    const history = useHistory();
    const goToPersonTrip = useCallback(() => {
        const personTripUrl = RoutePersonTrip.getPath({ tripId, personId }, {});

        history.push(personTripUrl);
    }, [history, personId, tripId]);

    const canGoToPersonTrip = addedServices.length > 0 &&
       addedServices.filter(service => service.isLoading).length === 0;

    return (
        <div className={cn('Passengers', [className])}>
            {addedServices.length > 0 && (
                <Text color="secondary" typography="body-s">
                    {addedServices?.map(service => (
                        <PersonListItem
                            key={`${service.carriageNumber}${service.seatNumber}`}
                            expanded
                            transparent
                            className={cn('Passenger')}
                            external={service.isExternal}
                            leadingCaption={i18n('car_and_seat', {
                                carNumber: Number(service.carriageNumber),
                                seatNumber: service.seatNumber,
                            })}
                            login={service.login}
                            name={service.personName || ''}
                            trailing={service.isLoading ?
                                <Button
                                    disabled
                                    icon={getIconProvider(Spin, {
                                        progress: true,
                                        size: 'xxs',
                                        view: 'default',
                                        className: cn('AddServiceSpinner'),
                                    })}
                                    size="m"
                                    view="outline"
                                /> :
                                <Button
                                    icon={iconCloseProvider}
                                    size="m"
                                    view="outline"
                                    onClick={onRemoveServiceClick(service.serviceId!)}
                                />
                            }
                        />
                    ))}
                </Text>
            )}
            <Button
                className={cn('GoToTripButton')}
                disabled={!canGoToPersonTrip}
                size="l"
                view="brand"
                width="max"
                onClick={goToPersonTrip}
            >
                {i18n('go_to_trip')}
            </Button>
        </div>
    );
};
