import { FC } from 'react';

import { Card } from 'components/Card';
import { ListItem } from 'shared/ui/ListItem';

import { cn } from '../TabbedFormsLayout.cn';

import { TabbedMenuProps } from './TabbedMenu.types';

import './TabbedMenu.css';

export const TabbedMenu: FC<TabbedMenuProps> = props => {
    const {
        tabs,
        currentTab,
        changeTab,
    } = props;

    const handleTabClick = (id: string) => {
        const sectionElement = document.querySelector<HTMLElement>(`#${id}`);

        if (sectionElement) {
            changeTab(id);
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Card className={cn('TabbedMenu')}>
            <div className={cn('MenuList')}>
                {tabs.map(tab => (
                    <ListItem
                        key={tab.id}
                        className={cn('MenuItem', { active: currentTab === tab.id })}
                        leadingTextParams={{ label: tab.title, size: 's' }}
                        onClick={() => handleTabClick(tab.id)}
                    />
                ))}
            </div>
        </Card>
    );
};

TabbedMenu.displayName = cn();
