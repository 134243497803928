export const en = {
    details: 'Сompany details',
    profile: 'Profile',
    finance: 'Finance',
    main: 'Home',
    label_more: 'More',
    label_services: 'Services',
    me_on_staff: 'Me on Staff',
    new_trip: 'Create travel request',
    staff: 'Staff',
    trips: 'Travel requests',
    companies: 'Companies',
};
