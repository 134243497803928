import { FC, useCallback } from 'react';
import { Button } from '@yandex-lego/components/Button/desktop/bundle';

import { Card } from 'components/Card';
import { getIconProvider, IconRepeat } from 'components/Icon';
import { usePersonTripPatch } from 'hooks/usePersonTripPatch';
import { usePlatform } from 'hooks/usePlatform';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { cn } from '../TripRouteSegment.cn';
import { EmptyHotelSegmentProps } from '../TripRouteSegment.types';
import { i18n } from '../TripRouteSegment.yt.i18n';

const iconProviderRepeat = getIconProvider(IconRepeat);

export const SegmentNoServicesHotel: FC<EmptyHotelSegmentProps> = props => {
    const { trip_id, person_id, segment, showSearchButtons } = props;
    const [patch, patchState] = usePersonTripPatch();

    const onClickHandle = useCallback(() => {
        patch(trip_id, person_id, { route: segment.getRouteWithToggledHotel() });
    }, [patch, person_id, segment, trip_id]);
    const { isMobile } = usePlatform();
    const size = isMobile ? 'm' : 's';

    return (
        <Card className={cn('NoServices')}>
            <div className={cn('EmptySegmentHeader')}>
                <Text typography="title-s">
                    {i18n('no_hotel')}
                </Text>
                <Text color="secondary" typography="body-s">
                    {i18n('no_hotel_tip')}
                </Text>
            </div>
            {showSearchButtons &&
                <div className={cn('EmptySegmentActions')}>
                    <Button
                        className={cn('ActionButton')}
                        iconLeft={iconProviderRepeat}
                        progress={isLoading(patchState)}
                        size={size}
                        view="default"
                        onClick={onClickHandle}
                    >
                        {i18n('need_hotel')}
                    </Button>
                </div>
            }
        </Card>);
};
