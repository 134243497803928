import { useEffect, useState } from 'react';
import { Platform } from '@yandex-int/hr-components/utils';

const MIN_WIDTH_DESKTOP = 768;

function calcPlatform(): Platform {
    return window.innerWidth < MIN_WIDTH_DESKTOP ? Platform.MOBILE : Platform.DESKTOP;
}

type PlatformResults = {
    isMobile: boolean;
    isDesktop: boolean;
    platform: Platform;
};

export const usePlatform = (): PlatformResults => {
    const [platform, setPlatform] = useState<Platform>(calcPlatform());

    useEffect(() => {
        function updatePlatform() {
            const newPlatform = calcPlatform();

            if (newPlatform !== platform) {
                setPlatform(newPlatform);
            }
        }

        window.addEventListener('resize', updatePlatform);

        return () => {
            window.removeEventListener('resize', updatePlatform);
        };
    });

    const isMobile = platform === Platform.MOBILE;
    const isDesktop = platform === Platform.DESKTOP;

    return { isMobile, isDesktop, platform };
};
