import { FC, useCallback } from 'react';
import { useSwiper } from 'swiper/react';

import { usePlatform } from 'hooks/usePlatform';
import { i18nAction } from 'i18n/i18nAction';
import { openFile } from 'platform/bridge-methods';
import { Button } from 'shared/ui/Button';
import { ModalLayout } from 'shared/ui/ModalLayout';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';

import { ModalSlides } from '../CompanyInvoice.types';

import { cn } from './DownloadInvoice.cn';
import { i18n } from './DownloadInvoice.i18n';
import { DownloadInvoiceProps } from './DownloadInvoice.types';

import './DownloadInvoice.css';

export const DownloadInvoice: FC<DownloadInvoiceProps> = props => {
    const {
        className,
        onClose,
        url,
        amount,
    } = props;

    const swiper = useSwiper();
    const { isDesktop, platform } = usePlatform();

    const backHandle = useCallback(() => {
        swiper.slideTo(ModalSlides.FORM_SLIDE);
    }, [swiper]);

    const downloadFileHandle = useCallback(() => {
        openFile(url);
        onClose();
    }, [url, onClose]);

    const valueFormatted = formatCurrency(amount, { fractionDigits: 2 });

    return (
        <ModalLayout
            actions={[
                <Button
                    key="cancel"
                    size={isDesktop ? 'm' : 'l'}
                    view="pseudo"
                    onClick={backHandle}
                >
                    {i18nAction('back')}
                </Button>,
                <Button
                    key="next"
                    size={isDesktop ? 'm' : 'l'}
                    view="brand"
                    onClick={downloadFileHandle}
                >
                    {i18n('download')}
                </Button>,
            ]}
            className={cn('DownloadSlide')}
            platform={platform}
            title={i18n('balance_invoice')}
            onClose={onClose}
        >
            <div className={cn(null, [className])}>
                <Text typography="body-s">
                    {i18n('can_download')}
                </Text>
                <div className={cn('Amount')}>
                    <Text as="div" color="secondary" typography="body-s">
                        {i18n('amount')}
                    </Text>
                    <Text as="div" typography="title-m">
                        {valueFormatted}
                    </Text>
                </div>
            </div>
        </ModalLayout>
    );
};
