import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_body-s.css';

export type WithTypographyBodySProps = {
    typography?: 'body-s';
};

export const withTypographyBodyS = withBemMod<WithTypographyBodySProps>(
    cn(),
    { typography: 'body-s' },
);
