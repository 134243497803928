import * as yup from 'yup';

import { CitySuggestItem } from 'components/Suggest/_type/Suggest_type_cities';
import { ClassAvia } from 'services/SwaggerApi';

import { SearchType } from './SearchMain.types';

export const schema = yup.object({
    type: yup.mixed<SearchType>().required(),
    city_from: yup.mixed<CitySuggestItem>().when(['type'], {
        is: (searchType: SearchType) => searchType !== 'Hotel',
        then: yup.mixed<CitySuggestItem>().nullable().required(),
    }),
    city_to: yup.mixed<CitySuggestItem>().nullable().required(),
    dateRange: yup.mixed(),
    class_avia: yup.mixed<ClassAvia>().when(['type'], {
        is: (searchType: SearchType) => searchType === 'Avia',
        then: yup.mixed<ClassAvia>().required(),
    }),
    adult: yup.number().required(),
});

export type SearchMainSchema = yup.InferType<typeof schema>;
