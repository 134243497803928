import { FC, useMemo, useRef } from 'react';
import { Tag } from '@yandex-int/hr-components/Tag2/bundle';

import { Participants } from 'components/Participants';
import { TabsPage } from 'components/TabsPage';
import { TripTickets } from 'components/TripTickets';
import { UsersGroupTrip } from 'components/UsersGroupTrip';
import { config } from 'config';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useMeta } from 'hooks/useMeta';
import { usePlatform } from 'hooks/usePlatform';
import { i18nPrepositions } from 'i18n/i18nPrepositions';
import { RoutePersonTrip, TABS } from 'routes/RoutePersonTrip';
import { Container } from 'shared/ui/Container';
import { Text } from 'shared/ui/Text';
import { getTripCompactDates } from 'utils/getTripCompactDates';
import { getTripTitle } from 'utils/getTripTitle';
import { getParticipants, personToParticipant } from 'utils/personToParticipant';

import { Controls } from './Controls/Controls';
import { cn } from './TripInfoPersonHeader.cn';
import { i18n } from './TripInfoPersonHeader.i18n';
import { TripInfoPersonHeaderProps } from './TripInfoPersonHeader.types';

import './TripInfoPersonHeader.css';

const { isB2B } = config;

export const TripInfoPersonHeader: FC<TripInfoPersonHeaderProps> = props => {
    const {
        trip,
        personId,
        personTrip,
        trip: { trip_id },
        activeTab,
    } = props;

    const meta = useMeta();
    const { isDesktop, isMobile } = usePlatform();

    const { copyToClipboard } = useCopyToClipboard();

    const scopeRef = useRef<HTMLDivElement>(null);

    const tripAuthor = [personToParticipant(personTrip)];

    const participants = getParticipants(trip.person_trips);

    const purposes = trip.purposes.map(purpose => purpose.name);

    const tripTitle = getTripTitle(trip);

    const routeDates = getTripCompactDates(personTrip);

    const isGroupTrip = participants.length > 1;
    const showAuthor = isDesktop || (isMobile && !isGroupTrip);

    const hasCancelledServices = personTrip.services.filter(service => service.status === 'cancelled').length > 0;

    const tabs = useMemo(() => TABS.map(tab => ({
        id: tab,
        text: i18n(tab),
        disabled: (tab === 'cancelled' && !hasCancelledServices),
        href: RoutePersonTrip.getPath({ personId, tripId: trip_id }, { tab }),
    })), [hasCancelledServices, personId, trip_id]);

    return (
        <div ref={scopeRef} className={cn()}>
            <Container className={cn('Content')}>
                <div className={cn('Details')}>
                    <div className={cn('TitleAndNumber')}>
                        <Text
                            as="div"
                            className={cn('Cities')}
                            data-testid="trip-cities"
                            typography="display-s"
                            weight="medium"
                        >
                            {tripTitle}
                        </Text>
                        {!isB2B && (
                            <TripTickets
                                className={cn('Tickets')}
                                personTrip={personTrip}
                                size="m"
                                trip={trip}
                            />
                        )}
                        <Tag
                            className={cn('TripNumber')}
                            color="default"
                            maxWidth="full"
                            size="m"
                            text={i18n('trip_number', { trip_id })}
                            onClick={() => copyToClipboard(`${trip_id}`, i18n('copied'))}
                        />
                    </div>
                    <Text as="span" className={cn('DatesAndPurposes')} typography="body-m">
                        <Text
                            className={cn('Dates')}
                            data-testid="trip-dates"
                        >
                            {routeDates}
                        </Text>
                        <Text
                            className={cn('Purposes')}
                            color="secondary"
                            data-testid="trip-purposes"
                        >
                            <Text> · </Text>
                            {purposes.join(` ${i18nPrepositions('and')} `)}
                        </Text>
                    </Text>
                </div>
                <div className={cn('Footer')}>
                    <div className={cn('Participants')}>
                        {isGroupTrip && (
                            <UsersGroupTrip className={cn('GroupParticipants')} size="l" trip={trip} />
                        )}
                        {showAuthor && (
                            <Participants
                                className={cn('Author')}
                                participants={tripAuthor}
                                size="l"
                            />
                        )}
                    </div>
                    <Controls
                        personId={personId}
                        personTrip={personTrip}
                        tripId={trip_id}
                        user={meta.user}
                    />
                </div>
                <TabsPage
                    activeTab={activeTab}
                    tabs={tabs}
                />
            </Container>
        </div>
    );
};

TripInfoPersonHeader.displayName = cn();
