import { FC } from 'react';

import { HeaderLite } from 'components/HeaderLite';
import { usePlatform } from 'hooks/usePlatform';
import { Container } from 'shared/ui/Container';
import { ErrorState } from 'shared/ui/ErrorState';
import { Game } from 'shared/ui/Game';

import { cn } from '../Root.cn';
import { i18n } from '../Root.i18n';

import { ErrorProps } from './Error.types';

import './Error.css';

export const Error: FC<ErrorProps> = props => {
    const {
        status,
        className,
    } = props;

    const { isMobile } = usePlatform();

    return (
        <div
            className={cn('Error', [className])}
            data-testid="root-error"
        >
            <HeaderLite showAvatar={false} />
            <Container wrapperClassName={cn('GameContainer')}>
                {
                    isMobile ? (
                        <ErrorState
                            description={i18n('error_description')}
                            imageType="not_found"
                            title={i18n('error_title')}
                        />
                    ) : (
                        <Game
                            errorStateProps={{
                                title: i18n('error_title'),
                                description: i18n('error_description'),
                            }}
                            gameProps={{ errorCode: status ?? undefined }}
                        />
                    )
                }
            </Container>
        </div>
    );
};
