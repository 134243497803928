export const en = {
    title: 'Email confirmation',
    email_confirmed: 'Email confirmed',
    wait_for_link: 'It remains to wait until the coordinator allows you access to the service. We will send an email to {email} when access is open',
    follow_the_link: 'To confirm your email, follow the link we sent to the {email}.',
    go_back: 'If you entered the wrong email you can go back and enter new one.',
    no_email: 'Email not in inbox or spam folder?',
    send_again: "Let's send it again",
    send_success: 'Email was sent',
    send_error: 'Failed to send email. Try again later.',
};
