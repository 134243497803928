import { FC } from 'react';
import { Select } from '@yandex-int/hr-components/Select/desktop/bundle';

import { Card } from 'components/Card';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';

import { useSelectOptions } from './AccountExpenses.hooks/useSelectOptions';
import { cn } from './AccountExpenses.cn';
import { i18n } from './AccountExpenses.i18n';
import { AccountExpensesProps } from './AccountExpenses.types';

import './AccountExpenses.css';

export const AccountExpenses: FC<AccountExpensesProps> = props => {
    const {
        onPeriodChange,
        data,
        dateFrom,
        dateTo,
    } = props;

    const amountFormatted = formatCurrency(data.amount, { fractionDigits: 2 });
    const {
        value: selectValue,
        options: selectOptions,
    } = useSelectOptions(dateFrom, dateTo);

    return (
        <Card
            className={cn('Card')}
            description={i18n('preliminary_data')}
            size="s"
            title={i18n('expenses_for_period')}
        >
            <div className={cn('Content')}>
                <Text
                    as="p"
                    className={cn('Amount')}
                    typography="title-l"
                >
                    {amountFormatted}
                </Text>
                <Select
                    className={cn('PeriodSelect')}
                    iconProps={{ glyph: 'arrowShortDown' }}
                    options={selectOptions}
                    size="m"
                    value={selectValue}
                    view="outline"
                    onChange={onPeriodChange}
                />
            </div>
        </Card>
    );
};

AccountExpenses.displayName = cn();
