import { FC, useEffect, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';

import { createGenderHints } from './TrainCarriage.utils/addGenderHints';
import { getPlaceNumbers } from './TrainCarriage.utils/getPlaceNumbers';
import { cn } from './TrainCarriage.cn';
import { i18n } from './TrainCarriage.i18n';
import { TrainCarriageProps } from './TrainCarriage.types';

import './TrainCarriage.css';

const cnTrainCarriagePlace = cn('Place');
const cnTrainCarriagePlace_available = cn('Place_available');
const cnTrainCarriagePlace_clickable = cn('Place_clickable');
const cnTrainCarriagePlace_selected = cn('Place_selected');
const cnTrainCarriagePlace_currently_selected = cn('Place_currently_selected');

export const TrainCarriage: FC<TrainCarriageProps> = props => {
    const {
        availablePlaces,
        coach,
        schema,
        chosenPlaces,
        currentSelectedSeat,
        onChange,
        canAddServices,
    } = props;

    const schemaRef = useRef<HTMLDivElement>(null);
    const placesSet = useMemo(() => new Set(availablePlaces), [availablePlaces]);
    const [visible, setVisible] = useToggle(false);

    useEffect(() => {
        if (!schemaRef.current) {
            return;
        }

        const placesContainer = schemaRef.current.querySelector('#places') as SVGGElement;

        const getPlaceNode = (e: MouseEvent & { target: Node }): Node => {
            let target = e.target;

            while (
                target.parentNode &&
                target.parentNode !== placesContainer
            ) {
                target = target.parentNode;
            }

            return target;
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const onPlaceClick = (e: any): void => {
            const placeNode = getPlaceNode(e);
            const numbers = getPlaceNumbers(placeNode as SVGGElement);

            if (numbers.length === 0) {
                return;
            }

            const selectedPlace = numbers[0];

            const isPlaceAvailable = placesSet.has(selectedPlace);
            const isPlaceChosen = chosenPlaces.includes(selectedPlace);

            if (!isPlaceAvailable || isPlaceChosen) {
                return;
            }

            canAddServices && onChange && onChange(selectedPlace, e);
        };

        const nodes: SVGGElement[] = Array.from(
            schemaRef.current.querySelectorAll('#places > g'),
        );

        for (const node of nodes) {
            const numbers = getPlaceNumbers(node);
            const placeAvailable = numbers.every(n => placesSet.has(n));
            const placeChosen = chosenPlaces?.length && numbers.every(n => chosenPlaces.includes(n));

            node.classList.toggle(cnTrainCarriagePlace, true);
            node.classList.toggle(cnTrainCarriagePlace_available, placeAvailable);
            node.classList.toggle(
                cnTrainCarriagePlace_clickable,
                Boolean(canAddServices && onChange && placeAvailable),
            );
            node.classList.toggle(cnTrainCarriagePlace_selected, Boolean(placeChosen));

            node.classList.toggle(cnTrainCarriagePlace_currently_selected,
                typeof currentSelectedSeat === 'number' && numbers.includes(currentSelectedSeat),
            );

            node.setAttribute('role', 'button');

            const trigger = node.querySelector<HTMLElement>('[id^="trigger"]');

            if (trigger) {
                trigger.classList.toggle('Trigger', true);
            }
        }

        if (placesContainer) {
            placesContainer.addEventListener('click', onPlaceClick);
        }

        // без этого хака, мы увидим гендеры не на том месте вначале
        setTimeout(() => {
            createGenderHints(coach, schemaRef.current, schema.places, nodes);
        }, 0);

        // до этого момента не показываем саму схему потому-что меняем её размеры
        setVisible(true);

        return () => {
            if (placesContainer) {
                placesContainer.removeEventListener('click', onPlaceClick);
            }
        };
    }, [coach, onChange, placesSet, schema, setVisible, chosenPlaces, currentSelectedSeat, canAddServices]);

    return (
        <div className={cn('SchemaContainer')} data-testid="train-carriage-schema-container">
            <div
                dangerouslySetInnerHTML={ { __html: schema.svg } }
                ref={schemaRef}
                className={cn('Schema', { visible })}
            />
            {coach.directionConfirmed &&
                <div className={cn('TrainDirection')}>
                    {i18n('train_direction') + ' →'}
                </div>
            }
        </div>
    );
};
