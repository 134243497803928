import { ComponentType, FC } from 'react';

import { PageParams } from 'types/Page';

import { cn } from './Page.cn';
import { PageProps } from './Page.types';

import './Page.css';

/**
 *
 */
export const Page: FC<PageProps> = props => {
    const {
        className,
        children,
        ...rest
    } = props;

    return (
        <div className={cn(null, [className])} {...rest}>
            {children}
        </div>
    );
};

export type Page<R extends PageParams = PageParams> = ComponentType<R>;

Page.displayName = cn();
