import { ExtraActions } from 'components/ServiceCardFooter/ServiceCardFooter.types';
import { i18n } from 'components/TrainServiceCard/TrainServiceCard.i18n';
import { RailServiceDetails } from 'services/SwaggerApi';
import { BannerInline } from 'types/Notifications';

import { getTpBanner } from './getTpBanner';

type GetTrainServiceAction = (serviceDetails: RailServiceDetails) => {
        extraActions?: ExtraActions;
        banner: BannerInline | null;
    };

export const getTrainServiceAction: GetTrainServiceAction = serviceDetails => {
    // TODO: Выпилить oldIsTpCompliant после включения новых ТП - BTRIP-9669
    const oldIsTpCompliant = serviceDetails.is_travel_policy_compliant;
    const isTravelPolicyViolation = !oldIsTpCompliant || !serviceDetails.train.is_travel_policy_compliant;

    if (serviceDetails.place_state === 'need_change_place') {
        return {
            banner: {
                title: i18n('choose_new_seat'),
                type: 'info',
            },
            extraActions: {
                shouldChangeSeat: true,
            },
        };
    }

    if (serviceDetails.place_state === 'need_change_carriage') {
        return {
            banner: {
                title: i18n('all_seats_sold_out'),
                type: 'info',
            },
            extraActions: {
                shouldRemove: true,
            },
        };
    }

    if (isTravelPolicyViolation) {
        return {
            banner: getTpBanner(serviceDetails.train, oldIsTpCompliant),
        };
    }

    return {
        banner: null,
    };
};
