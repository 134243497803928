export const ru = {
    no_consent_title: 'Доступ ограничен, но это поправимо',
    no_consent_description: 'Для использования сервиса необходимо подписать разрешение на обработку персональных данных',
    view_consent: 'Посмотреть согласие',
    consent_agree: 'Да, согласен',
    consent_disagree: 'Нет, не согласен',
    consent_subtitle: 'Я даю согласие на',
    consent_title: 'Персональные данные',
    consent_pd_processing: 'обработку моих персональных данных',
};
