import * as z from 'zod';

import { ActivePage } from 'shared/constants/ActivePage';
import { checkAccessFactory } from 'utils/checkAccessFactory';

import { route } from './route';

export const RouteTravelPolicyAddDecoder = z.object({
    params: z.object({}),
    search: z.object({}),
});

export type RouteTravelPolicyAdd = z.output<typeof RouteTravelPolicyAddDecoder>;

const checkAccess = checkAccessFactory(
    meta => Boolean(meta.is_coordinator && meta.is_tp_enabled),
    'forbidden',
);

export const RouteTravelPolicyAdd = route(
    '/travel-policy/new',
    RouteTravelPolicyAddDecoder,
    {
        isAdminPanel: true,
        activePage: ActivePage.GROUP,
        checkAccess,
    },
);
