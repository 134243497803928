import dayjs, { ConfigType } from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import { getTypedI18nLang } from '../i18n';

dayjs.extend(LocalizedFormat);

export enum DateFormat {
    YYYY_MM_DDTHH_mm_ss = 'YYYY-MM-DDTHH:mm:ss', // '2023-10-18T20:30:37'
    YYYY_MM_DD = 'YYYY-MM-DD', // '2023-10-18'
    DD_MM_YYYY = 'DD.MM.YYYY', // '18.10.2023'
    D_MMMM_YYYY = 'D MMMM YYYY', // '18 October 2023'
    D_MMMM_HH_mm = 'D MMMM, HH:mm', // '18 October, 20:31'
    D_MMMM_dd = 'D MMMM, dd', // '18 October, We'
    D_MMMM = 'D MMMM', // '18 October'
    D_MMM = 'D MMM', // '18 Oct'
    HH_mm = 'HH:mm', // '20:33'
    D = 'D', // '18'
    MMMM = 'MMMM', // 'October'
    L = 'L', // '10/18/2023'
    LLL = 'LLL', // 'October 18, 2023 8:34 PM'÷
    LLLL = 'LLLL', // 'Wednesday, October 18, 2023 8:34 PM'
}

type FormatDate = (date: ConfigType | null | undefined, format: DateFormat, fallback?: string) => string;

export const formatDate: FormatDate = (date, format, fallback = '-') => {
    if (!date) return fallback;

    const locale = getTypedI18nLang();

    return dayjs(date).locale(locale).format(format);
};
