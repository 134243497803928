import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import arrayMutators from 'final-form-arrays';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { FieldCheckbox, FieldPerson } from 'components/FormField/FormField.providers';
import { Purposes } from 'components/Purposes';
import { UnprocessableEntityError } from 'errors';
import { withRemote } from 'hocs/withRemote';
import { usePathToTrip } from 'hooks/usePathToTrip/usePathToTrip';
import { usePurposes } from 'hooks/usePurposes';
import { useTripCreate } from 'hooks/useTripCreate';
import { validateRequiredField } from 'utils/form/validateRequiredField';
import { getFieldsType } from 'utils/getFieldsType';
import { isLoading } from 'utils/Loadable';

import { formValuesToTripCreate } from './FormTripCreate.utils/formValuesToTripCreate';
import { ConfFields } from './FormTripCreate-ConfFields/ConfFields';
import { TripFields } from './FormTripCreate-TripFields/TripFields';
import { cn } from './FormTripCreate.cn';
import { i18n } from './FormTripCreate.i18n';
import {
    FormTripCreateFormProps,
    FormTripCreateProps,
    SearchFormValues,
} from './FormTripCreate.types';

import './FormTripCreate.css';

const PurposesRemote = withRemote(Purposes);
const SHOW_COVID_WARNING = false;

const FormTripCreateForm: FC<FormTripCreateFormProps> = props => {
    const [purposesSelected, setPurposesSelected] = useState<number[]>(props.initialValues.purposes || []);

    const purposes = usePurposes();
    const history = useHistory();
    const handleCancel = useCallback(() => history.goBack(), [history]);
    const tripType = getFieldsType(purposesSelected);

    const additionalFields = useMemo(() => {
        if (tripType === 'conf') return <ConfFields />;
        if (tripType === 'trip') return <TripFields />;
    }, [tripType]);

    const commonFields = useMemo(() => (
        <>
            <Field
                required
                className={cn('Field')}
                component={FieldPerson}
                name="participants"
                placeholder={i18n('add_participant')}
                title={i18n('participants')}
                validate={validateRequiredField}
            />

            {SHOW_COVID_WARNING && (
                <Field
                    required
                    className={cn('Field')}
                    component={FieldCheckbox}
                    name="risk_acceptance"
                    title={i18n('risk_acceptance')}
                    type="checkbox"
                    validate={validateRequiredField}
                />
            )}
        </>
    ), []);

    return (
        <form className={cn(null, [props.className])} onSubmit={props.handleSubmit}>
            <PurposesRemote
                purposes={purposes}
                selected={purposesSelected}
                setPurposesSelected={setPurposesSelected}
                {...props}
            />

            {additionalFields}

            {tripType && commonFields}

            <div className={cn('Actions')}>
                <ButtonPlatform
                    className={cn('Cancel')}
                    onClick={handleCancel}
                >
                    {i18n('cancel')}
                </ButtonPlatform>
                <ButtonPlatform
                    className={cn('Submit')}
                    progress={props.progress}
                    type="submit"
                    view="brand"
                >
                    {i18n('save')}
                </ButtonPlatform>
            </div>
        </form>
    );
};

export const FormTripCreate: FC<FormTripCreateProps> = ({ user, ...props }) => {
    const queryClient = useQueryClient();
    const history = useHistory();
    const { getPathToTrip } = usePathToTrip();

    // TODO: удалить asyncMutateFn в createUseMutationHook после перехода на react-hook-form BTRIP-2789
    const [, createState, , asyncCreateTrip] = useTripCreate({
        onSuccess: ({ trip_id }, payload) => {
            const tripPath = getPathToTrip({ tripId: trip_id, personTripsCreate: payload.person_trips });

            history.push(tripPath);

            // https://st.yandex-team.ru/BTRIP-3455 убрать этот костыль в этом тикете
            setTimeout(() => queryClient.invalidateQueries('auth_meta'), 3000);
        },
    });

    const initialValues: Partial<SearchFormValues> = {
        participants: [user.person_id],
        purposes: props.purposesSelected || [],
        conf_payment_type: 'paid',
    };
    const [value, setValue] = useState(initialValues);

    const handleSubmit = useCallback(async formValues => {
        setValue(formValues);

        const payload = formValuesToTripCreate(formValues);

        try {
            await asyncCreateTrip(payload);
        } catch (err) {
            if (err instanceof UnprocessableEntityError && err.validationErrors.length) {
                const formErrors = err.validationErrors.reduce((res, err) => ({
                    ...res,
                    [err.formInputName]: err.msg,
                }), {});

                return formErrors;
            }
        }

        return undefined;
    }, [asyncCreateTrip]);

    useEffect(() => {
        if (props.purposesSelected !== value.purposes && props.purposesSelected) {
            setValue({
                ...value,
                purposes: props.purposesSelected || [],
            });
        }
    }, [value, props]);

    return (
        <Form
            {...props}
            className={cn(null, [props.className])}
            initialValues={value}
            mutators={{ ...arrayMutators }}
            progress={isLoading(createState)}
            render={FormTripCreateForm as any}
            onSubmit={handleSubmit}
        />
    );
};

FormTripCreate.displayName = cn();
