import { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';

import { usePathToTrip } from 'hooks/usePathToTrip/usePathToTrip';
import { i18nAction } from 'i18n/i18nAction';
import { Text } from 'shared/ui/Text';

import { PersonListItem } from './PersonListItem';
import { cn } from './ServiceGroupAdd.cn';
import { i18n } from './ServiceGroupAdd.i18n';
import { ServiceGroupAddProps } from './ServiceGroupAdd.types';

import './ServiceGroupAdd.css';

/**
 * Добавление услуги участникам групповой командировки
 */
export const ServiceGroupAdd: FC<ServiceGroupAddProps> = props => {
    const {
        className,
        trip,
        isViolatesTravelPolicy,
        getServiceCreatePayload,
        onAddServiceError,
        onAddServiceStart,
        onAddServiceSuccess,
    } = props;

    const { trip_id, person_trips } = trip;

    const [isServiceAdded, setIsServiceAdded] = useState(false);
    const [loadingServicesCount, setLoadingServicesCount] = useState(0);

    const handleAddService = useCallback(() => {
        setIsServiceAdded(true);
    }, []);

    const handleIncLoadingServices = useCallback(() => {
        setLoadingServicesCount(loadingServicesCount => loadingServicesCount + 1);
    }, []);

    const handleDecLoadingServices = useCallback(() => {
        setLoadingServicesCount(loadingServicesCount => loadingServicesCount - 1);
    }, []);

    const history = useHistory();
    const { getPathToTrip: getPersonTripUrl } = usePathToTrip();
    const personTripUrl = getPersonTripUrl({ tripId: trip_id, personTrips: person_trips });
    const goToTrip = useCallback(() => {
        history.push(personTripUrl);
    }, [history, personTripUrl]);

    const canGoToTrip = isServiceAdded && loadingServicesCount === 0;

    const personsList = person_trips?.map(personTrip => {
        if (!personTrip.person) {
            return null;
        }

        return (
            <PersonListItem
                key={personTrip.person.login}
                getServiceCreatePayload={getServiceCreatePayload}
                isViolatesTravelPolicy={isViolatesTravelPolicy}
                person={personTrip.person}
                onAddService={handleAddService}
                onAddServiceError={onAddServiceError}
                onAddServiceStart={onAddServiceStart}
                onAddServiceSuccess={onAddServiceSuccess}
                onDecLoadingServices={handleDecLoadingServices}
                onIncLoadingServices={handleIncLoadingServices}
            />
        );
    });

    return (
        <div className={cn(null, [className])}>
            <Text
                className={cn('Title')}
                typography="title-m"
            >
                {i18n('title')}
            </Text>
            <Text
                className={cn('Description')}
                typography="body-s"
            >
                {i18n('description')}
            </Text>
            <div className={cn('PersonsList')}>
                {personsList}
            </div>
            <div className={cn('Controls')}>
                <Button
                    disabled={!canGoToTrip}
                    size="m"
                    view="brand"
                    onClick={goToTrip}
                >
                    {i18nAction('continue')}
                </Button>
            </div>
        </div>
    );
};

ServiceGroupAdd.displayName = cn();
