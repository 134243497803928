import { FC, useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import { useMessenger } from 'hooks/useMessenger';
import { usePlatform } from 'hooks/usePlatform';

import { cn } from './MessengerWidget.cn';
import { MessengerWidgetProps } from './MessengerWidget.types';

import '@yandex-int/messenger.widget/lib/ui/button.css';
import './MessengerWidget.css';

const ROUTES_WITH_FLOATING_ISLAND = ['/search/:searchId/results/:key/', '/search/:searchId/results/:key/rooms/'];

export const MessengerWidget: FC<MessengerWidgetProps> = props => {
    const { chatId } = props;
    const messengerWidgetRef = useRef<HTMLDivElement | null>(null);

    const { isMobile } = usePlatform();

    const widget = useMessenger(chatId);

    useEffect(() => {
        if (messengerWidgetRef.current) {
            widget.mountWidget(messengerWidgetRef);
        }

        return () => {
            widget.destroyWidget();
        };
    }, [widget, chatId]);

    const location = useLocation();

    const isActiveRouteWithFloatingIsland = ROUTES_WITH_FLOATING_ISLAND.some(route =>
        matchPath(location.pathname, {
            path: route,
            exact: true,
            strict: false,
        }),
    );

    const shiftMessengerButton = isMobile && isActiveRouteWithFloatingIsland;

    return (
        <div
            ref={messengerWidgetRef}
            className={cn({ shift: shiftMessengerButton })}
            data-testid="messenger-widget-button"
        />
    );
};

MessengerWidget.displayName = cn();
