export const ru = {
    approved: 'Согласована',
    cancelled: 'Отменена',
    closed: 'Завершена',
    draft: 'Черновик',
    executed: 'Оформлена',
    executing: 'Услуги оформляются',
    under_approval: 'На согласовании',
    verification: 'Подтверждение',
    new: 'Новая',
};
