import { PersonTripLite, Trip } from 'services/SwaggerApi';
import { compactDates } from 'utils/formatDate';

export function getTripCompactDates(trip: PersonTripLite | Trip) {
    if (trip.route && trip.route.length > 2) {
        const dateStart = trip.route[0].date;
        const dateEnd = trip.route[trip.route.length - 1].date;

        return compactDates(dateStart, dateEnd);
    }

    const personTrip = trip as PersonTripLite;

    if (personTrip.gap_date_from && personTrip.gap_date_to) {
        return compactDates(personTrip.gap_date_from, personTrip.gap_date_to);
    }

    const groupTrip = trip as Trip;

    if (groupTrip.date_from && groupTrip.date_to) {
        return compactDates(groupTrip.date_from, groupTrip.date_to);
    }

    return '';
}
