export const getSearchURL = (url: string) => {
    const hashIndex = url.indexOf('#');
    const queryIndex = url.indexOf('?');

    if (queryIndex !== -1 && hashIndex !== -1) {
        return {
            pathname: url.slice(0, queryIndex),
            search: url.slice(queryIndex, hashIndex),
            hash: url.slice(hashIndex),
        };
    }

    if (queryIndex !== -1) {
        return { pathname: url.slice(0, queryIndex), search: url.slice(queryIndex), hash: '' };
    }

    if (hashIndex !== -1) {
        return { pathname: url.slice(0, hashIndex), search: '', hash: url.slice(hashIndex) };
    }

    return { pathname: url, search: '', hash: '' };
};

export const createLocationObject = (url: string) => ({
    href: `${window.location.protocol}//${window.location.host}${url}`,
    ...getSearchURL(url),
});

export const createUxFeedbackEvent = (eventName: string) => {
    window.postMessage(
        {
            type: 'uxfb/event',
            payload: { eventName: eventName },
        },
        '*',
    );
};
