import { FC } from 'react';
import { Tag, TagProps } from '@yandex-int/hr-components/Tag2/bundle';

import { i18nCombinedStatus } from 'i18n/i18nCombinedStatus';

import { cn } from './TripStatus.cn';
import { TripStatusProps } from './TripStatus.types';

import './TripStatus.css';

export const TripStatus: FC<TripStatusProps> = props => {
    const { trip } = props;

    const activeTrips = trip.person_trips?.filter(trip => trip.status !== 'cancelled');
    const isSoloTrip = activeTrips?.length === 1;

    const status = isSoloTrip ? activeTrips[0].combined_status : trip.status;

    const statusText = i18nCombinedStatus(status);

    let color: TagProps['color'];

    if (['new', 'executed', 'approved'].includes(status)) {
        color = 'green';
    }

    if (['under_approval', 'executing', 'verification'].includes(status)) {
        color = 'purple';
    }

    if (['cancelled'].includes(status)) {
        color = 'red';
    }

    if (['closed'].includes(status)) {
        color = 'blue';
    }

    if (['draft'].includes(status)) {
        color = 'default';
    }

    return (
        <Tag
            className={cn({ status }, [props.className])}
            color={color}
            maxWidth="full"
            size="m"
            text={statusText}
        />
    );
};

TripStatus.displayName = cn();
