import dayjs from 'dayjs';

import { DateFormat, formatDate } from './formatDate';

export function compactDate(date: string | null | undefined): string {
    if (!date) return '-';

    const dayjsDate = dayjs(date);

    const isCurrentYear = dayjsDate.year() === dayjs().year();
    const dateFormat: DateFormat = isCurrentYear ? DateFormat.D_MMMM : DateFormat.D_MMMM_YYYY;

    return formatDate(date, dateFormat);
}
