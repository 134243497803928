import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_title-s.css';

export type WithTypographyTitleSProps = {
    typography?: 'title-s';
};

export const withTypographyTitleS = withBemMod<WithTypographyTitleSProps>(
    cn(),
    { typography: 'title-s' },
);
