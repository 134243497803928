import * as yup from 'yup';

import { EmployeesGroupFormFields } from './EmployeesGroupForm.types';

type TravelPolicy = EmployeesGroupFormFields['travel_policy'];

export const schema: yup.SchemaOf<EmployeesGroupFormFields> = yup.object({
    group_name: yup.string().trim().max(255).required(),
    travel_policy: yup.mixed<TravelPolicy>().required(),
});
