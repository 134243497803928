import { useMemo } from 'react';

import { TrainFilterKey } from 'api/models/filters//TrainFilterKey';
import { TrainFilterOptions } from 'api/models/filters//TrainFilterOptions';
import { travelTimeOptions } from 'api/models/search/filter';
import { FilterItem } from 'services/SwaggerApi';

import { getTransliteratedFilters } from '../TrainFilters.utils/getTransliteratedFilters';

type GetTrainFilterOptions = () => TrainFilterOptions;

const getTrainFilterOptions: GetTrainFilterOptions = () => ({
    departure_there_timespan: [],
    arrival_there_timespan: [],
    carriers: [],
    carriage_types: [],
    departure_from_there: [],
    arrival_to_there: [],
    train_categories: [],
    train_names: [],
});

type UsePrepareFilters = (filtersRaw: FilterItem[]) => TrainFilterOptions;

export const usePrepareFilters: UsePrepareFilters = filtersRaw => {
    const filters = useMemo(() => {
        return filtersRaw.reduce((acc, filter) => {
            if (!acc[filter.name as TrainFilterKey] || !filter.values) {
                return acc;
            }

            if (filter.name === 'departure_there_timespan' || filter.name === 'arrival_there_timespan') {
                return Object.assign(acc, {
                    [filter.name]: travelTimeOptions,
                });
            }

            return Object.assign(acc, { [filter.name]: filter.values });
        }, getTrainFilterOptions());
    }, [filtersRaw]);

    return getTransliteratedFilters(filters);
};
