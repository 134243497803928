import { FC } from 'react';

import { OptionInfoItem } from 'components/OptionInfoItem';
import { i18nAction } from 'i18n/i18nAction';
import { i18nServiceInfo } from 'i18n/i18nServiceInfo';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { getBaggageInfo } from 'utils/getBaggageInfo';

import { cn } from './FlightSummary.cn';
import { i18n } from './FlightSummary.i18n';
import { FlightSummaryProps } from './FlightSummary.types';

import './FlightSummary.css';

export const FlightSummary: FC<FlightSummaryProps> = props => {
    const {
        className,
        isGroupTrip,
        isChangeable,
        isRefundable,
        canAddService,
        title,
        onClick,
        onClickGroupTrip,
        baggage,
        price,
        progress,
    } = props;

    const handleClick = isGroupTrip ? onClickGroupTrip : onClick;

    const formattedPrice = formatCurrency(price);

    const {
        hasBaggage,
        baggageText,
    } = getBaggageInfo(baggage?.baggage, { withReplace: true });
    const {
        hasBaggage: hasHandBaggage,
        baggageText: handBaggageText,
    } = getBaggageInfo(baggage?.hand_baggage, { handBaggage: true, withReplace: true });

    const isButtonAddServiceDisabled = !isGroupTrip && !canAddService;

    return (
        <div className={cn(null, [className])}>
            <Text
                typography="title-s"
                weight="medium"
            >
                {i18n('selected_tariff')}
            </Text>
            <Text
                as="div"
                className={cn('Header')}
                typography="body-m"
                weight="medium"
            >
                <span>{title || i18n('tariff')}</span>
                <span>{formattedPrice}</span>
            </Text>
            <div className={cn('Options')}>
                <OptionInfoItem
                    hasColorfulText
                    condition={hasHandBaggage}
                    defaultColor="secondary"
                    failureText={handBaggageText}
                    successText={handBaggageText}
                />
                <OptionInfoItem
                    hasColorfulText
                    condition={hasBaggage}
                    defaultColor="secondary"
                    failureText={baggageText}
                    successText={baggageText}
                />
                <OptionInfoItem
                    hasColorfulText
                    condition={Boolean(isChangeable)}
                    defaultColor="secondary"
                    failureText={i18nServiceInfo('not_changeable')}
                    successText={i18nServiceInfo('changeable')}
                />
                <OptionInfoItem
                    hasColorfulText
                    condition={Boolean(isRefundable)}
                    defaultColor="secondary"
                    failureText={i18nServiceInfo('not_refundable')}
                    successText={i18nServiceInfo('refundable')}
                />
            </div>
            <Button
                className={cn('Continue')}
                disabled={isButtonAddServiceDisabled}
                progress={progress}
                size="l"
                view="brand"
                width="max"
                onClick={handleClick}
            >
                {i18nAction('continue')}
            </Button>
            <Text
                align="center"
                as="div"
                className={cn('GroupTripInfo')}
                color="secondary"
                typography="caption-m"
            >
                {isGroupTrip ? i18n('group_trip_info') : i18n('person_trip_info')}
            </Text>
        </div>
    );
};

FlightSummary.displayName = cn();
