import { config } from 'config';
import { ForbiddenError, UnauthorizedError } from 'errors';
import { MetaResponse } from 'services/SwaggerApi';
import { AuthDetails } from 'types/AuthDetails';
import { isFailure, success } from 'utils/Loadable';

import { createUseQueryHook } from './createUseQueryHook';

const authStatuses = [
    AuthDetails.NOT_REGISTERED,
    AuthDetails.EMPTY_COMPANY,
    AuthDetails.NOT_CONFIRMED_EMAIL,
    AuthDetails.USER_DEACTIVATED,
    AuthDetails.USER_REJECTED,
];

const { isB2B } = config;

const useGetAuthMeta = createUseQueryHook(
    'auth_meta',
    {
        staleTime: Infinity,
        // no retry for 401 error, it's valid for b2b nonregistred users
        retry: false,
        refetchOnWindowFocus: false,
    },
);

export interface AuthDetailsResponse {
    detail: AuthDetails;
}

export type MetaMetaResponse = MetaResponse | AuthDetailsResponse;

export const isAuthDetailsResponse = (meta: MetaMetaResponse): meta is AuthDetailsResponse => {
    return 'detail' in meta;
};

export const isUsersResponse = (meta: MetaMetaResponse): meta is MetaResponse => {
    return !('detail' in meta);
};

export const useAuthMeta = () => {
    const meta = useGetAuthMeta();

    if (isB2B && isFailure(meta) && (
        meta.error instanceof UnauthorizedError ||
        meta.error instanceof ForbiddenError
    )) {
        const message = meta.error.message as AuthDetails;

        if (authStatuses.includes(message)) {
            const res: AuthDetailsResponse = { detail: message };

            return success(res);
        }
    }

    return meta;
};

export type { MetaResponse };
