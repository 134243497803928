export const en = {
    waiting_approval: 'Waiting for approval',
    waiting_approval_title: 'Getting ready to process',
    waiting_approval_description: 'We need to approve the trip for you to add services',
    under_approval_title: 'Processing the business trip',
    under_approval_description: 'We need to approve the trip and process the added services',
    under_approval: 'Waiting for approval',
    executing: 'Processing the services',
    executing_title: 'Processing the business trip',
    executing_description: 'The trip is approved, we only need to process the added services',
};
