import * as D from 'io-ts/Decoder';

import { OneToBoolean, ToArray, ToNumber, ZeroToBoolean } from 'utils/io-ts/decoders';

import { ACHotelConfirmationTypeDecoder } from './ACHotelConfirmationTypeDecoder';
import { ACHotelPaymentPlaceDecoder } from './ACHotelPaymentPlaceDecoder';
import { ACOrderByDecoder } from './ACOrderByDecoder';

export const ACSearchFiltersHotelDecoder = D.partial({
    order_by: ACOrderByDecoder,
    is_descending: OneToBoolean,
    stars: ToArray(D.string),
    price_from: ToNumber,
    price_to: ToNumber,
    hotel_types: ToArray(D.string),
    hotel_name: D.string,
    payment_places: ToArray(ACHotelPaymentPlaceDecoder),
    confirmation_types: ToArray(ACHotelConfirmationTypeDecoder),
    is_recommended: OneToBoolean,
    is_restricted_by_travel_policy: ZeroToBoolean,
});

export type ACSearchFiltersHotel = D.TypeOf<typeof ACSearchFiltersHotelDecoder>;
