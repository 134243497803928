import { FC } from 'react';
import { Palettes } from '@yandex-int/hr-components/PaletteGeneration';

import { Dropdown } from 'components/Dropdown';

import { cn } from './DropdownTooltip.cn';
import { DropdownTooltipProps } from './DropdownTooltip.types';

import './DropdownTooltip.css';

export const DropdownTooltip: FC<DropdownTooltipProps> = props => {
    const {
        desktopTrigger = 'hover',
        inverse,
        content,
        direction = ['top', 'right', 'top-end', 'left'],
        hasTail = true,
        className,
        trigger,
    } = props;

    return (
        <Dropdown
            className={cn(null, [className])}
            desktopTrigger={desktopTrigger}
            popupProps={{
                hasTail,
                direction,
                className: inverse ? Palettes.inverse : undefined,
            }}
            triggerElement={trigger}
        >
            <div
                className={cn('Content')}
                data-testid="tooltip-content"
            >
                {content}
            </div>
        </Dropdown>
    );
};

DropdownTooltip.displayName = cn();
