import { FC } from 'react';

import { Card } from 'components/Card';
import { Text } from 'shared/ui/Text';

import { StatusProgressBar } from '../StatusProgressBar';

import { cn } from './ExecutingTripStatus.cn';
import { i18n } from './ExecutingTripStatus.i18n';
import { ExecutingTripStatusProps } from './ExecutingTripStatus.types';

import './ExecutingTripStatus.css';

export const ExecutingTripStatus: FC<ExecutingTripStatusProps> = props => {
    const {
        className,
        status,
    } = props;

    let iconUnit: number;
    let title: string;
    let description: string;
    let iconCaption: string;

    switch (status) {
        case 'draft':
            iconUnit = 1;
            title = i18n('waiting_approval_title');
            description = i18n('waiting_approval_description');
            iconCaption = i18n('waiting_approval');
            break;

        case 'executing':
            iconUnit = 3;
            title = i18n('executing_title');
            description = i18n('executing_description');
            iconCaption = i18n('executing');
            break;

        default:
            iconUnit = 2;
            title = i18n('under_approval_title');
            description = i18n('under_approval_description');
            iconCaption = i18n('under_approval');
            break;
    }

    return (
        <Card className={cn(null, [className])}>
            <Text typography="title-s">
                {title}
            </Text>

            <Text
                className={cn('Description')}
                color="secondary"
                typography="body-s"
            >
                {description}
            </Text>

            <StatusProgressBar
                iconUnit={iconUnit}
                text={iconCaption}
                unitsNumber={4}
            />
        </Card>

    );
};
