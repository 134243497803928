import { FC, memo, useMemo } from 'react';
import { useToggle } from 'react-use';
import { Skeleton } from '@yandex-int/hr-components/Skeleton';

import { GeoMap } from 'components/GeoMap';
import { HotelMark } from 'components/GeoMap/GeoMap.types';
import { getIconProvider, IconClose } from 'components/Icon';
import { Button } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';

import { cn } from '../HotelDetails.cn';

import { LocationOnMapProps } from './LocationOnMap.types';

import './LocationOnMap.css';

const GeoMapMemo = memo(GeoMap);
const ymapOptions = { controls: [] };

export const LocationOnMap: FC<LocationOnMapProps> = props => {
    const {
        className,
        geo_position,
    } = props;

    const [visible, toggleVisible] = useToggle(false);
    const { latitude, longitude } = geo_position;
    const marks = useMemo<[HotelMark]>(
        () => [{ latitude, longitude } as HotelMark],
        [latitude, longitude],
    );

    return (
        <div className={cn('LocationOnMap', [className])}>
            <Skeleton
                className={cn('LocationOnMapPreviewSkeleton')}
                type="control"
            />
            <GeoMapMemo
                className={cn('LocationOnMapPreview')}
                isDraggable={false}
                marks={marks}
                ymapOptions={ymapOptions}
                onMapClick={toggleVisible}
            />
            <Modal
                keepMounted={false}
                scope="inplace"
                theme="normal"
                visible={visible}
                onClose={toggleVisible}
            >
                <GeoMapMemo
                    className={cn('LocationOnMapInModal')}
                    marks={marks}
                    ymapOptions={ymapOptions}
                />
                <Button
                    className={cn('LocationOnMapClose')}
                    icon={getIconProvider(IconClose)}
                    size="m"
                    view="media"
                    onClick={toggleVisible}
                />
            </Modal>
        </div>
    );
};
