export const en = {
    trip_id_label: 'Trip',
    trip_id_placeholder: 'Trip number',
    general_service_id_label: 'Service number',
    general_service_id_placeholder: 'Service number',
    person_label: 'Employee',
    execution_date_label: 'Execution date',
    service_type_label: 'Service type',
    service_type_value_avia: 'Flight',
    service_type_value_hotel: 'Hotel',
    service_type_value_rail: 'Rail',
    service_type_value_transfer: 'Transfer',
    service_type_value_vip_lounge: 'VIP lounge',
    service_type_value_fast_track: 'Fast track',
    service_type_value_aeroexpress: 'Aeroexpress',
    service_type_value_insurance: 'Insurance',
    service_type_value_additional: 'Additional service',
    invoice_date_label: 'Invoice date',
    type_label: 'Transaction type',
    status_label: 'Transaction status',
    type_value_refund: 'Refund',
    type_value_purchase: 'Purchase',
    type_value_exchange: 'Modification',
    status_value_paid: 'Paid',
    status_value_hold: 'Hold',
    status_value_completed: '',
    status_value_verified: '',
    price_label: 'Service price',
    price_placeholder: 'RUB 000 000',
    fee_label: 'Service fee',
    fee_placeholder: 'RUB 000 000',
    is_penalty_label: 'Mark as a fine',
    group_heading_service: 'Service',
    group_heading_transaction: 'Transaction',
    action_alter: 'Alter',
    action_create: 'Create',
    add_cost_center: 'Add cost center',
    cost_center: 'Cost center',
    cost_center_not_specified: 'Not specified',
};
