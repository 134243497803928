import { FC } from 'react';
import { useToggle } from 'react-use';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';
import { Link } from '@yandex-int/hr-components/Link/desktop/bundle';

import { Dialog } from 'components/Dialog';
import { HeaderLite } from 'components/HeaderLite';
import { useMeta } from 'hooks/useMeta';
import { Container } from 'shared/ui/Container';
import { EmptyState } from 'shared/ui/EmptyState';

import { cn } from '../Root.cn';

import { useSignConsent } from './NoPersonalDataConsent.hooks/useSignConsent';
import { i18n } from './NoPersonalDataConsent.i18n';

import './NoPersonalDataConsent.css';

export const NoPersonalDataConsent: FC = () => {
    const { user: { consent: { link } } } = useMeta();

    const [isVisible, toggleVisible] = useToggle(true);

    const [signConsent] = useSignConsent({
        onSuccess: () => {
            toggleVisible();
        },
    });

    const subtitle = (
        <>
            {i18n('consent_subtitle')}
            {' '}
            <Link href={link!} target="_blank" theme="link">
                {i18n('consent_pd_processing')}
            </Link>
        </>
    );

    return (
        <div
            className={cn('NoPersonalDataConsent')}
            data-testid="root-no-consent"
        >
            <HeaderLite showAvatar={false} />
            <Container wrapperClassName={cn('ErrorContainer')}>
                <EmptyState
                    actions={[
                        <Button
                            key="create"
                            size="m"
                            view="primary"
                            onClick={toggleVisible}
                        >
                            {i18n('view_consent')}
                        </Button>,
                    ]}
                    description={i18n('no_consent_description')}
                    imageType="not_found"
                    title={i18n('no_consent_title')}
                />
                <Dialog
                    close={i18n('consent_disagree')}
                    confirm={i18n('consent_agree')}
                    subtitle={subtitle}
                    title={i18n('consent_title')}
                    visible={isVisible}
                    onClose={toggleVisible}
                    onConfirm={signConsent}
                />
            </Container>
        </div>
    );
};
