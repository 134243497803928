import { UserResponse } from 'services/SwaggerApi';

export const nonPersonalFields = [
    'company_id',
    'display_covid_message',
    'display_messenger_chats',
    'display_tracker_issues',
    'gender',
    'is_allowed_select_company',
    'is_allowed_store_documents',
    'is_b2b',
    'is_coordinator',
    'is_dismissed',
    'is_assistant',
    'is_accountant',
    'is_chief',
    'is_limited_access',
    'is_offline_trip',
    'is_support',
    'is_yandex_employee',
    'language',
    'provider',
    'timezone',
    'has_access',
] as const;

type NonPersonalField = typeof nonPersonalFields[number];

const personalFields = [
    'avatar_id',
    'chat_id',
    'first_name',
    'last_name',
    'login',
    'middle_name',
    'person_id',
    'provider_profile_id',
    'uid',
    'consent',
] as const;

type PersonalField = typeof personalFields[number];

// !!Важно не отправлять в метрику персональные данные!!
// сломается на этапе проверки типов когда изменится тип UserResponse
// сделано специально, так как если тип меняется - надо определить
// являются ли добавленные поля не персональными, и если являются -
// добавить в nonPersonalFields, если нет - то наоборот в personalFields
export type UserResponseKeysLeft = keyof Omit<UserResponse, NonPersonalField | PersonalField>;

/**
 * Собирает объект не персональных пользовательских данных
 */
export function getNonPersonalUserData(meta: UserResponse): Partial<UserResponse> {
    return nonPersonalFields.reduce((acc, key) => Object.assign(acc, { [key]: meta[key] }), {});
}
