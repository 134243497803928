export const ru = {
    persons_placeholder: 'Начните вводить имя или почту',
    countries_placeholder: 'Начните вводить страну',
    bonus_placeholder: 'Начните вводить название бонусной карты',
    companies_placeholder: 'Начните вводить компанию',
    kontur_companies_placeholder: 'Начните вводить ИНН или название компании',
    cities_placeholder: 'Начните вводить город',
    travel_policies_placeholder: 'Начните вводить название тревел-политики',
    inn: 'ИНН',
    kpp: 'КПП',
};
