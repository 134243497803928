import { FC } from 'react';
import { IClassNameProps } from '@bem-react/core';
import { useUnit } from 'effector-react';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { Card } from 'components/Card';
import { FormProvider } from 'components/FormField2';
import { PersonBaseFields, PersonContactFields, useFormPersonDetails } from 'components/FormPersonDetails';
import { config } from 'config';
import { withRemote } from 'hocs/withRemote';
import { usePersonDetails } from 'hooks/usePersonDetails';
import { usePersonDetailsPut } from 'hooks/usePersonDetailsPut';
import { i18nAction } from 'i18n/i18nAction';
import { isIOS } from 'platform/utils';
import { PersonDetails } from 'services/SwaggerApi';
import { switchAccountFx } from 'shared/models/platform';
import { Text } from 'shared/ui/Text';
import { addToast } from 'utils/addToast';
import { isLoading } from 'utils/Loadable';

import { cn } from '../PageProfile.cn';

import { i18n } from './TabProfile.i18n';
import { TabProfileProps } from './TabProfile.types';

import './TabProfile.css';

const FormProfile: FC<IClassNameProps & {personDetails: PersonDetails}> = props => {
    const { personDetails } = props;
    const switchAccount = useUnit(switchAccountFx);
    const { isB2B } = config;

    const [personDetailsPut, state, { error }] = usePersonDetailsPut({
        onSuccess: () => addToast({ title: i18n('changes_saved') }),
        options: { hideValidationErrors: false } });

    const { methods, schema, onSubmit } = useFormPersonDetails(personDetails, personDetailsPut);

    return (
        <FormProvider error={error} methods={methods} schema={schema}>
            <form onSubmit={methods.handleSubmit(onSubmit, console.error)}>
                <div className={cn('TabProfile')}>
                    <div className={cn('Forms')}>
                        <Card
                            className={cn('Form')}
                            description={i18n('main_info_desc')}
                            title={i18n('main_info')}
                        >
                            <PersonBaseFields allEditable={Boolean(isB2B)} withMiddleName={Boolean(isB2B)} />
                        </Card>
                        <Card
                            className={cn('Contact')}
                            description={i18n('contact_info_desc')}
                            title={i18n('contact_info')}
                        >
                            <PersonContactFields />
                        </Card>
                    </div>
                    <div className={cn('Controls')}>
                        <Card className={cn('SubmitContainer')}>
                            <ButtonPlatform
                                className={cn('Submit')}
                                progress={isLoading(state)}
                                type="submit"
                                view="brand"
                            >
                                {i18nAction('save_changes')}
                            </ButtonPlatform>
                            <Text typography="caption-m">
                                {i18n('save_changes_desc')}
                            </Text>
                        </Card>
                        {isIOS() && (
                            <Card className={cn('SubmitContainer')}>
                                <ButtonPlatform onClick={switchAccount}>
                                    {i18n('change_user')}
                                </ButtonPlatform>
                            </Card>
                        )}
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

const FormPersonDetailsRemote = withRemote(FormProfile);

export const TabProfile: FC<TabProfileProps> = props => {
    const {
        className,
        personId,
    } = props;

    const personDetails = usePersonDetails(personId);

    return (
        <FormPersonDetailsRemote
            className={cn('TabProfile', [className])}
            personDetails={personDetails}
        />
    );
};
