export const VISA_FREE_LIST = new Set([
    'Россия',
    'Russia',
    'Армения',
    'Armenia',
    'Казахстан',
    'Kazakhstan',
    'Киргизия',
    'Kyrgyzstan',
    'Турция',
    'Turkey',
    'Албания',
    'Albania',
    'Грузия',
    'Georgia',
]);
