import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_label-s.css';

export type WithTypographyLabelSProps = {
    typography?: 'label-s';
};

export const withTypographyLabelS = withBemMod<WithTypographyLabelSProps>(
    cn(),
    { typography: 'label-s' },
);
