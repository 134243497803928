import { FC } from 'react';
import { IClassNameProps } from '@bem-react/core';

import { IconAvia } from 'components/IconAvia';
import { ItemsGroup } from 'components/ItemsGroup';
import { usePlatform } from 'hooks/usePlatform';
import { BaseInfo } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';

import { cn } from '../FlightInfoRoute.cn';
import { i18n } from '../FlightInfoRoute.i18n';

import './CarriersInfo.css';

type CarriersInfoProps = IClassNameProps & {
    carriers: BaseInfo[];
    aircraft?: BaseInfo | null;
};

const aviaIconProvider = (id: string | null | undefined) => <IconAvia name={id} size={28} />;

const MAX_ITEMS = 3;

export const CarriersInfo: FC<CarriersInfoProps> = props => {
    const { carriers, aircraft, className } = props;
    const hasExtraItems = carriers.length > MAX_ITEMS;

    const { isMobile } = usePlatform();

    const carriersIcons = carriers.map(item => aviaIconProvider(item.id));

    const shownNames = hasExtraItems ? carriers.slice(0, MAX_ITEMS - 1) : carriers;

    const mobileCarriersNames = carriers.map(item => item.name).join(' • ');
    const extraCarriersNamesCount = carriers.length - MAX_ITEMS + 1;

    return (
        <div className={cn('CarriersInfo', [className])}>
            <div className={cn('Icons')}>
                <ItemsGroup items={carriersIcons} />
            </div>

            {isMobile ? (
                <Text
                    align="start"
                    as="div"
                    className={cn('ItemName')}
                    typography="caption-m"
                >
                    {mobileCarriersNames}
                </Text>
            ) : (
                <div className={cn('Names')}>
                    {shownNames.map((item, index) => (
                        <span key={index} title={item.name}>
                            <Text
                                align="start"
                                as="div"
                                className={cn('ItemName')}
                                color="primary"
                                typography="caption-m"
                            >
                                {item.name}
                            </Text>
                        </span>
                    ))}
                    {hasExtraItems && (
                        <Text
                            align="start"
                            as="div"
                            className={cn('ItemName')}
                            color="secondary"
                            typography="caption-m"
                        >
                            {i18n('more', { count: extraCarriersNamesCount })}
                        </Text>
                    )}
                </div>
            )}

            {aircraft && (
                <Text
                    align="start"
                    as="div"
                    className={cn('Aircraft')}
                    color="secondary"
                    typography="caption-m"
                >
                    {aircraft.name}
                </Text>
            )}
        </div>
    );
};
