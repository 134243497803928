import { FC, MouseEventHandler } from 'react';
import { Divider } from '@yandex-lego/components/Divider/desktop';

import {
    getIconProvider,
    IconArrowShortLeft,
    IconClose,
} from 'components/Icon';
import { i18nAction } from 'i18n/i18nAction';
import { ButtonClearL } from 'shared/ui/Button';
import { Modal } from 'shared/ui/Modal';

import { cn } from './ModalView.cn';
import { ModalViewProps } from './ModalView.types';

import './ModalView.css';

const iconArrowShortLeft = getIconProvider(IconArrowShortLeft);
const iconClose = getIconProvider(IconClose);

const stopPropagation: MouseEventHandler<HTMLDivElement> = e => e.stopPropagation();

export const ModalView: FC<ModalViewProps> = props => {
    const {
        visible,
        onClose,
        modalOnCloseOff,
        hasCloseIcon,
        autoFocus,
        size = 'l',
        className,
    } = props;

    return (
        <Modal
            autoFocus={autoFocus}
            className={cn(null, [className])}
            keepMounted={false}
            theme="normal"
            visible={visible}
            onClick={stopPropagation}
            onClose={modalOnCloseOff ? undefined : onClose}
        >
            <div className={cn('Wrapper')}>
                <ButtonClearL
                    className={cn('Back')}
                    iconLeft={iconArrowShortLeft}
                    onClick={onClose}
                >
                    {i18nAction('back')}
                </ButtonClearL>

                <Divider className={cn('Divider')} />
                <div className={cn('Content', { size: size })}>
                    {hasCloseIcon && (
                        <div className={cn('ButtonClose')}>
                            <ButtonClearL icon={iconClose} onClick={onClose} />
                        </div>
                    )}
                    {props.children}
                </div>
            </div>
        </Modal>
    );
};

ModalView.displayName = cn();
