import { FC, memo } from 'react';

import { Text } from 'shared/ui/Text';

import { cn } from '../ServiceCostDetails.cn.ts';

import type { CostExplanationElementProps } from './CostExplanationElement.types.ts';

import '../ServiceCostDetails.css';

export const CostExplanationElement: FC<CostExplanationElementProps> = memo(props => {
    const { text, cost } = props;

    return (
        <div className={cn('CostExplanationElement')}>
            <Text
                as="span"
                color="primary"
                typography="body-s"
            >
                {text}
            </Text>
            <Text
                as="div"
                color="ghost"
                typography="body-s"
            >
                {cost}
            </Text>
        </div>
    );
});

CostExplanationElement.displayName = cn('CostExplanationElement');
