import { FC, useMemo } from 'react';

import { generateSegments } from 'components/TripRoute/TripRoute.utils/generateSegments';
import { useOrdersDetails } from 'components/TripRoute/useOrdersDetails';
import { withPropsV2 } from 'hocs/withPropsV2';
import { withRemote } from 'hocs/withRemote';
import { Text } from 'shared/ui/Text';
import { SegmentEvent } from 'types/TripSegment';
import { DateFormat } from 'utils/formatDate';
import { getTypedI18nLang } from 'utils/i18n';

import { cn } from './ViewTimeline.cn';
import { i18n } from './ViewTimeline.i18n';
import { TimelineProps, ViewTimelineViewProps } from './ViewTimeline.types';

import './ViewTimeline.css';

const HeadlineText = withPropsV2(Text, {
    as: 'div',
    typography: 'title-m',
});

const timeLinePointEvents = (events: SegmentEvent[]) => {
    return events.map((event, index: number) => {
        const textColor = event.isPastEvent ? 'ghost' : 'primary';
        const EventIcon = event.icon;

        return (
            <div key={index} className={cn('Event', { past: event.isPastEvent })}>
                <div className={cn('EvenLeftContent')}>
                    <div className={cn('EventIcon', { active: event.isActive })}>
                        <EventIcon size="m" />
                    </div>
                    <div className={cn('EventIconsLineContainer')}>
                        {(index + 1 !== events.length) && <div className={cn('EventIconsLine')} />}
                    </div>
                </div>
                <div className={cn('EventRightContent')}>
                    <Text className={cn('EventName')} typography="title-m">
                        <Text className={cn('EventTime')} color={textColor}>
                            {event.date?.format(DateFormat.HH_mm)}
                        </Text>
                        <Text className={cn('EventText')} color={textColor} typography="title-s" weight="regular">
                            {event.text}
                        </Text>
                    </Text>
                    <Text as="div" className={cn('EventHint')} color="ghost" typography="body-m">
                        {event.hint}
                    </Text>
                </div>
            </div>
        );
    });
};

const ViewTimelineView: FC<ViewTimelineViewProps> = props => {
    const { personTrip, services } = props;
    const locale = getTypedI18nLang();

    const { grouppedEvents } = useMemo(() => generateSegments({
        personTrip,
        services,
    }), [personTrip, services]);

    const timeLinePoints = useMemo(() => {
        return grouppedEvents.map((eventPoint, index) => {
            const { date, events } = eventPoint;

            return (
                <div key={index} className={cn('EventPoint')}>
                    <HeadlineText className={cn('EventTitle')}>
                        {date.locale(locale).format(DateFormat.D_MMMM)}
                        {' '}
                        {eventPoint.city}
                    </HeadlineText>
                    {timeLinePointEvents(events)}
                </div>);
        });
    }, [locale, grouppedEvents]);

    return (
        <div className={cn(null, [props.className])}>
            <div className={cn('Title')}>
                <Text as="span" className={cn('PageTitle')} typography="display-s">
                    {i18n('timeline_title')}
                    <Text as="div" color="ghost" typography="title-s">
                        {i18n('time')}
                    </Text>
                </Text>
                <div className={cn('Actions')}>
                    {/* TODO: https://st.yandex-team.ru/BTRIP-1891
                    <div className={cn('ExportIcons')}>
                        <ButtonClearM onClick={onClose} icon={getIconProvider(IconDownload)} />
                        <ButtonClearM onClick={onClose} icon={getIconProvider(IconExport)} />
                    </div>  */}
                </div>
            </div>
            <div className={cn('TimelineContent')}>
                {timeLinePoints}
                {!grouppedEvents.length && (
                    <Text as="div" className={cn('Event')} typography="title-m">
                        {i18n('services_not_choosed')}
                    </Text>
                )}
            </div>
        </div>
    );
};

const ViewTimelineViewRemote = withRemote(ViewTimelineView);

export const ViewTimeline: FC<TimelineProps> = props => {
    const { trip_id, personTrip } = props;

    const services = useOrdersDetails(trip_id, personTrip);

    return (
        <ViewTimelineViewRemote
            spinner
            personTrip={personTrip}
            services={services}
        />
    );
};

ViewTimeline.displayName = cn();
