import { ComponentProps, FC, ReactElement, useCallback } from 'react';
import { withBemMod } from '@bem-react/core';
import { Radio, Radiobox, RadioOptionProps } from '@yandex-lego/components/Radiobox/desktop/bundle';

import { Text } from 'shared/ui/Text';

import { cn } from '../FormField.cn';
import { FormFieldProps as BaseProps } from '../FormField.types';

import './FormField_type_radioToggle.css';

export type RadioOption = RadioOptionProps & {
    hint?: string;
};

type RadioboxProps = ComponentProps<typeof Radiobox>;

export interface WithTypeRadioToggleProps {
    type?: 'radioToggle';
    onChange?: (value: RadioboxProps['value']) => void;
    value?: RadioboxProps['value'];
    state?: 'error';
    name?: string;
    options?: RadioOption[];
    multiline?: boolean;
}

export const withTypeRadioToggle = withBemMod<WithTypeRadioToggleProps, BaseProps>(
    cn(),
    { type: 'radioToggle' },
    (Base): FC<BaseProps & WithTypeRadioToggleProps> => (props): ReactElement => {
        const {
            onChange = () => {},
            value,
            name,
            options = [],
            multiline = false,
        } = props;

        const onChangeValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
        }, [onChange]);

        const appendAfter = (
            <label>
                <Radiobox
                    className={cn('Control', { name, multiline })}
                    name={name}
                    options={options}
                    size="m"
                    value={value}
                    view="outline"
                    onChange={onChangeValue}
                >
                    {options.map(option => {
                        const {
                            value,
                            label,
                            hint,
                        } = option;

                        const hintText = hint && (
                            <Text
                                as="div"
                                className={cn('Hint')}
                                color="secondary"
                                typography="caption-m"
                            >
                                {hint}
                            </Text>
                        );

                        return (
                            <Radio
                                key={value}
                                className={cn('Radio')}
                                value={value}
                            >
                                {label}
                                {hintText}
                            </Radio>
                        );
                    })}
                </Radiobox>
            </label>
        );

        return (
            <Base
                { ...props }
                appendAfter={appendAfter}

            />
        );
    },
);
