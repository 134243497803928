export const en = {
    trip_id_header: 'Trip',
    execution_date_header: 'Execution date',
    cost_center_header: 'Cost center',
    person_header: 'Employee',
    service_type_header: 'Service',
    service_type_value_avia: 'Flight',
    service_type_value_hotel: 'Hotel',
    service_type_value_rail: 'Rail',
    service_type_value_transfer: 'Transfer',
    service_type_value_vip_lounge: 'VIP lounge',
    service_type_value_fast_track: 'Fast track',
    service_type_value_aeroexpress: 'Aeroexpress',
    service_type_value_insurance: 'Insurance',
    service_type_value_additional: 'Additional service',
    type_header: 'Transaction type',
    type_value_refund: 'Refund',
    type_value_purchase: 'Purchase',
    type_value_exchange: 'Modification',
    price_header: 'Service price',
    fee_header: 'Service fee',
    full_price_header: 'Full price',
    status_header: 'Status',
    status_value_completed: 'Send to billing',
    status_value_verified: 'Collated with registry',
    status_value_paid: 'Paid',
    status_value_hold: 'Hold',
    is_penalty_header: 'Penalty',
};
