import { UseInfiniteQueryResult } from 'react-query';
import * as t from 'io-ts';

import {
    Hotel,
    TGet_avia_search_resultsQuery,
    TGet_hotel_search_resultsQuery,
    TGet_rail_search_resultsQuery,
} from 'services/SwaggerApi';
import { Service } from 'services/TripApi/Service';
import { decodeLoadable } from 'utils/decodeLoadable';
import { mapLoadable, RemoteData } from 'utils/Loadable';

import { useSearchOptionResultListMethod } from './useSearchOptionResultList';

type AviaQuery = { type: 'Avia' } & TGet_avia_search_resultsQuery;
type HotelQuery = { type: 'Hotel' } & TGet_hotel_search_resultsQuery;
type RailQuery = { type: 'Rail' } & TGet_rail_search_resultsQuery;

export type SearchFilters = AviaQuery | HotelQuery | RailQuery;

export const SearchResultItems = t.type({
    count: t.number,
    items: t.array(Service),
    service_type: t.union([t.literal('avia'), t.literal('hotel'), t.literal('rail')]),
}, 'SearchResultItems');

export type SearchResultItems = t.TypeOf<typeof SearchResultItems>;

export const QUERY_LIMIT = 20;

export const META_HOTEL_NAME_RU = 'Метамосква';
export const META_HOTEL_NAME_EN = 'Metamoscow';

type GetHotelServices = (services: SearchResultItems['items']) => Hotel[];

const getHotelServices: GetHotelServices = services => {
    return services.filter((service): service is Hotel => Boolean(('hotel_name' in service) && service.hotel_name));
};

export function useSearchResults(searchId: string, query: SearchFilters & { person_id: string }): [
    RemoteData<SearchResultItems>,
    UseInfiniteQueryResult<unknown>,
] {
    query.limit = QUERY_LIMIT;

    const useSearchOptionResultList = useSearchOptionResultListMethod(query);
    const [results, infiniteQueryResult] = useSearchOptionResultList(searchId, query);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore поправить тут BTRIP-3113
    const typedResults = decodeLoadable(results, SearchResultItems);

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    liftMetaMoskva(typedResults);

    return [typedResults, infiniteQueryResult];
}

// выводить у Метамосквы плашку Я.Путешествий http://st/BTRIP-1867 и поднимать в поиске
function liftMetaMoskva(typedResults: RemoteData<SearchResultItems>) {
    mapLoadable(typedResults, typedResults => {
        const services = typedResults.items;
        const hotelServices = getHotelServices(services);

        if (hotelServices.length) {
            const metaHotelIndex = hotelServices.findIndex(
                hotel => [META_HOTEL_NAME_RU, META_HOTEL_NAME_EN].includes(hotel.hotel_name),
            );

            if (metaHotelIndex >= 0) {
                const [metaHotel] = hotelServices.splice(metaHotelIndex, 1);

                // у Метамосквы не должно быть флага is_recommeded
                // при этом is_recommended = false означает, что отель в черном списке, мы такие не показываем
                // is_recommended = null - просто означает, что отель не рекомендованный
                metaHotel.is_recommended = null;

                // При сортировке по цене или ещё каких-то действиях, рекомендованные отели
                // могут идти не подряд сверху, поэтому буду менять место положения Метамосквы
                // только тогда, когда все рекомендованные отели в выдаче идут подряд начиная с первого.
                // В этом случае Метамосква будет идти сразу за ними.
                // Проверяем, что рекомендованные отели идут подряд, начиная с первого
                const countRecommended = hotelServices.filter(hotel => hotel.is_recommended).length;
                const areFirstHotelsRecommended = countRecommended > 0 &&
                    hotelServices.slice(0, countRecommended).every(hotel => hotel.is_recommended);
                const targetIndex = areFirstHotelsRecommended ? countRecommended : metaHotelIndex;

                hotelServices.splice(targetIndex, 0, metaHotel);
            }
        }
    });
}
