export const ru = {
    title: 'Подтверждение почты',
    email_confirmed: 'Почта подтверждена',
    wait_for_link: 'Осталось дождаться, когда координатор разрешит вам доступ к сервису. Мы отправим письмо на {email}, когда доступ будет открыт',
    follow_the_link: 'Для подтверждения почты перейдите по ссылке в письме, которое мы отправили на {email}.',
    go_back: 'Если вы неправильно ввели адрес электронной почты при регистрации, вернитесь назад и укажите новый адрес почты.',
    no_email: 'Письма нет во входящих или папке со спамом?',
    send_again: 'Давайте отправим его повторно',
    send_success: 'Письмо отправлено',
    send_error: 'Не удалось отправить письмо. Попробуйте ещё раз позже.',
};
