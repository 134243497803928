export const ru = {
    addDocumentLink: 'Добавить документ',
    addHotelLink: 'Выбрать гостиницу',
    add_airline_ticket: 'Добавить авиабилет',
    add_airline_ticket_back: 'Добавить авиабилет обратно',
    add_participants: 'Добавить участников',
    add_train_ticket: 'Добавить ЖД билет',
    add_train_ticket_back: 'Добавить ЖД билет обратно',
    add_train_ticket_to_destination: 'Добавить ЖД билет туда',
    business_trip: 'Командировка',
    cancel: 'Нет',
    cancel_confirmation: 'Вы уверены, что хотите отменить заявку?',
    cancel_confirmation_with_services:
        'Мы запустим отмену оформленных услуг, если такая возможность будет доступна. Как правило, отмена услуг невозможна или происходит со штрафами. Для уточнения возможных штрафов перед отменой, обратитесь в поддержку.',
    cancel_confirmation_reason: 'Расскажите, пожалуйста, почему отменяете заявку на командировку',
    cancel_trip: 'Отменить мою заявку',
    confirm: 'Да, отменить',
    documentsHeader: 'Документ',
    fix_application_date: 'Не получается создать поиск, так как даты заявки в прошедшем времени, поправьте их',
    group_trip: 'Групповая заявка',
    hotelHeader: 'Гостиница',
    inner_conference_reminder:
        'Заявка на конференцию в вашем городе создана, оформление участия будет проходить в тикете очереди INTERCONF',
    offline_trip:
        'Оформление услуг в сервисе для вас недоступно. Агент оформит их исходя из ваших комментариев при создании заявки.',
    see_in_tracker: 'Тикет: ',
    ticketsHeader: 'Билеты',
    timeline: 'Маршрут поездки',
    tripLoading: 'Загрузка командировки',
    trip_number: 'Заявка номер {trip_id}',
    yes_understand: 'Да, понятно',
    conference_without_trip_title: 'Конференция без командировки',
    conference_without_trip_description: 'Если планы изменились и командировка всё-таки нужна, то можно отменить заявку и оформить новую',
    cancelled_title: 'Командировка отменена',
    cancelled_description: 'Можно создать новую или обратиться в поддержку, если это ошибка',
    chat_with_support: 'Написать в поддержку',
    we_recommend_to_submit: 'Рекомендуем все добавленные услуги сразу отправлять на оформление. Иначе что-то может закончиться, где-то измениться цена, и нужно будет добавлять услуги заново.',
    we_recomment_to_submit_continuation: 'Подробности об оформлении читайте на',
    wiki_page: 'вики-странице',
};
