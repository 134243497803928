import { FC } from 'react';
import { Icon } from '@yandex-int/hr-components/Icon/desktop/bundle';

import { Address } from 'components/Address';
import { TagHint } from 'components/TagHint';
import { i18nHints } from 'i18n/i18nHints';
import { Tag } from 'shared/ui/Tag';
import { Text } from 'shared/ui/Text';

import { cn } from '../HotelDetails.cn';
import { i18n } from '../HotelDetails.i18n';
import { NearbyStops } from '../NearbyStops';
import { getNearbyOffice } from '../utils/getNearbyOffice';
import { getNearbyStations } from '../utils/getNearbyStations';

import { SubHeaderProps } from './SubHeader.types';

import './SubHeader.css';

export const SubHeader: FC<SubHeaderProps> = ({ hotel }) => {
    const {
        address,
        stars,
        hotel_name,
        ymap_info,
        location,
        is_recommended,
    } = hotel.hotel;

    const rate = ymap_info?.rate;
    const hotelCity = location.city?.name || '';
    const orgId = ymap_info?.org_id ?? undefined;
    const hasStars = Boolean(stars);

    const nearbyStations = getNearbyStations(ymap_info?.near_stops);
    const nearbyOffice = getNearbyOffice(ymap_info?.near_stops)[0];

    const rateContent = rate && (
        <>
            <Text typography="body-s" weight="medium">{rate.average_score?.toFixed(1)}</Text>
            <Text color="secondary" typography="body-s">{i18n('rating')}</Text>
        </>
    );

    const hotelStarsContent = hasStars && (
        <Text color="secondary">
            {Array(stars).fill(
                <Icon
                    glyph="starChecked"
                    size="m"
                />,
            )}
        </Text>
    );

    return (
        <div className={cn('SubHeader')}>
            <div className={cn('Info')}>
                <div className={cn('InfoTags')}>
                    {is_recommended && (
                        <div className={cn('RecommendedTag')}>
                            <TagHint
                                inverse
                                color="purple"
                                desktopTrigger="hover"
                                hintContent={i18nHints('recommended_hotel_hint')}
                                tagText={i18n('colleagues_recommend')}
                            />
                        </div>
                    )}
                    {hotelStarsContent && (
                        <Tag
                            className={cn('Stars')}
                            size="m"
                            text={hotelStarsContent}
                        />
                    )}
                    {rateContent && (
                        <Tag
                            className={cn('Rating')}
                            size="m"
                            text={rateContent}
                        />
                    )}
                </div>
                {address && (
                    <Address
                        address={address}
                        as="span"
                        city={hotelCity}
                        hotelName={hotel_name}
                        orgId={orgId}
                        typography="body-s"
                    >
                        {address}
                    </Address>
                )}

            </div>
            <NearbyStops nearbyOffice={nearbyOffice} nearbyStops={nearbyStations} />
        </div>
    );
};

SubHeader.displayName = cn();
