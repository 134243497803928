import { FC, useCallback } from 'react';
import { useToggle } from 'react-use';

import { BonusCardForm } from 'components/BonusCardForm';
import { ButtonPlatform } from 'components/ButtonPlatform';
import { getIconProvider, IconPlus } from 'components/Icon';
import { withModalView } from 'hocs/withModalView';
import { ListItem } from 'shared/ui/ListItem';

import { EmptyState } from './EmptyState';
import { ItemMenu } from './ItemMenu';
import { cn } from './LoyaltyCards.cn';
import { i18n } from './LoyaltyCards.i18n';
import { LoyaltyCardsProps } from './LoyaltyCards.types';

import './LoyaltyCards.css';

const iconAdd = getIconProvider(IconPlus);
const LoyaltyCardsView = withModalView(BonusCardForm);

/**
 * Блок бонусных карт на странице профиля
 */
export const LoyaltyCards: FC<LoyaltyCardsProps> = props => {
    const {
        className,
        loyaltyCards,
        personId,
    } = props;

    const isEmpty = loyaltyCards.length === 0;

    const [visibleModalAddBonusCard, toggleModalAddBonusCard] = useToggle(false);

    const handleAddClick = useCallback(() => {
        toggleModalAddBonusCard();
    }, [toggleModalAddBonusCard]);

    return (
        <div className={cn({ empty: isEmpty }, [className])}>
            {
                isEmpty ? (
                    <EmptyState handleAddClick={handleAddClick} />
                ) : (
                    <>
                        <div className={cn('List')}>
                            {loyaltyCards.map(item => (
                                <ListItem
                                    key={item.bonus_card_id}
                                    className={cn('Item')}
                                    leadingTextParams={{
                                        label: item.name.ru || '',
                                        caption: item.number,
                                    }}
                                    testId="loyalty-card-item"
                                    trailing={
                                        <ItemMenu
                                            documentId={item.bonus_card_id}
                                            personId={personId}
                                        />
                                    }
                                />
                            ))}
                        </div>
                        <ButtonPlatform
                            className={cn('AddButton')}
                            iconLeft={iconAdd}
                            view="pseudo"
                            onClick={toggleModalAddBonusCard}
                        >
                            {i18n('add_bonus_card')}
                        </ButtonPlatform>
                    </>
                )
            }
            <LoyaltyCardsView
                modalViewOnClose={toggleModalAddBonusCard}
                modalViewVisible={visibleModalAddBonusCard}
                personId={personId}
                onCancel={toggleModalAddBonusCard}
                onSubmit={toggleModalAddBonusCard}
            />
        </div>

    );
};

LoyaltyCards.displayName = cn();
