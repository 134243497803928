import { FC, useCallback } from 'react';

import { Dialog } from 'components/Dialog/Dialog';
import { i18nAction } from 'i18n/i18nAction';

import { useTransactionDelete } from './TransactionDeleteModal.hooks/useTransactionDelete';
import { cn } from './TransactionDeleteModal.cn';
import { i18n } from './TransactionDeleteModal.i18n';
import { TransactionDeleteModalProps } from './TransactionDeleteModal.types';

import './TransactionDeleteModal.css';

export const TransactionDeleteModal: FC<TransactionDeleteModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        transaction,
        className,
    } = props;

    const { company_id, transaction_id } = transaction;

    const [deleteTransaction] = useTransactionDelete({ onSuccess: onSubmit });

    const handleClick = useCallback(() => {
        deleteTransaction(company_id, transaction_id);
    }, [deleteTransaction, company_id, transaction_id]);

    return (
        <Dialog
            visible
            className={className}
            close={i18nAction('cancel')}
            closeButtonView="ghost"
            confirm={i18n('action_delete')}
            subtitle={i18n('info')}
            title={i18n('title')}
            onClose={onCancel}
            onConfirm={handleClick}
        />
    );
};

TransactionDeleteModal.displayName = cn();
