import { FC, useCallback, useMemo } from 'react';

import { getIconProvider, IconMinus, IconPlus } from 'components/Icon';
import { Button } from 'shared/ui/Button';
import { ListItem } from 'shared/ui/ListItem';

import { Text } from '../Text';

import { cn } from './NumberCounterInput.cn';
import { NumberCounterInputProps } from './NumberCounterInput.types';

import './NumberCounterInput.css';

const iconProviderPlus = getIconProvider(IconPlus);
const iconProviderMinus = getIconProvider(IconMinus);

export const NumberCounterInput: FC<NumberCounterInputProps> = props => {
    const {
        value,
        onChange,
        label,
        caption,
        min = -Infinity,
        max = Infinity,
        dataTestId,
        className,
    } = props;

    const onAddClick = useCallback(() => {
        onChange(value + 1);
    }, [value, onChange]);

    const onSubtractClick = useCallback(() => {
        onChange(value - 1);
    }, [value, onChange]);

    const trailing = useMemo(() => (
        <div className={cn('Trailing')}>
            <Button
                disabled={value === min}
                icon={iconProviderMinus}
                size="s"
                view="primary"
                onClick={onSubtractClick}
            />
            <Text>{value}</Text>
            <Button
                disabled={value === max}
                icon={iconProviderPlus}
                size="s"
                view="primary"
                onClick={onAddClick}
            />
        </div>
    ), [max, min, onAddClick, onSubtractClick, value]);

    return (
        <ListItem
            expanded
            noVerticalPadding
            transparent
            className={cn(null, [className])}
            leadingTextParams={{ label, caption }}
            testId={dataTestId}
            trailing={trailing}
        />
    );
};

NumberCounterInput.displayName = cn();
