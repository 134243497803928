export const en = {
    action_activate: 'Allow access',
    action_block: 'Block access',
    action_change_approver: 'Change approver',
    action_change_company: 'Change company',
    action_grant_coordinator: 'Appoint coordinator',
    action_assign_assistant: 'Assign an assistant',
    action_remove_assistant: 'Delete an assistant',
    action_reject: 'Reject',
    action_restore: 'Restore access',
    action_revoke_coordinator: 'Revoke coordinator role',
    action_add_employee: 'Add employee',
    approver_header: 'Approver',
    email_header: 'Email',
    name_header: 'Full name',
    role_header: 'Role',
    role_value_user: 'Employee',
    role_value_coordinator: 'Coordinator',
    role_value_assistant: 'Assistant',
    status_header: 'Access right',
    status_value_wait_activation: 'Wait activation',
    status_value_activated: 'Activated',
    status_value_blocked: 'Blocked',
    status_value_rejected: 'Activation rejected',
    hint_goto_profile: 'Go to user profile',
};
