import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { getQueryParamsFromSearch } from 'utils/getQueryParams';

export function useQueryParams<T>() {
    const location = useLocation();

    const query = useMemo(() => {
        return getQueryParamsFromSearch(location.search);
    }, [location.search]);

    return query as T;
}
