import { FC } from 'react';

import { AppLink } from 'components/AppLink';
import { UGCCategoryAspect } from 'services/SwaggerApi';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import { cn } from '../HotelDetails.cn';
import { i18n } from '../HotelDetails.i18n';

import { StatsProps } from './Rating.types';
import { RatingProgress } from './RatingProgress';

const STATS_SHOWN = 4;

const getPositiveReviewsPercentage = (stat: UGCCategoryAspect) => {
    return Math.floor(stat.positiveReviewsCount / stat.reviewsCount * 100);
};

export const Stats: FC<StatsProps> = props => {
    const { reviews: { aspect_stats } } = props;

    const mainStats = aspect_stats.slice(0, STATS_SHOWN);

    if (mainStats.length === 0) {
        return null;
    }

    return (
        <div className={cn('StatInfo')}>
            <div className={cn('Stats')}>
                <Text typography="body-m" weight="medium">
                    {i18n('guests_enjoyed')}
                </Text>
                {mainStats.map(stat => (
                    <RatingProgress
                        key={stat.id}
                        title={stat.key}
                        totalCount={stat.reviewsCount}
                        value={getPositiveReviewsPercentage(stat)}
                    />
                ))}
            </div>
            <AppLink href="#review">
                <Button
                    className={cn('GoToReviews')}
                    size="m"
                    view="outline"
                >
                    {i18n('go_to_reviews')}
                </Button>
            </AppLink>
        </div>
    );
};
