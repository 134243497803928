import { FC, useCallback } from 'react';

import { IconAvia } from 'components/IconAvia';
import { RichCheckbox } from 'components/RichCheckbox';
import { i18nErrors } from 'i18n/i18nErrors';
import { Text } from 'shared/ui/Text';

import { cn } from './cn';
import { i18n } from './FilterFieldAirlines.i18n';
import { FilterFieldAirlinesProps } from './interface';

import './style.css';

export const FilterFieldAirlines: FC<FilterFieldAirlinesProps> = props => {
    const { value: selected, onChange } = props.input;

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();

        const { code } = event.target.dataset;

        if (code === undefined) throw new Error(i18nErrors('option_code_undefined'));

        if (code === '*') {
            if (selected.length === props.airlines.length) return onChange([]);

            return onChange(props.airlines.map(({ target_id }) => target_id));
        }

        const codeIndex = selected.indexOf(code);

        if (codeIndex === -1) return onChange([...selected, code]);

        return onChange([
            ...selected.slice(0, codeIndex),
            ...selected.slice(codeIndex + 1),
        ]);
    }, [selected, onChange, props.airlines]);

    return (
        <div className={cn(null, [props.className])}>
            <RichCheckbox
                key="*"
                checked={selected.length === props.airlines.length}
                className={cn('Checkbox')}
                data-code="*"
                onChange={handleChange}
            >
                <Text typography="body-s">{i18n('select_all_airlines')}</Text>
            </RichCheckbox>
            {props.airlines.map(airline => (
                <RichCheckbox
                    key={airline.target_id}
                    checked={selected.includes(airline.target_id)}
                    className={cn('Checkbox')}
                    data-code={airline.target_id}
                    onChange={handleChange}
                >
                    <span className={cn('Airline')}>
                        <IconAvia className={cn('AirlineIcon')} name={airline.target_id} />
                        <Text typography="body-s">{airline.caption}</Text>
                    </span>
                </RichCheckbox>
            ))}
        </div>
    );
};
