import { ComponentProps, FC, ReactElement } from 'react';
import { withBemMod } from '@bem-react/core';

import { DatePicker } from 'components/DatePicker';

import { cn } from '../FormField.cn';
import { FormFieldProps as BaseProps } from '../FormField.types';

type DatePickerProps = ComponentProps<typeof DatePicker>;

export type WithDatePickerProps = {
    type?: 'datepicker';
    name?: string;
    state?: Maybe<'error'>;
} & Partial<DatePickerProps>;

export const withTypeDatePicker = withBemMod<WithDatePickerProps, BaseProps>(
    cn(),
    { type: 'datepicker' },
    (Base): FC<BaseProps & Partial<DatePickerProps>> => (props): ReactElement => {
        const {
            onChange,
            value,
            name,
            state,
            error,
            inputPlaceholder = '',
            ...DatePickerProps
        } = props;

        const appendAfter = (
            <DatePicker
                {...DatePickerProps}
                inputPlaceholder={inputPlaceholder}
                state={error ? 'error' : undefined}
                value={value}
                onChange={onChange}
            />
        );

        return (
            <Base
                { ...props }
                appendAfter={appendAfter}
            />
        );
    },
);
