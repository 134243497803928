import dayjs from 'dayjs';

import { TariffCancellationRule } from 'services/SwaggerApi';

import { i18n } from '../HotelRoom.i18n';

export type GetCancellationTerm = {
    title: string;
    penaltyRules: TariffCancellationRule[];
    noPenaltyRule: Nullable<TariffCancellationRule>;
};

export const getCancellationTerm = (
    cancellationRules: TariffCancellationRule[],
    price: number,
): GetCancellationTerm => {
    const penaltyRules: TariffCancellationRule[] = [];
    let noPenaltyRule: Nullable<TariffCancellationRule> = null;

    if (cancellationRules.length === 0) {
        return {
            title: i18n('cancellation_no_info'),
            penaltyRules,
            noPenaltyRule,
        };
    }

    for (const rule of cancellationRules) {
        if (rule.penalty === 0) {
            noPenaltyRule = rule;
            continue;
        }

        penaltyRules.push(rule);
    }

    const [firstRule] = cancellationRules;
    const {
        date_to,
        penalty,
        non_refundable_rule,
    } = firstRule;

    if (firstRule.penalty === 0) {
        return {
            title: `${i18n('cancellation_without_penalty')}${date_to ? ' ' + i18n('to', { date: dayjs(date_to).format('D MMMM') }) : ''}`,
            penaltyRules,
            noPenaltyRule,
        };
    }

    if (non_refundable_rule || penalty === price) {
        return {
            title: i18n('cancellation_no'),
            penaltyRules,
            noPenaltyRule,
        };
    }

    return {
        title: i18n('cancellation_with_penalty'),
        penaltyRules,
        noPenaltyRule,
    };
};
