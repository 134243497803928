import { createContext } from 'react';
import dayjs from 'dayjs';
import * as yup from 'yup';

import { i18n } from 'utils/form/SchemaErrors.i18n';
import { DateFormat, formatDate } from 'utils/formatDate';

declare module 'yup' {
    interface NumberSchema {
        finite(): this;
    }
    export interface StringSchema {
        /**
         * Check for phone number validity.
         *
         * @param {String} [errorMessage=DEFAULT_MESSAGE] The error message to return if the validation fails.
         */
        phone(
            errorMessage?: string
        ): StringSchema;
    }

    interface DateSchema {
        /**
         * Проверяет, что дата в поле меньше чем в поле с которым происходит сравнение
         */
        lessThan(
            depField: string,
            errorMessageFn?: (value: Date) => string
        ): yup.DateSchema;
        /**
         * Проверяет, что дата в поле превосходит дату в поле с которым происходит сравнение
         */
        moreThan(
            depField: string,
            errorMessageFn?: (value: Date) => string
        ): yup.DateSchema;
    }

}

yup.setLocale({
    mixed: {
        required: () => i18n('required'),
    },
    string: {
        length: params => i18n('length', { count: params.length }),
        min: params => i18n('minLength', { count: params.min }),
        max: params => i18n('maxLength', { count: params.max }),
        email: () => i18n('email'),
        url: () => i18n('url'),
    },
    number: {
        positive: () => i18n('positive'),
        negative: () => i18n('negative'),
        integer: () => i18n('integer'),
        min: params => i18n('minNumber', { count: params.min }),
        max: params => i18n('maxNumber', { count: params.max }),
        lessThan: params => i18n('lessThan', { count: params.less }),
        moreThan: params => i18n('moreThan', { count: params.more }),
    },
    date: {
        min: params => i18n('minDate', { date: formatDate(params.min, DateFormat.L) }),
        max: params => i18n('maxDate', { date: formatDate(params.max, DateFormat.L) }),
    },
    array: {
        min: params => i18n('minArrayLength', { count: params.min }),
        max: params => i18n('maxArrayLength', { count: params.max }),
        length: params => i18n('arrayLength', { count: params.length }),
    },
});

yup.addMethod(yup.number, 'finite', function() {
    return this.transform((num: number) => {
        // Empty form field value is empty string => yup casts it to NaN under the hood
        if (isNaN(num)) {
            return undefined;
        }

        return num;
    });
});

const YUP_PHONE_METHOD = 'phone';
const YUP_PHONE_REGEX = /^((\+?\d{0,4})?[\- ]?)?(\(?\d{3,5}\)?[\- ]?)?[\d\- ]{7,10}$/;

// Чтобы не тянуть 600Кб библиотеки yup-phone доопределил такой же метод тут
// Посмотрел вот тут: https://a.yandex-team.ru/arcadia/frontend/services/news-partners/src/lib/form/validate/phone/yup-phone.ts?rev=r10837692#L16
yup.addMethod(yup.string, YUP_PHONE_METHOD, function yupPhone(errorMessage: string = 'Некорректный номер телефона') {
    return this.test(YUP_PHONE_METHOD, errorMessage, value => {
        if (!value) {
            return true;
        }

        return YUP_PHONE_REGEX.test(value);
    });
});

yup.addMethod(yup.date, 'lessThan', function yupLessThan(depField: string, errorMessageFn?: (value: Date) => string) {
    return this.when(depField,
        (depFieldValue: Date | undefined) =>
            this.test('lessThan',
                value => errorMessageFn ? errorMessageFn(value.value) : `Укажите дату после ${formatDate(depFieldValue, DateFormat.DD_MM_YYYY)}`,
                value => {
                    if (!value || !depFieldValue || !dayjs(depFieldValue).isValid() || !dayjs(value).isValid()) {
                        return true;
                    }

                    return value! > depFieldValue;
                },
            ),
    );
});

yup.addMethod(yup.date, 'moreThan', function yupLessThan(depField: string, errorMessageFn?: (value: Date) => string) {
    return this.when(depField,
        (depFieldValue: Date | undefined) =>
            this.test('moreThan',
                value => errorMessageFn ? errorMessageFn(value.value) : `Укажите дату до ${formatDate(depFieldValue, DateFormat.DD_MM_YYYY)}`,
                value => {
                    if (!value || !depFieldValue || !dayjs(depFieldValue).isValid() || !dayjs(value).isValid()) {
                        return true;
                    }

                    return value! < depFieldValue;
                },
            ),
    );
});

export const SchemaContext = createContext<yup.AnyObjectSchema>(yup.object());
