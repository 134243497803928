import { root } from 'shared/models/root';
import { Banner } from 'types/Notifications';

export const createBannerNotification = root.createEvent<Banner>();
export const deleteBannerNotification = root.createEvent<number>();
export const deleteAllBannerNotifications = root.createEvent();

export const $banners = root.createStore<Banner[]>([], { sid: 'banners' })
    .on(createBannerNotification, (state, newBanner) => [...state, newBanner])
    .on(deleteBannerNotification, (state, bannerId) => state.filter(({ id }) => id !== bannerId))
    .reset(deleteAllBannerNotifications);
