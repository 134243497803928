import * as t from 'io-ts';

import { GENDER_TYPE } from 'components/TrainCarriage/TrainCarriage.utils/constants';
import { nullable } from 'utils/io-ts';

const TYCarriagePlace = t.intersection([
    t.UnknownRecord,
    t.type({
        number: t.number,
        geometry: t.type({
            top: t.number,
            left: t.number,
        }),
        groupNumber: t.number,
    }),
], 'TYCarriagePlace');

const CoachGender = t.keyof({
    [GENDER_TYPE.MIXED]: null,
    [GENDER_TYPE.MALE]: null,
    [GENDER_TYPE.FEMALE]: null,
    [GENDER_TYPE.SINGLE]: null,
});

const TYCoachPlace = t.intersection([
    t.UnknownRecord,
    t.type({
        adultNonRefundableTariff: nullable(t.boolean),
        adultTariff: t.type({
            currency: t.string,
            value: t.number,
        }),
        beddingTariffWithFee: t.type({
            currency: t.string,
            value: t.number,
        }),
        childNonRefundableTariff: nullable(t.boolean),
        childTariff: t.type({
            currency: t.string,
            value: t.number,
        }),
        gender: nullable(CoachGender),
        number: t.number,
        genderTipUp: nullable(t.boolean),
    }),
], 'TYCarriagePlace');

const TYCoach = t.intersection([
    t.UnknownRecord,
    t.type({
        number: t.string,
        schemaId: nullable(t.number),
        places: t.array(TYCoachPlace),
    }),
], 'TYCoach');

const TYCarriageSchema = t.intersection([
    t.UnknownRecord,
    t.type({
        id: t.number,
        width: t.number,
        height: t.number,
        svg: t.string,
        places: t.array(TYCarriagePlace),
        placeFlags: t.UnknownRecord,
        hidePlaceNumbers: t.boolean,
        url: t.string,
    }),
], 'TYCarriageSchema');

export const TYTrainDetails = t.type({
    trainDetails: t.intersection([
        t.UnknownRecord,
        t.type({
            coaches: t.array(TYCoach),
            schemas: t.array(TYCarriageSchema),
        }),
    ]),
}, 'TYTrainDetails');

export type TYTrainDetails = t.TypeOf<typeof TYTrainDetails>;
export type TYCoach = t.TypeOf<typeof TYCoach>;
export type TYCarriageSchema = t.TypeOf<typeof TYCarriageSchema>;
export type TYCarriagePlace = t.TypeOf<typeof TYCarriagePlace>;
export type TYCoachPlace = t.TypeOf<typeof TYCoachPlace>;
