import { FC } from 'react';

import { Bubble } from 'components/Bubble';
import { ListItem } from 'shared/ui/ListItem';

import { cn } from './PersonListItem.cn';
import { PersonListItemProps } from './PersonListItem.types';

import './PersonListItem.css';

/**
 * Блок пользователя в списке
 */
export const PersonListItem: FC<PersonListItemProps> = props => {
    const {
        className,
        trailing,
        login = '',
        leadingCaption,
        embed,
        name,
        showMark,
        external,
        onClick,
        clickable,
        border,
        expanded,
        transparent,
        padding,
        personId,
        noVerticalPadding,
    } = props;

    const leading = (
        <Bubble
            bubble={{
                type: 'person',
                login,
                name,
                personId: String(personId),
                ...(showMark && { mark: 'dash' }),
            }}
            className={cn('Avatar')}
            type={external ? 'external' : 'person'}
        />
    );

    return (
        <ListItem
            border={border}
            className={cn(null, [className])}
            clickable={clickable}
            embed={embed}
            expanded={expanded}
            leading={leading}
            leadingTextParams={{
                label: name,
                caption: leadingCaption || login,
            }}
            noVerticalPadding={noVerticalPadding}
            padding={padding}
            trailing={trailing}
            transparent={transparent}
            onClick={onClick}
        />
    );
};

PersonListItem.displayName = cn();
