import { useEffect } from 'react';
import { useSwiper } from 'swiper/react';
import useResizeObserver from 'use-resize-observer';

export const useSwiperAutoHeight = () => {
    const { ref, height } = useResizeObserver();

    const swiper = useSwiper();

    useEffect(() => {
        swiper.updateAutoHeight();
    }, [swiper, height]);

    return { ref };
};
