import { FC } from 'react';
import { Composition } from '@bem-react/core';
import { Icon } from '@yandex-lego/components/Icon';

import { IconProps } from './Icon.types';

export function getComposedIcon<T extends string>(hoc: Composition<{ glyph?: T }>, glyph: T): FC<IconProps> {
    const Component = hoc(Icon);

    return props => <Component {...props} glyph={glyph} />;
}
