import { FC } from 'react';

import { AppLink } from 'components/AppLink';

import { cn } from './Card.cn';
import { CardProps } from './Card.types';
import { Header } from './Header';

import './Card.css';

export const Card: FC<CardProps> = props => {
    const {
        title,
        description,
        size = 'm',
        href,
        children,
        className,
        dataTestId,
        onClick,
    } = props;

    const withLayout = Boolean(title) || Boolean(description);

    const header = withLayout ?
        <Header
            description={description}
            size={size}
            title={title}
        /> : null;

    const content = withLayout ? (
        <div className={cn('Content')}>
            {children}
        </div>
    ) : <>{ children }</>;

    if (href) {
        return (
            <AppLink className={cn({ clickable: true, withLayout }, [className])} data-testId="app-link" href={href}>
                {header}
                {content}
            </AppLink>
        );
    }

    return (
        <div
            className={cn({ withLayout }, [className])}
            data-testid={dataTestId}
            onClick={onClick}
        >
            {header}
            {content}
        </div>
    );
};

Card.displayName = cn();
