import { ComponentProps, FC, ReactElement } from 'react';
import { DateRange } from 'react-day-picker';
import { withBemMod } from '@bem-react/core';

import { RangeDatePicker } from 'components/DatePicker';

import { cn } from '../FormField.cn';
import { FormFieldProps as BaseProps } from '../FormField.types';

type RangeDatePickerProps = ComponentProps<typeof RangeDatePicker>;

export type WithTypeRangeDatePickerProps = {
    type?: 'rangepicker';
    onChange?: (value: DateRange | undefined) => void;
    name?: string;
    state?: Maybe<'error'>;
} & Partial<RangeDatePickerProps>;

export const withTypeRangePicker = withBemMod<WithTypeRangeDatePickerProps, BaseProps>(
    cn(),
    { type: 'rangepicker' },
    (Base): FC<BaseProps & WithTypeRangeDatePickerProps> => (props): ReactElement => {
        const {
            onChange,
            value,
            name,
            state,
            error,
            onBlur,
            leftInputPlaceholder = '',
            rightInputPlaceholder = '',
            ...restRangeDatePickerProps
        } = props;

        const appendAfter = (
            <RangeDatePicker
                {...restRangeDatePickerProps}
                leftInputPlaceholder={leftInputPlaceholder}
                rightInputPlaceholder={rightInputPlaceholder}
                state={error ? 'error' : undefined}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
            />
        );

        return (
            <Base
                { ...props }
                appendAfter={appendAfter}
            />
        );
    },
);
