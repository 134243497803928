import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { FieldSuggest, FormProvider } from 'components/FormField2';

import { cn } from '../FormTravelPolicy.cn';
import { i18n } from '../FormTravelPolicy.i18n';

import { DirectionFormFields, DirectionSuggestProps } from './DirectionSuggest.types';

import './DirectionSuggest.css';

const directionTypes = {
    country: 'countries',
    city: 'cities',
} as const;

export const DirectionSuggest: FC<DirectionSuggestProps> = props => {
    const { type, value, dataTestId, onChange } = props;

    const methods = useForm<DirectionFormFields>({
        defaultValues: {
            ...(value && value.id && {
                direction: {
                    id: value.id,
                    ...(value.name && { name: value.name }),
                    ...(value.title && { title: value.name }),
                },
            }),
        },
    });

    useEffect(() => {
        const subscription = methods.watch(({ direction }) => {
            if (direction) {
                onChange(direction);
            }
        });

        return () => subscription.unsubscribe();
    }, [methods, onChange]);

    return (
        <FormProvider methods={methods}>
            <FieldSuggest
                className={cn('DirectionSuggest')}
                data-testId={dataTestId}
                name="direction"
                placeholder={i18n(`suggest_${type}_placeholder`)}
                size="s"
                type={directionTypes[type]}
            />
        </FormProvider>
    );
};
