import { FC, useCallback, useContext } from 'react';
import { withBemMod } from '@bem-react/core';

import { PersonListItem } from 'components/PersonListItem';
import { PersonSearchResponse } from 'services/IntraSearchApi';
import { UserResponse } from 'services/SwaggerApi';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../../../SwaggerContext';
import { cn } from '../Suggest.cn';
import { i18n } from '../Suggest.i18n';
import {
    RenderSelectedValue,
    SuggestProps,
} from '../Suggest.types';

export type PersonItem = {
    id: number | string;
    title: string;
    login: string;
};

type FetchPersons = (searchQuery: string) => Promise<PersonItem[]>;

export interface WithTypePersonsProps {
    type?: 'persons';
    companyId?: UserResponse['company_id'];
}

export const withTypePersons = withBemMod<WithTypePersonsProps, SuggestProps>(
    cn(),
    { type: 'persons' },
    (Suggest): FC<WithTypePersonsProps & SuggestProps> => props => {
        const { companyId, ...suggestProps } = props;
        const {
            placeholder = i18n('persons_placeholder'),
        } = suggestProps;

        const { api } = useContext(SwaggerContext);

        const dataprovider = useCallback<FetchPersons>(async text => {
            const { people } = await api.persons_suggest({
                text,
                company_id: companyId,
            }).then(throwHTTPErrors) as PersonSearchResponse;

            const result = people?.result ?? [];

            return result.map(person => {
                return {
                    id: person.staff_id,
                    title: person.title,
                    login: person.login,
                };
            });
        }, [api, companyId]);

        const renderTagOptionContent = useCallback(item => {
            const itemTyped = item as PersonItem;

            return (
                <PersonListItem
                    noVerticalPadding
                    transparent
                    login={itemTyped.login}
                    name={itemTyped.title}
                    personId={String(itemTyped.id)}
                    size="s"
                />
            );
        }, []);

        const renderSelectedValue = useCallback<RenderSelectedValue>(item => {
            return `${item.title}`;
        }, []);

        return (
            <Suggest
                {...suggestProps}
                dataprovider={dataprovider}
                placeholder={placeholder}
                renderOptionContent={renderTagOptionContent}
                renderSelectedValue={renderSelectedValue}
            />
        );
    },
);
