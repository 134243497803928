import { SearchParamsToFlightFilters } from 'api/models/filters/encoders/SearchParamsToFlightFilters';
import { SearchParamsToHotelFilters } from 'api/models/filters/encoders/SearchParamsToHotelFilters';
import { SearchParamsToTrainFilters } from 'api/models/filters/encoders/SearchParamsToTrainFilters';
import { ACServiceType } from 'services/AeroClubApi/ACServiceType';
import { getQueryParams } from 'utils/getQueryParams';

export function getFilters(type: ACServiceType) {
    const queryParams = getQueryParams();

    switch (type) {
        case 'Avia':
            return SearchParamsToFlightFilters.encode(queryParams);

        case 'Hotel':
            return SearchParamsToHotelFilters.encode(queryParams);

        case 'Rail':
            return SearchParamsToTrainFilters.encode(queryParams);

        default:
            return {};
    }
}
