import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useToggle } from 'react-use';
import { yupResolver } from '@hookform/resolvers/yup';

import { CostCenterModal } from 'components/FormCostCenter';
import {
    FieldCheckbox,
    FieldDate,
    FieldSelect,
    FieldSelectExt,
    FieldSuggest,
    FieldTextinput,
    FormProvider,
} from 'components/FormField2';
import { useCostCenterList } from 'hooks/useCostCenterList';
import { useMeta } from 'hooks/useMeta';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { foldSuccess } from 'utils/Loadable';

import { getCostCenterOptions } from '../../utils/getCostCenterOptions';

import { getServiceTypeOption } from './TransactionForm.utils/getServiceTypeOption';
import { getTransactionStatusOption } from './TransactionForm.utils/getTransactionStatusOption';
import { getTransactionTypeOption } from './TransactionForm.utils/getTransactionTypeOption';
import { cn } from './TransactionForm.cn';
import { i18n } from './TransactionForm.i18n';
import {
    schema,
    serviceTypes,
    transactionStatus,
    transactionTypes,
} from './TransactionForm.schema';
import { TransactionFormFields, TransactionFormProps } from './TransactionForm.types';

import './TransactionForm.css';

export const TransactionForm: FC<TransactionFormProps> = props => {
    const {
        className,
        mode = 'edit',
        defaultValues,
        editableFields,
        companyId,
        isLoading,
        error,
        onSubmit,
        onCancel,
    } = props;

    const methods = useForm<TransactionFormFields>({
        resolver: yupResolver(schema),
        defaultValues,
    });
    const serviceTypeOptions = useMemo(() => serviceTypes.map(getServiceTypeOption), []);
    const transactionTypeOptions = useMemo(() => transactionTypes.map(getTransactionTypeOption), []);
    const transactionStatusOptions = useMemo(() => transactionStatus.map(getTransactionStatusOption), []);

    const { user: { is_coordinator, is_accountant, is_yandex_employee } } = useMeta();

    const showAddCostCenterButton = is_coordinator || is_accountant;
    const showTransactionStatus = (is_coordinator && is_yandex_employee) || is_accountant;

    const isPenalty = methods.watch('is_penalty');

    useEffect(() => {
        if (isPenalty) {
            methods.setValue('fee', 0);
            methods.clearErrors('fee');
        }
    }, [isPenalty, methods]);

    const canEditFee = editableFields.fee && !isPenalty;

    const costCenterList = useCostCenterList(companyId, {});
    const costCenterOptions = foldSuccess(costCenterList, getCostCenterOptions, () => []);

    const [isModalVisible, toggleModalVisible] = useToggle(false);

    return (
        <>
            <FormProvider error={error} methods={methods} schema={schema}>
                <form
                    className={cn(null, [className])}
                    onSubmit={methods.handleSubmit(onSubmit)}
                >
                    <div className={cn('Fieldset')}>
                        <Text
                            as="h4"
                            className={cn('GroupHeading')}
                            typography="body-m"
                        >
                            {i18n('group_heading_service')}
                        </Text>
                        <FieldTextinput
                            disabled={!editableFields.trip_id}
                            inputMode="numeric"
                            label={i18n('trip_id_label')}
                            name="trip_id"
                            placeholder={i18n('trip_id_placeholder')}
                            type="number"
                        />
                        <FieldTextinput
                            disabled={!editableFields.general_service_id}
                            label={i18n('general_service_id_label')}
                            name="general_service_id"
                            placeholder={i18n('general_service_id_placeholder')}
                        />
                        <FieldSuggest
                            className={cn('FieldPerson')}
                            companyId={companyId}
                            disabled={!editableFields.person_id}
                            label={i18n('person_label')}
                            name="person"
                            type="persons"
                        />
                        <FieldDate
                            disabled={!editableFields.execution_date}
                            label={i18n('execution_date_label')}
                            name="execution_date"
                        />
                        <FieldSelect
                            disabled={!editableFields.service_type}
                            label={i18n('service_type_label')}
                            name="service_type"
                            options={serviceTypeOptions}
                            placeholder="Выберите тип услуги"
                        />
                    </div>
                    <div className={cn('Fieldset')}>
                        <Text
                            as="h4"
                            className={cn('GroupHeading')}
                            typography="body-m"
                        >
                            {i18n('group_heading_transaction')}
                        </Text>
                        <FieldDate
                            disabled={!editableFields.invoice_date}
                            label={i18n('invoice_date_label')}
                            name="invoice_date"
                        />
                        <FieldSelect
                            disabled={!editableFields.type}
                            label={i18n('type_label')}
                            name="type"
                            options={transactionTypeOptions}
                        />
                        {showTransactionStatus && <FieldSelect
                            disabled={!editableFields.status}
                            label={i18n('status_label')}
                            name="status"
                            options={transactionStatusOptions}
                        />}
                        <FieldSelectExt
                            addonButtonText={showAddCostCenterButton ? i18n('add_cost_center') : undefined}
                            className={cn('CostCenterField')}
                            disabled={!editableFields.cost_center_id}
                            label={i18n('cost_center')}
                            name="cost_center"
                            options={costCenterOptions}
                            width="max"
                            onAddonButtonClick={toggleModalVisible}
                        />
                        <FieldTextinput
                            disabled={!editableFields.price}
                            inputMode="decimal"
                            label={i18n('price_label')}
                            name="price"
                            placeholder={i18n('price_placeholder')}
                            type="number"
                        />
                        <FieldTextinput
                            disabled={!canEditFee}
                            inputMode="decimal"
                            label={i18n('fee_label')}
                            name="fee"
                            placeholder={i18n('fee_placeholder')}
                            type="number"
                        />
                        <FieldCheckbox
                            checkboxLabel={i18n('is_penalty_label')}
                            disabled={!editableFields.is_penalty}
                            name="is_penalty"
                        />
                    </div>
                    <div className={cn('Controls')}>
                        <Button
                            className={cn('Cancel')}
                            size="m"
                            view="clear"
                            onClick={onCancel}
                        >
                            {i18nAction('cancel')}
                        </Button>
                        <Button
                            className={cn('Submit')}
                            progress={isLoading}
                            size="m"
                            type="submit"
                            view="brand"
                        >
                            {mode === 'edit' ? i18n('action_alter') : i18n('action_create')}
                        </Button>
                    </div>
                </form>
            </FormProvider>
            <CostCenterModal
                companyId={companyId}
                modalViewOnClose={toggleModalVisible}
                modalViewVisible={isModalVisible}
                onCancel={() => toggleModalVisible(false)}
                onSubmit={() => toggleModalVisible(false)}
            />
        </>
    );
};

TransactionForm.displayName = cn();
