import { FC } from 'react';

import { Bubble } from 'components/Bubble';
import { ListItem } from 'shared/ui/ListItem';
import { Text } from 'shared/ui/Text';

import { cn } from './ChiefsDropdownContent.cn';
import { i18n } from './ChiefsDropdownContent.i18n';
import { ChiefsDropdownContentProps } from './ChiefsDropdownContent.types';

import './ChiefsDropdownContent.css';

export const ChiefsDropdownContent: FC<ChiefsDropdownContentProps> = props => {
    const {
        chiefs,
    } = props;

    return (
        <div className={cn()}>
            <Text className={cn('Title')} color="secondary" typography="label-s">
                {i18n('assistant_for')}
            </Text>
            <div className={cn('Items')}>
                {chiefs.map(({ first_name, last_name, login }, index) => (
                    <ListItem
                        key={index}
                        expanded
                        noVerticalPadding
                        transparent
                        leading={
                            <Bubble
                                bubble={{
                                    type: 'person',
                                    login,
                                    name: `${first_name} ${last_name}`,
                                    personId: '',
                                }}
                                className={cn('Avatar')}
                                size="l"
                                type="person"
                            />
                        }
                        leadingTextParams={{
                            label: `${first_name} ${last_name}`,
                            caption: login,
                        }}
                    />
                ))}
            </div>
        </div>
    );
};

ChiefsDropdownContent.displayName = cn();
