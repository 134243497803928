export const en = {
    checkin_date: 'Check-in date',
    checkout_date: 'Checkout date',
    city_from: 'From',
    city_to: 'To',
    date_from: 'Departure',
    date_to: 'Back',
    flight_tickets: 'Flights',
    hotels: 'Hotels',
    train_tickets: 'Trains',
    press_search: 'Click to see results',
    search: 'Search',
    passengers_title: 'Number of passengers',
    avia_class_title: 'Class selection',
    adult: 'Adults',
    adult_caption: 'older than 12 years',
    adult_persons: {
        many: '{count} adults',
        one: '{count} adult',
        some: '{count} adults',
    },
    adult_passengers: {
        many: '{count} passengers',
        one: '{count} passenger',
        some: '{count} passengers',
    },
};
