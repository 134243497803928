import { useAviaSearchResults } from './useAviaSearchResults';
import { useHotelSearchResults } from './useHotelSearchResults';
import { useRailSearchResults } from './useRailSearchResults';
import { SearchFilters } from './useSearchResults';

export const useSearchOptionResultListMethod = (query: SearchFilters) => {
    const { type } = query;

    switch (type) {
        case 'Avia':
            return useAviaSearchResults;

        case 'Hotel':
            return useHotelSearchResults;

        case 'Rail':
            return useRailSearchResults;
    }
};
