import { i18nFileSize } from 'i18n/i18nFileSize';

type FormatBytesOptions = {
    fractionDigits?: number;
};

type FormatBytes = (bytes: number | string, options?: FormatBytesOptions) => string;

/**
 * Преобразует байты в читаемый размер файла
 *
 *  @example
 * ```tsx
 * const formattedBytes = formatBytes(5000000) // => '4.8 МБ'
 * ```
 */
export const formatBytes: FormatBytes = (bytes, options = {}) => {
    const {
        fractionDigits = 1,
    } = options;

    const bytesNumber = Number(bytes);

    const units = ['b', 'kb', 'mb', 'gb', 'tb'] as const;

    if (bytes === 0) {
        return `0 ${i18nFileSize(units[0])}`;
    }

    const bytesPower = Math.floor(Math.log(bytesNumber) / Math.log(1024));
    const unitIndex = Math.min(bytesPower, units.length - 1);

    const bytesFormatted = (bytesNumber / (1024 ** unitIndex)).toFixed(fractionDigits);
    const unitsFormatted = i18nFileSize(units[unitIndex]);

    return `${bytesFormatted} ${unitsFormatted}`;
};
