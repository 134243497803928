export const en = {
    add: 'Add',
    apply: 'Apply',
    back: 'Back',
    cancel: 'Cancel',
    change_seat: 'Change seat',
    confirm: 'Confirm',
    continue: 'Continue',
    download_attachments: 'Download attachments',
    execute: 'Book all',
    export: 'Export',
    reset: 'Reset',
    remove: 'Remove',
    remove_service: 'Remove service',
    retry: 'Retry',
    save: 'Save',
    save_changes: 'Save changes',
    select: 'Select',
    send: 'Send',
    copied: 'Copied',
    open_details: 'View data',
    close: 'Close',
    yes: 'Yes',
    no: 'No',
    yes_delete: 'Yes, delete',
    yes_replace: 'Yes, replace',
    no_leave: 'No, leave',
    refresh_page: 'Refresh the page',
    chat_with_support: 'Contact support',
    to_the_main_page: 'To the main page',
    yes_recall: 'Yes, recall',
    recall: 'Recall',
};
