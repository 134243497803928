import { config } from 'config';

const METHODS_TO_ADD_CSRF = new Set(['POST', 'PATCH', 'PUT', 'DELETE']);

type FetchHeaders = {
    'X-Client-Version': string;
    'X-Client-Session-ID': string;
    'X-Request-ID': string;
    'X-Csrf-Token'?: string;
    Accept?: string;
    'Content-Type'?: string;
};

/**
 * Функция возвращает заголовки для запроса
 * @param requestId Уникальный id запроса
 * @param method Метод запроса
 * @param payload Тело запроса
 * @returns Заголовки запроса
 */
function getHeaders<TPayload>(
    requestId: string,
    method: string,
    payload: TPayload,
): FetchHeaders {
    const headers: FetchHeaders = {
        'X-Client-Version': config.version,
        'X-Client-Session-ID': window.SESSION_ID,
        'X-Request-ID': requestId,
    };

    if (METHODS_TO_ADD_CSRF.has(method)) {
        headers['X-Csrf-Token'] = window.CSRF_TOKEN;
    }

    if (payload) {
        const hasFile = Object.values(payload)
            .some(item => item instanceof File);

        // В случае отправки файла, payload отправляется в формате FormData.
        // Заголовок Content-Type в таком случае выставится автоматически в multipart/form-data;
        // вместе со сгенерированными значениями boundary для каждого файла
        if (!hasFile) {
            headers['Content-Type'] = 'application/json';
        }

        headers.Accept = 'application/json';
    }

    return headers;
}

type GetRequestOptionsProps<TPayload> = {
    /**Метод запроса */
    method: string;
    /**Тело запроса */
    payload: TPayload;
    /**Уникальный id запроса */
    requestId: string;
    /**Опции запроса */
    options?: RequestInit;
};

/**
 * Преобразует тело запроса в объект типа FormData
 * @param payload Тело запроса
 * @returns объект FormData
 */
const payloadToFormData = <T extends Object>(payload: T) => {
    const formData = new FormData();

    Object.entries(payload).forEach(([key, value]) => {
        if (value instanceof Blob) {
            formData.append(key, value);
        } else {
            formData.append(key, JSON.stringify(value));
        }
    });

    return formData;
};

/**
 * Функция возвращает обогащенные полученные опции запроса заголовками и body
 * @param props Метод тело и id запроса
 * @returns Обогащенные опции запроса
 */
export function getRequestOptions<TPayload>(
    {
        method,
        payload,
        requestId,
        options,
    }: GetRequestOptionsProps<TPayload>): RequestInit {
    const credentials: RequestCredentials = 'same-origin';
    const headers = {
        ...getHeaders(requestId, method, payload),
        ...options?.headers,
    };

    const optionsToSend = {
        ...options,
        credentials,
        method,
        headers,
    };

    if (payload) {
        const hasFile = Object.values(payload)
            .some(item => item instanceof File);

        if (hasFile) {
            optionsToSend.body = payloadToFormData(payload);
        } else {
            optionsToSend.body = JSON.stringify(payload);
        }
    }

    return optionsToSend;
}
