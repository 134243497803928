import { LocalizedString } from 'services/SwaggerApi';
import { getTranslation } from 'utils/getTranslation';

type GetTranslatedRouteCities = (...cities: LocalizedString[]) => string;

export const getTranslatedRouteCities: GetTranslatedRouteCities = (...cities) => {
    return cities.map(city => getTranslation(city, { fallbackToTranslit: true }))
        .map(city => city.split(',')[0])
        .filter((city, i) => city !== '' && city !== cities[i - 1])
        .join(' — ');
};

type GetRouteCities = (...cities: (string | null | undefined)[]) => string;

export const getRouteCities: GetRouteCities = (...cities) => {
    return cities.filter((city, i) => city !== '' && city !== cities[i - 1])
        .join(' — ');
};
