import { ServiceDetails } from 'types/TripRoute';

export const getOrderCity = (order: ServiceDetails, isDestination = false): string | undefined => {
    if (isDestination) {
        switch (order.service_type) {
            case 'avia':
                const flightSegments = order.flight.legs?.[0].segments;

                return flightSegments?.[flightSegments.length - 1].arrival.city?.name;

            case 'rail':
                return order.train.arrival?.city?.name || order.train.arrival?.train_station?.name;

            case 'hotel':
                return order.location_name;
        }
    }

    switch (order.service_type) {
        case 'avia':
            return order.flight.legs?.[0].segments[0].departure.city?.name;

        case 'rail':
            return order.train.departure?.city?.name || order.train.departure?.train_station?.name;

        case 'hotel':
            return order.location_name;
    }
};
