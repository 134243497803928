import { FC, Fragment, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import dayjs from 'dayjs';
import arrayMutators from 'final-form-arrays';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { FieldDatePicker, FieldTextinput } from 'components/FormField/FormField.providers';
import { usePersonTripPatch } from 'hooks/usePersonTripPatch';
import { RoutePoint, RoutePointCreate } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';
import { validateRequiredField } from 'utils/form/validateRequiredField';
import { isLoading } from 'utils/Loadable';
import { serializeDate } from 'utils/serializeDate';

import { cn } from './ViewPersonTripChangeDate.cn';
import { i18n } from './ViewPersonTripChangeDate.i18n';
import { ViewPersonTripChangeDateProps } from './ViewPersonTripChangeDate.types';

import './ViewPersonTripChangeDate.css';

export const ViewPersonTripChangeDate: FC<ViewPersonTripChangeDateProps> = props => {
    const { className, onCancel, onSubmit, tripId, personTrip } = props;
    const { route } = personTrip;
    const [patch, patchState] = usePersonTripPatch({ onSuccess: onSubmit });

    const handleSubmit = useCallback(({ route }: { route: (RoutePoint & {date: Date | string})[]}) => {
        const updatedRoute: RoutePointCreate[] = route.map(point => {
            if (!point.geoid) {
                return null;
            }

            return ({
                geoid: point.geoid,
                date: (typeof point.date === 'string') ? point.date : serializeDate(point.date),
                city: point.city.name.ru!,
                country: point.city.name.ru!,
            });
        }).filter(Boolean);

        patch(tripId, personTrip.person!.person_id, { route: updatedRoute });
    }, [patch, tripId, personTrip]);

    const getForm = useCallback(({
        handleSubmit,
        progress,
        values,
    }) => {
        const minDate = dayjs(route?.[0].date).isAfter(dayjs()) ? new Date() : new Date(route?.[0].date || '');

        return (
            <form className={cn(null, [className])} onSubmit={handleSubmit}>
                <Text className={cn('ChangeDateFormTitle')} typography="title-s">
                    {i18n('input_new_dates')}
                </Text>

                <FieldArray name="route">
                    {({ fields }) => fields.map((name, index) =>
                        <Fragment key={name}>
                            <Field
                                disabled
                                className={cn('Field', [cn('Point')])}
                                component={FieldTextinput}
                                name={`${name}.city.name.ru`}
                            />
                            <Field
                                component={FieldDatePicker}
                                fromDate={index === 0 ?
                                    minDate :
                                    new Date(values.route[index - 1].date)
                                }
                                inputClassName={cn('Field', [cn('Date')])}
                                name={`${name}.date`}
                                placeholder={i18n('placeholder_date')}
                                scope="inplace"
                                toDate={new Date(values.route[index + 1]?.date)}
                                validate={validateRequiredField}
                            />
                        </Fragment>,
                    )}
                </FieldArray>

                <div className={cn('ControlsContainer')}>
                    <ButtonPlatform
                        className={cn('Cancel')}
                        view="default"
                        onClick={onCancel}
                    >
                        {i18n('cancel')}
                    </ButtonPlatform>
                    <ButtonPlatform
                        className={cn('Submit')}
                        progress={progress}
                        type="submit"
                        view="brand"
                    >
                        {i18n('save')}
                    </ButtonPlatform>
                </div>
            </form>
        );
    }, [className, onCancel, route]);

    return (
        <Form
            initialValues={{ route }}
            mutators={{ ...arrayMutators }}
            progress={isLoading(patchState)}
            render={getForm}
            onSubmit={handleSubmit}
        />
    );
};

ViewPersonTripChangeDate.displayName = cn();
