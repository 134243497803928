import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_caption-s.css';

export type WithTypographyCaptionSProps = {
    typography?: 'caption-s';
};

export const withTypographyCaptionS = withBemMod<WithTypographyCaptionSProps>(
    cn(),
    { typography: 'caption-s' },
);
