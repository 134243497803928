import { ComponentType } from 'react';
import { IClassNameProps } from '@bem-react/core';

export function withPropsV2<T extends IClassNameProps>(
    WrappedComponent: ComponentType<T>,
    initialProps: Partial<T>,
    className: string | null = null,
): ComponentType<T> {
    return remainingProps => {
        const {
            className: remainingPropsClassName,
            ...remainingPropsWithoutClassname
        } = remainingProps;
        const props = { ...remainingPropsWithoutClassname, ...initialProps } as unknown as T;
        const componentClassName = [className, remainingPropsClassName].join(' ').trim();

        return <WrappedComponent className={componentClassName} {...props} />;
    };
}
