import { Decoder } from 'io-ts/Decoder';
import * as z from 'zod';

import { RouteParams } from 'types/Route';
import { defaultRouteOptions, RouteOptions } from 'types/RouteOptions';
import { RoutePathParams } from 'types/RoutePathParams';
import { SomePartial } from 'types/SomePartial';

import { generatePathTyped } from './generatePathTyped';

export function route<P extends RoutePathParams, S extends {}>(
    path: string,
    decoder: Decoder<unknown, RouteParams<P, S>> | z.ZodType<RouteParams<P, S>, z.ZodTypeDef, unknown>,
    routeOptions: SomePartial<RouteOptions, 'checkAccess'>,
) {
    return {
        path,
        decoder,
        exact: true,
        getPath: (params: P, search: S) => generatePathTyped(path, params, search, decoder),
        routeOptions: { ...defaultRouteOptions, ...routeOptions },
    };
}
