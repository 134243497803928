import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_caption-m.css';

export type WithTypographyCaptionMProps = {
    typography?: 'caption-m';
};

export const withTypographyCaptionM = withBemMod<WithTypographyCaptionMProps>(
    cn(),
    { typography: 'caption-m' },
);
