import { FetchError } from 'errors';
import { GroupDetailResponse } from 'services/SwaggerApi';
import { RemoteData } from 'utils/Loadable';

import { createUseQueryHook } from './createUseQueryHook';

type UseGroupDetails = (group_id: number) => RemoteData<GroupDetailResponse, FetchError>;

export const useGroupDetails: UseGroupDetails = group_id => {
    return createUseQueryHook('get_group_details', {
        cacheTime: Infinity,
    })(group_id);
};
