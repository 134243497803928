import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_title-m.css';

export type WithTypographyTitleMProps = {
    typography?: 'title-m';
};

export const withTypographyTitleM = withBemMod<WithTypographyTitleMProps>(
    cn(),
    { typography: 'title-m' },
);
