import { ProviderSegment } from 'services/SwaggerApi';

export const getRouteDuration = (segments: ProviderSegment[]): string => {
    const { departure_at_utc } = segments[0];
    const { arrival_at_utc } = segments[segments.length - 1];

    const dateA = new Date(departure_at_utc || 0);
    const dateB = new Date(arrival_at_utc || 0);

    const totalMinutes = (dateB.valueOf() - dateA.valueOf()) / 1000 / 60;
    const minutes = totalMinutes % 60;
    const hours = (totalMinutes - minutes) / 60;
    const duration = `${hours}:${minutes}:00`;

    return duration;
};
