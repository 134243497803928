import { config } from 'config';
import { SwaggerApi, UserResponse } from 'services/SwaggerApi';
import { reachGoal } from 'shared/lib/metrika/reachGoal';

import { getNonPersonalUserData } from './getNonPersonalUserData';

export function sendMetrikaGoal<
    K extends keyof SwaggerApi,
    TArgs extends Parameters<SwaggerApi[K]>,
>(
    meta: UserResponse,
    methodName: K,
    ...args: TArgs
) {
    if (config.env !== 'production') {
        return;
    }

    const params: Record<string, unknown> = {};

    function addParams <
        K extends keyof SwaggerApi,
        TArgs extends Parameters<SwaggerApi[K]>,
    >(key: K, adder: (...args: TArgs) => void) {
        if (methodName as string !== key as string) {
            return;
        }

        adder(...args as unknown as TArgs);
    }

    const addKeys = <T>(from: T, keys: (keyof T)[]) => {
        for (const key of keys) {
            params[key as string] = from[key];
        }
    };

    addParams('trip_create', payload => {
        const routeGeoIds = (payload.route || []).map(point => point.geoid).filter(Boolean);

        params.route_geo_ids = routeGeoIds;
        params.route_length = routeGeoIds.length;

        params.route_cities = (payload.route || []).map(point => point.city);

        addKeys(payload, [
            'city_from', 'city_from_geoid', 'city_to', 'city_to_geoid',
        ]);
    });

    addParams('create_avia_search', (_, payload) => addKeys(payload, [
        'class_avia', 'from_geoid', 'to_geoid',
    ]));

    addParams('create_rail_search', (_, payload) => addKeys(payload, [
        'from_geoid', 'to_geoid',
    ]));

    addParams('create_hotel_search', (_, payload) => addKeys(payload, [
        'target_geoid',
    ]));

    addParams('service_create', payload => addKeys(payload, [
        'type', 'overprice_workflow',
    ]));

    addParams('person_trip_partial_update', payload => {
        // отправим список ключей, по ним можно понять что менялось
        params.payload_keys = Object.keys(payload);
    });

    const nonPersonalUserData = getNonPersonalUserData(meta);

    reachGoal(methodName, { params, meta: nonPersonalUserData });
}
