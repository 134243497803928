import { FC, useCallback } from 'react';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { Card } from 'components/Card';
import { EmployeesGroupCard } from 'components/EmployeesGroupCard';
import { ScrollButton } from 'components/ScrollButton';
import { withRemote } from 'hocs/withRemote';
import { useGroupsInfinite } from 'hooks/useGroupsInfinite';
import { RouteGroupAdd } from 'routes/RouteGroupAdd';
import { Button } from 'shared/ui/Button';
import { ErrorState } from 'shared/ui/ErrorState';

import { EmptyState } from '../EmptyState';
import { cn } from '../PageCompanies.cn';
import { i18n } from '../PageCompanies.i18n';

import { GroupsListProps, TabGroupsProps } from './TabGroups.types';

import './TabGroups.css';

const GroupsListView = withRemote(({ result }: GroupsListProps) => {
    const isEmpty = result.items.length === 0;

    const newGroupUrl = RouteGroupAdd.getPath({}, {});

    if (isEmpty) {
        return (
            <Card className={cn('TabGroupsEmptyState')}>
                <ErrorState
                    actions={[
                        <Button
                            key="create"
                            className={cn('AddButton')}
                            size="l"
                            type="link"
                            url={newGroupUrl}
                            view="primary"
                        >
                            {i18n('add_group')}
                        </Button>,
                    ]}
                    title={i18n('groups_not_added')}
                />
            </Card>
        );
    }

    return (
        <div className={cn('TabGroupsList')}>
            {result.items.map(group => (
                <EmployeesGroupCard key={group.group_id} group={group} />
            ))}
        </div>
    );
});

export const TabGroups: FC<TabGroupsProps> = () => {
    const [groups, { isFetchingNextPage, fetchNextPage, hasNextPage }] = useGroupsInfinite({
        limit: 20,
    });

    const handleFetchMore = useCallback(() => fetchNextPage(), [fetchNextPage]);

    return (
        <div className={cn('TabGroups')}>
            <GroupsListView spinner fallbackElement={<EmptyState />} result={groups} />
            {hasNextPage && (
                <ButtonPlatform progress={Boolean(isFetchingNextPage)} onClick={handleFetchMore}>
                    {i18n('show_more')}
                </ButtonPlatform>
            )}
            <ScrollButton />
        </div>
    );
};
