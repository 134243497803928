import { FC, useCallback, useRef, useState } from 'react';
import { Divider } from '@yandex-lego/components/Divider';

import { IPopupProps } from 'shared/ui/Popup';
import { PopupPlatform } from 'shared/ui/PopupPlatform';

import { cn } from './ActionsMenu.cn';
import { ActionsMenuProps } from './ActionsMenu.types';
import { Option } from './Option';

import './ActionsMenu.css';

const popupDirections: IPopupProps['direction'] = ['left-start', 'bottom-end', 'top-end'];

export const ActionsMenu: FC<ActionsMenuProps> = props => {
    const {
        menu,
        additionalMenu = [],
        scope,
        direction = popupDirections,
        trigger,
        disabled,
        className,
    } = props;

    const anchorRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);

    const togglePopup = useCallback(() => setVisible(() =>
        disabled ? false : !visible,
    ), [disabled, visible]);

    const additionalMenuElem = additionalMenu.length > 0 ? (
        <>
            {menu.length > 0 && <Divider className={cn('AdditionalMenuDivider')} />}
            {additionalMenu.map((option, index) => (
                <Option
                    key={index}
                    className={option.className}
                    hidePopup={togglePopup}
                    option={option}
                />
            ))}
        </>
    ) : null;

    return (
        <div className={cn({ disabled }, [className])}>
            <div
                ref={anchorRef}
                className={cn('Trigger')}
                data-testid="actions-menu-trigger"
                onClick={togglePopup}
            >
                {trigger}
            </div>
            <PopupPlatform
                anchor={anchorRef}
                className={cn('Popup')}
                data-testid="actions-menu"
                keepMounted={false}
                popupDirection={direction}
                scope={scope}
                target="anchor"
                view="default"
                visible={visible}
                onClose={togglePopup}
            >
                <div
                    className={cn('Options')}
                    data-testid="actions-menu-options"
                >
                    {menu.map((option, index) => (
                        <Option
                            key={index}
                            className={option.className}
                            hidePopup={togglePopup}
                            option={option}
                        />
                    ))}
                    {additionalMenuElem}
                </div>
            </PopupPlatform>
        </div>
    );
};
