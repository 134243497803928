import * as z from 'zod';

import { ActivePage } from 'shared/constants/ActivePage';

import { route } from './route';

export const RouteCompanionDecoder = z.object({
    params: z.object({
        extPersonId: z.string(),
    }),
    search: z.object({
        modal: z.optional(z.enum(['add_companion_document'])),
    }),
});

export type RouteCompanion = z.output<typeof RouteCompanionDecoder>;

export const RouteCompanion = route(
    '/companions/:extPersonId',
    RouteCompanionDecoder,
    {
        activePage: ActivePage.PROFILE,
    },
);
