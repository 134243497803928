import { FC } from 'react';

import { Card } from 'components/Card';
import { withRemote } from 'hocs/withRemote';
import { Text } from 'shared/ui/Text';

import { cn } from '../HotelDetails.cn';
import { i18n } from '../HotelDetails.i18n';

import { getColorChip } from './getColorChip';
import { RatingProps } from './Rating.types';
import { Stats } from './Stats';
import { StatsSkeleton } from './StatsSkeleton';

import './Rating.css';

const StatsRemote = withRemote(Stats);

export const Rating: FC<RatingProps> = props => {
    const {
        className,
        count_reviews,
        average_rating,
        reviews,
    } = props;

    const colorChip = getColorChip(average_rating);

    return (
        <Card className={cn('RatingAndStats', [className])}>
            <div className={cn('Rating')}>
                <div className={cn('LeadingText')}>
                    <Text as="div" typography="title-s" weight="medium">
                        {i18n('rating')}
                    </Text>
                    <Text color="secondary" typography="body-s">
                        {i18n('based_on_reviews', { count: count_reviews })}
                    </Text>
                </div>
                <div className={cn('Chip', { color: colorChip })}>
                    <Text typography="title-s">
                        {average_rating.toFixed(1)}
                    </Text>
                </div>
            </div>
            {reviews && (
                <StatsRemote
                    reviews={reviews}
                    skeleton={<StatsSkeleton />}
                />
            )}
        </Card>
    );
};
