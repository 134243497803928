export const en = {
    add_route_point: 'Route',
    remove_route_point: 'Remove',
    make_complex_route: 'Create a complex route',
    complex_route: 'Complex route',
    route: 'Route',
    trip_date: 'Route date',
    trip_dates: 'Route dates',
    find: 'Find',
    who: 'Who',
    goal: 'Goal',
    'choose-goal': 'Choose goal',
    trip: 'Business trip',
    conf: 'Conference',
    'error-departure-lt': 'Departure date should be less than Back date',
    'error-back-gt': 'Back date should be greater than Departure date',
    save: 'Create application',
    inside_conf: 'Inside Conference',
    outside_conf: 'Outside Conference',
    meeting_trip: 'Work meeting with colleagues',
    business_talk_trip: 'Business negotiation',
    recruiting_trip: 'Recruiting',
    engineer_work_trip: 'Engineering and installation works',
    committee_directors_trip: 'Committee of Directors',
    production_trip: 'Production',
    cancel: 'Cancel',
    add_participant: 'Add participant',
    comment_placeholder: 'Any additional information that will be useful for making an application',
    comment: 'Comment',
    conf_another_city: 'Do you need a business trip to this conference?',
    conf_dates: 'Dates of the conference',
    conf_date_from_placeholder: 'Start',
    conf_date_to_placeholder: 'End',
    conf_details: 'Conference details',
    conf_name: 'Conference name',
    conf_name_placeholder: 'Enter the name',
    conf_url: 'Conference website',
    conf_url_placeholder: 'Enter the link to website',
    conf_city: 'The city of the conference',
    conf_city_placeholder: 'For example, Moscow',
    conf_city_hint: 'Leave the field empty if the conference will be online',
    program_url: 'Event program',
    program_url_placeholder: 'Link to the website with the program',
    participation_terms: 'Reason for participation in the conference',
    participation_terms_placeholder: "Why you should to participate this conference, what's the profit",
    conf_payment_type_title: 'Terms of your participation in the conference',
    conf_payment_type_paid: 'Paid',
    conf_payment_type_paid_hint: 'If you need to buy a ticket or get a compensation',
    conf_payment_type_free: 'Free',
    conf_payment_type_free_hint: 'If you participate for free or compensation for the ticket is not needed',
    conf_payment_need_help: 'I need help registering for a conference',
    conf_price: 'Price of participation per person',
    conf_price_placeholder: 'Enter the price',
    conf_ticket_type: 'Ticket type',
    conf_ticket_type_placeholder: 'For example, Standard, VIP and so on',
    conf_promocode: 'Discount promo code',
    conf_promocode_placeholder: 'Enter the promo code',
    conf_payment_need_compensation: 'I have already bought a ticket, I need compensation',
    departure_city: 'City',
    departure_date: 'Date',
    description: 'Trip description',
    participants: 'Participants',
    risk_acceptance: 'I understand that the incidence of coronavirus infection is on the rise again, and the company does not recommend traveling unless absolutely necessary',
    trip_details: 'Trip details',
    why_you_go: 'Why are you going to the trip',
    city_from: 'From city',
    city_to: 'To city',
    date_there: 'Date there',
    date_back: 'Date back',
    date_when: 'Date when',
    listener: 'Listener',
    speaker: 'Speaker',
    other_role: 'Another role',
    other_role_placeholder: 'Specify the role at the conference',
    presentation_topic: 'Presentation topic',
    presentation_topic_placeholder: 'Specify the topic of the report',
    is_pr_approved: 'The presentation was approved by the PR-service',
    is_pr_approved_description: '{link} all presentations must be coordinated',
    is_pr_approved_description_link: 'According to the rules of the PR-service',
    attendance_options: 'Method of participation in the conference',
    online: 'Online',
    offline: 'Offline',
    pr_group_rules: 'PR group rules',
    for_conference: 'For conference',
    role: 'Conference role',
    name_for_badge: 'Name for badge',
    name_for_badge_placeholder: 'Enter a name',
    position_for_badge: 'Position for badge',
    position_for_badge_placeholder: 'Specify the position',
    is_paid_by_host: 'Participation in the conference is paid by the host party',
    is_paid_by_host_description: 'According to the Rules of Ethics, some gifts and hospitality, including payment for accommodation/relocation, that you receive from external persons, require approval by the Compliance Department. If you think that these signs of hospitality in total cost more than 11,000 rubles, fill in',
    form_for_approval: 'form for approval',
    yes: 'Yes',
    no: 'No',
    need_visa: 'Need visa',
    wishes_and_passport_details: 'Ticket and hotel requests, passport details',
};
