import * as D from 'io-ts/Decoder';

export const ACCarriageTypeDecoder = D.union(
    D.literal('common'),
    D.literal('coupe'),
    D.literal('lux'),
    D.literal('reserved'),
    D.literal('seat'),
    D.literal('vip'),
);
