import * as E from 'io-ts/Encoder';

import { NumberToBooleanInverse } from 'utils/io-ts/encoders/NumberToBooleanInverse';
import { OneToBoolean } from 'utils/io-ts/encoders/OneToBoolean';
import { StringToArray } from 'utils/io-ts/encoders/StringToArray';
import { StringToNumber } from 'utils/io-ts/encoders/StringToNumber';

export const SearchParamsToFlightFilters = E.partial({
    has_baggage: OneToBoolean,
    is_restricted_by_travel_policy: NumberToBooleanInverse,
    is_changeable: StringToArray,
    is_refundable: StringToArray,
    cabin_classes: StringToArray,
    air_companies: StringToArray,
    departure_there_timespan: StringToArray,
    arrival_there_timespan: StringToArray,
    departure_back_timespan: StringToArray,
    arrival_back_timespan: StringToArray,
    departure_from_there: StringToArray,
    arrival_to_there: StringToArray,
    departure_from_back: StringToArray,
    arrival_to_back: StringToArray,
    maximum_transfers_count: StringToNumber,
});
