import { FC } from 'react';

import { FormFindCompany } from 'components/FormFindCompany';
import { Text } from 'shared/ui/Text';

import { cn } from '../Register.cn';
import { i18n } from '../Register.i18n';

import { FindCompanyProps } from './FindCompany.types';

import './FindCompany.css';

export const FindCompany: FC<FindCompanyProps> = props => {
    const {
        onSubmit,
        className,
    } = props;

    return (
        <div className={cn('FindCompany', [className])}>
            <div className={cn('FindCompanyTitle')}>
                <Text as="div" typography="display-s">
                    {i18n('find_company_title')}
                </Text>
                <Text as="div" color="secondary" typography="paragraph-m">
                    {i18n('find_company_description')}
                </Text>
            </div>
            <FormFindCompany
                className={cn('FormFindCompany')}
                onSubmit={onSubmit}
            />
        </div>
    );
};
