import { FC } from 'react';

import { Bubble } from 'components/Bubble';
import { BubbleParticipantPerson } from 'components/Bubble/Bubble.interface';
import { Text } from 'shared/ui/Text';

import { cn } from './UserPane.cn';
import { UserPaneProps } from './UserPane.interface';

import './UserPane.css';

export const UserPane: FC<UserPaneProps> = props => {
    const { login, fullName, personId } = props;

    const participant: BubbleParticipantPerson = {
        type: 'person',
        login,
        name: fullName,
        personId,
    };

    return (
        <div className={cn()}>
            <Bubble bubble={participant} type="person" />
            <div className={cn('Info')}>
                <Text
                    className={cn('Name')}
                    typography="body-s"
                >
                    {fullName}
                </Text>
                <Text
                    className={cn('Login')}
                    color="secondary"
                    typography="caption-m"
                >
                    {login}
                </Text>
            </div>
        </div>
    );
};
