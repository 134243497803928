import { FC, useCallback, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { Pagination } from '@yandex-int/hr-components/Pagination/desktop/bundle';

import { EmployeeActivateModal } from 'components/EmployeeActivateModal';
import { EmployeeAssignAssistantModal } from 'components/EmployeeAssignAssistantModal';
import { EmployeeBlockModal } from 'components/EmployeeBlockModal';
import { EmployeeChangeApproverModal } from 'components/EmployeeChangeApproverModal';
import { EmployeeChangeCompanyModal } from 'components/EmployeeChangeCompanyModal';
import { EmployeeGrantCoordinatorModal } from 'components/EmployeeGrantCoordinatorModal';
import { EmployeeRejectModal } from 'components/EmployeeRejectModal';
import { EmployeeRemoveAssistantModal } from 'components/EmployeeRemoveAssistantModal';
import { EmployeeRestoreModal } from 'components/EmployeeRestoreModal';
import { EmployeeRevokeCoordinatorModal } from 'components/EmployeeRevokeCoordinatorModal';
import { TableEmployeesHeader } from 'components/TableEmployeesHeader';
import { TableSkeleton } from 'components/TableSkeleton';
import { withRemote } from 'hocs/withRemote';
import { useTablePagination } from 'hooks/useTablePagination';
import { Employee, TEmployee_listQuery } from 'services/SwaggerApi';
import { hasData } from 'utils/Loadable';

import { useEmployeeList } from './TableEmployees.hooks/useEmployeeList';
import { serializeFilters } from './TableEmployees.utils/serializeFilters';
import { EmployeeAddModal } from './EmployeeAddModal';
import { TableEmployees } from './TableEmployees';
import { cn } from './TableEmployees.cn';
import {
    ActionsCallbacks,
    TableEmployeesContainerProps,
    TableEmployeesFilters,
} from './TableEmployees.types';

const TableEmployeesRemote = withRemote(TableEmployees);

const defaultFilters: TableEmployeesFilters = {
    user: false,
    coordinator: false,
    activated: true,
    blocked: false,
};

export const TableEmployeesContainer: FC<TableEmployeesContainerProps> = props => {
    const {
        companyId,
        className,
    } = props;

    const [filters, setFilters] = useState(defaultFilters);
    const [isEmployeeAddModalVisible, toggleIsEmployeeAddModalVisible] = useToggle(false);
    const [employeeToActivate, setEmployeeToActivate] = useState<Employee | null>(null);
    const [employeeToBlock, setEmployeeToBlock] = useState<Employee | null>(null);
    const [employeeToChangeApprover, setEmployeeToChangeApprover] = useState<Employee | null>(null);
    const [employeeToChangeCompany, setEmployeeToChangeCompany] = useState<Employee | null>(null);
    const [employeeToGrantCoordinator, setEmployeeToGrantCoordinator] = useState<Employee | null>(null);
    const [employeeToAssignAsistant, setEmployeeToAssignAsistant] = useState<Employee | null>(null);
    const [employeeToRemoveAssistant, setEmployeeToRemoveAssistant] = useState<Employee | null>(null);
    const [employeeToReject, setEmployeeToReject] = useState<Employee | null>(null);
    const [employeeToRestore, setEmployeeToRestore] = useState<Employee | null>(null);
    const [employeeToRevokeCoordinator, setEmployeeToRevokeCoordinator] = useState<Employee | null>(null);
    const {
        paginationProps,
        computePagesCount,
        resetCurrentPage,
    } = useTablePagination();
    const {
        pageSize,
        currentPage,
    } = paginationProps;

    const handleFiltersChange = useCallback(data => {
        resetCurrentPage();
        setFilters(data);
    }, [resetCurrentPage]);

    const queryParams: TEmployee_listQuery = useMemo(() => ({
        ...serializeFilters(filters),
        page: currentPage,
        limit: pageSize,
    }), [currentPage, pageSize, filters]);

    const employees = useEmployeeList(companyId, queryParams);
    const employeesCount = hasData(employees) ? employees.result.count : 0;
    const pagesCount = computePagesCount(employeesCount);

    const isHeaderDisabled = !hasData(employees);

    const handleCloseActivate = useCallback(() => {
        setEmployeeToActivate(null);
    }, []);
    const handleSubmitActivate = handleCloseActivate;

    const handleCloseReject = useCallback(() => {
        setEmployeeToReject(null);
    }, []);
    const handleSubmitReject = handleCloseReject;

    const handleCloseChangeApprover = useCallback(() => {
        setEmployeeToChangeApprover(null);
    }, []);
    const handleSubmitChangeApprover = handleCloseChangeApprover;

    const handleCloseChangeCompany = useCallback(() => {
        setEmployeeToChangeCompany(null);
    }, []);
    const handleSubmitChangeCompany = handleCloseChangeCompany;

    const handleCloseRestore = useCallback(() => {
        setEmployeeToRestore(null);
    }, []);
    const handleSubmitRestore = handleCloseRestore;

    const handleCloseBlock = useCallback(() => {
        setEmployeeToBlock(null);
    }, []);
    const handleSubmitBlock = handleCloseBlock;

    const handleCloseRevokeCoordinator = useCallback(() => {
        setEmployeeToRevokeCoordinator(null);
    }, []);
    const handleSubmitRevokeCoordinator = handleCloseRevokeCoordinator;

    const handleCloseGrantCoordinator = useCallback(() => {
        setEmployeeToGrantCoordinator(null);
    }, []);
    const handleSubmitGrantCoordinator = handleCloseGrantCoordinator;

    const handleCloseAssignAssistant = useCallback(() => {
        setEmployeeToAssignAsistant(null);
    }, []);
    const handleSubmitAssignAsistant = handleCloseAssignAssistant;

    const handleCloseRemoveAssistant = useCallback(() => {
        setEmployeeToRemoveAssistant(null);
    }, []);
    const handleSubmitRemoveAssistant = handleCloseRemoveAssistant;

    const actionsCallbacks = useMemo<ActionsCallbacks>(() => ({
        onActivate: setEmployeeToActivate,
        onBlock: setEmployeeToBlock,
        onChangeApprover: setEmployeeToChangeApprover,
        onChangeCompany: setEmployeeToChangeCompany,
        onGrantCoordinator: setEmployeeToGrantCoordinator,
        onAssignAssistant: setEmployeeToAssignAsistant,
        onRemoveAssistant: setEmployeeToRemoveAssistant,
        onReject: setEmployeeToReject,
        onRestore: setEmployeeToRestore,
        onRevokeCoordinator: setEmployeeToRevokeCoordinator,
    }), []);

    return (
        <>
            <div className={cn(null, [className])}>
                <TableEmployeesHeader
                    className={cn('Header')}
                    companyId={companyId}
                    defaultFilters={defaultFilters}
                    disabled={isHeaderDisabled}
                    onAddEmployeeClick={toggleIsEmployeeAddModalVisible}
                    onFiltersChange={handleFiltersChange}
                />
                <TableEmployeesRemote
                    actionsCallbacks={actionsCallbacks}
                    data={employees}
                    pageCount={pagesCount}
                    pageIndex={currentPage}
                    pageSize={pageSize}
                    skeleton={
                        <TableSkeleton
                            className={cn('Skeleton')}
                            columns={5}
                            rows={10}
                        />
                    }
                />
            </div>
            {pagesCount > 0 && <Pagination
                {...paginationProps}
                className={cn('Pagination')}
                pagesCount={pagesCount}
            />}
            {employeeToActivate && (
                <EmployeeActivateModal
                    modalViewVisible
                    employee={employeeToActivate}
                    modalViewHasCloseIcon={false}
                    modalViewOnClose={handleCloseActivate}
                    onCancel={handleCloseActivate}
                    onSubmit={handleSubmitActivate}
                />)
            }
            {employeeToReject && (
                <EmployeeRejectModal
                    modalViewVisible
                    employee={employeeToReject}
                    modalViewHasCloseIcon={false}
                    modalViewOnClose={handleCloseReject}
                    onCancel={handleCloseReject}
                    onSubmit={handleSubmitReject}
                />)
            }
            {employeeToChangeApprover && (
                <EmployeeChangeApproverModal
                    modalViewVisible
                    employee={employeeToChangeApprover}
                    modalViewHasCloseIcon={false}
                    modalViewOnClose={handleCloseChangeApprover}
                    onCancel={handleCloseChangeApprover}
                    onSubmit={handleSubmitChangeApprover}
                />
            )}
            {employeeToChangeCompany && (
                <EmployeeChangeCompanyModal
                    modalViewVisible
                    employee={employeeToChangeCompany}
                    modalViewHasCloseIcon={false}
                    modalViewOnClose={handleCloseChangeCompany}
                    onCancel={handleCloseChangeCompany}
                    onSubmit={handleSubmitChangeCompany}
                />
            )}
            {employeeToRestore && (
                <EmployeeRestoreModal
                    modalViewVisible
                    employee={employeeToRestore}
                    modalViewHasCloseIcon={false}
                    modalViewOnClose={handleCloseRestore}
                    onCancel={handleCloseRestore}
                    onSubmit={handleSubmitRestore}
                />)
            }
            {employeeToBlock && (
                <EmployeeBlockModal
                    modalViewVisible
                    employee={employeeToBlock}
                    modalViewHasCloseIcon={false}
                    modalViewOnClose={handleCloseBlock}
                    onCancel={handleCloseBlock}
                    onSubmit={handleSubmitBlock}
                />)
            }
            {employeeToGrantCoordinator && (
                <EmployeeGrantCoordinatorModal
                    modalViewVisible
                    employee={employeeToGrantCoordinator}
                    modalViewHasCloseIcon={false}
                    modalViewOnClose={handleCloseGrantCoordinator}
                    onCancel={handleCloseGrantCoordinator}
                    onSubmit={handleSubmitGrantCoordinator}

                />
            )}
            {employeeToAssignAsistant && (
                <EmployeeAssignAssistantModal
                    modalViewVisible
                    employee={employeeToAssignAsistant}
                    modalViewHasCloseIcon={false}
                    modalViewOnClose={handleCloseAssignAssistant}
                    onCancel={handleCloseAssignAssistant}
                    onSubmit={handleSubmitAssignAsistant}
                />
            )}
            {employeeToRemoveAssistant && (
                <EmployeeRemoveAssistantModal
                    employee={employeeToRemoveAssistant}
                    onCancel={handleCloseRemoveAssistant}
                    onSubmit={handleSubmitRemoveAssistant}
                />
            )}
            {employeeToRevokeCoordinator && (
                <EmployeeRevokeCoordinatorModal
                    modalViewVisible
                    employee={employeeToRevokeCoordinator}
                    modalViewHasCloseIcon={false}
                    modalViewOnClose={handleCloseRevokeCoordinator}
                    onCancel={handleCloseRevokeCoordinator}
                    onSubmit={handleSubmitRevokeCoordinator}
                />
            )}
            <EmployeeAddModal
                companyId={companyId}
                toggleShowModal={toggleIsEmployeeAddModalVisible}
                visible={isEmployeeAddModalVisible}
            />
        </>
    );
};

TableEmployeesContainer.displayName = cn();
