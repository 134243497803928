import { logError } from 'utils/logError';

import { i18n } from '../../FormField2.i18n';

import { tripIdError, tripIdField } from './getExtraErrors';

/**
 * Обрабатывает сообщения ошибок бекенда, которые невозможно обработать на фронте
 * @param fieldName Имя поля с ошибкой
 * @param message Полученная ошибка на это поле
 */
export const getServerErrorMessage = (fieldName: string, message: string) => {
    const messageLower = message.toLowerCase();
    const errorCode = `${fieldName}: ${messageLower}`;

    switch (errorCode) {
        case 'fee: invalid value': {
            return i18n('value_error');
        }

        case `${tripIdField}: ${tripIdError}`: {
            return i18n('trip_does_not_exist');
        }

        default: {
            logError(new Error(`Нет валидации на поле ${fieldName}. Ошибка: ${message}`));

            return i18n('value_error');
        }
    }
};
