// сделано на основе - https://github.yandex-team.ru/data-ui/ya-travel/blob/d980f6c6c3265089364a52c43c45799e511fca0b/src/projects/trains/components/TrainsOrderPage/SvgSchema/SvgSchema.tsx
import { TYCarriagePlace, TYCoach, TYCoachPlace } from 'services/TravelApi/TYTrainDetails';

import { i18n, I18nKeys } from '../TrainCarriage.i18n';

import {
    COMPARTMENT,
    GENDER_TITLE_LINE_GAP,
    GENDER_TITLE_LINE_HEIGHT,
    GENDER_TYPE,
    PLACE_SIZE,
    SUITE,
    SVG_NAMESPACE,
} from './constants';
import { getPlaceNumbers } from './getPlaceNumbers';

function isNotNull<T>(value: T): value is Exclude<T, null> {
    return value !== null;
}

/*
 * Возвращает основной гендерный признак вагона
 */
export function getCoachGender(coach: TYCoach): GENDER_TYPE | null {
    const genders = new Set(
        coach.places.map(({ gender }) => gender).filter(isNotNull),
    );

    if (genders.size === 1) {
        if (genders.has(GENDER_TYPE.MALE)) {
            return GENDER_TYPE.MALE;
        }

        if (genders.has(GENDER_TYPE.FEMALE)) {
            return GENDER_TYPE.FEMALE;
        }

        return GENDER_TYPE.MIXED;
    } else if (genders.size > 1) {
        return GENDER_TYPE.MIXED;
    }

    return null;
}

interface IGenderHint {
    gender?: GENDER_TYPE | null;
    genderTipUp?: null | boolean;
    left: number;
    top: number;
    right: number;
    bottom: number;
}

/*
 * Метод выставляет координаты нод мест
 */
export const setPlaceNodesCoords = (place: SVGGElement, container: HTMLDivElement) => {
    // написать болеее узкий тип HTMLElement
    const trigger = place.querySelector<HTMLElement>('[id^="trigger"]');
    const position = trigger!.getBoundingClientRect();
    const containerCoords = container.getBoundingClientRect();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    place.coords = {
        left: position.left - containerCoords.left,
        top: position.top - containerCoords.top,
        height: position.height,
        width: position.width,
    };

    return place;
};

/*
 * Метод выставляет координаты нод мест
 */
export const getPlaceNodesCoords = (places: SVGGElement[], container: HTMLDivElement) => {
    const placeNodesCoords: { left: number; top: number; height: number; width: number }[] = [];

    for (let i = 0; i < places.length; i++) {
        // написать болеее узкий тип HTMLElement
        const trigger = places[i].querySelector<HTMLElement>('[id^="trigger"]');
        const position = trigger!.getBoundingClientRect();
        const containerCoords = container.getBoundingClientRect();

        placeNodesCoords.push({
            left: position.left - containerCoords.left,
            top: position.top - containerCoords.top,
            height: position.height,
            width: position.width,
        });
    }

    return placeNodesCoords;
};

/*
 * Метод получает информацию для отображения гендерных подсказок
 */
export function getGenderHints(
    schemaPlaces: TYCarriagePlace[],
    svgNodePlaces: SVGGElement[],
    coachPlaces: TYCoachPlace[],
    container: HTMLDivElement,
): IGenderHint[] {
    if (!schemaPlaces || !svgNodePlaces) {
        return [];
    }

    const groups = [...new Set(schemaPlaces.map(({ groupNumber }) => groupNumber))];
    const placesWithCoords = svgNodePlaces.map(place => setPlaceNodesCoords(place, container));

    const groupsToPlaces: number[][] = [];

    for (let i = 0; i < groups.length; i++) {
        groupsToPlaces[groups[i]] = schemaPlaces.filter(schemaPlace => schemaPlace.groupNumber === groups[i])
            .map(schemaPlace => schemaPlace.number);
    }

    return groups.map(group => {
        const groupPlaces = placesWithCoords
            .filter(placeNode => {
                const numbers = getPlaceNumbers(placeNode);
                const place = numbers[0];

                return groupsToPlaces[group].indexOf(place) !== -1;
            });
        const placesCoords = getPlaceNodesCoords(groupPlaces, container);

        const currentCoachPlaces = coachPlaces.filter(
            coachPlace => groupsToPlaces[group].indexOf(Number(coachPlace.number)) !== -1,
        );

        return {
            gender: currentCoachPlaces.reduce<GENDER_TYPE | null>(
                (gender, coachPlace) => gender || coachPlace.gender,
                null,
            ),
            genderTipUp: currentCoachPlaces.reduce<null | boolean>(
                (genderTipUp, coachPlace) => genderTipUp || coachPlace.genderTipUp,
                null,
            ),
            left: Math.min(...placesCoords.map(({ left }) => left)),
            top: Math.min(...placesCoords.map(({ top }) => top)),
            right: Math.max(
                ...placesCoords.map(({ left }) => left + PLACE_SIZE),
            ),
            bottom: Math.max(
                ...placesCoords.map(({ top }) => top + PLACE_SIZE),
            ),
        };
    });
}

/*
 у тревела эта функция кэшируется при помощи скрипта cashe.js кажется это излишнее действие
 если понадобится можно взять у них
*/
function genderTitleWithoutCache(gender: GENDER_TYPE): SVGTextElement {
    const titleElement = document.createElementNS(SVG_NAMESPACE, 'text');
    const genderTitleElement = document.createElementNS(SVG_NAMESPACE, 'tspan');
    const groupTitleElement = document.createElementNS(SVG_NAMESPACE, 'tspan');

    genderTitleElement.textContent = i18n(`compartment_${gender}` as I18nKeys);
    groupTitleElement.textContent = i18n('compartment');

    titleElement.classList.add('Place__genderTitle');
    titleElement.setAttribute('text-anchor', 'middle');
    groupTitleElement.setAttribute('dy', '1.2em');

    titleElement.appendChild(genderTitleElement);
    titleElement.appendChild(groupTitleElement);

    return titleElement;
}

export function getGenderTitle(
    gender: GENDER_TYPE,
    { x, y }: {x: number; y: number},
): Node {
    const title = genderTitleWithoutCache(gender).cloneNode(true);

    title.childNodes.forEach(node => {
        (node as HTMLElement).setAttribute('x', String(x));
        (node as HTMLElement).setAttribute('y', String(y));
    });

    return title;
}

/*
 * Метод добавляет гендерные подскаки к схеме
 */
export function renderGenderHints(container: HTMLDivElement, hints: IGenderHint[]): void {
    const textContainer = container.querySelector('#base');

    if (!textContainer) {
        return;
    }

    hints.forEach(
        ({ gender, genderTipUp, left, top, right, bottom }) =>
            gender &&
            gender !== GENDER_TYPE.SINGLE &&
            textContainer.appendChild(
                getGenderTitle(gender, {
                    x: left + (right - left) / 2,
                    y: genderTipUp ? top - 2 * (GENDER_TITLE_LINE_HEIGHT + GENDER_TITLE_LINE_GAP) :
                        bottom + 2 * GENDER_TITLE_LINE_HEIGHT,
                }),
            ),
    );
}

/*
 * Метод получает и отображает гендерные подсказки, если они необходимы
 */
export const createGenderHints = (
    coach: TYCoach,
    schema: HTMLDivElement | null,
    schemaPlaces: TYCarriagePlace[],
    svgNodePlaces: SVGGElement[],
): void => {
    if (!schema) {
        return;
    }

    const textContainer = schema.querySelector('#base');

    const genderHints = Array.from(
        textContainer!.querySelectorAll('.Place__genderTitle'),
    );

    // Т.к. мы работаем с одной и той же dom нодой, то нужно подчищать за собой артефакты
    genderHints.forEach(node => textContainer!.removeChild(node));

    if (
        (coach.type === SUITE || coach.type === COMPARTMENT) &&
        getCoachGender(coach)
    ) {
        renderGenderHints(schema, getGenderHints(schemaPlaces, svgNodePlaces, coach.places, schema));
    }
};
