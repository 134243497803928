import { OverpriceWorkflow, ServiceCreate } from 'services/SwaggerApi';
import { ServiceDetails } from 'types/TripRoute';

const extra_payment: OverpriceWorkflow = 'extra_payment';

type PrepareServiceCreatePayloadOptions = {
    isViolatesTravelPolicy: boolean;
    optionId: string;
    tripId: number;
    searchId: string;
    serviceType: ServiceDetails['service_type'];
};
type PrepareServiceCreatePayload = (options: PrepareServiceCreatePayloadOptions) => (personId: string) => ServiceCreate;

type PrepareServiceCreatePayloadCompanions = (options: PrepareServiceCreatePayloadOptions) =>
(personId: string) => (personIds: string[]) => ServiceCreate;

export const prepareServiceCreatePayload: PrepareServiceCreatePayload = options => {
    const {
        isViolatesTravelPolicy,
        optionId,
        tripId,
        searchId,
        serviceType,
    } = options;

    const payload = {
        type: serviceType,
        trip_id: tripId,
        search_id: searchId,
        provider_item_id: optionId,
        ...(isViolatesTravelPolicy && { overprice_workflow: extra_payment }),
    };

    return (person_id: string): ServiceCreate => (
        { ...payload, person_id }
    );
};
export const prepareServiceCreatePayloadCompanions: PrepareServiceCreatePayloadCompanions = options => {
    return (person_id: string) => (ext_person_uuids: string[]): ServiceCreate => {
        const payload = prepareServiceCreatePayload(options)(person_id);

        return { ...payload, ext_person_uuids };
    };
};
