import { FC, useCallback } from 'react';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import { FieldDatePicker, FieldDestination } from 'components/FormField/FormField.providers';
import { FieldLabel } from 'components/FormField2/utils';
import { withProps } from 'hocs/withProps';
import { usePlatform } from 'hooks/usePlatform';
import { SettlementSuggestItem } from 'services/SwaggerApi';
import { validateRequiredField } from 'utils/form/validateRequiredField';

import { cn } from '../FormTripCreate.cn';
import { i18n } from '../FormTripCreate.i18n';
import { ONE_YEAR_FROM_NOW } from '../FormTripCreate.utils/ONE_YEAR_FROM_NOW';

import { ComplexRoutePointProps } from './ComplexRoute.types';

const CityField = withProps({
    component: FieldDestination,
},
cn('Field'),
)(Field);

const DateField = withProps({
    component: FieldDatePicker,
    validate: validateRequiredField,
},
cn('Field'),
)(Field);

export const ComplexRoutePoint: FC<ComplexRoutePointProps> = props => {
    const { fields, name, index } = props;

    const { isDesktop } = usePlatform();
    const isFirstRoute = index === 0;
    const isLastRoute = fields.value.length - 1 === index;

    const handleFirstOriginChange = useCallback((firstRouteOrigin: SettlementSuggestItem) => {
        const lastRouteIndex = fields.value.length - 1;

        fields.update(lastRouteIndex, { ...fields.value[lastRouteIndex], cityTo: firstRouteOrigin });
    }, [fields]);

    const handleDestinationChange = useCallback((currentRouteDestination: SettlementSuggestItem) => {
        fields.update(index + 1, { ...fields.value[index + 1], cityFrom: currentRouteDestination });
    }, [fields, index]);

    const dates = fields.value.map(({ date }) => date);
    const maxDate = new Date(dates.find((date, dateIndex) => index < dateIndex && date) || ONE_YEAR_FROM_NOW);
    const lastMinDate = dates.reverse().find((date, dateIndex) => date &&
        (fields.value.length - 1 - dateIndex < index));
    const minDate = new Date(lastMinDate || new Date());

    const validateCityFrom = isFirstRoute ? validateRequiredField : undefined;
    const validateCityTo = !isLastRoute ? validateRequiredField : undefined;

    return (
        <div className={cn('RoutePoint')}>
            {isFirstRoute &&
                <FieldLabel
                    isRequired
                    className={cn('CitiesLabel')}
                    label={i18n('complex_route')}
                />
            }
            {isFirstRoute && isDesktop &&
                <FieldLabel
                    isRequired
                    className={cn('DatesLabel')}
                    label={i18n('trip_date')}
                />
            }
            {isFirstRoute && <OnChange name={`${name}.cityFrom`}>{handleFirstOriginChange}</OnChange>}
            <CityField
                className={cn('CityFrom')}
                disabled={!isFirstRoute}
                name={`${name}.cityFrom`}
                placeholder={i18n('city_from')}
                searchSuggestParams={{ service_type: 'hotel' }}
                validate={validateCityFrom}
            />
            {!isLastRoute && <OnChange name={`${name}.cityTo`}>{handleDestinationChange}</OnChange>}
            <CityField
                className={cn('CityTo')}
                disabled={isLastRoute}
                name={`${name}.cityTo`}
                placeholder={i18n('city_to')}
                searchSuggestParams={{ service_type: 'hotel' }}
                validate={validateCityTo}
            />
            <div className={cn('DateField')}>
                <DateField
                    fromDate={minDate}
                    inputClassName={cn('DateWhen')}
                    inputPlaceholder={i18n('date_when')}
                    name={`${name}.date`}
                    toDate={maxDate}
                />
            </div>
        </div>
    );
};
