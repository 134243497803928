import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { i18nAction } from 'i18n/i18nAction';
import { RouteMyActiveTrips } from 'routes/RouteMyActiveTrips';
import { Container } from 'shared/ui/Container';
import { ErrorState } from 'shared/ui/ErrorState';

import { cn } from './PageError.cn';
import { i18n } from './PageError.i18n';
import { PageErrorProps } from './PageError.types';

import './PageError.css';

// Несмотря на название это просто полностраничный вид ошибки, а не отдельная страница
export const PageError: FC<PageErrorProps> = props => {
    const { type } = props;

    const history = useHistory();

    const onActionClick = useCallback(() => {
        history.push(RouteMyActiveTrips.getPath({}, {}));
    }, [history]);

    return (
        <Container data-testid="page-error">
            <ErrorState
                withChatSupport
                actionTitle={i18nAction('to_the_main_page')}
                className={cn('ErrorName')}
                description={i18n(`${type}_description`)}
                imageType={type}
                size="l"
                title={i18n(`${type}_title`)}
                onActionClick={onActionClick}
            />
        </Container>
    );
};

PageError.displayName = cn();
