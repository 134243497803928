import { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { GeoMap } from 'components/GeoMap';
import { usePlatform } from 'hooks/usePlatform';
import { RouteServiceDetails } from 'routes/RouteServiceDetails';
import { Text } from 'shared/ui/Text';

import { cn } from './HotelsMapSearchResult.cn';
import { i18n } from './HotelsMapSearchResult.i18n';
import { HotelsMapSearchResultProps } from './HotelsMapSearchResult.types';

import './HotelsMapSearchResult.css';

export const HotelsMapSearchResult: FC<HotelsMapSearchResultProps> = props => {
    const {
        hotels,
        searchId,
        personId,
        tripId,
    } = props;
    const history = useHistory();
    const { isMobile } = usePlatform();
    const hotelsMarks = useMemo(() => {
        return hotels
            .filter(h => h.geo_position)
            .map(h => {
                return {
                    latitude: h.geo_position!.latitude,
                    longitude: h.geo_position!.longitude,
                    stars: Number(h.stars),
                    title: h.hotel_name,
                    price: h.min_price_per_night,
                    currency: h.currency,
                    id: h.id,
                    isRecommended: Boolean(h.is_recommended),
                };
            });
    }, [hotels]);

    const handleHotelClick = useCallback((key: string, name: string) => {
        const hotelDetailsUrl = RouteServiceDetails.getPath({ searchId, key }, {
            type: 'Hotel',
            person_id: personId,
            trip_id: tripId,
            tariff_index: [0],
            name,
        });

        history.push(hotelDetailsUrl);
    }, [history, personId, searchId, tripId]);

    return (
        <div className={cn(null, [props.className])}>
            {hotelsMarks.length === 0 ? (
                <Text typography="paragraph-m">
                    {i18n('no_hotels_found')}
                </Text>
            ) : (
                <GeoMap
                    className={cn('MapContainer')}
                    marks={hotelsMarks}
                    ymapOptions={isMobile ? { controls: [] } : {}}
                    onItemClick={handleHotelClick}
                />
            )}
        </div>
    );
};

HotelsMapSearchResult.displayName = cn();
