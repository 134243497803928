/* eslint-disable */
// DO NOT EDIT: Generated by node ./scripts/createRuErrors.js from openapi
export const ru = {
    common_b2b_problem: "Ошибка в B2B (deprecated)",
    consent_already_signed: "Согласие об обработке ПД уже подписано",
    consent_not_signed: "Не подписано согласие на обработку ПД",
    consent_not_unavailable_in_country: "Согласие не доступно в стране пользователя",
    email_not_sent: "Email не был отправлен",
    internal_service_problem: "Ошибка внутреннего сервиса",
    not_enough_perm: "Недостаточно прав для выполнения операции",
    not_found: "Запрашиваемый объект не найден",
    settlement_not_found_by_geoid: "Населенный пункт не найден по geoid",
    unexpected_company: "Неожиданное значение компании для АК",
    unknown: "Неизвестная ошибка",
    calendar_error: "Ошибка в процессе взаимодействия с Calendar",
    idm_error: "Ошибка в процессе взаимодействия с IDM",
    ok_error: "Ошибка в процессе взаимодействия с OK",
    s3_key_not_found: "Ошибка в процессе взаимодействия с MDS",
    taxi_error: "Ошибка в процессе взаимодействия с Taxi",
    tracker_error: "Ошибка в процессе взаимодействия с Tracker",
    travel_error: "Ошибка в процессе взаимодействия с Travel",
    travel_policy_error: "Ошибка доступности микросервиса тревел-политик",
    aeroclub_error: "Ошибка провайдера Аэроклуб",
    aeroclub_bad_document_error: "Неверный тип документа на стороне АК",
    aeroclub_bad_profile_error: "Неверный профиль на стороне АК",
    aeroclub_failed_add_custom_properties: "Не получилось установить дополнительные атрибуты командировки на стороне АК",
    aeroclub_failed_add_travellers: "Не получилось добавить путешественника на стороне АК",
    aeroclub_failed_execute_service: "Не получилось оформить услугу на стороне АК",
    aeroclub_failed_get_trip_auth_state: "Не удалось получить состояние авториазции командировки на стороне АК",
    aeroclub_failed_get_free_seats: "Не удалось получить свободные места из АК",
    aeroclub_failed_get_service_auth_state: "Не удалось получить статус авторизации услуг на стороне АК",
    aeroclub_failed_reserve_service: "Ошибка резервирования услуги на стороне АК",
    aeroclub_service_not_authorized: "Услуга не авторизована на стороне АК",
    aeroclub_source_not_found_error: "Данные не найдены на стороне АК",
    aeroclub_trip_not_created: "Командировка не создана на стороне АК",
    aeroclub_trip_not_authorized: "Командировка не авторизована на стороне АК",
    aviacenter_error: "Ошибка провайдера Авиацентр",
    aviacenter_failed_add_traveller: "Ошибка добавления путешественника в АЦ",
    aviacenter_failed_execute_service: "Ошибка оформления услуги на стороне АЦ",
    aviacenter_failed_reserve_service: "Ошибка резервирования услуги на стороне АЦ",
    aviacenter_passenger_not_found_in_service: "Пассажир не прикреплен к услуге на стороне АЦ",
    empty_travellers_list: "Пустой список путешественников на стороне АК",
    empty_billing_number: "Пустой billing_number на стороне ААЦ",
    provider_incorrect_search_id: "Некорректный идентификатор поиска услуг",
    unavailable_provider_in_pt: "Недоступный provider для персональной заявки",
    unavailable_service_reservation_in_aeroclub: "Нельзя забронировать услугу в АК",
    unavailable_provider_city_id: "Поиск услуг на стороне поставщика для данного населенного пункта недоступен",
    chat_already_created: "Чат с ПЛП уже создан",
    chat_not_created: "Чат с ПЛП не создан",
    unable_create_chat: "Невозможно создать чат с ПЛП (например, если для командировки отсутствует менеджер)",
    empty_date_of_birth: "Не заполнена дата рождения",
    empty_gender: "Не установлен пол",
    empty_phone_number: "Не заполен номер телефона",
    empty_services_for_execute: "Пустой список услуг для оформления",
    ext_person_not_available: "+1 не доступно в этой заявке",
    inactive_users_in_pt: "Неактивные пользователи в заявке",
    incorrect_first_city_in_route: "Некорректное значение первого города в маршруте командировки - нельзя изменять",
    incorrect_waypoints_in_route: "Некорректный список точек маршрута в командировке - нельзя изменять",
    no_services_to_verification: "Нет услуг, для которых нужно ручное согласование/верификация",
    pt_not_need_verification: "Командировке не нужно ручное согласование/верификация",
    pt_need_verification: "Для командировки необходимо ручное согласование/верификация",
    pt_in_terminated_status: "Командировка находится в терминальном статусе (закрыта или отменена) и не может быть изменена",
    service_workflow_disabled: "Оформление услуг отключено",
    service_workflow_not_available_for_offline: "Оформление услуг не доступно для офлайн командировок",
    several_holdings_in_pt: "Несколько холдингов в одной командировке",
    unavailable_company: "Неподходящая компания (юр.лицо) для онлайн-командировки",
    wrong_ext_persons: "Неверный список людей для +1",
    wrong_number_of_approvers: "Неверное количество согласующих",
    wrong_pt_status: "Неверный статус персональной командировки",
    incorrect_service_type_for_update: "Неверный тип услуги для обновления",
    seat_already_selected: "Нельзя оформить места, так как они уже заняты",
    service_no_longer_available_for_execute: "Услуга/место больше недоступна для оформления - повторите поиск",
    wrong_service_document: "Для услуги не выбран документ либо выбран неподходящий документ",
    wrong_service_status: "Услуга находится в неверном статусе",
    document_already_add: "Документ уже добавлен к услуге",
    document_duplicate: "Нельзя добавлять дубликаты документов",
    incorrect_ru_passport_number: "Некорректный номер паспорта РФ",
    incorrect_ru_passport_number_len: "Некорректная длина номера паспорта РФ",
    pt_not_approved: 'Командировка не согласована',
}
