import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ListItem } from '@yandex-int/hr-components/ListItem';

import { MenuOption } from 'components/ActionsMenu';
import { Bubble } from 'components/Bubble';
import { ButtonMenu } from 'components/ButtonMenu';
import { getIconProvider, IconMoreVertical, IconProfile, IconTrash } from 'components/Icon';
import { RouteProfile } from 'routes/RouteProfile';
import { Text } from 'shared/ui/Text';

import { cn } from '../EmployeesGroupForm.cn';
import { i18n } from '../EmployeesGroupForm.i18n';

import { MembersListProps } from './MembersList.types';

import './MembersList.css';

const iconProviderMoreVertical = getIconProvider(IconMoreVertical);
const iconProviderTrash = getIconProvider(IconTrash);
const iconProviderProfile = getIconProvider(IconProfile);

export const MembersList: FC<MembersListProps> = props => {
    const { membersList, newMembersList, setMemberIdToDelete } = props;

    const { push } = useHistory();

    const getMenuOptions = useCallback(
        (id: string): MenuOption[] => [
            {
                label: i18n('go_to_profile'),
                icon: iconProviderProfile,
                onClick: () => {
                    push(RouteProfile.getPath({ personId: id }, {}));
                },
            },
            {
                label: i18n('remove_from_group'),
                icon: iconProviderTrash,
                onClick: () => {
                    setMemberIdToDelete(id);
                },
            },
        ],
        [push, setMemberIdToDelete],
    );

    return (
        <div className={cn('List')}>
            {[...newMembersList, ...membersList].map(employee => (
                <ListItem
                    key={employee.person_id}
                    embed
                    border="m"
                    className={cn('ListItem')}
                    leading={
                        <Bubble
                            bubble={{
                                type: 'person',
                                login: employee.login,
                                name: employee.login,
                                personId: String(employee.person_id),
                            }}
                            className={cn('Avatar')}
                            size="l"
                            type="person"
                        />
                    }
                    padding="m"
                    trailing={
                        <ButtonMenu
                            className={cn('ButtonMenu')}
                            direction="bottom-end"
                            icon={iconProviderMoreVertical}
                            menu={getMenuOptions(employee.person_id)}
                            size="m"
                            view="pseudo"
                        />
                    }
                >
                    <div className={cn('ListItemInfo')}>
                        <Text typography="body-m">{employee.login}</Text>
                        <Text color="secondary" typography="caption-m">
                            {employee.login}
                        </Text>
                    </div>
                </ListItem>
            ))}
        </div>
    );
};

MembersList.displayName = cn();
