import { AviaLocation } from 'services/SwaggerApi';

import { i18n } from '../TripRoute.yt.i18n';

type GetAirportWithTerminal = (aviaLocation: AviaLocation | undefined) => string | undefined;

export const getAirportWithTerminal: GetAirportWithTerminal = aviaLocation => {
    const airport = aviaLocation?.airport?.name;
    const terminal = aviaLocation?.terminal?.name;

    if (!airport) {
        return;
    }

    if (!terminal) {
        return airport;
    }

    return `${airport}, ${i18n('terminal')} ${terminal}`;
};
