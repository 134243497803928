import { useCallback } from 'react';

import { usePlatform } from 'hooks/usePlatform';
import { getPassportAutofillUrl } from 'utils/urls';

export const useYaAuthInitSave = () => {
    const { isMobile } = usePlatform();

    const showInitSave = useCallback(async(onAccepted: FunctionVoid) => {
        // если будет reject здесь или ниже, то всё уйдет в .catch в месте вызова
        const showFormResult = await window.YaAuthDataProvider.initSave(
            { isDrawer: isMobile },
            { hostname: getPassportAutofillUrl() },
        );

        const acceptanceResult = await showFormResult.handler();

        if (acceptanceResult?.action !== 'accepted') {
            return;
        }

        onAccepted();
    }, [isMobile]);

    return showInitSave;
};
