import { IconTravelCar } from 'components/Icon';
import { fromLocalDate } from 'utils/date/fromLocalDate';
import { getOrderCity } from 'utils/getOrderCity';

import { i18n } from '../TripRoute.yt.i18n';

import { getAirportWithTerminal } from './getAirportNameTerminal';
import { segmentEvent } from './segmentEvent';
import { switchTypeFn } from './switchTypeFn';
import { EventCreator } from './types';

export const taxiTo: EventCreator = order => {
    const date = switchTypeFn({
        rail: order => fromLocalDate(order.departure_at).subtract(90, 'minute'),
        avia: order => fromLocalDate(order.departure_at).subtract(180, 'minute'),
        hotel: order => fromLocalDate(order.start_at).subtract(60, 'minute'),
    })(order);

    const city = getOrderCity(order) || '';

    const text = switchTypeFn({
        rail: order => {
            const station = order.train.departure?.train_station?.name;

            return i18n('taxi_to_rail', { station });
        },
        avia: order => {
            const aviaLocation = order.flight.legs?.[0].segments[0]?.departure;
            const airport_w_terminal = getAirportWithTerminal(aviaLocation);

            return i18n('taxi_to_airport', { airport_w_terminal });
        },
        hotel: order => {
            const hotel = order.hotel.hotel_name;
            const address = order.hotel.address;

            return i18n('taxi_to_hotel', { hotel, address });
        },
    })(order);

    return segmentEvent({
        date,
        text,
        city,
        hint: i18n('taxi_hint'),
        icon: IconTravelCar,
    });
};
