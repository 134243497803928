import * as t from 'io-ts';

export function nullable<RT extends t.Any>(
    type: RT,
    name: string = `${type.name} | null`,
): t.UnionType<
    [RT, t.NullType],
    t.TypeOf<RT> | null,
    t.OutputOf<RT> | null,
    t.InputOf<RT> | null
  > {
    return t.union<[RT, t.NullType]>([type, t.null], name);
}
