import { i18nDocumentType } from 'i18n/TT/i18nDocumentType';
import { TravellerDocument } from 'services/SwaggerApi';

export const getDocumentTitle = (document?: TravellerDocument) => {
    if (!document) {
        return '';
    }

    const {
        series,
        document_type,
        number,
    } = document;

    const type = i18nDocumentType(document_type);
    const seriesNumber = [series, number].join('');

    return [type, seriesNumber].join(' · ');
};
