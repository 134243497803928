import { FC } from 'react';
import { Field } from 'react-final-form';

import { FieldRadioToggle, FieldTextarea } from 'components/FormField/FormField.providers';
import { config } from 'config';
import { validateRequiredField } from 'utils/form/validateRequiredField';

import { cn } from '../FormTripCreate.cn';
import { i18n } from '../FormTripCreate.i18n';
import { getVisaRequired } from '../FormTripCreate.utils/getVisaRequired';

import { PersonFieldsProps } from './PersonFields.types';

export const PersonFields: FC<PersonFieldsProps> = props => {
    const { name, person, route = [] } = props;

    const needVisaOptions = [
        { value: 'true', content: i18n('yes'), label: i18n('yes') },
        { value: 'false', content: i18n('no'), label: i18n('no') },
    ];

    const showDescription = person.is_offline_trip && route.length > 0;

    return (
        <div className={cn('PersonFields')}>
            {getVisaRequired(route, config.isB2B) &&
                <Field
                    required
                    className={cn('Field', [cn('NeedVisaField')])}
                    component={FieldRadioToggle}
                    name={`${name}.need_visa`}
                    options={needVisaOptions}
                    title={i18n('need_visa')}
                    validate={validateRequiredField}
                />
            }
            {showDescription &&
                <Field
                    required
                    className={cn('Field')}
                    component={FieldTextarea}
                    name={`${name}.description`}
                    title={i18n('wishes_and_passport_details')}
                    validate={validateRequiredField}
                />
            }
        </div>
    );
};
