import { i18nErrors } from 'i18n/i18nErrors';

import { isFailure, RemoteData } from './Loadable';

export function getFailureError<E>(loadable: RemoteData<unknown, E>) {
    if (isFailure(loadable)) {
        if (loadable.error instanceof Error) {
            return {
                errorName: loadable.error.name,
                message: loadable.error.message,
                details: loadable.error.stack,
            };
        }

        return {
            errorName: 'not_recognizable_error',
            message: i18nErrors('not_recognizable_error'),
        };
    }

    return null;
}
