import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { FieldTextinput, FormProvider } from 'components/FormField2';
import { RouteRegisterEmail } from 'routes/RouteRegisterEmail';
import { PersonCreate } from 'services/SwaggerApi';
import { Button } from 'shared/ui/Button';
import { errorToast } from 'utils/errorToast';
import { isLoading } from 'utils/Loadable';

import { usePersonCreate } from './CompanyEmailForm.hooks/usePersonCreate';
import { getRedirectPath } from './CompanyEmailForm.utils/getRedirectPath';
import { cn } from './CompanyEmailForm.cn';
import { i18n } from './CompanyEmailForm.i18n';
import { schema } from './CompanyEmailForm.schema';
import {
    CompanyEmailFormFields,
    CompanyEmailFormProps,
} from './CompanyEmailForm.types';

import './CompanyEmailForm.css';

export const CompanyEmailForm: FC<CompanyEmailFormProps> = props => {
    const {
        className,
    } = props;

    const history = useHistory();
    const methods = useForm<CompanyEmailFormFields>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
    });

    const [createPerson, createState, { error }] = usePersonCreate({
        onSuccess: () => {
            history.push(RouteRegisterEmail.path);
        },
        onError: error => {
            const redirectPath = getRedirectPath(error.detail);

            if (redirectPath) {
                history.push(redirectPath);
            } else {
                errorToast(error, { title: i18n('error_title'), message: i18n('error_message') });
            }
        },
        options: {
            hideErrorNotifier: true,
            invalidateOnSuccess: true,
        },
    });

    const handleSubmit = (data: PersonCreate) => {
        createPerson(data);
    };

    const isCreating = isLoading(createState);

    return (
        <FormProvider error={error} methods={methods} schema={schema}>
            <form
                className={cn(null, [className])}
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <FieldTextinput
                    hasClear={false}
                    inputMode="email"
                    name="email"
                    placeholder={i18n('placeholder')}
                    size="m"
                />
                <Button
                    progress={isCreating}
                    size="l"
                    type="submit"
                    view="primary"
                    width="max"
                >
                    {i18n('continue')}
                </Button>
            </form>
        </FormProvider>
    );
};

CompanyEmailForm.displayName = cn();
