import { FC, useCallback, useRef, useState } from 'react';

import { MenuOption } from 'components/ActionsMenu';
import { ButtonMenu } from 'components/ButtonMenu';
import { getIconProvider, IconEye, IconMoreVertical, IconTrash } from 'components/Icon';
import { i18nAction } from 'i18n/i18nAction';
import { isLoading } from 'utils/Loadable';

import { cn } from '../DocumentsList.cn';
import { useDeleteDocument } from '../DocumentsList.hooks/useDeleteDocument';
import { useDeleteDocumentExtPerson } from '../DocumentsList.hooks/useDeleteDocumentExtPerson';

import { ItemMenuProps } from './ItemMenu.types';

import './ItemMenu.css';

const kebabIcon = getIconProvider(IconMoreVertical);

export const ItemMenu: FC<ItemMenuProps> = props => {
    const {
        onOpenDetailsClick,
        document,
        personId,
        type,
    } = props;

    const anchor = useRef(null);

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const onClick = useCallback(() => {
        setIsMenuVisible(!isMenuVisible);
    }, [isMenuVisible]);

    const [deleteDocument, deleteResponse] = useDeleteDocument();
    const [deleteDocumentExt, deleteExtResponse] = useDeleteDocumentExtPerson();

    const deleteFn = type === 'person' ? deleteDocument : deleteDocumentExt;
    const deleteState = type === 'person' ? deleteResponse : deleteExtResponse;

    const isDeleting = isLoading(deleteState);

    const menu: MenuOption[] = [
        {
            icon: getIconProvider(IconEye),
            label: i18nAction('open_details'),
            onClick: onOpenDetailsClick,
        },
        {
            icon: getIconProvider(IconTrash),
            label: i18nAction('remove'),
            onClick: () => {
                deleteFn(document.document_id, personId);
            },
            disabled: isDeleting,
        },
    ];

    return (
        <>
            <ButtonMenu
                className={cn('ButtonMenu')}
                direction="bottom-end"
                icon={kebabIcon}
                innerRef={anchor}
                menu={menu}
                scope="inplace"
                size="m"
                view="pseudo"
                onClick={onClick}
            />
        </>
    );
};
