import { FC } from 'react';
import dayjs from 'dayjs';

import { AppLink } from 'components/AppLink';
import { config } from 'config';
import { Container } from 'shared/ui/Container';
import { Text } from 'shared/ui/Text';

import { i18n } from './Footer.b2b.i18n';
import { cn } from './Footer.cn';
import { FooterProps } from './Footer.types';

import './Footer.css';

const { isB2B } = config;

export const Footer: FC<FooterProps> = () => {
    return (
        <Container>
            <div className={cn({ b2b: isB2B })}>
                <div className={'Left'} />
                <div className={cn('Right')}>
                    <Text as="div" className={cn('Version')} color="secondary" typography="body-s">
                        {i18n('yandex_trip')}{' '}{config.version}
                    </Text>
                    {' '}
                    <AppLink className={cn('Copyright')} href="https://ya.ru" theme="text">
                        <Text color="secondary" typography="body-s">© {dayjs().format('YYYY')} {i18n('yandex')}</Text>
                    </AppLink>
                </div>
            </div>
        </Container>
    );
};
