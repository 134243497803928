import { FC, ReactNode, useCallback } from 'react';
import { useToggle } from 'react-use';
import { Button, ButtonProps } from '@yandex-int/hr-components/Button/desktop/bundle';
import { Spin } from '@yandex-lego/components/Spin/desktop/bundle';

import { Dialog } from 'components/Dialog';
import { IconCheck } from 'components/Icon';
import { PersonListItem as PersonListItemBase } from 'components/PersonListItem';
import { useAddService } from 'hooks/useAddService';
import { i18nAction } from 'i18n/i18nAction';
import { i18nTravelPolicy } from 'i18n/i18nTravelPolicy';
import { Text } from 'shared/ui/Text';
import { errorToast } from 'utils/errorToast';
import { getTravelPolicyMessage } from 'utils/getTravelPolicyMessage';
import { RemoteTag } from 'utils/Loadable';

import { cn } from '../ServiceGroupAdd.cn';
import { i18n } from '../ServiceGroupAdd.i18n';

import { PersonListItemProps } from './PersonListItem.types';

import './PersonListItem.css';

export const PersonListItem: FC<PersonListItemProps> = props => {
    const {
        person,
        getServiceCreatePayload,
        onDecLoadingServices,
        onIncLoadingServices,
        onAddService,
        onAddServiceSuccess,
        onAddServiceError,
        onAddServiceStart,
        isViolatesTravelPolicy,
    } = props;

    const {
        person_id,
        first_name,
        last_name,
        login,
    } = person;

    const personFullName = [first_name, last_name].filter(Boolean).join(' ');

    let actionContent: ReactNode;
    let actionAdditionalProps: ButtonProps = {};

    const [isDialogVisible, setDialogVisible] = useToggle(false);

    const [request, result] = useAddService({
        onSuccess: ({ service_id }) => {
            onAddService();
            onDecLoadingServices();
            onAddServiceSuccess?.({ person_id, service_id });
        },
        onError: (e, { provider_detail_id, person_id }) => {
            errorToast(e, { title: i18n('add_service_error_message') });
            onDecLoadingServices();
            onAddServiceError?.({ provider_detail_id, person_id });
        },
        options: {
            hideErrorNotifier: true,
        },
    });

    switch (result._tag) {
        case RemoteTag.LOADING:
            actionContent = (
                <Spin
                    progress
                    size="xxs"
                    view="default"
                />
            );
            actionAdditionalProps = { disabled: true };
            break;

        case RemoteTag.SUCCESS:
            actionContent = (
                <IconCheck />
            );
            actionAdditionalProps = {
                checked: true,
                disabled: true,
            };
            break;

        default:
            actionContent = (
                <Text
                    typography="body-s"
                    weight="medium"
                >
                    {i18nAction('add')}
                </Text>
            );
    }

    const addService = useCallback(() => {
        onIncLoadingServices();
        request(getServiceCreatePayload(person_id));
        onAddServiceStart?.(person_id);
    }, [onIncLoadingServices, request, getServiceCreatePayload, person_id, onAddServiceStart]);

    const onActionClick = useCallback(() => {
        if (isViolatesTravelPolicy) {
            setDialogVisible();
        } else {
            addService();
        }
    }, [addService, setDialogVisible, isViolatesTravelPolicy]);

    const onDialogConfirmClick = useCallback(() => {
        addService();
        setDialogVisible();
    }, [addService, setDialogVisible]);

    const isButtonDisabled = actionAdditionalProps.disabled;

    const action = (
        <Button
            {...actionAdditionalProps}
            className={cn('AddButton')}
            disabled={isButtonDisabled}
            size="s"
            view="outline"
            onClick={onActionClick}
        >
            {actionContent}
        </Button>
    );

    return (
        <>
            <PersonListItemBase
                transparent
                className={cn('PersonListItem')}
                login={login}
                name={personFullName}
                trailing={action}
            />
            <Dialog
                close={i18nAction('cancel')}
                closeButtonView="ghost"
                confirm={i18nTravelPolicy('add_service')}
                scope={null}
                subtitle={getTravelPolicyMessage()}
                title={i18nTravelPolicy('add_not_compliant_service')}
                visible={isDialogVisible}
                onClose={setDialogVisible}
                onConfirm={onDialogConfirmClick}
            />
        </>
    );
};
