import { useContext } from 'react';

import { CurrentCompanyContext } from 'components/CurrentCompanyProvider/CurrentCompanyProvider.context';
import { EmployeesGroupForm } from 'components/EmployeesGroupForm';
import { HeaderPage } from 'components/HeaderPage';
import { Page } from 'components/Page';
import { RouteGroupAdd } from 'routes/RouteGroupAdd';
import { Container } from 'shared/ui/Container';

import { cn } from './PageGroupAdd.cn';
import { i18n } from './PageGroupAdd.i18n';

export const PageGroupAdd: Page<RouteGroupAdd> = () => {
    const { currentCompanyId } = useContext(CurrentCompanyContext);

    return (
        <Page className={cn()}>
            <HeaderPage title={i18n('new_group_title')} />
            <Container>
                <EmployeesGroupForm companyId={currentCompanyId} groupDetails={null} />
            </Container>
        </Page>
    );
};

PageGroupAdd.displayName = cn();
