import { FC } from 'react';

import { FieldSelect } from 'components/FormField2';
import { i18nAviaClass } from 'i18n/i18nAviaClass';
import { ClassAvia } from 'services/SwaggerApi';

import { cn } from '../FlightFareFamily.cn';

import { FieldFlightClassProps } from './FieldFlightClass.types';

export const FieldFlightClass: FC<FieldFlightClassProps> = props => {
    const { flightClasses, unsafeRef, className } = props;

    if (flightClasses.length < 2) {
        return null;
    }

    const flightClassOptions = flightClasses.map(value => ({
        value,
        content: i18nAviaClass(`avia_class_${value.toLowerCase() as Lowercase<ClassAvia>}`),
    }));

    return (
        <FieldSelect
            className={cn('FieldFlightClass', [className])}
            name="flightClass"
            options={flightClassOptions}
            size="s"
            unsafe_scope={unsafeRef}
            view="outline"
        />
    );
};
