import { FC } from 'react';

import { TravellerDocuments } from '../TravellerDocuments';

import { CompanionDocumentsProps } from './CompanionDocuments.types';

export const CompanionDocuments: FC<CompanionDocumentsProps> = props => {
    const {
        service,
        extService,
        allowedDocuments,
        className,
    } = props;

    const personInfo = { ...extService.ext_person, person_id: extService.ext_person_uuid };
    const extPersonAllowedDocuments = allowedDocuments.filter(document =>
        document.ext_person_uuid === extService.ext_person_uuid,
    );

    return (
        <>
            <TravellerDocuments
                actions={service.actions}
                allowedDocuments={extPersonAllowedDocuments}
                className={className}
                person={extService.ext_person}
                personInfo={personInfo}
                personType="extPerson"
                service={extService}
            />
        </>
    );
};
