import { config } from 'config';
import { PersonDetailsPublic, PersonTrip, Trip } from 'services/SwaggerApi';
import { isConferenceTrip } from 'utils/isConferenceTrip';

import { PersonalDataKeys } from '../PersonalData.i18n';

const checkAreConfFieldsFilled = (trip: Trip, personTrip: PersonTrip): boolean => {
    const isConference = isConferenceTrip(trip);

    if (!isConference) {
        return true;
    }

    if (!personTrip.conf_details) {
        return false;
    }

    const {
        role,
        badge_name,
        badge_position,
    } = personTrip.conf_details;

    return Boolean(role && badge_name && badge_position);
};

const { isB2B } = config;

type GetPersonalDataErrorMessage = (
    trip: Trip,
    person: PersonDetailsPublic,
    personTrip: PersonTrip,
) => PersonalDataKeys | null;

export const getPersonalDataErrorMessage: GetPersonalDataErrorMessage = (trip, person, personTrip) => {
    const {
        has_date_of_birth,
        has_first_name_en,
        has_last_name_en,
        has_phone_number,
        gender,
    } = person;

    const hasRequiredConfFields = checkAreConfFieldsFilled(trip, personTrip);

    if (!has_date_of_birth) {
        return 'missing_date_of_birth';
    }

    if (!has_first_name_en) {
        return 'missing_first_name_en';
    }

    if (!has_last_name_en) {
        return 'missing_last_name_en';
    }

    if (!hasRequiredConfFields) {
        return 'missing_conf_fields';
    }

    if (!has_phone_number && isB2B) {
        return 'missing_phone_number';
    }

    if (!gender && isB2B) {
        return 'missing_gender';
    }

    return null;
};
