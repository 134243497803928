import { FC } from 'react';

import {
    IconBaggageOff,
    IconBaggageOn,
    IconCheckCircle,
    IconCloseCircle,
    IconHandBaggageOff,
    IconHandBaggageOn,
} from 'components/Icon';
import { OptionInfoItem } from 'components/OptionInfoItem';
import { i18nServiceInfo } from 'i18n/i18nServiceInfo';
import { Flight, ProviderSegment } from 'services/SwaggerApi';
import { getBaggageInfo } from 'utils/getBaggageInfo';

import { cn } from '../FlightServiceCard.cn';

import './FlightCardOptions.css';

type FlightCardOptionsProps = {
    baggage: ProviderSegment['baggage'];
    isChangeable: Flight['is_changeable'];
    isRefundable: Flight['is_refundable'];
};

export const FlightCardOptions: FC<FlightCardOptionsProps> = props => {
    const {
        baggage,
        isChangeable,
        isRefundable,
    } = props;

    const { hasBaggage, baggageText } = getBaggageInfo(baggage?.baggage);
    const {
        hasBaggage: hasHandBaggage,
        baggageText: handBaggageText,
    } = getBaggageInfo(baggage?.hand_baggage, { handBaggage: true });

    const isNoInfoAboutChange = isChangeable ?? true;
    const isNoInfoAboutRefund = isRefundable ?? true;

    return (
        <div className={cn('TariffInfo')}>
            <OptionInfoItem
                hasSuccessColor
                condition={hasHandBaggage}
                failureIcon={IconHandBaggageOff}
                failureText={handBaggageText}
                successIcon={IconHandBaggageOn}
                successText={handBaggageText}
            />
            <OptionInfoItem
                hasSuccessColor
                condition={hasBaggage}
                failureIcon={IconBaggageOff}
                failureText={baggageText}
                successIcon={IconBaggageOn}
                successText={baggageText}
            />
            <OptionInfoItem
                hasSuccessColor
                condition={Boolean(isChangeable)}
                failureIcon={IconCloseCircle}
                failureText={isNoInfoAboutChange ? i18nServiceInfo('unknown_changeable') : i18nServiceInfo('not_changeable')}
                successIcon={IconCheckCircle}
                successText={i18nServiceInfo('changeable')}
            />
            <OptionInfoItem
                hasSuccessColor
                condition={Boolean(isRefundable)}
                failureIcon={IconCloseCircle}
                failureText={isNoInfoAboutRefund ? i18nServiceInfo('unknown_refundable') : i18nServiceInfo('not_refundable')}
                successIcon={IconCheckCircle}
                successText={i18nServiceInfo('refundable')}
            />
        </div>
    );
};
