export const en = {
    in_process_of_cancelling: 'In process of cancelling',
    draft: 'Draft',
    new: 'New',
    verification: 'Verification',
    in_progress: 'Handled by agent',
    executed: 'Booked',
    cancelled: 'Cancelled',
    deleted: 'Deleted',
    refunded: 'Refund',
    reserved: 'Reserved',
};
