import { FC, useCallback, useContext } from 'react';
import { withBemMod } from '@bem-react/core';

import { CompanyQuerySuggestItem } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../../../SwaggerContext';
import { cn } from '../Suggest.cn';
import { i18n } from '../Suggest.i18n';
import {
    RenderOptionContent,
    RenderSelectedValue,
    SuggestProps,
} from '../Suggest.types';

import './Suggest_type_konturCompanies.css';

export interface WithTypeKonturCompaniesProps {
    type?: 'konturCompanies';
}

const getCompanyTitle = (company: CompanyQuerySuggestItem) => {
    switch (company.type) {
        case 'IP':
            return `ИП ${company.fio}`;

        case 'UL':

        default:
            return company.longName?.toUpperCase();
    }
};

export const withTypeKonturCompanies = withBemMod<WithTypeKonturCompaniesProps, SuggestProps>(
    cn(),
    { type: 'konturCompanies' },
    (Suggest): FC<WithTypeKonturCompaniesProps & SuggestProps> => props => {
        const { placeholder = i18n('kontur_companies_placeholder') } = props;

        const { api } = useContext(SwaggerContext);

        const dataprovider = useCallback(async text => {
            if (!text) {
                return [];
            }

            const { items } = await api.kontur_company_suggest({ text }).then(throwHTTPErrors);

            return items.slice(0, 4).map(company => ({
                ...company,
                id: company.inn,
                title: getCompanyTitle(company),
            }));
        }, [api]);

        const renderOptionContent = useCallback<RenderOptionContent>(item => {
            return (
                <div className={cn('KonturCompaniesOptionContent')}>
                    <Text as="div" className={cn('CompanyName')}>
                        {(item.title as string).toUpperCase()}
                    </Text>
                    <Text as="div" color="secondary">
                        {[
                            item.inn && `${i18n('inn')}: ${item.inn}`,
                            item.kpp && `${i18n('kpp')}: ${item.kpp}`,
                            item.address && item.address,
                        ].filter(Boolean).join(' • ')}
                    </Text>
                </div>
            );
        }, []);

        const renderSelectedValue = useCallback<RenderSelectedValue>(item => `${item.title}`, []);

        return (
            <Suggest
                {...props}
                dataprovider={dataprovider}
                placeholder={placeholder}
                renderOptionContent={renderOptionContent}
                renderSelectedValue={renderSelectedValue}
            />
        );
    },
);
