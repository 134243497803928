export const ru = {
    in_process_of_cancelling_title: 'Отмена услуги обрабатывавается агентством',
    draft_title: 'Услуга добавлена в командировку, но еще не отправлена на оформление',
    new_title: 'Новая услуга',
    verification_title: 'Услуга проверяется координатором',
    in_progress_title: 'Услуга обрабатывается агентом',
    reserved_title: 'Услуга забронирована',
    executed_title: 'Услуга оформлена',
    cancelled_title: 'Услуга отменена',
    deleted_title: 'Услуга удалена',
    refunded_title: 'Оформлен возврат по услуге',
    in_process_of_cancelling_description: 'По завершении статус изменится на «Отменено».',
    draft_description: 'Как только вы добавите все остальные услуги по командировке и нажмете кнопку "Оформить командировку", начнется оформление билетов и бронирование отелей. Рекомендуем добавлять сразу все услуги и отправлять командировку на оформление, так как цены могут меняться, а места — оказаться последними.',
    new_description: 'В услугу добавлен участник и документы, но сама услуга еще не отправлена на оформление',
    verification_description: 'Услуга выходит за рамки тревел-политики, поэтому отправлена координатору для проверки необходимых согласований. Обычно это занимает не более 24 часов.',
    in_progress_description: 'Обычно этот процесс занимает не более {timespan}, по завершении вам придет уведомление. Если услуга недоступна или ее стоимость изменилась, с вами свяжутся в чате или тикете.',
    timespan_rail: 'пары минут',
    timespan_avia: 'часа',
    timespan_hotel: '24 часов',
    reserved_description: 'Забронировано ровно то, что вы хотели. Осталось дождаться статуса «Оформлено».',
    executed_description: 'Услуги оформлены. Если ваши планы изменятся, услугу можно удалить, но обратите внимание, что оформленные услуги чаще всего отменяются со штрафами.',
    cancelled_description: 'Обратитесь в поддержку, если эта отмена для вас неожиданна: вам помогут добавить новую услугу и узнать, что случилось с прежней.',
    deleted_description: '',
    refunded_description: '',
};
