import { FC } from 'react';
import { TagProps } from '@yandex-int/hr-components/Tag2/bundle';

import { i18nServiceState } from 'components/ServiceState/i18nServiceState';
import { TagHint } from 'components/TagHint';

import { cn } from './ServiceState.cn';
import { AllServiceStatuses, ServiceStateProps } from './ServiceState.types';
import { ServiceStateHint } from './ServiceStateHint';

import './ServiceState.css';

const statusColorMap: {[status in AllServiceStatuses]: TagProps['color']} = {
    draft: 'blue',
    new: 'purple',
    verification: 'purple',
    in_progress: 'purple',
    in_process_of_cancelling: 'purple',
    reserved: 'purple',
    executed: 'green',
    cancelled: 'red',
    deleted: 'blue',
    refunded: 'blue',
};

export const ServiceState: FC<ServiceStateProps> = props => {
    const { state, isCancelling, className, serviceType } = props;

    const serviceState = isCancelling ? 'in_process_of_cancelling' : state;

    const tagText = i18nServiceState(serviceState);
    const color = statusColorMap[serviceState];

    return (
        <TagHint
            className={cn(null, [className])}
            color={color}
            desktopTrigger="hover"
            hintContent={
                <ServiceStateHint
                    serviceType={serviceType}
                    state={serviceState}
                />
            }
            tagText={tagText}
        />
    );
};

ServiceState.displayName = cn();
