import { compose } from '@bem-react/core';
import { ILoggableProps, logClick, withBaobab } from '@yandex-int/react-baobab-logger';
import {
    Modal as ModalDesktop,
    withThemeNormal,
} from '@yandex-lego/components/Modal/desktop';
import { withZIndex } from '@yandex-lego/components/withZIndex';

import './Modal.css';

// Композиция из различных модификаторов
const LegoModal = compose(
    withThemeNormal,
    withZIndex,
)(ModalDesktop);

export type LegoModal = Parameters<typeof LegoModal>[0];

// Настройки для Baobab
const baobabButtonOptions = {
    name: 'Button',
    events: {
        onClick: logClick({ type: 'click' }),
    },
};

export type Modal = ILoggableProps<LegoModal>;
export const Modal = withBaobab<LegoModal>(baobabButtonOptions)(LegoModal);
