import { presets } from '@yandex-int/messenger.widget';

import { SERVICE_ID_EXTERNAL } from 'shared/constants/messenger';

import { ConfigState } from './config.types';
import { getVersion } from './getVersion';

export const config: ConfigState = {
    hosts: {
        staff: {
            host: 'staff.yandex-team.ru',
        },
        center: {
            host: 'center.yandex.ru',
        },
        passport: {
            host: 'passport.yandex.ru',
        },
        messenger: {
            host: 'yandex.ru',
        },
        passportAutofill: {
            host: 'autofill.yandex.ru',
        },
    },
    messengerLink: 'https://yandex.ru/chat/#/user/',
    messengerWidgetConfigPreset: presets.backend.prod,
    messengerWidgetID: SERVICE_ID_EXTERNAL,
    env: 'production',
    isB2B: true,
    version: getVersion(),
};
