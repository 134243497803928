import { FC } from 'react';

import { TrainPlaceSelect } from 'components/TrainPlaceSelect';

import { cn } from './TrainCarriageFallback.cn';
import { TrainCarriageFallbackProps } from './TrainCarriageFallback.types';

import './TrainCarriageFallback.css';

export const TrainCarriageFallback: FC<TrainCarriageFallbackProps> = props => {
    const {
        availablePlaces,
        onChange,
        chosenPlaces,
        currentSelectedSeat,
        canAddServices,
    } = props;

    const isReadOnly = !onChange || !canAddServices;

    return (
        <div className={cn(null, [props.className])}>
            {availablePlaces.map(place => (
                <TrainPlaceSelect
                    key={place}
                    className={cn('seat')}
                    currentSelected={currentSelectedSeat === place}
                    isReadOnly={isReadOnly}
                    number={place}
                    selected={chosenPlaces.includes(place)}
                    onClick={onChange}
                />
            ))}
        </div>
    );
};

TrainCarriageFallback.displayName = cn();
