import { FC } from 'react';
import { Game as GameBase } from '@yandex-int/hr-components/Game';

import { ErrorState } from 'shared/ui/ErrorState';

import { cn } from './Game.cn';
import { GameProps } from './Game.types';

export const Game: FC<GameProps> = props => {
    const {
        gameProps,
        errorStateProps,
        className,
    } = props;

    return (
        <ErrorState
            {...errorStateProps}
            className={cn(null, [className])}
            image={<GameBase {...gameProps} />}
        />
    );
};

Game.displayName = cn();
