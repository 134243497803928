import { FC, ReactNode } from 'react';

import { Spinner } from 'components/Spinner';
import { TrainCarriageFallback } from 'components/TrainCarriageFallback';
import { isFailure, isPending } from 'utils/Loadable';

import { useTrainTravel } from './TrainCarriage.hooks/useTrainTravel';
import { TrainCarriage } from './TrainCarriage';
import { cn } from './TrainCarriage.cn';
import { i18n } from './TrainCarriage.i18n';
import { TrainCarriageContainerProps } from './TrainCarriage.types';

export const TrainCarriageContainer: FC<TrainCarriageContainerProps> = props => {
    const {
        train,
        availablePlaces,
        carriageNumber,
        chosenPlaces,
        onChange,
        currentSelectedSeat,
        className,
        canAddServices,
    } = props;

    const trainTravelRD = useTrainTravel(train, carriageNumber);

    let content: ReactNode = null;

    if (isPending(trainTravelRD)) {
        content = <Spinner message={i18n('schema_loading')} />;
    } else if (isFailure(trainTravelRD) || trainTravelRD.result === null) {
        content = (
            <TrainCarriageFallback
                availablePlaces={availablePlaces}
                canAddServices={canAddServices}
                chosenPlaces={chosenPlaces}
                currentSelectedSeat={currentSelectedSeat}
                onChange={onChange}
            />
        );
    } else {
        const { coach, schema } = trainTravelRD.result;

        content = (
            <TrainCarriage
                availablePlaces={availablePlaces}
                canAddServices={canAddServices}
                chosenPlaces={chosenPlaces}
                coach={coach}
                currentSelectedSeat={currentSelectedSeat}
                schema={schema}
                onChange={onChange}
            />
        );
    }

    return (
        <div className={cn(null, [className])}>
            {content}
        </div>
    );
};

TrainCarriageContainer.displayName = cn();
