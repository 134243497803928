import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Cell } from 'components/Cell';
import { FieldSuggest, FormProvider } from 'components/FormField2';
import { withTdAttrs } from 'hocs/withTdAttrs';

import { cn } from './CellSuggest.cn';
import { CellSuggestProps } from './CellSuggest.types';

const CellSuggestBase: FC<CellSuggestProps> = props => {
    const {
        className,
        value,
        type,
        onChange,
        getAttrs,
        suggestProps = {},
    } = props;

    const methods = useForm({
        defaultValues: {
            suggest: value,
        },
    });

    useEffect(() => {
        const subscription = methods.watch(data => {
            onChange(data);
        });

        return () => subscription.unsubscribe();
    }, [methods, onChange]);

    return (
        <Cell
            className={cn(null, [className])}
            getAttrs={getAttrs}
            justifyContent="stretch"
            size="m"
        >
            <FormProvider methods={methods}>
                <form className={cn('Form')}>
                    <FieldSuggest
                        {...suggestProps}
                        name="suggest"
                        type={type}
                    />
                </form>
            </FormProvider>
        </Cell>
    );
};

export const CellSuggest = withTdAttrs(CellSuggestBase);
CellSuggest.displayName = cn();
