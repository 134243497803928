import { config } from 'config';
import { ConfDetailsCreate, PersonConfDetailsCreateInTrip, PersonTripCreateInTrip, TripCreate } from 'services/SwaggerApi';
import { getFieldsType } from 'utils/getFieldsType';
import { serializeDate } from 'utils/serializeDate';

import { PersonsConfField, SearchFormValues } from '../FormTripCreate.types';

import { getVisaRequired } from './getVisaRequired';

type ConfDetailsPayment = Pick<ConfDetailsCreate, 'price' | 'ticket_type' | 'need_compensation' | 'promo_code' | 'need_help'>;

const getRouteFieldsValues = (form: SearchFormValues): Pick<TripCreate, 'route'> => {
    const purposes = form.purposes || [];
    const isConference = getFieldsType(purposes) === 'conf';
    const isAnotherCity = form.is_another_city === 'true';

    if (!(isConference && isAnotherCity && form.route) && isConference) {
        return {};
    }

    const isSimpleRoute = form.route.length === 2;
    const simpleRouteDates = form.dates;

    if (isSimpleRoute) {
        return ({
            route: form.route.map((point, index) => ({
                geoid: point.cityFrom.geoid,
                city: point.cityFrom.name || '',
                country: point.cityFrom.country || '',
                date: index === 0 ? serializeDate(simpleRouteDates.from) || '' : serializeDate(simpleRouteDates.to) || '',
            })),
        });
    }

    return ({
        route: form.route.map(point => ({
            geoid: point.cityFrom.geoid,
            city: point.cityFrom.name || '',
            country: point.cityFrom.country || '',
            date: serializeDate(point.date),
        })),
    });
};

type PersonToPersonTripCreateInOptions = { isConference: boolean; isVisaRequired: boolean };

const personToPersonTripCreateIn = ({
    isConference,
    isVisaRequired,
}: PersonToPersonTripCreateInOptions) => (person: PersonsConfField) => {
    const result: PersonTripCreateInTrip = {
        person_uid: person.uid,
    };

    if (isVisaRequired) {
        result.need_visa = person.need_visa === 'true';
    }

    if (isConference) {
        const role = person.role;
        const confDetails: PersonConfDetailsCreateInTrip = {
            badge_name: person.badge_name,
            badge_position: person.badge_position,
            role,
            is_offline: person.is_offline === 'true',
        };

        if (role === 'speaker') {
            confDetails.presentation_topic = person.presentation_topic;
            confDetails.is_hr_approved = Boolean(person.is_hr_approved);
            confDetails.is_paid_by_host = Boolean(person.is_paid_by_host);
        }

        if (role === 'other') {
            confDetails.other_role = person.other_role;
        }

        result.conf_details = confDetails;
    }

    if (person.description) {
        result.description = person.description;
    }

    return result;
};

export function formValuesToTripCreate(form: SearchFormValues): TripCreate {
    const purposes = form.purposes || [];
    const isConference = getFieldsType(purposes) === 'conf';

    const route = form.route || [];

    const isVisaRequired = getVisaRequired(route, config.isB2B);

    const result: TripCreate = {
        comment: form.comment || '',
        description: '',
        purposes,
        with_days_off: false,
        ...(getRouteFieldsValues(form)),
        person_trips: form.persons.map(personToPersonTripCreateIn({ isConference, isVisaRequired })),
        date_from: serializeDate(form.route?.[0].date) || '',
        date_to: serializeDate(form.route?.[form.route.length - 1].date) || '',
        ...(route.length === 2) && {
            date_from: serializeDate(form.dates?.from) || '',
            date_to: serializeDate(form.dates?.to) || '',
        },
    };

    if (isConference) {
        result.date_from = result.date_from || serializeDate(form.conf_dates.from) || '';
        result.date_to = result.date_to || serializeDate(form.conf_dates.to) || '';

        // Degrade to '0' will be deleted in https://st.yandex-team.ru/BTRIP-3973
        let price = form.price;
        const isConfPaymentTypePaid = form.conf_payment_type === 'paid';

        if (!isConfPaymentTypePaid || typeof price === 'undefined') price = '0';

        let confDetailsPayment: ConfDetailsPayment = {
            price,
        };

        if (isConfPaymentTypePaid) {
            confDetailsPayment = {
                price,
                ticket_type: form.ticket_type,
                need_compensation: Boolean(form.need_compensation),
                promo_code: form.promo_code,
            };
        } else {
            confDetailsPayment = {
                price,
                need_help: Boolean(form.need_help),
            };
        }

        result.conf_details = {
            ...confDetailsPayment,
            conf_is_paid: isConfPaymentTypePaid,
            conference_name: form.conference_name,
            is_another_city: form.is_another_city === 'true',
            conf_date_from: serializeDate(form.conf_dates.from) || '',
            conf_date_to: serializeDate(form.conf_dates.to) || '',
            conference_url: form.conference_url,
            participation_terms: form.participation_terms,
            cancellation_terms: '',
            program_url: form.program_url,
        };

        if (form.conf_city && result.conf_details) {
            result.conf_details.conf_city = form.conf_city.name;
        }
    }

    return result;
}
