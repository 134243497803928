import { ComponentProps, FC } from 'react';

import { Textinput } from 'shared/ui/Textinput';

import { withFormField } from '../../FormField2';
import { cn as cnFormField } from '../../FormField2.cn';
import { FormFieldProps } from '../../FormField2.types';

import { cn } from './FieldTextinput.cn';

import './FieldTextinput.css';

export type WithTypeTextinputProps = ComponentProps<typeof Textinput>;

const Control: FC<WithTypeTextinputProps & FormFieldProps> = props => {
    const {
        hasClear = true,
        size = 'm',
        disabled,
        name,
        controller,
        context,
        ...inputProps
    } = props;

    const {
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
    } = controller;

    let state: WithTypeTextinputProps['state'];

    if (error) {
        state = 'error';
    }

    return (
        <Textinput
            {...inputProps}
            className={cnFormField('Control', { name }, [cn()])}
            controlRef={ref}
            disabled={disabled}
            hasClear={hasClear}
            id={name}
            name={name}
            size={size}
            state={state}
            value={value}
            view="outline"
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};

export const FieldTextinput = withFormField(Control);
