import { FC, useCallback } from 'react';

import { SelectApproverForm } from 'components/SelectApproverForm';
import { withModalView } from 'hocs/withModalView';
import { i18nAction } from 'i18n/i18nAction';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';
import { mapApproverToFieldPersonValue, serializePerson } from 'utils/serializePerson';

import { useEmployeeChangeApprover } from './EmployeeChangeApproverModal.hooks/useEmployeeChangeApprover';
import { cn } from './EmployeeChangeApproverModal.cn';
import { i18n } from './EmployeeChangeApproverModal.i18n';
import { EmployeeChangeApproverModalProps, HandleSubmit } from './EmployeeChangeApproverModal.types';

import './EmployeeChangeApproverModal.css';

const EmployeeChangeApproverModalPresenter: FC<EmployeeChangeApproverModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        className,
        employee,
    } = props;

    const {
        employee_id,
        company_id,
        approver,
    } = employee;

    const defaultValues = {
        approver: mapApproverToFieldPersonValue(approver),
    };
    const [changeApprover, changingState, { error }] = useEmployeeChangeApprover({
        onSuccess: onSubmit,
        options: {
            invalidateOnSuccess: true,
            hideValidationErrors: true,
        },
    });

    const isChanging = isLoading(changingState);

    const handleSubmit = useCallback<HandleSubmit>(formFields => {
        changeApprover(company_id, employee_id, {
            approver_id: serializePerson(formFields.approver)!,
        });
    }, [changeApprover, company_id, employee_id]);

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <SelectApproverForm
                buttonSubmitText={i18nAction('save')}
                defaultValues={defaultValues}
                employee={employee}
                error={error}
                progress={isChanging}
                textBeforeTag={i18n('approver_for')}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

EmployeeChangeApproverModalPresenter.displayName = cn();

export const EmployeeChangeApproverModal = withModalView(EmployeeChangeApproverModalPresenter);
