import * as yup from 'yup';

import { i18n } from './SelectCompanyForm.i18n';
import { SelectCompanyFormFields } from './SelectCompanyForm.types';

type CompanyId = SelectCompanyFormFields['company_id'];

export const schema: yup.SchemaOf<SelectCompanyFormFields> = yup.object({
    company_id: yup.mixed<CompanyId>().required(() => i18n('company_required')),
});
