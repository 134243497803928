import { FC, useEffect } from 'react';
import { useFieldArray } from 'react-final-form-arrays';

import { uuidv4 } from 'shared/lib/uuidv4';

import { ComplexRoute } from '../FormTripCreate-ComplexRoute';
import { SimpleRoute } from '../FormTripCreate-SimpleRoute';

import { RoutePointForm } from './RouteFields.types';

// eslint-disable-next-line react-refresh/only-export-components
export const getEmptyRoutePoint = (): RoutePointForm => ({
    cityFrom: undefined,
    cityTo: undefined,
    date: undefined,
    id: uuidv4(),
});

export const RouteFields: FC = () => {
    const { fields } = useFieldArray<RoutePointForm>('route');

    useEffect(() => {
        if (fields.length && fields.length >= 2) return;

        for (let i = 0; i < 2; i++) {
            fields.push({ ...getEmptyRoutePoint() });
        }
    }, [fields]);

    if (!fields.length) {
        return null;
    }

    const isComplexRoute = fields.length && fields.length > 2;

    return isComplexRoute ?
        <ComplexRoute fields={fields} /> :
        <SimpleRoute fields={fields} />;
};
