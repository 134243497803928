import { FC } from 'react';

import { ListItem } from 'shared/ui/ListItem';

import { cn } from './FloatingIsland.cn';
import { FloatingIslandProps } from './FloatingIsland.types';

import './FloatingIsland.css';

/**
 * Плавающий островок внизу страницы мобилки
 */
export const FloatingIsland: FC<FloatingIslandProps> = props => {
    const {
        className,
        onClick,
        leadingTextParams,
        leading,
        trailing,
        trailingTextParams,
    } = props;

    return (
        <div className={cn(null, [className])} onClick={onClick}>
            <ListItem
                border="m"
                leading={leading}
                leadingTextParams={leadingTextParams}
                padding="m"
                trailing={trailing}
                trailingTextParams={trailingTextParams}
                transparent={false}
            />
        </div>
    );
};

FloatingIsland.displayName = cn();
