import { FC, useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Tooltip } from '@yandex-int/hr-components/Tooltip/desktop/bundle';

import {
    FieldDatePicker,
    FieldRadioButton,
    FieldRangeDatePicker,
    FieldSuggest,
    FormProvider,
} from 'components/FormField2';
import { getIconProvider, IconSort, IconSwap } from 'components/Icon';
import { useCreateSearch } from 'hooks/useCreateSearchId';
import { useMeta } from 'hooks/useMeta';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { serializeDate } from 'utils/serializeDate';

import { useWatchSearchType } from './SearchMain.hooks/useWatchSearchType';
import { PassengersInput } from './PassengersInput';
import { cn } from './SearchMain.cn';
import { MAX_HOTEL_ADULT } from './SearchMain.constants';
import { i18n } from './SearchMain.i18n';
import { schema, SearchMainSchema } from './SearchMain.schema';
import { SearchMainFormFields, SearchMainProps } from './SearchMain.types';

import './SearchMain.css';

const iconProviderSwap = getIconProvider(IconSwap);

const getSearchTypeOptions = () => [
    { value: 'Avia', children: i18n('flight_tickets') },
    { value: 'Hotel', children: i18n('hotels') },
    { value: 'Rail', children: i18n('train_tickets') },
];

const stringToDate = (date?: string): Date | undefined => {
    if (date) {
        return new Date(date);
    }

    return undefined;
};

export const SearchMain: FC<SearchMainProps> = props => {
    const {
        search,
        person_id,
        onTypeChange,
        onStartSearch,
        className,
        tooltipText,
    } = props;

    const { user: { ext_persons_feature_enabled } } = useMeta();

    const { isMobile, isDesktop } = usePlatform();
    const anchorRef = useRef<HTMLElement>(null);

    const { isSearchLoading, createSearch } = useCreateSearch(onStartSearch);

    const date_from = stringToDate(search?.date_from);
    const date_to = stringToDate(search?.date_to);

    const methods = useForm<SearchMainFormFields>({
        defaultValues: {
            ...search,
            dateRange: {
                from: date_from,
                to: date_to,
            },
            type: search?.type || 'Avia',
            class_avia: search?.class_avia || 'Econom',
            adult: ext_persons_feature_enabled && search?.adult ? search?.adult : 1,
        },
        mode: 'onSubmit',
        resolver: yupResolver(schema),
    });

    const [searchType, adult, classAvia] = methods.watch(['type', 'adult', 'class_avia']);

    useEffect(() => {
        if (searchType === 'Hotel' && adult > MAX_HOTEL_ADULT) {
            methods.setValue('adult', MAX_HOTEL_ADULT);
        }
    }, [adult, methods, searchType]);

    useWatchSearchType(methods, onTypeChange);

    const handleSubmit = useCallback((values: SearchMainFormFields) => {
        const formValues = values as unknown as SearchMainSchema;
        const { class_avia, type, city_from, city_to, dateRange, adult } = formValues;

        createSearch({
            type,
            to_geoid: city_to.id,
            from_geoid: city_from?.id,
            toCountryName: city_to.country,
            departure_on: serializeDate(dateRange.from),
            departure_back_on: serializeDate(dateRange.to),
            class_avia,
            person_id,
            trip_id: search?.trip_id,
            adult,
        });

        methods.reset({}, { keepValues: true });
    }, [createSearch, methods, person_id, search?.trip_id]);

    const swapDirections = useCallback(() => {
        const { city_from, city_to } = methods.getValues();

        methods.setValue('city_from', city_to ?? null, { shouldDirty: true });
        methods.setValue('city_to', city_from ?? null, { shouldDirty: true });
    }, [methods]);

    const showNewSearchTooltip = isDesktop && (Boolean(tooltipText) || methods.formState.isDirty);

    const canChangeAviaTicketClass = searchType === 'Avia';
    const canChangeGuestsCount = ext_persons_feature_enabled && searchType === 'Hotel';

    return (
        <FormProvider methods={methods}>
            <form
                className={cn(null, [className])} onSubmit={methods.handleSubmit(handleSubmit)}
            >
                <FieldRadioButton
                    className={cn('SearchType')}
                    name="type"
                    options={getSearchTypeOptions()}
                    width="max"
                />
                <div className={cn('SearchFields')}>
                    {searchType !== 'Hotel' && (
                        <FieldSuggest
                            hideErrors
                            autoComplete="off"
                            className={cn('CityFrom')}
                            hasClear={isDesktop}
                            iconRight={isMobile ? <IconSort onClick={swapDirections} /> : undefined}
                            name="city_from"
                            placeholder={i18n('city_from')}
                            suggestView={isDesktop ? 'tags' : 'text'}
                            type="cities"
                        />
                    )}
                    {isDesktop && searchType !== 'Hotel' && (
                    <Button
                        className={cn('SwapDirections')}
                        data-testid="SwapDirections"
                        icon={iconProviderSwap}
                        size={isDesktop ? 'l' : 'm'}
                        view={isDesktop ? 'outline' : 'secondary'}
                        onClick={swapDirections}
                    />
                    )}
                    <FieldSuggest
                        hideErrors
                        autoComplete="off"
                        className={cn('CityTo', { hotel: searchType === 'Hotel' })}
                        hasClear={isDesktop}
                        name="city_to"
                        placeholder={i18n('city_to')}
                        suggestView={isDesktop ? 'tags' : 'text'}
                        type="cities"
                    />
                    <div className={cn('Dates', { avia: searchType === 'Avia' })}>
                        {searchType === 'Rail' ? (
                            <FieldDatePicker
                                showSubText
                                useDateRangeFormat
                                inputClassName={cn('DateInput', { rail: searchType === 'Rail' })}
                                inputPlaceholder={i18n('date_from')}
                                name="dateRange"
                            />
                        ) : (
                            <FieldRangeDatePicker
                                hideErrors
                                showSubText
                                differentDates={searchType === 'Hotel'}
                                leftInputClassName={cn('DateInput', { roundedLeft: !ext_persons_feature_enabled && searchType === 'Hotel' })}
                                leftInputPlaceholder={i18n(searchType === 'Hotel' ? 'checkin_date' : 'date_from')}
                                name="dateRange"
                                rightInputClassName={cn('DateInput', { roundedRight: !ext_persons_feature_enabled && searchType === 'Hotel' })}
                                rightInputPlaceholder={i18n(searchType === 'Hotel' ? 'checkout_date' : 'date_to')}
                            />
                        )}
                    </div>
                    {
                        (canChangeAviaTicketClass || canChangeGuestsCount) && (
                            <PassengersInput
                                adult={adult}
                                classAvia={classAvia}
                                isPlusOneEnabled={ext_persons_feature_enabled}
                                searchType={searchType}
                            />
                        )
                    }
                    <Tooltip
                        disableOverlay
                        hasTail
                        anchor={anchorRef}
                        className={cn('Hint')}
                        direction={['top', 'bottom']}
                        size="l"
                        view="default"
                        visible={showNewSearchTooltip}
                    >
                        {tooltipText || i18n('press_search')}
                    </Tooltip>
                    <Button
                        className={cn('SearchButton')}
                        innerRef={anchorRef}
                        progress={isSearchLoading}
                        size={isMobile ? 'l' : 'm'}
                        type="submit"
                        view={'brand'}
                        width={isMobile ? 'max' : undefined}
                    >
                        <Text typography="body-m">
                            {i18n('search')}
                        </Text>
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};
