import { Citizenship } from 'services/SwaggerApi';
import { getTypedI18nLang } from 'utils/i18n';

type Locale = { [key in Citizenship]: string };

const locales: { en: Locale; ru: Locale } = {
    en: {
        AM: 'Armenia',
        BY: 'Belarus',
        KG: 'Kyrgyzstan',
        KZ: 'Kazakhstan',
        LV: 'Latvia',
        RU: 'Russian federation',
        TJ: 'Tajikistan',
        UA: 'Ukraine',
        UZ: 'Uzbekistan',
        OTHER: 'Other',
    },
    ru: {
        AM: 'Армения',
        BY: 'Беларусь',
        KG: 'Кыргызстан',
        KZ: 'Казахстан',
        LV: 'Латвия',
        RU: 'Россия',
        TJ: 'Таджикистан',
        UA: 'Украина',
        UZ: 'Узбекистан',
        OTHER: 'Другое',
    },
} as const;

export const i18nCitizenship = (key?: Nullable<Citizenship>) => {
    if (!key) return '';

    const lang = getTypedI18nLang();

    const dictionary = locales[lang];

    return dictionary[key];
};
