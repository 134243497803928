import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_body-m.css';

export type WithTypographyBodyMProps = {
    typography?: 'body-m';
};

export const withTypographyBodyM = withBemMod<WithTypographyBodyMProps>(
    cn(),
    { typography: 'body-m' },
);
