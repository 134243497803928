import { FC } from 'react';

import { HotelInfo } from 'components/HotelInfo';
import { ServiceCard } from 'components/ServiceCard';
import { ServiceCardFooter } from 'components/ServiceCardFooter';
import { ServiceHeader } from 'components/ServiceHeader';
import { ServiceState } from 'components/ServiceState';
import { usePlatform } from 'hooks/usePlatform';
import { getTpBanner } from 'utils/getTpBanner';

import { ReservationDetails } from './ReservationDetails/ReservationDetails';
import { cn } from './HotelServiceCard.cn';
import { HotelServiceCardProps } from './HotelServiceCard.types';

import './HotelServiceCard.css';

/**
 * Карточка отеля в заявке
 */
export const HotelServiceCard: FC<HotelServiceCardProps> = props => {
    const {
        className,
        service,
        serviceDetails,
    } = props;
    const { isDesktop } = usePlatform();

    // TODO: Выпилить oldIsTpCompliant после включения новых ТП - BTRIP-9669
    const banner = getTpBanner(serviceDetails.hotel, serviceDetails.is_travel_policy_compliant);

    return (
        <div className={cn(null, [className])}>
            <ServiceCard banner={banner}>
                <div className={cn('Header')}>
                    <ServiceHeader serviceDetails={serviceDetails} />
                    <ServiceState
                        className={cn('ServiceState')}
                        isCancelling={service.in_process_of_cancelling}
                        serviceType={service.type}
                        state={serviceDetails.service_state}
                    />
                </div>
                <div className={cn('CardContent')}>
                    <HotelInfo hotel={serviceDetails.hotel} />
                    {isDesktop && <div className={cn('Divider')} />}
                    <ReservationDetails {...props} />
                </div>
                <ServiceCardFooter {...props} />
            </ServiceCard>
        </div>
    );
};

HotelServiceCard.displayName = cn();
