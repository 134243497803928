export const en = {
    available_rooms: 'Available rooms',
    features: 'Services and facilities',
    review: 'Reviews',
    choose: 'Choose',
    check_in_from: 'Check in from {time}',
    check_out_before: 'Check out before {time}',
    colleagues_recommend: 'Colleagues recommend',
    early_check_in: 'Early check-in',
    late_check_out: 'Late check-out',
    early_check_in_from: 'Check in time — {time}',
    late_check_out_before: 'Check out time — {time}',
    early_check_in_possible: 'Check with the hotel in advance if early check-in is possible',
    late_check_out_possible: 'Check with the hotel in advance if late check-out is possible',
    description: 'Description',
    meal_included: 'Meal included',
    no_meal: 'No meal',
    free_cancelation: 'Free cancelation',
    cancelation_with_fee: 'Cancelation with fee',
    per_night: 'per night',
    rating: 'Rating',
    placement_terms: 'Terms of placement',
    important_information: 'Important information',
    based_on_reviews: {
        one: 'Based on {count} rewiew',
        some: 'Based on {count} rewiews',
        many: 'Based on {count} rewiews',
    },
    room_on_request: 'Room upon request',
    room_on_request_hint: 'Accommodation is not guaranteed. It may take up to 24 hours to receive the voucher',
    show_all: 'Show all',
    show_more: 'Show more',
    hide: 'Hide',
    tariffs: 'Tariffs',
    reviews: 'Reviews',
    empty_reviews_title: 'There are no reviews yet',
    empty_reviews_description: 'But soon they will appear',
    choose_room: 'Choose room',
    guests_enjoyed: 'Guests enjoyed',
    go_to_reviews: 'Go to reviews',
    go_to_trip: 'Go to trip',
    general_rooms_info: '{count} offers from {minPricePerNight} per night',
    m: 'm. {station}',
    no_rooms_selected: 'Choose a room to add to the trip',
    rooms_selected: 'Rooms added to the trip',
    passengers_list: 'Chosen rooms',
};
