import { FC, useCallback } from 'react';

import { RichCheckbox } from 'components/RichCheckbox';
import { i18nErrors } from 'i18n/i18nErrors';
import { Text } from 'shared/ui/Text';

import { cn } from './cn';
import { FilterFieldAirportsProps } from './interface';
import { parseAirportOption } from './utils';

import './style.css';

export const FilterFieldAirports: FC<FilterFieldAirportsProps> = props => {
    const { value: selected, onChange } = props.input;

    const airports = props.airports.map(parseAirportOption);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        event.stopPropagation();

        const { code } = event.target.dataset;

        if (code === undefined) throw new Error(i18nErrors('option_code_undefined'));

        if (code === '*') {
            if (selected.length === props.airports.length) return onChange([]);

            return onChange(props.airports.map(({ target_id }) => target_id));
        }

        const codeIndex = selected.indexOf(code);

        if (codeIndex === -1) return onChange([...selected, code]);

        return onChange([
            ...selected.slice(0, codeIndex),
            ...selected.slice(codeIndex + 1),
        ]);
    }, [selected, onChange, props.airports]);

    return (
        <div className={cn(null, [props.className])}>
            {airports.map(airport => (
                <RichCheckbox
                    key={airport.target_id}
                    checked={selected.includes(airport.target_id)}
                    className={cn('Checkbox')}
                    data-code={airport.target_id}
                    onChange={handleChange}
                >
                    <span className={cn('Airport')}>
                        <Text typography="body-s">
                            {airport.airportName ? (
                                <>
                                    {airport.airportName}{', '}
                                    <Text color="secondary" typography="caption-m">{airport.airportCity}</Text>
                                </>
                            ) :
                                airport.airportCity
                            }

                        </Text>
                        <Text color="secondary" typography="body-s">{airport.airportCode}</Text>
                    </span>
                </RichCheckbox>
            ))}
        </div>
    );
};
