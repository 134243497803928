import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { getTypedI18nLang } from 'utils/i18n';

dayjs.extend(duration);

type TimeUnit = 'days' | 'hours' | 'minutes';
type Locale = { [key in TimeUnit]: string };

const locales: { en: Locale; ru: Locale } = {
    en: {
        days: 'd',
        hours: 'h',
        minutes: 'min',
    },
    ru: {
        days: 'д',
        hours: 'ч',
        minutes: 'мин',
    },
} as const;

type Duration = {
    get(unit: TimeUnit): number;
};

const getLocalizedDuration = (locale: Locale, duration: Duration) => (unit: TimeUnit) => `${duration.get(unit)} ${locale[unit]}`;

export function formatDuration(duration: string): string {
    const lang = getTypedI18nLang();
    const locale = locales[lang];

    let days;
    // eslint-disable-next-line prefer-const
    let [hours, minutes, seconds] = duration.split(':');

    if (hours.includes('.')) {
        [days, hours] = hours.split('.');
    }

    if ([hours, minutes, seconds].some(value => value === undefined)) {
        throw new Error(`Неправильный формат длительности: ${String(duration)}`);
    }

    const dayjsDuration = dayjs.duration({
        seconds: Number(seconds),
        minutes: Number(minutes),
        hours: Number(hours),
        days: Number(days),
    });

    const localizedDuration = getLocalizedDuration(locale, dayjsDuration);
    const timeUnitParts: TimeUnit[] = ['days', 'hours', 'minutes'];

    return timeUnitParts.filter(unit => dayjsDuration.get(unit)).map(localizedDuration).join(' ');
}
