import { useEffect, useState } from 'react';

type UseShowMoreButttonProps = (
    ref: React.RefObject<HTMLDivElement>,
) => { showButton: boolean };

/*
 * Хук определяет, есть ли скрытый текст в поле
 * И если он есть, то устанавливает флаг в true, чтобы отображать кнопку "Показать еще"
 */
export const useShowMoreButtton: UseShowMoreButttonProps = ref => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        if (ref.current && ref.current.clientHeight < ref.current.scrollHeight) {
            setShowButton(true);
        }
    }, [ref]);

    return { showButton };
};
