import { FC } from 'react';

import { FormEmployeeAdd } from 'components/FormEmployeeAdd';
import { ModalPlatform } from 'shared/ui/ModalPlatform';

import { cn } from '../TableEmployees.cn';
import { i18n } from '../TableEmployees.i18n';

import { EmployeeAddModalProps } from './EmployeeAddModal.types';

import './EmployeeAddModal.css';

export const EmployeeAddModal: FC<EmployeeAddModalProps> = props => {
    const {
        visible,
        companyId,
        toggleShowModal,
    } = props;

    return (
        <ModalPlatform
            title={i18n('action_add_employee')}
            toggleVisible={toggleShowModal}
            visible={visible}
        >
            <FormEmployeeAdd
                className={cn('EmployeeAddModal')}
                companyId={companyId}
                formVisible={visible}
                onCancel={toggleShowModal}
                onSuccess={toggleShowModal}
            />
        </ModalPlatform>
    );
};
