import { FC, useCallback } from 'react';
import { useSwiper } from 'swiper/react';

import { FormEmployeeGroupAdd } from 'components/FormEmployeeGroupAdd';
import { Text } from 'shared/ui/Text';

import { i18n } from '../EmployeeGroupAddModal.i18n';

import { cn } from './FormSlide.cn';
import { FormSlideProps } from './FormSlide.types';

import './FormSlide.css';

export const FormSlide: FC<FormSlideProps> = props => {
    const { className, setCheckedPersons, membersIds, companyId, onCancel, onSubmit } = props;

    const swiper = useSwiper();

    const handleSubmit = useCallback(() => {
        onSubmit(swiper);
    }, [swiper, onSubmit]);

    return (
        <div className={cn(null, [className])}>
            <div className={cn('Header')}>
                <Text as="h3" className={cn('Title')} typography="title-m" weight="medium">
                    {i18n('title')}
                </Text>
                <Text as="p" className={cn('Info')} color="secondary" typography="body-s">
                    {i18n('info')}
                </Text>
            </div>
            <FormEmployeeGroupAdd
                companyId={companyId}
                membersIds={membersIds}
                setCheckedPersons={setCheckedPersons}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

FormSlide.displayName = cn();
