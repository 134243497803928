import {
    FC,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useForm } from 'react-final-form';

import { PersonSearchResponse } from 'services/IntraSearchApi';
import { Person, SwaggerApi } from 'services/SwaggerApi';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../../SwaggerContext';

import { PersonSuggest } from './PersonSuggest';
import { PersonSuggestContainerProps, PersonSuggestItem } from './PersonSuggest.types';

function dataProvider(value: string, selected: PersonSuggestItem[], personSuggestFn: SwaggerApi['persons_suggest']) {
    if (value.trim() === '') {
        return Promise.resolve([]);
    }

    const chosenUidsHash: {[uid: string]: true} = {};

    selected.forEach(person => chosenUidsHash[person.uid] = true);

    return (personSuggestFn({ text: value })
        .then(throwHTTPErrors) as Promise<PersonSearchResponse>)
        .then(suggestResponse => {
            if (!suggestResponse.people) return [];

            return suggestResponse.people.result.map((person): PersonSuggestItem & { is_dismissed: boolean } => {
                return {
                    title: person.title || '',
                    uid: person.uid,
                    id: person.uid,
                    login: person.login,
                    staff_id: person.staff_id,
                    is_dismissed: person.is_dismissed,
                };
            })
                .filter(person => !person.is_dismissed)
                .filter(person => !chosenUidsHash[person.uid]);
        });
}

const getPerson = async(staffId: Person['person_id'], getPersonFn: SwaggerApi['get_person']) => {
    return getPersonFn(staffId)
        .then(throwHTTPErrors)
        .then(async person => {
            const {
                first_name,
                last_name,
                login,
                uid,
                person_id,
                is_dismissed,
            } = person;

            const fullName = [first_name, last_name].join(' ').trim();

            return {
                title: fullName,
                login,
                uid,
                id: String(person_id),
                staff_id: person_id,
                is_dismissed,
            };
        });
};

export const PersonSuggestContainer: FC<PersonSuggestContainerProps> = props => {
    const {
        value: staffIds,
        className,
        onChange,
        onBlur,
        pin,
        state,
        placeholder,
        singleChoice,
    } = props;
    const { api } = useContext(SwaggerContext);

    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [opened, setOpened] = useState(false);
    const [choices, setChoices] = useState<PersonSuggestItem[]>([]);
    const form = useForm();
    const [selected, setSelected] = useState<PersonSuggestItem[]>(() => (form.getState().values.persons || []));

    useEffect(() => {
        let cancelled = false;

        setLoading(true);

        const timeoutHandler = setTimeout(() => {
            dataProvider(value, selected, api.persons_suggest).then(result => {
                if (!cancelled) {
                    setChoices(result);
                    setLoading(false);
                }
            });
        }, 300);

        return () => {
            cancelled = true;
            clearTimeout(timeoutHandler);
        };
    }, [selected, value, api]);

    useEffect(() => {
        if (!selected.length && staffIds.length > 0) {
            Promise.all(staffIds.map(id => getPerson(id, api.get_person)))
                .then(person => {
                    setSelected(person);

                    if (form.getRegisteredFields().includes('persons')) {
                        form.mutators.push('persons', ...person);
                    }
                })
                .catch(console.error);
        }
    }, [staffIds, selected, form, api]);

    const handleChange = useCallback(data => {
        setSelected(data);
        onChange(data);
    }, [onChange]);

    const handleInputChange = useCallback(value => {
        setValue(value);

        setOpened(value.length > 0);
    }, []);

    return (
        <PersonSuggest
            choices={choices}
            className={className}
            input={value}
            loading={loading}
            opened={opened}
            pin={pin}
            placeholder={placeholder}
            singleChoice={singleChoice}
            state={state}
            value={selected}
            onBlur={onBlur}
            onChange={handleChange}
            onInputChange={handleInputChange}
        />
    );
};
