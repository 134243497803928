import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormProvider } from 'components/FormField2';
import { TabbedFormsLayout } from 'components/TabbedFormsLayout';
import { useCreateTravelPolicy } from 'hooks/useCreateTravelPolicy';
import { useEditTravelPolicy } from 'hooks/useEditTravelPolicy';
import { i18nAction } from 'i18n/i18nAction';
import { i18nErrors } from 'i18n/i18nErrors';
import { RouteTravelPolicy } from 'routes/RouteTravelPolicy';
import { errorToast } from 'utils/errorToast';
import { isLoading } from 'utils/Loadable';

import { getCreatePayload } from './FormTravelPolicy.utils/getCreatePayload';
import { getDefaultFormValues } from './FormTravelPolicy.utils/getDefaultFormValues';
import { ActionsCard } from './ActionsCard';
import { AviaRules } from './AviaRules';
import { cn } from './FormTravelPolicy.cn';
import { initialFormValues } from './FormTravelPolicy.constants';
import { i18n } from './FormTravelPolicy.i18n';
import { schema, TravelPolicySchema } from './FormTravelPolicy.schema';
import { FormTravelPolicyProps } from './FormTravelPolicy.types';
import { GeneralRules } from './GeneralRules';
import { HotelRules } from './HotelRules';
import { RailRules } from './RailRules';

export const FormTravelPolicy: FC<FormTravelPolicyProps> = props => {
    const { className, details } = props;

    const { push } = useHistory();

    const methods = useForm<TravelPolicySchema>({
        resolver: yupResolver(schema),
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        defaultValues: {
            ...initialFormValues,
            ...getDefaultFormValues(details),
        },
    });

    const [createTravelPolicy, createTravelPolicyStatus] = useCreateTravelPolicy({
        onSuccess: ({ travel_policy_id: travelPolicyId }) => push(RouteTravelPolicy.getPath({ travelPolicyId }, {})),
        onError: e => {
            errorToast(e, { title: i18nErrors('error_in_save_data') });
        },
        options: {
            hideErrorNotifier: true,
        },
    });

    const [editTravelPolicy, editTravelPolicyStatus] = useEditTravelPolicy({
        onSuccess: () => {
            methods.reset({}, { keepValues: true });
        },
        onError: e => {
            errorToast(e, { title: i18nErrors('error_in_save_data') });
        },
        options: {
            hideErrorNotifier: true,
        },
    });

    const isNewTravelPolicy = !details;

    const isFormEdited = methods.formState.isDirty;

    const tabbedItems = [
        {
            id: 'general',
            title: i18n('general_rules'),
            content: <GeneralRules />,
        },
        {
            id: 'avia',
            title: i18n('avia_rules'),
            content: <AviaRules methods={methods} />,
        },
        {
            id: 'rail',
            title: i18n('rail_rules'),
            content: <RailRules methods={methods} />,
        },
        {
            id: 'hotel',
            title: i18n('hotel_rules'),
            content: <HotelRules methods={methods} />,
        },
    ];

    const handleSubmit = (values: TravelPolicySchema) => {
        if (isNewTravelPolicy) {
            createTravelPolicy(getCreatePayload(values));
        } else {
            editTravelPolicy(details.travel_policy_id, getCreatePayload(values));
        }
    };

    return (
        <FormProvider methods={methods} schema={schema}>
            <form className={cn(null, [className])} onSubmit={methods.handleSubmit(handleSubmit)}>
                <TabbedFormsLayout
                    actions={
                        <ActionsCard
                            actionCaption={isNewTravelPolicy ? i18n('save_caption') : i18n('save_changes_caption')}
                            actionDisabled={!isFormEdited && !isNewTravelPolicy}
                            actionText={isNewTravelPolicy ? i18nAction('save') : i18nAction('save_changes')}
                            progress={isLoading(createTravelPolicyStatus) || isLoading(editTravelPolicyStatus)}
                        />
                    }
                    items={tabbedItems}
                />
            </form>
        </FormProvider>
    );
};

FormTravelPolicy.displayName = cn();
