import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { DateFormat } from 'utils/formatDate';

dayjs.extend(utc);
dayjs.extend(timezone);

export type GetTimeWithMoscowOffset = (dateUtc: string) => string;

/**
 * Переводит время в формате utc в локальное время по Москве (GMT+3)
 * Именно в таком формате ручка тревела принимает время отправления поездов
 * @param dateUtc Дата в формате utc
 */
export const getTimeWithMoscowOffset: GetTimeWithMoscowOffset = dateUtc => {
    return dayjs(dateUtc, { utc: true })
        .tz('Europe/Moscow')
        .format(DateFormat.YYYY_MM_DDTHH_mm_ss);
};
