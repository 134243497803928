import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@yandex-int/hr-components/Button/desktop/bundle';
import { Link } from '@yandex-int/hr-components/Link/desktop/bundle';

import { Card } from 'components/Card';
import { FormProvider } from 'components/FormField2';
import { HeaderPage } from 'components/HeaderPage';
import { Page } from 'components/Page';
import { useExtPersonCreate } from 'hooks/useExtPersonCreate';
import { i18nAction } from 'i18n/i18nAction';
import { RouteCompanion } from 'routes/RouteCompanion';
import { RouteCompanionAdd } from 'routes/RouteCompanionAdd';
import { Container } from 'shared/ui/Container';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';
import { serializeDate } from 'utils/serializeDate';

import { ContactPersonalInfo } from './ContactPersonalInfo/ContactPersonalInfo';
import { MainPersonalInfo } from './MainPersonalInfo/MainPersonalInfo';
import { cn } from './PageCompanionAdd.cn';
import { i18n } from './PageCompanionAdd.i18n';
import { schema, SchemaType } from './PageCompanionAdd.schema';

import './PageCompanionAdd.css';

const PD_DOC_LINK = 'https://s3.mds.yandex.net/trip/consent/personal-data-of-3-persons.pdf';

export const PageCompanionAdd: Page<RouteCompanionAdd> = () => {
    const { push } = useHistory();

    const methods = useForm<SchemaType>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const [createExtPerson, createStatus, { error }] = useExtPersonCreate({
        onSuccess: data => {
            push(RouteCompanion.getPath({ extPersonId: data.ext_person_uuid }, { modal: 'add_companion_document' }));
        },
    });

    const handleSubmit: SubmitHandler<SchemaType> = values => {
        const { date_of_birth, first_name_ac_en, last_name_ac_en, ...restValues } = values;

        createExtPerson({
            ...restValues,
            first_name_en: first_name_ac_en,
            last_name_en: last_name_ac_en,
            date_of_birth: serializeDate(date_of_birth)!,
        });
    };

    return (
        <Page className={cn()}>
            <HeaderPage title={i18n('add_companion')} />
            <FormProvider error={error} methods={methods} schema={schema}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <Container className={cn('Container')}>
                        <div className={cn('Main')}>
                            <MainPersonalInfo />
                            <ContactPersonalInfo />
                        </div>

                        <Card className={cn('Aside')}>
                            <Button
                                progress={isLoading(createStatus)}
                                size="l"
                                type="submit"
                                view="brand"
                            >
                                {i18nAction('save')}
                            </Button>
                            <Text
                                align="center"
                                as="div"
                                color="secondary"
                                typography="caption-m"
                            >
                                {i18n('agreement')}
                                {' '}
                                <Link
                                    href={PD_DOC_LINK}
                                    target="_blank"
                                    theme="link"
                                >
                                    {i18n('agreement_name')}
                                </Link>
                            </Text>

                        </Card>
                    </Container>
                </form>
            </FormProvider>
        </Page>
    );
};

PageCompanionAdd.displayName = cn();
