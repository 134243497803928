import { FC, useCallback } from 'react';

import { SelectApproverForm } from 'components/SelectApproverForm';
import { withModalView } from 'hocs/withModalView';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';
import { serializePerson } from 'utils/serializePerson';

import { useEmployeeRestore } from './EmployeeRestoreModal.hooks/useEmployeeRestore';
import { cn } from './EmployeeRestoreModal.cn';
import { i18n } from './EmployeeRestoreModal.i18n';
import { EmployeeRestoreModalProps, HandleSubmit } from './EmployeeRestoreModal.types';

import './EmployeeRestoreModal.css';

const EmployeeRestoreModalPresenter: FC<EmployeeRestoreModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        className,
        employee,
    } = props;

    const { employee_id, company_id } = employee;

    const [restoreEmployee, restoreState, { error }] = useEmployeeRestore({
        onSuccess: onSubmit,
        options: {
            invalidateOnSuccess: true,
            hideValidationErrors: true,
        },
    });

    const isRestoring = isLoading(restoreState);

    const handleSubmit = useCallback<HandleSubmit>(formFields => {
        restoreEmployee(company_id, employee_id, {
            approver_id: serializePerson(formFields.approver)!,
        });
    }, [restoreEmployee, company_id, employee_id]);

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <SelectApproverForm
                buttonSubmitText={i18n('action_submit')}
                employee={employee}
                error={error}
                progress={isRestoring}
                onCancel={onCancel}
                onSubmit={handleSubmit}
            />
        </div>
    );
};

EmployeeRestoreModalPresenter.displayName = cn();

export const EmployeeRestoreModal = withModalView(EmployeeRestoreModalPresenter);
