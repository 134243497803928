import { IClassNameProps } from '@bem-react/core';

export type AddCompanionDocumentProps = IClassNameProps & {
    visible: boolean;
    onModalClose: VoidFunction;
    extPersonId: string;
};

export enum ModalSlides {
    HINT_SLIDE,
    FORM_SLIDE
}
