import * as t from 'io-ts';

import { hasData, RemoteData, success } from 'utils/Loadable';

import { decode } from './decode';

export function decodeLoadable<T, U>(
    loadable: RemoteData<T>,
    type: t.Type<U>,
): RemoteData<U> {
    if (!hasData(loadable)) return loadable;

    return success(decode(loadable.result, type));
}
