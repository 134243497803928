import { FC } from 'react';
import { Image } from '@yandex-lego/components/Image/desktop';

import {
    getIconProvider,
    IconArrowShortLeft,
} from 'components/Icon';
import { RegistrationFeedbackButton } from 'components/RegistrationFeedbackButton';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import background from './assets/background.jpg';
import backgroundX2 from './assets/backgroundX2.jpg';
import { cn } from './RegisterLayout.cn';
import { RegisterLayoutProps } from './RegisterLayout.types';

import './RegisterLayout.css';

const iconProviderArrowLeft = getIconProvider(IconArrowShortLeft);

export const RegisterLayout: FC<RegisterLayoutProps> = props => {
    const {
        onBackClick,
        className,
        title,
        children,
    } = props;

    return (
        <div className={cn(null, [className])}>
            <main className={cn('Main')}>
                {onBackClick && (
                    <Button
                        className={cn('BackButton')}
                        icon={iconProviderArrowLeft}
                        pin="circle-circle"
                        size="l"
                        view="raised"
                        onClick={onBackClick}
                    />
                )}
                <Text
                    as="h1"
                    className={cn('Title')}
                    typography="display-s"
                >
                    {title}
                </Text>
                <div className={cn('Content')}>
                    {children}
                </div>
                <RegistrationFeedbackButton />
            </main>
            <Image
                className={cn('Image')}
                src={background}
                src2x={backgroundX2}
            />
        </div>
    );
};

RegisterLayout.displayName = cn();
