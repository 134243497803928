import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_display-m.css';

export type WithTypographyDisplayMProps = {
    typography?: 'display-m';
};

export const withTypographyDisplayM = withBemMod<WithTypographyDisplayMProps>(
    cn(),
    { typography: 'display-m' },
);
