import { FC, useMemo } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';

import { HotelFilterKey } from 'api/models/filters/HotelFilterKey';
import { FieldSort, getSortOptionValue } from 'components/FieldSort';
import { FilterFieldCheckbox } from 'components/FilterFieldCheckbox';
import { FilterFieldOption } from 'components/FilterFieldOption';
import { FilterFieldPriceRange } from 'components/FilterFieldPriceRange';
import { FilterFieldTextinput } from 'components/FilterFieldTextinput';
import { FilterGroup } from 'components/FilterGroup';
import { HelpTooltip } from 'components/HelpTooltip';
import { HotelStars } from 'components/HotelStars';
import { withProps } from 'hocs/withProps';
import { useAvailableFilters } from 'hooks/useAvailableFilters';
import { useCallbackWithScrollToTop } from 'hooks/useCallbackWithScrollToTop';
import { useMeta } from 'hooks/useMeta';
import { i18nAction } from 'i18n/i18nAction';
import { i18nHints } from 'i18n/i18nHints';
import { i18nTravelPolicy } from 'i18n/i18nTravelPolicy';
import { Divider } from 'shared/ui//Divider';
import { Button } from 'shared/ui/Button';

import { usePrepareFilters } from './HotelFilters.hooks/usePrepareFilters';
import { cn } from './HotelFilters.cn';
import { i18n } from './HotelFilters.i18n';
import { HotelFiltersFormValues, HotelFiltersProps } from './HotelFilters.types';

import './HotelFilters.css';

const FieldOptions = withProps({ component: FilterFieldOption }, cn('FilterOptions'))(Field);
const FieldPriceRange = withProps({ component: FilterFieldPriceRange }, cn('FilterRange'))(Field);
const FieldHotelName = withProps({ component: FilterFieldTextinput }, cn('FilterHotelName'))(Field);

/**
 * От Аэроклуба приходят непонятные числа, по которым невозможно фильтровать, поэтому
 * используем захардкоженный фильтр от 0 до 20000
 */
const defaultPriceRange: HotelFiltersFormValues['price_range'] = [0, 20000];

const defaultValues: HotelFiltersFormValues = {
    confirmation_types: [],
    stars: [],
    is_recommended: false,
    price_range: defaultPriceRange,
};

const starsOptions = Array.from({ length: 5 }, (_, index) => {
    const stars = index + 1;

    return {
        target_id: String(stars),
        element: <HotelStars count={stars} />,
    };
});

export const HotelFilters: FC<HotelFiltersProps> = props => {
    const {
        filters: filtersRaw,
        search,
        instant,
        tripNights,
        onSubmit,
        onChange,
    } = props;

    const { user: { is_tp_enabled } } = useMeta();

    const isAvailable = useAvailableFilters<HotelFilterKey>(filtersRaw);
    const filters = usePrepareFilters(filtersRaw);

    const initialValues = useMemo(() => {
        const possibleHotelTypes = filters.hotel_types?.map(hotel_type => hotel_type.target_id);
        const hotel_types = search.hotel_types?.filter(hotel_type => possibleHotelTypes?.includes(hotel_type));

        return {
            ...defaultValues,
            ...search,
            sortOptionValue: getSortOptionValue({ order_by: search.order_by, is_descending: search.is_descending }, 'Hotel'),
            hotel_types,
        };
    }, [filters.hotel_types, search]);

    if (search.price_from !== undefined && search.price_to !== undefined) {
        initialValues.price_range = [
            Math.trunc(search.price_from / tripNights),
            Math.trunc(search.price_to / tripNights),
        ];
    }

    const hasIsRecommendedFilter = isAvailable('is_recommended');
    const hasHotelNameFilter = isAvailable('hotel_name');
    const hasConfirmationTypeFilters = isAvailable('confirmation_type');

    const compliantTravelPolicyFilterVisible = !is_tp_enabled;

    const onSubmitWithScrollToTop = useCallbackWithScrollToTop(onSubmit);

    const recommendedLabel = (
        <div className={cn('RecommendedTag')}>
            {i18n('recommended')}
            <HelpTooltip
                inverse
                content={i18nHints('recommended_hotel_hint')}
                desktopTrigger="hover"
                direction={['bottom', 'top', 'bottom-end', 'top-end']}
                iconSize="m"
                popupProps={{ scope: undefined }}
            />
        </div>
    );

    return (
        <Form {...props} initialValues={initialValues} onSubmit={onSubmitWithScrollToTop}>
            {render => {
                return (
                    <div className={cn(null, [props.className])}>
                        <form onSubmit={render.handleSubmit}>
                            {instant && <FormSpy onChange={onChange} />}
                            <Field
                                className={cn('FieldSort')}
                                component={FieldSort}
                                name="sortOptionValue"
                                type="Hotel"
                            />
                            <div className={cn('QuickFilters')}>
                                {hasIsRecommendedFilter && (
                                    <Field
                                        component={FilterFieldCheckbox}
                                        label={recommendedLabel}
                                        name="is_recommended"
                                        title={i18n('recommended')}
                                    />
                                )}
                                {compliantTravelPolicyFilterVisible && (
                                    <Field activeValue="0" component={FilterFieldCheckbox} label={i18nTravelPolicy('compliant')} name="is_restricted_by_travel_policy" />
                                )}
                            </div>

                            {hasHotelNameFilter && (
                                <>
                                    <Divider />
                                    <FilterGroup label={i18n('hotel_name')}>
                                        <FieldHotelName name="hotel_name" throttle={300} />
                                    </FilterGroup>
                                </>
                            )}

                            <Divider />

                            <FilterGroup label={i18n('price_per_night')}>
                                <FieldPriceRange name="price_range" range={defaultPriceRange} />
                            </FilterGroup>

                            <Divider />

                            <FilterGroup label={i18n('stars_count')}>
                                <FieldOptions name="stars" options={starsOptions} />
                            </FilterGroup>

                            {hasConfirmationTypeFilters && (
                                <>
                                    <Divider />
                                    <FilterGroup label={i18n('confirmation')}>
                                        <FieldOptions name="confirmation_types" options={filters.confirmation_type} />
                                    </FilterGroup>
                                </>
                            )}

                            <Divider />

                            <FilterGroup className={cn('HotelTypes')} label={i18n('hotel_type')}>
                                <FieldOptions name="hotel_types" options={filters.hotel_types} />
                            </FilterGroup>

                            {!instant && (
                                <div className={cn('ActionButtons')}>
                                    {/* eslint-disable-next-line react/jsx-no-bind */}
                                    <Button
                                        className={cn('ActionButton')}
                                        size="m"
                                        view="outline"
                                        width="max"
                                        onClick={() => render.form.reset(defaultValues)}
                                    >
                                        {i18nAction('reset')}
                                    </Button>
                                    <Button
                                        className={cn('ActionButton',
                                            { type: 'submit' })}
                                        size="m"
                                        type="submit"
                                        view="brand"
                                        width="max"
                                    >
                                        {i18nAction('apply')}
                                    </Button>

                                </div>
                            )}
                        </form>
                    </div>
                );
            }}
        </Form>
    );
};

HotelFilters.displayName = cn();
