import {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { getIconProvider, IconClose } from 'components/Icon';
import { Spinner } from 'components/Spinner';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { ModalPlatform } from 'shared/ui/ModalPlatform';

import { cn } from './ImageViewer.cn';
import { ImageViewerProps } from './ImageViewer.types';

import './ImageViewer.css';

const iconProviderClose = getIconProvider(IconClose, { size: 'l' });

export const ImageViewer: FC<ImageViewerProps> = props => {
    const {
        className,
        images,
        closeModal,
        visible,
    } = props;

    const { isDesktop } = usePlatform();
    const [loadedImages, setLoadedImages] = useState(0);
    const [isImagesLoaded, setIsImagesLoaded] = useState(false);

    const handleImageLoad = useCallback(() => {
        setLoadedImages(prevState => prevState + 1);
    }, []);

    const HDImages = useMemo(() => {
        return images.map(image => {
            const regex = /\/L$/;

            return image.replace(regex, '/XXXL');
        });
    }, [images]);

    useEffect(() => {
        if (HDImages.length === loadedImages) {
            setIsImagesLoaded(true);
        }
    }, [setIsImagesLoaded, HDImages, loadedImages]);

    return (
        <ModalPlatform
            noPadding
            className={className}
            scope={undefined}
            toggleVisible={closeModal}
            visible={visible}
        >
            {!isImagesLoaded && (
                <Spinner />
            )}
            <div className={cn('Gallery', { hide: !isImagesLoaded })}>
                {HDImages.map((image, index) => (
                    <img
                        key={index}
                        className={cn('Image')}
                        src={image}
                        onLoad={handleImageLoad}
                    />
                ))}
            </div>
            {isImagesLoaded && isDesktop && (
                <Button
                    className={cn('Action', { type: 'close' })}
                    icon={iconProviderClose}
                    size="l"
                    view="clear"
                    onClick={closeModal}
                />
            )}
        </ModalPlatform>
    );
};

ImageViewer.displayName = cn();
