import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { FieldPersonValue } from 'utils/serializePerson';

export type EmployeeField = {
    employeeSuggest?: FieldPersonValue;
};

type UseWatchEmployeeSuggestProps<T extends EmployeeField> = {
    onFiltersChange: (data: T) => void;
    methods: UseFormReturn<T>;
    filters: React.MutableRefObject<T>;
} ;

export function useWatchEmployeeSuggest<T extends EmployeeField>(props: UseWatchEmployeeSuggestProps<T>) {
    const { methods, onFiltersChange, filters } = props;

    useEffect(() => {
        const subscription = methods.watch(((data, { name }) => {
            if (name === 'employeeSuggest') {
                const newValues = {
                    ...filters.current,
                    employeeSuggest: data.employeeSuggest as FieldPersonValue,
                };

                onFiltersChange(newValues);
                filters.current = newValues;
            }
        }));

        return () => subscription.unsubscribe();
    }, [onFiltersChange, methods, filters]);
}
