export const en = {
    title: 'Business trip approval',
    description: 'If you decline travel request, the business trip will need to be re-created',
    comment: 'Comment on the travel request:',
    no: 'No',
    yes: 'Yes, decline',
    approve: 'Approve',
    approve_success: 'Trip request was approved',
    approve_error: 'Failed to approve the trip request. Please try again later',
    decline: 'Decline request',
    decline_confirmation: 'Are you sure you want to decline this trip?',
    decline_reason: "Please tell us why you're declinening this trip request",
    decline_success: 'Trip request was canceled',
    decline_error: 'Failed to cancel the trip request. Please try again later',
};
