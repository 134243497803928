import { FC } from 'react';

import { ActionsMenu } from 'components/ActionsMenu';
import { Button } from 'shared/ui/Button';
import { Direction } from 'shared/ui/Popup';

import { cn } from './ButtonMenu.cn';
import { ButtonMenuProps } from './ButtonMenu.types';

import './ButtonMenu.css';

const popupDirections: Direction[] = ['left-start', 'bottom-end', 'top-end'];

export const ButtonMenu: FC<ButtonMenuProps> = props => {
    const {
        menu,
        scope,
        direction = popupDirections,
        ...buttonProps
    } = props;

    return (
        <ActionsMenu
            direction={direction}
            menu={menu}
            scope={scope}
            trigger={(
                <Button
                    {...buttonProps}
                    className={cn(null, [props.className])}
                    data-testid="actions-button"
                />
            )}
        />
    );
};

ButtonMenu.displayName = cn();
