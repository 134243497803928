import { FC } from 'react';
import { Image } from '@yandex-lego/components/Image/Image';

import { HeaderLite } from 'components/HeaderLite';
import { usePlatform } from 'hooks/usePlatform';
import { Container } from 'shared/ui/Container';

import background from '../assets/background.png';
import { cn } from '../Register.cn';

import { LayoutProps } from './Layout.types';

import './Layout.css';

export const Layout: FC<LayoutProps> = props => {
    const {
        children,
        className,
    } = props;

    const { isMobile } = usePlatform();

    return (
        <div className={cn('Layout', [className])}>
            <HeaderLite
                overlay
                logoColor={isMobile ? 'default' : 'light'}
            />
            <div className={cn('Content')}>
                <Image
                    className={cn('Image')}
                    src={background}
                />
                <Container className={cn('Main')} wrapperClassName={cn('MainContainer')}>
                    {children}
                </Container>
            </div>
        </div>
    );
};
