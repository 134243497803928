import { ComponentProps, FC, ReactElement } from 'react';
import { withBemMod } from '@bem-react/core';

import { Textinput } from 'shared/ui/Textinput';

import { cn } from '../FormField.cn';
import { FormFieldProps as BaseProps } from '../FormField.types';

type TextinputProps = ComponentProps<typeof Textinput>;

export interface WithTypeTextinputProps {
    type?: 'textinput';
    placeholder?: TextinputProps['placeholder'];
    onChange?: TextinputProps['onChange'];
    onBlur?: TextinputProps['onBlur'];
    value?: TextinputProps['value'];
    state?: TextinputProps['state'];
    name?: string;
}

export const withTypeTextinput = withBemMod<WithTypeTextinputProps, BaseProps>(
    cn(),
    { type: 'textinput' },
    (Base): FC<BaseProps & WithTypeTextinputProps> => (props): ReactElement => {
        const {
            placeholder,
            onChange,
            onBlur,
            value,
            state,
            disabled,
            name,
        } = props;

        const appendAfter = (
            <Textinput
                hasClear
                className={cn('Control', { name })}
                disabled={disabled}
                placeholder={placeholder}
                size="m"
                state={state}
                value={value}
                view="outline"
                onBlur={onBlur}
                onChange={onChange}
            />
        );

        return (
            <Base
                {...props}
                appendAfter={appendAfter}
            />
        );
    },
);
