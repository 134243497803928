import { FC, ReactElement } from 'react';

import { cn } from './Bubble.cn';
import { BubbleProps } from './Bubble.interface';

import './Bubble.css';

export const Bubble: FC<BubbleProps> = (props: BubbleProps): ReactElement => {
    const { bubble, bubbleColor, size = 'm' } = props;
    const mark = bubble.type === 'person' ? bubble.mark : undefined;

    return (
        <div className={cn({ mark, size }, [props.className])} style={{ backgroundColor: bubbleColor }}>
            { props.children }
        </div>
    );
};
