import { ComponentProps, FC } from 'react';

import { DateField } from 'components/DateField';

import { withFormField } from '../FormField2';
import { cn } from '../FormField2.cn';
import { FormFieldProps } from '../FormField2.types';

type DateFieldProps = ComponentProps<typeof DateField>;

const Control: FC<DateFieldProps & FormFieldProps> = props => {
    const {
        name,
        controller,
        context,
        ...dateFieldProps
    } = props;

    const {
        field: {
            onChange,
            value,
            onBlur,
            ref,
        },
        fieldState: { error },
    } = controller;

    return (
        <DateField
            {...dateFieldProps}
            className={cn('Control', { name })}
            controlRef={ref}
            id={name}
            name={name}
            state={error ? 'error' : undefined}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};

export const FieldDate = withFormField(Control);
