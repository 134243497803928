export const ru = {
    bed: 'Постельное белье',
    food: 'Питание',
    wifi: 'Wifi',
    tv: 'Телевизор',
    sanitary_set: 'Санитарно-гигиенический набор',
    newspaper: 'Пресса',
    air_conditioner: 'Кондиционер',
    bio_toilet: 'Биотуалет',
    blanket: 'Плед',
    sink_in_compartment: 'Умывальник',
    shower_in_compartment: 'Душевая',
    hygienic_shower: 'Гигиенический душ',
    socket_220: 'Розетка 220В',
    slippers: 'Тапочки',
    pets: 'Провоз домашних животных',
    baby: 'Места для пассажиров с детьми',
    transfer: 'Трансфер',
    restaurant_car: 'Вагон-ресторан или купе-буфет',
    luggage_compartment: 'Купе для багажа',
    shower_in_train: 'Душевая в поезде',
    safe: 'Сейф',
    improved_service: '',
    comfortable_waiting_room: 'Вагон с улучшенными характеристиками',
    multimedia_portal: 'Мультимедийный портал (кинотеатр, книги, музыка)',
    hot_drinks: 'Горячие напитки',
    socket_usb: 'Usb розетка',
    sightseeing_service: 'Экскурсионное обслуживание',
    panoramic_view: 'Панорамный салон',
};
