export const ru = {
    cyrillic_required: 'Допустимы символы русского алфавита, пробелы и дефис',
    latin_required: 'Допустимы символы английского алфавита, пробелы и дефис',
    length: {
        one: 'Необходимо ввести {count} символ',
        some: 'Необходимо ввести {count} символа',
        many: 'Необходимо ввести {count} символов',
    },
    minLength: {
        one: 'Содержимое этого поля должно иметь длину не меньше, чем {count} символ',
        some: 'Содержимое этого поля должно иметь длину не меньше, чем {count} символа',
        many: 'Содержимое этого поля должно иметь длину не меньше, чем {count} символов',
    },
    maxLength: {
        one: 'Содержимое этого поля не должно превышать {count} символ',
        some: 'Содержимое этого поля не должно превышать {count} символа',
        many: 'Содержимое этого поля не должно превышать {count} символов',
    },
    required: 'Заполните это поле',
    positive: 'Значение этого поля должно быть положительным',
    negative: 'Значение этого поля должно быть отрицательным',
    integer: 'Значение этого поля должно быть целым',
    phone: 'Некорректный номер телефона',
    email: 'Неверный формат почты',
    url: 'Поле должно содержать URL',
    minNumber: 'Содержимое этого поля должно быть не меньше, чем {count}',
    maxNumber: 'Содержимое этого поля должно быть не больше, чем {count}',
    lessThan: 'Содержимое этого поля должно быть меньше, чем {count}',
    moreThan: 'Содержимое этого поля должно быть больше, чем {count}',
    minDate: 'Дата должна быть позже {date}',
    maxDate: 'Дата должна быть раньше {date}',
    futureDate: 'Дата рождения не может быть в будущем',
    minArrayLength: {
        one: 'Поле должно содержать хотя бы {count} элемент',
        some: 'Поле должно содержать хотя бы {count} элемента',
        many: 'Поле должно содержать хотя бы {count} элементов',
    },
    maxArrayLength: {
        one: 'Поле должно содержать не больше, чем {count} элемент',
        some: 'Поле должно содержать не больше, чем {count} элемента',
        many: 'Поле должно содержать не больше, чем {count} элементов',
    },
    arrayLength: {
        one: 'Поле должно содержать {count} элемент',
        some: 'Поле должно содержать {count} элемента',
        many: 'Поле должно содержать {count} элементов',
    },
};
