import { useContext, useMemo } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

import { FetchError, ImpossibleError } from 'errors';
import { i18nErrors } from 'i18n/i18nErrors';
import { SwaggerApi } from 'services/SwaggerApi';
import {
    failure,
    initial,
    loading,
    refetching,
    RemoteData,
    success,
} from 'utils/Loadable';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../SwaggerContext';

export function createUseQueryHook<
    K extends keyof SwaggerApi,
    TSuccess extends ExtractResponseSuccess<ReturnType<SwaggerApi[K]>>,
    TArgs extends Parameters<SwaggerApi[K]>,
>(
    key: K,
    queryConfig?: UseQueryOptions<TSuccess, FetchError>,
) {
    return function useQueryHook(...args: TArgs): RemoteData<TSuccess, FetchError> {
        const { api } = useContext(SwaggerContext);
        const fetchFn = api[key];

        //@ts-ignore
        const queryFn = () => fetchFn(...args).then(throwHTTPErrors) as Promise<TSuccess>;

        const {
            data,
            isError,
            isIdle,
            isLoading,
            isFetched,
            isRefetching,
            error,
        } = useQuery<TSuccess, FetchError, TSuccess>(
            [key, ...args],
            queryFn,
            queryConfig,
        );

        return useMemo(() => {
            if (isIdle) return initial();
            if (isLoading) return loading();
            if (isError && error !== null) return failure(error);

            if (data === undefined) {
                throw new ImpossibleError(i18nErrors('data_undefined'));
            }

            if (isRefetching && queryConfig?.keepPreviousData) {
                return refetching(data, isFetched);
            }

            return success(data);
        }, [data, error, isError, isIdle, isLoading, isRefetching, isFetched]);
    };
}
