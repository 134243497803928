import { FC, memo, MouseEvent, useCallback, useState } from 'react';

import { PersonListItem } from 'components/PersonListItem';
import { Checkbox } from 'shared/ui/Checkbox';

import { cn } from '../CompanionsAdd.cn';

import { CompanionsListProps } from './CompanionList.types';

import '../CompanionsAdd.css';

export const CompanionList: FC<CompanionsListProps> = memo(props => {
    const { companions, companionsInSearch, sendList, setSendList } = props;

    const [hoveredId, setHoveredId] = useState<string | null>(null);

    const onMouseLeave = useCallback(() => {
        setHoveredId(null);
    }, []);

    const stopClickPropagation = useCallback((e: MouseEvent) => {
        e.stopPropagation();
    }, []);

    return (
        <div className={cn('CompanionList')}>
            {companions.map(e => {
                const checked = sendList.has(e.ext_person_uuid);

                const disabled = sendList.size >= companionsInSearch && !checked;

                function addChecked() {
                    if (disabled) {
                        return;
                    }

                    setSendList(prevList => {
                        const res = new Set(prevList);

                        if (prevList.has(e.ext_person_uuid)) {
                            res.delete(e.ext_person_uuid);

                            return res;
                        }

                        return res.add(e.ext_person_uuid);
                    });
                }

                function onMouseEnter() {
                    setHoveredId(e.ext_person_uuid);
                }

                return (
                    <div
                        key={e.ext_person_uuid}
                        aria-label={`${e.first_name} ${e.last_name}`}
                        className={cn('Item', { disabled })}
                        onClick={addChecked}
                        onMouseEnter={onMouseEnter}
                        onMouseLeave={onMouseLeave}
                    >
                        <PersonListItem
                            external
                            name={`${e.first_name} ${e.last_name}`}
                            size="m"
                        />
                        <Checkbox
                            checked={checked}
                            className={cn('Checkbox')}
                            disabled={disabled}
                            hovered={e.ext_person_uuid === hoveredId}
                            label=""
                            size="m"
                            view="outline"
                            onChange={addChecked}
                            onClick={stopClickPropagation}
                        />
                    </div>);
            })}
        </div>);
});

CompanionList.displayName = cn('List');
