import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { isIOS } from './utils';

/**
 * Тип поля `detail` события `teamticket:webViewChangeUrl` объекта window
 */
export type ChangeUrlEventDetail = {
    currentUrl?: string;
    nextUrl: string;
};

declare global {
    interface WindowEventMap {
        'teamticket:webViewChangeUrl': CustomEvent<ChangeUrlEventDetail>;
    }
}

/**
 * Сильно урезанная версия такой функции из трекера, по
 * сути оставлена только логика для переключения табов
 * исходник arcadia/data-ui/tracker/src/ui/services/urlHandler.ts
 */
export function useNativeUrlHandler() {
    const history = useHistory();

    const changeUrlHandler = useCallback((event: WindowEventMap['teamticket:webViewChangeUrl']) => {
        if (!isIOS()) {
            return;
        }

        const { nextUrl } = event.detail;

        // очищаем историю, так как сейчас это работает только для переключения табов
        history.replace(nextUrl);
    }, [history]);

    window.addEventListener('teamticket:webViewChangeUrl', changeUrlHandler);

    return () => {
        window.removeEventListener('teamticket:webViewChangeUrl', changeUrlHandler);
    };
}
