/*
 * Заменяет предыдущую выбранную конференционную опцию на новую
 */

export function handleConfOptions(options: Array<number>, confIds: Array<number>) {
    const valuesConf: Array<number> = [];

    return options.reduce((acc: Array<number>, item: number) => {
        if (confIds.includes(item)) {
            const valueConfToRemove = valuesConf.pop();
            const indexConfToRemove = acc.indexOf(valueConfToRemove || NaN);

            if (indexConfToRemove !== -1) {
                acc.splice(indexConfToRemove, 1);
            }

            valuesConf.push(item);
        }

        acc.push(item);

        return acc;
    }, [] as Array<number>);
}
