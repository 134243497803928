import { i18nAviaClass } from 'i18n/i18nAviaClass';
import { i18nCarriageType } from 'i18n/i18nCarriageType';

import { i18n } from '../FormTravelPolicy.i18n';

export const getDirectionOptions = () => [
    {
        content: i18n('countries'),
        value: 'country',
    },
    {
        content: i18n('cities'),
        value: 'city',
    },
];

export const getClassesOptions = () => [
    {
        target_id: 'E',
        caption: i18nAviaClass('avia_class_econom'),
    },
    {
        target_id: 'C',
        caption: i18nAviaClass('avia_class_comfort'),
    },
    {
        target_id: 'F',
        caption: i18nAviaClass('avia_class_first'),
    },
    {
        target_id: 'B',
        caption: i18nAviaClass('avia_class_business'),
    },
];

export const getCategoriesOptions = () => [
    {
        target_id: 'Shared',
        caption: i18nCarriageType('common'),
    },
    {
        target_id: 'Soft',
        caption: i18nCarriageType('vip'),
    },
    {
        target_id: 'Luxury',
        caption: i18nCarriageType('lux'),
    },
    {
        target_id: 'Compartment',
        caption: i18nCarriageType('coupe'),
    },
    {
        target_id: 'ReservedSeat',
        caption: i18nCarriageType('reserved'),
    },
    {
        target_id: 'Sedentary',
        caption: i18nCarriageType('seat'),
    },
];

export const getStarsOptions = () => {
    const options = ['1', '2', '3', '4', '5', 'Unknown'];

    const formatCaption = (star: string) => i18n('stars', { count: star === 'Unknown' ? 0 : star });

    return options.map(star => ({ target_id: star, caption: formatCaption(star) }));
};
