import { ComponentProps, FC, useCallback } from 'react';
import dayjs from 'dayjs';

import { DatePicker } from 'components/DatePicker';

import { withFormField } from '../FormField2';
import { cn } from '../FormField2.cn';
import { FormFieldProps } from '../FormField2.types';

type DatepickerProps = ComponentProps<typeof DatePicker> & {
    useDateRangeFormat?: boolean;
    showSubText?: boolean
};

type HandleDateChange = Required<DatepickerProps>['onChange'];

const Control: FC<DatepickerProps & FormFieldProps> = props => {
    const {
        name,
        controller,
        useDateRangeFormat,
        context,
        ...datepickerProps
    } = props;

    const {
        field: {
            onChange,
            value,
            onBlur,
        },
        fieldState: { error },
    } = controller;

    const handleDateChange = useCallback<HandleDateChange>(date => {
        if (useDateRangeFormat) {
            const isFromGreaterThanTo = dayjs(date).isAfter(value.to);

            onChange({
                from: date,
                to: isFromGreaterThanTo ? undefined : value.to,
            });
        } else {
            onChange(date);
        }
    }, [onChange, useDateRangeFormat, value]);

    return (
        <DatePicker
            {...datepickerProps}
            className={cn('Control', { name })}
            state={error ? 'error' : undefined}
            value={useDateRangeFormat ? value.from : value}
            onBlur={onBlur}
            onChange={handleDateChange}
        />
    );
};

export const FieldDatePicker = withFormField(Control);
