import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from '@yandex-int/hr-components/Link/desktop/bundle';

import { cn } from './AppLink.cn';
import { AppLinkProps } from './AppLink.types';

export const AppLink: FC<AppLinkProps> = props => {
    const { className, href, target, children, ...restLinkProps } = props;
    const isExternalLink = href.startsWith('https://') || target === '_blank';
    const modifiedTarget = isExternalLink ? '_blank' : target;
    const history = useHistory();

    const handleClick = useCallback(event => {
        if (href.startsWith('#') || location.hash) {
            event.preventDefault();

            const anchorElement = document.querySelector(href || location.hash) as HTMLElement;

            if (anchorElement) {
                window.scrollTo({
                    top: anchorElement.offsetTop,
                    behavior: 'smooth',
                });
            }
        } else {
            event.stopPropagation();

            const isModifierPressed = event.altKey || event.ctrlKey || event.shiftKey || event.metaKey;

            if (isExternalLink || isModifierPressed) return;
            event.preventDefault();
            history.push(href);
        }
    }, [isExternalLink, history, href]);

    return (
        <Link
            className={cn(null, [className])}
            href={href}
            target={modifiedTarget}
            theme="link"
            onClick={handleClick}
            {...restLinkProps}
        >
            {children}
        </Link>
    );
};
