import { createUseMutationHook } from 'hooks/createUseMutationHook';

export const useTransactionCreate = createUseMutationHook(
    'transaction_create',
    companyId => [
        ['transaction_list', companyId],
        ['company_balance', companyId],
        ['company_expenses', companyId],
    ],
);
