export const ru = {
    available_places: 'Свободных мест',
    bottom: 'Нижние',
    bottom_side: 'Нижние боковые',
    carriage: 'Вагон',
    choose_carriage_class: 'Выберите тип и класс вагона',
    choose_tariff: 'Выберите тариф',
    class: 'Класс {code}',
    female: 'Женские',
    man: 'Мужские',
    mixed: 'Смешанные',
    not_branded: 'не фирменный',
    not_defined: 'Не определено',
    places_from: 'Места от',
    places_next_step: 'Выбор места будет доступен на следующем шаге.',
    price_from_to: 'Стоимость: от {from} до {to}',
    price_is: 'Стоимость: {price}',
    select: 'Выбрать',
    top: 'Верхние',
    top_side: 'Верхние боковые',
    wo_desc: 'Без описания',
    two_storey: 'Двухэтажный вагон',
};
