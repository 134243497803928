import { FC, useRef } from 'react';
import {
    Button as DatePickerButton,
    DayProps,
    useDayRender,
} from 'react-day-picker';

import { DateFormat, formatDate } from 'utils/formatDate';

/**
 * Кастомный компонент отображения даты в Дейтпикере
 * Нужен для добавления лейблов для тестирования
 * Логика компонента взята из https://github.com/gpbl/react-day-picker/blob/main/src/components/Day/Day.tsx
 */
export const Day: FC<DayProps> = props => {
    const { date, displayMonth } = props;
    const buttonRef = useRef(null);
    const { isHidden, buttonProps, isButton, divProps } = useDayRender(date, displayMonth, buttonRef);

    if (isHidden) {
        return <div role="gridcell" />;
    }

    if (!isButton) {
        return <div {...divProps} />;
    }

    return (
        <DatePickerButton
            {...buttonProps}
            ref={buttonRef}
            aria-label={formatDate(date, DateFormat.DD_MM_YYYY)}
        >
            {formatDate(date, DateFormat.D)}
        </DatePickerButton>
    );
};
