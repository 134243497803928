export const ru = {
    checkin_date: 'Дата заезда',
    checkout_date: 'Дата выезда',
    city_from: 'Откуда',
    city_to: 'Куда',
    date_from: 'Туда',
    date_to: 'Обратно',
    flight_tickets: 'Авиабилеты',
    hotels: 'Отели',
    train_tickets: 'Ж/д билеты',
    press_search: 'Нажмите для просмотра результатов',
    search: 'Найти',
    passengers_title: 'Количество пассажиров',
    avia_class_title: 'Выбор класса',
    adult: 'Взрослые',
    adult_caption: 'старше 12 лет',
    adult_persons: {
        many: '{count} взрослых',
        one: '{count} взрослый',
        some: '{count} взрослых',
    },
    adult_passengers: {
        many: '{count} пассажиров',
        one: '{count} пассажир',
        some: '{count} пассажира',
    },
};
