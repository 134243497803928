export const ru = {
    headerDisclaimer: 'Информация актуальна для граждан Российской Федерации',
    footerDisclaimer: 'Требования часто меняются, проверьте актуальность данных на официальных сайтах перед поездкой',
    findError: 'Нашли ошибку в сведениях?',
    inform: 'Сообщить',
    entranceForRussians: 'Въезд',
    visaRequired: 'Виза',
    quarantineRequired: 'Карантин',
    pcrExpirationPeriodInHoursOrRequired: 'ПЦР-тест',
    vaccineRequired: 'Вакцинация',
    flightsAvailability: 'Прямой перелет',
    tourismAvailability: 'Туризм',
};
