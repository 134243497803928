import {
    FC,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

import { SwaggerContext } from '../../SwaggerContext';

import { dataProvider } from './Destination.utils/dataProvider';
import { Destination } from './Destination';
import { DestinationContainerProps, DestinationSuggestItem } from './Destination.types';

export const DestinationContainer: FC<DestinationContainerProps> = ({
    value,
    className,
    onChange,
    onBlur,
    placeholder,
    pin,
    state,
    uniqPopupId,
    disabled,
    hasClear,
}) => {
    const { api } = useContext(SwaggerContext);

    const [inputValue, setInputValue] = useState(value?.name || '');
    const [loading, setLoading] = useState(false);
    const [opened, setOpened] = useState(false);
    const [results, setResults] = useState<DestinationSuggestItem[]>([]);
    const [picked, setPicked] = useState<DestinationSuggestItem[]>([]);
    const [selected, setSelected] = useState<DestinationSuggestItem[]>([]);

    useEffect(() => {
        setInputValue(value?.name || '');
    }, [value]);

    useEffect(() => {
        let cancelled = false;

        setLoading(true);

        const timeoutHandler = setTimeout(() => {
            dataProvider(inputValue, api.settlement_suggest).then(result => {
                if (!cancelled) {
                    setResults(result);
                    setLoading(false);
                }
            });
        }, 300);

        return () => {
            cancelled = true;
            clearTimeout(timeoutHandler);
        };
    }, [inputValue, api]);

    const handleOnChange = useCallback((value: DestinationSuggestItem[]) => {
        if (value.length) {
            const inputValue = value[0].value.name;

            setInputValue(inputValue);
            onChange(value[0].value);
            setSelected(value);
            setOpened(false);
        }
    }, [onChange]);

    const handleBlur = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
        // при очистке текста в инпуте — очищаем внутренний стейт
        if (inputValue?.length === 0) {
            setSelected([]);
            onChange();
            // если ранее что-то было выбрано, ставим в инпут текст предыдущего выбора
            // для кейса, когда ты поменял текст в инпуте, но не выбрал новое значение в выпадушке
        } else if (selected.length > 0) {
            setInputValue(selected[0].value.name);
        }

        onBlur && onBlur(event);
    }, [inputValue, selected, onBlur, onChange]);

    const handleInputChange = useCallback(value => {
        setInputValue(value);

        setOpened(value.length > 0);
    }, []);

    return (
        <Destination
            className={className}
            disabled={disabled}
            hasClear={hasClear}
            input={inputValue}
            loading={loading}
            opened={opened}
            picked={picked}
            pin={pin}
            placeholder={placeholder}
            searchResults={results}
            state={state}
            uniqPopupId={uniqPopupId}
            value={selected}
            onBlur={handleBlur}
            onChange={handleOnChange}
            onInputChange={handleInputChange}
            onPick={setPicked}
        />
    );
};
