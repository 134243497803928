import { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FieldTextinput, FormProvider } from 'components/FormField2';
import { withModalView } from 'hocs/withModalView';
import { useCostCenterCreate } from 'hooks/useCostCenterCreate';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { cn } from './FormCostCenter.cn';
import { i18n } from './FormCostCenter.i18n';
import { CostCenterSchema, schema } from './FormCostCenter.schema';
import { FormCostCenterProps } from './FormCostCenter.types';

import './FormCostCenter.css';

/**
 * Форма создания центра затрат
 */
export const FormCostCenter: FC<FormCostCenterProps> = props => {
    const {
        className,
        companyId,
        onCancel,
        onSubmit,
    } = props;

    const [createCostCenter, costCenterCreateState, { error }] = useCostCenterCreate({
        onSuccess: onSubmit,
    });

    const methods = useForm<CostCenterSchema>({
        resolver: yupResolver(schema),
    });

    const handleSubmit: SubmitHandler<CostCenterSchema> = fields => {
        createCostCenter(companyId, fields);
    };

    return (
        <form className={cn(null, [className])} onSubmit={methods.handleSubmit(handleSubmit)}>
            <Text
                as="h3"
                className={cn('ModalTitle')}
                typography="title-s"
            >
                {i18n('add_cost_center')}
            </Text>
            <FormProvider error={error} methods={methods} schema={schema}>
                <FieldTextinput
                    label={i18n('const_center_name')}
                    name="name"
                />
            </FormProvider>
            <div className={cn('Controls')}>
                <Button
                    className={cn('Cancel')}
                    size="m"
                    view="ghost"
                    onClick={onCancel}
                >
                    {i18nAction('cancel')}
                </Button>
                <Button
                    className={cn('Submit')}
                    progress={isLoading(costCenterCreateState)}
                    size="m"
                    type="submit"
                    view="primary"
                >
                    {i18nAction('add')}
                </Button>
            </div>
        </form>
    );
};

FormCostCenter.displayName = cn();

export const CostCenterModal = withModalView(FormCostCenter);
