import { FC, useMemo } from 'react';
import { Skeleton } from '@yandex-int/hr-components/Skeleton';

import { withRemote } from 'hocs/withRemote';
import { useAllowedDocumentsWithExtPersons } from 'hooks/useAllowedDocumentsWithExtPersons';
import { Text } from 'shared/ui/Text';

import { ActionButtons } from './ActionButtons/ActionButtons';
import { ExtendedDocumentsActionsProps } from './ExtendedDocumentsActions/ExtendedDocumentsActions.types';
import { AttachmentsDropdown } from './AttachmentsDropdown';
import { DocumentsActions } from './DocumentsActions';
import { ExtendedDocumentsActions } from './ExtendedDocumentsActions';
import { cn } from './ServiceCardFooter.cn';
import { ServiceCardFooterProps } from './ServiceCardFooter.types';

import './ServiceCardFooter.css';

const DocumentsActionsRemote = withRemote(DocumentsActions);
const DocumentsActionsSkeleton =
    <span data-testid="documents-actions-skeleton">
        <Skeleton
            className={cn('DocumentsActionsSkeleton')}
            type="control"
        />
    </span>;

const CompanionsDocumentsWrapper: FC<ExtendedDocumentsActionsProps> = props => {
    const {
        service,
        allowedDocuments,
        serviceDetails,
    } = props;

    const extService = service.ext_person_services;

    if (!extService) {
        return null;
    }

    return <DocumentsActions
        allowedDocuments={allowedDocuments.ext_persons_documents}
        extService={extService[0]}
        service={service}
        serviceDetails={serviceDetails}
    />;
};

const ExtendedDocumentsActionsRemote = withRemote(ExtendedDocumentsActions);
const CompanionsDocumentsWrapperRemote = withRemote(CompanionsDocumentsWrapper);

/**
 * Футер карточки услуги в персональной заявке
 */

export const ServiceCardFooter: FC<ServiceCardFooterProps> = props => {
    const {
        className,
        serviceDetails,
        service,
        extraActions,
    } = props;

    const {
        update,
        reserve,
        delete: deleteAction,
    } = service.actions;

    const hasExtPersonsServices = service.ext_person_services && service.ext_person_services?.length > 0;
    const serviceDocuments = serviceDetails.documents;
    const showActionButtons = deleteAction || reserve || update;
    const showAttachmentsDropdown = serviceDocuments.length > 0;

    const allowedDocuments = useAllowedDocumentsWithExtPersons(service.service_id);

    const content = useMemo(() => {
        if (service.type === 'rail' && hasExtPersonsServices) {
            const person = service.ext_person_services ? service.ext_person_services[0].ext_person : null;

            return (
                <div className={cn('RailExtDocuments')}>
                    <Text typography="body-s">
                        {person && person.first_name}{' '}
                        {person && person.last_name}
                    </Text>

                    <CompanionsDocumentsWrapperRemote
                        allowedDocuments={allowedDocuments}
                        service={service}
                        serviceDetails={serviceDetails}
                        skeleton={DocumentsActionsSkeleton}
                    />
                </div>
            );
        }

        return hasExtPersonsServices ? (
            <ExtendedDocumentsActionsRemote
                allowedDocuments={allowedDocuments}
                service={service}
                serviceDetails={serviceDetails}
            />
        ) : (
            <DocumentsActionsRemote
                allowedDocuments={allowedDocuments}
                service={service}
                serviceDetails={serviceDetails}
                skeleton={DocumentsActionsSkeleton}
            />
        );
    }, [allowedDocuments, hasExtPersonsServices, service, serviceDetails]);

    return (
        <div className={cn(null, [className])}>
            {content}

            <div className={cn('ServiceActions')}>
                {showAttachmentsDropdown && (
                    <AttachmentsDropdown attachments={serviceDocuments} />
                )}
                {showActionButtons && (
                    <ActionButtons extraActions={extraActions} service={service} />
                )}
            </div>
        </div>
    );
};

ServiceCardFooter.displayName = cn();
