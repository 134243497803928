import { UserResponse } from 'services/SwaggerApi';
import { logError } from 'utils/logError';

import { MetrikaGoal } from './MetrikaGoal';
import { METRIKA_ID } from './metrikaId';

export const reachGoal = (goal: MetrikaGoal, options: { params?: object; meta: Partial<UserResponse> }) => {
    const ym = window && window.ym;

    if (!ym) {
        logError(new Error('Metrika error: window.ym not available'));
    }

    ym(METRIKA_ID, 'reachGoal', goal, options);
};
