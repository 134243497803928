import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_label-m.css';

export type WithTypographyLabelMProps = {
    typography?: 'label-m';
};

export const withTypographyLabelM = withBemMod<WithTypographyLabelMProps>(
    cn(),
    { typography: 'label-m' },
);
