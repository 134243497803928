import { FC } from 'react';

import { ButtonMenu } from 'components/ButtonMenu';
import { Cell } from 'components/Cell';
import { getIconProvider, IconMoreVertical } from 'components/Icon';
import { withTdAttrs } from 'hocs/withTdAttrs';

import { cn } from './CellActions.cn';
import { CellActionsProps } from './CellActions.types';

import './CellActions.css';

const iconProviderMoreVertical = getIconProvider(IconMoreVertical);

const CellActionsBase: FC<CellActionsProps> = props => {
    const {
        className,
        menuOptions,
        getAttrs,
    } = props;

    const isDisabled = menuOptions.length === 0;

    return (
        <Cell
            className={cn(null, [className])}
            getAttrs={getAttrs}
            size="m"
            sizeHorizontal="s"
        >
            <ButtonMenu
                disabled={isDisabled}
                icon={iconProviderMoreVertical}
                menu={menuOptions}
                size="s"
                view="clear"
            />
        </Cell>
    );
};

export const CellActions = withTdAttrs(CellActionsBase);
CellActions.displayName = cn();
