import {
    useFlexLayout,
    usePagination,
    useTable,
} from 'react-table';
import { useSticky } from 'react-table-sticky';

import { cn } from './Table.cn';
import { TableProps } from './Table.types';

import './Table.css';

export const Table = <T extends object>(props: TableProps<T>) => {
    const {
        columns,
        data,
        defaultColumn,
        hasStickyColumns,
        pageSize,
        pageIndex,
        pageCount,
        className,
    } = props;

    const {
        headerGroups,
        rows: rowsAll,
        page: rowsPaginated,
        getTableProps,
        getTableBodyProps,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            manualPagination: true,
            initialState: {
                pageSize,
                pageIndex,
            },
            pageCount,
        },
        useFlexLayout,
        useSticky,
        usePagination,
    );
    const rows = rowsPaginated || rowsAll;

    return (
        <table
            {...getTableProps()}
            className={cn({ hasStickyColumns }, [className])}
        >
            <thead className={cn('Head')}>
                {headerGroups.map(headerGroup => {
                    const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();

                    return (
                        <tr {...headerGroupProps} key={key} className={cn('Row')}>
                            {headerGroup.headers.map(column => {
                                return column.render('Header', column.getHeaderProps());
                            })}
                        </tr>
                    );
                })}
            </thead>
            <tbody
                {...getTableBodyProps()}
                className={cn('Body')}
            >
                {rows.map(row => {
                    prepareRow(row);

                    const { key, ...rowProps } = row.getRowProps();

                    return (
                        <tr {...rowProps} key={`${key}_${pageIndex}`} className={cn('Row')}>
                            {row.cells.map(cell => {
                                return cell.render('Cell', cell.getCellProps());
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

Table.displayName = cn();
