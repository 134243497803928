import { FC, useCallback } from 'react';
import { useToggle } from 'react-use';

import { Cell } from 'components/Cell';
import {
    getIconProvider,
    IconEye,
    IconEyeOff,
} from 'components/Icon';
import { withTdAttrs } from 'hocs/withTdAttrs';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import { useEmail } from './CellEmployeeEmail.hooks/useEmail';
import { cn } from './CellEmployeeEmail.cn';
import { i18n } from './CellEmployeeEmail.i18n';
import { CellEmployeeEmailProps } from './CellEmployeeEmail.types';

import './CellEmployeeEmail.css';

const iconProviderEye = getIconProvider(IconEye);
const iconProviderEyeOff = getIconProvider(IconEyeOff);

const CellEmployeeEmailBase: FC<CellEmployeeEmailProps> = props => {
    const {
        className,
        value: {
            employeeId,
            companyId,
            emailMasked,
        },
        getAttrs,
    } = props;

    const [isEmailMasked, toggleIsEmailVisible] = useToggle(true);
    const handleClick = useCallback(() => toggleIsEmailVisible(), [toggleIsEmailVisible]);
    const {
        email,
        hasUnmaskedEmail,
        isLoading,
    } = useEmail(companyId, employeeId, isEmailMasked, emailMasked);

    const icon = hasUnmaskedEmail ? iconProviderEyeOff : iconProviderEye;
    const ariaLabel = hasUnmaskedEmail ? i18n('hide_email') : i18n('show_email');

    return (
        <Cell
            className={cn({ loading: isLoading }, [className])}
            getAttrs={getAttrs}
            justifyContent="space-between"
            size="m"
            title={email}
        >
            <Text className={cn('Text')}>{email}</Text>
            <Button
                aria-label={ariaLabel}
                className={cn('VisibilityControl')}
                icon={icon}
                progress={isLoading}
                size="s"
                view="clear"
                onClick={handleClick}
            />
        </Cell>
    );
};

export const CellEmployeeEmail = withTdAttrs(CellEmployeeEmailBase);
CellEmployeeEmail.displayName = cn();
