export const en = {
    in_process_of_cancelling_title: 'Cancellation of the service is being handled by the agency',
    draft_title: 'The service has been added to the business trip, but has not yet been sent for processing',
    new_title: 'New service',
    verification_title: 'The service is being verified by a coordinator',
    in_progress_title: 'The service is being processed by an agent',
    reserved_title: 'The service is booked',
    executed_title: 'The service is executed',
    cancelled_title: 'The service is cancelled',
    deleted_title: 'The service is deleted',
    refunded_title: 'A refund on the service is issued',
    in_process_of_cancelling_description: 'When finished, the status will change to "Cancelled".',
    draft_description: 'As soon as you add all the other services for the business trip and click the button "Execute business trip", the ticketing and hotel reservations will begin. We recommend that you add all services at once and send your business trip for processing, as prices may change and seats may be last.',
    new_description: 'Participant and documents have been added to the service, but the service itself has not yet been sent for processing',
    verification_description: 'The service exceeds the travel policy, so it has been sent to the coordinator to verify the necessary approvals. This usually takes no more than 24 hours.',
    in_progress_description: 'This process usually takes no more than {timespan} and you will be notified when it is complete. If the service is unavailable or the cost has changed, you will be contacted via chat or ticket.',
    timespan_rail: 'few minutes',
    timespan_avia: 'an hour',
    timespan_hotel: '24 hours',
    reserved_description: 'Services are booked. All you have to do is wait for the "Booked" status.',
    executed_description: 'Exactly what you booked is executed. If your plans change, you can delete the service, but note that services that have been executed are most often cancelled with penalties.',
    cancelled_description: 'Contact support if this cancellation is unexpected for you: they can help you add a new service and find out what happened to the old one.',
    deleted_description: '',
    refunded_description: '',
};
