import { pipe } from 'fp-ts/function';
import * as D from 'io-ts/Decoder';

export const DServiceType = D.union(
    D.literal('Avia'),
    D.literal('Rail'),
    D.literal('Hotel'),
);

export const ToNumber: D.Decoder<unknown, number> = pipe(
    D.string,
    D.parse(s => {
        const n = Number(s);

        return Number.isNaN(n) ?
            D.failure(s, `cannot decode ${s} to number`) :
            D.success(n);
    }),
);

export function ToArray<T>(dec: D.Decoder<unknown, T>): D.Decoder<unknown, T[]> {
    return {
        decode: s => Array.isArray(s) ? D.array(dec).decode(s) : D.array(dec).decode([s]),
    };
}

export const OneToBoolean: D.Decoder<unknown, boolean> = {
    decode: u => u === '1' ? D.success(true) : D.failure(u, '1'),
};

export const ZeroToBoolean: D.Decoder<unknown, boolean> = {
    decode: u => u === '0' ? D.success(true) : D.failure(u, '0'),
};
