import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_typography_display-s.css';

export type WithTypographyDisplaySProps = {
    typography?: 'display-s';
};

export const withTypographyDisplayS = withBemMod<WithTypographyDisplaySProps>(
    cn(),
    { typography: 'display-s' },
);
