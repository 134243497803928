import { withBemMod } from '@bem-react/core';
import { cnButton } from '@yandex-lego/components/Button';

import './Button_view_danger.css';

export type WithViewDanger = {
    /**
     * Модификатор, отвечающий за внешний вид кнопки.
     */
    view?: 'danger';
};

export const withViewDanger = withBemMod<WithViewDanger>(
    cnButton(),
    { view: 'danger' },
);
