import { FC, useCallback } from 'react';
import { useToggle } from 'react-use';

import { DocumentForm } from 'components/DocumentForm';
import { getIconProvider, IconPlus } from 'components/Icon';
import { SyncYandexIdDocumentsButton } from 'components/SyncYandexIdDocumentsButton';
import { withModalView } from 'hocs/withModalView';
import { withRemote } from 'hocs/withRemote';
import { useExtPersonGet } from 'hooks/useExtPersonGet';
import { usePersonDetails } from 'hooks/usePersonDetails';
import { Button } from 'shared/ui/Button';
import { mapLoadable } from 'utils/Loadable';

import { DocumentItem } from './DocumentItem';
import { cn } from './DocumentsList.cn';
import { i18n } from './DocumentsList.i18n';
import { DocumentsListProps } from './DocumentsList.types';
import { EmptyState } from './EmptyState';

import './DocumentsList.css';

const addIcon = getIconProvider(IconPlus);

const DocumentViewRemote = withModalView(withRemote(DocumentForm));

/**
 * Спсиок документов пользователя
 */
export const DocumentsList: FC<DocumentsListProps> = props => {
    const {
        items,
        personId,
        type = 'person',
        className,
    } = props;

    const [visibleModalAddDocument, toggleModalAddDocument] = useToggle(false);

    const handleAddClick = useCallback(() => {
        toggleModalAddDocument();
    }, [toggleModalAddDocument]);

    const isEmpty = items.length === 0;

    const extPerson = useExtPersonGet(personId, { isEnabled: type === 'extPerson' });
    const personDetails = usePersonDetails(personId, { isEnabled: type === 'person' });

    const person = type === 'person' ?
        personDetails :
        mapLoadable(extPerson, extPerson => ({
            ...extPerson,
            person_id: extPerson.ext_person_uuid,
        }));

    return (
        <div className={cn({ empty: isEmpty }, [className])}>
            {
                isEmpty ? (
                    <EmptyState handleAddClick={handleAddClick} />
                ) : (
                    <>
                        <div className={cn('List')}>
                            {
                                items.map(item => (
                                    <DocumentItem
                                        key={item.document_id}
                                        document={item}
                                        personId={personId}
                                        testId="document-item"
                                        type={type}
                                    />
                                ))
                            }
                        </div>
                        <div className={cn('Actions')}>
                            <Button
                                className={cn('AddButton')}
                                iconLeft={addIcon}
                                size="m"
                                view={isEmpty ? 'brand' : 'outline'}
                                onClick={handleAddClick}
                            >
                                {i18n('document_add')}
                            </Button>
                            <SyncYandexIdDocumentsButton />
                        </div>
                    </>
                )
            }
            <DocumentViewRemote
                modalViewOnCloseOff
                modalViewOnClose={toggleModalAddDocument}
                modalViewVisible={visibleModalAddDocument}
                personInfo={person}
                personType={type}
                onCancel={toggleModalAddDocument}
                onSubmit={toggleModalAddDocument}
            />
        </div>
    );
};

DocumentsList.displayName = cn();
