import { theme as dark } from '@yandex-int/hr-components/Theme/presets/dark';
import { theme as light } from '@yandex-int/hr-components/Theme/presets/light';
import { Theme } from '@yandex-lego/components/Theme';

import { ThemeName } from 'types/Theme';
import { localStorageHelper } from 'utils/localStorage';

export const THEME_LOCAL_STORAGE_KEY = 'LOCAL_STORAGE_THEME';
export interface LocalStorageThemeData { themeName: ThemeName }

export const getNameFromTheme = (theme?: Theme) => {
    switch (theme) {
        case light:
            return 'light';

        case dark:
            return 'dark';

        default:
            return 'light';
    }
};

export const getThemeFromName = (name?: ThemeName) => {
    switch (name) {
        case 'light':
            return light;

        case 'dark':
            return dark;

        default:
            return light;
    }
};

export const saveThemeToLocalStorage = (theme: Theme) => {
    const themeName = getNameFromTheme(theme);

    localStorageHelper.setData({ themeName }, THEME_LOCAL_STORAGE_KEY);
};

export const getThemeFromLocalStorage = () => {
    const localStorageData = localStorageHelper.getData<LocalStorageThemeData>(THEME_LOCAL_STORAGE_KEY);

    if (localStorageData?.themeName) {
        return getThemeFromName(localStorageData.themeName);
    }

    return;
};
