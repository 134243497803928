import { FC, useCallback, useEffect, useMemo } from 'react';
import { useToggle } from 'react-use';
import { Link } from '@yandex-int/hr-components/Link/desktop/bundle';

import { AppLink } from 'components/AppLink';
import { CovidRestrictions } from 'components/CovidRestrictions';
import { FailedSyncInfo } from 'components/FailedSyncInfo';
import { MessengerButton } from 'components/MessengerButton';
import { PersonalData } from 'components/PersonalData';
import { SupportChat } from 'components/SupportChat';
import { TaxiDetails } from 'components/TaxiDetails';
import { TripCallToAction } from 'components/TripCallToAction';
import { TripInfoPersonHeader } from 'components/TripInfoPersonHeader';
import { TripRoute } from 'components/TripRoute';
import { ViewTimeline } from 'components/ViewTimeline';
import { config } from 'config';
import { withModalView } from 'hocs/withModalView';
import { withRemote } from 'hocs/withRemote';
import { useAddChatParticipant } from 'hooks/useAddChatParticipant';
import { useBanners } from 'hooks/useBanners';
import { useMeta } from 'hooks/useMeta';
import { usePersonTrip } from 'hooks/usePersonTrip';
import { useTrip } from 'hooks/useTrip';
import { openURLInBrowser } from 'platform/bridge-methods';
import { isIOS } from 'platform/utils';
import { RoutePersonTrip } from 'routes/RoutePersonTrip';
import { Container } from 'shared/ui/Container';
import { InlineNotification } from 'shared/ui/InlineNotification';
import { Page } from 'types/Page';
import { getMessengerUrl, getTrackerUrl } from 'utils/urls';

import { getPersonTripContentType } from './PagePersonTrip.utils/getPersonTripContentType';
import { ApprovalStatus } from './ApprovalStatus';
import { EmptyState } from './EmptyState';
import { cn } from './PagePersonTrip.cn';
import { i18n } from './PagePersonTrip.i18n';
import { PagePersonTripRendererProps } from './PagePersonTrip.types';

import './PagePersonTrip.css';

const TimelineModal = withModalView(ViewTimeline);

// id внешней конференции
const EXTERNAL_CONF_ID = 14;

const PagePersonTripRenderer: FC<PagePersonTripRendererProps> = props => {
    const {
        trip,
        personTrip,
        personId,
        meta: { user },
        trip: { trip_id },
        activeTab,
    } = props;
    const { isB2B } = config;

    const [visibleTimelineModal, toggleVisibleTimelineModal] = useToggle(false);
    const [addParticipant] = useAddChatParticipant({
        onSuccess: () => {
            setTimeout(() => {
                if (personTrip.chat_id) {
                    openURLInBrowser(getMessengerUrl(personTrip.chat_id, true));
                }
            });
        },
    });

    // проверка тот кто открыл заявку является ли это его заявкой
    const isOwner = user.person_id === personId;

    const handleMessengerButtonClick = useCallback((): void => {
        if (personTrip.chat_id) {
            if (personTrip.actions?.join_chat && !isOwner) {
                addParticipant(trip_id, personId);
            } else {
                openURLInBrowser(getMessengerUrl(personTrip.chat_id, true));
            }
        }
    }, [addParticipant, isOwner, personId, personTrip.actions?.join_chat, personTrip.chat_id, trip_id]);

    const showMobileChat = isIOS() && !isB2B;

    const isOffline = personTrip.is_offline;
    const isInterconfReminderNeeded = trip.purposes.some(({ purpose_id }) => purpose_id === EXTERNAL_CONF_ID);
    const trackerIssue = personTrip.travel_details?.tracker_issue;
    const trackerLink = useMemo(() => {
        if (trackerIssue) {
            const trackerUrl = getTrackerUrl(trackerIssue);

            return (
                <div>
                    {i18n('see_in_tracker')}
                    <Link href={trackerUrl}>{trackerIssue}</Link>
                </div>
            );
        }
    }, [trackerIssue]);

    const messageIfOfflineOrInnerConf = useMemo(() => {
        if (isOffline) {
            return (
                <InlineNotification
                    className={cn('MessageBar')}
                    view="info"
                >
                    {i18n('offline_trip')}
                    {trackerLink}
                </InlineNotification>
            );
        }

        if (isInterconfReminderNeeded) {
            return (
                <InlineNotification
                    className={cn('MessageBar')}
                    view="info"
                >
                    {i18n('inner_conference_reminder')}
                </InlineNotification>
            );
        }
    }, [isOffline, isInterconfReminderNeeded, trackerLink]);

    const foreignCountries = useMemo(() => {
        if (!personTrip.route) return [];

        const [originCountry, ...destinationCountries] = personTrip.route;
        const originCountryName = originCountry?.country.name.ru;

        if (!originCountryName) return [];

        return [...new Set(
            destinationCountries
                .map(point => point.country.name.ru)
                .filter(Boolean)
                .filter(countryName => countryName !== originCountryName),
        )];
    }, [personTrip.route]);

    const contentType = getPersonTripContentType(personTrip);

    const { addBanner, deleteBanner } = useBanners();

    useEffect(() => {
        const warningBannerClosedTripIds = localStorage.getItem('WarningBannerClosedTripIds');

        let bannerId: number | null = null;

        const wasBannerClosed = warningBannerClosedTripIds?.split(',').includes(String(trip.trip_id));
        const hasDraftServices = personTrip.services.some(service => service.status === 'draft');
        const didExecute = personTrip.status !== 'new';
        const shouldAddBanner =
            personTrip.actions?.create_service &&
            !wasBannerClosed &&
            hasDraftServices &&
            !didExecute;

        if (shouldAddBanner) {
            const newWarningBannerClosedTripIds = warningBannerClosedTripIds ? warningBannerClosedTripIds + ',' + String(trip.trip_id) : String(trip.trip_id);

            bannerId = addBanner({
                type: 'info',
                description: (
                    <>
                        {i18n('we_recommend_to_submit')}
                        <br />
                        {i18n('we_recomment_to_submit_continuation')} <AppLink href="https://wiki.yandex-team.ru/HR/Travel/">{i18n('wiki_page')}</AppLink>.
                    </>),
                onClose: () => localStorage.setItem('WarningBannerClosedTripIds', newWarningBannerClosedTripIds),
            });
        }

        return () => {
            if (bannerId !== null) {
                deleteBanner(bannerId);
            }
        };
    }, [
        addBanner,
        deleteBanner,
        personTrip.actions?.create_service,
        personTrip.services,
        personTrip.status,
        trip.trip_id,
    ]);

    return (
        <div className={cn()}>
            <TripInfoPersonHeader
                activeTab={activeTab}
                personId={personId}
                personTrip={personTrip}
                trip={trip}
            />

            <Container className={cn('Content')}>
                {
                    contentType === 'general' ? (
                        <>
                            <div className={cn('Left')}>
                                <FailedSyncInfo personId={personId} />
                                {foreignCountries.length > 0 &&
                                    foreignCountries.map(country =>
                                        <CovidRestrictions
                                            key={country}
                                            country={country}
                                        />,
                                    )
                                }
                                {messageIfOfflineOrInnerConf}
                                <TripRoute {...props} />
                            </div>
                            <div className={cn('Right')}>
                                <PersonalData
                                    personId={personId}
                                    tripId={trip_id}
                                />
                                {Boolean(user.is_support) && (
                                    <ApprovalStatus approved={personTrip.is_approved} />
                                )}
                                <TripCallToAction
                                    personId={personId}
                                    personTrip={personTrip}
                                    toggleVisibleTimelineModal={toggleVisibleTimelineModal}
                                    trip={trip}
                                    trip_id={trip_id}
                                />
                                <TaxiDetails
                                    personId={personId}
                                    tripId={trip_id}
                                />
                                {(personTrip.chat_id || user.chat_id) && (showMobileChat ? null : <SupportChat />)}
                            </div>
                        </>
                    ) : (
                        <EmptyState type={contentType} />
                    )
                }
            </Container>

            {personTrip.actions?.join_chat && (
                <MessengerButton
                    className={cn('MessengerButton')}
                    isBreakInChat={Boolean(personTrip.actions?.join_chat && !isOwner)}
                    onClick={handleMessengerButtonClick}
                />
            )}

            <TimelineModal
                modalViewOnClose={toggleVisibleTimelineModal}
                modalViewVisible={visibleTimelineModal}
                personTrip={personTrip}
                trip_id={trip_id}
                onClose={toggleVisibleTimelineModal}
            />
        </div>
    );
};

const PagePersonTripRendererRemote = withRemote(PagePersonTripRenderer);

export const PagePersonTrip: Page<RoutePersonTrip> = props => {
    const {
        params,
        search = {},
    } = props;

    const { tripId, personId } = params;
    const { tab = 'active' } = search;
    const meta = useMeta();
    const trip = useTrip(tripId);
    const personTrip = usePersonTrip(tripId, personId);

    return (
        <PagePersonTripRendererRemote
            spinner
            activeTab={tab}
            meta={meta}
            personId={personId}
            personTrip={personTrip}
            trip={trip}
        />
    );
};

PagePersonTrip.displayName = cn();
