import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useVirtualElementRef } from '@yandex-lego/components/usePopper';

import { Card } from 'components/Card';
import { Remote } from 'components/Remote';
import { TrainCarriageDetails } from 'components/TrainCarriageDetails';
import { useAddService } from 'hooks/useAddService';
import { useTrip } from 'hooks/useTrip';
import { addToast } from 'utils/addToast';
import { isFailure } from 'utils/Loadable';
import { getParticipants } from 'utils/personToParticipant';
import { errorToast } from 'utils/errorToast';

import { i18n as i18nTrainCarriage } from '../../TrainCarriage/TrainCarriage.i18n';
import { TrainChooseSeatsPopup } from '../TrainChooseSeatsPopup/TrainChooseSeatsPopup';
import { ParticipantInfo } from '../TrainChooseSeatsPopup/TrainChooseSeatsPopup.types';
import { cn } from '../TrainDetails.cn';
import { i18n } from '../TrainDetails.i18n';
import { TrainCarriageProps } from '../TrainDetails.types';
import { serializeAddServicePayload } from '../TrainDetails.utils/serializeAddServicePayload';
import { useContextMenu } from '../TrainDetails.utils/useContextMenu';
import { TrainSeatInfoPopup } from '../TrainSeatInfoPopup/TrainSeatInfoPopup';

export const TrainCarriage: FC<TrainCarriageProps> = props => {
    const {
        trainDetails,
        carriage,
        dispatchService,
        addedServices,
        search: { trip_id, person_id: tripPersonId },
        canAddServices,
    } = props;

    const selectedSeats = addedServices
        .filter(service => service.carriageNumber === carriage.carriage_number)
        .map(service => service.seatNumber!);

    const [currentSelectedSeat, setCurrentSelectedSeat] = useState<number | null>(null);

    const selectedSeatDetails = useMemo(() => {
        if (currentSelectedSeat) {
            return carriage.places.find(
                place => place.place_number === currentSelectedSeat,
            );
        }

        return null;
    }, [carriage.places, currentSelectedSeat]);

    const trip = useTrip(trip_id ?? 0);

    const [addService] = useAddService({
        onSuccess: ({ service_id }, args) => {
            dispatchService({ type: 'unsetLoading',
                payload: {
                    serviceId: service_id,
                    seatNumber: Number(args.seat_number),
                    carriageNumber: carriage.carriage_number,
                },
            });
        },
        onError: (e, { seat_number }) => {
            errorToast(e, { title: i18n('add_service_error_message') });
            dispatchService({ type: 'removeService',
                payload: {
                    seatNumber: Number(seat_number),
                    carriageNumber: carriage.carriage_number,
                },
            });
        },
        options: {
            hideErrorNotifier: true,
        },
    });

    const { position, visible, openContextMenu, closeContextMenu } = useContextMenu();
    const {
        position: seatInfoPosition,
        visible: isSeatInfoVisible,
        openContextMenu: openSeatInfoContextMenu,
        closeContextMenu: closeSeatInfoContextMenu,
    } = useContextMenu();

    const handleClosePassengersPopup = useCallback(() => {
        closeContextMenu();
        closeSeatInfoContextMenu();
    }, [closeContextMenu, closeSeatInfoContextMenu]);

    const seatInfoAnchorRef = useVirtualElementRef({ rect: seatInfoPosition });
    const anchorRef = useVirtualElementRef({ rect: position });

    const handleAddService = useCallback((seat_number: number | null, event: MouseEvent) => {
        event.preventDefault();
        openSeatInfoContextMenu({ top: event.clientY, left: event.clientX });
        setCurrentSelectedSeat(seat_number);
    }, [openSeatInfoContextMenu]);

    const handleChoosePassengerClick = useCallback<MouseEventHandler>(event => {
        openContextMenu({ top: event.clientY, left: event.clientX });
    }, [openContextMenu]);

    const handleParticipantClick = useCallback((person: ParticipantInfo) => {
        if (currentSelectedSeat && trip_id) {
            const carriageGenderType = selectedSeatDetails?.compartment_gender;
            const personGender = person.gender;
            const isDifferendGender = (personGender === 'male' && carriageGenderType === 'female') ||
                (personGender === 'female' && carriageGenderType === 'male');

            if (isDifferendGender) {
                addToast({
                    title: i18n('choose_place_error_title'),
                    message: i18n('choose_place_error_description', { type: i18nTrainCarriage(`compartment_${carriageGenderType}`) }),
                });
            } else {
                addService(serializeAddServicePayload({
                    props,
                    tripPersonId,
                    tripId: trip_id,
                    personId: person.personId,
                    seatNumber: currentSelectedSeat,
                    isExternal: person.isExternal,
                }));
                dispatchService({ type: 'addService', payload: {
                    carriageNumber: carriage.carriage_number,
                    personId: person.personId,
                    personName: person.fullName,
                    login: person.login,
                    isExternal: person.isExternal,
                    seatNumber: currentSelectedSeat,
                } });
            }
        }

        handleClosePassengersPopup();
    }, [
        currentSelectedSeat,
        trip_id,
        handleClosePassengersPopup,
        selectedSeatDetails?.compartment_gender,
        addService,
        props,
        tripPersonId,
        dispatchService,
        carriage.carriage_number,
    ]);

    return (
        <Card key={carriage.carriage_number} className={cn('Carriage')}>
            <TrainCarriageDetails
                canAddServices={canAddServices}
                carriage={carriage}
                chosenPlaces={selectedSeats}
                currentSelectedSeat={currentSelectedSeat}
                trainDetails={trainDetails}
                onChange={trip_id ? handleAddService : undefined}
            />
            <TrainSeatInfoPopup
                anchorRef={seatInfoAnchorRef}
                carriageClass={i18n('class', { code: carriage.service_class_code || undefined })}
                selectedSeatDetails={selectedSeatDetails}
                visible={isSeatInfoVisible}
                onChooseClick={handleChoosePassengerClick}
                onClose={closeSeatInfoContextMenu}
            />
            {(trip && !isFailure(trip)) &&
                <Remote data={trip}>
                    {({ data: trip }) =>
                        <>
                            <TrainChooseSeatsPopup
                                anchorRef={anchorRef}
                                isInTravelPolicy={props.carriage.is_travel_policy_compliant}
                                participants={getParticipants(trip.person_trips)}
                                visible={visible}
                                onClose={handleClosePassengersPopup}
                                onParticipantClick={handleParticipantClick}
                            />
                        </>
                    }
                </Remote>
           }
        </Card>
    );
};
