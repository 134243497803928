import { FC } from 'react';

import { Card } from 'components/Card';
import { FinanceWidgetSkeleton } from 'components/FinanceWidgetSkeleton';
import { withRemote } from 'hocs/withRemote';

import { useBalance } from './AccountBalance.hooks/useBalance';
import { AccountBalance } from './AccountBalance';
import { cn } from './AccountBalance.cn';
import { AccountBalanceContainerProps } from './AccountBalance.types';

const AccountBalanceRemote = withRemote(AccountBalance);

const skeleton = <FinanceWidgetSkeleton className={cn('Skeleton')} />;

export const AccountBalanceContainer: FC<AccountBalanceContainerProps> = props => {
    const {
        className,
        companyId,
    } = props;

    const balance = useBalance(companyId);

    return (
        <div className={cn(null, [className])}>
            <AccountBalanceRemote
                data={balance}
                fallbackElement={<></>}
                skeleton={<Card className={cn('SkeletonContainer')}>{skeleton}</Card>}
            />
        </div>
    );
};

AccountBalanceContainer.displayName = cn();
