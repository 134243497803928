import { FetchError } from 'errors';
import { PersonTrip } from 'services/SwaggerApi';
import { RemoteData } from 'utils/Loadable';

import { createUseQueryHook } from './createUseQueryHook';

type UsePersonTripOptions = {
    isEnabled?: boolean;
};

type UsePersonTrip = (trip_id: number, person_id: string, options?: UsePersonTripOptions) =>
    RemoteData<PersonTrip, FetchError>;

export const usePersonTrip: UsePersonTrip = (trip_id, person_id, options = {}) => {
    const { isEnabled } = options;

    return createUseQueryHook('person_trip_detail', {
        enabled: isEnabled,
    })(trip_id, person_id);
};
