export const en = {
    general_rules: 'General rules',
    avia_rules: 'Rules for air tickets',
    rail_rules: 'Rules for railway tickets',
    hotel_rules: 'Rules for hotels',
    name: 'Name',
    name_placeholder: 'Enter the name',
    available_classes: 'Available classes',
    available_categories: 'Available carriage types',
    available_stars: 'Available number of stars',
    additional_conditions: 'Additional conditions',
    suggest_country_placeholder: 'Enter the country',
    suggest_city_placeholder: 'Enter the city',
    save_caption: 'A new travel policy will be added to the company',
    save_changes_caption: 'The changes will apply to new business trips',
    countries: 'Country',
    cities: 'City',
    stars: {
        one: '{count} stars',
        some: '{count} stars',
        many: '{count} stars',
        none: 'Without stars',
    },
    no_more_expensive: 'Not more expensive',
    one_way: '₽ one way',
    per_night: '₽ per night',
    for_direction: '₽ for direction',
    cheapest_more_percent: 'More expensive than the cheapest no more than by',
    no_later_than: 'No later than for',
    avia_before_days: 'days before departure',
    rail_before_days: 'days before departure',
    hotel_before_days: 'days before check-in',
    avia_max_transfer_duration: 'Maximum duration of the transplant',
    hours: 'hours',
    avia_refundable_only: 'Only with the possibility of a refund',
    avia_exchangeable_only: 'Only with the possibility of exchange',
    avia_with_baggage_only: 'Only with baggage',
    avia_business_days_only: 'Only with departure and arrival on weekdays',
    rail_business_days_only: 'Only with departure and arrival on weekdays',
    hotel_business_days_only: 'Only with booking on weekdays',
    add_condition: 'Add a condition',
    for_certain_directions: 'for certain directions',
};
