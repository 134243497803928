import { QueryClient } from 'react-query';

type QueryClientConfig = Required<ConstructorParameters<typeof QueryClient>>[0];

export const queryClientConfig: QueryClientConfig = {
    defaultOptions: {
        queries: {
            retry: false,
        },
    },
};
