import { HotelDetail, HotelFeatureItem } from 'services/SwaggerApi';
import { transliterate } from 'shared/lib/text/transliterate';

export const groupFacilities = (facilities: HotelDetail['facilities']) => {
    const facilitiesByCategory = facilities
        .reduce<Record<string, { name: string; features: HotelFeatureItem[] }>>(
            (features, { category, name }) => {
                features[category] ??= {
                    name: category,
                    features: [],
                };

                features[category].features.push({
                    feature_id: transliterate(name),
                    name,
                    type: 'bool',
                    value: true,
                });

                return features;
            }, {});

    return Object.values(facilitiesByCategory);
};
