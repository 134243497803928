import { useCallback } from 'react';

import { ArgsFn } from 'types/ArgsFn';

export const useCallbackWithScrollToTop = <T extends TypedArgs, U>(callback: ArgsFn<T, U>) => {
    const callbackWithScroll = useCallback((...args: T) => {
        window.scrollTo({ top: 0 });

        callback(...args);
    }, [callback]);

    return callbackWithScroll;
};
