import { Option as SelectOption } from '@yandex-lego/components/Select/Select';

import { TransactionStatus } from 'services/SwaggerApi';

import { i18n } from '../TransactionForm.i18n';

type GetTransactionStatusOption = (value: TransactionStatus) => SelectOption;

export const getTransactionStatusOption: GetTransactionStatusOption = value => {
    return {
        value,
        content: i18n(`status_value_${value}`),
    };
};
