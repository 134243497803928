import { UnknownError } from 'errors';
import { getTypedI18nLang } from 'utils/i18n';
import { logError } from 'utils/logError';

type FormatCurrencyOptions = {
    fractionDigits?: number;
};
type FormatCurrency = (value: string | number | undefined, options?: FormatCurrencyOptions) => string;

/**
 * Преобразует число в валюту (российский рубль)
 * Для всех локалей для обозначения рубля используем символ ₽
 */
export const formatCurrency: FormatCurrency = (value, options = {}) => {
    const { fractionDigits = 0 } = options;

    const language = getTypedI18nLang();
    const locale = language === 'ru' ? 'ru-RU' : 'en-US';
    const number = Number(value);

    if (Number.isNaN(number)) {
        logError(new UnknownError('Price is not a number', value));

        return '-';
    }

    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(number) + ' ₽';
};
