import { configureRootTheme } from '@yandex-lego/components/Theme';
import { sample } from 'effector';

import { ThemeName } from 'types/Theme';

import { RootGate } from '../gates';
import { root } from '../root';

import {
    getNameFromTheme,
    getThemeFromLocalStorage,
    getThemeFromName,
    saveThemeToLocalStorage,
} from './utils';

export const DEFAULT_THEME: ThemeName = 'light';

export const $theme = root.createStore<ThemeName>(DEFAULT_THEME);

export const changeThemeEvent = root.createEvent<ThemeName>();

export const configureThemeFx = root.createEffect<ThemeName, void, Error>();
export const initThemeFx = root.createEffect<void, ThemeName, Error>();

configureThemeFx.use(async themeName => {
    const theme = getThemeFromName(themeName);

    configureRootTheme({ theme });

    saveThemeToLocalStorage(theme);
});

initThemeFx.use(() => {
    const lsTheme = getThemeFromLocalStorage();
    const isDarkThemePreferred = window.matchMedia('(prefers-color-scheme: dark)').matches;

    if (lsTheme) {
        return getNameFromTheme(lsTheme);
    }

    if (isDarkThemePreferred) {
        return 'dark';
    }

    return DEFAULT_THEME;
});

sample({
    clock: RootGate.open,
    target: initThemeFx,
});

sample({
    clock: initThemeFx.doneData,
    target: changeThemeEvent,
});

sample({
    clock: changeThemeEvent,
    target: [configureThemeFx, $theme],
});
