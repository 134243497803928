import { FC, useCallback, useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { FieldDestination, FieldRangeDatePicker } from 'components/FormField/FormField.providers';
import { FieldLabel } from 'components/FormField2/utils';
import { IconGeolocationCircle } from 'components/Icon';
import { withProps } from 'hocs/withProps';
import { SettlementSuggestItem } from 'services/SwaggerApi';
import { Text } from 'shared/ui/Text';
import { validateRequiredField } from 'utils/form/validateRequiredField';

import { cn } from '../FormTripCreate.cn';
import { i18n } from '../FormTripCreate.i18n';
import { getEmptyRoutePoint } from '../FormTripCreate-RouteFields/RouteFields';

import { SimpleRouteProps } from './SimpleRoute.types';

const CityField = withProps({
    component: FieldDestination,
    validate: validateRequiredField,
},
cn('Field'),
)(Field);

const validateRangeDatePickerField = (value: DateRange | undefined) => {
    if (!value || !value.from || !value.to) {
        return 'Укажите даты поездки';
    }
};

const DateField = withProps({
    component: FieldRangeDatePicker,
    validate: validateRangeDatePickerField,
},
cn('Field'),
)(Field);

export const SimpleRoute: FC<SimpleRouteProps> = props => {
    const { fields } = props;

    const [originRoute, destinationRoute] = useMemo(() => fields.map((name, index) => ({ name, index })), [fields]);

    const handleDestinationChange = useCallback((destination: SettlementSuggestItem) => {
        fields.update(0, { ...fields.value[0], cityTo: destination });
    }, [fields]);

    const addRoutePoint = useCallback(() => {
        const firstRouteOrigin = fields.value?.[0]?.cityFrom;

        fields.push({ ...getEmptyRoutePoint(), cityTo: firstRouteOrigin });
    }, [fields]);

    return (
        <div className={cn('SimpleRoute')}>
            <FieldLabel
                isRequired
                className={cn('CitiesLabel')}
                label={i18n('route')}
            />
            <FieldLabel
                isRequired
                className={cn('DatesLabel')}
                label={i18n('trip_dates')}
            />
            <CityField
                className={cn('CityFrom')}
                name={`${originRoute.name}.cityFrom`}
                placeholder={i18n('city_from')}
                searchSuggestParams={{ service_type: 'hotel' }}
            />
            <OnChange name={`${destinationRoute.name}.cityFrom`}>{handleDestinationChange}</OnChange>
            <CityField
                className={cn('CityTo')}
                name={`${destinationRoute.name}.cityFrom`}
                placeholder={i18n('city_to')}
                searchSuggestParams={{ service_type: 'hotel' }}
            />
            <div className={cn('DateFields')}>
                <DateField
                    leftInputClassName={cn('DateFrom')}
                    leftInputPlaceholder={i18n('date_there')}
                    name={'dates'}
                    rightInputClassName={cn('DateTo')}
                    rightInputPlaceholder={i18n('date_back')}
                />
            </div>
            <div className={cn('RouteActions')}>
                <ButtonPlatform size={undefined} view="clear" onClick={addRoutePoint}>
                    <Text color="secondary" typography="body-s" weight="medium">
                        <IconGeolocationCircle size="m" />
                        {' '}
                        {i18n('make_complex_route')}
                    </Text>
                </ButtonPlatform>
            </div>
        </div>);
};
