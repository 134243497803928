import { IconClock } from 'components/Icon';
import { fromLocalDate } from 'utils/date/fromLocalDate';
import { getOrderCity } from 'utils/getOrderCity';

import { i18n } from '../TripRoute.yt.i18n';

import { segmentEvent } from './segmentEvent';
import { switchTransportFn } from './switchTransportFn';
import { TransportEventCreator } from './types';

export const arrivalToLocation: TransportEventCreator = service => {
    const date = switchTransportFn({
        rail: () => fromLocalDate(service.departure_at).subtract(30, 'minute'),
        avia: () => fromLocalDate(service.departure_at).subtract(120, 'minute'),
    })(service);

    const city = getOrderCity(service) || '';

    const text = switchTransportFn({
        rail: () => i18n('arrival_to_railway'),
        avia: () => i18n('arrival_to_airport'),
    })(service);

    return segmentEvent({
        date,
        text,
        city,
        icon: IconClock,
    });
};
