import { FC } from 'react';

import { TabsPage } from 'components/TabsPage';
import { TitlePage } from 'components/TitlePage';
import { Container } from 'shared/ui/Container';

import { cn } from './HeaderPage.cn';
import { HeaderPageProps } from './HeaderPage.types';

import './HeaderPage.css';

export const HeaderPage: FC<HeaderPageProps> = props => {
    const {
        className,
        children,
        title,
        titleSecondary,
        actions,
        tabs,
        activeTab,
        retButton,
    } = props;

    return (
        <Container wrapperClassName={cn({ tabs: Boolean(tabs) }, [className])}>
            <TitlePage
                actions={actions}
                className={cn('Title')}
                retButton={retButton}
                title={title}
                titleSecondary={titleSecondary}
            />
            {children}
            {tabs && (
                <TabsPage
                    activeTab={activeTab}
                    tabs={tabs}
                />
            )}
        </Container>
    );
};

HeaderPage.displayName = cn();
