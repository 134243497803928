import { TrainFilterOptions } from 'api/models/filters/TrainFilterOptions';
import { IFilterOption } from 'api/models/search/filter';
import { transliterate } from 'shared/lib/text/transliterate';
import { getTypedI18nLang } from 'utils/i18n';

function getTransliteratedFilter(filter: IFilterOption[]) {
    const lang = getTypedI18nLang();

    if (lang === 'en') {
        return filter.map(filterItem => {
            return { ...filterItem, caption: transliterate(filterItem.caption) };
        });
    }

    return filter;
}

export function getTransliteratedFilters(filters: TrainFilterOptions) {
    const transliteratedFilters = { ... filters };

    for (const filterKey in transliteratedFilters) {
        const typedFilterKey = filterKey as keyof TrainFilterOptions;

        transliteratedFilters[typedFilterKey] = getTransliteratedFilter(transliteratedFilters[typedFilterKey] ?? []);
    }

    return transliteratedFilters;
}
