import { FC, useCallback, useState } from 'react';
import { Swiper as SwiperType } from 'swiper/types';

import { GroupAvailablePersonsResponse } from 'services/SwaggerApi';

import { Content } from './Content/Content';
import { cn } from './EmployeeGroupAddModal.cn';
import { EmployeeGroupAddModalProps } from './EmployeeGroupAddModal.types';

import './EmployeeGroupAddModal.css';

export const EmployeeGroupAddModal: FC<EmployeeGroupAddModalProps> = props => {
    const { onSubmit, onClose, visible, groupName, travelPolicy, membersIds, companyId } = props;

    const [checkedPersons, setCheckedPersons] = useState<GroupAvailablePersonsResponse[]>([]);

    const handleSubmitModal = useCallback(
        (persons: GroupAvailablePersonsResponse[]) => {
            const checkedPersons = persons.filter(person => !membersIds.includes(person.person_id));
            const uncheckedPersons = persons.filter(person => membersIds.includes(person.person_id));

            onSubmit(checkedPersons, uncheckedPersons);
            onClose();
            setCheckedPersons([]);
        },
        [membersIds, onClose, onSubmit],
    );

    const handleReplaceClick = useCallback(() => {
        handleSubmitModal(checkedPersons);
    }, [checkedPersons, handleSubmitModal]);

    const handleExcludeClick = useCallback(() => {
        const filteredCheckedPersons = checkedPersons.filter(
            person =>
                ((person.group_name === groupName || person.group_name === null) &&
                    (person.travel_policy_name === travelPolicy || person.travel_policy_name === null)) ||
                membersIds.includes(person.person_id),
        );

        handleSubmitModal(filteredCheckedPersons);
    }, [checkedPersons, handleSubmitModal, groupName, travelPolicy, membersIds]);

    const handleSubmit = useCallback(
        (swiper: SwiperType) => {
            const isPersonsHasOtherGroupOrPolicy = checkedPersons.some(person => {
                const isNoGroupMatching = person.group_name !== groupName && person.group_name !== null;
                const isNoPolicyMatching =
                    person.travel_policy_name !== travelPolicy && person.travel_policy_name !== null;

                return (isNoGroupMatching || isNoPolicyMatching) && !membersIds.includes(person.person_id);
            });

            if (isPersonsHasOtherGroupOrPolicy) {
                swiper.slideTo(1);
            } else {
                handleSubmitModal(checkedPersons);
            }
        },
        [checkedPersons, membersIds, groupName, travelPolicy, handleSubmitModal],
    );

    return (
        <Content
            companyId={companyId}
            membersIds={membersIds}
            setCheckedPersons={setCheckedPersons}
            visible={visible}
            onExcludeClick={handleExcludeClick}
            onModalClose={onClose}
            onReplaceClick={handleReplaceClick}
            onSubmit={handleSubmit}
        />
    );
};

EmployeeGroupAddModal.displayName = cn();
