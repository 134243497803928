import { FC } from 'react';
import { useToggle } from 'react-use';

import { FlightCardActions } from 'components/FlightCardActions';
import { FlightFareFamily } from 'components/FlightFareFamily';
import { FlightInfoRoute } from 'components/FlightInfoRoute';
import { ServiceCard } from 'components/ServiceCard';
import { getFlightCardValues } from 'utils/getFlightCardValues';

import { cn } from './FlightSearchCard.cn';
import { FlightSearchCardProps } from './FlightSearchCard.types';

import './FlightSearchCard.css';

export const FlightSearchCard: FC<FlightSearchCardProps> = props => {
    const {
        className,
        service,
        tripId,
        searchId,
        personId,
        adultsCount,
        canAddServices,
    } = props;

    const {
        price,
        fare_family,
    } = service;

    const {
        legs,
        baggage,
        banner,
        ticketsRemaining,
    } = getFlightCardValues(service);

    const [isVisible, toggleVisible] = useToggle(false);

    return (
        <div className={cn(null, [className])}>
            <ServiceCard banner={banner}>
                <div className={cn('CardContent')}>
                    <div className={cn('Cards')}>
                        {legs.map((leg, index) => {
                            return (
                                <div key={index} className={cn('Card')}>
                                    <FlightInfoRoute extendable={false} leg={leg} />
                                </div>
                            );
                        })}
                    </div>
                    <div className={cn('Divider')} />
                    <FlightCardActions
                        baggage={baggage}
                        canAddServices={canAddServices}
                        isFareFamily={fare_family}
                        price={price}
                        ticketsRemaining={ticketsRemaining}
                        onClick={toggleVisible}
                    />
                </div>
            </ServiceCard>
            {tripId && (
                <FlightFareFamily
                    adultsCount={adultsCount}
                    flightFromSearch={service}
                    isVisible={isVisible}
                    personId={personId}
                    searchId={searchId}
                    tripId={tripId}
                    onClose={toggleVisible}
                />
            )}
        </div>
    );
};

FlightSearchCard.displayName = cn();
