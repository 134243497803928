import { FC, useCallback } from 'react';
import { Platform } from '@yandex-int/hr-components/utils';
import dayjs from 'dayjs';
import { useSwiper } from 'swiper/react';

import {
    FieldSelect,
    FieldTextarea,
    FormProvider,
} from 'components/FormField2';
import { useSwiperAutoHeight } from 'hooks/useSwiperAutoHeight';
import { i18nAction } from 'i18n/i18nAction';
import { Holiday } from 'services/SwaggerApi';
import { Button } from 'shared/ui/Button';
import { ModalLayout } from 'shared/ui/ModalLayout';
import { Text } from 'shared/ui/Text';

import { cn } from '../ExecuteTripButton.cn';
import { i18n, i18nRaw } from '../ExecuteTripButton.i18n';

import { FormSlideProps } from './FormSlide.types';

import './FormSlide.css';

const getHolidayOption = (holiday: Holiday) => {
    const dayjsDate = dayjs(holiday.date);
    const isAnotherYear = dayjsDate.year() !== dayjs().year();
    const dateTemplate = isAnotherYear ? 'D MMMM YYYY' : 'D MMMM';

    const formattedDate = dayjs(holiday.date).format(dateTemplate);
    const detailedDate = i18nRaw(`${holiday.type}_date`, { date: <Text color="primary">{formattedDate}</Text> });
    const optionContent = <Text color="secondary">{detailedDate}</Text>;

    return {
        value: holiday.date,
        content: optionContent,
        checkedText: formattedDate,
    };
};

export const FormSlide: FC<FormSlideProps> = props => {
    const {
        onClose,
        methods,
        schema,
        holidays,
        unsafeRef,
        platform,
    } = props;

    const swiper = useSwiper();

    const { ref } = useSwiperAutoHeight();

    const onCancelClick = useCallback(() => {
        swiper.slideTo(0);
    }, [swiper]);

    const onNextClick = useCallback(() => {
        swiper.slideTo(2);
    }, [swiper]);

    const selectOptions = holidays?.holidays.map(getHolidayOption) || [];

    return (
        <ModalLayout
            actions={[
                <Button
                    key="cancel"
                    size={platform === Platform.DESKTOP ? 'm' : 'l'}
                    view="default"
                    onClick={onCancelClick}
                >
                    {i18nAction('back')}
                </Button>,
                <Button
                    key="next"
                    size={platform === Platform.DESKTOP ? 'm' : 'l'}
                    view="brand"
                    onClick={methods.handleSubmit(onNextClick)}
                >
                    {i18nAction('continue')}
                </Button>,
            ]}
            className={cn('FormSlide')}
            platform={platform}
            title={i18n('working_holidays_modal_title')}
            onClose={onClose}
        >
            {
                (
                    <FormProvider methods={methods} schema={schema}>
                        <form ref={ref} className={cn('Form', [])}>
                            {
                                holidays && (
                                    <FieldSelect
                                        className={cn('Select')}
                                        label={i18n('choose_dates_label')}
                                        name="dates"
                                        options={selectOptions}
                                        placeholder={i18n('choose_dates_placeholder')}
                                        unsafe_scope={unsafeRef}
                                        view="outline"
                                    />
                                )
                            }
                            <FieldTextarea
                                label={i18n('reason_label')}
                                name="reason"
                            />
                        </form>
                    </FormProvider>
                )
            }
        </ModalLayout>
    );
};
