import { FC, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ModalSlider } from 'components/ModalSlider';
import { usePlatform } from 'hooks/usePlatform';

import { useExecuteService } from '../ExecuteTripButton.hooks/useExecuteService';
import { i18n } from '../ExecuteTripButton.i18n';
import { TripHolidaysScenario, WorkingHolidaysFormValues } from '../ExecuteTripButton.types';
import { getInitialHolidaysScenario } from '../ExecuteTripButton.utils/getInitialHolidaysScenario';
import { getTravelPolicyDialogContent } from '../ExecuteTripButton.utils/getTravelPolicyDialogContent';
import { getWorkingHolidays } from '../ExecuteTripButton.utils/getWorkingHolidays';
import { FinalSlide } from '../FinalSlide';
import { FormSlide } from '../FormSlide';
import { InfoSlide } from '../InfoSlide';

import { getSchema } from './Content.shema';
import { ContentProps } from './Content.types';

import './Content.css';

export const Content: FC<ContentProps> = props => {
    const {
        visible,
        onClose,
        actions,
        holidays,
        workingHolidays,
        personId,
        tripId,
    } = props;

    const { platform } = usePlatform();
    const innerRef = useRef<HTMLDivElement | null>(null);

    const initialScenario = getInitialHolidaysScenario(workingHolidays, holidays);
    const [scenario, setScenario] = useState<Nullable<TripHolidaysScenario>>(initialScenario);

    const needVerification = Boolean(actions?.send_to_verification);
    const { title, message } = getTravelPolicyDialogContent(needVerification);

    const schema = getSchema({ isDatesRequired: holidays !== null });
    const methods = useForm<WorkingHolidaysFormValues>({
        defaultValues: {
            reason: '',
            dates: [],
        },
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        resolver: yupResolver(schema),
    });

    const { executeFn, isExecuting } = useExecuteService(actions, onClose);

    const handleExecute = useCallback(() => {
        // если уже указаны рабочие выходные, мы их не меняем
        if (initialScenario === TripHolidaysScenario.REASON_ALREADY_SPECIFIED) {
            return executeFn(tripId, personId, { working_holidays: workingHolidays });
        }

        // если не указаны - генерим новую строку
        // при этом если рабочие выходные не выбраны, то строка будет пустой, ее и отправляем в бек
        const working_holidays = getWorkingHolidays(methods.getValues());

        return executeFn(tripId, personId, { working_holidays });
    }, [methods, initialScenario, executeFn, tripId, personId, workingHolidays]);

    const slides = [
        {
            isVisible:
                scenario !== TripHolidaysScenario.NO_HOLIDAYS &&
                scenario !== TripHolidaysScenario.REASON_ALREADY_SPECIFIED,
            content: (
                <InfoSlide
                    platform={platform}
                    setScenario={setScenario}
                    onClose={onClose}
                />
            ),
        },
        {
            isVisible: scenario === TripHolidaysScenario.WORKING_ON_HOLIDAYS,
            content: (
                <FormSlide
                    holidays={holidays}
                    methods={methods}
                    platform={platform}
                    schema={schema}
                    unsafeRef={innerRef}
                    onClose={onClose}
                />
            ),
        },
        {
            isVisible: true,
            content: (
                <FinalSlide
                    handleExecute={handleExecute}
                    isExecuting={isExecuting}
                    message={message}
                    platform={platform}
                    scenario={scenario}
                    setScenario={setScenario}
                    title={scenario === TripHolidaysScenario.NOT_WORKING_ON_HOLIDAYS ? i18n('subheader_working_holidays_2') : title}
                    willChill={scenario === TripHolidaysScenario.NOT_WORKING_ON_HOLIDAYS}
                    onClose={onClose}
                />
            ),
        },
    ];

    return (
        <ModalSlider
            innerRef={innerRef}
            testId="TEST_ID_SLIDER_WORKING_HOLIDAYS"
            visible={visible}
            onModalClose={onClose}
        >
            {slides?.map(({ isVisible, content }) =>
                isVisible && <>{ content }</>,
            )}

        </ModalSlider>
    );
};
