import { withBemMod } from '@bem-react/core';

import { cn } from '../Bubble.cn';

import './Bubble_mark_dash.css';

export interface WithMarkDashProps {
    mark?: 'dash';
}

export const withMarkDash = withBemMod<WithMarkDashProps>(cn(), {
    mark: 'dash',
});
