import { FC } from 'react';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { FormProvider } from 'components/FormField2';
import { usePersonDetailsPut } from 'hooks/usePersonDetailsPut';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { PersonBaseFields } from './FormPersonDetails.fields/PersonBaseFields';
import { PersonContactFields } from './FormPersonDetails.fields/PersonContactFields';
import { useFormPersonDetails } from './FormPersonDetails.hooks/useFormPersonDetails';
import { cn } from './FormPersonDetails.cn';
import { i18n } from './FormPersonDetails.i18n';
import { FormPersonDetailsProps } from './FormPersonDetails.types';

import './FormPersonDetails.css';

export const FormPersonDetails: FC<FormPersonDetailsProps> = props => {
    const {
        personDetails,
        onSubmit: onSuccess,
        onCancel,
    } = props;

    const [personDetailsPut, state, { error }] = usePersonDetailsPut({ onSuccess,
        options: { hideValidationErrors: true } });

    const { methods, schema, onSubmit } = useFormPersonDetails(personDetails, personDetailsPut);

    return (
        <FormProvider error={error} methods={methods} schema={schema}>
            <form className={cn()} data-testid="form-person-details" onSubmit={methods.handleSubmit(onSubmit)}>
                <Text className={cn('Title')} typography="title-m">
                    {i18n('profile')}
                </Text>
                <div className={cn('Fields')}>
                    <PersonBaseFields />
                    <div className={cn('FieldsBlock')}>
                        <PersonContactFields />
                    </div>
                </div>
                <div className={cn('Controls')}>
                    <ButtonPlatform
                        className={cn('Cancel')}
                        onClick={onCancel}
                    >
                        {i18n('cancel')}
                    </ButtonPlatform>
                    <ButtonPlatform
                        className={cn('Submit')}
                        progress={isLoading(state)}
                        type="submit"
                        view="brand"
                    >
                        {i18n('save')}
                    </ButtonPlatform>
                </div>
            </form>
        </FormProvider>
    );
};

FormPersonDetails.displayName = cn();
