import { pipe } from 'fp-ts/lib/function';
import * as D from 'io-ts/Decoder';

import { ActivePage } from 'shared/constants/ActivePage';
import { ToArray, ToNumber } from 'utils/io-ts/decoders';

import { route } from './route';

export const HotelServiceDetailsTab = D.union(
    D.literal('available_rooms'),
    D.literal('features'),
    D.literal('review'),
    D.literal('placement_terms'),
    D.literal('description'),
);

export type HotelServiceDetailsTab = D.TypeOf<typeof HotelServiceDetailsTab>;

const Rail = pipe(
    D.type({
        type: D.union(D.literal('Rail')),
    }),
);

const Hotel = pipe(
    D.type({
        type: D.union(D.literal('Hotel')),
    }),
    D.intersect(D.partial({
        tab: HotelServiceDetailsTab,
    })),
);

const Service = D.sum('type')({ Rail, Hotel });

export const RouteServiceDetailsDecoder = D.type({
    params: D.type({
        searchId: D.string,
        key: D.string,
    }),
    search: pipe(
        D.type({
            person_id: D.string,
            name: D.string,
        }),
        D.intersect(D.partial({
            trip_id: ToNumber,
            tariff_index: ToArray(ToNumber),
            adult: ToNumber,
        })),
        D.intersect(Service),
    ),
});

export type RouteServiceDetails = D.TypeOf<typeof RouteServiceDetailsDecoder>;

export const RouteServiceDetails = route(
    '/search/:searchId/results/:key/',
    RouteServiceDetailsDecoder,
    {
        activePage: ActivePage.ALL_TRIPS_LIST,
    },
);
