import * as yup from 'yup';

import { CompanyName } from 'services/SwaggerApi';
import { FieldPersonValue } from 'utils/serializePerson';

import { i18n } from './SelectApproverForm.i18n';

type NewCompanyId = CompanyName['company_id'];

export const schema = yup.object({
    approver: yup.mixed<FieldPersonValue>().required(() => i18n('approver_required')),
    new_company_id: yup.mixed<NewCompanyId>(),
});
