import { ErrorName } from './ErrorName';

type ErrorParams = {
    message: string;
    requestId: string;
    detail?: unknown;
    data?: unknown;
};

export class ClientBaseError extends Error {
    public readonly name: ErrorName;
    public readonly status: ErrorCode;
    public readonly requestId: string;
    public readonly detail?: unknown;

    public constructor(status: ErrorCode, name: ErrorName, params: ErrorParams) {
        const {
            message,
            requestId,
            detail,
        } = params;

        super(message);

        this.status = status;
        this.name = name;
        this.requestId = requestId;
        this.detail = detail;
    }
}
