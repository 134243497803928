import { OptionSimple as SelectOption } from '@yandex-lego/components/Select/desktop/bundle';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { i18n } from '../AccountExpenses.i18n';
import { Period } from '../AccountExpenses.types';

type Option = SelectOption & { value: number };
type UseSelectOptions = (dateFrom: Dayjs, dateTo: Dayjs) => {
    value: number;
    options: Option[];
};
type OptionToMs = Record<Period, number>;

dayjs.extend(duration);

const optionToMs: OptionToMs = {
    month_3: dayjs.duration(3, 'months').asMilliseconds(),
    month_6: dayjs.duration(6, 'months').asMilliseconds(),
    year: dayjs.duration(1, 'year').asMilliseconds(),
};

export const useSelectOptions: UseSelectOptions = (dateFrom, dateTo) => {
    const options: Option[] = [
        {
            value: optionToMs.month_3,
            content: i18n('option_months_3'),
        },
        {
            value: optionToMs.month_6,
            content: i18n('option_months_6'),
        },
        {
            value: optionToMs.year,
            content: i18n('option_year'),
        },
    ];
    let value = optionToMs.month_3;
    const diffMonth = dateTo.diff(dateFrom, 'month');

    if (diffMonth > 6) {
        value = optionToMs.year;
    } else if (diffMonth > 3) {
        value = optionToMs.month_6;
    }

    return {
        value,
        options,
    };
};
