import { FC } from 'react';

import { ModalPlatform } from 'shared/ui/ModalPlatform';

import { FormEarlyInLateOut } from './FormEarlyInLateOut';
import { cn } from './ModalEarlyInLateOut.cn';
import { i18n } from './ModalEarlyInLateOut.i18n';
import { ModalEarlyInLateOutProps } from './ModalEarlyInLateOut.types';

import './ModalEarlyInLateOut.css';

export const ModalEarlyInLateOut: FC<ModalEarlyInLateOutProps> = props => {
    const {
        className,
        visible,
        toggleModal,
    } = props;

    return (
        <ModalPlatform
            className={cn(null, [className])}
            description={i18n('description')}
            scope="inplace"
            showCloseButton={false}
            title={i18n('title')}
            toggleVisible={toggleModal}
            visible={visible}
        >
            <FormEarlyInLateOut {...props} />
        </ModalPlatform>

    );
};
