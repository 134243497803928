import { config } from 'config';
import { i18nErrors } from 'i18n/i18nErrors';
import { uuidv4 } from 'shared/lib/uuidv4';
import { AuthDetails } from 'types/AuthDetails';
import { HTTPMethod } from 'types/HTTPMethod';
import { formatParams } from 'utils/formatParams';
import { getPassportUrl } from 'utils/urls';

import { getRequestOptions } from './getRequestOptions';

const { isB2B } = config;

const {
    host,
    port,
    protocol = 'https',
} = config.hosts.trip_api || {};

// надо либо использовать ноду, либо генерить js и вставлять в документ
// чтобы как-то иначе прокинуть абсолютные адреса стендов типа pr-XXX.trip.test.yandex-team.ru
// поэтому там где нет хоста, отключаю (для тестинга)
let tripApi = host ?
    `${protocol}://${host}${port ? `:${port}` : ''}` :
    '';

if (config.env === 'hermione') {
    tripApi = '';
}

const EXTRA_HTTP_CODES = [
    400,
    401,
    403,
    404,
    500,
];

type EXTRA_HTTP_RESPONSES_TYPES =
    ResponseOnStatus<400, object> |
    ResponseOnStatus<401, object> |
    ResponseOnStatus<403, unknown> |
    ResponseOnStatus<404, unknown> |
    ResponseOnStatus<500, unknown>;

function redirectToPassport() {
    const passportUrl = getPassportUrl(window.location.href);

    window.location.assign(passportUrl);
}

// eslint-disable-next-line max-params
function swaggerFetchTypedErrors<T extends ResponseOnStatus<number, unknown>, TPayload, TQueryParams>(
    path: string,
    queryParams: TQueryParams,
    typedCodes: number[],
    method: string,
    payload: TPayload,
    options?: RequestInit,
): Promise<T | EXTRA_HTTP_RESPONSES_TYPES> {
    const requestId = uuidv4();
    const optionsToSend = getRequestOptions({ method, payload, requestId, options });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
    return fetch(tripApi + path + formatParams(queryParams as any || {}), optionsToSend)
        .then(async response => {
            const isUnauthorized = response.status === 401;

            let parsedResponse;

            try {
                if (response.headers.get('content-disposition')?.includes('attachment')) {
                    parsedResponse = await response.blob();
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    parsedResponse = await response.json();
                }
            } catch {
                if (isUnauthorized) {
                    redirectToPassport();
                }

                if (EXTRA_HTTP_CODES.includes(response.status)) {
                    const text = await response.text();

                    return {
                        status: response.status,
                        body: text,
                        requestId,
                    } as EXTRA_HTTP_RESPONSES_TYPES;
                }
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (isUnauthorized && (!isB2B || parsedResponse.detail === AuthDetails.UNAUTHORIZED)) {
                redirectToPassport();
            }

            if (typedCodes.includes(response.status)) {
                if (response.status === 204) {
                    return {
                        status: 204,
                        body: null,
                        requestId,
                    } as T;
                }

                return {
                    status: response.status,
                    body: parsedResponse,
                    requestId,
                } as T;
            }

            const defaultError = i18nErrors('error_occurred');

            return {
                status: response.status,
                body: parsedResponse || defaultError,
                requestId,
            } as EXTRA_HTTP_RESPONSES_TYPES;
        });
}

const RETRIES_COUNT = config.env === 'production' ? 3 : 0;
const TIMEOUT = 1000;

// eslint-disable-next-line max-params
export function apiFetchTypedErrors<T extends {status: number; body: unknown}, TPayload, TQueryParams>(
    path: string,
    queryParams: TQueryParams,
    typedCodes: number[],
    method: HTTPMethod,
    payload: TPayload,
    options?: RequestInit,
): Promise<T | EXTRA_HTTP_RESPONSES_TYPES> {
    return new Promise((resolve, reject) => {
        const modifiedApiPath = config.env === 'hermione' ? '/api-e2e' + path : path;

        const retry = (retriesLeft: number) => {
            swaggerFetchTypedErrors(
                modifiedApiPath,
                queryParams,
                typedCodes,
                method,
                payload,
                options,
            ).then(response => {
                if (method === 'GET' && response.status === 500 && retriesLeft) {
                    return setTimeout(() => retry(retriesLeft - 1), TIMEOUT);
                }

                // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
                resolve(response as any);
            }).catch(reject);
        };

        return retry(RETRIES_COUNT);
    });
}
