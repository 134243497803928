import { FC } from 'react';
import { useToggle } from 'react-use';

import { Card } from 'components/Card';
import { CompanyInvoice } from 'components/CompanyInvoice';
import { HelpTooltip } from 'components/HelpTooltip';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { DateFormat, formatDate } from 'utils/formatDate';

import { useBalanceNotification } from './AccountBalance.hooks/useBalanceNotification';
import { cn } from './AccountBalance.cn';
import { i18n } from './AccountBalance.i18n';
import { AccountBalanceProps } from './AccountBalance.types';

import './AccountBalance.css';

const DEFAULT_PAYMENT_SCHEME = 'prepayment';

export const AccountBalance: FC<AccountBalanceProps> = props => {
    const { data } = props;

    const payment_scheme = data.payment_scheme ?? DEFAULT_PAYMENT_SCHEME;

    const lastTopUpDateFormatted = formatDate(data.last_deposit_date, DateFormat.LLL, i18n('no_data'));
    const amount = payment_scheme === 'postpayment' ? Math.abs(Number(data.amount)) : Number(data.amount);
    const valueFormatted = formatCurrency(amount, { fractionDigits: 2 });

    useBalanceNotification(data.status);

    const [modalVisible, toggleModalVisible] = useToggle(false);

    const title = i18n(`account_balance_title_${payment_scheme}`);
    const description = payment_scheme === 'postpayment' ?
        <div className={cn('PostpaymentDescription')}>
            {i18n('convenient_service')}
            <HelpTooltip
                info
                content={i18n('convenient_service_description')}
                direction="bottom"
                iconSize="m"
            />
        </div> :
        i18n('last_top_up', { date: lastTopUpDateFormatted });

    const valueColor = payment_scheme === 'postpayment' ? 'alert' : 'control-primary';

    return (
        <Card
            className={cn('Card')}
            description={description}
            size="s"
            title={title}
        >
            <div className={cn('Content')}>
                <Text
                    as="p"
                    className={cn('Value')}
                    color={valueColor}
                    typography="title-l"
                >
                    {valueFormatted}
                </Text>
                {data.can_create_invoice && (
                    <Button
                        size="m"
                        view="primary"
                        onClick={toggleModalVisible}
                    >
                        {i18n('top_up')}
                    </Button>
                )}
            </div>
            <CompanyInvoice visible={modalVisible} onModalClose={toggleModalVisible} />
        </Card>
    );
};

AccountBalance.displayName = cn();
