export const ru = {
    details: 'Данные компании',
    profile: 'Профиль',
    finance: 'Финансы',
    main: 'Главная',
    label_more: 'Ещё',
    label_services: 'Сервисы',
    me_on_staff: 'Я на Стаффе',
    new_trip: 'Создать заявку',
    staff: 'Сотрудники',
    trips: 'Заявки',
    companies: 'Компании',
};
