import { logError } from 'utils/logError';

import { BROWSER_MOCKED_BRIDGE } from './browser-mocks';
import { getPlatformValue } from './getPlatformValue';
import { sync } from './sync';

// TODO https://st.yandex-team.ru/BTRIP-5781
function isVersionLess(currentVersion: string, targetVersion: string) {
    return currentVersion < targetVersion;
}

function getPlatformId(): string {
    return getPlatformValue('id');
}

export function getPlatformShowTabs(): boolean | undefined {
    return getPlatformValue('showTabs');
}

export function isIOS() {
    return getPlatformId() === 'ios';
}

export function isAndroid() {
    return getPlatformId() === 'android';
}

export function isBrowser() {
    return getPlatformId() === 'browser';
}

export function getPlatformHandler(name: string): Function | undefined {
    switch (getPlatformId()) {
        case 'ios': {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return (...args: any[]) => {
                // Context with descriptor is required to call postMessage method for webkit.
                // https://github.com/WebKit/webkit/blob/master/Source/WebCore/page/UserMessageHandler.cpp#L50
                // @ts-ignore
                const context = webkit.messageHandlers[name];
                const passedArgs = args.length > 0 ? args : [null];

                if (context) {
                    // eslint-disable-next-line no-useless-call,prefer-spread
                    return context.postMessage.apply(context, passedArgs);
                }
            };
        }

        case 'android':
            return typeof window.Android[name] === 'function' ?
                (data?: unknown) =>
                    typeof data === 'undefined' ?
                        window.Android[name]!() :
                        window.Android[name]!(
                            typeof data === 'object' ? JSON.stringify(data) : data,
                        ) :
                undefined;

        default:
            return BROWSER_MOCKED_BRIDGE[name];
    }
}

export function getPlatformLang() {
    return getPlatformValue('lang');
}

export function isMethodSupported(name: string) {
    let result: boolean;

    try {
        // @ts-ignore
        const method = isIOS() ? webkit.messageHandlers[name] : sync(name);

        result = Boolean(method);
    } catch {
        result = false;
    }

    return result;
}

export function getNativeAppVersion(): string {
    return getPlatformValue('appVersion');
}

/**
 * Проверяет, удовлетворяет-ли текущая версия приложения целевой. На момент написания в прелизных версиях
 * приложения используется версия в формате "${major}.${minor}.${patch}". В тестовых сборках и эмуляторах
 * версия может отличаться. По этому чтобы при отладке сообщение не выпадало во всех emergency-ветках
 * возвращается false
 * @param version ожидается версия в формате "${major}.${minor}.${patch}"
 * @returns если и текущая версия и целевая переданы в корректном формате возвращает результат сравнения иначе false
 */
export function isNativeAppVersionLessThen(version: string): boolean {
    const appVersion = getNativeAppVersion();

    if (appVersion === '0.0.0') {
        return false;
    }

    try {
        return isVersionLess(appVersion, version);
    } catch (error) {
        logError(Error('Ошибка при парсинге версии приложения'));
    }

    return false;
}
