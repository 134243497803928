import * as D from 'io-ts/Decoder';

import { ActivePage } from 'shared/constants/ActivePage';

import { RouteUntyped } from './decoders/RouteUntyped';
import { route } from './route';

export type RouteTripCreate = D.TypeOf<typeof RouteUntyped>;

export const RouteTripCreate = route(
    '/trips/new/',
    RouteUntyped,
    {
        activePage: ActivePage.ACTIVE_TRIPS_LIST,
    },
);
