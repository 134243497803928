import { useExecuteExtraServices } from 'hooks/useExecuteExtraServices';
import { useExecuteServices } from 'hooks/useExecuteServices';
import { useSendToVerification } from 'hooks/useSendToVerification';
import { PersonTrip } from 'services/SwaggerApi';
import { UXFeedbackEvents } from 'shared/constants/UXFeedbackEvents';
import { createUxFeedbackEvent } from 'shared/ui/UXFeedback';
import { addToast } from 'utils/addToast';
import { errorToast } from 'utils/errorToast';
import { isLoading } from 'utils/Loadable';

import { i18n } from '../ExecuteTripButton.i18n';

export const useExecuteService = (actions: PersonTrip['actions'], onClose: VoidFunction) => {
    const canExecuteExtra = actions?.execute_extra_services;
    const needVerification = actions?.send_to_verification;
    const target = i18n(needVerification ? 'verification' : 'execution');

    const [execute, executeStatus] = useExecuteServices({
        onSuccess: () => {
            addToast({ title: i18n('send_services_success', { target }) });
            createUxFeedbackEvent(UXFeedbackEvents.TRIP_SERVICE_CONFIRM);
            onClose();
        },
        onError: e => {
            errorToast(e, { title: i18n('execution_banned_title'), message: i18n('execution_banned_message') });
        },
        options: {
            hideErrorNotifier: true,
            invalidateOnSuccess: true,
        },
    });

    const [executeExtra, executeExtraStatus] = useExecuteExtraServices({
        onSuccess: () => {
            addToast({ title: i18n('send_services_success', { target }) });
            onClose();
        },
        onError: e => {
            errorToast(e, { title: i18n('execution_banned_title'), message: i18n('execution_banned_message') });
        },
        options: {
            hideErrorNotifier: true,
            invalidateOnSuccess: true,
        },
    });

    const [sendToVerification, sendToVerificationStatus] = useSendToVerification({
        onSuccess: () => {
            addToast({ title: i18n('send_services_success', { target }) });
            createUxFeedbackEvent(UXFeedbackEvents.TRIP_SERVICE_CONFIRM);
            onClose();
        },
    });

    if (needVerification) {
        return {
            executeFn: sendToVerification,
            isExecuting: isLoading(sendToVerificationStatus),
        };
    }

    if (canExecuteExtra) {
        return {
            executeFn: executeExtra,
            isExecuting: isLoading(executeExtraStatus),
        };
    }

    return {
        executeFn: execute,
        isExecuting: isLoading(executeStatus),
    };
};
