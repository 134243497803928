export const en = {
    airlines: 'Airlines',
    airports: 'Airports',
    airports_there_back: 'Airports (departure/arrival)',
    apply: 'Apply',
    arrival: 'Arrival',
    arrival_to_back: 'Arrival back',
    arrival_to_there: 'Arrival to destination',
    departure: 'Departure',
    departure_from_back: 'Departure back',
    departure_from_there: 'Departure to destination',
    flight_back: 'Flight back',
    flight_there: 'Flight to destination',
    flight_time_there_back: 'Departure/arrival time',
    reset: 'Reset',
    search_type_error: 'Wrong search type, expected Avia, Rail, or Hotel',
    services_search: 'Search tickets and hotels',
    there_back: 'Round trip',
    time: 'Time',
    time_back: 'Back',
    time_hint: 'Morning: 06:00 - 12:00, afternoon: 12:00 - 18:00, evening: 18:00 - 00:00, night: 00:00 - 06:00',
    time_there: 'To',
    transfers: 'Transfers',
    with_baggage: 'With baggage',
    with_changeable: 'With the possibility of exchange',
    with_refundable: 'With the possibility of refund',
};
