import { FC } from 'react';

import { Card } from 'components/Card';
import { FieldTextinput } from 'components/FormField2';

import { cn } from '../FormTravelPolicy.cn';
import { i18n } from '../FormTravelPolicy.i18n';

import { GeneralRulesProps } from './GeneralRules.types';

import '../FormTravelPolicy.css';

export const GeneralRules: FC<GeneralRulesProps> = () => {
    return (
        <Card title={i18n('general_rules')}>
            <div className={cn('Fieldset')}>
                <FieldTextinput
                    className={cn('FieldName')}
                    label={i18n('name')}
                    name="name"
                    placeholder={i18n('name_placeholder')}
                />
            </div>
        </Card>
    );
};

GeneralRules.displayName = cn();
