import { useCallback, useState } from 'react';

import {
    ComputePagesCount,
    HandlePageChange,
    HandlePageSizeChange,
    PageLinkConstructorFunc,
    PageSizeOption,
    ResetCurrentPage,
    UseTablePagination,
} from './useTablePagination.types';

const pageLinkConstructor: PageLinkConstructorFunc = (pageNumber, pageSize) => {
    const queryParams = new URLSearchParams([
        ['page', String(pageNumber)],
    ]);

    if (typeof pageSize === 'number') queryParams.append('limit', String(pageSize));

    const queryParamsAsStr = queryParams.toString();

    return queryParamsAsStr ? `?${queryParamsAsStr}` : '';
};

const CURRENT_PAGE_INITIAL = 1;
const PAGE_SIZE_INITIAL = 10;
const pageSizeSelectOptions: PageSizeOption[] = [10, 15];

export const useTablePagination: UseTablePagination = () => {
    const [currentPage, setCurrentPage] = useState(CURRENT_PAGE_INITIAL);
    const [pageSize, setPageSize] = useState(PAGE_SIZE_INITIAL);

    const computePagesCount = useCallback<ComputePagesCount>(rowsCount => {
        return Math.ceil(rowsCount / pageSize);
    }, [pageSize]);
    const resetCurrentPage = useCallback<ResetCurrentPage>(() => {
        setCurrentPage(CURRENT_PAGE_INITIAL);
    }, []);
    const handlePageChange = useCallback<HandlePageChange>(page => {
        setCurrentPage(page);
    }, []);
    const handlePageSizeChange = useCallback<HandlePageSizeChange>(pageSize => {
        setPageSize(pageSize);
        resetCurrentPage();
    }, [resetCurrentPage]);

    return {
        paginationProps: {
            currentPage,
            hasPageSizeSelect: true,
            pageSize,
            pageSizeSelectOptions,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
            pageLinkConstructor,
        },
        computePagesCount,
        resetCurrentPage,
    };
};
