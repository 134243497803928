import { FC, Fragment, useMemo } from 'react';
import { useToggle } from 'react-use';
import { Divider } from '@yandex-lego/components/Divider';

import { ImageWithZoom } from 'components/ImageWithZoom';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';

import { findBestPriceTariffs } from './utils/findBestPriceTariffs';
import { cn } from './HotelRoom.cn';
import { i18n } from './HotelRoom.i18n';
import { HotelRoomProps } from './HotelRoom.types';
import { Tariff } from './Tariff';

import './HotelRoom.css';

const MAX_VISIBLE_TARIFFS = 2;

export const HotelRoom: FC<HotelRoomProps> = props => {
    const {
        nights,
        roomData,
        insideCard = true,
        className,
        searchId,
        optionId,
        trip,
        personId,
        addedServices,
        dispatchService,
        adultsCount,
        canAddServices,
    } = props;

    const {
        name,
        description,
        tariffs,
        images,
    } = roomData;

    const hasHiddenTariffs = tariffs.length > MAX_VISIBLE_TARIFFS;

    const [showAllTariffs, toggleShowAllTariffs] = useToggle(false);

    const { bestPriceTarrifId, bestPriceWithMealTarrifId } = useMemo(() => findBestPriceTariffs(tariffs), [tariffs]);

    const { isMobile } = usePlatform();

    const visibleTariffs = tariffs.slice(0, showAllTariffs ? tariffs.length : MAX_VISIBLE_TARIFFS);

    return (
        <div className={cn({ insideCard }, [className])}>
            <div className={cn('Header')}>
                <div className={cn('Description')}>
                    <Text as="div" typography={isMobile ? 'title-s' : 'title-m'}>{name}</Text>
                    {
                        description && (
                            <Text
                                as="div"
                                color="secondary"
                                typography={isMobile ? 'body-s' : 'body-m'}
                            >
                                {description}
                            </Text>
                        )
                    }
                </div>
                <ImageWithZoom withZoom images={images} />
            </div>
            <div className={cn('TariffList')}>
                {visibleTariffs.map(tariff => (
                    <Fragment key={tariff.id}>
                        <Divider />
                        <Tariff
                            addedServices={addedServices}
                            adultsCount={adultsCount}
                            canAddServices={canAddServices}
                            dispatchService={dispatchService}
                            hotelId={optionId}
                            isBestPrice={bestPriceTarrifId === tariff.id}
                            isBestPriceWithMeal={bestPriceWithMealTarrifId === tariff.id}
                            nights={nights}
                            personId={personId}
                            searchId={searchId}
                            tariffData={tariff}
                            tariffName={name}
                            trip={trip}
                        />
                    </Fragment>
                ))}
            </div>
            {
                hasHiddenTariffs && (
                    <div className={cn('ToggleShowAll')}>
                        <Divider />
                        <Button
                            size="m"
                            view="outline"
                            onClick={toggleShowAllTariffs}
                        >
                            {
                            showAllTariffs ?
                                i18n('toggle_hide') :
                                i18n('toggle_show', { count: tariffs.length - MAX_VISIBLE_TARIFFS })
                            }
                        </Button>
                    </div>
                )
            }
        </div>
    );
};

HotelRoom.displayName = cn();
