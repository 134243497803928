import { useEffect } from 'react';

import { withRemote } from 'hocs/withRemote';
import { Page } from 'types/Page';
import { isSuccess } from 'utils/Loadable';

import { useUserEmail } from './PageRegisterEmail.hooks/useUserEmail';
import { useUserEmailConfirm } from './PageRegisterEmail.hooks/useUserEmailConfirm';
import { PageRegisterEmail } from './PageRegisterEmail';
import { cn } from './PageRegisterEmail.cn';

import './PageRegisterEmail.css';

const PageRegisterEmailRemote = withRemote(PageRegisterEmail);

export const PageRegisterEmailContainer: Page = () => {
    const email = useUserEmail();
    const [confirmEmail, confirmEmailState] = useUserEmailConfirm({
        options: {
            hideErrorNotifier: true,
        },
    });

    useEffect(() => {
        confirmEmail();
    }, [confirmEmail]);

    return (
        <PageRegisterEmailRemote
            data={email}
            isEmailConfirmed={isSuccess(confirmEmailState)}
        />
    );
};

PageRegisterEmailContainer.displayName = cn();
