import { FC } from 'react';

import { Card } from 'components/Card';
import { FieldCheckbox } from 'components/FormField2';
import { FieldOptions } from 'components/FormField2/Fields/FieldOptions';

import { AdditionalConditions } from '../AdditionalConditions';
import { CheckboxInputLabel } from '../CheckboxInputLabel';
import { cn } from '../FormTravelPolicy.cn';
import { i18n } from '../FormTravelPolicy.i18n';
import { getStarsOptions } from '../FormTravelPolicy.utils/getFormOptions';

import { HotelRulesProps } from './HotelRules.types';

import '../FormTravelPolicy.css';

export const HotelRules: FC<HotelRulesProps> = props => {
    const { methods } = props;

    return (
        <Card title={i18n('hotel_rules')}>
            <div className={cn('Fieldset')}>
                <div className={cn('CommonOptions')}>
                    <FieldCheckbox
                        checkboxLabel={
                            <div className={cn('FieldCheckboxLabel')}>
                                {i18n('no_more_expensive')}
                                <div className={cn('FieldCheckboxInputWrapper')}>
                                    <CheckboxInputLabel name="hotel_max_price_per_night" />
                                    {i18n('per_night')}
                                </div>
                            </div>
                        }
                        className={cn('FieldCheckbox', { type: 'label' })}
                        name="is_hotel_max_price_per_night"
                    />

                    <FieldCheckbox
                        checkboxLabel={
                            <div className={cn('FieldCheckboxLabel')}>
                                {i18n('cheapest_more_percent')}
                                <div className={cn('FieldCheckboxInputWrapper')}>
                                    <CheckboxInputLabel name="hotel_cheapest_more_percent" /> %
                                </div>
                            </div>
                        }
                        className={cn('FieldCheckbox', { type: 'label' })}
                        name="is_hotel_cheapest_more_percent"
                    />

                    <FieldCheckbox
                        checkboxLabel={
                            <div className={cn('FieldCheckboxLabel')}>
                                {i18n('no_later_than')}
                                <div className={cn('FieldCheckboxInputWrapper')}>
                                    <CheckboxInputLabel name="hotel_book_before_days" widthSize="s" />
                                    {i18n('hotel_before_days')}
                                </div>
                            </div>
                        }
                        className={cn('FieldCheckbox', { type: 'label' })}
                        name="is_hotel_book_before_days"
                    />

                    <FieldCheckbox
                        checkboxLabel={i18n('hotel_business_days_only')}
                        className={cn('FieldCheckbox')}
                        data-testId="hotel_business_days_only"
                        name="hotel_business_days_only"
                    />
                </div>

                <FieldOptions
                    className={cn('FieldOptions')}
                    label={i18n('available_stars')}
                    name="hotel_stars"
                    options={getStarsOptions()}
                />

                <AdditionalConditions methods={methods} name="hotel_max_price_directions" />
            </div>
        </Card>
    );
};

HotelRules.displayName = cn();
