import { ComponentProps, FC, useCallback, useState } from 'react';
import { Select } from '@yandex-int/hr-components/Select/desktop/bundle';

import { useHandleChange } from 'components/FormField2/utils/useHandleChange';

import { withFormField } from '../../FormField2';
import { cn as cnFormField } from '../../FormField2.cn';
import { FormFieldProps } from '../../FormField2.types';

import { cn } from './FieldSelect.cn';

import './FieldSelect.css';

export type SelectPropsBase = ComponentProps<typeof Select>;

export type SelectProps = Omit<SelectPropsBase, 'width'> & {
    width?: SelectPropsBase['width'] | 'auto';
    hideOverflow?: boolean;
};

const Control: FC<SelectProps & FormFieldProps> = props => {
    const {
        name,
        width = 'max',
        hideOverflow = false,
        size = 'm',
        view = 'outline',
        deps,
        controller,
        context,
        ...selectProps
    } = props;

    const {
        field: { onBlur, value },
    } = controller;

    const handleChange = useHandleChange({ controller, context, deps });

    const [opened, setOpened] = useState(false);
    const handleSetOpened = useCallback((opened: boolean) => {
        // Чтобы срабатывала валидация при закрытии селекта (равносильно потере фокуса селектом)
        if (!opened) {
            onBlur();
        }

        setOpened(opened);
    }, [onBlur]);

    return (
        <Select
            {...selectProps}
            className={cnFormField('Control', { name }, [cn({ hideOverflow })])}
            iconProps={{
                glyph: 'arrowShortDown',
            }}
            name={name}
            opened={opened}
            setOpened={handleSetOpened}
            size={size}
            value={value}
            view={view}
            width={width === 'max' ? 'max' : undefined}
            onBlur={onBlur}
            onChange={handleChange}
        />
    );
};

export const FieldSelect = withFormField(Control);
