import { FC } from 'react';

import { CellHeader } from '../CellHeader';

import { cn } from './CellActionsHeader.cn';
import { CellActionsHeaderProps } from './CellActionsHeader.types';

import './CellActionsHeader.css';

export const CellActionsHeader: FC<CellActionsHeaderProps> = props => {
    const {
        className,
    } = props;

    return <CellHeader {...props} className={cn(null, [className])} />;
};

CellActionsHeader.displayName = cn();
