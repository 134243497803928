import { FC, useCallback } from 'react';
import { Link } from '@yandex-lego/components/Link/desktop/bundle';

import { RegisterModalLayout } from 'components/RegisterModalLayout';
import { Text } from 'shared/ui/Text';
import { addToast } from 'utils/addToast';
import { errorToast } from 'utils/errorToast';
import { isLoading } from 'utils/Loadable';

import { usePersonSubscribe } from './PageRegisterEmail.hooks/usePersonSubscribe';
import { cn } from './PageRegisterEmail.cn';
import { i18n } from './PageRegisterEmail.i18n';
import { EmailConfirmedProps, EmailWaitForConfirmationProps, PageRegisterEmailProps } from './PageRegisterEmail.types';

import './PageRegisterEmail.css';

const EmailWaitForConfirmation: FC<EmailWaitForConfirmationProps> = props => {
    const {
        email,
        person_id,
    } = props;

    const [sendEmail, sendEmailState] = usePersonSubscribe({
        onSuccess: () => addToast({ title: i18n('send_success') }),
        onError: e => {
            errorToast(e, { title: i18n('send_error') });
        },
        options: {
            hideErrorNotifier: true,
        },
    });

    const handleSendEmailClick = useCallback(() => {
        sendEmail(person_id);
    }, [person_id, sendEmail]);

    return (
        <RegisterModalLayout
            className={cn()}
            title={i18n('title')}
        >
            <Text
                className={cn('FollowLink')}
                typography="body-s"
            >
                {i18n('follow_the_link', { email })}
            </Text>
            <Text
                className={cn('GoBack')}
                color="secondary"
                typography="body-s"
            >
                {i18n('go_back')}
            </Text>
            <Text
                className={cn('NoEmail')}
                color="secondary"
                typography="body-s"
            >
                {i18n('no_email')}
            </Text>
            <Link
                pseudo
                disabled={isLoading(sendEmailState)}
                view="default"
                onClick={handleSendEmailClick}
            >
                {i18n('send_again')}
            </Link>
        </RegisterModalLayout>
    );
};

const EmailConfirmed: FC<EmailConfirmedProps> = props => {
    const {
        email,
    } = props;

    return (
        <RegisterModalLayout
            className={cn()}
            title={i18n('email_confirmed')}
        >
            <Text
                className={cn('GoBack')}
                color="secondary"
                typography="body-s"
            >
                {i18n('wait_for_link', { email })}
            </Text>
        </RegisterModalLayout>
    );
};

export const PageRegisterEmail: FC<PageRegisterEmailProps> = props => {
    const {
        data: {
            email,
            person_id,
        },
        isEmailConfirmed,
    } = props;

    if (isEmailConfirmed) {
        return <EmailConfirmed
            email={email}
        />;
    }

    return <EmailWaitForConfirmation
        email={email}
        person_id={person_id}
    />;
};

PageRegisterEmail.displayName = cn();
