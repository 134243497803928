import { withBemMod } from '@bem-react/core';

import { cn } from '../Text.cn';

import './Text_weight_fat.css';

export interface WithWeightFatProps {
    weight?: 'fat';
}

export const withWeightFat = withBemMod<WithWeightFatProps>(cn(), {
    weight: 'fat',
});
