export const ru = {
    arrival: 'Прибытие',
    branded: 'Фирменный',
    carriage_owner: 'Перевозчик',
    carriage_type: 'Тип вагона',
    departure: 'Отправление',
    electronic_registration: 'Электронная регистрация',
    time: 'Время',
    train_category: 'Категория поезда',
    train_name: 'Поезд',
    train_station: 'Вокзал',
};
