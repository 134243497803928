import { FC } from 'react';
import { cn as classname } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { Icon } from '@yandex-lego/components/Icon/touch-phone';

import { ACTranslatedEntry } from 'services/AeroClubApi/ACTranslatedEntry';

import companys from './assets/companys.json';

const cn = classname('IconAvia');

const defaultAirlineName = '# Не Удалять! Авиакомпания - заглушка #';

type AviaIcons = Record<string, string | undefined> & Record<typeof defaultAirlineName, string>;

interface IconAviaProps extends IClassNameProps {
    name?: string | null | undefined;
    size?: number;
}

interface IconAviaPropsOld extends IClassNameProps {
    name?: Nullable<ACTranslatedEntry>;
    size?: number;
}

const aviaIcons: AviaIcons = Object.fromEntries(companys);

const commonUrlPath = 'https://avatars.mds.yandex.net/get-avia/';

const getIconUrl = (iconPath: string): string => `${commonUrlPath}${iconPath}`;
const defaultIconPath: string = aviaIcons[defaultAirlineName];

export const IconAvia: FC<IconAviaProps> = ({ name, className, size = 20 }) => {
    const nameString = name || '';
    const iconPath = aviaIcons[nameString] || defaultIconPath;
    const iconUrl = getIconUrl(iconPath);
    const style = {
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url(${iconUrl})`,
        backgroundSize: `${size}px ${size}px`,
        borderRadius: '50%',
    };

    return <Icon className={cn(null, [className])} style={style} />;
};

/**@deprecated Возможно надо будет выпилить после перехода на новую ручку */
export const IconAviaOld: FC<IconAviaPropsOld> = ({ name, className, size = 20 }) => {
    const nameString = name ? name.ru || name.en || '' : '';

    const iconPath = aviaIcons[nameString] || defaultIconPath;
    const iconUrl = getIconUrl(iconPath);
    const style = {
        width: `${size}px`,
        height: `${size}px`,
        backgroundImage: `url(${iconUrl})`,
        backgroundSize: `${size}px ${size}px`,
    };

    return <Icon className={cn(null, [className])} style={style} />;
};
