import { PersonItem } from 'components/Suggest/_type/Suggest_type_persons';
import { SwaggerApi } from 'services/SwaggerApi';
import { getFullName } from 'shared/lib/person/getFullName';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

type FetchPersonOptions = {
    api: SwaggerApi;
    value: unknown;
};

export async function fetchPerson({ api, value }: FetchPersonOptions): Promise<PersonItem | null> {
    const result = await api.get_person(value as string)
        .then(throwHTTPErrors).then(response => response)
        .catch(() => null);

    if (!result) {
        return null;
    }

    const { login, first_name, last_name, uid } = result;

    return {
        id: uid,
        login,
        title: getFullName({ first_name, last_name }),
    };
}
