// сделано на основе - https://github.yandex-team.ru/data-ui/ya-travel/blob/ac9b7562cb2db469ddac4756e63751a64c6b387a/src/projects/trains/lib/segments/tariffs/constants.ts

export const PLATZKARTE = 'platzkarte'; // плацкарт
export const COMPARTMENT = 'compartment'; // купе
export const SUITE = 'suite'; // св
export const COMMON = 'common'; // общие
export const SITTING = 'sitting'; // сидячие
export const SOFT = 'soft'; // люкс

export const RESERVED = 'reserved';
export const AVAILABLE = 'available';
export const SELECTED = 'selected';
export const UNAVAILABLE = 'unavailable';

export const PLACE_SIZE = 24;
export const GENDER_TITLE_LINE_HEIGHT = 8;
export const GENDER_TITLE_LINE_GAP = 2;

export const SVG_NAMESPACE = 'http://www.w3.org/2000/svg';

export const TRAIN_TARIFF_CLASSES = [
    PLATZKARTE,
    COMPARTMENT,
    SUITE,
    SOFT,
    COMMON,
    SITTING,
];

// Классы тарифов, упорядоченные для дневных поездов. Сидячка и общий - в начале списка.
export const TRAIN_TARIFF_CLASSES_DAY = [
    SITTING,
    COMMON,
    PLATZKARTE,
    COMPARTMENT,
    SUITE,
    SOFT,
];

// Типы мест
export const PLACE_TYPE_BOTTOM = 'bottom';
export const PLACE_TYPE_TOP = 'top';
export const PLACE_TYPE_SIDE = 'side';
export const PLACE_TYPE_BOTTOM_SIDE = 'bottom-side';
export const PLACE_TYPE_TOP_SIDE = 'top-side';
export const PLACE_TYPE_NEAR_TABLE = 'near-table';
export const PLACE_TYPE_FOLDING = 'folding';
export const PLACE_TYPE_REGULAR = 'regular';
export const PLACE_TYPE_COMPARTMENT = 'compartment'; // используется для вагонов, где нужно выкупать все купе целиком

/**
 * Бэкенд никогда не возвращает GENDER_TYPE.SINGLE для поля compartmentGender в заказе.
 * Потому что GENDER_TYPE.SINGLE используется только в одном месте приложения:
 * при выборе мест в купе. Когда купе должно быть определенного гендера, и мы должны выбрать какого.
 */
export enum GENDER_TYPE {
    MIXED = 'mixed',
    MALE = 'male',
    FEMALE = 'female',
    SINGLE = 'single',
}

export const GENDER_TYPES = Object.values(GENDER_TYPE);

export function isGenderType(gender: GENDER_TYPE | unknown): gender is GENDER_TYPE {
    return GENDER_TYPES.includes(gender as GENDER_TYPE);
}

export const MALE_AND_FEMALE_GENDER_TYPES: GENDER_TYPE[] = [
    GENDER_TYPE.MALE,
    GENDER_TYPE.FEMALE,
];

export enum EGender {
    MALE = 'male',
    FEMALE = 'female',
}

export function mapTrainsGenderToCommon(
    gender: GENDER_TYPE | null,
): EGender | undefined {
    if (gender === GENDER_TYPE.MALE) {
        return EGender.MALE;
    } else if (gender === GENDER_TYPE.FEMALE) {
        return EGender.FEMALE;
    }

    return undefined;
}
