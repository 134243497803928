import { FC } from 'react';
import { useToggle } from 'react-use';

import { Dropdown } from 'components/Dropdown';
import { getIconProvider, IconAddToList, IconCheck, IconPlus } from 'components/Icon';
import { Button } from 'shared/ui/Button';
import { ErrorState } from 'shared/ui/ErrorState';
import { ListItem } from 'shared/ui/ListItem';

import { cn } from './SelectExt.cn';
import { i18n } from './SelectExt.i18n';
import { SelectExtProps } from './SelectExt.types';

import './SelectExt.css';

/**
 * Адаптивный селект
 */
export const SelectExt: FC<SelectExtProps> = props => {
    const {
        className,
        options,
        width = 'auto',
        value,
        disabled,
        onChange,
        onAddonButtonClick,
        addonButtonText,
    } = props;

    const [isPopupVisible, togglePopupVisible] = useToggle(false);

    const handleAddonButtonClick = () => {
        onAddonButtonClick?.();
        togglePopupVisible(false);
    };

    const selectedValueContent = options.find(option => String(option.value) === String(value))?.content;

    const showAddonButton = options.length > 0 && addonButtonText;

    return (
        <div className={cn(null, [className])}>
            <Dropdown
                showIcon
                disabled={disabled}
                drawerProps={{
                    scope: 'inplace',
                }}
                isPopupVisible={isPopupVisible}
                popupProps={{
                    scope: 'inplace',
                }}
                shrink={false}
                togglePopupVisible={togglePopupVisible}
                triggerElement={
                    <Button
                        className={cn('TriggerButton')}
                        disabled={disabled}
                        size="m"
                        view="outline"
                        width={width}
                    >
                        {selectedValueContent || i18n('placeholder_text')}
                    </Button>
                }
            >
                <div className={cn('Popup')} style={{ padding: '8px' }}>
                    {options.length === 0 && (
                        <ErrorState
                            actions={[(
                                <Button
                                    key="create"
                                    className={cn('AddButton')}
                                    iconLeft={getIconProvider(IconPlus, { size: 'm' })}
                                    size="m"
                                    view="outline"
                                    onClick={handleAddonButtonClick}
                                >
                                    {addonButtonText}
                                </Button>
                            )]}
                            image={<IconAddToList
                                className={cn('ListIcon')}
                                size="l"
                                sizeManagement="parent"
                            />}

                            title={i18n('empty_text')}
                        />
                    )}
                    {options.map(option => (
                        <ListItem
                            key={option.value}
                            className={cn('MenuItem')}
                            leadingTextParams={{
                                label: option.content,
                                size: 's',
                            }}
                            trailing={option.value === value && <IconCheck size="m" />}
                            onClick={() => {
                                onChange(option.value);
                                togglePopupVisible(false);
                            }}
                        />
                    ))}
                    {showAddonButton && (
                        <div className={cn('AddonButton')}>
                            <Button
                                iconLeft={getIconProvider(IconPlus, { size: 'm' })}
                                size="m"
                                view="ghost"
                                width="max"
                                onClick={handleAddonButtonClick}
                            >
                                {addonButtonText}
                            </Button>
                        </div>
                    )}
                </div>
            </Dropdown>
        </div>
    );
};

SelectExt.displayName = cn();
