import { FC, useRef } from 'react';
import { useToggle } from 'react-use';
import { Icon } from '@yandex-int/hr-components/Icon/desktop/bundle';
import dayjs from 'dayjs';

import { Card } from 'components/Card';
import { usePlatform } from 'hooks/usePlatform';
import { Button } from 'shared/ui/Button';
import { ListItem } from 'shared/ui/ListItem';
import { ModalPlatform } from 'shared/ui/ModalPlatform';
import { Text } from 'shared/ui/Text';

import { cn } from '../HotelDetails.cn';
import { i18n } from '../HotelDetails.i18n';
import { useShowMoreButtton } from '../useShowMoreButton';

import { ImportantInformationProps } from './ImportantInformation.types';

import './ImportantInformation.css';

export const ImportantInformation: FC<ImportantInformationProps> = props => {
    const {
        className,
        importantInformation,
        checkIn,
        checkOut,
    } = props;

    const ref = useRef<HTMLDivElement>(null);
    const [showModal, toggleShowModal] = useToggle(false);

    const { showButton } = useShowMoreButtton(ref);

    const { isMobile } = usePlatform();

    return (
        <div className={cn('Section', [className])}>
            <Text className={cn('Title')} color="secondary" typography={isMobile ? 'title-s' : 'title-m'}>
                {i18n('placement_terms')}
            </Text>
            <div className={cn('TimesWrapper')}>
                <div className={cn('Times')}>
                    <Card>
                        <ListItem
                            leading={
                                <Text color="secondary">
                                    <Icon color="secondary" glyph="clock" size="l" />
                                </Text>
                            }
                            leadingTextParams={{
                                label: i18n('check_in_from', { time: dayjs(checkIn).format('HH:mm') }),
                                caption: i18n('early_check_in_possible'),
                            }}
                        />
                    </Card>
                    <Card>
                        <ListItem
                            leading={
                                <Text color="secondary">
                                    <Icon color="secondary" glyph="clock" size="l" />
                                </Text>
                            }
                            leadingTextParams={{
                                label: i18n('check_out_before', { time: dayjs(checkOut).format('HH:mm') }),
                                caption: i18n('late_check_out_possible'),
                            }}
                        />
                    </Card>
                </div>
                {importantInformation && (
                    <>
                        <Card
                            className={cn('TextWrapperCard')}
                            title={i18n('important_information')}
                        >
                            <div ref={ref} className={cn('TextWrapper')}>
                                <Text
                                    as="div"
                                    className={cn('DescriptionText')}
                                    typography="body-m"
                                >
                                    {importantInformation}
                                </Text>
                            </div>
                            {showButton && (
                                <Button
                                    className={cn('ShowMoreButton')}
                                    size="m"
                                    view="outline"
                                    width="auto"
                                    onClick={toggleShowModal}
                                >
                                    {i18n('show_all')}
                                </Button>
                            )}
                        </Card>
                        <ModalPlatform
                            title={i18n('important_information')}
                            toggleVisible={toggleShowModal}
                            visible={showModal}
                        >
                            <Text
                                as="div"
                                className={cn('DescriptionText')}
                                typography="body-m"
                            >
                                {importantInformation}
                            </Text>
                        </ModalPlatform>
                    </>
                )}
            </div>
        </div>
    );
};
