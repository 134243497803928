import { FC } from 'react';
import { useStore } from 'effector-react/scope';

import { NotificationBanner } from 'components/NotificationBanner';
import { useBanners } from 'hooks/useBanners';
import { $banners } from 'shared/models/banner';

import { cn } from './NotificationBannerRoot.cn';
import { NotificationBannerRootProps } from './NotificationBannerRoot.types';

import './NotificationBannerRoot.css';

export const NotificationBannerRoot: FC<NotificationBannerRootProps> = props => {
    const {
        className,
    } = props;

    const banners = useStore($banners);
    const { deleteBanner } = useBanners();

    const getHandleClose = (id: number) => {
        return () => {
            banners.find(({ id: bannerId }) => bannerId === id)?.onClose?.();
            deleteBanner(id);
        };
    };

    const empty = banners.length === 0;

    return (
        <div className={cn({ empty }, [className])}>
            {banners.map(banner => (
                <NotificationBanner
                    key={banner.id}
                    className={cn('Notification')}
                    description={banner.description}
                    title={banner.title}
                    type={banner.type}
                    onClose={getHandleClose(banner.id)}
                />
            ))}
        </div>
    );
};

NotificationBannerRoot.displayName = cn();
