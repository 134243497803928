import { FC, useCallback, useMemo, useRef } from 'react';
import { Spin } from '@yandex-lego/components/Spin/bundle';

import { Suggest, SuggestProps } from 'components/Suggest';
import { HandleTagsChange } from 'components/Suggest/Suggest.types';
import { Item } from 'shared/ui/Suggest';

import { withFormField } from '../../FormField2';
import { cn } from '../../FormField2.cn';
import { FormFieldProps } from '../../FormField2.types';
import { validateDependentFields } from '../../utils';

import { useSetDefaultValue } from './useSetDefaultValue';

import './FieldSuggest.css';

type ControlSuggestProps = SuggestProps & FormFieldProps;

const Control: FC<ControlSuggestProps> = props => {
    const {
        size = 'm',
        disabled,
        name,
        type,
        multiple,
        controller,
        deps,
        context,
        suggestView,
        ...suggestProps
    } = props;

    const {
        field: {
            onChange,
            onBlur,
            value,
            ref: bindRef,
        },
        fieldState: { error },
        formState: { touchedFields, isSubmitted },
    } = controller;

    const { trigger, setValue } = context;

    const { formattedValue, fetching } = useSetDefaultValue({ value, name, setValue, type });

    const tags: Item[] = useMemo(() => {
        if (!formattedValue) {
            return [];
        }

        if (Array.isArray(formattedValue)) {
            return formattedValue;
        }

        return [formattedValue];
    }, [formattedValue]);

    const handleTagsChange = useCallback<HandleTagsChange>(tags => {
        const [firstTag = null] = tags;

        if (multiple) {
            onChange(tags);
        } else {
            onChange(firstTag);
        }

        trigger(name);

        if (deps) {
            validateDependentFields(deps, touchedFields, trigger, isSubmitted);
        }
    }, [name, deps, isSubmitted, multiple, onChange, touchedFields, trigger]);

    const ref = useRef<HTMLInputElement>(null);

    bindRef(ref.current);

    let state: ControlSuggestProps['state'];

    if (error) {
        state = 'error';
    }

    const iconRight = fetching ? (
        <div data-testid="suggest-spinner">
            <Spin
                progress
                className={cn('SuggestSpinner')}
                size="xxs"
                view="default"
            />
        </div>
    ) : props.iconRight;

    return (
        <Suggest
            {...suggestProps}
            className={cn('Control', { name })}
            controlRef={ref}
            debounceTimeout={300}
            disabled={disabled}
            iconRight={iconRight}
            multiple={multiple}
            name={name}
            size={size}
            state={state}
            suggestView={suggestView || 'text'}
            tags={tags}
            type={type}
            view="outline"
            onBlur={onBlur}
            onTagsChange={handleTagsChange}
        />
    );
};

export const FieldSuggest = withFormField(Control);
