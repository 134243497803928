import { FC, useCallback, useContext } from 'react';
import { withBemMod } from '@bem-react/core';

import { getTypedI18nLang } from 'utils/i18n';
import { throwHTTPErrors } from 'utils/throwHTTPErrors';

import { SwaggerContext } from '../../../SwaggerContext';
import { cn } from '../Suggest.cn';
import { i18n } from '../Suggest.i18n';
import {
    RenderOptionContent,
    RenderSelectedValue,
    SuggestProps,
} from '../Suggest.types';

export interface WithTypeCountriesProps {
    type?: 'countries';
}

export const withTypeCountries = withBemMod<WithTypeCountriesProps, SuggestProps>(
    cn(),
    { type: 'countries' },
    (Suggest): FC<WithTypeCountriesProps & SuggestProps> => props => {
        const { placeholder = i18n('countries_placeholder') } = props;

        const { api } = useContext(SwaggerContext);

        const dataprovider = useCallback(async text => {
            if (!text) {
                return [];
            }

            const { result } = await api.country_suggest({ text }).then(throwHTTPErrors);

            const lang = getTypedI18nLang();

            return result.map(country => {
                const title = lang === 'ru' ? country.name : country.name_en;

                return {
                    id: country.country_id,
                    title,
                };
            });
        }, [api]);

        const renderOptionContent = useCallback<RenderOptionContent>(item => {
            return <>{item.title}</>;
        }, []);

        const renderSelectedValue = useCallback<RenderSelectedValue>(item => {
            return `${item.title}`;
        }, []);

        return (
            <Suggest
                {...props}
                dataprovider={dataprovider}
                placeholder={placeholder}
                renderOptionContent={renderOptionContent}
                renderSelectedValue={renderSelectedValue}
            />
        );
    },
);
