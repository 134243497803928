import { FC, useCallback, useState } from 'react';
import { Form } from 'react-final-form';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { Confirm } from 'components/Confirm';

import { cn } from './ButtonConfirm.cn';
import { ButtonConfirmProps } from './ButtonConfirm.types';

export const ButtonConfirm: FC<ButtonConfirmProps> = props => {
    const {
        title,
        message,
        onClick,
        onCancel,
        className,
        children,
        confirmText,
        confirmClassName,
        trigger,
        triggerClassname,
        cancelText,
        needConfirm = true,
        reason,
        ...buttonProps
    } = props;

    const [confirmationVisible, setConfirmationVisible] = useState(false);
    const stopPropagation = useCallback(e => {
        e.stopPropagation();
    }, []);

    const showConfirmation = useCallback(() => {
        setConfirmationVisible(true);
    }, []);

    const handleClick = useCallback(e => {
        onClick && onClick(e);
        setConfirmationVisible(false);
    }, [onClick]);

    const handleCancel = useCallback(e => {
        onCancel && onCancel(e);
        setConfirmationVisible(false);
    }, [onCancel, setConfirmationVisible]);

    const getConfirmForm = useCallback(({ handleSubmit }) => (
        <Confirm
            cancelText={cancelText}
            className={confirmClassName}
            confirmText={confirmText}
            message={message}
            reason={reason}
            title={title}
            visible={confirmationVisible}
            onCancel={handleCancel}
            onConfirm={handleSubmit}
        />
    ), [cancelText, confirmText, confirmationVisible, handleCancel, message, reason, title, confirmClassName]);

    const Trigger = trigger || ButtonPlatform;

    return (
        <span className={cn(null, [className])} onClick={stopPropagation}>
            <Trigger
                {...buttonProps}
                className={triggerClassname}
                onClick={needConfirm ? showConfirmation : handleClick}
            >
                {children}
            </Trigger>
            <Form
                render={getConfirmForm}
                onSubmit={handleClick}
            />
        </span>
    );
};

ButtonConfirm.displayName = cn();
