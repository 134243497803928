import { FC, useCallback } from 'react';

import { withModalView } from 'hocs/withModalView';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { Text } from 'shared/ui/Text';
import { isLoading } from 'utils/Loadable';

import { useTransactionPaid } from './TransactionPaidModal.hooks/useTransactionPaid';
import { cn } from './TransactionPaidModal.cn';
import { i18n } from './TransactionPaidModal.i18n';
import { TransactionPaidModalProps } from './TransactionPaidModal.types';

import './TransactionPaidModal.css';

const TransactionPaidModalPresenter: FC<TransactionPaidModalProps> = props => {
    const {
        onSubmit,
        onCancel,
        transaction,
        className,
    } = props;

    const { company_id, transaction_id } = transaction;

    const [paidTransaction, paidState] = useTransactionPaid({ onSuccess: onSubmit });

    const isDeleting = isLoading(paidState);

    const handleClick = useCallback(() => {
        paidTransaction(company_id, transaction_id);
    }, [paidTransaction, company_id, transaction_id]);

    return (
        <div className={cn(null, [className])}>
            <Text
                as="h3"
                className={cn('Title')}
                typography="title-s"
            >
                {i18n('title')}
            </Text>
            <Text
                as="p"
                className={cn('Info')}
                typography="body-s"
            >
                {i18n('info')}
            </Text>

            <div className={cn('Controls')}>
                <Button
                    className={cn('Cancel')}
                    size="m"
                    view="clear"
                    onClick={onCancel}
                >
                    {i18nAction('cancel')}
                </Button>
                <Button
                    className={cn('Submit')}
                    progress={isDeleting}
                    size="m"
                    view="brand"
                    onClick={handleClick}
                >
                    {i18n('action_paid')}
                </Button>
            </div>

        </div>
    );
};

TransactionPaidModalPresenter.displayName = cn();

export const TransactionPaidModal = withModalView(TransactionPaidModalPresenter);
