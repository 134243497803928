import { FetchError } from 'errors';
import { createUseQueryHook } from 'hooks/createUseQueryHook';
import { TGet_reviews_for_hotelQuery, UGCReviewContent } from 'services/SwaggerApi';
import { RemoteData } from 'utils/Loadable';

type UseGetReviewsForHotelOptions = {
   isEnabled?: boolean;
};

type UseGetReviewsForHotel = (queryParams: TGet_reviews_for_hotelQuery, options?: UseGetReviewsForHotelOptions) =>
        RemoteData<UGCReviewContent, FetchError>;

export const useGetReviewsForHotel: UseGetReviewsForHotel = (queryParams, options = {}) => {
    const { isEnabled } = options;

    return createUseQueryHook('get_reviews_for_hotel', {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        cacheTime: Infinity,
        enabled: isEnabled,
    })(queryParams);
};
