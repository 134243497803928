export const en = {
    available_places: 'Available seats',
    bottom: 'Bottom',
    bottom_side: 'Bottom side',
    carriage: 'Carriage',
    choose_carriage_class: 'Choose a car class',
    choose_tariff: 'Choose a fare',
    class: 'Class {code}',
    female: 'Female',
    man: 'Male',
    mixed: 'Mixed',
    not_branded: 'not branded',
    not_defined: 'Not defined',
    places_from: 'Seats from',
    places_next_step: 'Seat selection will be available at the next step.',
    price_from_to: 'Price from {from} to {to}',
    price_is: 'Price {price}',
    select: 'Select',
    top: 'Top',
    top_side: 'Top side',
    wo_desc: 'Without description',
    two_storey: 'Double-decker carriage',
};
