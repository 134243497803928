import { FC, useCallback, useState } from 'react';

import { Item, Suggest as SuggestBase } from 'shared/ui/Suggest';

import { cn } from './Suggest.cn';
import {
    HandleOpenedChange,
    HandlePickedChange,
    HandleValueChange,
    SuggestProps,
} from './Suggest.types';

import './Suggest.css';

export const Suggest: FC<SuggestProps> = props => {
    const { className, placeholder } = props;

    const [opened, setOpened] = useState(false);
    const handleOpenedChange = useCallback<HandleOpenedChange>(opened => {
        setOpened(opened);
    }, []);

    const [value, setValue] = useState('');
    const handleValueChange = useCallback<HandleValueChange>(value => {
        setValue(value);
    }, []);

    const [picked, setPicked] = useState<Item>();
    const handlePickedChange = useCallback<HandlePickedChange>(picked => {
        setPicked(picked);
    }, []);

    return (
        <div className={cn(null, [className])}>
            <SuggestBase
                {...props}
                opened={opened}
                picked={picked}
                placeholder={placeholder}
                popupProps={{
                    keepMounted: false,
                }}
                // Скелетоны загрузки сильно моргают, скрываем их
                progressNode={null}
                value={value}
                onOpenedChange={handleOpenedChange}
                onPickedChange={handlePickedChange}
                onValueChange={handleValueChange}
            />
        </div>
    );
};
