import { ToastOptions } from 'react-toastify';

import { FetchError } from 'errors';
import { i18nErrors } from 'i18n/i18nErrors';

import { addToast } from '../addToast';

import { i18n } from './Errors.i18n';

type ErrorToast =
    (fn: Function)
        => (
            error: FetchError,
            incomingValues?: {
                title: string;
                message?: string;
                options?: ToastOptions;
            }
        ) => void

export const basicNotifier: ErrorToast = notifierFunc => (error, incomingValues) => {
    const parse = error && error.message && JSON.parse(error.message);

    const defaultValues = incomingValues || {
        title: i18nErrors('error_default_title'),
        message: i18nErrors('error_default_message'),
    };

    if (parse && 'slug' in parse) {
        notifierFunc({ title: i18nErrors('error_default_title'), message: i18n(parse.slug) });
    } else {
        notifierFunc(defaultValues);
    }
};

export const errorToast = basicNotifier(addToast);
