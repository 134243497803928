import { ComponentProps, FC } from 'react';

import { RangeDatePicker } from 'components/DatePicker';

import { withFormField } from '../FormField2';
import { FormFieldProps } from '../FormField2.types';

type RangeDatePickerProps = ComponentProps<typeof RangeDatePicker> & {
    showSubText?: boolean
};

const Control: FC<RangeDatePickerProps & FormFieldProps> = props => {
    const {
        name,
        controller,
        context,
        ...datepickerProps
    } = props;

    const {
        field: {
            onChange,
            onBlur,
            value,
        },
        fieldState: { error },
    } = controller;

    return (
        <RangeDatePicker
            {...datepickerProps}
            state={error ? 'error' : undefined}
            value={value}
            onBlur={onBlur}
            onChange={onChange}
        />
    );
};

export const FieldRangeDatePicker = withFormField(Control);
