import * as D from 'io-ts/Decoder';

import { OneToBoolean, ToArray, ZeroToBoolean } from 'utils/io-ts/decoders';

import { ACCarriageTypeDecoder } from './ACCarriageTypeDecoder';
import { ACOrderByDecoder } from './ACOrderByDecoder';
import { ACTimeOptionDecoder } from './ACTimeOptionDecoder';
import { ACTrainCategoryDecoder } from './ACTrainCategoryDecoder';

export const ACSearchFiltersRailDecoder = D.partial({
    order_by: ACOrderByDecoder,
    is_descending: OneToBoolean,
    departure_there_timespan: ToArray(ACTimeOptionDecoder),
    arrival_there_timespan: ToArray(ACTimeOptionDecoder),
    departure_from_there: ToArray(D.string),
    arrival_to_there: ToArray(D.string),
    carriage_types: ToArray(ACCarriageTypeDecoder),
    carriage_owners: ToArray(D.string),
    train_names: ToArray(D.string),
    train_categories: ToArray(ACTrainCategoryDecoder),
    is_brand_train: OneToBoolean,
    has_electronic_registration: OneToBoolean,
    is_restricted_by_travel_policy: ZeroToBoolean,
});

export type ACSearchFiltersRail = D.TypeOf<typeof ACSearchFiltersRailDecoder>;
