export const ru = {
    goToMap: 'Открыть на карте',
    list: 'Список',
    map: 'Карта',
    press_search: 'Нажмите для просмотра результатов',
    results_count: {
        one: '{count} вариант',
        some: '{count} варианта',
        many: '{count} вариантов',
        none: '{count} вариантов',
    },
    searchDetails: 'Это может занять несколько минут',
    searchMessage: 'Ищем услуги',
    search_failed: 'Что-то пошло не так. Попробуйте ещё раз.',
    search_results: 'Результаты поиска',
    show_more: 'Показать ещё',
    back_to_trip: 'Вернуться к заявке',
};
