import { FC } from 'react';
import { Field } from 'react-final-form';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { FieldTextarea } from 'components/FormField/FormField.providers';
import { config } from 'config';
import { i18nAction } from 'i18n/i18nAction';
import { Modal } from 'shared/ui/Modal';
import { Text } from 'shared/ui/Text';
import { validateRequiredField } from 'utils/form/validateRequiredField';

import { cn } from './Confirm.cn';
import { i18n } from './Confirm.i18n';
import { ConfirmProps } from './Confirm.types';

import './Confirm.css';

export const Confirm: FC<ConfirmProps> = props => {
    const {
        error,
        title = i18n('confirm'),
        message,
        reason,
        onConfirm,
        onCancel,
        progress,
        visible,
        confirmText = error ? i18nAction('retry') : i18nAction('confirm'),
        cancelText = i18nAction('cancel'),
    } = props;

    return (
        // Добавил zIndex чтобы модалка стала выше ButtonMenu
        <Modal className={cn('Modal')} keepMounted={false} theme="normal" visible={visible} zIndex={9999} onClose={onCancel}>
            <form className={cn(null, [props.className])} onSubmit={onConfirm}>
                <Text as="div" className={cn('Title')} typography="title-s">
                    {title}
                </Text>
                {message && (
                    <Text as="div" className={cn('Message')} typography="body-m">
                        {message}
                    </Text>
                )}
                {reason && <Field
                    required
                    className={cn('Reason')}
                    component={FieldTextarea}
                    name="reason"
                    placeholder={reason}
                    validate={validateRequiredField}
                />}
                <div className={cn('Controls')}>
                    {onCancel &&
                        <ButtonPlatform
                            className={cn('Button', [cn('Cancel')])}
                            view="default"
                            onClick={onCancel}
                        >
                            {cancelText}
                        </ButtonPlatform>
                    }
                    <ButtonPlatform
                        className={cn('Button', [cn('Submit')])}
                        progress={progress}
                        type="submit"
                        view="brand"
                    >
                        {confirmText}
                    </ButtonPlatform>
                </div>
                {error && (
                    <div className={cn('Error')}>
                        <Text color="control-error">{i18n('error')}: {error.message}</Text>
                        {(['development', 'testing'].includes(config.env) && error.details) &&
                            <pre className={cn('ErrorDetail')}>{JSON.stringify(error.details)}</pre>
                        }
                    </div>
                )}
            </form>
        </Modal>
    );
};
