import { config } from 'config';
import { FetchError } from 'errors';
import { Trip } from 'services/SwaggerApi';
import { RemoteData } from 'utils/Loadable';

import { createUseQueryHook } from './createUseQueryHook';

type UseTripOptions = {
    isEnabled?: boolean;
 };

type UseTrip = (tripId: number, options?: UseTripOptions) => RemoteData<Trip, FetchError>;

export const useTrip: UseTrip = (tripId, options = {}) => {
    const { isEnabled } = options;

    return createUseQueryHook(
        'trip_detail',
        { enabled: isEnabled },
    )(tripId);
};

export const useTripOnce = createUseQueryHook('trip_detail', {
    staleTime: config.env === 'production' ? Infinity : 0,
    cacheTime: config.env === 'production' ? undefined : 0,
});
