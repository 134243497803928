import * as D from 'io-ts/Decoder';

import { config } from 'config';
import { ActivePage } from 'shared/constants/ActivePage';
import { checkAccessFactory } from 'utils/checkAccessFactory';
import { ToNumber } from 'utils/io-ts/decoders';

import { route } from './route';

const { isB2B } = config;

export const RouteStaffDecoder = D.type({
    params: D.type({
        companyId: ToNumber,
    }),
    search: D.partial({
        retpath: D.string,
    }),
});

export type RouteStaff = D.TypeOf<typeof RouteStaffDecoder>;

const checkAccess = checkAccessFactory(
    meta => Boolean(isB2B && meta.is_coordinator),
    isB2B ? 'forbidden' : 'not_found',
);

export const RouteStaff = route(
    '/company/:companyId(\\d+)/staff',
    RouteStaffDecoder,
    {
        isAdminPanel: true,
        activePage: ActivePage.STAFF,
        checkAccess,
    },
);
