import { FC, useCallback } from 'react';
import { ErrorState } from '@yandex-int/hr-components/ErrorState';

import { IconClose } from 'components/Icon';
import { Table } from 'components/Table';
import { TableSkeleton } from 'components/TableSkeleton';
import { i18nAction } from 'i18n/i18nAction';
import { Button } from 'shared/ui/Button';
import { Textinput } from 'shared/ui/Textinput';

import { useAvailablePersons } from './FormEmployeeGroupAdd.hooks/useAvailablePersons';
import { useColumns } from './FormEmployeeGroupAdd.hooks/useColumns';
import { useEmployeeDirectory } from './FormEmployeeGroupAdd.hooks/useEmployeeDirectory';
import { usePersonSuggest } from './FormEmployeeGroupAdd.hooks/usePersonSuggest';
import { cn } from './FormEmployeeGroupAdd.cn';
import { i18n } from './FormEmployeeGroupAdd.i18n';
import { FormEmployeeGroupAddProps } from './FormEmployeeGroupAdd.types';

import './FormEmployeeGroupAdd.css';

export const FormEmployeeGroupAdd: FC<FormEmployeeGroupAddProps> = props => {
    const { className, setCheckedPersons, companyId, membersIds, onCancel, onSubmit } = props;

    const { searchValue, setSearchValue, suggestPersons, isSuggestPersonsLoading } = usePersonSuggest({
        companyId,
    });

    const {
        availablePersons,
        availablePersonsCount,
        isAvailablePersonsLoading,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useAvailablePersons({ companyId });

    const { handleChange, selectedPersonsIds } = useEmployeeDirectory({
        persons: [...availablePersons, ...suggestPersons],
        membersIds,
        setCheckedPersons,
    });

    const hasAvailablePersons = availablePersons && availablePersons.length > 0;
    const hasSuggestPersons = suggestPersons && suggestPersons.length > 0;

    const suggestPersonsCount = suggestPersons ? suggestPersons.length : 0;

    const isAvailableTableVisible = !searchValue && hasAvailablePersons;
    const isSuggestTableVisible = searchValue && hasSuggestPersons;
    const isSkeletonVisible = isAvailablePersonsLoading || isSuggestPersonsLoading;
    const isErrorVisible =
        !isAvailableTableVisible && !isSuggestTableVisible && !isSuggestPersonsLoading && !isAvailablePersonsLoading;

    const searchColumns = useColumns(handleChange, selectedPersonsIds, availablePersonsCount);
    const suggestColumns = useColumns(handleChange, selectedPersonsIds, suggestPersonsCount);

    const isSubmitButtonDisabled =
        JSON.stringify([...selectedPersonsIds].sort()) === JSON.stringify([...membersIds].sort());

    const handleInputChange = useCallback(
        event => {
            setSearchValue(event.target.value);
        },
        [setSearchValue],
    );

    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

        if (clientHeight + scrollTop + 10 >= scrollHeight && hasNextPage && !isFetchingNextPage && !searchValue) {
            void fetchNextPage();
        }
    };

    return (
        <div className={cn(null, [className])}>
            <Textinput
                hasClear
                clearIcon={<IconClose />}
                debounceTimeout={300}
                placeholder={i18n('suggest_placeholder')}
                size="m"
                value={searchValue}
                view="default"
                onChange={handleInputChange}
            />

            <div className={cn('Content')} onScroll={handleScroll}>
                {isSkeletonVisible && <TableSkeleton columns={3} rows={6} />}

                {isAvailableTableVisible && (
                    <Table
                        className={cn('Table')}
                        columns={searchColumns}
                        data={availablePersons}
                        pageCount={1}
                        pageIndex={1}
                        pageSize={Infinity}
                    />
                )}

                {isSuggestTableVisible && (
                    <Table
                        className={cn('Table')}
                        columns={suggestColumns}
                        data={suggestPersons}
                        pageCount={1}
                        pageIndex={1}
                        pageSize={Infinity}
                    />
                )}

                {isErrorVisible && (
                    <ErrorState
                        description={i18n('not_found__description')}
                        size="s"
                        title={i18n('not_found__title')}
                    />
                )}

                {isFetchingNextPage && <TableSkeleton columns={3} rows={4} />}
            </div>

            <div className={cn('Controls')}>
                <Button className={cn('Cancel')} size="m" view="ghost" onClick={onCancel}>
                    {i18nAction('cancel')}
                </Button>
                <Button
                    className={cn('Submit')}
                    disabled={isSubmitButtonDisabled}
                    size="m"
                    view="primary"
                    onClick={onSubmit}
                >
                    {i18nAction('save')}
                </Button>
            </div>
        </div>
    );
};

FormEmployeeGroupAdd.displayName = cn();
