import { ComponentProps, FC } from 'react';
import { Tumbler } from '@yandex-lego/components/Tumbler/desktop/bundle';

import { withFormField } from 'components/FormField2/FormField2';
import { FormFieldProps } from 'components/FormField2/FormField2.types';

import { cn } from '../../FormField2.cn';

export type WithTypeTumblerProps = Partial<ComponentProps<typeof Tumbler>>;

const FieldTumblerBase: FC <WithTypeTumblerProps & FormFieldProps> = props => {
    const { view = 'default',
        size = 'm',
        controller, name,
    } = props;
    const {
        field: { onChange, value },
    } = controller;

    return <Tumbler
        {...props}
        checked={value}
        className={cn('Control', { name }, [cn()])}
        id={name}
        name={name}
        size={size}
        view={view}
        onChange={onChange}
    />;
};

export const FieldTumbler = withFormField(FieldTumblerBase);
