import * as yup from 'yup';

import { i18n } from './CollateRegistryModal.i18n';

const FILE_SIZE_IN_MB = 20;
const FILE_SIZE_IN_BYTES = FILE_SIZE_IN_MB * 1024 * 1024;

export const EXTENSIONS = ['.xls', '.xlsx', '.xlsm'];

export const schema = yup.object({
    registry_file: yup.mixed()
        .required(() => i18n('file_required'))
        .test('fileType',
            () => i18n('wrong_file_extension'),
            (file: File | undefined) => {
                if (!file) return false;

                const fileName = file.name.trim().toLowerCase();

                return EXTENSIONS.some(ext => fileName.endsWith(ext));
            })
        .test('fileSize',
            () => i18n('file_is_too_large', { sizeInMB: FILE_SIZE_IN_MB }),
            (file: File | undefined) => {
                if (!file) return false;

                return file.size < FILE_SIZE_IN_BYTES;
            }),
});
