import { FC } from 'react';

import { useHandleChange } from 'components/FormField2/utils/useHandleChange';
import { NumberCounterInput, NumberCounterInputProps } from 'shared/ui/NumberCounterInput';

import { withFormField } from '../../FormField2';
import { cn as cnFormField } from '../../FormField2.cn';
import { FormFieldProps } from '../../FormField2.types';

import { cn } from './FieldNumberCounter.cn';

type FieldNumberCounterProps = {
    title?: NumberCounterInputProps['label'];
    caption?: NumberCounterInputProps['caption'];
    min?: NumberCounterInputProps['min'];
    max?: NumberCounterInputProps['max'];
    dataTestId?: NumberCounterInputProps['dataTestId'];
} & FormFieldProps;

const Control: FC<FieldNumberCounterProps> = props => {
    const {
        name,
        deps,
        controller,
        context,
        title,
        ...numberCounterProps
    } = props;

    const {
        field: { value },
    } = controller;

    const handleChange = useHandleChange({ controller, context, deps });

    return (
        <NumberCounterInput
            {...numberCounterProps}
            className={cnFormField('Control', { name }, [cn()])}
            label={title}
            value={value}
            onChange={handleChange}
        />
    );
};

export const FieldNumberCounter = withFormField(Control);
