import { FC } from 'react';
import { Select } from '@yandex-int/hr-components/Select/desktop/bundle';

import { getOptions } from './FieldSort.utils/getOptions';
import { cn } from './FieldSort.cn';
import { FieldSortProps } from './FieldSort.types';

import './FieldSort.css';

export const FieldSort: FC<FieldSortProps> = props => {
    const { value, onChange, type } = props.input;

    const options = getOptions();
    const currentOptions = options.filter(option => option.types.includes(type));

    return (
        <Select
            iconProps={{ glyph: 'arrowShortDown' }}
            options={currentOptions}
            size="m"
            value={value}
            view="outline"
            width="max"
            onChange={onChange}
        />
    );
};

FieldSort.displayName = cn();
