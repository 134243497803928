import { FC, useMemo } from 'react';

import { ServiceOrder } from 'components/ServiceOrder';
import { TripRouteSegment } from 'components/TripRouteSegment';
import { withRemote } from 'hocs/withRemote';
import { Text } from 'shared/ui/Text';

import { generateSegments } from './TripRoute.utils/generateSegments';
import { getHideReturnSegment } from './TripRoute.utils/getHideReturnSegment';
import { cn } from './TripRoute.cn';
import {
    TripRouteProps,
    TripRouteViewCancelledProps,
    TripRouteViewProps,
} from './TripRoute.types';
import { i18n } from './TripRoute.yt.i18n';
import { useOrdersDetails } from './useOrdersDetails';

import './TripRoute.css';

export const TripRouteViewCancelled: FC<TripRouteViewCancelledProps> = props => {
    const {
        personTrip,
    } = props;

    const cancelledServices = personTrip.services.filter(service => service.status === 'cancelled');

    return (
        <div className={cn(null, [props.className])}>
            {cancelledServices.map(service =>
                <ServiceOrder key={service.service_id} service={service} />)
            }
        </div>
    );
};

const TripRouteView: FC<TripRouteViewProps> = props => {
    const {
        services,
        showSearchButtons,
        personTrip,
        personId,
        trip_id,
    } = props;

    const activeServices = services.filter(service => service.service_state !== 'cancelled');

    const { segments, restServices } = useMemo(() => generateSegments({
        personTrip,
        services: activeServices,
    }), [personTrip, activeServices]);

    const hideReturnSegment = getHideReturnSegment(personTrip?.route, services, segments);
    const slicedSegments = hideReturnSegment ? segments.slice(0, -1) : segments;

    const canAddServices = personTrip ? personTrip.is_approved : false;

    return (
        <div className={cn(null, [props.className])}>
            {slicedSegments.map((segment, index) => (
                <TripRouteSegment
                    key={index}
                    canAddServices={canAddServices}
                    person_id={personId}
                    segment={segment}
                    showCitiesAsTitle={!hideReturnSegment}
                    showSearchButtons={showSearchButtons}
                    trip_id={trip_id}
                />),
            )}
            {restServices.length > 0 && (
                <div className={cn('RestServices')}>
                    <Text typography="title-m">
                        {i18n('other_services')}
                    </Text>
                    {restServices.map(service => (
                        <ServiceOrder key={service.service_id} service={service} />
                    ))}
                </div>
            )}
        </div>
    );
};

const TripRouteViewRemote = withRemote(TripRouteView);

export const TripRoute: FC<TripRouteProps> = props => {
    const {
        personTrip,
        activeTab,
        personId,
        meta,
        trip: { trip_id },
    } = props;

    const services = useOrdersDetails(trip_id, personTrip);

    const showServices = personTrip.services.length || personTrip.actions?.search_service;
    const showSearchButtons = Boolean(personTrip.actions?.search_service);

    if (!showServices) return null;

    if (activeTab === 'cancelled') {
        return (
            <TripRouteViewCancelled
                personTrip={personTrip}
            />
        );
    }

    return (
        <TripRouteViewRemote
            spinner
            activeTab={activeTab}
            meta={meta}
            personId={personId}
            personTrip={personTrip}
            services={services}
            showSearchButtons={showSearchButtons}
            trip_id={trip_id}
        />
    );
};

TripRoute.displayName = cn();
