import { FC, useCallback } from 'react';
import { useToggle } from 'react-use';

import { RegistrationFeedbackForm } from 'components/RegistrationFeedbackForm';
import { withModalView } from 'hocs/withModalView';
import { Button } from 'shared/ui/Button';

import { cn } from './DomainlessCompanyButton.cn';
import { i18n } from './DomainlessCompanyButton.i18n';
import { DomainlessCompanyButtonProps } from './DomainlessCompanyButton.types';

const RegistrationFeedbackFormModal = withModalView(RegistrationFeedbackForm);

export const DomainlessCompanyButton: FC<DomainlessCompanyButtonProps> = props => {
    const {
        className,
    } = props;

    const [isModalVisible, setModalVisible] = useToggle(false);

    const handleClose = useCallback(() => {
        setModalVisible();
    }, [setModalVisible]);

    const handleOpen = useCallback(() => {
        setModalVisible();
    }, [setModalVisible]);

    return (
        <>
            <Button
                className={cn(null, [className])}
                size="m"
                view="clear"
                width="max"
                onClick={handleOpen}
            >
                {i18n('no_domain')}
            </Button>
            <RegistrationFeedbackFormModal
                className={cn('Modal')}
                defaultValues={{
                    message: i18n('no_domain_message'),
                }}
                modalViewOnClose={handleClose}
                modalViewVisible={isModalVisible}
                onClose={handleClose}
            />
        </>
    );
};

DomainlessCompanyButton.displayName = cn();
