import { FC } from 'react';
import { Checkbox } from '@yandex-lego/components/Checkbox/desktop/bundle';

import { Cell } from 'components/Cell';
import { withTdAttrs } from 'hocs/withTdAttrs';

import { cn } from './CellCheckbox.cn';
import { CellCheckboxProps } from './CellCheckbox.types';

const CellCheckboxBase: FC<CellCheckboxProps> = props => {
    const {
        className,
        getAttrs,
        value,
    } = props;
    const { code, checked, onChange } = value;

    return (
        <Cell
            className={cn(null, [className])}
            getAttrs={getAttrs}
            size="m"
        >
            <Checkbox
                key={code}
                checked={checked}
                data-code={code}
                data-testid={code}
                lines="one"
                size="m"
                view="default"
                onChange={onChange}
            />
        </Cell>
    );
};

export const CellCheckbox = withTdAttrs(CellCheckboxBase);
CellCheckbox.displayName = cn();
