import { FC } from 'react';
import { Image } from '@yandex-lego/components/Image/desktop';

import { Text } from 'shared/ui/Text';

import noPhoto from './HotelImage.assets/no-photo.svg';
import { cn } from './HotelImage.cn';
import { i18n } from './HotelImage.i18n';
import { HotelImageProps } from './HotelImage.types';

import './HotelImage.css';

export const HotelImage: FC<HotelImageProps> = props => {
    const { image, name, recommended } = props;

    return (
        <div className={cn(null, [props.className])}>
            <Image
                alt={name}
                ariaLabel={name}
                className={cn('Image')}
                fallbackSrc={noPhoto}
                src={`${image}`}
            />
            {recommended && (
                <Text
                    as="div"
                    className={cn('Recommended')}
                    typography="body-s"
                >
                    {i18n('recommended')}
                </Text>
            )}
        </div>
    );
};

HotelImage.displayName = cn();
