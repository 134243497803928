import { FC } from 'react';
import { Field } from 'react-final-form';

import { FieldTextarea } from 'components/FormField/FormField.providers';
import { Text } from 'shared/ui/Text';

import { cn } from '../FormTripCreate.cn';
import { i18n } from '../FormTripCreate.i18n';
import { RouteFields } from '../FormTripCreate-RouteFields/RouteFields';

export const TripFields: FC = () => {
    return (
        <>
            <Text className={cn('Subheader')} typography="title-s">{i18n('trip_details')}</Text>

            <RouteFields />

            <Field
                className={cn('Field')}
                component={FieldTextarea}
                name="comment"
                placeholder={i18n('why_you_go')}
                title={i18n('description')}
            />
        </>
    );
};
