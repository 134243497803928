import { TravellerDocument } from 'services/SwaggerApi';

import { formatDocument } from './formatDocument';

export const getDocumentOptions = (documents: TravellerDocument[]) => {
    return documents.map(document => ({
        value: document.document_id,
        content: formatDocument(document),
    }));
};
