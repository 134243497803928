import { FC, useCallback, useState } from 'react';

import { HelpTooltip } from 'components/HelpTooltip';
import { IconArrowShortDown, IconArrowShortUp } from 'components/Icon';
import { Text } from 'shared/ui/Text';

import { cn } from './FilterGroup.cn';
import { FilterGroupProps } from './FilterGroup.types';

import './FilterGroup.css';

const iconArrowUp = <IconArrowShortUp size="s" />;
const iconArrowDown = <IconArrowShortDown size="s" />;

export const FilterGroup: FC<FilterGroupProps> = props => {
    const { label, collapsed = false, hint } = props;
    const [expanded, setExpanded] = useState<boolean>(!collapsed);

    const toggleGroup = useCallback(() => {
        setExpanded(expanded => !expanded);
    }, [setExpanded]);

    return (
        <div className={cn(null, [props.className])}>
            <div className={cn('Header')} onClick={toggleGroup}>
                <Text typography="body-m" weight="medium">
                    {label}
                    {hint && (
                        <HelpTooltip
                            hasTail
                            className={cn('Hint')}
                            content={hint}
                            direction="top"
                            iconSize="m"
                        />
                    )}
                </Text>
                <Text>{expanded ? iconArrowDown : iconArrowUp}</Text>
            </div>
            {expanded && <div className={cn('Content')}>{props.children}</div>}
        </div>
    );
};

FilterGroup.displayName = cn();
