import { FC } from 'react';
import dayjs from 'dayjs';

import { IconHelp, IconProps } from 'components/Icon';
import { SegmentEvent } from 'types/TripSegment';

type SegmentEventOptions = Omit<SegmentEvent, 'isActive' | 'isPastEvent' | 'icon'> & { icon?: FC<IconProps> };

export const segmentEvent = (options: SegmentEventOptions): SegmentEvent => {
    const { date, text, hint, city, icon = IconHelp } = options;
    const isPastEvent = dayjs() >= date;

    return {
        text,
        hint,
        date,
        icon,
        isActive: false,
        isPastEvent,
        city,
    };
};
