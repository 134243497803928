import { RegisterModalLayout } from 'components/RegisterModalLayout';
import { Text } from 'shared/ui/Text';
import { Page } from 'types/Page';

import { cn } from './PageRegisterRejected.cn';
import { i18n } from './PageRegisterRejected.i18n';

export const PageRegisterRejected: Page = () => {
    return (
        <RegisterModalLayout
            className={cn()}
            title={i18n('title')}
        >
            <Text
                className={cn('Description')}
                color="secondary"
                typography="body-s"
            >
                {i18n('description')}
            </Text>
        </RegisterModalLayout>
    );
};

PageRegisterRejected.displayName = cn();
