export const en = {
    cities_must_be_filled: 'Add cities',
    city_not_found: "Couldn't find city: {query}",
    data_undefined: 'No data',
    error_default_title: 'An error occurred',
    error_default_message: 'Please try again later',
    error_in_link_generation: 'Invalid link',
    error_in_page_params: 'Invalid page parameters',
    error_in_search_create: 'Failed to start the search',
    error_in_save_data: 'Data could not be saved. Try again later or contact support for help',
    error_occurred: 'An error has occurred, please try again later or contact the support chat',
    incorrect_data_format: 'Incorrect data format',
    no_render_function: 'No render function provided',
    option_code_undefined: 'Option code is undefined',
    zero_flight_segments: 'There must be at least one flight segment',
    not_recognizable_error: 'Unrecognizable error',
    type_not_exist: 'The specified type does not exist',
    required_field: 'Required field',
    digits_only: 'Digits only allowed',
    invalid_date: 'Invalid date',
};
