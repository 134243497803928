import { FC } from 'react';
import {
    IModalLayoutProps,
    ModalLayout as ModalLayoutBase,
} from '@yandex-int/hr-components/ModalLayout/ModalLayout';

import { cn } from './ModalLayout.cn';

import './ModalLayout.css';

export const ModalLayout: FC<IModalLayoutProps> = props => {
    const { className } = props;

    return (
        <ModalLayoutBase
            {...props}
            className={cn(null, [className])}
        />
    );
};
