export enum RemoteTag {
    INITIAL = 'RemoteInitial',
    LOADING = 'RemoteLoading',
    REFETCHING = 'RemoteRefetching',
    FAILURE = 'RemoteFailure',
    SUCCESS = 'RemoteSuccess',
}

export interface RemoteInitial {
    _tag: RemoteTag.INITIAL;
}

export interface RemoteLoading {
    _tag: RemoteTag.LOADING;
}

export interface RemoteRefetching<A> {
    _tag: RemoteTag.REFETCHING;
    isFetched: boolean;
    result: A;
}

export interface RemoteSuccess<A> {
    _tag: RemoteTag.SUCCESS;
    result: A;
}

export interface RemoteFailure<E = unknown> {
    _tag: RemoteTag.FAILURE;
    error: E;
}

export type Loadable<A, E = unknown> =
    | RemoteSuccess<A>
    | RemoteLoading
    | RemoteRefetching<A>
    | RemoteFailure<E>
    ;

export type RemoteData<A, E = unknown> =
    | RemoteInitial
    | Loadable<A, E>
    ;

export function initial(): RemoteInitial {
    return {
        _tag: RemoteTag.INITIAL,
    };
}

export function loading(): RemoteLoading {
    return {
        _tag: RemoteTag.LOADING,
    };
}

export function refetching<T>(result: T, isFetched = false): RemoteRefetching<T> {
    return {
        _tag: RemoteTag.REFETCHING,
        isFetched,
        result,
    };
}

export function success<T>(result: T): RemoteSuccess<T> {
    return {
        _tag: RemoteTag.SUCCESS,
        result,
    };
}

export const failure = <E>(error: E): RemoteFailure<E> => ({
    _tag: RemoteTag.FAILURE,
    error,
});

export function isInitial<T, F>(result: RemoteData<T, F>): result is RemoteInitial {
    return result._tag === RemoteTag.INITIAL;
}

export function isLoading<T, F>(result: RemoteData<T, F>): result is RemoteLoading {
    return result._tag === RemoteTag.LOADING;
}

type RefetchingOptions = {
    withNewKeys: boolean;
};

/**
 * Checks if LoadableData has a background refetch with new query keys.
 * Works only with queryOption `keepPreviousData` set to true.
 * @param result LoadableData
 * @param options `withNewKeys`: Checks if the query is refetching with new keys.
 * So it doesn not store any cached data for these keys yet.
 * @returns boolean;
 * @deprecated в большинстве случаев, пытаясь использовать этот метод вы совершаете ошибку,
 * используйте утилиты типа mapLoadable или найдите другой паттерн работы с RemoteData
 */
export function isRefetching<T, F>(
    result: RemoteData<T, F>,
    options?: RefetchingOptions,
): result is RemoteRefetching<T> {
    if (options?.withNewKeys) {
        return result._tag === RemoteTag.REFETCHING && !result.isFetched;
    }

    return result._tag === RemoteTag.REFETCHING;
}

/**@deprecated в большинстве случаев, пытаясь использовать этот метод вы совершаете ошибку,
 * используйте утилиты типа mapLoadable или найдите другой паттерн работы с RemoteData */
export function isPending(remoteData: RemoteData<unknown, unknown>): remoteData is RemoteLoading | RemoteInitial {
    return isLoading(remoteData) || isInitial(remoteData);
}

/**@deprecated в большинстве случаев, пытаясь использовать этот метод вы совершаете ошибку,
 * используйте утилиты типа mapLoadable или найдите другой паттерн работы с RemoteData */
export function isSuccess<A, E>(result: RemoteData<A, E>): result is RemoteSuccess<A> {
    return result._tag === RemoteTag.SUCCESS;
}

/**@deprecated в большинстве случаев, пытаясь использовать этот метод вы совершаете ошибку,
 * используйте утилиты типа mapLoadable или найдите другой паттерн работы с RemoteData */
export function isFailure<E>(result: RemoteData<unknown, E>): result is RemoteFailure<E> {
    return result._tag === RemoteTag.FAILURE;
}

export function every<T>(
    fn: (l: RemoteData<T>) => boolean,
    ls: readonly [...RemoteData<T>[]],
): ls is RemoteSuccess<T>[] {
    return ls.every(fn);
}

export type TypedArgsRD<A = unknown, E = unknown> =
    | []
    | [RemoteData<A, E>]
    | [RemoteData<A, E>, RemoteData<A, E>]
    | [RemoteData<A, E>, RemoteData<A, E>, RemoteData<A, E>]
    ;

export type ExtractRDArray<T> =
    T extends readonly [RemoteData<infer R1>] ? RemoteData<readonly [R1]> :
    T extends readonly [RemoteData<infer R1>, RemoteData<infer R2>] ? RemoteData<readonly [R1, R2]> :
    T extends readonly [RemoteData<infer R1>, RemoteData<infer R2>, RemoteData<infer R3>]
        ? RemoteData<readonly [R1, R2, R3]> :
        never;

export function loadablesToArray<T>(loadables: readonly [...RemoteData<T>[]]): RemoteData<T[]> {
    if (every(isSuccess, loadables)) {
        return success(loadables.map(({ result }) => result));
    }

    const failure = loadables.find(isFailure);

    if (failure) return failure;

    return loading();
}

export function combineLoadables<L1>(
    l1: RemoteData<L1>,
): RemoteData<[L1]>;

export function combineLoadables<L1, L2>(
    l1: RemoteData<L1>,
    l2: RemoteData<L2>,
): RemoteData<[L1, L2]>;

export function combineLoadables<L1, L2, L3>(
    l1: RemoteData<L1>,
    l2: RemoteData<L2>,
    l3: RemoteData<L3>,
): RemoteData<[L1, L2, L3]>;

export function combineLoadables<T extends TypedArgsRD>(
    ...loadables: T
) {
    return loadablesToArray(loadables) as ExtractRDArray<T>;
}

/**
 * @deprecated в новых местах не использовать, надо выпилить
 * используйте mapLoadable или foldSuccess
 */
export function getValueOrNull<T, F>(loadable: RemoteData<T, F>) {
    if (isSuccess(loadable)) {
        return loadable.result;
    }

    return null;
}

export function mapLoadable<V1, V2, TError>(
    loadable: RemoteData<V1, TError>,
    mapFn: (value: V1) => V2,
): RemoteData<V2, TError> {
    if (isSuccess(loadable)) {
        return success(mapFn(loadable.result));
    }

    return loadable as RemoteData<V2, TError>;
}

export const hasData = <A, E>(result: RemoteData<A, E>): result is RemoteSuccess<A> | RemoteRefetching<A> =>
    isSuccess(result) || isRefetching(result);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isRemote<T, U>(obj: any): obj is RemoteData<T, U> {
    if (obj == null) return false;

    return (
        obj._tag === 'RemoteInitial' ||
        obj._tag === 'RemoteLoading' ||
        obj._tag === 'RemoteFailure' ||
        obj._tag === 'RemoteRefetching' ||
        obj._tag === 'RemoteSuccess');
}

export function foldSuccess<T, A>(
    loadable: RemoteData<A>,
    onSuccess: (value: A) => T,
    onOther: () => T extends never ? never : T,
) {
    if (isSuccess(loadable)) {
        return onSuccess(loadable.result);
    }

    return onOther();
}
