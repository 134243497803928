import { FC } from 'react';
import { Tag } from '@yandex-int/hr-components/Tag2/bundle';
import { Divider } from '@yandex-lego/components/Divider';

import { Dropdown } from 'components/Dropdown';
import { i18nTransfers } from 'i18n/i18nTransfers';
import { ListItem } from 'shared/ui/ListItem';
import { Text } from 'shared/ui/Text';
import { countTransferDuration } from 'utils/countTransfersDuration';
import { getTransfersDurationList } from 'utils/getTransfersDurationList';

import { cn } from './FlightRouteTransferTag.cn';
import { i18n } from './FlightRouteTransferTag.i18n';
import { FlightRouteTransferTagProps } from './FlightRouteTransferTag.types';

import './FlightRouteTransferTag.css';

export const FlightRouteTransferTag: FC<FlightRouteTransferTagProps> = props => {
    const { className, segments, segmentsCount, onTagClick } = props;

    const transfersDuration = countTransferDuration(segments);
    const transfersFormatted = i18nTransfers('transfers', { count: segmentsCount - 1 });

    const tagText = [transfersFormatted, transfersDuration].filter(Boolean).join(' • ');

    const transfersList = getTransfersDurationList(segments);

    return (
        <Dropdown
            stopPropagation
            className={cn(null, [className])}
            desktopTrigger="hover"
            disabled={!transfersList.length}
            drawerProps={{
                nested: true,
                scope: 'inplace',
            }}
            popupProps={{
                hasTail: true,
                direction: ['bottom'],
                className: cn('Popup'),
            }}
            triggerElement={<Tag maxWidth="full" size="s" text={tagText} onClick={onTagClick} />}
        >
            <div className={cn('PopupHeader')}>
                <Text as="div" typography="body-m" weight="medium">
                    {transfersFormatted}
                </Text>
                <Text as="span" color="secondary" typography="caption-m">
                    {i18n('total_time')} {transfersDuration}
                </Text>
            </div>
            <Divider />
            <div className={cn('PopupList')}>
                {transfersList.map((transfer, index) => (
                    <ListItem
                        key={index}
                        embed
                        leadingTextParams={{
                            label: transfer.textContent,
                            caption: transfer.transferDuration,
                            size: 's',
                        }}
                    />
                ))}
            </div>
        </Dropdown>
    );
};

FlightRouteTransferTag.displayName = cn();
