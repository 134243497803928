export const initialFormValues = {
    avia_max_price: '5000',
    avia_cheapest_more_percent: '10',
    avia_book_before_days: '7',
    avia_max_transfer_duration_hours: '4',
    avia_service_classes: [],
    avia_max_price_directions: [],
    is_avia_max_price: false,
    is_avia_cheapest_more_percent: false,
    is_avia_book_before_days: false,
    is_avia_max_transfer_duration_hours: false,
    avia_refundable_only: false,
    avia_exchangeable_only: false,
    avia_with_baggage_only: false,
    avia_business_days_only: false,

    rail_max_price: '5000',
    rail_cheapest_more_percent: '10',
    rail_book_before_days: '7',
    rail_categories: [],
    rail_max_price_directions: [],
    is_rail_max_price: false,
    is_rail_cheapest_more_percent: false,
    is_rail_book_before_days: false,
    rail_business_days_only: false,

    hotel_max_price_per_night: '5000',
    hotel_cheapest_more_percent: '10',
    hotel_book_before_days: '7',
    hotel_stars: [],
    hotel_max_price_directions: [],
    is_hotel_max_price_per_night: false,
    is_hotel_cheapest_more_percent: false,
    is_hotel_book_before_days: false,
    hotel_business_days_only: false,
};
