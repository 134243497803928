import { FC, useCallback } from 'react';
import FlipMove from 'react-flip-move';
import sanitizeHtml from 'sanitize-html';

import { ButtonPlatform } from 'components/ButtonPlatform';
import { HotelsMapSearchResult } from 'components/HotelsMapSearchResult';
import { withRemote } from 'hocs/withRemote';
import { useInfoMessage } from 'hooks/useInfoMessage';
import { useQueryParams } from 'hooks/useQueryParams';
import { SearchFilters, SearchResultItems, useSearchResults } from 'hooks/useSearchResults';
import { Flight, Hotel, Person, ServiceType, Train } from 'services/SwaggerApi';
import { InlineNotification } from 'shared/ui/InlineNotification';
import { getTypedI18nLang } from 'utils/i18n';
import { hasData } from 'utils/Loadable';

import { cn } from '../PageSearch.cn';
import { i18n } from '../PageSearch.i18n';
import { SearchLayoutType } from '../PageSearch.types';

import { AviaService, HotelService, ServiceCardTyped, TrainService } from './ServiceCardTyped';

import './PageSearch-Results.css';

type GetTypedService = (services: unknown, service_type: ServiceType) => AviaService | HotelService | TrainService;

const getTypedService: GetTypedService = (service, service_type) => {
    switch (service_type) {
        case 'avia':
            return {
                service: service as Flight,
                service_type: 'avia',
            };

        case 'rail':
            return {
                service: service as Train,
                service_type: 'rail',
            };

        case 'hotel':
            return {
                service: service as Hotel,
                service_type: 'hotel',
            };
    }
};

type GetTypedServices = (
    services: unknown[],
    service_type: ServiceType
) => (AviaService | HotelService | TrainService)[];

const getTypedServices: GetTypedServices = (services, service_type) => {
    return services.map(service => getTypedService(service, service_type));
};

const Renderer = withRemote(({ adultsCount, result, type, searchId, personId, tripId, canAddServices }: {
    adultsCount: number;
    result: SearchResultItems;
    searchId: string;
    type: SearchLayoutType;
    personId: Person['person_id'];
    tripId?: number;
    canAddServices: boolean;
}) => {
    const typedServices = getTypedServices(result.items, result.service_type);
    const hotelServices = typedServices.filter((service): service is HotelService => service.service_type === 'hotel');
    const hotels = hotelServices.map(({ service }) => service);

    if (type === 'map') {
        return (
            <HotelsMapSearchResult
                hotels={hotels}
                personId={personId}
                searchId={searchId}
                tripId={tripId}
            />
        );
    }

    return (
        <FlipMove
            enterAnimation="fade"
            leaveAnimation="fade"
        >
            {typedServices.map(service => (
                <div
                    key={service.service.id}
                    className={cn('CardWrapper')}
                >
                    <ServiceCardTyped
                        adultsCount={adultsCount}
                        canAddServices={canAddServices}
                        className={cn('Card')}
                        personId={personId}
                        searchId={searchId}
                        service={service}
                        tripId={tripId}
                    />
                </div>
            ))}
        </FlipMove>
    );
});

type PageSearchResultsProps = {
    searchId: string;
    query: SearchFilters & { adult?: number };
    type: SearchLayoutType;
    personId: Person['person_id'];
    tripId?: number;
    canAddServices: boolean;
};

export const PageSearchResults: FC<PageSearchResultsProps> = props => {
    const {
        searchId,
        query,
        type,
        personId,
        tripId,
        canAddServices,
    } = props;
    const [data, infiniteQuery] = useSearchResults(searchId, { ...query, person_id: personId });
    const { fetchNextPage, hasNextPage, isFetchingNextPage } = infiniteQuery;
    const fetchMore = useCallback(() => fetchNextPage(), [fetchNextPage]);
    const language = getTypedI18nLang();

    const { city_from, city_to, type: service_type } = useQueryParams<{
        city_from: number;
        city_to: number;
        type: ServiceType
    }>();

    const infoMessagesResponse = useInfoMessage({
        city_from: service_type?.toLowerCase() !== 'hotel' ? city_from : city_to,
        city_to,
        service_type: service_type?.toLowerCase() as ServiceType,
    });

    const infoMessages = hasData(infoMessagesResponse) ? infoMessagesResponse.result.data : [];

    const inlineNotificationContent = infoMessages.map(infoMessage => {
        const view = infoMessage.type === 'warning' ? 'error' : 'info';

        const sanitizedRuMessage = sanitizeHtml(infoMessage.message);
        const sanitizedEnMessage = sanitizeHtml(infoMessage.message_en);

        return (
            <InlineNotification
                key={infoMessage.message_id}
                view={view}
            >
                <div dangerouslySetInnerHTML={ { __html: language === 'ru' ? sanitizedRuMessage : sanitizedEnMessage } } />
            </InlineNotification>
        );
    });

    return (
        <div className={cn('PageSearchResults', { type })}>
            {inlineNotificationContent}
            <Renderer
                spinner
                adultsCount={props.query?.adult || 1}
                canAddServices={canAddServices}
                personId={personId}
                result={data}
                searchId={searchId}
                tripId={tripId}
                type={type}
            />
            {hasNextPage && (
                <ButtonPlatform className={cn('ShowMore')} progress={isFetchingNextPage} onClick={fetchMore}>
                    {i18n('show_more')}
                </ButtonPlatform>
            )}
        </div>
    );
};
