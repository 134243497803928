export const en = {
    form_title: 'Company data',
    form_subtitle: 'Specify the company\'s phone number for communication and the accounting department\'s email address for sending financial documents',
    phone_number: 'Company phone number',
    email: 'Accounting Department email',
    company_name: 'Company name',
    inn: 'INN',
    kpp: 'KPP',
    legal_address: 'Legal adress',
    postal_address: 'Postal adress',
    submit_subtitle: 'By clicking the "Continue" button, you accept {offerLink} and confirm that you have the necessary authority to accept the offer on behalf of the person whose data is specified',
    submit_privacy_subtitle: 'Please note that the processing of personal data is carried out by Yandex under the conditions defined in {privacyPolicyLink}',
    offer: 'the offer of the "Yandex Trip" service',
    privacy_policy: 'Privacy policy',
};
