import { FC } from 'react';

import { cn } from './ToastContent.cn';

import './ToastContent.css';

export type ToastContentProps = {
    title: string;
    message?: string;
};

export const ToastContent: FC<ToastContentProps> = props => {
    const {
        title,
        message,
    } = props;

    return (
        <div className={cn()}>
            <div className="MyToastTitle">
                {title}
            </div>
            {message &&
                <div className="MyToastDescription">
                    {message}
                </div>
            }
        </div>
    );
};
