import { FC } from 'react';

import { Card } from 'components/Card';
import { CompanionsList } from 'components/CompanionsList';
import { withRemote } from 'hocs/withRemote';
import { ExtPerson } from 'services/SwaggerApi';

import { cn } from '../PageProfile.cn';
import { i18n } from '../PageProfile.i18n';

import { useExtPersonList } from './TabCompanions.hooks/useExtPersonList';
import { TabCompanionsProps } from './TabCompanions.types';

import './TabCompanions.css';

type CompanionsProps = {
    companions: ExtPerson[];
    personId: string;
};

const Companions = withRemote(({ companions, personId }: CompanionsProps) => {
    const isEmpty = companions.length === 0;

    return (
        <div className={cn('TabCompanions', { empty: isEmpty })}>
            <div className={cn('Main')}>
                <Card title={isEmpty ? '' : i18n('companions')}>
                    <CompanionsList
                        companions={companions}
                        personId={personId}
                    />
                </Card>
            </div>
        </div>
    );
});

export const TabCompanions: FC<TabCompanionsProps> = props => {
    const { personId } = props;

    const companions = useExtPersonList();

    return (
        <Companions
            companions={companions}
            personId={personId}
        />
    );
};
