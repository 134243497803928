import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { FieldDate, FieldSuggest, FieldTextinput, FormProvider } from 'components/FormField2';
import { parseDate } from 'components/TransactionForm/TransactionEditModal/TransactionEditModal.utils/parseDate';
import { config } from 'config';
import { RouteTrips } from 'routes/RouteTrips';
import { TripsFiltersEncoder } from 'services/TripApi/encoders/TripsFilters';
import { serializeDate } from 'utils/serializeDate';

import { cn } from './TripsFilter.cn';
import { i18n } from './TripsFilter.i18n';
import { TripFiltersFormFields, TripFiltersFormFieldsInitial, TripsFilterProps } from './TripsFilter.types';

import './TripsFilter.css';

const { isB2B } = config;

const getQueryFromFormValues = (data: TripFiltersFormFields): TripsFiltersEncoder => {
    const { city_to, date_from__gte, date_from__lte, manager, person, tracker_issue } = data;

    return {
        city_to: city_to?.id,
        date_from__gte: serializeDate(date_from__gte),
        date_from__lte: serializeDate(date_from__lte),
        tracker_issue,
        manager: manager?.id.toString(),
        person: person?.id.toString(),
    };
};

export const TripsFilter: FC<TripsFilterProps> = props => {
    const history = useHistory();
    const { search } = props;

    const {
        city_to,
        date_from__gte,
        date_from__lte,
        manager,
        person,
        tracker_issue,
    } = search;

    const methods = useForm<TripFiltersFormFieldsInitial>({
        defaultValues: {
            city_to,
            manager,
            person,
            tracker_issue,
            date_from__gte: parseDate(date_from__gte),
            date_from__lte: parseDate(date_from__lte),
        },
    });

    useEffect(() => {
        const subscription = methods.watch(data => {
            const pageTripsUrl = RouteTrips.getPath({}, getQueryFromFormValues(data as TripFiltersFormFields));

            history.push(pageTripsUrl);
        });

        return () => subscription.unsubscribe();
    }, [history, methods]);

    return (
        <FormProvider methods={methods}>
            <form className={cn()}>
                <FieldDate
                    label={i18n('date_from__gte')}
                    name="date_from__gte"
                />
                <FieldDate
                    label={i18n('date_from__lte')}
                    name="date_from__lte"
                />
                <FieldSuggest
                    label={i18n('city_to')}
                    name="city_to"
                    type="cities"
                />
                {!isB2B &&
                    <FieldTextinput
                        debounceTimeout={300}
                        label={i18n('tracker_issue')}
                        name="tracker_issue"
                        placeholder="XXX-123"
                    />
                }
                <FieldSuggest
                    label={i18n('person')}
                    name="person"
                    type="persons"
                />
                {!isB2B &&
                    <FieldSuggest
                        label={i18n('manager')}
                        name="manager"
                        type="persons"
                    />
                }
            </form>
        </FormProvider>
    );
};

TripsFilter.displayName = cn();
