import { MenuOption } from 'components/ActionsMenu';
import {
    getIconProvider,
    IconLink,
} from 'components/Icon';

import { i18n } from '../TableTransactionsHeader.i18n';
import { OnCollateRegistryClick } from '../TableTransactionsHeader.types';

type UseMenuOptions = (onCollateRegistryClick: OnCollateRegistryClick) => MenuOption[];

const iconProviderLink = getIconProvider(IconLink);

export const useMenuOptions: UseMenuOptions = onCollateRegistryClick => {
    return [{
        label: i18n('collate_registry'),
        icon: iconProviderLink,
        onClick: onCollateRegistryClick,
    }];
};
