export const en = {
    action_delete: 'Delete',
    action_update: 'Edit',
    action_set_transaction_paid: 'Paid',
    add_transaction: 'Add transaction',
    empty_table_title: 'Nothing has been added yet',
    empty_table_description: 'Add transactions and manage them in the Finance cabinet',
    cost_center_header: 'Cost center',
    trip_id_header: 'Trip',
    registry_id_header: 'Registry ID',
    general_service_id_header: 'Service number',
    execution_date_header: 'Execution date',
    invoice_date_header: 'Invoice date',
    person_header: 'Employee',
    service_type_header: 'Service type',
    service_type_value_avia: 'Flight',
    service_type_value_hotel: 'Hotel',
    service_type_value_rail: 'Rail',
    service_type_value_transfer: 'Transfer',
    service_type_value_vip_lounge: 'VIP lounge',
    service_type_value_fast_track: 'Fast track',
    service_type_value_aeroexpress: 'Aeroexpress',
    service_type_value_insurance: 'Insurance',
    service_type_value_additional: 'Additional service',
    service_type_value_convenient_service: 'Additional service',
    type_header: 'Transaction type',
    type_value_refund: 'Refund',
    type_value_purchase: 'Purchase',
    type_value_exchange: 'Modification',
    is_offline_header: 'Execution type',
    is_offline_value_true: 'Offline',
    is_offline_value_false: 'Online',
    is_penalty_header: 'Penalty',
    price_header: 'Service price',
    fee_header: 'Service fee',
    full_price_header: 'Full price',
    status_header: 'Status',
    status_value_completed: 'Send to billing',
    status_value_verified: 'Collated with registry',
    status_value_paid: 'Paid',
    status_value_hold: 'Hold',
};
