import { getTransferDuration } from 'components/FlightInfoRoute/FightInfoRoute.utils/getTransferDuration';
import { ProviderSegment } from 'services/SwaggerApi';

type TransferRouteDuration = {
    textContent: string;
    transferDuration: string;
};

export const getTransfersDurationList = (segments: ProviderSegment[]): TransferRouteDuration[] => {
    const transfersList: TransferRouteDuration[] = [];

    segments.slice(0, -1).forEach((segment, i) => {
        const {
            textContent,
            transferDuration,
        } = getTransferDuration({
            airportArrivalAt: segment.arrival_at,
            airportDepartureAt: segments[i + 1].departure_at,
            city: segment.arrival?.city,
        });

        transfersList.push({
            textContent,
            transferDuration,
        });
    });

    return transfersList;
};
