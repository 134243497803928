import { FC, useCallback } from 'react';

import { Dropdown } from 'components/Dropdown';
import { IconDownload } from 'components/Icon';
import { usePlatform } from 'hooks/usePlatform';
import { i18nAction } from 'i18n/i18nAction';
import { openFile } from 'platform/bridge-methods';
import { Button } from 'shared/ui/Button';
import { ListItem } from 'shared/ui/ListItem';

import { cn } from '../ServiceCardFooter.cn';

import { getAbsoluteAttachmentPath } from './utils/getAbsoluteAttachmentPath';
import { getAttachmentsInfo } from './utils/getAttachmentInfo';
import { AttachmentProps, AttachmentsDropdownProps } from './AttachmentsDropdown.types';

const Attachment: FC<AttachmentProps> = props => {
    const { attachment } = props;

    const { name, url } = attachment;
    const { fileName, additionalInfo } = getAttachmentsInfo(attachment);

    const handleClick = useCallback(() => {
        /* У АК и ААЦ различаются данные в url:
         * ААЦ идет с запросом по хосту сервиса, АК в урле содержит только хост
         * из-за этого разные способы формирования абсолютной ссылки
         */

        const absolutePath = getAbsoluteAttachmentPath(url, name);

        openFile(absolutePath);
    }, [url, name]);

    return (
        <ListItem
            className={cn('Attachment')}
            leadingTextParams={{
                label: fileName,
                caption: additionalInfo,
                size: 's',
            }}
            trailing={<IconDownload size="m" />}
            onClick={handleClick}
        />
    );
};

/**
 * Дропдаун со списком документов
 */
export const AttachmentsDropdown: FC<AttachmentsDropdownProps> = props => {
    const {
        attachments,
    } = props;

    const { isDesktop } = usePlatform();

    return (
        <Dropdown
            className={cn('AttachmentsDropdown')}
            drawerProps={{
                scope: 'inplace',
            }}
            popupProps={{
                direction: ['bottom-end', 'top-end'],
                scope: 'inplace',
            }}
            showIcon={isDesktop}
            triggerElement={
                <Button
                    className={cn('AttachmentsButton')}
                    data-testid="attachements-download-button"
                    size="m"
                    view="outline"
                    width={isDesktop ? 'auto' : 'max'}
                >
                    {i18nAction('download_attachments')}
                </Button>
            }
        >
            <div className={cn('Attachments')}>
                {attachments.map((attachment, index) => (
                    <Attachment key={index} attachment={attachment} />
                ))}
            </div>
        </Dropdown>
    );
};

AttachmentsDropdown.displayName = cn();
