import { FC } from 'react';

import { AppLink } from 'components/AppLink';
import { Text } from 'shared/ui/Text';

import { cn } from '../Register.cn';
import { i18n } from '../Register.i18n';

import { AlreadyRegisteredErrorProps } from './AlreadyRegisteredError.types';

import './AlreadyRegisteredError.css';

const SUPPORT_EMAIL = 'komandirovki@yandex-team.ru';

export const AlreadyRegisteredError: FC<AlreadyRegisteredErrorProps> = props => {
    const {
        className,
    } = props;

    return (
        <div
            className={cn('AlreadyRegisteredError', [className])}
            data-testid="already-registered-error"
        >
            <Text typography="display-s">{i18n('already_registered_error_title')}</Text>
            <ul className={cn('SolutionSteps')}>
                <li className={cn('SolutionStep')}>
                    <Text color="secondary">{i18n('solution_step_first')}</Text>
                </li>
                <li className={cn('SolutionStep')}>
                    <Text color="secondary">
                        {i18n('solution_step_second')}
                        {' '}
                        <AppLink href={`mailto:${SUPPORT_EMAIL}`}>
                            {SUPPORT_EMAIL}
                        </AppLink>
                    </Text>
                </li>
            </ul>
        </div>
    );
};
