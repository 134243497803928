import { UserResponse } from 'services/SwaggerApi';
import { CheckAccess, RouteErrorType } from 'types/Route';

export const checkAccessFactory = (
    checkAccessFn: (meta: UserResponse) => boolean,
    errorType: RouteErrorType,
): CheckAccess => meta => {
    const hasAccess = checkAccessFn(meta);

    if (hasAccess) {
        return {
            hasAccess,
        };
    }

    return {
        hasAccess,
        errorType,
    };
};
