import { FC } from 'react';

import { Card } from 'components/Card';
import { FieldCheckbox } from 'components/FormField2';
import { FieldOptions } from 'components/FormField2/Fields/FieldOptions';

import { AdditionalConditions } from '../AdditionalConditions';
import { CheckboxInputLabel } from '../CheckboxInputLabel';
import { cn } from '../FormTravelPolicy.cn';
import { i18n } from '../FormTravelPolicy.i18n';
import { getClassesOptions } from '../FormTravelPolicy.utils/getFormOptions';

import { AviaRulesProps } from './AviaRules.types';

import '../FormTravelPolicy.css';

export const AviaRules: FC<AviaRulesProps> = props => {
    const { methods } = props;

    return (
        <Card title={i18n('avia_rules')}>
            <div className={cn('Fieldset')}>
                <div className={cn('CommonOptions')}>
                    <FieldCheckbox
                        checkboxLabel={
                            <div className={cn('FieldCheckboxLabel')}>
                                {i18n('no_more_expensive')}
                                <div className={cn('FieldCheckboxInputWrapper')}>
                                    <CheckboxInputLabel name="avia_max_price" />
                                    {i18n('one_way')}
                                </div>
                            </div>
                        }
                        className={cn('FieldCheckbox', { type: 'label' })}
                        name="is_avia_max_price"
                    />

                    <FieldCheckbox
                        checkboxLabel={
                            <div className={cn('FieldCheckboxLabel')}>
                                {i18n('cheapest_more_percent')}
                                <div className={cn('FieldCheckboxInputWrapper')}>
                                    <CheckboxInputLabel name="avia_cheapest_more_percent" /> %
                                </div>
                            </div>
                        }
                        className={cn('FieldCheckbox', { type: 'label' })}
                        name="is_avia_cheapest_more_percent"
                    />

                    <FieldCheckbox
                        checkboxLabel={
                            <div className={cn('FieldCheckboxLabel')}>
                                {i18n('no_later_than')}
                                <div className={cn('FieldCheckboxInputWrapper')}>
                                    <CheckboxInputLabel name="avia_book_before_days" widthSize="s" />
                                    {i18n('avia_before_days')}
                                </div>
                            </div>
                        }
                        className={cn('FieldCheckbox', { type: 'label' })}
                        name="is_avia_book_before_days"
                    />

                    <FieldCheckbox
                        checkboxLabel={
                            <div className={cn('FieldCheckboxLabel')}>
                                {i18n('avia_max_transfer_duration')}
                                <div className={cn('FieldCheckboxInputWrapper')}>
                                    <CheckboxInputLabel name="avia_max_transfer_duration_hours" widthSize="s" />
                                    {i18n('hours')}
                                </div>
                            </div>
                        }
                        className={cn('FieldCheckbox', { type: 'label' })}
                        name="is_avia_max_transfer_duration_hours"
                    />

                    <FieldCheckbox
                        checkboxLabel={i18n('avia_refundable_only')}
                        className={cn('FieldCheckbox')}
                        data-testId="avia_refundable_only"
                        name="avia_refundable_only"
                    />

                    <FieldCheckbox
                        checkboxLabel={i18n('avia_exchangeable_only')}
                        className={cn('FieldCheckbox')}
                        data-testId="avia_exchangeable_only"
                        name="avia_exchangeable_only"
                    />

                    <FieldCheckbox
                        checkboxLabel={i18n('avia_with_baggage_only')}
                        className={cn('FieldCheckbox')}
                        data-testId="avia_with_baggage_only"
                        name="avia_with_baggage_only"
                    />

                    <FieldCheckbox
                        checkboxLabel={i18n('avia_business_days_only')}
                        className={cn('FieldCheckbox')}
                        data-testId="avia_business_days_only"
                        name="avia_business_days_only"
                    />
                </div>

                <FieldOptions
                    className={cn('FieldOptions')}
                    label={i18n('available_classes')}
                    name="avia_service_classes"
                    options={getClassesOptions()}
                />

                <AdditionalConditions methods={methods} name="avia_max_price_directions" />
            </div>
        </Card>
    );
};

AviaRules.displayName = cn();
