export const en = {
    add_hotel: 'Add hotel',
    accommodation: 'Accommodation',
    placeholder_hotel_tip: 'If the service is not needed, click the "No hotel needed" button. This decision is subject to change.',
    placeholder_transfer_tip: 'If the service is not needed, click the "No transfer needed" button. This decision can be changed',
    no_hotel_needed: 'No hotel needed',
    no_transfer_needed: 'No transfer needed',
    no_hotel: 'This is where you organize your stay',
    no_hotel_tip: "For compensation, contact your company's coordinator",
    no_transfer: 'Here you get yourself',
    no_transfer_tip: "For compensation, contact your company's coordinator",
    hotel_not_added: 'Hotel not added yet',
    transfer_not_added: 'Transfer not added yet',
    avia: 'Avia',
    avia_round_trip: 'Round trip flight',
    hotel: 'Hotel',
    rail: 'Rail',
    need_transfer: 'I need transfer',
    need_hotel: 'I need hotel',
    fix_application_date: 'You entered past dates. Please correct them and try again',
    yes_understand: 'Yes, I understand',
    good: 'Ok',
};
