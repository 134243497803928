import { FC, useCallback } from 'react';
import { Swiper as SwiperType } from 'swiper/types';

import { getIconProvider, IconArrowShortLeft, IconArrowShortRight } from 'components/Icon';
import { Button } from 'shared/ui/Button';

const iconProviderArrowShortLeft = getIconProvider(IconArrowShortLeft, { size: 'l' });
const iconProviderArrowShortRight = getIconProvider(IconArrowShortRight, { size: 'l' });

type ButtonSlideProps = {
    className: string;
    swiperRef: React.RefObject<SwiperType | undefined>;
    isActive: boolean;
    direction: 'left' | 'right';
};

export const ButtonSlide: FC<ButtonSlideProps> = props => {
    const {
        swiperRef,
        direction,
        className,
        isActive,
    } = props;

    const buttonIcon = direction === 'left' ? iconProviderArrowShortLeft : iconProviderArrowShortRight;

    const handleClick = useCallback(() => {
        if (direction === 'left') {
            swiperRef.current?.slidePrev();
        } else {
            swiperRef.current?.slideNext();
        }
    }, [direction, swiperRef]);

    if (!isActive) {
        return null;
    }

    return (
        <Button
            className={className}
            icon={buttonIcon}
            pin="circle-circle"
            size="m"
            view="contrast"
            onClick={handleClick}
        />
    );
};
