import { getTransferDuration } from 'components/FlightInfoRoute/FightInfoRoute.utils/getTransferDuration';
import { ProviderSegment } from 'services/SwaggerApi';
import { formatDuration } from 'utils/formatDuration';

export const countTransferDuration = (segments: ProviderSegment[]): string => {
    let minutesDuration = 0;

    segments.slice(0, -1).forEach((segment, i) => {
        const {
            totalMinutes,
        } = getTransferDuration({
            airportArrivalAt: segment.arrival_at,
            airportDepartureAt: segments[i + 1].departure_at,
            city: segment.arrival?.city,
        });

        minutesDuration += totalMinutes;
    });

    const minutes = minutesDuration % 60;
    const hours = (minutesDuration - minutes) / 60;

    return formatDuration(`${hours}:${minutes}:00`);
};
