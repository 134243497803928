export const en = {
    general_rules: 'General rules',
    avia_rules: 'Rules for air tickets',
    rail_rules: 'Rules for railway tickets',
    hotel_rules: 'Rules for hotels',
    travel_policy_name: 'The name of the travel policy',
    violation_label: 'In case of violation of the travel policy',
    approval_needed_label: 'When buying under the travel policy',
    violation_allowed: 'Show and submit for approval',
    violation_forbidden: 'Don\'t show options with violation of travel policy',
    approval_needed: 'Submit for approval',
    approval_not_needed: 'Buy without approval',
    additional_conditions: 'Additional conditions',
    max_price: 'Not more expensive than {price} ₽ one way',
    max_price_per_night: 'Not more expensive {price} ₽ per night',
    cheapest_more_percent: 'More expensive than the cheapest by no more than {percent}%',
    max_transfer_duration_hours: 'The maximum duration of the transfer is {hours} hours',
    avia_book_before_days: 'No later than {days} days before departure',
    rail_book_before_days: 'No later than {days} days before departure',
    hotel_book_before_days: 'No later than {days} days before check-in',
    refundable_only: 'Only with the possibility of a refund',
    hotel_refundable_only: 'Only with the possibility of cancellation',
    exchangeable_only: 'Only with the possibility of exchange',
    with_baggage_only: 'Only with baggage',
    avia_business_days_only: 'Only with departure and arrival on weekdays',
    rail_business_days_only: 'Only with departure and arrival on weekdays',
    hotel_business_days_only: 'Only with booking on weekdays',
    round_trips_only: 'Only round-trip routes',
    available_classes: 'Available classes: {classes}',
    available_categories: 'Available carriage types: {categories}',
    available_stars: 'Available number of stars: {stars}',
    max_price_direction: 'Not more expensive {price} ₽ for the direction {direction}',
    no_resorts: 'Except resort hotels',
    no_restrictions: 'No restrictions',
};
