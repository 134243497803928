import { useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { i18nErrors } from 'i18n/i18nErrors';
import { RouteSearch } from 'routes/RouteSearch';
import {
    ACServiceType,
    ClassAvia,
    Person,
    SearchAviaRequestIn,
    SearchHotelRequestIn,
    SearchMode,
    SearchRailRequestIn,
} from 'services/SwaggerApi';
import { search_mode as static_search_modes } from 'utils/constants';
import { errorToast } from 'utils/errorToast';
import { isLoading } from 'utils/Loadable';

import { useAviaSearchCreate } from './useAviaSearchCreate';
import { useHotelSearchCreate } from './useHotelSearchCreate';
import { useRailSearchCreate } from './useRailSearchCreate';

/**
 * Захардкоженное значение, для максимальной цены отеля, при первом поиске
 */
const MAX_DEFAULT_PRICE = 20000;

export type SearchDetails = {
    toCountryName?: string;
    type: ACServiceType;
    person_id: Person['person_id'];
    trip_id?: number;
    to_geoid?: number;
    from_geoid?: number;
    departure_on?: string;
    departure_back_on?: string;
    class_avia?: ClassAvia;
    adult?: number;
};

type QuerySearch = SearchRailRequestIn | SearchHotelRequestIn | SearchAviaRequestIn;

const getOption = (searchDetails: SearchDetails): QuerySearch => {
    const {
        type,
        departure_back_on,
        departure_on,
        from_geoid,
        to_geoid,
        class_avia,
        toCountryName,
        adult,
    } = searchDetails;

    switch (type) {
        case 'Avia':
            return {
                departure_on: departure_on!,
                departure_back_on,
                from_geoid,
                to_geoid,
                class_avia,
                adult,
            };

        case 'Hotel':
            const search_mode: SearchMode[] = [
                ...static_search_modes,
                // медленные нужны для заграницы https://st.yandex-team.ru/BTRIP-1777
                (toCountryName === 'Россия' || toCountryName === 'Russia') ? undefined : 'NoncorporateAndSlow',
            ].filter(Boolean);

            return {
                check_in_on: departure_on!,
                check_out_on: departure_back_on!,
                target_geoid: to_geoid,
                search_mode,
                adult,
            };

        case 'Rail':
            return {
                departure_on: departure_on!,
                from_geoid,
                to_geoid,
            };
    }
};

type UseCreateSearch = (
    onStartSearch?: () => void
) => {
    isSearchLoading: boolean;
    createSearch: (searchDetails: SearchDetails) => void;
};

const addQueryParams = (searchDetails: SearchDetails): RouteSearch['search'] => {
    const {
        person_id,
        type,
        class_avia,
        departure_back_on,
        departure_on,
        from_geoid,
        to_geoid,
        trip_id,
        adult,
    } = searchDetails;

    const tripNights = Math.abs(dayjs(departure_on).diff((departure_back_on || 0), 'day'));

    switch (type) {
        case 'Avia':
            return {
                person_id,
                trip_id,
                type,
                class_avia,
                city_from: from_geoid,
                city_to: to_geoid,
                date_from: departure_on,
                date_to: departure_back_on,
                adult,
            };

        case 'Hotel':
            return {
                person_id,
                trip_id,
                type,
                city_to: to_geoid,
                date_from: departure_on,
                date_to: departure_back_on,
                adult,
                price_from: 0,
                // У АК и АЦ фильтрация по цене происходит по всему периоду, поэтому умножаем на количество ночей
                price_to: tripNights * MAX_DEFAULT_PRICE,
            };

        case 'Rail':
            return {
                person_id,
                trip_id,
                type,
                city_from: from_geoid,
                city_to: to_geoid,
                date_from: departure_on,
            };
    }
};

export const useCreateSearch: UseCreateSearch = onStartSearch => {
    const searchDetailsRef = useRef<SearchDetails>();

    const history = useHistory();
    const callbacks = useMemo(() => ({
        onSuccess: ({ search_id }: { search_id: string }) => {
            onStartSearch?.();

            const routePath = RouteSearch.getPath(
                { searchId: search_id },
                addQueryParams(searchDetailsRef.current!),
            );

            history.push(routePath);
        },
        onError: e => errorToast(e, { title: i18nErrors('error_in_search_create'), message: i18nErrors('error_occurred') }),
        options: { hideErrorNotifier: true },
    }), [history, onStartSearch, searchDetailsRef]);

    const [aviaSearch, aviaSearchState] = useAviaSearchCreate(callbacks);
    const [hotelSearch, hotelSearchState] = useHotelSearchCreate(callbacks);
    const [railSearch, railSearchState] = useRailSearchCreate(callbacks);

    const isSearchLoading = isLoading(aviaSearchState) || isLoading(hotelSearchState) || isLoading(railSearchState);

    const createSearch = (searchDetails: SearchDetails) => {
        const { person_id, trip_id } = searchDetails;

        searchDetailsRef.current = searchDetails;

        const requestParams = getOption(searchDetails);

        switch (searchDetails.type) {
            case ('Avia'):
                return aviaSearch({ person_id, trip_id }, requestParams as SearchAviaRequestIn);

            case ('Hotel'):
                return hotelSearch({ person_id, trip_id }, requestParams as SearchHotelRequestIn);

            case ('Rail'):
                return railSearch({ person_id, trip_id }, requestParams as SearchRailRequestIn);
        }
    };

    return { isSearchLoading, createSearch };
};
