import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useToggle } from 'react-use';

import { GeoMap } from 'components/GeoMap';
import { HotelMark } from 'components/GeoMap/GeoMap.types';
import { getIconProvider, IconArrowShortRight, IconClose, IconLocation } from 'components/Icon';
import { Button } from 'shared/ui/Button';
import { ListItem } from 'shared/ui/ListItem';
import { Text } from 'shared/ui/Text';

import { cn } from '../HotelDetails.cn';

import { i18n } from './LocationButton.i18n';
import { LocationButtonProps } from './LocationButton.types';

import './LocationButton.css';

const GeoMapMemo = memo(GeoMap);
const ymapOptions = { controls: [] };

export const LocationButton: FC<LocationButtonProps> = props => {
    const {
        className,
        geo_position,
    } = props;

    const [visible, toggleVisible] = useToggle(false);
    const [loading, toggleLoading] = useToggle(false);

    const { latitude, longitude } = geo_position;
    const marks = useMemo<[HotelMark]>(
        () => [{ latitude, longitude } as HotelMark],
        [latitude, longitude],
    );

    useEffect(() => {
        document.body.style.overflow = visible ? 'hidden' : 'unset';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, [visible]);

    const handleClick = useCallback(() => {
        toggleLoading(true);
    }, [toggleLoading]);

    const handleLoaded = useCallback(() => {
        toggleVisible(true);
        toggleLoading(false);
    }, [toggleLoading, toggleVisible]);

    return (
        <div className={cn('LocationButton', [className])}>
            <ListItem
                className={cn('LocationButtonOpen')}
                leading={
                    <Button icon={getIconProvider(IconLocation)} size="m" view="media" />
                }
                leadingTextParams={{
                    label: <Text weight="medium">{i18n('show_on_a_map')}</Text>,
                    caption: i18n('location_in_ymap'),
                    size: 's',
                }}
                trailing={
                    <IconArrowShortRight size="m" />
                }
                onClick={handleClick}
            />
            {(loading || visible) && (
                createPortal(
                    <div className={cn('LocationButtonMapWrapper', { visible })}>
                        <GeoMapMemo
                            className={cn('LocationButtonMap')}
                            marks={marks}
                            ymapOptions={ymapOptions}
                            onReady={handleLoaded}
                        />
                        <Button
                            className={cn('LocationButtonClose', { visible })}
                            icon={getIconProvider(IconClose)}
                            size="l"
                            view="media"
                            onClick={toggleVisible}
                        />
                    </div>,
                    document.body,
                ))}
        </div>
    );
};
