import * as yup from 'yup';

import { Gender } from 'services/SwaggerApi';
import { isLatinRegexp } from 'shared/constants/isLatinRegexp';
import { yupFieldDate } from 'shared/lib/yup/yupFieldDate';
import { i18n } from 'utils/form/SchemaErrors.i18n';

const todayDate = new Date();

const genders: Gender[] = ['male', 'female'];

export const schema = yup.object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    middle_name: yup.string().nullable().default(''),
    first_name_ac_en: yup.string().nullable().trim().matches(isLatinRegexp, () => i18n('latin_required')).max(64).required(),
    last_name_ac_en: yup.string().nullable().trim().matches(isLatinRegexp, () => i18n('latin_required')).max(64).required(),
    middle_name_ac_en: yup.string().nullable().default('').trim().matches(isLatinRegexp, () => i18n('latin_required')).max(64),
    date_of_birth: yupFieldDate().max(todayDate, () => i18n('futureDate')).required(),
    gender: yup.mixed<Gender>().oneOf(genders).required(),
    phone_number: yup.string().trim().nullable().required().max(25),
    email: yup.string(),
});

export type SchemaType = yup.InferType<typeof schema>;
